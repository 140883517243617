import React, { useState, Component, useEffect } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { TimeColumn } from "../TimeColumn";
import * as moment from "moment";
import { useTranslation, withTranslation } from "react-i18next";

const SalonWorkingTimeListComponent = ({
  selectedSalon,
  getWorkingTimePaged,
  workingTimes,
  workingTimeTotalCount,
  clearParentState,
  setParentState,
  setCurrentDate,
  setToGetFreeSlots,
  getSalonWorkingTimeIsDateCreated,
}) => {
  const { t, i18n } = useTranslation();

  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 10 });

  useEffect(() => {
    if (selectedSalon.salonId) {
      getWorkingTimePaged(selectedSalon.salonId, page.skip / 10 + 1, sort);
    }
  }, [getWorkingTimePaged, page, selectedSalon, sort]);

  const ActionButtonsColumnWithTranslation = withTranslation()(
    ActionButtonsColumn
  );

  const grid = (
    <Grid
      data={workingTimes}
      pageable={{
        buttonCount: 3,
        info: true,
        type: "numeric",
        previousNext: true,
      }}
      skip={page.skip}
      take={page.take}
      onPageChange={(event) => {
        setPage({ skip: event.page.skip, take: event.page.take });
        getWorkingTimePaged(
          selectedSalon.salonId,
          event.page.skip / 10 + 1,
          sort
        );
      }}
      total={workingTimeTotalCount}
      sortable
      sort={sort}
      onSortChange={(e) => {
        setSort(e.sort);
        getWorkingTimePaged(selectedSalon.salonId, page.skip / 10 + 1, e.sort);
      }}
      style={{ height: "410px" }}
      className="table-header-purple"
    >
      <GridColumn
        field="startTime"
        title={t(
          "salon.salonWorkingTimeListComponent.grid.startTime.title",
          "Start time"
        )}
        className="start-time-cell"
        headerClassName="start-time-cell"
        cell={(props) => (
          <TimeColumn
            time={props.dataItem.startTime}
            mobileTitle={t(
              "salon.salonWorkingTimeListComponent.grid.startTime.mobileTitle",
              "Start time:"
            )}
          />
        )}
      />
      <GridColumn
        field="endTime"
        title={t(
          "salon.salonWorkingTimeListComponent.grid.endTime.title",
          "End time"
        )}
        className="end-time-cell"
        headerClassName="end-time-cell"
        cell={(props) => (
          <TimeColumn
            time={props.dataItem.endTime}
            mobileTitle={t(
              "salon.salonWorkingTimeListComponent.grid.endTime.mobileTitle",
              "End time:"
            )}
          />
        )}
      />
      <GridColumn
        field="Actions"
        title={t("common.actions", "Actions")}
        cell={(props) => (
          <ActionButtonsColumnWithTranslation
            setParentState={setParentState}
            setToGetFreeSlots={setToGetFreeSlots}
            setCurrentDate={setCurrentDate}
            {...props}
          />
        )}
        headerClassName="actions-cell two-bttns"
      />
    </Grid>
  );

  return (
    <>
      <div className="table-rounded-border table-headlabel-responsive-md hairstylist-table">
        <header>
          <div className="button-container">
            <button
              type="button"
              className="btn btn-purple-outline btn-sm ico-l"
              data-toggle="modal"
              data-target={`#createSalonWorkingTimeModal`}
              onClick={() => {
                let nowDate = moment(new Date()).format("YYYY-MM-DD");
                setToGetFreeSlots(true);
                setCurrentDate(nowDate);
                getSalonWorkingTimeIsDateCreated(
                  selectedSalon.salonId,
                  nowDate
                );
              }}
            >
              <i className="fal fa-plus"></i>
              {t("salon.salonWorkingTimeListComponent.newLabel", "New")}{" "}
              <span className="mobile-hide-550">
                {" "}
                {t(
                  "salon.salonWorkingTimeListComponent.workingTimeLabel",
                  "Working time"
                )}
              </span>
            </button>
          </div>
        </header>
        {grid}
      </div>
    </>
  );
};
class ActionButtonsColumn extends Component {
  render() {
    return (
      <td className="actions-cell two-bttns">
        <div className="button-container">
          <button
            type="button"
            className="btn btn-outline-success btn-sm ico-l"
            data-toggle="modal"
            data-target="#editSalonWorkingTimeModal"
            onClick={() => {
              this.props.setToGetFreeSlots(true);
              this.props.setCurrentDate(this.props.dataItem.startTime);
              this.props.setParentState(this.props.dataItem);
            }}
            disabled={this.props.dataItem.hasDependents}
          >
            <i className="fal fa-edit"></i>{" "}
            {this.props.t("common.edit", "Edit")}
          </button>
          <button
            type="button"
            className="btn btn-outline-danger btn-sm ico-l"
            data-toggle="modal"
            data-target="#deleteSalonWorkingTimeModal"
            onClick={() => {
              this.props.setParentState(this.props.dataItem);
            }}
            disabled={this.props.dataItem.hasDependents}
          >
            <i className="fal fa-ban"></i>{" "}
            {this.props.t("common.deactivate", "Deactivate")}
          </button>
        </div>
      </td>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    workingTimes: state.salonWorkingTimes,
    workingTimeTotalCount: state.salonWorkingTimeTotalCount,
    selectedSalon: ownProps.selectedSalon,
    setParentState: ownProps.setParentState,
    clearParentState: ownProps.clearParentState,
    setCurrentDate: ownProps.setCurrentDate,
    setToGetFreeSlots: ownProps.setToGetFreeSlots,
    getSalonWorkingTimeIsDateCreated: ownProps.getSalonWorkingTimeIsDateCreated,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getWorkingTimePaged(salonId, page, sort) {
    dispatch(mutations.getSalonWorkingTimePaged(salonId, page, sort));
  },
});

export const ConnectedSalonWorkingTimeList = connect(
  mapStateToProps,
  mapDispatchToProps
)(SalonWorkingTimeListComponent);
