/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ConnectedUserList } from "./UserList";
import * as mutations from "../../store/mutations";
import { FormErrors } from "../FormErrors";
import { ConnectedRolesDropDown } from "../Dropdowns/RolesDropDown";
import { ConnectedCategoryItemsDropDown } from "../Dropdowns/CategoryItemsDropDown";

import * as helper from "../HelperRepository";
import { ConnectedSalonsDropDown } from "../Dropdowns/SalonsDropDown";
import * as moment from "moment";
import { useTranslation } from "react-i18next";
import RequiredFieldsComponent from "../RequiredFieldsComponent";

const UserComponent = ({
  activeUser,
  createUser,
  saveUser,
  deleteUser,
  getActiveUser,
  getAllSalons,
  updateUserPassword,
}) => {
  const { t, i18n } = useTranslation();

  document.getElementsByTagName("html")[0].classList.remove("page-signin");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [personId, setPersonId] = useState("");
  const [statusId, setStatusId] = useState("");
  const [localeId, setLocaleId] = useState("");
  const [genderId, setGenderId] = useState("");

  const [formErrors, setFormErrors] = useState("");
  const [emailValid, setEmailValid] = useState("");
  const [passwordValid, setPasswordValid] = useState("");
  const [newUserPasswordValid, setNewUserPasswordValid] = useState("");
  const [
    confirmNewUserPasswordValid,
    setConfirmNewUserPasswordValid,
  ] = useState("");
  const [formValid, setFormValid] = useState("");
  const [createFormValid, setCreateFormValid] = useState("");
  const [editFormValid, setEditFormValid] = useState("");
  const [passwordFormValid, setPasswordFormValid] = useState("");
  const [search, setSearch] = useState("");
  const [roleId, setRoleId] = useState("");
  const [role, setRole] = useState("");
  const [serviceTypes, setServiceTypes] = useState("");
  const [salonId, setSalonId] = useState("");
  const [salonTimeSlot, setSalonTimeSlot] = useState("");

  const [enableRender, setEnableRender] = useState(false);

  const [newUserPassword, setNewUserPassword] = useState("");
  const [confirmNewUserPassword, setConfirmNewUserPassword] = useState("");

  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isChangeUserPassword, setIsChangeUserPassword] = useState(false);

  const [firstNameValid, setFirstNameValid] = useState(false);
  const [lastNameValid, setLastNameValid] = useState(false);
  const [usernameValid, setUsernameValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(false);

  const passwordLength = 6;

  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add("grid-view");

    if (!activeUser || !activeUser.personId) {
      getActiveUser();
      getAllSalons();
    }
    return () => {
      document.getElementsByTagName("html")[0].classList.remove("grid-view");
    };
  }, []);

  useEffect(() => {
    // because Object.entries(new Date()).length === 0;
    // we have to do some additional check
    // Object.entries(activeUser).length === 0 &&
    //   activeUser.constructor === Object;

    // ECMA 5+
    //   Object.keys(activeUser).length === 0 &&
    //   activeUser.constructor === Object

    if (!window.jQuery.isEmptyObject(activeUser)) {
      setEnableRender(true);
    } else {
      setEnableRender(false);
    }
  }, [activeUser]);

  const clearState = () => {
    setPersonId("");
    setStatusId("");
    setUsername("");
    setPassword("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setRoleId("");
    setNewUserPassword("");
    setConfirmNewUserPassword("");

    setEmailValid(false);
    setPasswordValid(false);
    setFormValid(false);
    setCreateFormValid(false);
    setEditFormValid(false);
    setPasswordFormValid(false);
    setNewUserPasswordValid(false);
    setConfirmNewUserPasswordValid(false);
    setUsernameValid(false);
    setFirstNameValid(false);
    setLastNameValid(false);
    setPhoneValid(false);
    setFormErrors({});
    setLocaleId("");
    setGenderId("");
    clearNewPasswordState();
  };

  const clearComponentShow = () => {
    setIsCreate(false);
    setIsDelete(false);
    setIsEdit(false);
  };

  const clearNewPasswordState = () => {
    setIsChangeUserPassword(false);

    setNewUserPassword("");
    setConfirmNewUserPassword("");
    setNewUserPasswordValid("");
    setConfirmNewUserPasswordValid("");
    setFormErrors({
      email: "",
      emailEdit: "",
      password: "",
      newUserPassword: "",
      confirmNewUserPassword: "",
    });
  };

  const setUserState = (user) => {
    setPersonId(user.personId);
    setStatusId(user.statusId);
    setFirstName(user.firstName ? user.firstName : "");
    setLastName(user.lastName ? user.lastName : "");
    setEmail(user.email ? user.email : "");
    setPhone(user.phone ? user.phone : "");
    setRoleId(user.userRoles[0].role.roleId);
    setRole(user.userRoles[0].role);
    setSalonId(user.userRoles[0].externalId);
    setNewUserPassword("");
    setConfirmNewUserPassword("");

    setEmailValid(true);
    setPasswordValid(false);
    setFormValid(false);
    setEditFormValid(true);
    setPasswordFormValid(false);
    setNewUserPasswordValid(false);
    setConfirmNewUserPasswordValid(false);
    setFirstNameValid(true);
    setLastNameValid(true);
    setPhoneValid(true);
    setFormErrors({
      email: "",
      emailEdit: "",
      password: "",
      newUserPassword: "",
      confirmNewUserPassword: "",
    });
    setGenderId(user.genderId ? user.genderId : "");
    setLocaleId(user.localeId ? user.localeId : "");
  };

  useEffect(() => {
    setCreateFormValid(
      usernameValid !== null &&
        passwordValid !== null &&
        firstNameValid !== null &&
        lastNameValid !== null &&
        phoneValid !== null &&
        emailValid !== null &&
        roleId !== ""
    );
  }, [roleId]);

  const handleUserInput = (e) => {
    switch (e.target.id) {
      case "email":
      case "emailEdit":
        setEmail(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "newUserPassword":
        setNewUserPassword(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "confirmNewUserPassword":
        setConfirmNewUserPassword(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "userName":
        setUsername(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "firstName":
      case "firstNameEdit":
        setFirstName(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "lastName":
      case "lastNameEdit":
        setLastName(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "phone":
      case "phoneEdit":
        setPhone(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      default:
        break;
    }
  };

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let fieldEmailValid = emailValid;
    let fieldPasswordValid = passwordValid;
    let fieldNewUserPasswordValid = newUserPasswordValid;
    let fieldConfirmNewUserPasswordValid = confirmNewUserPasswordValid;
    let fieldUsernameValid = usernameValid;
    let fieldFirstNameValid = firstNameValid;
    let fieldLastNameValid = lastNameValid;
    let fieldPhoneValid = phoneValid;

    switch (fieldName) {
      case "email":
        let emailFieldValidPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        fieldEmailValid = emailFieldValidPattern.test(value);
        setEmailValid(fieldEmailValid);
        fieldValidationErrors.email = fieldEmailValid
          ? ""
          : t(
              "common.emailIsInvalid",
              " is invalid email address. Valid e-mail can contain only latin letters, numbers, '@' and '.'"
            );
        break;

      case "emailEdit":
        let emailEditFieldValidPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        fieldEmailValid = emailEditFieldValidPattern.test(value);
        setEmailValid(fieldEmailValid);
        fieldValidationErrors.emailEdit = fieldEmailValid
          ? ""
          : t(
              "common.emailIsInvalid",
              " is invalid email address. Valid e-mail can contain only latin letters, numbers, '@' and '.'"
            );
        break;
      case "password":
        fieldPasswordValid = value.length >= passwordLength;
        setPasswordValid(fieldPasswordValid);
        fieldValidationErrors.password = fieldPasswordValid
          ? ""
          : `is too short (minimum ${passwordLength} characters)`;
        break;
      case "newUserPassword":
        fieldNewUserPasswordValid = value.length >= passwordLength;
        setNewUserPasswordValid(fieldNewUserPasswordValid);
        fieldValidationErrors.newUserPassword = fieldNewUserPasswordValid
          ? ""
          : `is too short (minimum ${passwordLength} characters)`;
        break;
      case "confirmNewUserPassword":
        fieldConfirmNewUserPasswordValid =
          newUserPassword === value && value.length >= passwordLength;
        setConfirmNewUserPasswordValid(fieldConfirmNewUserPasswordValid);
        if (value.length < passwordLength) {
          fieldValidationErrors.confirmNewUserPassword = `is too short (minimum ${passwordLength} characters)`;
        } else if (newUserPassword !== value) {
          fieldValidationErrors.confirmNewUserPassword = `must be the same as new password`;
        } else {
          fieldValidationErrors.confirmNewUserPassword = ``;
        }

        break;
      case "firstName":
      case "firstNameEdit":
        let firstNameFieldValidPattern = /^([A-Za-z\u0110\u017D\u01C8\u01CB\u0106\u010C\u01C5\u0160\u0111\u017E\u01C9\u01CC\u0107\u010D\u01C6\u0161 ]+)$/;
        fieldFirstNameValid = firstNameFieldValidPattern.test(value);
        setFirstNameValid(fieldFirstNameValid);
        fieldValidationErrors.firstName = fieldFirstNameValid
          ? ""
          : t(
              "common.firstNameIsInvalid",
              "  is invalid. Valid format is one or more character only strings separated by blank"
            );
        break;
      case "lastName":
      case "lastNameEdit":
        let lastNameFieldValidPattern = /^([A-Za-z\u0110\u017D\u01C8\u01CB\u0106\u010C\u01C5\u0160\u0111\u017E\u01C9\u01CC\u0107\u010D\u01C6\u0161 ]+)$/;
        fieldLastNameValid = lastNameFieldValidPattern.test(value);
        setLastNameValid(fieldLastNameValid);
        fieldValidationErrors.lastName = fieldLastNameValid
          ? ""
          : t(
              "common.lastNameIsInvalid",
              "  is invalid. Valid format is one or more character only strings separated by blank"
            );
        break;
      case "phone":
      case "phoneEdit":
        let phoneFieldValidPattern = /^((\+?)[0-9]{9,15})$/i;
        fieldPhoneValid = phoneFieldValidPattern.test(value);
        setPhoneValid(fieldPhoneValid);
        fieldValidationErrors.phone = fieldPhoneValid
          ? ""
          : t(
              "common.phoneIsInvalid",
              "  is invalid. Valid format +06xxxxxxxx, from 9 to 15 numbers, sign + optional"
            );
        break;
      case "userName":
        let usernameFieldValidPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        fieldUsernameValid = usernameFieldValidPattern.test(value);
        setUsernameValid(fieldUsernameValid);

        fieldValidationErrors.username = fieldUsernameValid
          ? ""
          : t(
              "common.emailIsInvalid",
              " is invalid email address. Valid e-mail can contain only latin letters, numbers, '@' and '.'"
            );
        break;
      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    // setEmailValid(fieldEmailValid);
    // setPasswordValid(fieldPasswordValid);
    // setNewUserPasswordValid(fieldNewUserPasswordValid);
    // setConfirmNewUserPasswordValid(fieldConfirmNewUserPasswordValid);
    validateForm();
    setCreateFormValid(
      fieldUsernameValid &&
        fieldPasswordValid &&
        fieldFirstNameValid &&
        fieldLastNameValid &&
        fieldPhoneValid &&
        fieldEmailValid &&
        roleId
    );
    setEditFormValid(
      fieldFirstNameValid &&
        fieldLastNameValid &&
        fieldPhoneValid &&
        fieldEmailValid &&
        roleId
    );
    setPasswordFormValid(
      fieldNewUserPasswordValid && fieldConfirmNewUserPasswordValid
    );
  };

  const validateForm = () => {
    setFormValid(emailValid && passwordValid);
  };

  const errorClassInput = (error) => {
    if (error) {
      return error.length === 0 ? "" : "is-invalid";
    }
    return "";
  };

  const errorClassLabel = (error) => {
    if (error) {
      return error.length === 0 ? "" : "text-danger";
    }
    return "";
  };

  if (!enableRender) {
    return null;
  } else {
    return (
      <>
        <main role="main">
          <div className="container-fluid">
            <ConnectedUserList
              setParentState={setUserState}
              setParentSearch={setSearch}
              clearParentState={clearState}
              setIsCreate={setIsCreate}
              setIsEdit={setIsEdit}
              setIsDelete={setIsDelete}
            />
          </div>
        </main>
        <footer className="footer">
          <div className="container copyright">
            <span className="text-muted">
              Hairstylist &copy; Copyright {moment().format("YYYY")},{" "}
              {t("common.version", "Version:")} {process.env.VERSION}
            </span>
          </div>
        </footer>

        {/* Create User Modal */}
        {isCreate && (
          <div
            className="modal fade"
            data-backdrop="static"
            data-keyboard="false"
            id="createUserModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="createUserModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-md"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="createUserModalLabel">
                    <i className="fal fa-user fa-lg c-purple"></i>{" "}
                    {t(
                      "user.userComponent.createUserModal.title",
                      "Create User"
                    )}{" "}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      clearComponentShow();
                    }}
                  >
                    <i className="fal fa-times"></i>
                  </button>
                </div>

                <div className="modal-body">
                  <form className="form needs-validation" noValidate>
                    <input type="hidden" value="something" />
                    <div className="form-group">
                      <label
                        htmlFor="userName"
                        className={`${errorClassLabel(
                          formErrors.username
                        )} required`}
                      >
                        {t(
                          "user.userComponent.createUserModal.usernameLabel",
                          "Username"
                        )}
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errorClassInput(
                          formErrors.username
                        )}`}
                        id="userName"
                        autoComplete="new-username"
                        placeholder={t(
                          "user.userComponent.createUserModal.usernamePlaceholder",
                          "Please enter your username"
                        )}
                        value={username}
                        onChange={handleUserInput}
                        onBlur={(e) => {
                          validateField(e.target.id, e.target.value);
                        }}
                        required
                      ></input>
                      <div className="invalid-feedback">
                        {formErrors["username"]?.length > 0 && (
                          <p className="text-danger">
                            {t(
                              "user.userComponent.createUserModal.usernameInvalid",
                              "Username"
                            )}
                            {formErrors["username"]}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="pasword"
                        className={`${errorClassLabel(
                          formErrors.password
                        )} required`}
                      >
                        {t(
                          "user.userComponent.createUserModal.passwordLabel",
                          "Password"
                        )}
                      </label>
                      <input
                        type="password"
                        className={`form-control ${errorClassInput(
                          formErrors.password
                        )}`}
                        id="password"
                        autoComplete="new-password"
                        placeholder={t(
                          "user.userComponent.createUserModal.passwordPlaceholder",
                          "Please enter your password"
                        )}
                        value={password}
                        onChange={handleUserInput}
                        onBlur={(e) => {
                          validateField(e.target.id, e.target.value);
                        }}
                        required
                      ></input>

                      <div className="panel panel-default">
                        <FormErrors
                          formErrors={formErrors}
                          fieldToShow={"password"}
                          valueToShow={"Password"}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="firstName"
                        className={`${errorClassLabel(
                          formErrors.firstName
                        )} required`}
                      >
                        {t(
                          "user.userComponent.createUserModal.firstnameLabel",
                          "Firstname"
                        )}
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errorClassInput(
                          formErrors.firstName
                        )}`}
                        id="firstName"
                        placeholder={t(
                          "user.userComponent.createUserModal.firstnamePlaceholder",
                          "Please enter your firstname"
                        )}
                        value={firstName}
                        onChange={handleUserInput}
                        onBlur={(e) => {
                          validateField(e.target.id, e.target.value);
                        }}
                        required
                      ></input>
                      <div className="invalid-feedback">
                        {formErrors["firstName"]?.length > 0 && (
                          <p className="text-danger">
                            {t(
                              "user.userComponent.createUserModal.firstnameInvalid",
                              "First name"
                            )}
                            {formErrors["firstName"]}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="lastName"
                        className={`${errorClassLabel(
                          formErrors.lastName
                        )} required`}
                      >
                        {t(
                          "user.userComponent.createUserModal.lastnameLabel",
                          "Lastname"
                        )}
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errorClassInput(
                          formErrors.lastName
                        )}`}
                        id="lastName"
                        placeholder={t(
                          "user.userComponent.createUserModal.lastnamePlaceholder",
                          "Please enter your lastname"
                        )}
                        value={lastName}
                        onChange={handleUserInput}
                        onBlur={(e) => {
                          validateField(e.target.id, e.target.value);
                        }}
                        required
                      ></input>
                      <div className="invalid-feedback">
                        {formErrors["lastName"]?.length > 0 && (
                          <p className="text-danger">
                            {t(
                              "user.userComponent.createUserModal.lastnameInvalid",
                              "Last name"
                            )}
                            {formErrors["lastName"]}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="email"
                        className={`${errorClassLabel(
                          formErrors.email
                        )} required`}
                      >
                        {t(
                          "user.userComponent.createUserModal.emailLabel",
                          "Email"
                        )}
                      </label>
                      <input
                        type="email"
                        className={`form-control ${errorClassInput(
                          formErrors.email
                        )} `}
                        id="email"
                        placeholder={t(
                          "user.userComponent.createUserModal.emailPlaceholder",
                          "you@example.com"
                        )}
                        value={email}
                        onChange={handleUserInput}
                        onBlur={(e) => {
                          validateField(e.target.id, e.target.value);
                        }}
                        required
                      ></input>
                      <div className="invalid-feedback">
                        {formErrors["email"]?.length > 0 && (
                          <p className="text-danger">
                            {t(
                              "user.userComponent.createUserModal.emailInvalid",
                              "Email"
                            )}
                            {formErrors["email"]}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="phone"
                        className={`${errorClassLabel(
                          formErrors.phone
                        )} required`}
                      >
                        {t(
                          "user.userComponent.createUserModal.phoneLabel",
                          "Phone"
                        )}
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errorClassInput(
                          formErrors.phone
                        )}`}
                        id="phone"
                        placeholder={t(
                          "user.userComponent.createUserModal.phonePlaceholder",
                          "+381 11 12346789"
                        )}
                        value={phone}
                        onChange={handleUserInput}
                        onBlur={(e) => {
                          validateField(e.target.id, e.target.value);
                        }}
                        required
                      ></input>
                      <div className="invalid-feedback">
                        {formErrors["Phone"]?.length > 0 && (
                          <p className="text-danger">
                            {t(
                              "user.userComponent.createUserModal.phoneInvalid",
                              "Phone"
                            )}
                            {formErrors["phone"]}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="genderId">
                        {t(
                          "user.userComponent.createUserModal.genderLabel",
                          "Gender"
                        )}
                      </label>
                      <ConnectedCategoryItemsDropDown
                        categoryType="Gender"
                        categoryItemId={genderId}
                        setCategoryItemId={setGenderId}
                        id="genderId"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="roleId" className="required">
                        {t(
                          "user.userComponent.createUserModal.roleLabel",
                          "Role"
                        )}
                      </label>
                      <ConnectedRolesDropDown
                        roleId={roleId}
                        setRole={setRole}
                        setRoleId={setRoleId}
                        id="roleId"
                      />
                      <div className="invalid-feedback">
                        {t(
                          "user.userComponent.createUserModal.roleInvalid",
                          "Please select your role."
                        )}
                      </div>
                    </div>
                    {role.name === "Salon admin" && (
                      <div className="form-group">
                        <label htmlFor="salonId" className="required">
                          {helper.i18nMultiFallbackKey(
                            "user.userComponent.createUserModal.shopLabel",
                            "Shop"
                          )}
                        </label>
                        <ConnectedSalonsDropDown
                          salonId={salonId}
                          setSalonId={setSalonId}
                          setServiceTypes={setServiceTypes}
                          setSalonTimeSlot={setSalonTimeSlot}
                          id="salonId"
                        />
                        <div className="invalid-feedback">
                          {helper.i18nMultiFallbackKey(
                            "user.userComponent.createUserModal.shopInvalid",
                            "Please select your shop."
                          )}
                        </div>
                      </div>
                    )}
                    <div className="form-group">
                      <label htmlFor="localeId">
                        {t(
                          "user.userComponent.createUserModal.localeLabel",
                          "Locale"
                        )}
                      </label>
                      <ConnectedCategoryItemsDropDown
                        categoryType="Locales"
                        categoryItemId={localeId}
                        setCategoryItemId={setLocaleId}
                        id="localeId"
                      />
                    </div>
                    <RequiredFieldsComponent />
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-danger ico-l"
                    data-dismiss="modal"
                    onClick={() => {
                      clearComponentShow();
                    }}
                  >
                    <i className="fal fa-ban"></i>
                    {t("common.cancel", "Cancel")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success ico-l"
                    data-dismiss="modal"
                    disabled={!createFormValid}
                    onClick={() =>
                      createUser(
                        username,
                        password,
                        firstName,
                        lastName,
                        email,
                        phone,
                        roleId,
                        role.name === "Salon admin" ? salonId : null,
                        genderId,
                        localeId,
                        () => {
                          clearState();
                          clearComponentShow();
                        }
                      )
                    }
                  >
                    <i className="fal fa-check"></i>
                    {t("common.finish", "Finish")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Edit User Modal */}

        {isEdit && (
          <div
            className="modal fade"
            data-backdrop="static"
            data-keyboard="false"
            id="editUserModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="editUserModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-md"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="editUserModalLabel">
                    <i className="fal fa-user fa-lg c-purple"></i>
                    {t(
                      "user.userComponent.editUserModal.title",
                      "Edit User"
                    )}{" "}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      clearComponentShow();
                    }}
                  >
                    <i className="fal fa-times"></i>
                  </button>
                </div>

                <div className="modal-body">
                  <form className="form needs-validation" noValidate>
                    <div className="form-group">
                      <label
                        htmlFor="firstNameEdit"
                        className={`${errorClassLabel(
                          formErrors.firstName
                        )} required`}
                      >
                        {t(
                          "user.userComponent.editUserModal.firstnameLabel",
                          "Firstname"
                        )}
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errorClassInput(
                          formErrors.firstName
                        )}`}
                        id="firstNameEdit"
                        placeholder={t(
                          "user.userComponent.editUserModal.firstnamePlaceholder",
                          "Firstname"
                        )}
                        value={firstName}
                        onChange={handleUserInput}
                        onBlur={(e) => {
                          validateField(e.target.id, e.target.value);
                        }}
                        required
                      ></input>
                      <div className="invalid-feedback">
                        {formErrors["firstName"]?.length > 0 && (
                          <p className="text-danger">
                            {t(
                              "user.userComponent.editUserModal.firstnameInvalid",
                              "First name"
                            )}
                            {formErrors["firstName"]}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="lastNameEdit"
                        className={`${errorClassLabel(
                          formErrors.lastName
                        )} required`}
                      >
                        {t(
                          "user.userComponent.editUserModal.lastnameLabel",
                          "Lastname"
                        )}
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errorClassInput(
                          formErrors.lastName
                        )}`}
                        id="lastNameEdit"
                        placeholder={t(
                          "user.userComponent.editUserModal.lastnamePlaceholder",
                          "Lastname"
                        )}
                        value={lastName}
                        onChange={handleUserInput}
                        onBlur={(e) => {
                          validateField(e.target.id, e.target.value);
                        }}
                        required
                      ></input>
                      <div className="invalid-feedback">
                        {formErrors["lastName"]?.length > 0 && (
                          <p className="text-danger">
                            {t(
                              "user.userComponent.editUserModal.lastnameInvalid",
                              "Last name"
                            )}
                            {formErrors["lastName"]}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="emailEdit"
                        className={`${errorClassLabel(
                          formErrors.emailEdit
                        )}  required`}
                      >
                        {t(
                          "user.userComponent.editUserModal.emailLabel",
                          "Email"
                        )}
                      </label>
                      <input
                        type="email"
                        className={`form-control ${errorClassInput(
                          formErrors.emailEdit
                        )} `}
                        id="emailEdit"
                        placeholder={t(
                          "user.userComponent.editUserModal.emailPlaceholder",
                          "you@example.com"
                        )}
                        value={email}
                        onChange={handleUserInput}
                        onBlur={(e) => {
                          validateField(e.target.id, e.target.value);
                        }}
                        required
                      ></input>
                      <div className="panel panel-default">
                        <FormErrors
                          formErrors={formErrors}
                          fieldToShow={"emailEdit"}
                          valueToShow={"E-Mail"}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="phoneEdit"
                        className={`${errorClassLabel(
                          formErrors.phone
                        )} required`}
                      >
                        {t(
                          "user.userComponent.editUserModal.phoneLabel",
                          "Phone"
                        )}
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errorClassInput(
                          formErrors.phone
                        )}`}
                        id="phoneEdit"
                        placeholder={t(
                          "user.userComponent.editUserModal.phonePlaceholder",
                          "+381 11 12346789"
                        )}
                        value={phone}
                        onChange={handleUserInput}
                        onBlur={(e) => {
                          validateField(e.target.id, e.target.value);
                        }}
                        required
                      ></input>
                      <div className="invalid-feedback">
                        {formErrors["phone"]?.length > 0 && (
                          <p className="text-danger">
                            {t(
                              "user.userComponent.editUserModal.phoneInvalid",
                              "Phone"
                            )}
                            {formErrors["phone"]}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="genderIdEdit">
                        {t(
                          "user.userComponent.editUserModal.genderLabel",
                          "Gender"
                        )}
                      </label>
                      <ConnectedCategoryItemsDropDown
                        categoryType="Gender"
                        categoryItemId={genderId}
                        setCategoryItemId={setGenderId}
                        id="genderIdEdit"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="roleIdEdit" className="required">
                        {t(
                          "user.userComponent.editUserModal.roleLabel",
                          "Role"
                        )}
                      </label>
                      <ConnectedRolesDropDown
                        roleId={roleId}
                        setRole={setRole}
                        setRoleId={setRoleId}
                        id="roleIdEdit"
                      />
                      <div className="invalid-feedback">
                        {t(
                          "user.userComponent.editUserModal.roleInvalid",
                          "Please select your role."
                        )}
                      </div>
                    </div>

                    {role.name === "Salon admin" && (
                      <div className="form-group">
                        <label htmlFor="salonId" className="required">
                          {helper.i18nMultiFallbackKey(
                            "user.userComponent.editUserModal.shopLabel",
                            "Shop"
                          )}
                        </label>
                        <ConnectedSalonsDropDown
                          salonId={salonId}
                          setSalonId={setSalonId}
                          setServiceTypes={setServiceTypes}
                          setSalonTimeSlot={setSalonTimeSlot}
                          id="salonId"
                        />
                        <div className="invalid-feedback">
                          {helper.i18nMultiFallbackKey(
                            "user.userComponent.editUserModal.shopInvalid",
                            "Please select your shop."
                          )}
                        </div>
                      </div>
                    )}
                    <div className="form-group">
                      <label htmlFor="localeIdEdit">
                        {t(
                          "user.userComponent.editUserModal.localeLabel",
                          "Locale"
                        )}
                      </label>
                      <ConnectedCategoryItemsDropDown
                        categoryType="Locales"
                        categoryItemId={localeId}
                        setCategoryItemId={setLocaleId}
                        id="localeIdEdit"
                      />
                    </div>
                    <RequiredFieldsComponent />
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-primary ico-l"
                    data-dismiss="modal"
                    onClick={() => {
                      clearComponentShow();
                    }}
                  >
                    <i className="fal fa-sync"></i>
                    {t("common.cancel", "Cancel")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary ico-l"
                    data-dismiss="modal"
                    // data-toggle="modal"
                    // data-target="#changeUserPasswordModal"
                    onClick={() => {
                      clearComponentShow();
                      setIsChangeUserPassword(true);
                      helper.showModal("#changeUserPasswordModal");
                    }}
                  >
                    <i className="fal fa-sync"></i>{" "}
                    {t(
                      "user.userComponent.editUserModal.changePassword",
                      "Change Password"
                    )}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success ico-l"
                    data-dismiss="modal"
                    disabled={!editFormValid}
                    onClick={() =>
                      saveUser(
                        personId,
                        firstName,
                        lastName,
                        email,
                        phone,
                        roleId,
                        role.name === "Salon admin" ? salonId : null,
                        statusId,
                        genderId,
                        localeId,
                        () => {
                          clearState();
                          clearComponentShow();
                        }
                      )
                    }
                  >
                    <i className="fal fa-check"></i>
                    {t("common.finish", "Finish")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Change User Password */}
        {isChangeUserPassword && (
          <div
            className="modal fade"
            data-backdrop="static"
            data-keyboard="false"
            id="changeUserPasswordModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="changeUserPasswordModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-md"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="changeUserPasswordModalLabel">
                    <i className="fal fa-user fa-lg c-purple"></i>{" "}
                    {t(
                      "user.userComponent.changeUserPasswordModal.title",
                      "Change User Password"
                    )}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      clearNewPasswordState();
                      setIsEdit(true);
                      helper.showModal("#editUserModal");
                    }}
                  >
                    <i className="fal fa-times"></i>
                  </button>
                </div>

                <div className="modal-body">
                  <form className="form needs-validation" noValidate>
                    <div className="form-group">
                      <label
                        htmlFor="newUserPassword"
                        className={`${errorClassLabel(
                          formErrors.newUserPassword
                        )} required`}
                      >
                        {t(
                          "user.userComponent.changeUserPasswordModal.newUserPasswordLabel",
                          "New user password"
                        )}
                      </label>
                      <input
                        type="password"
                        className={`form-control ${errorClassInput(
                          formErrors.newUserPassword
                        )}`}
                        id="newUserPassword"
                        placeholder={t(
                          "user.userComponent.changeUserPasswordModal.newUserPasswordPlaceholder",
                          "New user password"
                        )}
                        value={newUserPassword}
                        onChange={handleUserInput}
                        required
                      ></input>
                      <div className="panel panel-default">
                        <FormErrors
                          formErrors={formErrors}
                          fieldToShow={"newUserPassword"}
                          valueToShow={"New user password"}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="confirmNewUserPassword"
                        className={`${errorClassLabel(
                          formErrors.confirmNewUserPassword
                        )} required`}
                      >
                        {t(
                          "user.userComponent.changeUserPasswordModal.confirmNewUserPasswordLabel",
                          "Confirm new user password"
                        )}
                      </label>
                      <input
                        type="password"
                        className={`form-control ${errorClassInput(
                          formErrors.confirmNewUserPassword
                        )}`}
                        id="confirmNewUserPassword"
                        placeholder={t(
                          "user.userComponent.changeUserPasswordModal.confirmNewUserPasswordPlaceholder",
                          "Confirm new user password"
                        )}
                        value={confirmNewUserPassword}
                        onChange={handleUserInput}
                        required
                      ></input>
                      <div className="panel panel-default">
                        <FormErrors
                          formErrors={formErrors}
                          fieldToShow={"confirmNewUserPassword"}
                          valueToShow={"Confirm new user password"}
                        />
                      </div>
                    </div>
                    <RequiredFieldsComponent />
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-primary ico-l"
                    data-dismiss="modal"
                    onClick={() => {
                      clearNewPasswordState();
                      setIsEdit(true);
                      helper.showModal("#editUserModal");
                    }}
                  >
                    <i className="fal fa-sync"></i>
                    {t("common.cancel", "Cancel")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success ico-l"
                    data-dismiss="modal"
                    disabled={!passwordFormValid}
                    onClick={() => {
                      updateUserPassword(personId, newUserPassword, () => {
                        clearNewPasswordState();
                        setIsEdit(true);
                        helper.showModal("#editUserModal");
                      });
                    }}
                  >
                    <i className="fal fa-check"></i>
                    {t("common.finish", "Finish")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Delete User Modal */}
        {isDelete && (
          <div
            className="modal fade"
            data-backdrop="static"
            data-keyboard="false"
            id="deleteUserModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="deleteUserModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-md"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="deleteUserModalLabel">
                    <i className="fal fa-user fa-lg c-purple"></i>
                    {t(
                      "user.userComponent.deleteUserModal.title",
                      "Deactivate User"
                    )}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      clearComponentShow();
                    }}
                  >
                    <i className="fal fa-times"></i>
                  </button>
                </div>

                <div className="modal-body">
                  <h2 className="info-title-upp-case-xl icon-custom-top-center">
                    <span className="icon-custom is-xl icon-triangle-red-white"></span>
                    {t(
                      "user.userComponent.deleteUserModal.attention",
                      "Attention"
                    )}
                  </h2>
                  <div className="info-message text-center">
                    <p>
                      {t(
                        "user.userComponent.deleteUserModal.question",
                        "Are you sure you want to deactivate user"
                      )}
                    </p>
                    <p className="prime-text">
                      {firstName} {lastName} ?
                    </p>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-success ico-l"
                    data-dismiss="modal"
                    onClick={() =>
                      deleteUser(personId, () => {
                        clearState();
                        clearComponentShow();
                      })
                    }
                  >
                    <i className="fal fa-check"></i> {t("common.yes", "Yes")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger ico-l"
                    data-dismiss="modal"
                    onClick={() => {
                      clearComponentShow();
                    }}
                  >
                    <i className="fal fa-times"></i> {t("common.no", "No")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    activeUser: state.activeUser,
    page: state.page,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getActiveUser() {
    if (sessionStorage.getItem("user_id")) {
      dispatch(
        mutations.getActiveUser(JSON.parse(sessionStorage.getItem("user_id")))
      );
    }
  },
  getAllRoles() {
    dispatch(mutations.getAllRoles());
  },
  createUser(
    username,
    password,
    firstName,
    lastName,
    email,
    phone,
    roleId,
    salonId,
    genderId,
    localeId,
    cb
  ) {
    let user = {
      Username: username,
      PasswordHash: password,
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      Phone: phone,
      RoleId: roleId,
      SalonId: salonId,
      GenderId: genderId,
      LocaleId: localeId,
    };
    cb();
    dispatch(mutations.createUser(user));
  },
  saveUser(
    personId,
    firstName,
    lastName,
    email,
    phone,
    roleId,
    salonId,
    statusId,
    genderId,
    localeId,
    cb
  ) {
    let user = {
      PersonId: personId,
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      Phone: phone,
      RoleId: roleId,
      SalonId: salonId,
      StatusId: statusId,
      GenderId: genderId,
      LocaleId: localeId,
    };
    cb();
    dispatch(mutations.saveUser(user));
  },
  updateUserPassword(personId, password, cb) {
    let user = {
      PersonId: personId,
      PasswordHash: password,
    };
    dispatch(mutations.updateUserPassword(user, cb));
  },
  deleteUser(personId, cb) {
    let user = {
      PersonId: personId,
    };
    cb();
    dispatch(mutations.deleteUser(user));
  },
  getAllSalons() {
    dispatch(mutations.getAllSalons());
  },
});

const ConnectedUser = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserComponent);

export default ConnectedUser;
