import React, { useState, Component, useEffect } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  ExcelExport,
  ExcelExportColumn
} from "@progress/kendo-react-excel-export";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ConnectedNewItemButtonV2 } from "../NewItemButtonV2";
import { TimeColumn } from "../TimeColumn";

import * as helper from "../HelperRepository";

import { ActionButtonsColumn } from "./ActionButtonsColumn";
import { useTranslation, Translation } from "react-i18next";

const VisitListComponent = ({
  visits,
  getActiveUser,
  getVisitsPaged,
  // getVisitsPagedForSalon,
  totalCount,
  getVisitsForExport,
  setParentState,
  setVisitDate,
  clearParentState,
  getAllHairstylistWorkingTimes,
  hairstylists,
  setIsEdit,
  setIsCreate,
  setIsDelete,
  activeUser,
  pageState
}) => {
  const { t, i18n } = useTranslation();

  const [search, setSearch] = useState("");
  const [exporting, setExporting] = useState(false);
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 10 });
  const [rolesMap, setRolesMap] = useState(() => {
    return helper.createMapObject();
  });
  const debounceGetVisitsPaged = React.useCallback(
    debounce(getVisitsPaged, 700),
    []
  );
  let _export;
  const kendoExport = (searchValue, totalCount) => {
    var innerExport = _export;
    getVisitsForExport(searchValue, totalCount, sort, visits => {
      innerExport.save(visits);
    });
  };

  let gridPDFExport;
  const exportPDF = (searchValue, totalCount) => {
    var innerExport = gridPDFExport;
    getVisitsForExport(searchValue, totalCount, sort, visits => {
      innerExport.save(visits, pdfExportDone);
    });

    setExporting(true);
  };

  const pdfExportDone = () => {
    setExporting(false);
  };

  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add("grid-view");

    if (!activeUser || !activeUser.personId) {
      getActiveUser();
    }
    return () => {
      document.getElementsByTagName("html")[0].classList.remove("grid-view");
    };
  }, []);

  useEffect(() => {
    setPage({ skip: (pageState - 1) * 10, take: 10 });
  }, [pageState]);

  // useEffect(() => {
  //   // getVisitsPaged(search, page.skip / 10 + 1, sort);
  //   getVisitsPaged(search, page.skip / 10 + 1, [
  //     {
  //       field: "startTime",
  //       dir: "desc"
  //     }
  //   ]);
  // }, []);

  useEffect(() => {
    if (
      rolesMap.get("Admin") ||
      rolesMap.get("Salon admin") ||
      rolesMap.get("Hairstylist")
    ) {
      if (rolesMap.get("Admin")) {
        debounceGetVisitsPaged(search, page.skip / 10 + 1, [
          {
            field: "startTime",
            dir: "desc"
          }
        ]);
      } else {
        if (rolesMap.get("Salon admin")) {
          // getVisitsPagedForSalon(
          debounceGetVisitsPaged(
            search,
            // activeUser.userRoles[0].externalId,
            page.skip / 10 + 1,
            [
              {
                field: "startTime",
                dir: "desc"
              }
            ]
          );
        }
      }
    }
  }, [rolesMap]);

  useEffect(() => {
    setRolesMap(helper.mapForRole(activeUser));
  }, [activeUser]);

  function debounce(func, wait) {
    let timeout;
    return function(...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  const grid = (
    <Grid
      data={visits}
      pageable={{
        buttonCount: 3,
        info: true,
        type: "numeric",
        previousNext: true
      }}
      skip={page.skip}
      take={page.take}
      onPageChange={event => {
        setPage({ skip: event.page.skip, take: event.page.take });

        if (rolesMap.get("Admin")) {
          debounceGetVisitsPaged(search, event.page.skip / 10 + 1, sort);
        } else {
          if (rolesMap.get("Salon admin")) {
            // getVisitsPagedForSalon(
            debounceGetVisitsPaged(
              search,
              // activeUser.userRoles[0].externalId,
              event.page.skip / 10 + 1,
              sort
            );
          }
        }
      }}
      total={totalCount}
      sortable
      sort={sort}
      onSortChange={e => {
        setSort(e.sort);

        if (rolesMap.get("Admin")) {
          debounceGetVisitsPaged(search, page.skip / 10 + 1, e.sort);
        } else {
          if (rolesMap.get("Salon admin")) {
            // getVisitsPagedForSalon(
            debounceGetVisitsPaged(
              search,
              // activeUser.userRoles[0].externalId,
              page.skip / 10 + 1,
              e.sort
            );
          }
        }
      }}
      style={{ height: "410px" }}
      className="table-header-purple"
    >
      <GridColumn
        field="customerFullName"
        title={helper.i18nMultiFallbackKey(
          "visit.visitListComponent.grid.client.title",
          "Client"
        )}
        className="full-name-cell"
        headerClassName="full-name-cell"
      />
      <GridColumn
        field="salon.name"
        title={helper.i18nMultiFallbackKey(
          "visit.visitListComponent.grid.shop.title",
          "Shop"
        )}
        className="salon-name-cell"
        headerClassName="salon-name-cell"
        cell={props => <SalonColumn {...props} />}
      />
      <GridColumn
        field="hairstylistFullName"
        title={helper.i18nMultiFallbackKey(
          "visit.visitListComponent.grid.provider.title",
          "Provider"
        )}
        className="hairstylist-full-name-cell"
        headerClassName="hairstylist-full-name-cell"
        cell={props => <HairstylistColumn {...props} />}
      />
      <GridColumn
        field="status.itemName"
        title={t("visit.visitListComponent.grid.status.title", "Status")}
        className="status-cell"
        headerClassName="status-cell"
        cell={props => <StatusColumn {...props} />}
      />
      <GridColumn
        field="service.itemName"
        title={t(
          "visit.visitListComponent.grid.serviceType.title",
          "Service type"
        )}
        className="status-cell"
        headerClassName="status-cell"
        cell={props => <ServiceListColumn {...props} />}
      />
      <GridColumn
        field="startTime"
        title={t("visit.visitListComponent.grid.startTime.title", "Start Time")}
        className="time-cell"
        headerClassName="time-cell"
        cell={props => (
          <TimeColumn
            time={props.dataItem.startTime}
            mobileTitle={t(
              "visit.visitListComponent.grid.startTime.mobileTitle",
              "Start time:"
            )}
          />
        )}
      />
      <GridColumn
        field="endTime"
        title={t("visit.visitListComponent.grid.endTime.title", "End Time")}
        className="time-cell"
        headerClassName="time-cell"
        cell={props => (
          <TimeColumn
            time={props.dataItem.endTime}
            mobileTitle={t(
              "visit.visitListComponent.grid.endTime.mobileTitle",
              "End time:"
            )}
          />
        )}
      />
      {/* <GridColumn
        field="endTime"
        title="End Time"
        className="time-cell"
        headerClassName="time-cell"
        cell={props => <EndTimeColumn {...props} />}
      /> */}
      <GridColumn
        field="description"
        title={t(
          "visit.visitListComponent.grid.description.title",
          "Description"
        )}
        className="description-cell"
        headerClassName="description-cell"
      />
      <GridColumn
        field="Actions"
        title={t("common.actions", "Actions")}
        cell={props => (
          <ActionButtonsColumn
            setParentState={setParentState}
            setVisitDate={setVisitDate}
            getAllHairstylistWorkingTimes={getAllHairstylistWorkingTimes}
            hairstylists={hairstylists}
            setIsEdit={setIsEdit}
            setIsCreate={setIsCreate}
            setIsDelete={setIsDelete}
            {...props}
          />
        )}
        className="actions-cell two-md-bttns"
        headerClassName="actions-cell two-md-bttns"
      />
    </Grid>
  );

  return (
    <>
      <div className="table-rounded-border table-headlabel-responsive-md visit-table">
        <header>
          <div className="button-container">
            <ConnectedNewItemButtonV2
              clearParentState={clearParentState}
              setIsCreate={setIsCreate}
            />
            <button
              type="button"
              className="btn btn-gray-outline-transp ripple-gray btn-sm ico-l ico-red"
              onClick={() => exportPDF(search, totalCount)}
              disabled={exporting}
            >
              <i className="fal fa-file-pdf"></i>{" "}
              <span className="mobile-hide-550">
                {t("common.exportTo", "Export to")}{" "}
              </span>
              PDF
            </button>
            <button
              type="button"
              className="btn btn-gray-outline-transp ripple-gray btn-sm ico-l ico-green"
              onClick={() => kendoExport(search, totalCount)}
            >
              <i className="fal fa-file-excel"></i>{" "}
              <span className="mobile-hide-550">
                {t("common.exportTo", "Export to")}{" "}
              </span>
              Excel
            </button>
          </div>
          <div className="search-holder">
            <input
              type="text"
              id="inputEmail"
              className="form-control"
              placeholder={t("common.searchPlaceholder", "Search...")}
              value={search}
              onChange={event => {
                setPage({ skip: 0, take: 10 });
                setSearch(event.target.value);

                if (rolesMap.get("Admin")) {
                  debounceGetVisitsPaged(event.target.value, 1, sort);
                } else {
                  if (rolesMap.get("Salon admin")) {
                    // getVisitsPagedForSalon(
                    debounceGetVisitsPaged(
                      event.target.value,
                      // activeUser.userRoles[0].externalId,
                      1,
                      sort
                    );
                  }
                }
              }}
            ></input>
            <button type="button" className="btn ico-btn-search">
              <i className="fal fa-search"></i>
            </button>
          </div>
          <div className="actions-holder"></div>
        </header>

        <ExcelExport
          ref={exporter => (_export = exporter)}
          fileName={`Visits${new Date().getTime()}.xlsx`}
        >
          <ExcelExportColumn
            field="customerFullName"
            title={helper.i18nMultiFallbackKey(
              "visit.visitListComponent.grid.client.title",
              "Client"
            )}
            width={150}
          />
          <ExcelExportColumn
            field="salon.name"
            title={helper.i18nMultiFallbackKey(
              "visit.visitListComponent.grid.shop.title",
              "Shop"
            )}
            width={150}
          />
          <ExcelExportColumn
            field="hairstylistFullName"
            title={helper.i18nMultiFallbackKey(
              "visit.visitListComponent.grid.provider.title",
              "Provider"
            )}
            width={350}
          />
          <ExcelExportColumn
            field="status.itemName"
            title={t("visit.visitListComponent.grid.status.title", "Status")}
            width={150}
          />
          <ExcelExportColumn
            field="service.itemName"
            title={t(
              "visit.visitListComponent.grid.serviceType.title",
              "Service type"
            )}
            width={150}
          />
          <ExcelExportColumn
            field="startTime"
            title={t(
              "visit.visitListComponent.grid.startTime.title",
              "Start Time"
            )}
            width={150}
          />
          <ExcelExportColumn
            field="endTime"
            title={t("visit.visitListComponent.grid.endTime.title", "End Time")}
            width={150}
          />
          <ExcelExportColumn
            field="description"
            title={t(
              "visit.visitListComponent.grid.description.title",
              "Description"
            )}
            width={350}
          />
        </ExcelExport>
        {grid}
        <GridPDFExport
          ref={pdfExport => (gridPDFExport = pdfExport)}
          fileName={`Visits${new Date().getTime()}.pdf`}
          margin="1cm"
        >
          <GridColumn
            field="customerFullName"
            title={helper.i18nMultiFallbackKey(
              "visit.visitListComponent.grid.client.title",
              "Client"
            )}
            headerClassName="contact-cell"
          />
          <GridColumn
            field="salon.name"
            title={helper.i18nMultiFallbackKey(
              "visit.visitListComponent.grid.shop.title",
              "Shop"
            )}
            headerClassName="contact-cell"
          />
          <GridColumn
            field="hairstylistFullName"
            title={helper.i18nMultiFallbackKey(
              "visit.visitListComponent.grid.provider.title",
              "Provider"
            )}
            headerClassName="contact-cell"
          />
          <GridColumn
            field="status.itemName"
            title={t("visit.visitListComponent.grid.status.title", "Status")}
            headerClassName="contact-cell"
          />
          <GridColumn
            field="service.itemName"
            title={t(
              "visit.visitListComponent.grid.serviceType.title",
              "Service type"
            )}
            headerClassName="contact-cell"
          />
          <GridColumn
            field="startTime"
            title={t(
              "visit.visitListComponent.grid.startTime.title",
              "Start Time"
            )}
            className="last-name-cell"
            headerClassName="last-name-cell"
          />
          <GridColumn
            field="endTime"
            title={t("visit.visitListComponent.grid.endTime.title", "End Time")}
            className="last-name-cell"
            headerClassName="last-name-cell"
          />
          <GridColumn
            field="description"
            title={t(
              "visit.visitListComponent.grid.description.title",
              "Description"
            )}
            className="username-cell"
            headerClassName="username-cell"
          />
          {grid}
        </GridPDFExport>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    activeUser: state.activeUser,
    visits: state.visits,
    totalCount: state.totalCount,
    hairstylists: state.hairstylists,
    setParentState: ownProps.setParentState,
    setParentSearch: ownProps.setParentSearch,
    setVisitDate: ownProps.setVisitDate,
    setIsEdit: ownProps.setIsEdit,
    setIsCreate: ownProps.setIsCreate,
    setIsDelete: ownProps.setIsDelete,
    clearParentState: ownProps.clearParentState,
    pageState: state.page
  };
};
const mapDispatchToProps = dispatch => ({
  getActiveUser() {
    if (sessionStorage.getItem("user_id")) {
      dispatch(
        mutations.getActiveUser(JSON.parse(sessionStorage.getItem("user_id")))
      );
    }
  },
  getVisitsPaged(searchValue, page, sort) {
    const customSetSort = sort => {
      let result = [];
      sort.forEach(element => {
        switch (element.field) {
          case "hairstylistFullName":
            result.push({
              field: "Hairstylist.Person.FirstName",
              dir: element.dir
            });
            result.push({
              field: "Hairstylist.Person.LastName",
              dir: element.dir
            });
            break;
          case "customerFullName":
            result.push({
              field: "Customer.Person.FirstName",
              dir: element.dir
            });
            result.push({
              field: "Customer.Person.LastName",
              dir: element.dir
            });
            break;
          case "status.itemName":
            result.push({
              field: "Status.ItemName",
              dir: element.dir
            });
            break;
          default:
            result.push(element);
            break;
        }
      });

      return result;
    };

    let customSort = customSetSort(sort);
    dispatch(mutations.getVisitsPaged(searchValue, page, customSort));
  },
  // getVisitsPagedForSalon(searchValue, salonId, page, sort) {
  //   const customSetSort = sort => {
  //     let result = [];
  //     sort.forEach(element => {
  //       switch (element.field) {
  //         case "hairstylistFullName":
  //           result.push({
  //             field: "Hairstylist.Person.FirstName",
  //             dir: element.dir
  //           });
  //           result.push({
  //             field: "Hairstylist.Person.LastName",
  //             dir: element.dir
  //           });
  //           break;
  //         case "customerFullName":
  //           result.push({
  //             field: "Customer.Person.FirstName",
  //             dir: element.dir
  //           });
  //           result.push({
  //             field: "Customer.Person.LastName",
  //             dir: element.dir
  //           });
  //           break;
  //         case "status.itemName":
  //           result.push({
  //             field: "Status.ItemName",
  //             dir: element.dir
  //           });
  //           break;
  //         default:
  //           result.push(element);
  //           break;
  //       }
  //     });

  //     return result;
  //   };

  //   let customSort = customSetSort(sort);
  //   dispatch(
  //     mutations.getVisitsPagedForSalon(searchValue, salonId, page, customSort)
  //   );
  // },
  getVisitsForExport(searchValue, totalCount, sort, cb) {
    dispatch(mutations.getVisitsForExport(searchValue, totalCount, sort, cb));
  },
  getAllHairstylistWorkingTimes(salonHairstylistId) {
    dispatch(mutations.getAllHairstylistWorkingTimes(salonHairstylistId));
  }
});

export const ConnectedVisitList = connect(
  mapStateToProps,
  mapDispatchToProps
)(VisitListComponent);

class CustomerColumn extends Component {
  render() {
    return (
      <td className="full-name-cell">
        <Translation>
          {(t, { i18n }) => (
            <span className="mobile-view-title">
              {helper.i18nMultiFallbackKey(
                "visit.visitListComponent.grid.client.mobileTitle",
                "Client:"
              )}
            </span>
          )}
        </Translation>
        <span>
          {this.props.dataItem.customer.firstName}{" "}
          {this.props.dataItem.customer.lastName}
        </span>
      </td>
    );
  }
}

class HairstylistColumn extends Component {
  render() {
    return (
      <td className="hairstylist-full-name-cell">
        <Translation>
          {(t, { i18n }) => (
            <span className="mobile-view-title" style={{ fontWeight: 400 }}>
              {helper.i18nMultiFallbackKey(
                "visit.visitListComponent.grid.provider.mobileTitle",
                "Provider:"
              )}
            </span>
          )}
        </Translation>
        <span>
          {this.props.dataItem.hairstylist.firstName}{" "}
          {this.props.dataItem.hairstylist.lastName}
        </span>
      </td>
    );
  }
}

class StatusColumn extends Component {
  render() {
    return (
      <td className="status-cell">
        <Translation>
          {(t, { i18n }) => (
            <span className="mobile-view-title">
              {t("visit.visitListComponent.grid.status.mobileTitle", "Status:")}
            </span>
          )}
        </Translation>
        <span>{this.props.dataItem.status.itemName}</span>
      </td>
    );
  }
}

class ServiceColumn extends Component {
  render() {
    return (
      <td className="status-cell">
        <Translation>
          {(t, { i18n }) => (
            <span className="mobile-view-title">
              {t(
                "visit.visitListComponent.grid.serviceType.mobileTitle",
                "Service type:"
              )}
            </span>
          )}
        </Translation>
        <span>{this.props.dataItem.serviceType.serviceTypeName}</span>
      </td>
    );
  }
}

class ServiceListColumn extends Component {
  render() {
    return (
      <td className="status-cell">
        <Translation>
          {(t, { i18n }) => (
            <span className="mobile-view-title v-al-mid">
              {t(
                "visit.visitListComponent.grid.serviceTypes.mobileTitle",
                "Service types:"
              )}
            </span>
          )}
        </Translation>
        {this.props.dataItem.visitServiceTypes.map((v, i) => {
          return (
            <span
              key={v.visitServiceTypeId}
              className="date-label txt-truncate v-al-mid"
            >
              {v.serviceType.serviceTypeName}{" "}
            </span>
          );
        })}
      </td>
    );
  }
}

class SalonColumn extends Component {
  render() {
    return (
      <td className="salon-name-cell">
        {/* <span className="mobile-view-title">Salon:</span> */}
        <Translation>
          {(t, { i18n }) => (
            <span>{this.props.dataItem.salonCustomer.salon.name}</span>
          )}
        </Translation>
      </td>
    );
  }
}

class EndTimeColumn extends Component {
  render() {
    return (
      <td className="time-cell picker-cell" style={{ overflow: "visible" }}>
        <div className="date-time-picker open-picker-content">
          <button type="button" className="date-time-header">
            <span className="title-date mr-2 text-nowrap">
              2019-12-16 <i className="fal fa-calendar-times"></i>
            </span>{" "}
            <span className="title-time text-nowrap">
              15:00 <i className="fal fa-clock"></i>
            </span>
          </button>
          <div className="date-time-content flex-wrap">
            <div className="legend-bar">
              <span className="reserved">Rezervisano</span>
              <span className="free">Slobodno</span>
              <span className="choosed">Izabrano</span>
            </div>
            <div className="time-item selected">09:00</div>
            <div className="time-item selected">09:30</div>
            <div className="time-item">10:00</div>
            <div className="time-item selected">10:30</div>
            <div className="time-item">11:00</div>
            <div className="time-item">11:30</div>
            <div className="time-item">12:00</div>
            <div className="time-item">12:30</div>
            <div className="time-item">13:30</div>
            <div className="time-item">13:30</div>
            <div className="time-item">14:00</div>
            <div className="time-item choosed">14:30</div>
            <div className="time-item">15:00</div>
            <div className="time-item selected">15:30</div>
            <div className="time-item selected">16:00</div>
            <div className="time-item">16:30</div>
            <div className="time-item">17:00</div>
            <div className="time-item">17:30</div>
            <div className="time-item">18:00</div>
            <div className="time-item selected">18:30</div>
            <div className="time-item">19:00</div>
            <div className="time-item">19:30</div>
            <div className="time-item">20:00</div>
            <div className="time-item">20:30</div>
          </div>
        </div>
      </td>
    );
  }
}
