import React, { useState, Component, useEffect } from "react";
import { connect } from "react-redux";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { ServiceTypeDurationColumn } from "../../ServiceTypeDurationColumn";
import * as helper from "../../HelperRepository";
import * as mutations from "../../../store/mutations";
import { useTranslation, withTranslation } from "react-i18next";

const HairstylistServiceTypeListComponent = ({
  selectedSalonHairstylist,
  hairstylistServiceTypes,
  hairstylistServiceTypeTotalCount,
  setParentState,
  getHairstylistServiceTypesPaged,
  salons,
  pageState,
  getAvailableServiceTypes,
  setIsActivate,
  setIsDeactivate,
}) => {
  const { t, i18n } = useTranslation();

  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 10 });

  useEffect(() => {
    if (selectedSalonHairstylist.hairstylistId) {
      getHairstylistServiceTypesPaged(
        selectedSalonHairstylist,
        page.skip / 10 + 1,
        sort
      );
    }
  }, [
    getHairstylistServiceTypesPaged,
    page,
    selectedSalonHairstylist,
    sort,
    getAvailableServiceTypes,
  ]);

  useEffect(() => {
    setPage({ skip: (pageState - 1) * 10, take: 10 });
  }, [pageState]);

  const ActionButtonsColumnWithTranslation = withTranslation()(
    ActionButtonsColumn
  );

  const getSelectedSalon = () => {
    return salons.find(
      (salon) => salon.salonId === selectedSalonHairstylist.salonId
    );
  };

  const grid = (
    <Grid
      data={hairstylistServiceTypes}
      pageable={{
        buttonCount: 3,
        info: true,
        type: "numeric",
        previousNext: true,
      }}
      skip={page.skip}
      take={page.take}
      onPageChange={(event) => {
        setPage({ skip: event.page.skip, take: event.page.take });
        getHairstylistServiceTypesPaged(
          selectedSalonHairstylist,
          event.page.skip / 10 + 1,
          sort
        );
      }}
      total={hairstylistServiceTypeTotalCount}
      sortable
      sort={sort}
      onSortChange={(e) => {
        setSort(e.sort);
        getHairstylistServiceTypesPaged(
          selectedSalonHairstylist,
          page.skip / 10 + 1,
          e.sort
        );
      }}
      style={{ height: "410px" }}
      className="table-header-purple"
    >
      <GridColumn
        field="serviceType.serviceTypeName"
        title={t(
          "hairstylist.hairstylistServiceTypeListComponent.grid.service.title",
          "Service"
        )}
        className="start-time-cell"
        headerClassName="start-time-cell"
      />
      <GridColumn
        field="serviceType.serviceTypeDuration"
        title={t(
          "hairstylist.hairstylistServiceTypeListComponent.grid.duration.title",
          "Duration"
        )}
        className="end-time-cell"
        headerClassName="end-time-cell"
        cell={(props) => (
          <ServiceTypeDurationColumn
            serviceType={props.dataItem.serviceType}
            selectedSalonHairstylist={selectedSalonHairstylist}
            mobileTitle="Duration:"
          />
        )}
      />
      <GridColumn
        field="serviceType.serviceTypePrice"
        title={t(
          "hairstylist.hairstylistServiceTypeListComponent.grid.price.title",
          "Price [rsd]"
        )}
        className="end-time-cell"
        headerClassName="end-time-cell"
      />
      <GridColumn
        field="Actions"
        title={t("common.actions", "Actions")}
        cell={(props) => (
          <ActionButtonsColumnWithTranslation
            setParentState={setParentState}
            setIsDeactivate={setIsDeactivate}
            {...props}
          />
        )}
        headerClassName="actions-cell two-bttns"
      />
    </Grid>
  );

  return (
    <>
      <div className="table-rounded-border table-headlabel-responsive-md hairstylist-table">
        <header>
          <div className="button-container">
            <button
              type="button"
              className="btn btn-purple-outline btn-sm ico-l"
              // data-toggle="modal"
              // data-target={`#activateHairstylistServiceTypeModal`}
              onClick={() => {
                getAvailableServiceTypes(
                  selectedSalonHairstylist.salonId,
                  selectedSalonHairstylist.hairstylistId
                );
                setIsActivate(true);
                helper.showModal("#activateHairstylistServiceTypeModal");
              }}
            >
              <i className="fal fa-plus"></i>
              {t(
                "hairstylist.hairstylistServiceTypeListComponent.activate",
                "Activate"
              )}
              <span className="mobile-hide-550">
                {t(
                  "hairstylist.hairstylistServiceTypeListComponent.serviceType",
                  "Service type"
                )}
              </span>
            </button>
          </div>
        </header>
        {grid}
      </div>
    </>
  );
};
class ActionButtonsColumn extends Component {
  render() {
    return (
      <td className="actions-cell two-bttns">
        <div className="button-container">
          <button
            type="button"
            className="btn btn-outline-danger btn-sm ico-l"
            // data-toggle="modal"
            // data-target="#deleteHairstylistServiceTypeModal"
            onClick={() => {
              this.props.setParentState(this.props.dataItem);
              this.props.setIsDeactivate(true);
              helper.showModal("#deleteHairstylistServiceTypeModal");
            }}
            // disabled={this.props.dataItem.hasDependents}
          >
            <i className="fal fa-ban"></i>{" "}
            {this.props.t("common.deactivate", "Deactivate")}
          </button>
        </div>
      </td>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    hairstylistServiceTypes: state.hairstylistServiceTypes,
    hairstylistServiceTypeTotalCount: state.hairstylistServiceTypeTotalCount,
    salons: state.salons,
    pageState: state.hairstylistServiceTypePage,
    selectedSalonHairstylist: ownProps.selectedSalonHairstylist,
    setParentState: ownProps.setParentState,
    setIsActivate: ownProps.setIsActivate,
    setIsDeactivate: ownProps.setIsDeactivate,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getHairstylistServiceTypesPaged(selectedSalonHairstylist, page, sort) {
    dispatch(
      mutations.getHairstylistServiceTypesPaged(
        {
          hairstylistId: selectedSalonHairstylist.hairstylistId,
          salonId: selectedSalonHairstylist.salonId,
        },
        page,
        sort
      )
    );
  },
  getAvailableServiceTypes(salonId, hairstylistId) {
    dispatch(mutations.getAvailableServiceTypes(salonId, hairstylistId));
  },
});

export const ConnectedHairstylistServiceTypeList = connect(
  mapStateToProps,
  mapDispatchToProps
)(HairstylistServiceTypeListComponent);
