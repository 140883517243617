import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../../store/mutations";
import { withTranslation } from "react-i18next";

class SalonServiceTypeCreate extends Component {
  state = {
    name: "",
    price: "",
    duration: "",
    description: "",
  };

  handlePriceChange = (event) => {
    this.setState({ price: event.target.value || "" });
  };

  handleNameChange = (event) => {
    this.setState({ name: event.target.value || "" });
  };

  handleDurationChange = (event) => {
    this.setState({ duration: event.target.value || "" });
  };

  handleDescriptionChange = (event) => {
    this.setState({ description: event.target.value || "" });
  };
  render() {
    return (
      <div
        className="modal fade"
        data-backdrop="static"
        data-keyboard="false"
        id="createSalonServiceTypeModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="createSalonServiceTypeModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="createSalonServiceTypeModalLabel">
                <i className="fal fa-user fa-lg c-purple" />{" "}
                {helper.i18nMultiFallbackKey(
                  "salon.salonServiceTypeCreate.createSalonServiceTypeModal.title",
                  "Create Shop Service Type"
                )}{" "}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fal fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <form className="form needs-validation" noValidate>
                <input type="hidden" value="something" />
                <div className="form-group">
                  <label htmlFor="createSalonServiceTypeModalName">
                    {this.props.t(
                      "salon.salonServiceTypeCreate.createSalonServiceTypeModal.nameLabel",
                      "Name"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="createSalonServiceTypeModalName"
                    autoComplete="new-username"
                    placeholder={this.props.t(
                      "salon.salonServiceTypeCreate.createSalonServiceTypeModal.namePlaceholder",
                      "Please enter service type name"
                    )}
                    value={this.state.name}
                    onChange={this.handleNameChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="createSalonServiceTypeModalPrice">
                    {this.props.t(
                      "salon.salonServiceTypeCreate.createSalonServiceTypeModal.priceLabel",
                      "Price"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="createSalonServiceTypeModalPrice"
                    autoComplete="new-username"
                    placeholder={this.props.t(
                      "salon.salonServiceTypeCreate.createSalonServiceTypeModal.pricePlaceholder",
                      "Please enter service type price"
                    )}
                    value={this.state.price}
                    onChange={this.handlePriceChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="createSalonServiceTypeModalDuration">
                    {this.props.t(
                      "salon.salonServiceTypeCreate.createSalonServiceTypeModal.durationLabel",
                      "Duration"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="createSalonServiceTypeModalDuration"
                    autoComplete="new-username"
                    placeholder={this.props.t(
                      "salon.salonServiceTypeCreate.createSalonServiceTypeModal.durationPlaceholder",
                      "Please enter service type duration"
                    )}
                    value={this.state.duration}
                    onChange={this.handleDurationChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="createSalonServiceTypeModalDescription">
                    {this.props.t(
                      "salon.salonServiceTypeCreate.createSalonServiceTypeModal.descriptionLabel",
                      "Description"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="createSalonServiceTypeModalDescription"
                    autoComplete="new-username"
                    placeholder={this.props.t(
                      "salon.salonServiceTypeCreate.createSalonServiceTypeModal.descriptionPlaceholder",
                      "Please enter service type description"
                    )}
                    value={this.state.description}
                    onChange={this.handleDescriptionChange}
                    required
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger ico-l"
                data-dismiss="modal"
              >
                <i className="fal fa-ban" />{" "}
                {this.props.t("common.cancel", "Cancel")}
              </button>
              <button
                type="button"
                className="btn btn-outline-success ico-l"
                data-dismiss="modal"
                onClick={() => {
                  let serviceTypeForCreate = {
                    SalonId: this.props.selectedSalon.salonId,
                    ServiceTypeName: this.state.name,
                    ServiceTypePrice: this.state.price,
                    ServiceTypeDuration: this.state.duration,
                    Description: this.state.description,
                  };

                  this.props.createServiceType(serviceTypeForCreate, () =>
                    this.props.clearParentState()
                  );
                }}
              >
                <i className="fal fa-check" />{" "}
                {this.props.t("common.finish", "Finish")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPropsFreeSlots = (state, ownProps) => {
  return {
    selectedSalon: ownProps.selectedSalon,
    clearParentState: ownProps.clearParentState,
  };
};

const mapDispatchToPropsFreeSlots = (dispatch) => ({
  createServiceType(serviceTypeForCreate, cb) {
    cb();
    dispatch(mutations.createServiceType(serviceTypeForCreate));
  },
});

export const ConnectedSalonServiceTypeCreate = withTranslation()(
  connect(
    mapStateToPropsFreeSlots,
    mapDispatchToPropsFreeSlots
  )(SalonServiceTypeCreate)
);
