import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { history } from "../store/history";
import logo from "../style/images/Hairstylist-Logo-03-Side-BackOffice.svg";
import * as mutations from "../store/mutations";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as helper from "./HelperRepository";
import { useTranslation } from "react-i18next";
import { LoadingSpinnerConnect } from "./LoadingSpinner";
import { FormErrors } from "./FormErrors";
import { ToolTipOnRoundedString } from "./ToolTipOnRoundedString";
import { showImages } from "../../../package.json"


const NavigationBarComponent = ({
  activeUser,
  title,
  logout,
  renewToken,
  setPath,
  path,
  updateUserPassword,
  updateUserLanguage,
}) => {
  const { t, i18n } = useTranslation();

  const [rolesMap, setRolesMap] = useState(() => {
    return helper.createMapObject();
  });
  const [isChangeUserPassword, setIsChangeUserPassword] = useState(false);
  const [newUserPassword, setNewUserPassword] = useState("");
  const [confirmNewUserPassword, setConfirmNewUserPassword] = useState("");
  const [newUserPasswordValid, setNewUserPasswordValid] = useState("");
  const [
    confirmNewUserPasswordValid,
    setConfirmNewUserPasswordValid,
  ] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [passwordFormValid, setPasswordFormValid] = useState("");
  const [currentLang, setCurrentLang] = useState("");

  const passwordLength = 6;

  const clearNewPasswordState = () => {
    setIsChangeUserPassword(false);

    setNewUserPassword("");
    setConfirmNewUserPassword("");
    setNewUserPasswordValid("");
    setConfirmNewUserPasswordValid("");
    setFormErrors({
      newUserPassword: "",
      confirmNewUserPassword: "",
    });
  };

  const errorClassInput = (error) => {
    if (error) {
      return error.length === 0 ? "" : "is-invalid";
    }
    return "";
  };

  const errorClassLabel = (error) => {
    if (error) {
      return error.length === 0 ? "" : "text-danger";
    }
    return "";
  };

  const handleUserInput = (e) => {
    switch (e.target.id) {
      case "newUserPassword":
        setNewUserPassword(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "confirmNewUserPassword":
        setConfirmNewUserPassword(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      default:
        break;
    }
  };

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;

    let fieldNewUserPasswordValid = newUserPasswordValid;
    let fieldConfirmNewUserPasswordValid = confirmNewUserPasswordValid;

    switch (fieldName) {
      case "newUserPassword":
        fieldNewUserPasswordValid = value.length >= passwordLength;
        setNewUserPasswordValid(fieldNewUserPasswordValid);
        fieldValidationErrors.newUserPassword = fieldNewUserPasswordValid
          ? ""
          : `is too short (minimum ${passwordLength} characters)`;
        break;
      case "confirmNewUserPassword":
        fieldConfirmNewUserPasswordValid =
          newUserPassword === value && value.length >= passwordLength;
        setConfirmNewUserPasswordValid(fieldConfirmNewUserPasswordValid);
        if (value.length < passwordLength) {
          fieldValidationErrors.confirmNewUserPassword = `is too short (minimum ${passwordLength} characters)`;
        } else if (newUserPassword !== value) {
          fieldValidationErrors.confirmNewUserPassword = `must be the same as new password`;
        } else {
          fieldValidationErrors.confirmNewUserPassword = ``;
        }
        break;
      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    setPasswordFormValid(
      fieldNewUserPasswordValid && fieldConfirmNewUserPasswordValid
    );
  };

  let logoutTimeoutId;
  const logoutTimeoutValue = +process.env.LOGOUT_TIMEOUT;
  let warnTimeoutId;
  const warnTimeoutValue = +process.env.WARN_TIMEOUT;
  let renewTimeoutId;
  let renewTimeoutValue = 1;

  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];

  useEffect(() => {
    if (
      helper.isExpired(
        helper.getExpirationDate(sessionStorage.getItem("token"))
      )
    ) {
      fullLogout();
    }

    window.jQuery("#logout-alert").hide();

    if (warnTimeoutValue >= 5) {
      for (var i in events) {
        window.addEventListener(events[i], resetLogoutTimeout);
      }
      setLogoutTimeout();
    }
    var secondsToExpiration = Math.floor(
      (helper.getExpirationDate(sessionStorage.getItem("token")) -
        new Date().getTime()) /
        1000
    );
    renewTimeoutValue = secondsToExpiration;

    if (sessionStorage.getItem("token")) {
      renewTokenAndSetTimeout();
    }

    return () => {
      fullLogout();
    };
  }, []);

  const renewTokenAndSetTimeout = () => {
    renewToken();
    if (renewTimeoutId) clearTimeout(renewTimeoutId);
    renewTimeoutId = setTimeout(() => {
      renewTokenAndSetTimeout();
    }, (renewTimeoutValue - 5) * 1000);
  };

  const setLogoutTimeout = () => {
    warnTimeoutId = setTimeout(warnLogout, warnTimeoutValue * 1000);
  };

  const resetLogoutTimeout = () => {
    window.jQuery("#logout-alert").slideUp(500).hide(500);
    clearLogoutTimeout();
    setLogoutTimeout();
  };

  const clearLogoutTimeout = () => {
    if (warnTimeoutId) clearTimeout(warnTimeoutId);
    if (logoutTimeoutId) clearTimeout(logoutTimeoutId);
  };

  const warnLogout = () => {
    window.jQuery("#logout-alert").slideDown(500).show(500);
    logoutTimeoutId = setTimeout(() => {
      fullLogout();
    }, logoutTimeoutValue * 1000);
  };

  const destroyLogout = () => {
    clearLogoutTimeout();

    for (var i in events) {
      window.removeEventListener(events[i], resetLogoutTimeout);
    }
  };

  const fullLogout = () => {
    if (warnTimeoutValue > 5) {
      destroyLogout();
      if (renewTimeoutId) clearTimeout(renewTimeoutId);
    }
    logout();
  };

  const changeLanguage = (lng) => {
    updateUserLanguage(activeUser.personId, lng, () => {});
    i18n.changeLanguage(lng);
    setCurrentLang(lng);
  };

  useEffect(() => {
    setRolesMap(helper.mapForRole(activeUser));
    if (activeUser.personId) {
      i18n.changeLanguage(activeUser.locale.itemName);
      setCurrentLang(activeUser.locale.itemName);
    }
  }, [activeUser]);

  const getShopTitle = () => {
    if (rolesMap.get("Admin")) {
      title = helper.i18nMultiFallbackKey(
        "navigationBarComponent.listItemComponentName.shopPlural",
        "Shops"
      );
    } else {
      if (rolesMap.get("Salon admin")) {
        title = helper.i18nMultiFallbackKey(
          "navigationBarComponent.listItemComponentName.shopSingular",
          "Shop"
        );
      }
    }

    return title;
  };

  return (
    <>
      <header>
        <div
          className="alert alert-warning alert-dismissible fade show"
          id="logout-alert"
          style={{ display: "none" }}
        >
          <button type="button" className="close" data-dismiss="alert">
            x
          </button>
          <strong>Warning! </strong>
          {`You will be logged out automatically in ${logoutTimeoutValue}
        seconds.`}
        </div>
        <LoadingSpinnerConnect />
        <ToastContainer />
        <nav className="navbar navbar-expand-lg fixed-top navbar-light flex-sm-nowrap">
          <a className="navbar-brand mr-0" href="#">
            <img
              className="logo-hairstylist"
              src={logo}
              alt="Hairstylist logo"
              onClick={() => {
                if (
                  helper.isExpired(
                    helper.getExpirationDate(sessionStorage.getItem("token"))
                  )
                ) {
                  logout();
                } else {
                  setPath("home");
                  sessionStorage.setItem("path", JSON.stringify("home"));
                }
              }}
            ></img>
          </a>
          <h1 className="order-maxw-sm-2">{title}</h1>
          <button
            type="button"
            className="navbar-toggler border-0 collapsed"
            data-toggle="offcanvas"
          >
            <span className="icon-bar top-bar"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </button>

          <div
            className="navbar-collapse offcanvas-collapse"
            data-toggle="offcanvas"
            id="navbar"
          >
            <ul className="navbar-nav">
              <ListItemComponent
                path="home"
                id="homeTab"
                name={t(
                  "navigationBarComponent.listItemComponentName.home",
                  "Home"
                )}
                statePath={path}
                setPath={setPath}
                logout={fullLogout}
              />
              <ListItemComponent
                path="customer"
                id="clientsTab"
                name={helper.i18nMultiFallbackKey(
                  "navigationBarComponent.listItemComponentName.client",
                  "Clients"
                )}
                //name={JSON.parse(sessionStorage.getItem("clientType"))}
                statePath={path}
                setPath={setPath}
                logout={fullLogout}
              />
              {rolesMap.get("Salon admin") && (
                <ListItemComponent
                  path="hairstylist"
                  id="providersTab"
                  name={helper.i18nMultiFallbackKey(
                    "navigationBarComponent.listItemComponentName.provider",
                    "Providers"
                  )}
                  // name={JSON.parse(sessionStorage.getItem("serviceProvider"))}
                  statePath={path}
                  setPath={setPath}
                  logout={fullLogout}
                />
              )}
              {rolesMap.get("Salon admin") && (
                <ListItemComponent
                  path="salon"
                  id="shopsTab"
                  name={getShopTitle()}
                  statePath={path}
                  setPath={setPath}
                  logout={fullLogout}
                />
              )}
              <ListItemComponent
                path="visit"
                id="visitsTab"
                name={t(
                  "navigationBarComponent.listItemComponentName.visit",
                  "Visits"
                )}
                statePath={path}
                setPath={setPath}
                logout={fullLogout}
              />
              {showImages ?
                <ListItemComponent
                path="images"
                id="imagesTab"
                name={t(
                  "navigationBarComponent.listItemComponentName.images",
                  "Images"
                )}
                statePath={path}
                setPath={setPath}
                logout={fullLogout}
              /> : <></>
              }
              {rolesMap.get("Admin") && (
                <li className={"nav-item dropdown"}>
                  <a
                    className="nav-link dropdown-toggle fade-in"
                    href="#"
                    id="dropdownAdministration"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {t(
                      "navigationBarComponent.listItemComponentName.administration",
                      "Administration"
                    )}
                  </a>
                  <div
                    className="dropdown-menu dropdown-menu-right d-block-force-maxw991 admin-group"
                    aria-labelledby="dropdownAdministration"
                  >
                    {/* <DropDownItemComponent
                      path="category"
                      name={t(
                        "navigationBarComponent.listItemComponentName.category",
                        "Categories"
                      )}
                      statePath={path}
                      setPath={setPath}
                      logout={fullLogout}
                    /> */}
                    <DropDownItemComponent
                      path="generalization"
                      id="generalizationsTab"
                      name={t(
                        "navigationBarComponent.listItemComponentName.generalization",
                        "Generalization"
                      )}
                      statePath={path}
                      setPath={setPath}
                      logout={fullLogout}
                    />
                    <DropDownItemComponent
                      path="user"
                      id="usersTab"
                      name={t(
                        "navigationBarComponent.listItemComponentName.user",
                        "Users"
                      )}
                      statePath={path}
                      setPath={setPath}
                      logout={fullLogout}
                    />
                    <DropDownItemComponent
                      path="role"
                      id="rolesTab"
                      name={t(
                        "navigationBarComponent.listItemComponentName.role",
                        "Roles"
                      )}
                      statePath={path}
                      setPath={setPath}
                      logout={fullLogout}
                    />
                  </div>
                </li>
              )}
              {/* <ListItemComponent
                path="images"
                id="imagesTab"
                name={t(
                  "navigationBarComponent.listItemComponentName.images",
                  "Images"
                )}
                statePath={path}
                setPath={setPath}
                logout={fullLogout}
              /> */}
              <li
                className={
                  "nav-item dropdown has-user-role" +
                  (activeUser.firstName === "" && activeUser.lastName === ""
                    ? " empty-name"
                    : "")
                }
              >
                <a
                  className="nav-link dropdown-toggle fade-in"
                  href="#"
                  id="dropdown01"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <ToolTipOnRoundedString
                    user={activeUser}
                    circleStringColor={"bg-blue"}
                  />
                </a>
                {/* <span className="user-role">
                  {activeUser.userRoles?.length
                    ? activeUser.userRoles[0].role.name
                    : ""}
                </span> */}
                <div
                  className="dropdown-menu dropdown-menu-right d-block-force-maxw991"
                  aria-labelledby="dropdown01"
                >

                  <section className="user-avatar">
                    <div className="pos-rel">
                      <div className="avatar-circle">AE</div>
                      <button className="add-photo"><i className="fal fa-camera"></i></button>
                      </div>
                    <div className="avatar-info">
                   <span className="role"><label>Role:</label> <b>Salon Admin</b></span>
                   <span className="user"><label>User:</label> <b>Admin Esmeralda</b></span>
                   <span className="email"><label>Email:</label> <b>admin@esmeralda.rs</b></span>
                    </div>
                  </section>

                  <section className="languages">
                  <h5 className="section-title"><span>Languages</span></h5>
                  <div className="row">
                  <div className="col-6 pr-1">
                  <a
                    className={`btn btn-gray-outline-transp dropdown-item ${currentLang === "en-US" ? 'active' : ''}`}
                    href="#"
                    id="englishUSLocale"
                    onClick={() => changeLanguage("en-US")}
                  ><span className="flag eng"></span>
                    {t("navigationBarComponent.selectEnglishUS", "English")}
                  </a>
                  </div>
                  <div className="col-6 pl-1">
                  <a
                    className={`btn btn-gray-outline-transp dropdown-item ${currentLang === "sr-Latn" ? 'active' : ''}`}
                    href="#"
                    id="serbianLatinLocale"
                    onClick={() => changeLanguage("sr-Latn")}
                  ><span className="flag srp"></span>
                    {t(
                      "navigationBarComponent.selectSerbianLatn",
                      "Serbian"
                    )}
                  </a></div>
                    </div>
                  </section>

                  <section className="settings">
                  <h5 className="section-title"><span>Settings</span></h5>
                  <a
                    className="btn btn-purple-outline dropdown-item cng-pass ico-l"
                    href="#"
                    id="changePassword"
                    onClick={() => {
                      setIsChangeUserPassword(true);
                      helper.showModal("#changeUserPasswordModal");
                    }}
                  >
                    <i className="fal fa-sync"></i>
                    {t(
                      "navigationBarComponent.changePassword",
                      "Change password"
                    )}
                  </a>

                  <a
                    className="btn btn-pink-outline dropdown-item logout-link ico-l"
                    href=""
                    onClick={fullLogout}
                  >
                    <i className="fal fa-sign-out"></i>
                    {t("navigationBarComponent.logOut", "Log out")}
                  </a>
                </section>

                <section className="privacy">
                <h5 className="section-divider"></h5>
            <div><a href="#">Privacy Policy</a><b className="mid-dot">&middot;</b><a href="#">Terms of Service</a></div>

                </section>

                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      {/* Change User Password */}
      {isChangeUserPassword && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="changeUserPasswordModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="changeUserPasswordModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="changeUserPasswordModalLabel">
                  <i className="fal fa-user fa-lg c-purple"></i>{" "}
                  {t(
                    "navigationBarComponent.changeUserPasswordModal.title",
                    "Change User Password"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    clearNewPasswordState();
                  }}
                >
                  <i className="fal fa-times"></i>
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <div className="form-group">
                    <label
                      htmlFor="newUserPassword"
                      className={`${errorClassLabel(
                        formErrors.newUserPassword
                      )}`}
                    >
                      {t(
                        "navigationBarComponent.changeUserPasswordModal.newUserPasswordLabel",
                        "New user password"
                      )}
                    </label>
                    <input
                      type="password"
                      className={`form-control ${errorClassInput(
                        formErrors.newUserPassword
                      )}`}
                      id="newUserPassword"
                      placeholder={t(
                        "navigationBarComponent.changeUserPasswordModal.newUserPasswordPlaceholder",
                        "New user password"
                      )}
                      value={newUserPassword}
                      onChange={handleUserInput}
                      required
                    ></input>
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"newUserPassword"}
                        valueToShow={"New user password"}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="confirmNewUserPassword"
                      className={`${errorClassLabel(
                        formErrors.confirmNewUserPassword
                      )}`}
                    >
                      {t(
                        "navigationBarComponent.changeUserPasswordModal.confirmNewUserPasswordLabel",
                        "Confirm new user password"
                      )}
                    </label>
                    <input
                      type="password"
                      className={`form-control ${errorClassInput(
                        formErrors.confirmNewUserPassword
                      )}`}
                      id="confirmNewUserPassword"
                      placeholder={t(
                        "navigationBarComponent.changeUserPasswordModal.confirmNewUserPasswordPlaceholder",
                        "Confirm new user password"
                      )}
                      value={confirmNewUserPassword}
                      onChange={handleUserInput}
                      required
                    ></input>
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"confirmNewUserPassword"}
                        valueToShow={"Confirm new user password"}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-primary ico-l"
                  data-dismiss="modal"
                  onClick={() => {
                    clearNewPasswordState();
                  }}
                >
                  <i className="fal fa-sync"></i>
                  {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  disabled={!passwordFormValid}
                  onClick={() => {
                    updateUserPassword(
                      activeUser.personId,
                      newUserPassword,
                      () => {
                        clearNewPasswordState();
                      }
                    );
                  }}
                >
                  <i className="fal fa-check"></i>
                  {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

class ListItemComponent extends Component {
  render() {
    return (
      <li
        id={this.props.id}
        className={
          "nav-item " +
          (this.props.statePath === this.props.path ? "active" : "")
        }
      >
        <a
          className="nav-link"
          onClick={() => {
            if (
              helper.isExpired(
                helper.getExpirationDate(sessionStorage.getItem("token"))
              )
            ) {
              this.props.logout();
            } else {
              this.props.setPath(this.props.path);
              sessionStorage.setItem("path", JSON.stringify(this.props.path));
            }
          }}
        >
          {this.props.name}
        </a>
      </li>
    );
  }
}

class DropDownItemComponent extends Component {
  render() {
    return (
      <div
        id={this.props.id}
        className={
          "nav-link dropdown-item " +
          (this.props.statePath === this.props.path ? "active" : "")
        }
        onClick={() => {
          if (
            helper.isExpired(
              helper.getExpirationDate(sessionStorage.getItem("token"))
            )
          ) {
            this.props.logout();
          } else {
            this.props.setPath(this.props.path);
            sessionStorage.setItem("path", JSON.stringify(this.props.path));
          }
        }}
      >
        {this.props.name}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeUser: state.activeUser,
    path: state.path,
    title: ownProps.title,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout() {
      sessionStorage.removeItem("shopType");
      sessionStorage.removeItem("serviceProvider");
      sessionStorage.removeItem("clientType");

      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user_id");
      sessionStorage.removeItem("path");
      dispatch(mutations.processAuthenticateUser(mutations.NOT_AUTHENTICATED));
      history.push("/login");
    },
    renewToken() {
      dispatch(mutations.requestRenewToken());
    },
    setPath(path) {
      dispatch(mutations.setPath(path));
    },
    updateUserPassword(personId, password, cb) {
      let user = {
        PersonId: personId,
        PasswordHash: password,
      };
      dispatch(mutations.updateUsersOwnPassword(user, cb));
    },
    updateUserLanguage(personId, language, cb) {
      let user = {
        PersonId: personId,
        Language: language,
      };
      dispatch(mutations.updateUserLanguage(user, cb));
    },
  };
};

export const ConnectedNavigationBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationBarComponent);
