import React, { useState, Component, useEffect } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { TimeColumn } from "../TimeColumn";
import * as moment from "moment";
import { useTranslation } from "react-i18next";

const HairstylistWorkingTimeListComponent = ({
  selectedSalonHairstylist,
  getHairstylistWorkingTimePaged,
  hairstylistWorkingTimes,
  hairstylistWorkingTimeTotalCount,
  clearParentState,
  setParentState,
  setCurrentDate,
  setToGetFreeSlots,
  getHairstylistWorkingTimeIsDateCreated,
}) => {
  const { t, i18n } = useTranslation();

  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 10 });

  useEffect(() => {
    if (selectedSalonHairstylist.salonHairstylistId) {
      getHairstylistWorkingTimePaged(
        selectedSalonHairstylist.salonHairstylistId,
        page.skip / 10 + 1,
        sort
      );
    }
  }, [getHairstylistWorkingTimePaged, page, selectedSalonHairstylist, sort]);

  const grid = (
    <Grid
      data={hairstylistWorkingTimes}
      pageable={{
        buttonCount: 3,
        info: true,
        type: "numeric",
        previousNext: true,
      }}
      skip={page.skip}
      take={page.take}
      onPageChange={(event) => {
        setPage({ skip: event.page.skip, take: event.page.take });
        getHairstylistWorkingTimePaged(
          selectedSalonHairstylist.salonHairstylistId,
          event.page.skip / 10 + 1,
          sort
        );
      }}
      total={hairstylistWorkingTimeTotalCount}
      sortable
      sort={sort}
      onSortChange={(e) => {
        setSort(e.sort);
        getHairstylistWorkingTimePaged(
          selectedSalonHairstylist.salonHairstylistId,
          page.skip / 10 + 1,
          e.sort
        );
      }}
      style={{ height: "410px" }}
      className="table-header-purple"
    >
      <GridColumn
        field="startTime"
        title={t(
          "hairstylist.hairstylistWorkingTimeListComponent.grid.starttime.title",
          "Start time"
        )}
        className="start-time-cell"
        headerClassName="start-time-cell"
        cell={(props) => (
          <TimeColumn
            time={props.dataItem.startTime}
            mobileTitle={t(
              "hairstylist.hairstylistWorkingTimeListComponent.grid.starttime.mobileTitle",
              "Start time:"
            )}
          />
        )}
      />
      <GridColumn
        field="endTime"
        title={t(
          "hairstylist.hairstylistWorkingTimeListComponent.grid.endtime.title",
          "End time"
        )}
        className="end-time-cell"
        headerClassName="end-time-cell"
        cell={(props) => (
          <TimeColumn
            time={props.dataItem.endTime}
            mobileTitle={t(
              "hairstylist.hairstylistWorkingTimeListComponent.grid.endtime.mobileTitle",
              "End time:"
            )}
          />
        )}
      />
      <GridColumn
        field="Actions"
        title={t("common.actions", "Actions")}
        cell={(props) => (
          <ActionButtonsColumn
            setParentState={setParentState}
            setToGetFreeSlots={setToGetFreeSlots}
            setCurrentDate={setCurrentDate}
            {...props}
          />
        )}
        headerClassName="actions-cell two-bttns"
      />
    </Grid>
  );

  return (
    <>
      <div className="table-rounded-border table-headlabel-responsive-md hairstylist-table">
        <header>
          <div className="button-container">
            <button
              type="button"
              className="btn btn-purple-outline btn-sm ico-l"
              data-toggle="modal"
              data-target={`#createHairstylistWorkingTimeModal`}
              onClick={() => {
                let nowDate = moment(new Date()).format("YYYY-MM-DD");
                setToGetFreeSlots(true);
                setCurrentDate(nowDate);
                getHairstylistWorkingTimeIsDateCreated(
                  selectedSalonHairstylist.salonHairstylistId,
                  nowDate
                );
              }}
            >
              <i className="fal fa-plus"></i>
              {t("hairstylist.hairstylistWorkingTimeListComponent.new", "New")}
              <span className="mobile-hide-550">
                {" "}
                {t(
                  "hairstylist.hairstylistWorkingTimeListComponent.workingTime",
                  "Working time"
                )}
              </span>
            </button>
          </div>
        </header>
        {grid}
      </div>
    </>
  );
};
class ActionButtonsColumn extends Component {
  render() {
    return (
      <td className="actions-cell two-bttns">
        <div className="button-container">
          <button
            type="button"
            className="btn btn-outline-success btn-sm ico-l"
            data-toggle="modal"
            data-target="#editHairstylistWorkingTimeModal"
            onClick={() => {
              this.props.setToGetFreeSlots(true);
              this.props.setCurrentDate(this.props.dataItem.startTime);
              this.props.setParentState(this.props.dataItem);
            }}
            disabled={this.props.dataItem.hasDependents}
          >
            <i className="fal fa-edit"></i>
            {this.props.t("common.edit", "Edit")}
          </button>
          <button
            type="button"
            className="btn btn-outline-danger btn-sm ico-l"
            data-toggle="modal"
            data-target="#deleteHairstylistWorkingTimeModal"
            onClick={() => {
              this.props.setParentState(this.props.dataItem);
            }}
            disabled={this.props.dataItem.hasDependents}
          >
            <i className="fal fa-ban"></i>
            {this.props.t("common.deactivate", "Deactivate")}
          </button>
        </div>
      </td>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    hairstylistWorkingTimes: state.hairstylistWorkingTimes,
    hairstylistWorkingTimeTotalCount: state.hairstylistWorkingTimeTotalCount,
    selectedSalonHairstylist: ownProps.selectedSalonHairstylist,
    setParentState: ownProps.setParentState,
    clearParentState: ownProps.clearParentState,
    setCurrentDate: ownProps.setCurrentDate,
    setToGetFreeSlots: ownProps.setToGetFreeSlots,
    getHairstylistWorkingTimeIsDateCreated:
      ownProps.getHairstylistWorkingTimeIsDateCreated,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getHairstylistWorkingTimePaged(salonHairstylistId, page, sort) {
    dispatch(
      mutations.getHairstylistWorkingTimePaged(salonHairstylistId, page, sort)
    );
  },
});

export const ConnectedHairstylistWorkingTimeListComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(HairstylistWorkingTimeListComponent);
