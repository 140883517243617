import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ConnectedCategoryList } from "./CategoryList";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import * as mutations from "../../store/mutations";
import { FormErrors } from "../FormErrors";
import * as moment from "moment";
import * as helper from "../HelperRepository";
import { ActionButtonsColumnCategoryItems } from "./ActiveButtonsCategoryItemsList";
import { useTranslation } from "react-i18next";

const CategoryItemListComponent = ({
  selectedCategory,
  categoryItemsFiltered,
  getActiveUser,
  activeUser,
  totalCount,
  setParentState,
  clearParentState,
  setIsEdit,
  setIsCreate,
  setIsDelete,
  setIsActivate
}) => {
  const { t } = useTranslation();
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 10 });

  document.getElementsByTagName("html")[0].classList.remove("page-signin");

  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add("grid-view");

    if (!activeUser || !activeUser.personId) {
      getActiveUser();
    }
    return () => {
      document.getElementsByTagName("html")[0].classList.remove("grid-view");
    };
  }, []);

  const grid = (
    <Grid
      data={categoryItemsFiltered}
      pageable={{
        buttonCount: 3,
        info: true,
        type: "numeric",
        previousNext: true
      }}
      skip={page.skip}
      take={page.take}
      onPageChange={event => {
        setPage({ skip: event.page.skip, take: event.page.take });
      }}
      total={totalCount}
      sortable
      sort={sort}
      onSortChange={e => {
        setSort(e.sort);
      }}
      style={{ height: "410px" }}
      className="table-header-purple"
      onRowClick={e => {
        clearParentState();
      }}
    >
      <GridColumn
        field="itemName"
        title={t(
          "category.categoryItemListComponent.grid.itemName.title",
          "Name"
        )}
        className="username-cell"
        headerClassName="username-cell"
      />
      <GridColumn
        field="itemValue"
        title={t(
          "category.categoryItemListComponent.grid.itemValue.title",
          "Value"
        )}
        className="username-cell"
        headerClassName="username-cell"
      />
      <GridColumn
        field="parentCategoryItemId"
        title={t(
          "category.categoryItemListComponent.grid.parentCategoryItemId.title",
          "Parent Id"
        )}
        className="username-cell"
        headerClassName="username-cell"
      />
      <GridColumn
        field="parentCategoryItem.itemName"
        title={t(
          "category.categoryItemListComponent.grid.parentCategoryItemName.title",
          "Parent Name"
        )}
        className="username-cell"
        headerClassName="username-cell"
      />

      <GridColumn
        field="itemDescription"
        title={t(
          "category.categoryItemListComponent.grid.itemDescription.title",
          "Description"
        )}
        className="username-cell"
        headerClassName="username-cell"
      />
      <GridColumn
        field="Actions"
        title={t("common.actions", "Actions")}
        cell={props => (
          <ActionButtonsColumnCategoryItems
            setParentState={setParentState}
            setIsEdit={setIsEdit}
            setIsCreate={setIsCreate}
            setIsDelete={setIsDelete}
            setIsActivate={setIsActivate}
            {...props}
          />
        )}
        headerClassName="actions-cell two-bttns"
      />
    </Grid>
  );
  return (
    <>
      <div className="table-rounded-border table-headlabel-responsive-md salon-table">
        <header>
          <div className="button-container">
            <button
              type="button"
              className="btn btn-outline-success btn-sm ico-l"
              onClick={() => {
                setIsCreate(true);
                helper.showModal(`#createCategoryItemModal`);
              }}
            >
              <i className="fal fa-plus-square"></i>{" "}
              {t(
                "category.categoryItemListComponent.addCategoryItem.title",
                "Add Category Item"
              )}
            </button>
          </div>
        </header>
        {grid}
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    activeUser: state.activeUser,
    totalCount: state.totalCount,
    selectedCategory: ownProps.selectedCategory,
    categoryItemsFiltered: ownProps.categoryItemsFiltered,
    setParentState: ownProps.setParentState,
    clearParentState: ownProps.clearParentState,
    setIsEdit: ownProps.setIsEdit,
    setIsCreate: ownProps.setIsCreate,
    setIsDelete: ownProps.setIsDelete,
    setIsActivate: ownProps.setIsActivate
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getActiveUser() {
      if (sessionStorage.getItem("user_id")) {
        dispatch(
          mutations.getActiveUser(JSON.parse(sessionStorage.getItem("user_id")))
        );
      }
    }
  };
};

export const ConnectedCategoryItemList = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryItemListComponent);
