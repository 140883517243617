import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";

import * as helper from "../HelperRepository";
import { useTranslation } from "react-i18next";

const SalonsDropDownComponent = ({
  salons,
  salonId,
  setSalonId,
  setServiceTypes,
  setSalonTimeSlot,
  getAllSalons,
}) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getAllSalons();
  }, []);

  useEffect(() => {
    if (helper.isArray(salons) && !helper.isEmpty(salons)) {
      if (salonId !== "") {
        const salon = salons.find((salon) => salon.salonId === salonId);
        setServiceTypes(salon.serviceTypes);
        setSalonTimeSlot(salon.salonTimeSlot);
      } else {
        setServiceTypes([]);
        setSalonTimeSlot(30);
      }
    } else {
      setServiceTypes(salons.serviceTypes);
      setSalonTimeSlot(salons.salonTimeSlot);
    }
  }, [salons]);

  if (helper.isArray(salons)) {
    return (
      <div className="custom-select-box">
        <select
          className="form-control"
          value={salonId}
          onChange={(event) => {
            setSalonId(event.target.value);
          }}
          required
        >
          <option key={""} value={""}>
            {helper.i18nMultiFallbackKey(
              "dropdowns.SalonsDropDownComponent.label",
              "Select shop"
            )}
          </option>
          {salons.map((r) => {
            return (
              <option key={r.salonId} value={r.salonId}>
                {r.name}
              </option>
            );
          })}
        </select>
      </div>
    );
  } else {
    return (
      <div className="custom-select-box">
        <select
          className="form-control"
          value={salonId}
          onChange={(event) => {
            setSalonId(event.target.value);
          }}
          required
        >
          <option key={""} value={""}>
            {helper.i18nMultiFallbackKey(
              "dropdowns.SalonsDropDownComponent.loadingShops",
              "Loading shops..."
            )}
          </option>
          <option key={salons.salonId} value={salons.salonId}>
            {salons.name}
          </option>
        </select>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    salons: state.salons,
    salonId: ownProps.salonId,
    setSalonId: ownProps.setSalonId,
    setServiceTypes: ownProps.setServiceTypes,
    setSalonTimeSlot: ownProps.setSalonTimeSlot,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllSalons() {
    dispatch(mutations.getAllSalons());
  },
});

export const ConnectedSalonsDropDown = connect(
  mapStateToProps,
  mapDispatchToProps
)(SalonsDropDownComponent);
