import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { withTranslation } from "react-i18next";
import * as helper from "../HelperRepository";

class HairstylistsDropDownComponent extends Component {
  componentDidMount() {
    this.props.getAllHairstylists();
  }

  render() {
    let test = [];

    if (this.props.salonId !== "") {
      test = this.props.hairstylists.filter((hairstylist) => {
        let foundList = hairstylist.salonHairstylists.filter(
          (shs) => shs.salonId === this.props.salonId
        );
        return foundList.length > 0;
      });
    }
    if (test.length > 0) {
      return (
        <div className="custom-select-box">
          <select
            className="form-control"
            value={this.props.hairstylistId}
            onChange={(event) => {
              if (event.target.value !== "") {
                let hs = this.props.hairstylists.filter(
                  (hairstylist) => hairstylist.personId === event.target.value
                )[0];

                let shs = hs.salonHairstylists.filter(
                  (salonHairstylist) =>
                    salonHairstylist.salonId === this.props.salonId
                )[0];

                this.props.getAllHairstylistWorkingTimes(
                  shs.salonHairstylistId
                );
              }
              this.props.setHairstylistId(event.target.value);
            }}
            required
          >
            <option key={""} value={""}>
              {helper.i18nMultiFallbackKey("dropdowns.hairstylistsDropDownComponent.label", "Select provider")}
            </option>
            {/* {this.props.hairstylists.map(r => { */}
            {test.map((r) => {
              return (
                <option key={r.personId} value={r.personId}>
                  {r.firstName} {r.lastName}
                </option>
              );
            })}
          </select>
        </div>
      );
    } else {
      return (
        <div className="custom-select-box">
          <select
            className="form-control"
            value={this.props.hairstylistId}
            onChange={(event) =>
              this.props.setHairstylistId(event.target.value)
            }
            required
          >
            <option key={""} value={""}>
              {helper.i18nMultiFallbackKey("dropdowns.hairstylistsDropDownComponent.label", "Select provider")}
            </option>
          </select>
        </div>
      );
    }
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    hairstylists: state.hairstylists,
    hairstylistId: ownProps.hairstylistId,
    setHairstylistId: ownProps.setHairstylistId,
    salonId: ownProps.salonId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllHairstylists() {
    dispatch(mutations.getAllHairstylistsForDropdown());
  },
  getAllHairstylistWorkingTimes(salonHairstylistId) {
    dispatch(mutations.getAllHairstylistWorkingTimes(salonHairstylistId));
  },
});

export const ConnectedHairstylistsDropDown = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(HairstylistsDropDownComponent)
);
