import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import * as Helper from "../HelperRepository";
import { useTranslation } from "react-i18next";

const HairstylistServiceTypesCheckboxLilstComponentV3 = ({
  visitId,
  hairstylistId,
  salonId,
  selectedServiceTypeList,
  hairstylistServiceTypes,
  setSelectedServiceTypeList,
}) => {
  const { t, i18n } = useTranslation();

  const [selectedServiceTypeMap, setSelectedServiceTypeMap] = useState(
    new Map()
  );
  const [selectedServicesNames, setSelectedServicesNames] = useState("");

  const updateMap = (k, v) => {
    setSelectedServiceTypeMap(new Map(selectedServiceTypeMap.set(k, v)));
  };

  useEffect(() => {
    console.log("--------------- promenjen hairstylistId", hairstylistId);
    if (hairstylistId) {
      setSelectedServiceTypeMap(new Map());
    }
  }, [hairstylistId]);

  useEffect(() => {
    if (selectedServiceTypeList.length > 0) {
      for (let selectedServiceType of selectedServiceTypeList) {
        if (selectedServiceType !== undefined) {
          updateMap(selectedServiceType.serviceTypeId, true);
        }
      }
    }
  }, [visitId]);

  useEffect(() => {
    let checkArray = [];
    let internalSelectedServiceNames = [];
    if (hairstylistServiceTypes.length > 0) {
      for (let key of selectedServiceTypeMap.keys()) {
        if (selectedServiceTypeMap.get(key) === true) {
          let foundValue = hairstylistServiceTypes.find((hst) => {
            return hst.serviceTypeId === key;
          });
          if (foundValue !== undefined) {
            checkArray.push(foundValue.serviceType);
            internalSelectedServiceNames.push(
              foundValue.serviceType.serviceTypeName
            );
          }
        }
      }
      setSelectedServicesNames(internalSelectedServiceNames.join(", "));
      setSelectedServiceTypeList([...Helper.cloneDeep(checkArray)]);
    }
  }, [hairstylistServiceTypes, selectedServiceTypeMap]);

  if (hairstylistServiceTypes.length > 0 && hairstylistId) {
    return (
      <>
        {/* <div className="list-group-item form-group">
          {hairstylistServiceTypes.map((r, i) => (
            <div className="checkbox" key={r.hairstylistServiceTypeId}>
              <label>
                <input
                  onChange={e => updateMap(r.serviceTypeId, e.target.checked)}
                  id={r.hairstylistServiceTypeId}
                  type="checkbox"
                  checked={selectedServiceTypeMap.get(r.serviceTypeId)}
                  value={selectedServiceTypeMap.get(r.serviceTypeId)}
                />{" "}
                {r.serviceType.serviceTypeName}
              </label>
            </div>
          ))}
        </div> */}
        <div className="form-group">
          <div className="dropdown">
            <button
              className="btn btn-gray-outline-transp dropdown-toggle btn-block ico-r text-left ico-rotate180 txt-truncate"
              type="button"
              id="dropdownMenu2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="title-label">
                {t(
                  "checkboxList.HairstylistServiceTypesCheckboxLilstComponent.title",
                  "Service types:"
                )}
              </span>{" "}
              <span className="value-label">{`${selectedServicesNames}`}</span>
              <i className="fal fa-chevron-down"></i>
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
              <div className="dropdown-item multi-checkboxes">
                {hairstylistServiceTypes.map((r, i) => (
                  <div className="checkbox" key={r.hairstylistServiceTypeId}>
                    <label className="checkbox-container">
                      <input
                        onChange={(e) =>
                          updateMap(r.serviceTypeId, e.target.checked)
                        }
                        id={r.hairstylistServiceTypeId}
                        type="checkbox"
                        checked={
                          selectedServiceTypeMap.get(r.serviceTypeId) || false
                        }
                        value={
                          selectedServiceTypeMap.get(r.serviceTypeId) || false
                        }
                      />
                      <span className="txt">
                        {" "}
                        {r.serviceType.serviceTypeName}
                      </span>
                      <span className="checkmark"></span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="form-group">
        <div className="dropdown">
          <button
            className="btn btn-gray-outline-transp dropdown-toggle btn-block ico-r text-left ico-rotate180"
            type="button"
            id="dropdownMenu2"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="title-label">
              {" "}
              {t(
                "checkboxList.HairstylistServiceTypesCheckboxLilstComponent.title",
                "Service types:"
              )}
            </span>{" "}
            <i className="fal fa-chevron-down"></i>
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenu2"></div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    hairstylistServiceTypes: ownProps.hairstylistServiceTypes,
    hairstylistId: ownProps.hairstylistId,
    visitId: ownProps.visitId,
    setSelectedServiceTypeList: ownProps.setSelectedServiceTypeList,
    selectedServiceTypeList: ownProps.selectedServiceTypeList,
    salonId: ownProps.salonId,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export const ConnectedHairstylistServiceTypesCheckboxLilstComponentV3 = connect(
  mapStateToProps,
  mapDispatchToProps
)(HairstylistServiceTypesCheckboxLilstComponentV3);
