/* #region CheckBox constants*/
export const SET_CHECK_BOX = "SET_CHECK_BOX";
/* #endregion*/

/* #region CheckBox mutations*/
export const setCheckBox = (checkedState) => ({
  type: SET_CHECK_BOX,
  checkedState,
});
/* #endregion*/
