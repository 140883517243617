import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { ConnectedVisitList } from "./VisitList";
import * as mutations from "../../store/mutations";
import { ConnectedSalonCustomersDropDown } from "../Dropdowns/SalonCustomersDropDown";
import { ConnectedSalonsDropDown } from "../Dropdowns/SalonsDropDown";
import { ConnectedHairstylistsDropDown } from "../Dropdowns/HairstylistsDropDown";
import { ConnectedHairstylistServiceTypesCheckboxLilstComponentV2 } from "../CheckboxList/HairstylistServiceTypesCheckboxListV2";
import { ConnectedHairstylistServiceTypesCheckboxLilstComponentV3 } from "../CheckboxList/HairstylistServiceTypesCheckboxListV3";
import { ConnectedCategoryItemsDropDown } from "../Dropdowns/CategoryItemsDropDown";
import { FormErrors } from "../FormErrors";
import { ConnectedFreeVisitSlotsPopupComponentV2 } from "./FreeVisitSlotPopupComponentV2";
import { ConnectedFreeVisitSlotsPopupComponentV3 } from "./FreeVisitSlotPopupComponentV3";

import { AutoComplete } from "primereact/autocomplete";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as helper from "../HelperRepository";
import { useTranslation } from "react-i18next";
import RequiredFieldsComponent from "../RequiredFieldsComponent";

const VisitComponent = ({
  activeUser,
  getActiveUser,
  getAllHairstylists,
  getAllSalons,
  getAllCustomers,
  createVisit,
  createVisitFromServiceTypeList,
  saveVisit,
  saveVisitFromServiceTypeList,
  deleteVisit,
  customers,
  getCustomers,
  clearCustomers,
  createCustomer,
  hairstylistWorkingTimes,
  getCustomersPaged,
  // getCustomersPagedForSalon,
}) => {
  const { t, i18n } = useTranslation();

  document.getElementsByTagName("html")[0].classList.remove("page-signin");

  const [description, setDescription] = useState("");
  const [hairstylistId, setHairstylistId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [salonCustomerId, setSalonCustomerId] = useState("");

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [salonId, setSalonId] = useState("");
  const [selectedSalon, setSelectedSalon] = useState("");
  const [serviceTypes, setServiceTypes] = useState("");
  const [statusId, setStatusId] = useState("");
  const [visitId, setVisitId] = useState("");
  const [serviceTypeId, setServiceTypeId] = useState("");
  const [selectedServiceTypeList, setSelectedServiceTypeList] = useState([]);
  const [visitDate, setVisitDate] = useState("");
  const [salonTimeSlot, setSalonTimeSlot] = useState("");
  const [autoCompleteSearchValue, setAutoCompleteSearchValue] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [registrationsNumber, setRegistrationsNumber] = useState("");
  const [genderId, setGenderId] = useState("");

  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isCustomerCreate, setIsCustomerCreate] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const [editSalonId, setEditSalonId] = useState("");
  const [editHairstylistId, setEditHairstylistId] = useState("");

  const [createSalonId, setCreateSalonId] = useState("");
  const [createHairstylistId, setCreateHairstylistId] = useState("");
  const [
    hairstylistServiceTypesForEdit,
    setHairstylistServiceTypesForEdit,
  ] = useState("");

  const [rolesMap, setRolesMap] = useState(() => {
    return helper.createMapObject();
  });

  useEffect(() => {
    setRolesMap(helper.mapForRole(activeUser));
    if (
      activeUser.userRoles &&
      activeUser.userRoles[0].role.name === "Salon admin"
    ) {
      setSalonId(activeUser.userRoles[0].externalId);
    }
  }, [activeUser]);

  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add("grid-view");

    if (!activeUser || !activeUser.personId) {
      getActiveUser();
    }
    getAllHairstylists();
    getAllSalons();
    getAllCustomers();
    return () => {
      document.getElementsByTagName("html")[0].classList.remove("grid-view");
    };
  }, []);

  const whoSetsStartTime = (value, setObject) => {
    setStartTime(value);
  };

  const createVisitSetStartTime = (value) => {
    whoSetsStartTime(value, "createVisitsetStartTime");
  };

  const editVisitSetStartTime = (value) => {
    whoSetsStartTime(value, "editVisitSetStartTime");
  };

  const whoSetsEndTime = (value, setObject) => {
    setEndTime(value);
  };

  const createVisitSetEndTime = (value) => {
    whoSetsEndTime(value, "createVisitSetEndTime");
  };

  const editVisitSetEndTime = (value) => {
    whoSetsEndTime(value, "editVisitSetEndTime");
  };

  const clearState = () => {
    setDescription("");
    setHairstylistId("");
    setCustomerId("");
    setSalonCustomerId("");
    whoSetsStartTime("", "clearState");
    whoSetsEndTime("", "clearState");
    setSalonId("");
    setSelectedSalon("");
    setStatusId("");
    setVisitId("");
    setServiceTypeId("");
    setSelectedServiceTypeList([]);
    setAutoCompleteSearchValue("");
    setVisitDate("");

    setFirstName("");
    setLastName("");
    setPhone("");
    setEmail("");
    setRegistrationsNumber("");
    setGenderId("");
    setHairstylistServiceTypesForEdit([]);
    // clearCustomers();
  };

  const setVisitState = (visit) => {
    setDescription(visit.description || "");
    setHairstylistId(visit.hairstylist.personId);
    setCustomerId(visit.salonCustomer.customer.personId);
    setSalonCustomerId(visit.salonCustomer.salonCustomerId);
    whoSetsStartTime(visit.startTime ? visit.startTime : "", "setVisitState");
    whoSetsEndTime(visit.endTime ? visit.endTime : "", "setVisitState");
    setSalonId(visit.salonCustomer.salon.salonId);
    setSelectedSalon(visit.salonCustomer.salon);
    setCreateHairstylistId(visit.hairstylist.personId);
    setCreateSalonId(visit.salonCustomer.salon.salonId);
    setEditHairstylistId(visit.hairstylist.personId);
    setEditSalonId(visit.salonCustomer.salon.salonId);
    setStatusId(visit.status.categoryItemId);
    setVisitId(visit.visitId);
    setServiceTypeId(visit.serviceTypeId);
    setSelectedServiceTypeList([
      ...helper.cloneDeep(findServiceTypeList(visit)),
    ]);
    setHairstylistServiceTypesForEdit([
      ...helper.cloneDeep(visit.hairstylist.hairstylistServiceTypeShortModels),
    ]);
    setAutoCompleteSearchValue(`${visit.customerFullName}`);
    setVisitDate(moment(visit.startTime).format("YYYY-MM-DD"));
  };

  const findServiceTypeList = (visit) => {
    let serviceTypeListLocal = [];

    visit.visitServiceTypes.forEach((v) => {
      // serviceTypeListLocal.push(v.serviceTypeId);
      serviceTypeListLocal.push(v.serviceType);
    });

    return serviceTypeListLocal;
  };

  const clearCheckboxSystem = () => {
    setIsEdit(false);
    setIsCreate(false);
    setIsDelete(false);
    setIsCustomerCreate(false);

    setFirstName("");
    setLastName("");
    setPhone("");
    setEmail("");
    setRegistrationsNumber("");
    setGenderId("");
  };

  const activeDates = () => {
    let dates = [];
    let futureDate = moment(new Date())
      .add(selectedSalon.customerSchedulePeriod, "days")
      .startOf("day")
      .toDate();
    let nowDate = moment(new Date()).startOf("day").toDate();

    for (let workingTime of hairstylistWorkingTimes) {
      let activeDate = moment(workingTime.startTime, "YYYY-MM-DD")
        .startOf("day")
        .toDate();
      if (activeDate <= futureDate && activeDate >= nowDate) {
        dates.push(activeDate);
      }
    }
    return [
      ...dates.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.date) - new Date(a.date);
      }),
    ];
  };

  const emptySetSalonId = () => {};

  const helperFunction = () => {
    clearState();
    setIsCustomerCreate(true);
    helper.showModal("#createCustomerInVisitModal");
  };

  const checkIfSameEditHairstylist = (newEditHairstylistId) => {
    if (editHairstylistId !== newEditHairstylistId) {
      setDescription("");
      setStatusId("");
      whoSetsStartTime("", "checkIfSameEditHairstylist");
      whoSetsEndTime("", "checkIfSameEditHairstylist");
      setSelectedServiceTypeList([]);
      setEditHairstylistId(newEditHairstylistId);
    }
  };

  const checkIfSameCreateHairstylist = (newCreateHairstylistId) => {
    if (createHairstylistId !== newCreateHairstylistId) {
      setDescription("");
      setStatusId("");
      whoSetsStartTime("", "checkIfSameCreateHairstylist");
      whoSetsEndTime("", "checkIfSameCreateHairstylist");
      setSelectedServiceTypeList([]);
      setCreateHairstylistId(newCreateHairstylistId);
    }
  };

  const checkIfSameServiceTypeList = (newServiceTypeList) => {
    if (!helper.isArrayEqual(selectedServiceTypeList, newServiceTypeList)) {
      setDescription("");
      whoSetsStartTime("", "checkIfSameServiceTypeList");
      whoSetsEndTime("", "checkIfSameServiceTypeList");
      setSelectedServiceTypeList(newServiceTypeList);
    }
  };

  return (
    <>
      <main role="main">
        <div className="container-fluid">
          <ConnectedVisitList
            setParentState={setVisitState}
            clearParentState={clearState}
            setVisitDate={setVisitDate}
            setIsEdit={setIsEdit}
            setIsCreate={setIsCreate}
            setIsDelete={setIsDelete}
          />
        </div>
      </main>

      <footer className="footer">
        <div className="container copyright">
          <span className="text-muted">
            Hairstylist &copy; Copyright {moment().format("YYYY")},{" "}
            {t("common.version", "Version:")} {process.env.VERSION}
          </span>
        </div>
      </footer>

      {/* Create Visit Modal */}
      {isCreate && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="createVisitModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="createVisitModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="createVisitModalLabel">
                  <i className="fal fa-cut fa-lg c-purple" />
                  {t(
                    "visit.visitComponent.createVisitModal.title",
                    "Create Visit"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    clearState();
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <input type="hidden" value="something" />
                  <div className="form-group has-light-background bg-gray-lighter rounded-lg pt-1 mt-n1">
                    <div className="row">
                      <div className="col-md-8">
                        <label
                          htmlFor="customerId"
                          className="d-block text-center text-sm-left required"
                        >
                          {helper.i18nMultiFallbackKey(
                            "visit.visitComponent.createVisitModal.typeClient",
                            "Start typing to select Client"
                          )}
                        </label>
                        <AutoComplete
                          field="name"
                          className="form-control-nested"
                          value={autoCompleteSearchValue}
                          onChange={(e) => {
                            setAutoCompleteSearchValue(e.value);
                            setCustomerId("");
                            if (e.value.value) {
                              setCustomerId(e.value.value);
                            }
                          }}
                          suggestions={customers.map((c) => {
                            return {
                              name: `${c.firstName} ${c.lastName}`,
                              value: c.personId,
                            };
                          })}
                          completeMethod={(e) => {
                            getCustomers(e.query);
                          }}
                        />
                      </div>
                      <div className="col-md-4 text-center text-md-right mt-3 mt-md-0">
                        <label className="d-none d-md-block">&nbsp;</label>
                        <button
                          type="button"
                          className="btn btn-outline-primary ico-l w-sm-100"
                          onClick={helperFunction}
                        >
                          <i className="fal fa-plus"></i>
                          {helper.i18nMultiFallbackKey(
                            "visit.visitComponent.createVisitModal.createClientButton",
                            "New Client"
                          )}
                        </button>
                      </div>
                      <div className="invalid-feedback">
                        {" "}
                        {helper.i18nMultiFallbackKey(
                          "visit.visitComponent.createVisitModal.clientInvalid",
                          "Please start typing to select your client."
                        )}
                      </div>
                    </div>
                  </div>

                  {customerId && (
                    <>
                      <div className="form-group">
                        <label htmlFor="createSalonId" className="required">
                          {helper.i18nMultiFallbackKey(
                            "visit.visitComponent.createVisitModal.shop",
                            "Shop"
                          )}
                        </label>
                        <ConnectedSalonCustomersDropDown
                          salonId={createSalonId}
                          setSelectedSalon={setSelectedSalon}
                          customerId={customerId}
                          setSalonId={setCreateSalonId}
                          setSalonCustomerId={setSalonCustomerId}
                          setServiceTypes={setServiceTypes}
                          setSalonTimeSlot={setSalonTimeSlot}
                          id="createSalonId"
                        />
                        <div className="invalid-feedback">
                          {helper.i18nMultiFallbackKey(
                            "visit.visitComponent.createVisitModal.shopInvalid",
                            "Please select your shop."
                          )}
                        </div>
                      </div>
                      {createSalonId && (
                        <>
                          <div className="form-group">
                            <label
                              htmlFor="createHairstylistId"
                              className="required"
                            >
                              {helper.i18nMultiFallbackKey(
                                "visit.visitComponent.createVisitModal.provider",
                                "Provider"
                              )}
                            </label>
                            <ConnectedHairstylistsDropDown
                              hairstylistId={createHairstylistId}
                              setHairstylistId={checkIfSameCreateHairstylist}
                              salonId={createSalonId}
                              id="createHairstylistId"
                            />
                            <div className="invalid-feedback">
                              {helper.i18nMultiFallbackKey(
                                "visit.visitComponent.createVisitModal.providerInvalid",
                                "Please select your provider."
                              )}
                            </div>
                          </div>
                          {createHairstylistId && (
                            <>
                              <div className="form-group">
                                <label
                                  htmlFor="hairstylistServiceTypeId"
                                  className="required"
                                >
                                  {t(
                                    "visit.visitComponent.createVisitModal.serviceType",
                                    "Service type"
                                  )}
                                </label>
                                <ConnectedHairstylistServiceTypesCheckboxLilstComponentV2
                                  setSelectedServiceTypeList={
                                    checkIfSameServiceTypeList
                                  }
                                  selectedServiceTypeList={
                                    selectedServiceTypeList
                                  }
                                  hairstylistId={createHairstylistId}
                                  salonId={createSalonId}
                                  visitId={visitId}
                                  isEdit={false}
                                  id="hairstylistServiceTypeId"
                                />
                                <div className="invalid-feedback">
                                  {t(
                                    "visit.visitComponent.createVisitModal.serviceTypeInvalid",
                                    "Please select your service type."
                                  )}
                                </div>
                              </div>
                              {selectedServiceTypeList &&
                                selectedServiceTypeList.length > 0 && (
                                  <>
                                    <div className="row">
                                      <div className="form-group calendar-controls cc-default col-md-6">
                                        <label
                                          htmlFor="visitDateCreate"
                                          className="required"
                                        >
                                          {t(
                                            "visit.visitComponent.createVisitModal.visitDate",
                                            "Visit date"
                                          )}
                                        </label>

                                        <DatePicker
                                          className="form-control"
                                          id="visitDateCreate"
                                          selected={
                                            visitDate
                                              ? moment(visitDate, "YYYY-MM-DD")
                                                  .startOf("day")
                                                  .toDate()
                                              : setVisitDate(
                                                  moment(
                                                    activeDates()[0]
                                                  ).format("YYYY-MM-DD")
                                                )
                                          }
                                          dateFormat="yyyy-MM-dd"
                                          onKeyDown={(e) => {
                                            e.preventDefault();
                                            return false;
                                          }}
                                          onChange={(date) => {
                                            setVisitDate(
                                              moment(date).format("YYYY-MM-DD")
                                            );
                                            if (startTime && endTime) {
                                              whoSetsStartTime(
                                                moment(date)
                                                  .hour(
                                                    moment(startTime).format(
                                                      "HH"
                                                    )
                                                  )
                                                  .minute(
                                                    moment(startTime).format(
                                                      "mm"
                                                    )
                                                  )
                                                  .format("YYYY-MM-DD HH:mm"),
                                                "Create Visit Modal Date Picker On Change"
                                              );
                                              whoSetsEndTime(
                                                moment(date)
                                                  .hour(
                                                    moment(endTime).format("HH")
                                                  )
                                                  .minute(
                                                    moment(endTime).format("mm")
                                                  )
                                                  .format("YYYY-MM-DD HH:mm"),
                                                "Create Visit Modal Date Picker On Change"
                                              );
                                            }
                                          }}
                                          includeDates={activeDates()}
                                          highlightDates={activeDates()}
                                          maxDate={moment(new Date())
                                            .add(
                                              selectedSalon.customerSchedulePeriod,
                                              "days"
                                            )
                                            .toDate()}
                                          placeholderText={t(
                                            "visit.visitComponent.createVisitModal.visitDatePlaceholder",
                                            "Select visit date"
                                          )}
                                        />
                                      </div>
                                    </div>
                                    {visitDate && visitDate !== undefined && (
                                      <>
                                        <div className="form-group">
                                          <label
                                            htmlFor="slotTimeComponentCreate"
                                            className="required"
                                          >
                                            {t(
                                              "visit.visitComponent.createVisitModal.visitDurationFrom",
                                              "Time slots (expected visit duration from"
                                            )}{" "}
                                            {startTime
                                              ? moment(startTime).format(
                                                  "HH:mm"
                                                )
                                              : ""}{" "}
                                            {t(
                                              "visit.visitComponent.createVisitModal.visitDurationTo",
                                              "to"
                                            )}{" "}
                                            {endTime
                                              ? moment(endTime).format("HH:mm")
                                              : ""}
                                            )
                                          </label>
                                          <ConnectedFreeVisitSlotsPopupComponentV2
                                            salonId={createSalonId}
                                            hairstylistId={createHairstylistId}
                                            selectedServices={
                                              selectedServiceTypeList
                                            }
                                            visitDate={moment(visitDate).format(
                                              "YYYY-MM-DDTHH:mm:ss"
                                            )}
                                            setStartTime={
                                              createVisitSetStartTime
                                            }
                                            setEndTime={createVisitSetEndTime}
                                            salonTimeSlot={salonTimeSlot}
                                            id="slotTimeComponentCreate"
                                            caller={"Create Visit Modal"}
                                            editType={false}
                                            visitId={visitId}
                                            key={Math.random * 10000}
                                          />
                                        </div>
                                        {/* {startTime && endTime && (
                                          <div className="form-group">
                                            <label id="visitDurationCreate">
                                              Expected visit duration from{" "}
                                              {startTime
                                                ? moment(startTime).format(
                                                    "HH:mm"
                                                  )
                                                : ""}{" "}
                                              to{" "}
                                              {endTime
                                                ? moment(endTime).format(
                                                    "HH:mm"
                                                  )
                                                : ""}
                                            </label>
                                          </div>
                                        )} */}
                                        <div className="form-group">
                                          <label htmlFor="descriptionCreate">
                                            {t(
                                              "visit.visitComponent.createVisitModal.description",
                                              "Description"
                                            )}
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="descriptionCreate"
                                            autoComplete="new-username"
                                            placeholder={t(
                                              "visit.visitComponent.createVisitModal.descriptionPlaceholder",
                                              "Please enter visit description"
                                            )}
                                            value={description}
                                            onChange={(event) =>
                                              setDescription(event.target.value)
                                            }
                                          />
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}

                  <RequiredFieldsComponent />
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => {
                    clearState();
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  disabled={startTime === endTime}
                  onClick={() => {
                    createVisitFromServiceTypeList(
                      description,
                      createHairstylistId,
                      salonCustomerId,
                      createSalonId,
                      startTime,
                      endTime,
                      selectedServiceTypeList,
                      () => {
                        clearState();
                        clearCheckboxSystem();
                      }
                    );
                  }}
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Edit Visit Modal */}
      {isEdit && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="editVisitModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="editVisitModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="editVisitModalLabel">
                  <i className="fal fa-cut fa-lg c-purple" />{" "}
                  {t("visit.visitComponent.editVisitModal.title", "Edit Visit")}{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    clearState();
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <input type="hidden" value="something" />
                  {
                    <div className="form-group">
                      <label htmlFor="customerIdEdit" className="required">
                        {" "}
                        {helper.i18nMultiFallbackKey(
                          "visit.visitComponent.editVisitModal.client",
                          "Client"
                        )}
                      </label>
                      <AutoComplete
                        field="name"
                        className="form-control-nested"
                        value={autoCompleteSearchValue}
                        onChange={(e) => {
                          setAutoCompleteSearchValue(e.value);
                          setCustomerId("");
                          if (e.value.value) {
                            setCustomerId(e.value.value);
                          }
                        }}
                        suggestions={customers.map((c) => {
                          return {
                            name: `${c.firstName} ${c.lastName}`,
                            value: c.personId,
                          };
                        })}
                        completeMethod={(e) => {
                          getCustomers(e.query);
                        }}
                      />

                      <div className="invalid-feedback">
                        {helper.i18nMultiFallbackKey(
                          "visit.visitComponent.editVisitModal.clientInvalid",
                          "Please select your customer."
                        )}
                      </div>
                    </div>
                  }
                  <div className="form-group">
                    <label htmlFor="salonIdEdit" className="required">
                      {" "}
                      {helper.i18nMultiFallbackKey(
                        "visit.visitComponent.editVisitModal.shop",
                        "Shop"
                      )}
                    </label>
                    <ConnectedSalonCustomersDropDown
                      salonId={editSalonId}
                      customerId={customerId}
                      setSalonId={emptySetSalonId}
                      setSalonCustomerId={setSalonCustomerId}
                      setServiceTypes={setServiceTypes}
                      setSalonTimeSlot={setSalonTimeSlot}
                      id="salonIdEdit"
                    />
                    <div className="invalid-feedback">
                      {helper.i18nMultiFallbackKey(
                        "visit.visitComponent.editVisitModal.shopInvalid",
                        "Please select your shop."
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="hairstylistIdEdit" className="required">
                      {helper.i18nMultiFallbackKey(
                        "visit.visitComponent.editVisitModal.provider",
                        "Provider"
                      )}
                    </label>
                    <ConnectedHairstylistsDropDown
                      hairstylistId={editHairstylistId}
                      setHairstylistId={checkIfSameEditHairstylist}
                      salonId={editSalonId}
                      id="hairstylistIdEdit"
                    />
                    <div className="invalid-feedback">
                      {helper.i18nMultiFallbackKey(
                        "visit.visitComponent.editVisitModal.providerInvalid",
                        "Please select your provider."
                      )}
                    </div>
                  </div>
                  {editHairstylistId && (
                    <>
                      <div className="form-group">
                        <label htmlFor="serviceTypeIdEdit" className="required">
                          {t(
                            "visit.visitComponent.editVisitModal.serviceType",
                            "Service type"
                          )}
                        </label>
                        <ConnectedHairstylistServiceTypesCheckboxLilstComponentV3
                          setSelectedServiceTypeList={
                            checkIfSameServiceTypeList
                          }
                          hairstylistServiceTypes={
                            hairstylistServiceTypesForEdit
                          }
                          selectedServiceTypeList={selectedServiceTypeList}
                          hairstylistId={editHairstylistId}
                          salonId={editSalonId}
                          visitId={visitId}
                          isEdit={true}
                          id="hairstylistServiceTypeId"
                        />
                        <div className="invalid-feedback">
                          {t(
                            "visit.visitComponent.editVisitModal.serviceTypeInvalid",
                            "Please select your service type."
                          )}
                        </div>
                      </div>
                      {selectedServiceTypeList &&
                        selectedServiceTypeList.length > 0 && (
                          <>
                            <div className="form-group">
                              <label
                                htmlFor="statusIdEdit"
                                className="required"
                              >
                                {t(
                                  "visit.visitComponent.editVisitModal.status",
                                  "Status"
                                )}
                              </label>
                              <ConnectedCategoryItemsDropDown
                                categoryType="Visit status"
                                categoryItemId={statusId}
                                setCategoryItemId={setStatusId}
                                id="statusIdEdit"
                              />
                              <div className="invalid-feedback">
                                {t(
                                  "visit.visitComponent.editVisitModal.statusInvalid",
                                  "Please select your status."
                                )}
                              </div>
                            </div>
                            {statusId && (
                              <>
                                <div className="row">
                                  <div className="form-group calendar-controls cc-default col-md-6">
                                    <label
                                      htmlFor="visitDate"
                                      className="required"
                                    >
                                      {t(
                                        "visit.visitComponent.editVisitModal.visitDate",
                                        "Visit date"
                                      )}
                                    </label>

                                    <DatePicker
                                      className="form-control"
                                      id="visitDateEdit"
                                      selected={
                                        visitDate
                                          ? moment(visitDate, "YYYY-MM-DD")
                                              .startOf("day")
                                              .toDate()
                                          : setVisitDate(
                                              moment(activeDates()[0]).format(
                                                "YYYY-MM-DD"
                                              )
                                            )
                                      }
                                      dateFormat="yyyy-MM-dd"
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                      onChange={(date) => {
                                        setVisitDate(
                                          moment(date).format("YYYY-MM-DD")
                                        );
                                      }}
                                      includeDates={activeDates()}
                                      highlightDates={activeDates()}
                                      placeholderText={t(
                                        "visit.visitComponent.editVisitModal.visitDatePlaceholder",
                                        "This only includes today and tomorrow"
                                      )}
                                    />
                                  </div>
                                </div>
                                {
                                  <>
                                    <div className="form-group">
                                      <label
                                        htmlFor="slotTimeComponentCalendarDailyEdit"
                                        className="required"
                                      >
                                        {t(
                                          "visit.visitComponent.editVisitModal.visitDurationFrom",
                                          "Time slots (expected visit duration from"
                                        )}
                                        {startTime
                                          ? moment(startTime).format("HH:mm")
                                          : ""}{" "}
                                        {t(
                                          "visit.visitComponent.editVisitModal.visitDurationTo",
                                          "to"
                                        )}{" "}
                                        {endTime
                                          ? moment(endTime).format("HH:mm")
                                          : ""}
                                        )
                                      </label>
                                      <ConnectedFreeVisitSlotsPopupComponentV3
                                        salonId={editSalonId}
                                        hairstylistId={editHairstylistId}
                                        selectedServices={
                                          selectedServiceTypeList
                                        }
                                        hairstylistServiceTypes={
                                          hairstylistServiceTypesForEdit
                                        }
                                        visitDate={visitDate}
                                        setStartTime={editVisitSetStartTime}
                                        setEndTime={editVisitSetEndTime}
                                        salonTimeSlot={salonTimeSlot}
                                        id="slotTimeComponentEdit"
                                        caller={"Edit Visit Modal"}
                                        editType={true}
                                        visitId={visitId}
                                        key={Math.random * 10000}
                                      />
                                    </div>
                                    {/* <div className="form-group">
                                      <label id="visitDurationEdit">
                                        Expected visit duration from{" "}
                                        {startTime
                                          ? moment(startTime).format("HH:mm")
                                          : ""}{" "}
                                        to{" "}
                                        {endTime
                                          ? moment(endTime).format("HH:mm")
                                          : ""}
                                      </label>
                                    </div> */}
                                    <div className="form-group">
                                      <label htmlFor="description">
                                        {t(
                                          "visit.visitComponent.editVisitModal.description",
                                          "Description"
                                        )}
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="description"
                                        autoComplete="new-username"
                                        placeholder={t(
                                          "visit.visitComponent.editVisitModal.descriptionPlaceholder",
                                          "Please enter visit description"
                                        )}
                                        value={description}
                                        onChange={(event) =>
                                          setDescription(event.target.value)
                                        }
                                        required
                                      />
                                    </div>
                                  </>
                                }
                              </>
                            )}
                          </>
                        )}
                    </>
                  )}
                  <RequiredFieldsComponent />
                </form>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => {
                    clearState();
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-ban" />
                  {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  disabled={startTime === endTime}
                  onClick={() =>
                    saveVisitFromServiceTypeList(
                      visitId,
                      description,
                      editHairstylistId,
                      salonCustomerId,
                      editSalonId,
                      statusId,
                      serviceTypeId,
                      startTime,
                      endTime,
                      selectedServiceTypeList,
                      () => {
                        clearState();
                        clearCheckboxSystem();
                      }
                    )
                  }
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delete Visit Modal */}
      {isDelete && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="deleteVisitModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="deleteVisitModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="deleteVisitModalLabel">
                  <i className="fal fa-cut fa-lg c-purple" />{" "}
                  {t(
                    "visit.visitComponent.deleteVisitModal.title",
                    "Deactivate Visit"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <h2 className="info-title-upp-case-xl icon-custom-top-center">
                  <span className="icon-custom is-xl icon-triangle-red-white" />
                  {t(
                    "visit.visitComponent.deleteVisitModal.attention",
                    "Attention"
                  )}
                </h2>
                <div className="info-message text-center">
                  <p>
                    {t(
                      "visit.visitComponent.deleteVisitModal.question",
                      "Are you sure you want to deactivate visit"
                    )}
                  </p>
                  <p className="prime-text">{hairstylistId}?</p>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  onClick={() => deleteVisit(visitId, () => clearState())}
                >
                  <i className="fal fa-check" /> {t("common.yes", "Yes")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                >
                  <i className="fal fa-times" /> {t("common.no", "No")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Create Customer Modal */}
      {isCustomerCreate && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="createCustomerInVisitModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="createCustomerInVisitModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="createCustomerInVisitModalLabel"
                >
                  <i className="fal fa-user-tie fa-lg c-purple" />{" "}
                  {helper.i18nMultiFallbackKey(
                    "visit.visitComponent.createCustomerModal.title",
                    "Create Client"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setIsCustomerCreate(false)}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <input type="hidden" value="something" />
                  <div className="form-group">
                    <label htmlFor="customerFirstName">
                      {t(
                        "visit.visitComponent.createCustomerModal.firstName",
                        "First Name"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="customerFirstName"
                      placeholder={helper.i18nMultiFallbackKey(
                        "visit.visitComponent.createCustomerModal.firstNamePlaceholder",
                        "Please enter client first name"
                      )}
                      value={firstName}
                      onChange={(event) => setFirstName(event.target.value)}
                      required
                    />
                    <div className="invalid-feedback">
                      {t(
                        "visit.visitComponent.createCustomerModal.firstNameInvalid",
                        "First name is required."
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="customerLastName">
                      {t(
                        "visit.visitComponent.createCustomerModal.lastName",
                        "Last Name"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="customerLastName"
                      placeholder={helper.i18nMultiFallbackKey(
                        "visit.visitComponent.createCustomerModal.lastNamePlaceholder",
                        "Please enter client last name"
                      )}
                      value={lastName}
                      onChange={(event) => setLastName(event.target.value)}
                      required
                    />
                    <div className="invalid-feedback">
                      {t(
                        "visit.visitComponent.createCustomerModal.lastNameInvalid",
                        "Last name is required."
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="phone">
                      {t(
                        "visit.visitComponent.createCustomerModal.phoneNumber",
                        "Phone Number"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      placeholder={t(
                        "visit.visitComponent.createCustomerModal.phoneNumberPlaceholder",
                        "Phone number"
                      )}
                      value={phone}
                      onChange={(event) => setPhone(event.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">
                      {t(
                        "visit.visitComponent.createCustomerModal.email",
                        "Email"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder={t(
                        "visit.visitComponent.createCustomerModal.emailPlaceholder",
                        "Email"
                      )}
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="registrationsNumber">
                      {t(
                        "visit.visitComponent.createCustomerModal.registrationNumber",
                        "Registrations Number"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="registrationsNumber"
                      placeholder={t(
                        "visit.visitComponent.createCustomerModal.registrationNumberPlaceholder",
                        "Registrations Number"
                      )}
                      value={registrationsNumber}
                      onChange={(event) =>
                        setRegistrationsNumber(event.target.value)
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="salonId">
                      {helper.i18nMultiFallbackKey(
                        "visit.visitComponent.createCustomerModal.shop",
                        "Shop"
                      )}
                    </label>
                    <ConnectedSalonsDropDown
                      salonId={salonId}
                      setSalonId={setSalonId}
                      setServiceTypes={setServiceTypes}
                      setSalonTimeSlot={setSalonTimeSlot}
                      id="salonId"
                    />
                    <div className="invalid-feedback">
                      {helper.i18nMultiFallbackKey(
                        "visit.visitComponent.createCustomerModal.shopInvalid",
                        "Please select your shop."
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="genderId">
                      {t(
                        "visit.visitComponent.createCustomerModal.gender",
                        "Gender"
                      )}
                    </label>
                    <ConnectedCategoryItemsDropDown
                      categoryType="Gender"
                      categoryItemId={genderId}
                      setCategoryItemId={setGenderId}
                      id="genderId"
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => setIsCustomerCreate(false)}
                >
                  <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  onClick={() =>
                    createCustomer(
                      firstName,
                      lastName,
                      phone,
                      email,
                      registrationsNumber,
                      genderId,
                      salonId,
                      (customerId, isAllCustomers) => {
                        if (rolesMap.get("Admin")) {
                          getCustomersPaged(
                            "",
                            isAllCustomers ? null : 1,
                            [],
                            () => {
                              setCustomerId(customerId);
                              setAutoCompleteSearchValue(
                                `${firstName} ${lastName}`
                              );
                              setIsCustomerCreate(false);
                            }
                          );
                        } else {
                          if (rolesMap.get("Salon admin")) {
                            // getCustomersPagedForSalon(
                            getCustomersPaged(
                              "",
                              // activeUser.userRoles[0].externalId,
                              isAllCustomers ? null : 1,
                              [],
                              () => {
                                setCustomerId(customerId);
                                setAutoCompleteSearchValue(
                                  `${firstName} ${lastName}`
                                );
                                setIsCustomerCreate(false);
                              }
                            );
                          }
                        }
                      }
                    )
                  }
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    activeUser: state.activeUser,
    customers: state.customers,
    hairstylistWorkingTimes: state.hairstylistWorkingTimes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActiveUser() {
      if (sessionStorage.getItem("user_id")) {
        dispatch(
          mutations.getActiveUser(JSON.parse(sessionStorage.getItem("user_id")))
        );
      }
    },
    createCustomer(
      firstName,
      lastName,
      phone,
      email,
      registrationsNumber,
      genderId,
      salonId,
      cb
    ) {
      let customer = {
        FirstName: firstName,
        LastName: lastName,
        Phone: phone,
        Email: email,
        RegistrationsNumber: registrationsNumber,
        GenderId: genderId,
        SalonId: salonId,
      };
      dispatch(mutations.createCustomer(customer, true, cb));
    },
    createVisit(
      description,
      hairstylistId,
      salonCustomerId,
      salonId,
      serviceTypeId,
      startTime,
      endTime,
      cb
    ) {
      let visit = {
        Description: description,
        HairstylistId: hairstylistId,
        SalonCustomerId: salonCustomerId,
        SalonId: salonId,
        ServiceTypeId: serviceTypeId,
        StartTime: startTime,
        EndTime: endTime,
      };
      cb();
      dispatch(mutations.createVisit(visit));
    },
    createVisitFromServiceTypeList(
      description,
      hairstylistId,
      salonCustomerId,
      salonId,
      startTime,
      endTime,
      selectedServiceTypeList,
      cb
    ) {
      let visit = {
        Description: description,
        HairstylistId: hairstylistId,
        SalonCustomerId: salonCustomerId,
        SalonId: salonId,
        StartTime: startTime,
        EndTime: endTime,
        ServiceTypeList: selectedServiceTypeList,
      };
      // cb();
      dispatch(mutations.createVisitFromServiceTypeList(visit, cb));
    },
    saveVisit(
      visitId,
      description,
      hairstylistId,
      salonCustomerId,
      salonId,
      statusId,
      serviceTypeId,
      startTime,
      endTime,
      cb
    ) {
      let visit = {
        VisitId: visitId,
        Description: description,
        HairstylistId: hairstylistId,
        SalonCustomerId: salonCustomerId,
        SalonId: salonId,
        StatusId: statusId,
        ServiceTypeId: serviceTypeId,
        StartTime: startTime,
        EndTime: endTime,
      };
      dispatch(mutations.saveVisit(visit, cb));
    },
    saveVisitFromServiceTypeList(
      visitId,
      description,
      hairstylistId,
      salonCustomerId,
      salonId,
      statusId,
      serviceTypeId,
      startTime,
      endTime,
      selectedServiceTypeList,
      cb
    ) {
      let visit = {
        VisitId: visitId,
        Description: description,
        HairstylistId: hairstylistId,
        SalonCustomerId: salonCustomerId,
        SalonId: salonId,
        StatusId: statusId,
        ServiceTypeId: serviceTypeId,
        StartTime: startTime,
        EndTime: endTime,
        ServiceTypeList: selectedServiceTypeList,
      };
      dispatch(mutations.saveVisitFromServiceTypeList(visit, cb));
    },
    deleteVisit(visitId, cb) {
      let visit = {
        VisitId: visitId,
      };
      cb();
      dispatch(mutations.deleteVisit(visit));
    },
    getCustomers(searchValue) {
      dispatch(mutations.getCustomersForAutocomplete(searchValue));
    },
    clearCustomers() {
      dispatch(mutations.setAllCustomers([]));
    },
    getAllHairstylists() {
      dispatch(mutations.getAllHairstylistsForDropdown());
    },
    getAllSalons() {
      dispatch(mutations.getAllSalons());
    },
    getAllCustomers() {
      dispatch(mutations.getAllCustomers());
    },
    getCustomersPaged(searchValue, page, sort, cb) {
      const customSetSort = (sort) => {
        let result = [];
        sort.forEach((element) => {
          switch (element.field) {
            case "firstName":
            case "lastName":
            case "email":
            case "phone":
              result.push({
                field:
                  "Person." +
                  element.field[0].toUpperCase() +
                  element.field.slice(1),
                dir: element.dir,
              });
              break;
            default:
              result.push(element);
              break;
          }
        });

        return result;
      };

      let customSort = customSetSort(sort);
      dispatch(mutations.getCustomersPaged(searchValue, page, customSort, cb));
    },
    // getCustomersPagedForSalon(searchValue, salonId, page, sort, cb) {
    //   const customSetSort = (sort) => {
    //     let result = [];
    //     sort.forEach((element) => {
    //       switch (element.field) {
    //         case "firstName":
    //         case "lastName":
    //         case "email":
    //         case "phone":
    //           result.push({
    //             field:
    //               "Person." +
    //               element.field[0].toUpperCase() +
    //               element.field.slice(1),
    //             dir: element.dir,
    //           });
    //           break;
    //         default:
    //           result.push(element);
    //           break;
    //       }
    //     });

    //     return result;
    //   };

    //   let customSort = customSetSort(sort);
    //   dispatch(
    //     mutations.getCustomersPagedForSalon(
    //       searchValue,
    //       salonId,
    //       page,
    //       customSort,
    //       cb
    //     )
    //   );
    // },
  };
};

const ConnectedVisit = connect(
  mapStateToProps,
  mapDispatchToProps
)(VisitComponent);

export default ConnectedVisit;
