import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import * as helper from "../HelperRepository";
import RequiredFieldsComponent from "../RequiredFieldsComponent";

const EditServiceTypeModalComponent = ({
  serviceTypeName,
  setServiceTypeName,
  serviceTypePrice,
  setServiceTypePrice,
  selectedSalon,
  serviceTypeDuration,
  setServiceTypeDuration,
  serviceTypeDescription,
  setServiceTypeDescription,
  saveServiceType,
  clearSelectedSalonServiceType,
  setEnableEdit,
  formErrors,
  setFormErrors,
  formValid,
  setFormValid,
  serviceTypeNameValid,
  setServiceTypeNameValid,
  serviceTypePriceValid,
  setServiceTypePriceValid,
  serviceTypeDurationValid,
  setServiceTypeDurationValid,
  serviceTypeId,
}) => {
  const { t, i18n } = useTranslation();

  const handleUserInput = (e) => {
    switch (e.target.id) {
      case "editSalonServiceTypeModalName":
        setServiceTypeName(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "editSalonServiceTypeModalPrice":
        setServiceTypePrice(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "editSalonServiceTypeModalDuration":
        setServiceTypeDuration(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      default:
        break;
    }
  };
  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let serviceTypeNameFieldValid = serviceTypeNameValid;
    let serviceTypePriceFieldValid = serviceTypePriceValid;
    let serviceTypeDurationFieldValid = serviceTypeDurationValid;
    switch (fieldName) {
      case "editSalonServiceTypeModalName":
        let serviceTypeNameFieldPattern = /^([A-Za-z 0-9\u0110\u017D\u01C8\u01CB\u0106\u010C\u01C5\u0160\u0111\u017E\u01C9\u01CC\u0107\u010D\u01C6\u0161]+)$/i;
        serviceTypeNameFieldValid = serviceTypeNameFieldPattern.test(value);
        setServiceTypeNameValid(serviceTypeNameFieldValid);
        fieldValidationErrors.name = serviceTypeNameFieldValid
          ? ""
          : t("common.isInvalid", " is invalid");
        break;
      case "editSalonServiceTypeModalPrice":
        let serviceTypePricePattern = /^([1-9][0-9]*)$/i;
        serviceTypePriceFieldValid = serviceTypePricePattern.test(value);
        setServiceTypePriceValid(serviceTypePriceFieldValid);
        fieldValidationErrors.price = serviceTypePriceFieldValid
          ? ""
          : t("common.isInvalid", " is invalid");
        break;
      case "editSalonServiceTypeModalDuration":
        let serviceTypeDurationFieldPattern = /^([1-9][0-9]*)$/i;
        serviceTypeDurationFieldValid = serviceTypeDurationFieldPattern.test(
          value
        );
        setServiceTypeDurationValid(serviceTypeDurationFieldValid);
        fieldValidationErrors.duration = serviceTypeDurationFieldValid
          ? ""
          : t("common.isInvalid", " is invalid");
        break;
      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    setFormValid(
      serviceTypeNameFieldValid &&
        serviceTypePriceFieldValid &&
        serviceTypeDurationFieldValid
    );
  };

  //   const errorClassLabel = (error) => {
  //     if (error) {
  //       return error.length === 0 ? "" : "text-danger";
  //     }
  //     return "";
  //   };

  //   const errorClassInput = (error) => {
  //     if (error) {
  //       return error.length === 0 ? "" : "is-invalid";
  //     }
  //     return "";
  //   };

  return (
    <>
      {/* Edit Salon ServiceType Modal*/}
      {/* <ConnectedSalonServiceTypeEdit
          selectedServiceType={selectedSalonServiceType}
          selectedSalon={selectedSalon}
          setEnableEdit={setEnableEdit}
          enableEdit={enableEdit}
          clearParentState={clearSelectedSalonServiceType}
        />*/}
      <div
        className="modal fade"
        data-backdrop="static"
        data-keyboard="false"
        id="editSalonServiceTypeModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editSalonServiceTypeModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editSalonServiceTypeModalLabel">
                <i className="fal fa-user fa-lg c-purple" />{" "}
                {helper.i18nMultiFallbackKey(
                  "salon.salonComponent.editSalonServiceTypeModal.title",
                  "Edit Shop Service Type"
                )}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setEnableEdit(false);
                }}
              >
                <i className="fal fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <form className="form needs-validation" noValidate>
                <input type="hidden" value="something" />
                <div className="form-group">
                  <label
                    htmlFor="editSalonServiceTypeModalName"
                    className="required"
                  >
                    {t(
                      "salon.salonComponent.editSalonServiceTypeModal.serviceTypeNameLabel",
                      "Name"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="editSalonServiceTypeModalName"
                    autoComplete="new-username"
                    placeholder={t(
                      "salon.salonComponent.editSalonServiceTypeModal.serviceTypeNamePlaceholder",
                      "Please enter service type name"
                    )}
                    value={serviceTypeName}
                    onChange={handleUserInput}
                    required
                  />
                  {/* <div className="invalid-feedback"> */}
                  <div>
                    {formErrors["name"] && formErrors["name"].length > 0 && (
                      <p className="text-danger">
                        {t(
                          "salon.salonComponent.editSalonServiceTypeModal.serviceTypeNameLabel",
                          "Name"
                        )}
                        {formErrors["name"]}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="editSalonServiceTypeModalPrice"
                    className="required"
                  >
                    {t(
                      "salon.salonComponent.editSalonServiceTypeModal.serviceTypePriceLabel",
                      "Price"
                    )}
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="editSalonServiceTypeModalPrice"
                    autoComplete="new-username"
                    placeholder={t(
                      "salon.salonComponent.editSalonServiceTypeModal.serviceTypePricePlaceholder",
                      "Please enter service type price"
                    )}
                    value={serviceTypePrice}
                    onChange={handleUserInput}
                    required
                  />
                  {/* <div className="invalid-feedback"> */}
                  <div>
                    {formErrors["price"] && formErrors["price"].length > 0 && (
                      <p className="text-danger">
                        {t(
                          "salon.salonComponent.editSalonServiceTypeModal.serviceTypePriceLabel",
                          "Price"
                        )}
                        {formErrors["price"]}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="editSalonServiceTypeModalDuration">
                    {helper.i18nMultiFallbackKey(
                      "salon.salonComponent.editSalonServiceTypeModal.serviceTypeForShopLabel",
                      "For shop"
                    )}{" "}
                    {selectedSalon.name}{" "}
                    {t(
                      "salon.salonComponent.editSalonServiceTypeModal.serviceTypeTimeSlotIsLabel",
                      "time slot is"
                    )}{" "}
                    {selectedSalon.salonTimeSlot}{" "}
                    {t(
                      "salon.salonComponent.editSalonServiceTypeModal.serviceTypeMinutesLabel",
                      "minutes"
                    )}
                  </label>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="editSalonServiceTypeModalDuration"
                    className="required"
                  >
                    {helper.i18nMultiFallbackKey(
                      "salon.salonComponent.editSalonServiceTypeModal.serviceTypeDurationLabel",
                      "Duration (number of shop time slots, for shop"
                    )}
                    {selectedSalon.name}{" "}
                    {t(
                      "salon.salonComponent.editSalonServiceTypeModal.serviceTypeTimeSlotIsLabel",
                      "time slot is"
                    )}{" "}
                    {selectedSalon.salonTimeSlot}{" "}
                    {t(
                      "salon.salonComponent.editSalonServiceTypeModal.serviceTypeMinutesLabel",
                      "minutes"
                    )}
                    )
                  </label>
                  <input
                    type="number"
                    min="1"
                    className="form-control"
                    id="editSalonServiceTypeModalDuration"
                    autoComplete="new-username"
                    placeholder={t(
                      "salon.salonComponent.editSalonServiceTypeModal.serviceTypeDurationPlaceholder",
                      "Please enter service type duration"
                    )}
                    value={serviceTypeDuration}
                    onChange={handleUserInput}
                    // onKeyDown={(e) => {
                    //   console.log(
                    //     "----------------------------------- CreateServiceTypeModal onKeydown"
                    //   );
                    //   e.preventDefault();
                    //   return false;
                    // }}
                    required
                  />
                  {/* <div className="invalid-feedback"> */}
                  <div>
                    {formErrors["duration"] &&
                      formErrors["duration"].length > 0 && (
                        <p className="text-danger">
                          {t(
                            "salon.salonComponent.editSalonServiceTypeModal.serviceTypeDurationValidationLabel",
                            "Duration"
                          )}
                          {formErrors["duration"]}
                        </p>
                      )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="editSalonServiceTypeModalDescription">
                    {t(
                      "salon.salonComponent.editSalonServiceTypeModal.serviceTypeDescriptionLabel",
                      "Description"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="editSalonServiceTypeModalDescription"
                    autoComplete="new-username"
                    placeholder={t(
                      "salon.salonComponent.editSalonServiceTypeModal.serviceTypeDescriptionPlaceholder",
                      "Please enter service type description"
                    )}
                    value={serviceTypeDescription}
                    onChange={(event) =>
                      setServiceTypeDescription(event.target.value)
                    }
                    required
                  />
                </div>
                <RequiredFieldsComponent />
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger ico-l"
                data-dismiss="modal"
                onClick={() => {
                  setEnableEdit(false);
                  clearSelectedSalonServiceType();
                }}
              >
                <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
              </button>
              <button
                type="button"
                className="btn btn-outline-success ico-l"
                data-dismiss="modal"
                disabled={!formValid}
                onClick={() => {
                  let serviceTypeForUpdate = {
                    salonId: selectedSalon.salonId,
                    serviceTypeId: serviceTypeId,
                    serviceTypeName: serviceTypeName,
                    serviceTypePrice: serviceTypePrice,
                    serviceTypeDuration: serviceTypeDuration,
                    description: serviceTypeDescription,
                  };

                  saveServiceType(serviceTypeForUpdate, () => {
                    clearSelectedSalonServiceType();
                    setEnableEdit(false);
                  });
                }}
              >
                <i className="fal fa-check" /> {t("common.finish", "Finish")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    serviceTypeName: ownProps.serviceTypeName,
    setServiceTypeName: ownProps.setServiceTypeName,
    serviceTypePrice: ownProps.serviceTypePrice,
    setServiceTypePrice: ownProps.setServiceTypePrice,
    selectedSalon: ownProps.selectedSalon,
    serviceTypeDuration: ownProps.serviceTypeDuration,
    setServiceTypeDuration: ownProps.setServiceTypeDuration,
    serviceTypeDescription: ownProps.serviceTypeDescription,
    setServiceTypeDescription: ownProps.setServiceTypeDescription,
    saveServiceType: ownProps.saveServiceType,
    clearSelectedSalonServiceType: ownProps.clearSelectedSalonServiceType,
    setEnableEdit: ownProps.setEnableEdit,
    formErrors: ownProps.formErrors,
    setFormErrors: ownProps.setFormErrors,
    formValid: ownProps.formValid,
    setFormValid: ownProps.setFormValid,
    serviceTypeNameValid: ownProps.serviceTypeNameValid,
    setServiceTypeNameValid: ownProps.setServiceTypeNameValid,
    serviceTypePriceValid: ownProps.serviceTypePriceValid,
    setServiceTypePriceValid: ownProps.setServiceTypePriceValid,
    serviceTypeDurationValid: ownProps.serviceTypeDurationValid,
    setServiceTypeDurationValid: ownProps.setServiceTypeDurationValid,
    serviceTypeId: ownProps.serviceTypeId,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export const ConnectedEditServiceTypeModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditServiceTypeModalComponent);
