import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { ConnectedCustomerList } from "./CustomerList";
import { ConnectedSalonsDropDown } from "../Dropdowns/SalonsDropDown";
import { ConnectedCategoryItemsDropDown } from "../Dropdowns/CategoryItemsDropDown";
import { ConnectedCategoryItemsDropDownV2 } from "../Dropdowns/CategoryItemsDropDownV2";
import * as mutations from "../../store/mutations";
import * as helper from "../HelperRepository";
import * as moment from "moment";
import { useTranslation } from "react-i18next";
import RequiredFieldsComponent from "../RequiredFieldsComponent";

const CustomerComponent = ({
  activeUser,
  getActiveUser,
  createCustomer,
  saveCustomer,
  deleteCustomer,
  getCustomersPaged,
  // getCustomersPagedForSalon,
}) => {
  const { t, i18n } = useTranslation();

  document.getElementsByTagName("html")[0].classList.remove("page-signin");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [registrationsNumber, setRegistrationsNumber] = useState("");
  const [personId, setPersonId] = useState("");
  const [statusId, setStatusId] = useState("");
  const [genderId, setGenderId] = useState("");
  const [serviceTypes, setServiceTypes] = useState("");
  const [salonTimeSlot, setSalonTimeSlot] = useState("");
  const [formValid, setFormValid] = useState(false);

  const [rolesMap, setRolesMap] = useState(() => {
    return helper.createMapObject();
  });

  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [salonId, setSalonId] = useState("");

  // Following values do not call rerender, they are used to calculate formValid, that is used for rerender
  // for that reason we do not need them in state form, non mutable, we can use mutable useRef, that is outside rendering cycle
  // something like our own values but useRef persist over renders

  //const [formErrors, setFormErrors] = useState({});
  const formErrors = useRef({});
  const setFormErrors = (newFormErrors) => {
    formErrors.current = newFormErrors;
  };

  const firstNameValid = useRef(false);
  const lastNameValid = useRef(false);
  const phoneValid = useRef(false);
  const emailValid = useRef(false);

  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add("grid-view");

    if (!activeUser || !activeUser.personId) {
      getActiveUser();
    }
    return () => {
      document.getElementsByTagName("html")[0].classList.remove("grid-view");
    };
  }, []);

  useEffect(() => {
    setFormValid(
      firstNameValid.current &&
        lastNameValid.current &&
        emailValid.current &&
        phoneValid.current &&
        salonId !== ""
    );
  }, [salonId]);

  useEffect(() => {
    setRolesMap(helper.mapForRole(activeUser));
    if (
      activeUser.userRoles &&
      activeUser.userRoles[0].role.name === "Salon admin"
    ) {
      setSalonId(activeUser.userRoles[0].externalId);
    }
  }, [activeUser]);

  const handleUserInput = (e) => {
    switch (e.target.id) {
      case "customerFirstName":
      case "customerFirstNameEdit":
        setFirstName(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "customerLastName":
      case "customerLastNameEdit":
        setLastName(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "email":
      case "emailEdit":
        setEmail(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "phone":
      case "phoneEdit":
        setPhone(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      default:
        break;
    }
  };

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors.current;
    switch (fieldName) {
      case "customerFirstName":
      case "customerFirstNameEdit":
        let firstNameFieldValidPattern = /^([A-Za-z\u0110\u017D\u01C8\u01CB\u0106\u010C\u01C5\u0160\u0111\u017E\u01C9\u01CC\u0107\u010D\u01C6\u0161 ]+)$/;
        firstNameValid.current = firstNameFieldValidPattern.test(value);
        fieldValidationErrors.firstName = firstNameValid.current
          ? ""
          : t(
              "common.firstNameIsInvalid",
              "  is invalid. Valid format is one or more character only strings separated by blank"
            );
        break;
      case "customerLastName":
      case "customerLastNameEdit":
        let lastNameFieldValidPattern = /^([A-Za-z\u0110\u017D\u01C8\u01CB\u0106\u010C\u01C5\u0160\u0111\u017E\u01C9\u01CC\u0107\u010D\u01C6\u0161 ]+)$/;
        lastNameValid.current = lastNameFieldValidPattern.test(value);
        fieldValidationErrors.lastName = lastNameValid.current
          ? ""
          : t(
              "common.lastNameIsInvalid",
              "  is invalid. Valid format is one or more character only strings separated by blank"
            );
        break;
      case "email":
      case "emailEdit":
        //let emailFieldValidPattern = /^(([\w.%+-]+)@([\w-]+\.)+([\w]{2,}))?$/i;
        //let emailFieldValidPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let emailFieldValidPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        emailValid.current = emailFieldValidPattern.test(value);
        fieldValidationErrors.email = emailValid.current
          ? ""
          : t(
              "common.emailIsInvalid",
              " is invalid email address. Valid e-mail can contain only latin letters, numbers, '@' and '.'"
            );
        break;
      case "phone":
      case "phoneEdit":
        let phoneFieldValidPattern = /^((\+?)[0-9]{9,15})$/i;
        phoneValid.current = phoneFieldValidPattern.test(value);
        fieldValidationErrors.phone = phoneValid.current
          ? ""
          : t(
              "common.phoneIsInvalid",
              "  is invalid. Valid format +06xxxxxxxx, from 9 to 15 numbers, sign + optional"
            );
        break;
      default:
        break;
    }
    setFormErrors(fieldValidationErrors);

    setFormValid(
      firstNameValid.current &&
        lastNameValid.current &&
        emailValid.current &&
        phoneValid.current &&
        salonId !== ""
    );
  };

  const errorClassLabel = (error) => {
    if (error) {
      return error.length === 0 ? "" : "text-danger";
    }
    return "";
  };

  const errorClassInput = (error) => {
    if (error) {
      return error.length === 0 ? "" : "is-invalid";
    }
    return "";
  };

  const setFormValidComponentsState = (newState) => {
    setFormValid(newState);

    firstNameValid.current = newState;
    lastNameValid.current = newState;
    phoneValid.current = newState;
    emailValid.current = newState;

    setFormErrors({});
  };

  const clearState = () => {
    setFormValidComponentsState(false);

    if (
      activeUser.userRoles &&
      activeUser.userRoles[0].role.name === "Salon admin"
    ) {
      setSalonId(activeUser.userRoles[0].externalId);
    } else {
      setSalonId("");
    }

    setFirstName("");
    setLastName("");
    setPhone("");
    setEmail("");
    setPersonId("");
    setRegistrationsNumber("");
    setStatusId("");
    setGenderId("");
  };

  const clearModalShowState = () => {
    setIsEdit(false);
    setIsCreate(false);
    setIsDelete(false);
  };

  const setCustomerState = (customer) => {
    setFormValidComponentsState(true);

    handleUserInput({
      target: { id: "customerFirstNameEdit", value: customer.firstName },
    });

    handleUserInput({
      target: { id: "customerLastNameEdit", value: customer.lastName },
    });

    handleUserInput({
      target: { id: "phoneEdit", value: customer.mobile },
    });

    handleUserInput({
      target: { id: "emailEdit", value: customer.email },
    });

    if (
      activeUser.userRoles &&
      activeUser.userRoles[0].role.name === "Salon admin"
    ) {
      setSalonId(activeUser.userRoles[0].externalId);
    } else {
      setSalonId(customer.salonCustomers[0].salonId);
    }

    setPersonId(customer.personId);
    setRegistrationsNumber(customer.registrationsNumber);
    setStatusId(customer.statusId);
    setGenderId(customer.genderId ? customer.genderId : "");
  };

  return (
    <>
      <main role="main">
        <div className="container-fluid">
          <ConnectedCustomerList
            setParentState={setCustomerState}
            clearParentState={clearState}
            setIsEdit={setIsEdit}
            setIsCreate={setIsCreate}
            setIsDelete={setIsDelete}
          />
        </div>
      </main>

      <footer className="footer">
        <div className="container copyright">
          <span className="text-muted">
            Hairstylist &copy; Copyright {moment().format("YYYY")},{" "}
            {t("common.version", "Version:")} {process.env.VERSION}
          </span>
        </div>
      </footer>

      {/* Create Customer Modal */}
      {isCreate && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="createCustomerModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="createCustomerModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="createCustomerModalLabel">
                  <i className="fal fa-user-tie fa-lg c-purple" />
                  {helper.i18nMultiFallbackKey(
                    "customer.customerComponent.createCustomerModal.title",
                    "Create Client"
                  )}{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    clearState();
                    clearModalShowState();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <input type="hidden" value="something" />
                  <div className="form-group">
                    <label
                      htmlFor="customerFirstName"
                      className={`${errorClassLabel(
                        formErrors.current.firstName
                      )} required`}
                    >
                      {t(
                        "customer.customerComponent.createCustomerModal.fistnameLabel",
                        "First Name"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.current.firstName
                      )}`}
                      id="customerFirstName"
                      placeholder={helper.i18nMultiFallbackKey(
                        "customer.customerComponent.createCustomerModal.fistnamePlaceholder",
                        "Please enter client first name"
                      )}
                      value={firstName}
                      onChange={handleUserInput}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                      required
                    />
                    <div className="invalid-feedback">
                      {formErrors.current["firstName"]?.length > 0 && (
                        <p className="text-danger">
                          {t(
                            "customer.customerComponent.createCustomerModal.fistnameInvalid",
                            "First name"
                          )}
                          {formErrors.current["firstName"]}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="customerLastName"
                      className={`${errorClassLabel(
                        formErrors.current.lastName
                      )} required`}
                    >
                      {t(
                        "customer.customerComponent.createCustomerModal.lastnameLabel",
                        "Last Name"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.current.lastName
                      )}`}
                      id="customerLastName"
                      placeholder={helper.i18nMultiFallbackKey(
                        "customer.customerComponent.createCustomerModal.lastnamePlaceholder",
                        "Please enter client last name"
                      )}
                      value={lastName}
                      onChange={handleUserInput}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                      required
                    />
                    <div className="invalid-feedback">
                      {formErrors.current["lastName"]?.length > 0 && (
                        <p className="text-danger">
                          {t(
                            "customer.customerComponent.createCustomerModal.lastnameInvalid",
                            "Last name"
                          )}
                          {formErrors.current["lastName"]}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="phone"
                      className={`${errorClassLabel(
                        formErrors.current.phone
                      )} required`}
                    >
                      {t(
                        "customer.customerComponent.createCustomerModal.phoneLabel",
                        "Phone Number"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.current.phone
                      )}`}
                      id="phone"
                      placeholder={t(
                        "customer.customerComponent.createCustomerModal.phonePlaceholder",
                        "Phone Number"
                      )}
                      value={phone}
                      onChange={handleUserInput}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                      required
                    />
                    <div className="invalid-feedback">
                      {formErrors.current["phone"]?.length > 0 && (
                        <p className="text-danger">
                          {t(
                            "customer.customerComponent.createCustomerModal.phoneInvalid",
                            "Phone"
                          )}
                          {formErrors.current["phone"]}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="email"
                      className={`${errorClassLabel(
                        formErrors.current.email
                      )} required`}
                    >
                      {t(
                        "customer.customerComponent.createCustomerModal.emailLabel",
                        "Email"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.current.email
                      )}`}
                      id="email"
                      placeholder={t(
                        "customer.customerComponent.createCustomerModal.emailPlaceholder",
                        "Email"
                      )}
                      value={email}
                      onChange={handleUserInput}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                    />
                    <div className="invalid-feedback">
                      {formErrors.current["email"]?.length > 0 && (
                        <p className="text-danger">
                          {t(
                            "customer.customerComponent.createCustomerModal.emailInvalid",
                            "Email"
                          )}
                          {formErrors.current["email"]}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="registrationsNumber">
                      {t(
                        "customer.customerComponent.createCustomerModal.registrationNumberLabel",
                        "Registrations Number"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="registrationsNumber"
                      placeholder={t(
                        "customer.customerComponent.createCustomerModal.registrationNumberPlaceholder",
                        "Registrations Number"
                      )}
                      value={registrationsNumber || ""}
                      onChange={(event) =>
                        setRegistrationsNumber(event.target.value)
                      }
                    />
                  </div>
                  {rolesMap.get("Admin") && (
                    <div className="form-group">
                      <label htmlFor="salonId" className="required">
                        {helper.i18nMultiFallbackKey(
                          "customer.customerComponent.createCustomerModal.shopLabel",
                          "Shop"
                        )}
                      </label>
                      <ConnectedSalonsDropDown
                        salonId={salonId}
                        setSalonId={setSalonId}
                        setServiceTypes={setServiceTypes}
                        setSalonTimeSlot={setSalonTimeSlot}
                        id="salonId"
                      />
                      <div className="invalid-feedback">
                        {helper.i18nMultiFallbackKey(
                          "customer.customerComponent.createCustomerModal.shopInvalid",
                          "Please select your shop."
                        )}
                      </div>
                    </div>
                  )}

                  <div className="form-group">
                    <label htmlFor="genderId">
                      {t(
                        "customer.customerComponent.createCustomerModal.genderLabel",
                        "Gender"
                      )}
                    </label>
                    <ConnectedCategoryItemsDropDown
                      categoryType="Gender"
                      categoryItemId={genderId}
                      setCategoryItemId={setGenderId}
                      id="genderId"
                    />
                  </div>
                  <RequiredFieldsComponent />
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => {
                    clearState();
                    clearModalShowState();
                  }}
                >
                  <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  disabled={!formValid}
                  onClick={() =>
                    createCustomer(
                      firstName,
                      lastName,
                      phone,
                      email,
                      registrationsNumber,
                      genderId,
                      salonId,
                      (customerId, isAllCustomers) => {
                        if (rolesMap.get("Admin")) {
                          getCustomersPaged(
                            "",
                            isAllCustomers ? null : 1,
                            [],
                            () => {
                              clearState();
                              clearModalShowState();
                            }
                          );
                        } else {
                          if (rolesMap.get("Salon admin")) {
                            // getCustomersPagedForSalon(
                            getCustomersPaged(
                              "",
                              // activeUser.userRoles[0].externalId,
                              isAllCustomers ? null : 1,
                              [],
                              () => {
                                clearState();
                                clearModalShowState();
                              }
                            );
                          }
                        }
                      }
                    )
                  }
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Edit Customer Modal */}
      {isEdit && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="editCustomerModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="editCustomerModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="editCustomerModalLabel">
                  <i className="fal fa-user-tie fa-lg c-purple" />{" "}
                  {helper.i18nMultiFallbackKey(
                    "customer.customerComponent.editCustomerModal.title",
                    "Edit Client"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    clearState();
                    clearModalShowState();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <input type="hidden" value="something" />
                  <div className="form-group">
                    <label
                      htmlFor="customerFirstNameEdit"
                      className={`${errorClassLabel(
                        formErrors.current.firstName
                      )} required`}
                    >
                      {t(
                        "customer.customerComponent.editCustomerModal.fistNameLabel",
                        "First Name"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.current.firstName
                      )}`}
                      id="customerFirstNameEdit"
                      placeholder={helper.i18nMultiFallbackKey(
                        "customer.customerComponent.editCustomerModal.fistNamePlaceholder",
                        "Please enter Client first name"
                      )}
                      value={firstName || ""}
                      onChange={handleUserInput}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                      required
                    />
                    <div className="invalid-feedback">
                      {formErrors.current["firstName"]?.length > 0 && (
                        <p className="text-danger">
                          {t(
                            "customer.customerComponent.editCustomerModal.fistNameInvalid",
                            "First name"
                          )}
                          {formErrors.current["firstName"]}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="customerLastNameEdit"
                      className={`${errorClassLabel(
                        formErrors.current.lastName
                      )} required`}
                    >
                      {t(
                        "customer.customerComponent.editCustomerModal.lastNameLabel",
                        "Last Name"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.current.lastName
                      )}`}
                      id="customerLastNameEdit"
                      placeholder={helper.i18nMultiFallbackKey(
                        "customer.customerComponent.editCustomerModal.lastNamePlaceholder",
                        "Please enter client last name"
                      )}
                      value={lastName || ""}
                      onChange={handleUserInput}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                      required
                    />
                    <div className="invalid-feedback">
                      {formErrors.current["lastName"]?.length > 0 && (
                        <p className="text-danger">
                          {t(
                            "customer.customerComponent.editCustomerModal.lastNameInvalid",
                            "Last name"
                          )}
                          {formErrors.current["lastName"]}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="phoneEdit"
                      className={`${errorClassLabel(
                        formErrors.current.phone
                      )} required`}
                    >
                      {t(
                        "customer.customerComponent.editCustomerModal.phoneLabel",
                        "Phone Number"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.current.phone
                      )}`}
                      id="phoneEdit"
                      placeholder={t(
                        "customer.customerComponent.editCustomerModal.phonePlaceholder",
                        "Phone Number"
                      )}
                      value={phone || ""}
                      onChange={handleUserInput}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                      required
                    />
                    <div className="invalid-feedback">
                      {formErrors.current["phone"]?.length > 0 && (
                        <p className="text-danger">
                          {t(
                            "customer.customerComponent.editCustomerModal.phoneInvalid",
                            "Phone"
                          )}
                          {formErrors.current["phone"]}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="emailEdit"
                      className={`${errorClassLabel(
                        formErrors.current.email
                      )} required`}
                    >
                      {t(
                        "customer.customerComponent.editCustomerModal.emailLabel",
                        "Email"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.current.email
                      )}`}
                      id="emailEdit"
                      placeholder={t(
                        "customer.customerComponent.editCustomerModal.emailPlaceholder",
                        "Email"
                      )}
                      value={email || ""}
                      onChange={handleUserInput}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                    />
                    <div className="invalid-feedback">
                      {formErrors.current["email"]?.length > 0 && (
                        <p className="text-danger">
                          {t(
                            "customer.customerComponent.editCustomerModal.emailInvalid",
                            "Email"
                          )}
                          {formErrors.current["email"]}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="registrationsNumberEdit">
                      {t(
                        "customer.customerComponent.editCustomerModal.registrationNumberLabel",
                        "Registrations number"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="registrationsNumberEdit"
                      placeholder={t(
                        "customer.customerComponent.editCustomerModal.registrationNumberLabel",
                        "Registrations number"
                      )}
                      value={registrationsNumber || ""}
                      onChange={(event) =>
                        setRegistrationsNumber(event.target.value)
                      }
                    />
                  </div>
                  {rolesMap.get("Admin") && (
                    <div className="form-group">
                      <label htmlFor="salonId" className="required">
                        {helper.i18nMultiFallbackKey(
                          "customer.customerComponent.editCustomerModal.shopLabel",
                          "Shop"
                        )}
                      </label>
                      <ConnectedSalonsDropDown
                        salonId={salonId}
                        setSalonId={setSalonId}
                        setServiceTypes={setServiceTypes}
                        setSalonTimeSlot={setSalonTimeSlot}
                        id="salonIdEdit"
                      />
                      <div className="invalid-feedback">
                        {helper.i18nMultiFallbackKey(
                          "customer.customerComponent.editCustomerModal.shopInvalid",
                          "Please select your shop."
                        )}
                      </div>
                    </div>
                  )}
                  <div className="form-group">
                    <label htmlFor="genderIdEdit">
                      {t(
                        "customer.customerComponent.editCustomerModal.genderLabel",
                        "Gender"
                      )}
                    </label>
                    <ConnectedCategoryItemsDropDown
                      categoryType="Gender"
                      categoryItemId={genderId}
                      setCategoryItemId={setGenderId}
                      id="genderIdEdit"
                    />
                  </div>
                  <RequiredFieldsComponent />
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => {
                    clearState();
                    clearModalShowState();
                  }}
                >
                  <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  disabled={!formValid}
                  onClick={() =>
                    saveCustomer(
                      personId,
                      firstName,
                      lastName,
                      phone,
                      email,
                      registrationsNumber,
                      statusId,
                      salonId,
                      genderId,
                      () => {
                        clearState();
                        clearModalShowState();
                      }
                    )
                  }
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delete Customer Modal */}
      {isDelete && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="deleteCustomerModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="deleteCustomerModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="deleteCustomerModalLabel">
                  <i className="fal fa-user-tie fa-lg c-purple" />{" "}
                  {helper.i18nMultiFallbackKey(
                    "customer.customerComponent.deleteCustomerModal.title",
                    "Deactivate Client"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    clearState();
                    clearModalShowState();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <h2 className="info-title-upp-case-xl icon-custom-top-center">
                  <span className="icon-custom is-xl icon-triangle-red-white" />
                  {t(
                    "customer.customerComponent.deleteCustomerModal.attention",
                    "Attention"
                  )}
                </h2>
                <div className="info-message text-center">
                  <p>
                    {" "}
                    {helper.i18nMultiFallbackKey(
                      "customer.customerComponent.deleteCustomerModal.question",
                      "Are you sure you want to deactivate client."
                    )}{" "}
                  </p>
                  <p className="prime-text">
                    {firstName} {lastName}?
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  onClick={() =>
                    deleteCustomer(personId, () => {
                      clearState();
                      clearModalShowState();
                    })
                  }
                >
                  <i className="fal fa-check" /> {t("common.yes", "Yes")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => {
                    clearState();
                    clearModalShowState();
                  }}
                >
                  <i className="fal fa-times" /> {t("common.no", "No")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    activeUser: state.activeUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActiveUser() {
      if (sessionStorage.getItem("user_id")) {
        dispatch(
          mutations.getActiveUser(JSON.parse(sessionStorage.getItem("user_id")))
        );
      }
    },
    createCustomer(
      firstName,
      lastName,
      mobile,
      email,
      registrationsNumber,
      genderId,
      salonId,
      cb
    ) {
      let customer = {
        FirstName: firstName,
        LastName: lastName,
        Mobile: mobile,
        Email: email,
        RegistrationsNumber: registrationsNumber,
        GenderId: genderId,
        SalonId: salonId,
      };
      console.log(customer);
      dispatch(mutations.createCustomer(customer, false, cb));
    },
    saveCustomer(
      personId,
      firstName,
      lastName,
      mobile,
      email,
      registrationsNumber,
      statusId,
      salonId,
      genderId,
      cb
    ) {
      let customer = {
        PersonId: personId,
        FirstName: firstName,
        LastName: lastName,
        Mobile: mobile,
        Email: email,
        RegistrationsNumber: registrationsNumber,
        StatusId: statusId,
        SalonId: salonId,
        GenderId: genderId,
      };
      cb();
      dispatch(mutations.saveCustomer(customer));
    },
    deleteCustomer(personId, cb) {
      let customer = {
        PersonId: personId,
      };
      cb();
      dispatch(mutations.deleteCustomer(customer));
    },
    getCustomersPaged(searchValue, page, sort, cb) {
      const customSetSort = (sort) => {
        let result = [];
        sort.forEach((element) => {
          switch (element.field) {
            case "firstName":
            case "lastName":
            case "email":
            case "mobile":
              result.push({
                field:
                  "Person." +
                  element.field[0].toUpperCase() +
                  element.field.slice(1),
                dir: element.dir,
              });
              break;
            default:
              result.push(element);
              break;
          }
        });

        return result;
      };

      let customSort = customSetSort(sort);
      dispatch(mutations.getCustomersPaged(searchValue, page, customSort, cb));
    },
    // getCustomersPagedForSalon(searchValue, salonId, page, sort, cb) {
    //   const customSetSort = (sort) => {
    //     let result = [];
    //     sort.forEach((element) => {
    //       switch (element.field) {
    //         case "firstName":
    //         case "lastName":
    //         case "email":
    //         case "phone":
    //           result.push({
    //             field:
    //               "Person." +
    //               element.field[0].toUpperCase() +
    //               element.field.slice(1),
    //             dir: element.dir,
    //           });
    //           break;
    //         default:
    //           result.push(element);
    //           break;
    //       }
    //     });

    //     return result;
    //   };

    //   let customSort = customSetSort(sort);
    //   dispatch(
    //     mutations.getCustomersPagedForSalon(
    //       searchValue,
    //       salonId,
    //       page,
    //       customSort,
    //       cb
    //     )
    //   );
    // },
  };
};

const ConnectedCustomer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerComponent);

export default ConnectedCustomer;
