import React, { Component } from "react";
import * as helper from "../HelperRepository";
import { withTranslation } from "react-i18next";

class ImagesColumn_WithoutTranslation extends Component {
  render() {
    return (

      <td>
      <img src={this.props.dataItem.url} alt={this.props.dataItem.url} width="150" height="150"/>
      </td>
    );
  }
}

export const ImagesColumn = withTranslation()(
  ImagesColumn_WithoutTranslation
);
