import React, { useState, Component, useEffect } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ConnectedNewItemButton } from "../NewItemButton";
import { ConnectedNewItemButtonV2 } from "../NewItemButtonV2";
import { useTranslation, withTranslation } from "react-i18next";
import * as helper from "../HelperRepository";

const SalonListComponent = ({
  salons,
  getSalonsPaged,
  totalCount,
  getSalonsForExport,
  setParentState,
  clearParentState,
  setSelectedSalon,
  selectedSalon,
  setIsEdit,
  setIsCreate,
  setIsDelete,
}) => {
  const { t, i18n } = useTranslation();

  const [search, setSearch] = useState("");
  const [exporting, setExporting] = useState(false);
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 10 });
  const debounceGetSalonsPaged = React.useCallback(
    debounce(getSalonsPaged, 700),
    []
  );

  let _export;
  const kendoExport = (searchValue, totalCount) => {
    var innerExport = _export;
    getSalonsForExport(searchValue, totalCount, sort, (salons) => {
      innerExport.save(salons);
    });
  };

  let gridPDFExport;
  const exportPDF = (searchValue, totalCount) => {
    var innerExport = gridPDFExport;
    getSalonsForExport(searchValue, totalCount, sort, (salons) => {
      innerExport.save(salons, pdfExportDone);
    });

    setExporting(true);
  };

  const pdfExportDone = () => {
    setExporting(false);
  };

  useEffect(() => {
    debounceGetSalonsPaged(search, page.skip / 10 + 1, sort);
  }, []);

  const ActionButtonsColumnWithTranslation = withTranslation()(
    ActionButtonsColumn
  );
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  const grid = (
    <Grid
      data={salons.map((item) => ({
        ...item,
        selected: item.salonId === selectedSalon.salonId,
      }))}
      pageable={{
        buttonCount: 3,
        info: true,
        type: "numeric",
        previousNext: true,
      }}
      skip={page.skip}
      take={page.take}
      onPageChange={(event) => {
        setPage({ skip: event.page.skip, take: event.page.take });
        debounceGetSalonsPaged(search, event.page.skip / 10 + 1, sort);
      }}
      total={totalCount}
      sortable
      sort={sort}
      onSortChange={(e) => {
        setSort(e.sort);
        debounceGetSalonsPaged(search, page.skip / 10 + 1, e.sort);
      }}
      style={{ height: "410px" }}
      className="table-header-purple"
      selectedField="selected"
      onRowClick={(e) => {
        if (selectedSalon.salonId === e.dataItem.salonId) {
          setSelectedSalon({});
        } else {
          setSelectedSalon(e.dataItem);
        }
      }}
    >
      <GridColumn
        field="name"
        title={t("salon.salonListComponent.grid.name.title", "Name")}
        className="name-cell"
        headerClassName="name-cell"
      />
      <GridColumn
        field="address"
        title={t("salon.salonListComponent.grid.address.title", "Address")}
        className="address-cell"
        headerClassName="address-cell"
      />
      <GridColumn
        field="webAddress"
        title={t(
          "salon.salonListComponent.grid.webaddress.title",
          "Web address"
        )}
        className="address-cell"
        headerClassName="address-cell"
      />
      <GridColumn
        field="phone"
        title={t("salon.salonListComponent.grid.phone.title", "Phone")}
        className="phone-cell"
        headerClassName="phone-cell"
      />
      <GridColumn
        field="mobilePhone"
        title={t(
          "salon.salonListComponent.grid.mobilephone.title",
          "Mobile phone"
        )}
        className="phone-cell"
        headerClassName="phone-cell"
      />
      <GridColumn
        field="email"
        title={t("salon.salonListComponent.grid.email.title", "Email")}
        className="email-cell"
        headerClassName="email-cell"
      />
      <GridColumn
        field="generalization.description"
        title={t(
          "salon.salonListComponent.grid.generalization.title",
          "Generalization"
        )}
        className="email-cell"
        headerClassName="email-cell"
      />
      <GridColumn
        field="Actions"
        title={t("common.actions", "Actions")}
        cell={(props) => (
          <ActionButtonsColumnWithTranslation
            setParentState={setParentState}
            setIsEdit={setIsEdit}
            setIsCreate={setIsCreate}
            setIsDelete={setIsDelete}
            {...props}
          />
        )}
        headerClassName="actions-cell two-bttns"
      />
    </Grid>
  );
  return (
    <>
      <div className="table-rounded-border table-headlabel-responsive-md salon-table">
        <header>
          <div className="button-container">
            <ConnectedNewItemButtonV2
              clearParentState={clearParentState}
              setIsCreate={setIsCreate}
            />
            <button
              type="button"
              className="btn btn-gray-outline-transp ripple-gray btn-sm ico-l ico-red"
              onClick={() => exportPDF(search, totalCount)}
              disabled={exporting}
            >
              <i className="fal fa-file-pdf"></i>{" "}
              <span className="mobile-hide-550">
                {t("common.exportTo", "Export to")}{" "}
              </span>
              PDF
            </button>
            <button
              type="button"
              className="btn btn-gray-outline-transp ripple-gray btn-sm ico-l ico-green"
              onClick={() => kendoExport(search, totalCount)}
            >
              <i className="fal fa-file-excel"></i>{" "}
              <span className="mobile-hide-550">
                {t("common.exportTo", "Export to")}{" "}
              </span>
              Excel
            </button>
          </div>
          <div className="search-holder">
            <input
              type="text"
              id="inputEmail"
              className="form-control"
              placeholder={t("common.searchPlaceholder", "Search...")}
              value={search}
              onChange={(event) => {
                setPage({ skip: 0, take: 10 });
                setSearch(event.target.value);
                debounceGetSalonsPaged(event.target.value, 1, sort);
              }}
            ></input>
            <button type="button" className="btn ico-btn-search">
              <i className="fal fa-search"></i>
            </button>
          </div>
          <div className="actions-holder"></div>
        </header>
        <ExcelExport
          ref={(exporter) => (_export = exporter)}
          fileName={`Salons${new Date().getTime()}.xlsx`}
        >
          <ExcelExportColumn
            field="name"
            title={t("salon.salonListComponent.grid.name.title", "Name")}
            width={350}
          />
          <ExcelExportColumn
            field="address"
            title={t("salon.salonListComponent.grid.address.title", "Address")}
            width={350}
          />
          <ExcelExportColumn
            field="webAddress"
            title={t(
              "salon.salonListComponent.grid.webaddress.title",
              "Web address"
            )}
            width={150}
          />
          <ExcelExportColumn
            field="phone"
            title={t("salon.salonListComponent.grid.phone.title", "Phone")}
            width={150}
          />
          <ExcelExportColumn
            field="mobilePhone"
            title={t(
              "salon.salonListComponent.grid.mobilephone.title",
              "Mobile phone"
            )}
            width={150}
          />
          <ExcelExportColumn
            field="email"
            title={t("salon.salonListComponent.grid.email.title", "Email")}
            width={150}
          />
          <ExcelExportColumn
            field="generalization.description"
            title={t(
              "salon.salonListComponent.grid.generalization.title",
              "Generalization"
            )}
            width={150}
          />
        </ExcelExport>
        {grid}
        <GridPDFExport
          ref={(pdfExport) => (gridPDFExport = pdfExport)}
          fileName={`Salons${new Date().getTime()}.pdf`}
          margin="1cm"
        >
          <GridColumn
            field="name"
            title={t("salon.salonListComponent.grid.name.title", "Name")}
          />
          <GridColumn
            field="address"
            title={t("salon.salonListComponent.grid.address.title", "Address")}
          />
          <GridColumn
            field="webAddress"
            title={t(
              "salon.salonListComponent.grid.webaddress.title",
              "Web address"
            )}
          />
          <GridColumn
            field="phone"
            title={t("salon.salonListComponent.grid.phone.title", "Phone")}
          />
          <GridColumn
            field="mobilePhone"
            title={t(
              "salon.salonListComponent.grid.mobilephone.title",
              "Mobile phone"
            )}
          />
          <GridColumn
            field="email"
            title={t("salon.salonListComponent.grid.email.title", "Email")}
          />
          <GridColumn
            field="generalization.description"
            title={t(
              "salon.salonListComponent.grid.generalization.title",
              "Generalization"
            )}
          />

          {grid}
        </GridPDFExport>
      </div>
    </>
  );
};

class ActionButtonsColumn extends Component {
  render() {
    return (
      <td className="actions-cell two-bttns">
        <div className="button-container">
          <button
            type="button"
            className="btn btn-outline-success btn-sm ico-l"
            // data-toggle="modal"
            // data-target="#editSalonModal"
            onClick={() => {
              this.props.setParentState(this.props.dataItem);
              this.props.setIsEdit(true);
              helper.showModal("#editSalonModal");
            }}
          >
            <i className="fal fa-edit"></i>{" "}
            {this.props.t("common.edit", "Edit")}
          </button>
          <button
            type="button"
            className="btn btn-outline-danger btn-sm ico-l"
            data-toggle="modal"
            data-target="#deleteSalonModal"
            onClick={() => {
              this.props.setParentState(this.props.dataItem);
              this.props.setIsDelete(true);
              helper.showModal("#deleteSalonModal");
            }}
          >
            <i className="fal fa-trash-alt"></i>{" "}
            {this.props.t("common.deactivate", "Deactivate")}
          </button>
        </div>
      </td>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    salons: state.salons,
    totalCount: state.totalCount,
    setParentState: ownProps.setParentState,
    setSelectedSalon: ownProps.setSelectedSalon,
    setParentSearch: ownProps.setParentSearch,
    selectedSalon: ownProps.selectedSalon,
    setIsEdit: ownProps.setIsEdit,
    setIsCreate: ownProps.setIsCreate,
    setIsDelete: ownProps.setIsDelete,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getSalonsPaged(searchValue, page, sort) {
    dispatch(mutations.getSalonsPaged(searchValue, page, sort));
  },
  getSalonsForExport(searchValue, totalCount, sort, cb) {
    dispatch(mutations.getSalonsForExport(searchValue, totalCount, sort, cb));
  },
});

export const ConnectedSalonList = connect(
  mapStateToProps,
  mapDispatchToProps
)(SalonListComponent);
