import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { useTranslation } from "react-i18next";

const CategoryItemsDropDownComponentV2 = ({
  categoryId,
  categoryType,
  categoryItems,
  categoryItemId,
  setCategoryItemId,
  getAllCategoryItemsForCategory
}) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getAllCategoryItemsForCategory({
      Property: "CategoryId",
      Value: categoryId,
      Type: "guid"
    });
  }, []);

  return (
    <div className="custom-select-box">
      <select
        className="form-control"
        value={categoryItemId}
        onChange={event => {
          if (event.target.value !== "") {
            let categoryItemList = categoryItems.filter(
              ci => ci.categoryItemId === event.target.value
            );
            const [categoryItem, ...rest] = categoryItemList;

            setCategoryItemId(categoryItem.categoryItemId);
          } else {
            setCategoryItemId("");
          }
        }}
        required
      >
        <option key={""} value={""}>
          {t("dropdowns.CategoryItemsDropDownComponentV2.label", "Select")}{" "}
          {`${categoryType}`}
        </option>
        {categoryItems.map(r => {
          return (
            <option key={r.categoryItemId} value={r.categoryItemId}>
              {r.itemName}
            </option>
          );
        })}
      </select>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    categoryItems: state.categoryItems,
    categoryId: ownProps.categoryId,
    categoryType: ownProps.categoryType,
    categoryItemId: ownProps.categoryItemId,
    setCategoryItemId: ownProps.setCategoryItemId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getActiveUser() {
      if (sessionStorage.getItem("user_id")) {
        dispatch(
          mutations.getActiveUser(JSON.parse(sessionStorage.getItem("user_id")))
        );
      }
    },

    getAllCategoryItemsForCategory(filterObject) {
      dispatch(mutations.getAllCategoryItemsForCategory(filterObject));
    }
  };
};

export const ConnectedCategoryItemsDropDownV2 = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryItemsDropDownComponentV2);
