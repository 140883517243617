import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { useTranslation, withTranslation } from "react-i18next";

const SalonTest = ({ activeUser, getActiveUser }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    console.log("------------- tip", typeof getActiveUser());
    if (!activeUser || !activeUser.personId) {
      getActiveUser();
    }
    return () => {
      document.getElementsByTagName("html")[0].classList.remove("grid-view");
    };
  }, []);

  return (
    <div>
      <div>{activeUser.firstName}</div>
      <div>{activeUser.lastaName}</div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    activeUser: state.activeUser,
    selectedSalon: ownProps.selectedSalon,
    clearCheckboxSystem: ownProps.clearCheckboxSystem
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getActiveUser() {
      if (sessionStorage.getItem("user_id")) {
        dispatch(
          mutations.getActiveUser(JSON.parse(sessionStorage.getItem("user_id")))
        );
      }
    }
  };
};

export const ConnectedSalonTest = connect(
  mapStateToProps,
  mapDispatchToProps
)(SalonTest);
