import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import * as moment from "moment";
import * as Helper from "../HelperRepository";
import { withTranslation } from "react-i18next";

class FreeSalonSlotsPopupComponent extends Component {
  state = {
    checkboxInputs: [],
    initialLoad: true,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.initialLoad || props.freeSalonTimeSlots.length <= 0) {
      return null;
    }
    let a = [];
    if (state.checkboxInputs.length > 0) {
      if (
        moment(state.checkboxInputs[0].startTime).format("YYYY-MM-DD") !==
        moment(props.freeSalonTimeSlots[0].startTime).format("YYYY-MM-DD")
      ) {
        a = [...Helper.cloneDeep(props.freeSalonTimeSlots)];
      } else {
        a = [...Helper.cloneDeep(state.checkboxInputs)];
      }
    }

    if (!props.editType) {
      a.map((item, index) => {
        if (item.timeSlotFree) {
          if (
            props.startTime >
              moment(item.startTime, "YYYY-MM-DDTHH:mm:ss").toDate() ||
            moment(item.endTime, "YYYY-MM-DDTHH:mm:ss").toDate() > props.endTime
          ) {
            item.salonTimeSlot = false;
            item.timeSlotFree = true;
          } else {
            item.salonTimeSlot = true;
            item.timeSlotFree = true;
          }
        }
        return null;
      });
    }

    return {
      checkboxInputs: [...Helper.cloneDeep(a)],
    };
  }

  componentDidMount() {
    if (this.state.initialLoad) {
      this.setState({ initialLoad: false }, () => {
        this.reload();
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      checkboxInputs: [],
      initialLoad: true,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visitDate !== this.props.visitDate) {
      this.reload();
    }

    if (
      !Helper.isArrayEqual(
        prevProps.freeSalonTimeSlots,
        this.props.freeSalonTimeSlots
      )
    ) {
      this.setState({
        checkboxInputs: [...Helper.cloneDeep(this.props.freeSalonTimeSlots)],
      });
    }
  }

  reload = () => {
    this.props.getAllFreeSalonTimeSlots(
      this.props.selectedSalon.salonId,
      this.props.visitDate
    );
  };

  slotType = (slotData) => {
    if (slotData.timeSlotFree && !slotData.salonTimeSlot) {
      return "time-item free";
    }

    if (!slotData.timeSlotFree && !slotData.salonTimeSlot) {
      return "time-item selected";
    }

    if (slotData.timeSlotFree && slotData.salonTimeSlot) {
      return "time-item changed";
    }

    if (!slotData.timeSlotFree && slotData.salonTimeSlot) {
      if (this.props.editType) {
        return "time-item changed";
      } else {
        return "time-item choosed";
      }
    }
  };

  initializeCheckboxItemArray = (inputs) => {
    this.setState(
      {
        checkboxInputs: [...Helper.cloneDeep(inputs)],
      },
      () => {
        let a = [];
        this.state.checkboxInputs.forEach((item, index) => {
          if (this.props.editType) {
            a.push({
              value:
                inputs[index].salonTimeSlot &&
                (inputs[index].timeSlotFree || !inputs[index].timeSlotFree),
              startTime: moment(item.startTime).format("HH:mm"),
              endTime: moment(item.endTime).format("HH:mm"),
            });
          } else {
            a.push({
              value: inputs[index].salonTimeSlot && inputs[index].timeSlotFree,
              startTime: moment(item.startTime).format("HH:mm"),
              endTime: moment(item.endTime).format("HH:mm"),
            });
          }
        });
        this.props.setCheckboxInputs(a);
      }
    );
  };

  render() {
    return (
      <>
        <div className="form-group">
          <div className="date-time-picker modal-picker">
            <div className="date-time-content flex-wrap justify-content-around">
              <div className="legend-bar">
                <span className="reserved">
                  {this.props.t(
                    "salon.freeSalonSlotsPopupComponent.reserved",
                    "Reserved"
                  )}
                </span>
                <span className="free">
                  {this.props.t(
                    "salon.freeSalonSlotsPopupComponent.free",
                    "Free"
                  )}
                </span>
                <span className="choosed">
                  {this.props.t(
                    "salon.freeSalonSlotsPopupComponent.choosed",
                    "Choosed"
                  )}
                </span>
                <span className="changed">
                  {this.props.t(
                    "salon.freeSalonSlotsPopupComponent.changed",
                    "Changed"
                  )}
                </span>
              </div>
              {/* {this.props.freeSalonTimeSlots.map((r, index) => { */}

              {this.state.checkboxInputs.map((r, index) => {
                if (r.timeSlotFree && !r.salonTimeSlot) {
                  return (
                    <div
                      className={this.slotType(r)}
                      key={r.timeIntervalGuid}
                      onClick={(event) => {
                        let inputs = [
                          ...Helper.cloneDeep(this.state.checkboxInputs),
                        ];
                        inputs[index].salonTimeSlot = true;

                        this.initializeCheckboxItemArray(inputs);
                      }}
                      required
                    >
                      {moment(r.startTime).format("HH:mm")}
                    </div>
                  );
                }
                if (r.timeSlotFree && r.salonTimeSlot) {
                  return (
                    <div
                      className={this.slotType(r)}
                      key={r.timeIntervalGuid}
                      onClick={(event) => {
                        let inputs = [
                          ...Helper.cloneDeep(this.state.checkboxInputs),
                        ];

                        inputs[index].salonTimeSlot = false;

                        this.initializeCheckboxItemArray(inputs);
                      }}
                      required
                    >
                      {moment(r.startTime).format("HH:mm")}
                    </div>
                  );
                }
                if (!r.timeSlotFree && r.salonTimeSlot) {
                  return (
                    <div
                      className={this.slotType(r)}
                      key={r.timeIntervalGuid}
                      onClick={(event) => {
                        if (this.props.editType) {
                          let inputs = [
                            ...Helper.cloneDeep(this.state.checkboxInputs),
                          ];

                          inputs[index].salonTimeSlot = false;
                          inputs[index].timeSlotFree = true;

                          this.initializeCheckboxItemArray(inputs);
                        }
                      }}
                      required
                    >
                      {moment(r.startTime).format("HH:mm")}
                    </div>
                  );
                }

                return (
                  <div
                    className={this.slotType(r)}
                    key={r.timeIntervalGuid}
                    required
                  >
                    {moment(r.startTime).format("HH:mm")}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToPropsFreeSlots = (state, ownProps) => {
  return {
    visitDate: ownProps.visitDate,
    startTime: ownProps.startTime,
    endTime: ownProps.endTime,
    freeSalonTimeSlots: state.freeSalonTimeSlots,
    setCheckboxInputs: ownProps.setCheckboxInputs,
    selectedSalon: ownProps.selectedSalon,
    editType: ownProps.editType,
  };
};

const mapDispatchToPropsFreeSlots = (dispatch) => ({
  getAllFreeSalonTimeSlots(salonId, visitDate) {
    let freeSalonTimeSlotRequest = {
      SalonId: salonId,
      VisitDate: visitDate,
    };
    dispatch(mutations.getAllFreeSalonTimeSlots(freeSalonTimeSlotRequest));
  },
});

export const ConnectedFreeSalonSlotsPopupComponent = withTranslation()(
  connect(
    mapStateToPropsFreeSlots,
    mapDispatchToPropsFreeSlots
  )(FreeSalonSlotsPopupComponent)
);
