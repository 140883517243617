import React, { useState, Component, useEffect } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ConnectedNewItemButton } from "../NewItemButton";
import { ConnectedNewItemButtonV2 } from "../NewItemButtonV2";

import * as helper from "../HelperRepository";
import { ActionButtonsColumn } from "./ActionButtonsColumn";
import { useTranslation, withTranslation } from "react-i18next";

const CustomerList = ({
  customers,
  getCustomersPaged,
  // getCustomersPagedForSalon,
  totalCount,
  getCustomersForExport,
  setParentState,
  clearParentState,
  activeUser,
  pageState,
  clearCustomers,
  setIsEdit,
  setIsCreate,
  setIsDelete,
}) => {
  const { t, i18n } = useTranslation();

  const [search, setSearch] = useState("");
  const [exporting, setExporting] = useState(false);
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 10 });
  const [rolesMap, setRolesMap] = useState(() => {
    return helper.createMapObject();
  });
  const debounceGetCustomersPaged = React.useCallback(
    debounce(getCustomersPaged, 700),
    []
  );

  let _export;
  const kendoExport = (searchValue, totalCount) => {
    var innerExport = _export;
    getCustomersForExport(searchValue, totalCount, sort, (customers) => {
      innerExport.save(customers);
    });
  };

  let gridPDFExport;
  const exportPDF = (searchValue, totalCount) => {
    var innerExport = gridPDFExport;
    getCustomersForExport(searchValue, totalCount, sort, (customers) => {
      innerExport.save(customers, pdfExportDone);
    });

    setExporting(true);
  };

  const pdfExportDone = () => {
    setExporting(false);
  };

  // useEffect(() => {
  //   getCustomersPaged(search, page.skip / 10 + 1, sort);
  // }, []);

  useEffect(() => {
    clearCustomers();
  }, []);

  useEffect(() => {
    if (rolesMap.get("Admin")) {
      debounceGetCustomersPaged(search, page.skip / 10 + 1, sort);
    } else {
      if (rolesMap.get("Salon admin")) {
        // getCustomersPagedForSalon(
        debounceGetCustomersPaged(
          search,
          // activeUser.userRoles[0].externalId,
          page.skip / 10 + 1,
          sort
        );
      }
    }
  }, [rolesMap]);

  useEffect(() => {
    setRolesMap(helper.mapForRole(activeUser));
  }, [activeUser]);

  useEffect(() => {
    setPage({ skip: (pageState - 1) * 10, take: 10 });
  }, [pageState]);

  const ContactColumnWithTranslation = withTranslation()(ContactColumn);
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  const grid = (
    <Grid
      data={customers}
      pageable={{
        buttonCount: 3,
        info: true,
        type: "numeric",
        previousNext: true,
      }}
      skip={page.skip}
      take={page.take}
      onPageChange={(event) => {
        setPage({ skip: event.page.skip, take: event.page.take });
        if (rolesMap.get("Admin")) {
          debounceGetCustomersPaged(search, event.page.skip / 10 + 1, sort);
        } else {
          if (rolesMap.get("Salon admin")) {
            // getCustomersPagedForSalon(
            debounceGetCustomersPaged(
              search,
              // activeUser.userRoles[0].externalId,
              event.page.skip / 10 + 1,
              sort
            );
          }
        }
      }}
      total={totalCount}
      sortable
      sort={sort}
      onSortChange={(e) => {
        setSort(e.sort);
        if (rolesMap.get("Admin")) {
          debounceGetCustomersPaged(search, page.skip / 10 + 1, e.sort);
        } else {
          if (rolesMap.get("Salon admin")) {
            // getCustomersPagedForSalon(
            debounceGetCustomersPaged(
              search,
              // activeUser.userRoles[0].externalId,
              page.skip / 10 + 1,
              e.sort
            );
          }
        }
      }}
      style={{ height: "410px" }}
      className="table-header-purple"
    >
      <GridColumn
        field="firstName"
        title={t(
          "customer.customerListComponent.grid.firstname.title",
          "First name"
        )}
        className="first-name-cell"
        headerClassName="first-name-cell"
      />
      <GridColumn
        field="lastName"
        title={t(
          "customer.customerListComponent.grid.lastname.title",
          "Last name"
        )}
        className="last-name-cell"
        headerClassName="last-name-cell"
      />
      <GridColumn
        field="email"
        title={t("customer.customerListComponent.grid.email.title", "Email")}
        className="email-cell"
        headerClassName="email-cell"
      />
      <GridColumn
        field="mobile"
        title={t("customer.customerListComponent.grid.mobile.title", "Phone")}
        cell={(props) => <ContactColumnWithTranslation {...props} />}
        headerClassName="contact-cell"
      />
      <GridColumn
        field="Actions"
        title={t("common.actions", "Actions")}
        cell={(props) => (
          <ActionButtonsColumn
            setParentState={setParentState}
            setIsEdit={setIsEdit}
            setIsDelete={setIsDelete}
            {...props}
          />
        )}
        headerClassName="actions-cell two-bttns"
      />
    </Grid>
  );

  return (
    <>
      <div className="table-rounded-border table-headlabel-responsive-md user-table">
        <header>
          <div className="button-container">
            <ConnectedNewItemButtonV2
              clearParentState={clearParentState}
              setIsCreate={setIsCreate}
            />
            <button
              type="button"
              className="btn btn-gray-outline-transp ripple-gray btn-sm ico-l ico-red"
              onClick={() => exportPDF(search, totalCount)}
              disabled={exporting}
            >
              <i className="fal fa-file-pdf"></i>{" "}
              <span className="mobile-hide-550">
                {t("common.exportTo", "Export to")}{" "}
              </span>
              PDF
            </button>
            <button
              type="button"
              className="btn btn-gray-outline-transp ripple-gray btn-sm ico-l ico-green"
              onClick={() => kendoExport(search, totalCount)}
            >
              <i className="fal fa-file-excel"></i>{" "}
              <span className="mobile-hide-550">
                {t("common.exportTo", "Export to")}{" "}
              </span>
              Excel
            </button>
          </div>
          <div className="search-holder">
            <input
              type="text"
              id="inputEmail"
              className="form-control"
              placeholder={t("common.searchPlaceholder", "Search...")}
              value={search}
              onChange={(event) => {
                setPage({ skip: 0, take: 10 });
                setSearch(event.target.value);
                if (rolesMap.get("Admin")) {
                  debounceGetCustomersPaged(event.target.value, 1, sort);
                } else {
                  if (rolesMap.get("Salon admin")) {
                    // getCustomersPagedForSalon(
                    debounceGetCustomersPaged(
                      event.target.value,
                      // activeUser.userRoles[0].externalId,
                      1,
                      sort
                    );
                  }
                }
              }}
            ></input>
            <button type="button" className="btn ico-btn-search">
              <i className="fal fa-search"></i>
            </button>
          </div>
          <div className="actions-holder"></div>
        </header>
        <ExcelExport
          ref={(exporter) => (_export = exporter)}
          fileName={`Customers${new Date().getTime()}.xlsx`}
        >
          <ExcelExportColumn
            field="firstName"
            title={t(
              "customer.customerListComponent.grid.firstname.title",
              "First name"
            )}
            width={150}
          />
          <ExcelExportColumn
            field="lastName"
            title={t(
              "customer.customerListComponent.grid.lastname.title",
              "Last name"
            )}
            width={150}
          />
          <ExcelExportColumn
            field="email"
            title={t(
              "customer.customerListComponent.grid.email.title",
              "Email"
            )}
            width={350}
          />
          <ExcelExportColumn
            field="mobile"
            title={t(
              "customer.customerListComponent.grid.mobile.title",
              "Phone"
            )}
            width={150}
          />
        </ExcelExport>
        {grid}
        <GridPDFExport
          ref={(pdfExport) => (gridPDFExport = pdfExport)}
          fileName={`Customers${new Date().getTime()}.pdf`}
          margin="1cm"
        >
          <GridColumn
            field="firstName"
            title={t(
              "customer.customerListComponent.grid.firstname.title",
              "First name"
            )}
          />
          <GridColumn
            field="lastName"
            title={t(
              "customer.customerListComponent.grid.lastname.title",
              "Last name"
            )}
          />
          <GridColumn
            field="email"
            title={t(
              "customer.customerListComponent.grid.email.title",
              "Email"
            )}
          />
          <GridColumn
            field="mobile"
            title={t(
              "customer.customerListComponent.grid.mobile.title",
              "Phone"
            )}
          />
          {grid}
        </GridPDFExport>
      </div>
    </>
  );
};

class ContactColumn extends Component {
  render() {
    return (
      <td className="contact-cell">
        <span className="mobile-view-title">
          {this.props.t(
            "customer.customerListComponent.grid.mobile.mobileView",
            "Phone:"
          )}
        </span>
        <span>{this.props.dataItem.mobile}</span>
      </td>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeUser: state.activeUser,
    customers: state.customers,
    totalCount: state.totalCount,
    pageState: state.page,
    setParentState: ownProps.setParentState,
    clearParentState: ownProps.clearParentState,
    setIsEdit: ownProps.setIsEdit,
    setIsCreate: ownProps.setIsCreate,
    setIsDelete: ownProps.setIsDelete,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCustomersPaged(searchValue, page, sort) {
    const customSetSort = (sort) => {
      let result = [];
      sort.forEach((element) => {
        switch (element.field) {
          case "firstName":
          case "lastName":
          case "email":
          case "mobile":
            result.push({
              field:
                "Person." +
                element.field[0].toUpperCase() +
                element.field.slice(1),
              dir: element.dir,
            });
            break;
          default:
            result.push(element);
            break;
        }
      });

      return result;
    };

    let customSort = customSetSort(sort);
    dispatch(
      mutations.getCustomersPaged(searchValue, page, customSort, () => {})
    );
  },
  // getCustomersPagedForSalon(searchValue, salonId, page, sort) {
  //   const customSetSort = (sort) => {
  //     let result = [];
  //     sort.forEach((element) => {
  //       switch (element.field) {
  //         case "firstName":
  //         case "lastName":
  //         case "email":
  //         case "phone":
  //           result.push({
  //             field:
  //               "Person." +
  //               element.field[0].toUpperCase() +
  //               element.field.slice(1),
  //             dir: element.dir,
  //           });
  //           break;
  //         default:
  //           result.push(element);
  //           break;
  //       }
  //     });

  //     return result;
  //   };

  //   let customSort = customSetSort(sort);
  //   dispatch(
  //     mutations.getCustomersPagedForSalon(
  //       searchValue,
  //       salonId,
  //       page,
  //       customSort,
  //       () => {}
  //     )
  //   );
  // },
  getCustomersForExport(searchValue, totalCount, sort, cb) {
    dispatch(
      mutations.getCustomersForExport(searchValue, totalCount, sort, cb)
    );
  },
  clearCustomers() {
    dispatch(
      mutations.setCustomers({
        resultList: [],
        pageNumber: 1,
        totalCount: 0,
      })
    );
  },
});

export const ConnectedCustomerList = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerList);
