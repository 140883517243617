import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ConnectedNewItemButtonV2 } from "../NewItemButtonV2";
import { useTranslation } from "react-i18next";
import { ActionButtonsColumn } from "./ActionButtonsColumn";

const GeneralizationListComponent = ({
  generalizations,
  getGeneralizationsPaged,
  totalCount,
  getGeneralizationsForExport,
  setParentState,
  setParentSearch,
  clearParentState,
  pageState,
  setIsEdit,
  setIsCreate,
  setIsDelete,
  selectedGeneralization,
  setSelectedGeneralization,
  editModal,
  deleteModal,
}) => {
  const { t, i18n } = useTranslation();

  const [search, setSearch] = useState("");
  const [exporting, setExporting] = useState(false);
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 10 });

  const debounceGetGeneralizationsPaged = React.useCallback(
    debounce(getGeneralizationsPaged, 700),
    []
  );

  let _export;
  const kendoExport = (searchValue, totalCount) => {
    var innerExport = _export;
    getGeneralizationsForExport(
      searchValue,
      totalCount,
      sort,
      (generalizations) => {
        innerExport.save(generalizations);
      }
    );
  };

  let gridPDFExport;
  const exportPDF = (searchValue, totalCount) => {
    var innerExport = gridPDFExport;
    getGeneralizationsForExport(
      searchValue,
      totalCount,
      sort,
      (generalizations) => {
        innerExport.save(generalizations, pdfExportDone);
      }
    );

    setExporting(true);
  };

  const pdfExportDone = () => {
    setExporting(false);
  };

  useEffect(() => {
    getGeneralizationsPaged(search, page.skip / 10 + 1, sort);
  }, []);

  useEffect(() => {
    setPage({ skip: (pageState - 1) * 10, take: 10 });
  }, [pageState]);

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  const grid = (
    <Grid
      data={generalizations.map((item) => ({
        ...item,
        selected:
          item.generalizationId === selectedGeneralization.generalizationId,
      }))}
      pageable={{
        buttonCount: 3,
        info: true,
        type: "numeric",
        previousNext: true,
      }}
      skip={page.skip}
      take={page.take}
      onPageChange={(event) => {
        setPage({ skip: event.page.skip, take: event.page.take });
        debounceGetGeneralizationsPaged(search, event.page.skip / 10 + 1, sort);
      }}
      total={totalCount}
      sortable
      sort={sort}
      onSortChange={(e) => {
        setSort(e.sort);
        debounceGetGeneralizationsPaged(search, page.skip / 10 + 1, e.sort);
      }}
      style={{ height: "410px" }}
      className="table-header-purple"
    >
      <GridColumn
        field="shopTypeName"
        title={t(
          "generalization.generalizationListComponent.grid.shopTypeName.title",
          "Shop type name"
        )}
        className="username-cell"
        headerClassName="username-cell"
      />
      <GridColumn
        field="description"
        title={t(
          "generalization.generalizationListComponent.grid.description.title",
          "Description"
        )}
        className="username-cell"
        headerClassName="username-cell"
      />
      <GridColumn
        field="Actions"
        title={t("common.actions", "Actions")}
        cell={(props) => (
          <ActionButtonsColumn
            setParentState={setParentState}
            setIsEdit={setIsEdit}
            setIsCreate={setIsCreate}
            setIsDelete={setIsDelete}
            editModal={editModal}
            deleteModal={deleteModal}
            {...props}
          />
        )}
        headerClassName="actions-cell two-bttns"
      />
    </Grid>
  );

  return (
    <>
      <div className="table-rounded-border table-headlabel-responsive-md user-table">
        <header>
          <div className="button-container">
            {/* <ConnectedNewItemButton clearParentState={clearParentState} /> */}
            <ConnectedNewItemButtonV2
              clearParentState={clearParentState}
              setIsCreate={setIsCreate}
            />
            <button
              type="button"
              className="btn btn-gray-outline-transp ripple-gray btn-sm ico-l ico-red"
              onClick={() => exportPDF(search, totalCount)}
              disabled={exporting}
            >
              <i className="fal fa-file-pdf"></i>{" "}
              <span className="mobile-hide-550">
                {t("common.exportTo", "Export to")}{" "}
              </span>
              PDF
            </button>
            <button
              type="button"
              className="btn btn-gray-outline-transp ripple-gray btn-sm ico-l ico-green"
              onClick={() => kendoExport(search, totalCount)}
            >
              <i className="fal fa-file-excel"></i>{" "}
              <span className="mobile-hide-550">
                {t("common.exportTo", "Export to")}{" "}
              </span>
              Excel
            </button>
          </div>
          <div className="search-holder">
            <input
              type="text"
              id="inputEmail"
              className="form-control"
              placeholder={t("common.searchPlaceholder", "Search...")}
              value={search}
              onChange={(event) => {
                setPage({ skip: 0, take: 10 });
                setSearch(event.target.value);
                setParentSearch(event.target.value);
                getGeneralizationsPaged(event.target.value, 1, sort);
              }}
            ></input>
            <button type="button" className="btn ico-btn-search">
              <i className="fal fa-search"></i>
            </button>
          </div>
          <div className="actions-holder"></div>
        </header>
        <ExcelExport
          ref={(exporter) => (_export = exporter)}
          fileName={`Generalizations${new Date().getTime()}.xlsx`}
        >
          <ExcelExportColumn
            field="shopTypeName"
            title={t(
              "generalization.generalizationListComponent.grid.shopTypeName.title",
              "Shop type name"
            )}
            width={350}
          />
          <ExcelExportColumn
            field="description"
            title={t(
              "generalization.generalizationListComponent.grid.description.title",
              "Description"
            )}
            width={350}
          />
        </ExcelExport>
        {grid}
        <GridPDFExport
          ref={(pdfExport) => (gridPDFExport = pdfExport)}
          fileName={`Generalizations${new Date().getTime()}.pdf`}
          margin="1cm"
        >
          <GridColumn
            field="shopTypeName"
            title={t(
              "generalization.generalizationListComponent.grid.shopTypeName.title",
              "Shop type name"
            )}
          />
          <GridColumn
            field="description"
            title={t(
              "generalization.generalizationListComponent.grid.description.title",
              "Description"
            )}
          />
          {grid}
        </GridPDFExport>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    generalizations: state.generalizations,
    totalCount: state.totalCount,
    pageState: state.hairstylistPage,
    setParentState: ownProps.setParentState,
    setParentSearch: ownProps.setParentSearch,
    setIsEdit: ownProps.setIsEdit,
    setIsCreate: ownProps.setIsCreate,
    setIsDelete: ownProps.setIsDelete,
    setSelectedGeneralization: ownProps.setSelectedGeneralization,
    selectedGeneralization: ownProps.selectedGeneralization,
    editModal: ownProps.editModal,
    deleteModal: ownProps.deleteModal,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getGeneralizationsPaged(searchValue, page, sort) {
    const customSetSort = (sort) => {
      let result = [];
      sort.forEach((element) => {
        switch (element.field) {
          case "firstName":
          case "lastName":
          case "email":
          case "phone":
            result.push({
              field:
                "Person." +
                element.field[0].toUpperCase() +
                element.field.slice(1),
              dir: element.dir,
            });
            break;
          default:
            result.push(element);
            break;
        }
      });

      return result;
    };

    let customSort = customSetSort(sort);
    dispatch(mutations.getGeneralizationsPaged(searchValue, page, customSort));
  },
  getGeneralizationsForExport(searchValue, totalCount, sort, cb) {
    dispatch(
      mutations.getGeneralizationsForExport(searchValue, totalCount, sort, cb)
    );
  },
});

export const ConnectedGeneralizationList = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralizationListComponent);
