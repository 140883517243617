import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import * as moment from "moment";
import { withTranslation } from "react-i18next";

class FreeSlotsComponent extends Component {
  componentDidMount() {
    this.setState({ initialLoad: true });
  }
  componentDidUpdate(prevProps) {
    if (this.props.visitDate !== prevProps.visitDate) {
      this.setState({ initialLoad: true });
    }
    if (this.props.hairstylistId !== prevProps.hairstylistId) {
      this.setState({ initialLoad: true });
    }
    if (this.props.salonId !== prevProps.salonId) {
      this.setState({ initialLoad: true });
    }
    if (
      this.props.salonId !== "" &&
      this.props.hairstylistId !== "" &&
      this.props.selectedServices.length > 0 &&
      this.props.visitDate !== "" &&
      this.props.visitDate !== "Invalid date"
    ) {
      if (this.state.initialLoad) {
        this.setState({ initialLoad: false });
        this.reload();
      }
    }
  }
  reload = () => {
    this.props.getAllFreeVisitTimeSlots(
      this.props.salonId,
      this.props.hairstylistId,
      this.props.visitDate,
      this.props.selectedServices
    );
  };

  available = (status) => {
    if (status) {
      return "yes";
    } else {
      return "no";
    }
  };
  render() {
    if (
      this.props.salonId !== "" &&
      this.props.hairstylistId !== "" &&
      this.props.selectedServices.length > 0 &&
      this.props.visitDate !== "" &&
      this.props.visitDate !== "Invalid date"
    ) {
      return (
        <>
          <table>
            <tbody id="your-table-body-id">
              <tr>
                <th width="120px">
                  {this.props.t("home.freeSlotsComponent.dateLabel", "Date")}
                </th>
                <th width="120px">
                  {this.props.t(
                    "home.freeSlotsComponent.startTimeLabel",
                    "Start time"
                  )}
                </th>
                <th width="120px">
                  {this.props.t(
                    "home.freeSlotsComponent.availableLabel",
                    "Available"
                  )}
                </th>
                <th width="120px">
                  {this.props.t("home.freeSlotsComponent.visitLabel", "Visit")}
                </th>
              </tr>
              {this.props.freeVisitTimeSlots.map((r) => {
                return (
                  <>
                    <tr key={r.timeIntervalGuid}>
                      <td>
                        {moment(this.props.visitDate).format("YYYY-MM-DD")}
                      </td>
                      <td>{moment(r.startTime).format("HH:mm")}</td>
                      <td>{this.available(r.timeSlotFree)}</td>
                      <td>{this.available(r.visitTimeSlot)}</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </>
      );
    } else {
      return null;
    }
  }
}

const mapStateToPropsFreeSlots = (state, ownProps) => {
  return {
    salons: state.salons,
    salonId: ownProps.salonId,
    hairstylistId: ownProps.hairstylistId,
    selectedServices: ownProps.selectedServices,
    visitDate: ownProps.visitDate,
    freeVisitTimeSlots: state.freeVisitTimeSlots,
  };
};

const mapDispatchToPropsFreeSlots = (dispatch) => ({
  getAllFreeVisitTimeSlots(
    salonId,
    hairstylistId,
    visitDate,
    selectedServices
  ) {
    let freeTimeSlotRequest = {
      SalonId: salonId,
      HairstylistId: hairstylistId,
      VisitDate: visitDate,
      SelectedServices: selectedServices,
    };
    dispatch(mutations.getAllFreeVisitTimeSlots(freeTimeSlotRequest));
  },
});

export const ConnectedFreeSlotsComponent = withTranslation()(
  connect(
    mapStateToPropsFreeSlots,
    mapDispatchToPropsFreeSlots
  )(FreeSlotsComponent)
);
