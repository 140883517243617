import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import * as moment from "moment";
import { ConnectedSalonsDropDown } from "../Dropdowns/SalonsDropDown";
import { ConnectedHairstylistsDropDown } from "../Dropdowns/HairstylistsDropDown";
import { ConnectedCalendarDaily } from "./CalendarDaily";
import { ConnectedCalendarWeekly } from "./CalendarWeekly";
import { ConnectedCalendarMonthly } from "./CalendarMonthly";

import { ConnectedTimeSlotTest } from "./TimeSlotTest";

import * as helper from "../HelperRepository";
import { useTranslation } from "react-i18next";

const HomeComponent = ({
  activeUser,
  getActiveUser,
  getDashboardData,
  getDashboardDataDaily,
  clearDashboardData,
  getAllSalons
}) => {
  const { t, i18n } = useTranslation();

  document.getElementsByTagName("html")[0].classList.remove("page-signin");
  document.getElementsByTagName("html")[0].classList.remove("grid-view");

  const [salonId, setSalonId] = useState("");
  const [hairstylistId, setHairstylistId] = useState("");
  const [serviceTypes, setServiceTypes] = useState([]);
  const [salonTimeSlot, setSalonTimeSlot] = useState("");
  const [currentDate, setCurrentDate] = useState(
    moment().format("DD.MM.YYYY.")
  );

  const [currentArcivedDate, setCurrentArchivedDate] = useState(
    moment().format("DD.MM.YYYY.")
  );

  const [currentWeekStart, setCurrentWeekStart] = useState(
    moment()
      .startOf("isoWeek")
      .format("DD.MM.YYYY.")
  );
  const [currentMonthStart, setCurrentMonthStart] = useState(
    moment()
      .startOf("month")
      .format("DD.MM.YYYY.")
  );

  const [rolesMap, setRolesMap] = useState(() => {
    return helper.createMapObject();
  });

  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add("grid-view");

    if (!activeUser || !activeUser.personId) {
      getActiveUser();
    }
    return () => {
      clearDashboardData();
      clearState();
      document.getElementsByTagName("html")[0].classList.remove("grid-view");
    };
  }, []);

  useEffect(() => {
    if (salonId) {
      if (!rolesMap.get("Admin")) {
        // Dodato jer se saloni dohvataju u ConnectedSalonsDropDown,
        // ali se ta komponenta ne startuje ako nije ulogovan sistem admin
        getAllSalons();
      }
      getDashboardData(salonId, hairstylistId, currentDate);
      getDashboardDataDaily(salonId, hairstylistId, currentDate);
    } else {
      clearDashboardData();
    }
  }, [
    hairstylistId,
    salonId,
    getDashboardData,
    getDashboardDataDaily,
    clearDashboardData
  ]);

  useEffect(() => {
    if (salonId) {
      if (!rolesMap.get("Admin")) {
        // Dodato jer se saloni dohvataju u ConnectedSalonsDropDown,
        // ali se ta komponenta ne startuje ako nije ulogovan sistem admin
        getAllSalons();
      }

      if (
        moment(currentDate, "DD.MM.YYYY.").month() !==
        moment(currentArcivedDate, "DD.MM.YYYY.").month()
      ) {
        getDashboardData(salonId, hairstylistId, currentDate);
      }

      if (currentDate !== currentArcivedDate) {
        setCurrentArchivedDate(currentDate);
      }

      getDashboardDataDaily(salonId, hairstylistId, currentDate);
    } else {
      clearDashboardData();
    }
  }, [currentDate]);

  useEffect(() => {
    if (!rolesMap.get("Admin")) {
      if (rolesMap.get("Salon admin")) {
        setSalonId(activeUser.userRoles[0].externalId);
      } else {
        if (rolesMap.get("Hairstylist")) {
          setSalonId(activeUser.userRoles[0].externalId);
          setHairstylistId(activeUser.personId);
        }
      }
    }
  }, [rolesMap]);

  useEffect(() => {
    setRolesMap(helper.mapForRole(activeUser));
  }, [activeUser]);

  const clearState = () => {
    setSalonId("");
    setHairstylistId("");
    setServiceTypes([]);
    setSalonTimeSlot("");
    setCurrentDate("");
    setCurrentWeekStart("");
    setCurrentMonthStart("");
    setRolesMap([]);
  };

  return (
    <>
      {/* <ConnectedTimeSlotTest /> */}
      <div className="container mt-4">
        <div className="row mx-0 filter-group">
          {rolesMap.get("Admin") && (
            <div className="col-md-6">
              <div className="form-group mb-md-0">
                <div className="row">
                  <label htmlFor="salonId" className="col-form-label col-lg-3">
                    {helper.i18nMultiFallbackKey(
                      "home.homeComponent.shopLabel",
                      "Shop"
                    )}
                  </label>
                  <div className="col-lg-9">
                    <ConnectedSalonsDropDown
                      salonId={salonId}
                      setSalonId={setSalonId}
                      setServiceTypes={setServiceTypes}
                      setSalonTimeSlot={setSalonTimeSlot}
                      id="salonId"
                    />
                  </div>
                </div>
                <div className="invalid-feedback">
                  {helper.i18nMultiFallbackKey(
                    "home.homeComponent.shopInvalid",
                    "Please select your shop."
                  )}
                </div>
              </div>
            </div>
          )}
          {rolesMap.get("Salon admin") && (
            <div className="col-md-6">
              <div className="form-group mb-0">
                <div className="row">
                  <label
                    htmlFor="hairstylistId"
                    className="col-form-label col-lg-3"
                  >
                    {helper.i18nMultiFallbackKey(
                      "home.homeComponent.hairstylistLabel",
                      "Provider"
                    )}
                  </label>
                  <div className="col-lg-9">
                    <ConnectedHairstylistsDropDown
                      hairstylistId={hairstylistId}
                      setHairstylistId={setHairstylistId}
                      salonId={salonId}
                      id="hairstylistId"
                    />
                  </div>
                </div>
                <div className="invalid-feedback">
                  {helper.i18nMultiFallbackKey(
                    "home.homeComponent.hairstylistInvalid",
                    "Please select your provider."
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <main role="main">
        <div className="container-fluid">
          {/* <div className="row mb-3">
            <div className="col-md-6">
              <h4 className="text-primary">
                Welcome, {activeUser.firstName} {activeUser.lastName}!!!
              </h4>
            </div>
            <div className="col-md-6">
              <div className="calendar-type text-md-right">
                <button
                  type="button"
                  className="btn btn-lg btn-purple-outline ico-l"
                >
                  <i className="fal fa-calendar-week"></i> Week
                </button>
                <button
                  type="button"
                  className="btn btn-lg btn-pink-outline ico-l"
                >
                  <i className="fal fa-calendar-alt"></i> Month
                </button>
                <button
                  type="button"
                  className="btn btn-lg btn-outline-primary ico-l"
                >
                  <i className="fal fa-sync"></i> Reset
                </button>
              </div>
            </div>
          </div> */}
          <div className="row">
            <ConnectedCalendarDaily
              salonId={salonId}
              hairstylistId={hairstylistId}
              currentDate={currentDate}
              getDashboardData={getDashboardData}
              getDashboardDataDaily={getDashboardDataDaily}
              setCurrentMonthStart={setCurrentMonthStart}
              setCurrentWeekStart={setCurrentWeekStart}
              setCurrentDate={setCurrentDate}
            />
            {/* <ConnectedCalendarWeekly
              salonId={salonId}
              hairstylistId={hairstylistId}
              currentWeekStart={currentWeekStart}
              setCurrentMonthStart={setCurrentMonthStart}
              setCurrentWeekStart={setCurrentWeekStart}
              setCurrentDate={setCurrentDate}
            /> */}
            <ConnectedCalendarMonthly
              salonId={salonId}
              hairstylistId={hairstylistId}
              currentMonthStart={currentMonthStart}
              setCurrentMonthStart={setCurrentMonthStart}
              setCurrentWeekStart={setCurrentWeekStart}
              setCurrentDate={setCurrentDate}
            />
          </div>
        </div>
        {/* <button
          type="button"
          className="btn btn-outline-danger btn-sm ico-l"
          data-toggle="modal"
          data-target="#deleteElement"
        >
          <i className="fal fa-trash-alt"></i> Deactivate Modal
        </button> */}
      </main>
      <footer className="footer">
        <div className="container copyright">
          <span className="text-muted">
            Hairstylist &copy; Copyright {moment().format("YYYY")},{" "}
            {t("common.version", "Version:")} {process.env.VERSION}
          </span>
        </div>
      </footer>

      {/* Delete Element Modal */}
      <div
        className="modal fade"
        data-backdrop="static"
        data-keyboard="false"
        id="deleteElement"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteElementLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">
                <i className="fal fa-trash-alt fa-lg c-red"></i>{" "}
                {t(
                  "home.homeComponent.deleteElement.title",
                  "Deactivate Element"
                )}{" "}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fal fa-times" />
              </button>
            </div>

            <div className="modal-body">
              <h2 className="info-title-upp-case-xl icon-custom-top-center">
                <span className="icon-custom is-xl icon-triangle-red-white" />
                {t("home.homeComponent.deleteElement.attention", "Attention")}
              </h2>
              <div className="info-message text-center">
                <p>
                  {t(
                    "home.homeComponent.deleteElement.question",
                    "Are you sure you want to deactivate this element"
                  )}{" "}
                </p>
                <p className="prime-text">
                  {t(
                    "home.homeComponent.deleteElement.elementNameLabel",
                    "Element Name ?"
                  )}
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-success ico-l"
                data-dismiss="modal"
              >
                <i className="fal fa-check" /> {t("common.yes", "Yes")}
              </button>
              <button
                type="button"
                className="btn btn-outline-danger ico-l"
                data-dismiss="modal"
              >
                <i className="fal fa-times" /> {t("common.no", "No")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    activeUser: state.activeUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDashboardData(salonId, hairstylistId, visitStartDate) {
      let requestData = {
        SalonId: salonId,
        HairstylistId: hairstylistId,
        VisitDate: moment(visitStartDate, "DD.MM.YYYY.").format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
        SelectedServices: []
      };
      dispatch(mutations.getDashboardData(requestData));
    },
    getDashboardDataDaily(salonId, hairstylistId, visitStartDate) {
      let requestData = {
        SalonId: salonId,
        HairstylistId: hairstylistId,
        VisitDate: moment(visitStartDate, "DD.MM.YYYY.").format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
        SelectedServices: []
      };
      dispatch(mutations.getDashboardDataDaily(requestData));
    },
    clearDashboardData() {
      let data = {
        freeTimeSlots: [],
        dailyFreeTimeSlots: [],
        currentWeekSummary: {
          daySummaries: []
        },
        monthSummary: []
      };
      dispatch(mutations.setDashboardData(data));
      dispatch(mutations.setDashboardDataDaily(data));
    },
    getActiveUser() {
      if (sessionStorage.getItem("user_id")) {
        dispatch(
          mutations.getActiveUser(JSON.parse(sessionStorage.getItem("user_id")))
        );
      }
    },
    getAllSalons() {
      dispatch(mutations.getAllSalons());
    }
  };
};

const ConnectedHome = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeComponent);

export default ConnectedHome;
