import React, { useState, Component, useEffect } from "react";
import { connect } from "react-redux";
import { ConnectedSalonsDropDown } from "../Dropdowns/SalonsDropDown";
import { ConnectedCategoryItemsDropDown } from "../Dropdowns/CategoryItemsDropDown";
import * as mutations from "../../store/mutations";
import { useTranslation } from "react-i18next";
import * as helper from "../HelperRepository";
import RequiredFieldsComponent from "../RequiredFieldsComponent";

const EditHairstylistModalComponent = ({
  isEditHairstylist,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  phone,
  setPhone,
  email,
  setEmail,
  registrationsNumber,
  setRegistrationsNumber,
  salonId,
  personId,
  statusId,
  genderId,
  setGenderId,
  clearCheckboxSystem,
  clearState,
  rolesMap,
  saveHairstylist,
  saveHairstylistForSalon,
  formErrors,
  setFormErrors,
  formValid,
  setFormValid,
  firstNameValid,
  setFirstNameValid,
  lastNameValid,
  setLastNameValid,
  phoneValid,
  setPhoneValid,
  emailValid,
  setEmailValid,
}) => {
  const { t, i18n } = useTranslation();

  const handleUserInput = (e) => {
    switch (e.target.id) {
      case "hairstylistFirstNameEdit":
        setFirstName(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "hairstylistLastNameEdit":
        setLastName(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "emailEdit":
        setEmail(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "phoneEdit":
        setPhone(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      default:
        break;
    }
  };
  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let firstNameFieldValid = firstNameValid;
    let lastNameFieldValid = lastNameValid;
    let phoneFieldValid = phoneValid;
    let emailFieldValid = emailValid;
    switch (fieldName) {
      case "hairstylistFirstNameEdit":
        let firstNameFieldValidPattern = /^([A-Za-z\u0110\u017D\u01C8\u01CB\u0106\u010C\u01C5\u0160\u0111\u017E\u01C9\u01CC\u0107\u010D\u01C6\u0161 ]+)$/;
        firstNameFieldValid = firstNameFieldValidPattern.test(value);
        setFirstNameValid(firstNameFieldValid);
        fieldValidationErrors.firstName = firstNameFieldValid
          ? ""
          : t(
              "common.firstNameIsInvalid",
              "  is invalid. Valid format is one or more character only strings separated by blank"
            );
        break;
      case "hairstylistLastNameEdit":
        let lastNameFieldValidPattern = /^([A-Za-z\u0110\u017D\u01C8\u01CB\u0106\u010C\u01C5\u0160\u0111\u017E\u01C9\u01CC\u0107\u010D\u01C6\u0161 ]+)$/;
        lastNameFieldValid = lastNameFieldValidPattern.test(value);
        setLastNameValid(lastNameFieldValid);
        fieldValidationErrors.lastName = lastNameFieldValid
          ? ""
          : t(
              "common.lastNameIsInvalid",
              "  is invalid. Valid format is one or more character only strings separated by blank"
            );
        break;
      case "emailEdit":
        let emailFieldValidPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        emailFieldValid = emailFieldValidPattern.test(value);
        setEmailValid(emailFieldValid);
        fieldValidationErrors.email = emailFieldValid
          ? ""
          : t("common.isInvalid", " is invalid");
        break;
      case "phoneEdit":
        let phoneFieldValidPattern = /^((\+?)[0-9]{9,15})$/i;
        phoneFieldValid = phoneFieldValidPattern.test(value);
        setPhoneValid(phoneFieldValid);
        fieldValidationErrors.phone = phoneFieldValid
          ? ""
          : t(
              "common.phoneIsInvalid",
              "  is invalid. Valid format +06xxxxxxxx, from 9 to 15 numbers, sign + optional"
            );
        break;
      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    setFormValid(
      firstNameFieldValid &&
        lastNameFieldValid &&
        emailFieldValid &&
        phoneFieldValid
    );
  };
  const errorClassLabel = (error) => {
    if (error) {
      return error.length === 0 ? "" : "text-danger";
    }
    return "";
  };
  const errorClassInput = (error) => {
    if (error) {
      return error.length === 0 ? "" : "is-invalid";
    }
    return "";
  };

  return (
    <>
      {/* Edit Hairstylist Modal */}
      {isEditHairstylist && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="editHairstylistModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="editHairstylistModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="editHairstylistModalLabel">
                  <i className="fal fa-address-card fa-lg c-purple" />
                  {helper.i18nMultiFallbackKey(
                    "hairstylist.editHairstylistModalComponent.editHairstylistModal.title",
                    "Edit Provider"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <input type="hidden" value="something" />
                  <div className="form-group">
                    <label
                      htmlFor="hairstylistFirstNameEdit"
                      className={`${errorClassLabel(
                        formErrors.firstName
                      )} required`}
                    >
                      {t(
                        "hairstylist.editHairstylistModalComponent.editHairstylistModal.firstnameLabel",
                        "First Name"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.firstName
                      )}`}
                      id="hairstylistFirstNameEdit"
                      placeholder={helper.i18nMultiFallbackKey(
                        "hairstylist.editHairstylistModalComponent.editHairstylistModal.firstnamePlaceholder",
                        "Please enter provider first name"
                      )}
                      value={firstName}
                      onChange={handleUserInput}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                      required
                    />
                    <div className="invalid-feedback">
                      {formErrors["firstName"]?.length > 0 && (
                        <p className="text-danger">
                          {t(
                            "hairstylist.editHairstylistModalComponent.editHairstylistModal.firstnameInvalide",
                            "First name"
                          )}
                          {formErrors["firstName"]}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="hairstylistLastNameEdit"
                      className={`${errorClassLabel(
                        formErrors.lastName
                      )} required`}
                    >
                      {t(
                        "hairstylist.editHairstylistModalComponent.editHairstylistModal.lastnameLabel",
                        "Last Name"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.lastName
                      )}`}
                      id="hairstylistLastNameEdit"
                      placeholder={helper.i18nMultiFallbackKey(
                        "hairstylist.editHairstylistModalComponent.editHairstylistModal.lastnamePlaceholder",
                        "Please enter provider last name"
                      )}
                      value={lastName}
                      onChange={handleUserInput}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                      required
                    />
                    <div className="invalid-feedback">
                      {formErrors["lastName"]?.length > 0 && (
                        <p className="text-danger">
                          {t(
                            "hairstylist.editHairstylistModalComponent.editHairstylistModal.lastnameInvalid",
                            "Last name"
                          )}
                          {formErrors["lastName"]}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="phoneEdit"
                      className={`${errorClassLabel(
                        formErrors.phone
                      )}  required`}
                    >
                      {t(
                        "hairstylist.editHairstylistModalComponent.editHairstylistModal.phoneLabel",
                        "Phone Number"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.phone
                      )}`}
                      id="phoneEdit"
                      placeholder={t(
                        "hairstylist.editHairstylistModalComponent.editHairstylistModal.phonePlaceholder",
                        "Phone Number"
                      )}
                      value={phone}
                      onChange={handleUserInput}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                      required
                    />
                    <div className="invalid-feedback">
                      {formErrors["phone"]?.length > 0 && (
                        <p className="text-danger">
                          {t(
                            "hairstylist.editHairstylistModalComponent.editHairstylistModal.phoneInvalid",
                            "Phone"
                          )}
                          {formErrors["phone"]}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="emailEdit"
                      className={`${errorClassLabel(formErrors.email)}`}
                    >
                      {t(
                        "hairstylist.editHairstylistModalComponent.editHairstylistModal.emailLabel",
                        "Email"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.email
                      )}`}
                      id="emailEdit"
                      placeholder={t(
                        "hairstylist.editHairstylistModalComponent.editHairstylistModal.emailPlaceholder",
                        "Email"
                      )}
                      value={email}
                      onChange={handleUserInput}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                    />
                    <div className="invalid-feedback">
                      {formErrors["email"]?.length > 0 && (
                        <p className="text-danger">
                          {t(
                            "hairstylist.editHairstylistModalComponent.editHairstylistModal.emailInvalid",
                            "Email"
                          )}
                          {formErrors["email"]}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="registrationsNumberEdit">
                      {t(
                        "hairstylist.editHairstylistModalComponent.editHairstylistModal.registrationNumberLabel",
                        "Registrations number"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="registrationsNumberEdit"
                      placeholder={t(
                        "hairstylist.editHairstylistModalComponent.editHairstylistModal.registrationNumberPlaceholder",
                        "Registrations number"
                      )}
                      value={registrationsNumber}
                      onChange={(event) =>
                        setRegistrationsNumber(event.target.value)
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="genderIdEdit">
                      {t(
                        "hairstylist.editHairstylistModalComponent.editHairstylistModal.genderLabel",
                        "Gender"
                      )}
                    </label>
                    <ConnectedCategoryItemsDropDown
                      categoryType="Gender"
                      categoryItemId={genderId}
                      setCategoryItemId={setGenderId}
                      id="genderIdEdit"
                    />
                  </div>
                  <RequiredFieldsComponent />
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => {
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  disabled={!formValid}
                  onClick={() => {
                    if (rolesMap.get("Admin")) {
                      saveHairstylist(
                        personId,
                        firstName,
                        lastName,
                        phone,
                        email,
                        registrationsNumber,
                        statusId,
                        genderId,
                        () => {
                          clearState();
                          clearCheckboxSystem();
                        }
                      );
                    } else {
                      if (rolesMap.get("Salon admin")) {
                        saveHairstylistForSalon(
                          personId,
                          firstName,
                          lastName,
                          phone,
                          email,
                          registrationsNumber,
                          statusId,
                          genderId,
                          salonId,
                          () => {
                            clearState();
                            clearCheckboxSystem();
                          }
                        );
                      }
                    }
                  }}
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isEditHairstylist: ownProps.isEditHairstylist,
    firstName: ownProps.firstName,
    setFirstName: ownProps.setFirstName,
    lastName: ownProps.lastName,
    setLastName: ownProps.setLastName,
    phone: ownProps.phone,
    setPhone: ownProps.setPhone,
    email: ownProps.email,
    setEmail: ownProps.setEmail,
    registrationsNumber: ownProps.registrationsNumber,
    setRegistrationsNumber: ownProps.setRegistrationsNumber,
    salonId: ownProps.salonId,
    personId: ownProps.personId,
    statusId: ownProps.statusId,
    genderId: ownProps.genderId,
    setGenderId: ownProps.setGenderId,
    clearCheckboxSystem: ownProps.clearCheckboxSystem,
    clearState: ownProps.clearState,
    rolesMap: ownProps.rolesMap,
    formErrors: ownProps.formErrors,
    setFormErrors: ownProps.setFormErrors,
    formValid: ownProps.formValid,
    setFormValid: ownProps.setFormValid,
    firstNameValid: ownProps.firstNameValid,
    setFirstNameValid: ownProps.setFirstNameValid,
    lastNameValid: ownProps.lastNameValid,
    setLastNameValid: ownProps.setLastNameValid,
    phoneValid: ownProps.phoneValid,
    setPhoneValid: ownProps.setPhoneValid,
    emailValid: ownProps.emailValid,
    setEmailValid: ownProps.setEmailValid,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveHairstylist(
    personId,
    firstName,
    lastName,
    phone,
    email,
    registrationsNumber,
    statusId,
    genderId,
    cb
  ) {
    let hairstylist = {
      PersonId: personId,
      FirstName: firstName,
      LastName: lastName,
      Phone: phone,
      Email: email,
      RegistrationsNumber: registrationsNumber,
      StatusId: statusId,
      GenderId: genderId,
    };
    cb();
    dispatch(mutations.setLoaded(false));
    dispatch(mutations.saveHairstylist(hairstylist));
  },
  saveHairstylistForSalon(
    personId,
    firstName,
    lastName,
    phone,
    email,
    registrationsNumber,
    statusId,
    genderId,
    salonId,
    cb
  ) {
    let hairstylist = {
      PersonId: personId,
      FirstName: firstName,
      LastName: lastName,
      Phone: phone,
      Email: email,
      RegistrationsNumber: registrationsNumber,
      StatusId: statusId,
      GenderId: genderId,
    };

    dispatch(mutations.setLoaded(false));
    dispatch(mutations.saveHairstylistForSalon(hairstylist, salonId, cb));
  },
});

export const ConnectedEditHairstylistModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditHairstylistModalComponent);
