import React from "react";
import * as helper from "./HelperRepository";
// import "./tooltip.ZA.OBRADU.VS.module.css";

export const ToolTipOnRoundedString = (props) => {
  // const getTooltipOriginalTitle = () => {
  //   if (typeof props.user !== undefined && !helper.isEmpty(props.user)) {
  //     let roleName = props.user.userRoles?.length
  //       ? props.user.userRoles[0].role.name
  //       : "";

  //     let fullName = props.user.firstName + " " + props.user.lastName;
  //     let email = props.user.email;

  //     return `<div class="tooltip-role">Role: ${roleName}</div><div class="tooltip-user">User: ${fullName}</div><div class="tooltip-email">EMail: ${email}</div>`;
  //   } else {
  //     return "";
  //   }
  // };

  const getInitials = () => {
    if (typeof props.user !== undefined && !helper.isEmpty(props.user)) {
      return (
        (props.user.firstName ? props.user.firstName.substring(0, 1) : "") +
        (props.user.lastName ? props.user.lastName.substring(0, 1) : "")
      );
    } else {
      return "";
    }
  };

  return (
    <span
      id="tooltipRoundedString"
      className={`${props.circleStringColor} initials round-corners avatar-menu-letters`}
    >
      {getInitials()}
    </span>
  );
};
