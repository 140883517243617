import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ConnectedCategoryList } from "./CategoryList";
import { ConnectedCategoryItemList } from "./CategoryItemList";
import { ConnectedCategoryItemsDropDown } from "../Dropdowns/CategoryItemsDropDown";
import { ConnectedCategoryItemsDropDownV2 } from "../Dropdowns/CategoryItemsDropDownV2";
import * as mutations from "../../store/mutations";
import { FormErrors } from "../FormErrors";
import * as moment from "moment";
import * as helper from "../HelperRepository";
import { useTranslation } from "react-i18next";

const CategoryComponent = ({
  activeUser,
  getActiveUser,
  createCategory,
  saveCategory,
  deleteCategory,
  createCategoryItem,
  editCategoryItem,
  deleteCategoryItem,
  activateCategoryItem,
  categoryItems,
  getCategoryItemsPagedForCategory,
  clearCategoryItemsList
}) => {
  const { t } = useTranslation();

  document.getElementsByTagName("html")[0].classList.remove("page-signin");

  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");

  const [createCategoryItemId, setCreateCategoryItemId] = useState("");
  const [createCategoryItemName, setCreateCategoryItemName] = useState("");
  const [
    createCategoryItemDescription,
    setCreateCategoryItemDescription
  ] = useState("");
  const [createCategoryItemValue, setCreateCategoryItemValue] = useState("");
  const [createParentCategoryItemId, setCreateParentCategoryItemId] = useState(
    ""
  );

  const [editCategoryItemId, setEditCategoryItemId] = useState("");
  const [editCategoryItemName, setEditCategoryItemName] = useState("");
  const [
    editCategoryItemDescription,
    setEditCategoryItemDescription
  ] = useState("");
  const [editCategoryItemValue, setEditCategoryItemValue] = useState("");
  const [editParentCategoryItemId, setEditParentCategoryItemId] = useState("");

  const [deleteCategoryItemId, setDeleteCategoryItemId] = useState("");
  const [deleteCategoryItemName, setDeleteCategoryItemName] = useState("");

  const [activateCategoryItemId, setActivateCategoryItemId] = useState("");
  const [activateCategoryItemName, setActivateCategoryItemName] = useState("");

  const [hasDependents, setHasDependents] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({});

  const [formErrors, setFormErrors] = useState("");
  const [categoryNameValid, setCategoryNameValid] = useState("");
  const [formValid, setFormValid] = useState("");

  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const [isEditCategoryItem, setIsEditCategoryItem] = useState(false);
  const [isCreateCategoryItem, setIsCreateCategoryItem] = useState(false);
  const [isDeleteCategoryItem, setIsDeleteCategoryItem] = useState(false);
  const [isActivateCategoryItem, setIsActivateCategoryItem] = useState(false);

  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add("grid-view");
    clearCategoryItemsList();
    if (!activeUser || !activeUser.personId) {
      getActiveUser();
    }
    return () => {
      document.getElementsByTagName("html")[0].classList.remove("grid-view");
    };
  }, []);

  useEffect(() => {
    setFormValid(categoryNameValid !== null);
  }, [categoryNameValid]);

  useEffect(() => {
    if (!helper.isEmpty(selectedCategory)) {
      getCategoryItemsPagedForCategory(selectedCategory.categoryId);
    }
  }, [selectedCategory]);

  const clearState = () => {
    setCategoryId("");
    setCategoryName("");

    setCreateCategoryItemId("");
    setCreateCategoryItemName("");
    setCreateCategoryItemDescription("");
    setCreateCategoryItemValue("");
    setCreateParentCategoryItemId("");

    setEditCategoryItemId("");
    setEditCategoryItemName("");
    setEditCategoryItemDescription("");
    setEditCategoryItemValue("");
    setEditParentCategoryItemId("");

    setDeleteCategoryItemId("");
    setDeleteCategoryItemName("");

    setActivateCategoryItemId("");
    setActivateCategoryItemName("");

    setCategoryNameValid(false);
    setFormValid(false);
    setFormErrors({
      categoryName: "",
      categoryNameEdit: ""
    });
  };

  const setCategoryState = category => {
    setCategoryName(category.name);
    setCategoryId(category.categoryId);
    setHasDependents(category.hasDependents);
    setCategoryNameValid(true);
    setFormValid(true);
    setFormErrors({
      categoryName: "",
      categoryNameEdit: ""
    });
  };

  const setCategoryItemState = categoryItem => {
    setEditCategoryItemId(categoryItem.categoryItemId);
    setEditCategoryItemName(categoryItem.itemName);
    setEditCategoryItemDescription(categoryItem.itemDescription);
    setEditCategoryItemValue(categoryItem.itemValue);
    setEditCategoryItemId(categoryItem.categoryItemId);
    setEditParentCategoryItemId(categoryItem.parentCategoryItemId);

    setDeleteCategoryItemId(categoryItem.categoryItemId);
    setDeleteCategoryItemName(categoryItem.itemName);

    setActivateCategoryItemId(categoryItem.categoryItemId);
    setActivateCategoryItemName(categoryItem.itemName);
  };

  const clearComponentShow = () => {
    setIsCreate(false);
    setIsDelete(false);
    setIsEdit(false);

    setIsCreateCategoryItem(false);
    setIsEditCategoryItem(false);
    setIsDeleteCategoryItem(false);
    setIsActivateCategoryItem(false);
  };

  const handleUserInput = e => {
    switch (e.target.id) {
      case "categoryName":
      case "categoryNameEdit":
        setCategoryName(e.target.value);
        //validateField(e.target.id, e.target.value);
        break;
      case "createCategoryItemName":
        setCreateCategoryItemName(e.target.value);
        // validateField(e.target.id, e.target.value);
        break;
      case "editCategoryItemName":
        setEditCategoryItemName(e.target.value);
        // validateField(e.target.id, e.target.value);
        break;
      case "createCategoryItemDescription":
        setCreateCategoryItemDescription(e.target.value);
        // validateField(e.target.id, e.target.value);
        break;
      case "editCategoryItemDescription":
        setEditCategoryItemDescription(e.target.value);
        // validateField(e.target.id, e.target.value);
        break;
      case "createCategoryItemValue":
        setCreateCategoryItemValue(e.target.value);
        // validateField(e.target.id, e.target.value);
        break;
      case "editCategoryItemValue":
        setEditCategoryItemValue(e.target.value);
        // validateField(e.target.id, e.target.value);
        break;
      default:
        break;
    }
  };

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let fieldNameValid = categoryNameValid;

    switch (fieldName) {
      case "categoryName":
        fieldNameValid = value.match(/^(?!\s*$).+/i) && value.length > 5;
        fieldValidationErrors.categoryName = fieldNameValid
          ? ""
          : t("common.isInvalid", " is invalid");
        break;
      case "categoryNameEdit":
        fieldNameValid = value.match(/^(?!\s*$).+/i) && value.length > 5;
        fieldValidationErrors.categoryNameEdit = fieldNameValid
          ? ""
          : t("common.isInvalid", " is invalid");
        break;
      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    setCategoryNameValid(fieldNameValid);
    setFormValid(fieldNameValid);
  };

  const errorClassInput = error => {
    if (error) {
      return error.length === 0 ? "" : "is-invalid";
    }
    return "";
  };

  const errorClassLabel = error => {
    if (error) {
      return error.length === 0 ? "" : "text-danger";
    }
    return "";
  };

  return (
    <>
      <main category="main">
        <div className="container-fluid">
          <ConnectedCategoryList
            setParentState={setCategoryState}
            clearParentState={clearState}
            setSelectedCategory={setSelectedCategory}
            selectedCategory={selectedCategory}
            setIsCreate={setIsCreate}
            setIsEdit={setIsEdit}
            setIsDelete={setIsDelete}
          />
        </div>{" "}
        {!helper.isEmpty(selectedCategory) && (
          <>
            <div className="container-fluid mt-4">
              <ConnectedCategoryItemList
                setParentState={setCategoryItemState}
                selectedCategory={selectedCategory}
                categoryItemsFiltered={categoryItems}
                clearParentState={clearState}
                setIsCreate={setIsCreateCategoryItem}
                setIsEdit={setIsEditCategoryItem}
                setIsDelete={setIsDeleteCategoryItem}
                setIsActivate={setIsActivateCategoryItem}
              />
            </div>
          </>
        )}
      </main>

      <footer className="footer">
        <div className="container copyright">
          <span className="text-muted">
            Hairstylist &copy; Copyright {moment().format("YYYY")},{" "}
            {t("common.version", "Version:")}
            {process.env.VERSION}
          </span>
        </div>
      </footer>

      {/* Create Category Modal */}
      {isCreate && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="createCategoryModal"
          tabIndex="-1"
          category="dialog"
          aria-labelledby="createCategoryModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            category="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="createCategoryModalLabel">
                  <i className="fal fa-user-cog fa-lg c-purple" />{" "}
                  {t(
                    "category.categoryComponent.createCategoryModal.title",
                    "Create Category"
                  )}{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <input type="hidden" value="something" />
                  <div className="form-group">
                    <label
                      htmlFor="categoryName"
                      className={`${errorClassLabel(formErrors.categoryName)}`}
                    >
                      {t(
                        "category.categoryComponent.createCategoryModal.nameLabel",
                        "Name"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.categoryName
                      )}`}
                      id="categoryName"
                      autoComplete="new-username"
                      placeholder={t(
                        "category.categoryComponent.createCategoryModal.namePlaceholder",
                        "Please enter category name"
                      )}
                      value={categoryName}
                      onChange={handleUserInput}
                      required
                    />
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"categoryName"}
                        valueToShow={"Category name"}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => clearComponentShow()}
                >
                  <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  // disabled={!formValid}
                  onClick={() =>
                    createCategory(categoryName, () => {
                      clearState();
                      clearComponentShow();
                    })
                  }
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Edit Category Modal */}
      {isEdit && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="editCategoryModal"
          tabIndex="-1"
          category="dialog"
          aria-labelledby="editCategoryModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            category="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="editCategoryModalLabel">
                  <i className="fal fa-user-cog fa-lg c-purple" />{" "}
                  {t(
                    "category.categoryComponent.editCategoryModal.title",
                    "Edit Category"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <div className="form-group">
                    <label
                      htmlFor="categoryNameEdit"
                      className={`${errorClassLabel(formErrors.categoryName)}`}
                    >
                      {t(
                        "category.categoryComponent.editCategoryModal.nameLabel",
                        "Name"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.categoryName
                      )}`}
                      id="categoryNameEdit"
                      placeholder={t(
                        "category.categoryComponent.editCategoryModal.namePlaceholder",
                        "Category name"
                      )}
                      value={categoryName}
                      onChange={handleUserInput}
                      required
                    />
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"categoryName"}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-primary ico-l"
                  data-dismiss="modal"
                  onClick={() => clearComponentShow()}
                >
                  <i className="fal fa-sync" /> {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  // disabled={!formValid}
                  onClick={() =>
                    saveCategory(categoryId, categoryName, () => {
                      clearState();
                      clearComponentShow();
                    })
                  }
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delete Category Modal */}
      {isDelete && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="deleteCategoryModal"
          tabIndex="-1"
          category="dialog"
          aria-labelledby="deleteCategoryModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            category="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="deleteCategoryModalLabel">
                  <i className="fal fa-user-cog fa-lg c-purple" />{" "}
                  {t(
                    "category.categoryComponent.deleteCategoryModal.title",
                    "Deactivate Category"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <h2 className="info-title-upp-case-xl icon-custom-top-center">
                  <span className="icon-custom is-xl icon-triangle-red-white" />
                  {t(
                    "category.categoryComponent.deleteCategoryModal.attention",
                    "Attention"
                  )}
                </h2>
                {!hasDependents && (
                  <div className="info-message text-center">
                    <p>
                      {" "}
                      {t(
                        "category.categoryComponent.deleteCategoryModal.question",
                        "Are you sure you want to deactivate category"
                      )}
                    </p>
                    <p className="prime-text">{categoryName}?</p>
                  </div>
                )}
                {hasDependents && (
                  <div className="info-message text-center">
                    <p>
                      {t(
                        "category.categoryComponent.deleteCategoryModal.dependencies",
                        "Some category items have this category, it is not possible to deactivate."
                      )}
                    </p>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  onClick={() =>
                    deleteCategory(categoryId, () => {
                      clearState();
                      clearComponentShow();
                    })
                  }
                  disabled={hasDependents}
                >
                  <i className="fal fa-check" /> {t("common.yes", "Yes")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => clearComponentShow()}
                >
                  <i className="fal fa-times" /> {t("common.no", "No")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Create Category Item Modal */}
      {isCreateCategoryItem && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="createCategoryItemModal"
          tabIndex="-1"
          category="dialog"
          aria-labelledby="createCategoryItemModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            category="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="createCategoryItemModalLabel">
                  <i className="fal fa-user-cog fa-lg c-purple" />{" "}
                  {t(
                    "category.categoryComponent.createCategoryItemModal.title",
                    "Create Category Item"
                  )}{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <input type="hidden" value="something" />
                  <div className="form-group">
                    <label
                      htmlFor="createCategoryItemName"
                      className={`${errorClassLabel(
                        formErrors.createCategoryItemName
                      )}`}
                    >
                      {t(
                        "category.categoryComponent.createCategoryItemModal.itemNameLabel",
                        "Item name"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.createCategoryItemName
                      )}`}
                      id="createCategoryItemName"
                      autoComplete="new-username"
                      placeholder={t(
                        "category.categoryComponent.createCategoryItemModal.itemNamePlaceholder",
                        "Please enter category item name"
                      )}
                      value={createCategoryItemName}
                      onChange={handleUserInput}
                      required
                    />
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"createCategoryItemName"}
                        valueToShow={"Category item name"}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="createCategoryItemDescription"
                      className={`${errorClassLabel(
                        formErrors.createCategoryItemDescription
                      )}`}
                    >
                      {t(
                        "category.categoryComponent.createCategoryItemModal.itemDescriptionLabel",
                        "Item description"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.createCategoryItemDescription
                      )}`}
                      id="createCategoryItemDescription"
                      autoComplete="new-username"
                      placeholder={t(
                        "category.categoryComponent.createCategoryItemModal.itemDescriptionPlaceholder",
                        "Please enter category item description"
                      )}
                      value={createCategoryItemDescription}
                      onChange={handleUserInput}
                      required
                    />
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"createCategoryItemDescription"}
                        valueToShow={"Category item description"}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="createCategoryItemValue"
                      className={`${errorClassLabel(
                        formErrors.createCategoryItemValue
                      )}`}
                    >
                      {t(
                        "category.categoryComponent.createCategoryItemModal.itemValueLabel",
                        "Item value"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.createCategoryItemValue
                      )}`}
                      id="createCategoryItemValue"
                      autoComplete="new-username"
                      placeholder={t(
                        "category.categoryComponent.createCategoryItemModal.itemValuePlaceholder",
                        "Please enter category item value"
                      )}
                      value={createCategoryItemValue}
                      onChange={handleUserInput}
                      required
                    />
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"createCategoryItemValue"}
                        valueToShow={"Category item value"}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="createParentCategoryItemId">
                      {t(
                        "category.categoryComponent.createCategoryItemModal.createParentCategoryItemLabel",
                        "Parent category item"
                      )}
                    </label>
                    <ConnectedCategoryItemsDropDownV2
                      categoryType={selectedCategory.name}
                      categoryId={selectedCategory.categoryId}
                      categoryItemId={createParentCategoryItemId}
                      setCategoryItemId={setCreateParentCategoryItemId}
                      id="createParentCategoryItemId"
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => clearComponentShow()}
                >
                  <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  // disabled={!formValid}
                  onClick={() =>
                    createCategoryItem(
                      createCategoryItemName,
                      createCategoryItemDescription,
                      createCategoryItemValue,
                      createParentCategoryItemId,
                      selectedCategory.categoryId,
                      () => {
                        clearState();
                        clearComponentShow();
                      }
                    )
                  }
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Edit Category Item Modal */}
      {isEditCategoryItem && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="editCategoryItemModal"
          tabIndex="-1"
          category="dialog"
          aria-labelledby="editCategoryItemModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            category="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="editCategoryItemModalLabel">
                  <i className="fal fa-user-cog fa-lg c-purple" />{" "}
                  {t(
                    "category.categoryComponent.editCategoryItemModal.title",
                    "Create Category Item"
                  )}{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <input type="hidden" value="something" />
                  <div className="form-group">
                    <label
                      htmlFor="editCategoryItemName"
                      className={`${errorClassLabel(
                        formErrors.editCategoryItemName
                      )}`}
                    >
                      {t(
                        "category.categoryComponent.editCategoryItemModal.itemNameLabel",
                        "Item name"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.editCategoryItemName
                      )}`}
                      id="editCategoryItemName"
                      autoComplete="new-username"
                      placeholder={t(
                        "category.categoryComponent.editCategoryItemModal.itemNamePlaceholder",
                        "Please enter category item name"
                      )}
                      value={editCategoryItemName}
                      onChange={handleUserInput}
                      required
                    />
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"editCategoryItemName"}
                        valueToShow={"Category item name"}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="categoryItemDescription"
                      className={`${errorClassLabel(
                        formErrors.editCategoryItemDescription
                      )}`}
                    >
                      {t(
                        "category.categoryComponent.editCategoryItemModal.itemDescriptionLabel",
                        "Item description"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.editCategoryItemDescription
                      )}`}
                      id="editCategoryItemDescription"
                      autoComplete="new-username"
                      placeholder={t(
                        "category.categoryComponent.editCategoryItemModal.itemDescriptionPlaceholder",
                        "Please enter category item description"
                      )}
                      value={editCategoryItemDescription}
                      onChange={handleUserInput}
                      required
                    />
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"editCategoryItemDescription"}
                        valueToShow={"Category item description"}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="editCategoryItemValue"
                      className={`${errorClassLabel(
                        formErrors.editCategoryItemValue
                      )}`}
                    >
                      {t(
                        "category.categoryComponent.editCategoryItemModal.itemValueLabel",
                        "Item value"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.editCategoryItemValue
                      )}`}
                      id="editCategoryItemValue"
                      autoComplete="new-username"
                      placeholder={t(
                        "category.categoryComponent.editCategoryItemModal.itemValuePlaceholder",
                        "Please enter category item value"
                      )}
                      value={editCategoryItemValue}
                      onChange={handleUserInput}
                      required
                    />
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"editCategoryItemValue"}
                        valueToShow={"Category item value"}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="editParentCategoryItemId">
                      {t(
                        "category.categoryComponent.editCategoryItemModal.editParentCategoryItemLabel",
                        "Parent category item"
                      )}
                    </label>
                    <ConnectedCategoryItemsDropDownV2
                      categoryType={selectedCategory.name}
                      categoryId={selectedCategory.categoryId}
                      categoryItemId={editParentCategoryItemId}
                      setCategoryItemId={setEditParentCategoryItemId}
                      id="editParentCategoryItemId"
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => clearComponentShow()}
                >
                  <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  // disabled={!formValid}
                  onClick={() =>
                    editCategoryItem(
                      editCategoryItemId,
                      editCategoryItemName,
                      editCategoryItemDescription,
                      editCategoryItemValue,
                      editParentCategoryItemId,
                      selectedCategory.categoryId,
                      () => {
                        clearState();
                        clearComponentShow();
                      }
                    )
                  }
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delete Category Item Modal */}
      {isDeleteCategoryItem && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="deleteCategoryItemModal"
          tabIndex="-1"
          category="dialog"
          aria-labelledby="deleteCategoryItemModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            category="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="deleteCategoryItemModalLabel">
                  <i className="fal fa-user-cog fa-lg c-purple" />{" "}
                  {t(
                    "category.categoryComponent.deleteCategoryItemModal.title",
                    "Deactivate Category item"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <h2 className="info-title-upp-case-xl icon-custom-top-center">
                  <span className="icon-custom is-xl icon-triangle-red-white" />
                  {t(
                    "category.categoryComponent.deleteCategoryItemModal.attention",
                    "Attention"
                  )}
                </h2>
                {!hasDependents && (
                  <div className="info-message text-center">
                    <p>
                      {" "}
                      {t(
                        "category.categoryComponent.deleteCategoryItemModal.question",
                        "Are you sure you want to deactivate category item"
                      )}
                    </p>
                    <p className="prime-text">{deleteCategoryItemName}?</p>
                  </div>
                )}
                {hasDependents && (
                  <div className="info-message text-center">
                    <p>
                      {t(
                        "category.categoryComponent.deleteCategoryItemModal.dependencies",
                        "Some category items have this category item, it is not possible to deactivate."
                      )}
                    </p>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  onClick={() =>
                    deleteCategoryItem(
                      deleteCategoryItemId,
                      selectedCategory.categoryId,
                      () => {
                        clearState();
                        clearComponentShow();
                      }
                    )
                  }
                  disabled={hasDependents}
                >
                  <i className="fal fa-check" /> {t("common.yes", "Yes")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => clearComponentShow()}
                >
                  <i className="fal fa-times" /> {t("common.no", "No")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Activate Category Item Modal */}
      {isActivateCategoryItem && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="activateCategoryItemModal"
          tabIndex="-1"
          category="dialog"
          aria-labelledby="activateCategoryItemModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            category="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="activateCategoryItemModalLabel">
                  <i className="fal fa-user-cog fa-lg c-purple" />{" "}
                  {t(
                    "category.categoryComponent.activateCategoryItemModal.title",
                    "Activate Category item"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <h2 className="info-title-upp-case-xl icon-custom-top-center">
                  <span className="icon-custom is-xl icon-triangle-red-white" />
                  {t(
                    "category.categoryComponent.activateCategoryItemModal.attention",
                    "Attention"
                  )}
                </h2>
                {!hasDependents && (
                  <div className="info-message text-center">
                    <p>
                      {" "}
                      {t(
                        "category.categoryComponent.activateCategoryItemModal.question",
                        "Are you sure you want to activate category item"
                      )}
                    </p>
                    <p className="prime-text">{activateCategoryItemName}?</p>
                  </div>
                )}
                {hasDependents && (
                  <div className="info-message text-center">
                    <p>
                      {t(
                        "category.categoryComponent.activateCategoryItemModal.dependencies",
                        "Some category items have this category item, it is not possible to deactivate."
                      )}
                    </p>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  onClick={() =>
                    activateCategoryItem(
                      activateCategoryItemId,
                      selectedCategory.categoryId,
                      () => {
                        clearState();
                        clearComponentShow();
                      }
                    )
                  }
                  disabled={hasDependents}
                >
                  <i className="fal fa-check" /> {t("common.yes", "Yes")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => clearComponentShow()}
                >
                  <i className="fal fa-times" /> {t("common.no", "No")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    activeUser: state.activeUser,
    categoryItems: state.categoryItems
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getActiveUser() {
      if (sessionStorage.getItem("user_id")) {
        dispatch(
          mutations.getActiveUser(JSON.parse(sessionStorage.getItem("user_id")))
        );
      }
    },
    getCategoryItemsPagedForCategory(categoryId) {
      dispatch(
        mutations.getCategoryItemsPagedForCategory(
          {
            Property: "CategoryId",
            Value: categoryId,
            Type: "guid"
          },
          1,
          []
        )
      );
    },
    createCategory(categoryName, cb) {
      let category = {
        Name: categoryName
      };
      cb();
      dispatch(mutations.createCategory(category));
    },
    saveCategory(categoryId, categoryName, cb) {
      let category = {
        CategoryId: categoryId,
        Name: categoryName
      };
      cb();
      dispatch(mutations.saveCategory(category));
    },
    deleteCategory(categoryId, cb) {
      let category = {
        CategoryId: categoryId
      };
      cb();
      dispatch(mutations.deleteCategory(category));
    },
    createCategoryItem(
      categoryItemName,
      categoryItemDescription,
      categoryItemValue,
      parentCategoryItemId,
      categoryId,
      cb
    ) {
      let categoryItem = {
        ItemName: categoryItemName,
        ItemDescription: categoryItemDescription,
        ItemValue: categoryItemValue,
        ParentCategoryItemId: parentCategoryItemId,
        CategoryId: categoryId
      };
      cb();
      dispatch(mutations.createCategoryItem(categoryItem));
    },
    editCategoryItem(
      categoryItemId,
      categoryItemName,
      categoryItemDescription,
      categoryItemValue,
      parentCategoryItemId,

      categoryId,
      cb
    ) {
      let categoryItem = {
        CategoryItemId: categoryItemId,
        ItemName: categoryItemName,
        ItemDescription: categoryItemDescription,
        ItemValue: categoryItemValue,
        ParentCategoryItemId: parentCategoryItemId,
        CategoryId: categoryId
      };
      cb();
      dispatch(mutations.saveCategoryItem(categoryItem));
    },
    deleteCategoryItem(categoryItemId, categoryId, cb) {
      let categoryItem = {
        CategoryItemId: categoryItemId,
        CategoryId: categoryId
      };
      cb();
      dispatch(mutations.deleteCategoryItem(categoryItem));
    },
    activateCategoryItem(categoryItemId, categoryId, cb) {
      let categoryItem = {
        CategoryItemId: categoryItemId,
        CategoryId: categoryId
      };
      cb();
      dispatch(mutations.activateCategoryItem(categoryItem));
    },
    clearCategoryItemsList() {
      dispatch(
        mutations.setCategoryItems({
          resultList: [],
          pageNumber: 1,
          totalCount: 0
        })
      );
    }
  };
};

export const ConnectedCategory = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryComponent);

export default ConnectedCategory;
