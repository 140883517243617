import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { withTranslation } from "react-i18next";

class CustomersDropDownComponent extends Component {
  componentDidMount() {
    this.props.getAllCustomers();
  }

  render() {
    return (
      <div className="custom-select-box">
        <select
          className="form-control"
          value={this.props.customerId}
          onChange={(event) => this.props.setCustomerId(event.target.value)}
          required
        >
          <option key={""} value={""}>
            {" "}
            {helper.i18nMultiFallbackKey("dropdowns.customersDropDownComponent.label", "Select client")}
          </option>
          {this.props.customers.map((r) => {
            return (
              <option key={r.personId} value={r.personId}>
                {r.firstName} {r.lastName}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    customers: state.customers,
    customerId: ownProps.customerId,
    setCustomerId: ownProps.setCustomerId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllCustomers() {
    dispatch(mutations.getAllCustomers());
  },
});

export const ConnectedCustomersDropDown_NoTranslation = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CustomersDropDownComponent)
);
