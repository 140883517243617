import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../../store/mutations";
import { withTranslation } from "react-i18next";

export class HairstylistServiceTypeDelete extends Component {
  render() {
    return (
      <div
        className="modal fade"
        data-backdrop="static"
        data-keyboard="false"
        id="deleteHairstylistServiceTypeModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteHairstylistServiceTypeModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="deleteHairstylistServiceTypeModalLabel"
              >
                <i className="fal fa-user fa-lg c-purple" />{" "}
                {helper.i18nMultiFallbackKey("hairstylist.hairstylistServiceTypeDelete.deleteHairstylistServiceTypeModal.title", "Deactivate Provider Service Type")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.props.clearParentState()}
              >
                <i className="fal fa-times" />
              </button>
            </div>

            <div className="modal-body">
              <h2 className="info-title-upp-case-xl icon-custom-top-center">
                <span className="icon-custom is-xl icon-triangle-red-white" />
                {this.props.t(
                  "hairstylist.hairstylistServiceTypeDelete.deleteHairstylistServiceTypeModal.attention",
                  "Attention"
                )}
              </h2>
              <div className="info-message text-center">
                <p>
                  {helper.i18nMultiFallbackKey("hairstylist.hairstylistServiceTypeDelete.deleteHairstylistServiceTypeModal.question", "Are you sure you want to deactivate provider service type")}
                </p>
                <p className="prime-text">
                  {this.props.selectedHairstylistServiceType.serviceType
                    ? this.props.selectedHairstylistServiceType.serviceType
                      .serviceTypeName
                    : ""}{" "}
                  {helper.i18nMultiFallbackKey("hairstylist.hairstylistServiceTypeDelete.deleteHairstylistServiceTypeModal.forProvider", "for provider ")}
                  {this.props.selectedHairstylist.firstName} ?
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-success ico-l"
                data-dismiss="modal"
                onClick={() =>
                  this.props.deleteHairstylistServiceType(
                    this.props.selectedHairstylistServiceType,
                    () => this.props.clearParentState()
                  )
                }
              >
                <i className="fal fa-check" />{" "}
                {this.props.t("common.yes", "Yes")}
              </button>
              <button
                type="button"
                className="btn btn-outline-danger ico-l"
                data-dismiss="modal"
                onClick={() => this.props.clearParentState()}
              >
                <i className="fal fa-times" /> {this.props.t("common.no", "No")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPropsFreeSlots = (state, ownProps) => {
  return {
    selectedHairstylist: ownProps.selectedHairstylist,
    clearParentState: ownProps.clearParentState,
    selectedHairstylistServiceType: ownProps.selectedHairstylistServiceType,
  };
};

const mapDispatchToPropsFreeSlots = (dispatch) => ({
  deleteHairstylistServiceType(hairstylistServiceTypeForDelete, cb) {
    cb();
    dispatch(
      mutations.deleteHairstylistServiceType(hairstylistServiceTypeForDelete)
    );
  },
});

export const ConnectedHairstylistServiceTypeDelete = withTranslation()(
  connect(
    mapStateToPropsFreeSlots,
    mapDispatchToPropsFreeSlots
  )(HairstylistServiceTypeDelete)
);
