import React, { Component } from "react";

import { connect } from "react-redux";
import * as helper from "./HelperRepository";
import { useTranslation } from "react-i18next";

const NewItemButtonV2 = ({ path, clearParentState, setIsCreate }) => {
  const { t } = useTranslation();
  let currentType = "";
  let currentTypeName = "";
  let currentNew = "";

  switch (path) {
    case "home":
      currentType = "Home";
      currentTypeName = t("newItemButtonV2.currentTypeName.home", "Home");
      currentNew = t("newItemButtonV2.currentTypeName.new_home", "");
      break;
    case "category":
      currentType = "Category";
      currentTypeName = t(
        "newItemButtonV2.currentTypeName.category",
        "Category"
      );
      currentNew = t("newItemButtonV2.currentTypeName.new_category", "New");
      break;
    case "generalization":
      currentType = "Generalization";
      currentTypeName = t(
        "newItemButtonV2.currentTypeName.generalization",
        "Generalization"
      );
      currentNew = t(
        "newItemButtonV2.currentTypeName.new_generalization",
        "New"
      );
      break;

      case "images":
        currentType = "Images";
        currentTypeName = t(
          "newItemButtonV2.currentTypeName.images",
          "Images"
        );
        currentNew = t(
          "newItemButtonV2.currentTypeName.new_images",
          "New"
        );
        break;
    case "user":
      currentType = "User";
      currentTypeName = t("newItemButtonV2.currentTypeName.user", "User");
      currentNew = t("newItemButtonV2.currentTypeName.new_user", "New");
      break;
    case "customer":
      currentType = "Customer";
      currentTypeName = helper.i18nMultiFallbackKey("newItemButtonV2.currentTypeName.client", "Client");
      currentNew = helper.i18nMultiFallbackKey("newItemButtonV2.currentTypeName.new_client", "New");
      break;
    case "role":
      currentType = "Role";
      currentTypeName = t("newItemButtonV2.currentTypeName.role", "Role");
      currentNew = t("newItemButtonV2.currentTypeName.new_role", "New");
      break;
    case "salon":
      currentType = "Salon";
      currentTypeName = helper.i18nMultiFallbackKey("newItemButtonV2.currentTypeName.shop", "Shop");
      currentNew = helper.i18nMultiFallbackKey("newItemButtonV2.currentTypeName.new_shop", "New");
      break;
    case "hairstylist":
      currentType = "Hairstylist";
      currentTypeName = helper.i18nMultiFallbackKey("newItemButtonV2.currentTypeName.provider", "Provider");
      currentNew = helper.i18nMultiFallbackKey("newItemButtonV2.currentTypeName.new_provider", "New");
      break;
    case "visit":
      currentType = "Visit";
      currentTypeName = t("newItemButtonV2.currentTypeName.visit", "Visit");
      currentNew = t("newItemButtonV2.currentTypeName.new_visit", "New");
      break;
    default:
      currentType = "";
      currentTypeName = "";
      currentNew = "";
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-purple-outline btn-sm ico-l"
        onClick={() => {
          clearParentState();
          setIsCreate(true);
          helper.showModal(`#create${currentType}Modal`);
        }}
      >
        <i className="fal fa-plus"></i>
        {currentNew}
        <span className="mobile-hide-550"> {currentTypeName}</span>
      </button>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    path: state.path,
    clearParentState: ownProps.clearParentState,
    setIsCreate: ownProps.setIsCreate,
  };
};

export const ConnectedNewItemButtonV2 = connect(mapStateToProps)(
  NewItemButtonV2
);
