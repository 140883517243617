import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import * as moment from "moment";
import * as Helper from "../HelperRepository";
import { withTranslation } from "react-i18next";

class FreeVisitSlotsPopupComponentV3 extends Component {
  state = {
    checkboxInputs: [],
    enableRender: false,
    activeSlots: [],
    newSlots: [],
    lengthInSalonTimeSlots: 0,
    initialLoad: true,
  };
  componentDidMount() {
    if (this.state.initialLoad) {
      this.setState({ initialLoad: false }, () => {
        this.reload();
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      checkboxInputs: [],
      enableRender: false,
      activeSlots: [],
      newSlots: [],
      lengthInSalonTimeSlots: 0,
      initialLoad: true,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !Helper.isArrayEqual(
        prevProps.freeVisitTimeSlots,
        this.props.freeVisitTimeSlots
      )
    ) {
      this.setState({
        checkboxInputs: [...Helper.cloneDeep(this.props.freeVisitTimeSlots)],
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      state.initialLoad ||
      props.freeVisitTimeSlots.length <= 0 ||
      props.hairstylistServiceTypes.length <= 0 ||
      props.selectedServices.length <= 0
    ) {
      return null;
    }

    let a = [];
    let internalActiveSlots = [];

    if (state.checkboxInputs.length > 0) {
      if (
        moment(state.checkboxInputs[0].startTime).format("YYYY-MM-DD") !==
        moment(props.freeVisitTimeSlots[0].startTime).format("YYYY-MM-DD")
      ) {
        a = [...Helper.cloneDeep(props.freeVisitTimeSlots)];
      } else {
        a = [...Helper.cloneDeep(state.checkboxInputs)];
      }

      try {
        props.freeVisitTimeSlots.forEach((item, index) => {
          if (
            !item.timeSlotFree &&
            item.visitTimeSlot &&
            item.visitId === props.visitId
          ) {
            internalActiveSlots.push(index);
          }
        });

        a.forEach((item, index) => {
          if (props.editType) {
            if (!item.timeSlotFree && item.visitTimeSlot) {
              if (item.visitId !== props.visitId) {
                item.visitTimeSlot = false;
                item.timeSlotFree = false;
              }
            }
          } else {
            if (!item.timeSlotFree && item.visitTimeSlot) {
              item.visitTimeSlot = false;
              item.timeSlotFree = false;
            }
            if (
              props.startTime &&
              item.startTime === props.startTime &&
              item.endTime === props.endTime &&
              item.timeSlotFree === true
            ) {
              item.visitTimeSlot = true;
              item.timeSlotFree = true;
            } else if (props.startTime === "") {
              item.visitTimeSlot = false;
            }
          }
        });
      } catch (ex) {
        console.log(ex);
      }
    }

    let internalLengthInSalonTimeSlots = 0;
    try {
      props.selectedServices.forEach((element) => {
        let selectedServiceType = props.hairstylistServiceTypes.find(
          (serviceType) => serviceType.serviceTypeId === element.serviceTypeId
        );

        if (selectedServiceType !== undefined) {
          internalLengthInSalonTimeSlots +=
            selectedServiceType.serviceType.serviceTypeDuration;
        }
      });
    } catch (ex) {
      console.log(ex);
    }
    return {
      checkboxInputs: [...Helper.cloneDeep(a)],
      enableRender: true,
      activeSlots: [...Helper.cloneDeep(internalActiveSlots)],
      lengthInSalonTimeSlots: internalLengthInSalonTimeSlots,
    };
  }

  reload = () => {
    this.props.getAllFreeVisitTimeSlots(
      this.props.salonId,
      this.props.hairstylistId,
      this.props.visitDate,
      this.props.selectedServices.map((x) => x.serviceTypeId)
    );
  };

  slotType = (slotData) => {
    if (slotData.timeSlotFree && !slotData.visitTimeSlot) {
      return "time-item free";
    }

    if (!slotData.timeSlotFree && !slotData.visitTimeSlot) {
      return "time-item selected";
    }

    if (slotData.timeSlotFree && slotData.visitTimeSlot) {
      return "time-item changed";
    }

    if (!slotData.timeSlotFree && slotData.visitTimeSlot) {
      return "time-item choosed";
    }
  };

  setParentTime = (startTime, endTime) => {
    const startHours = moment(startTime).format("HH");
    const startMinutes = moment(startTime).format("mm");

    this.props.setStartTime(
      moment(this.props.visitDate)
        .hour(startHours)
        .minute(startMinutes)
        .format("YYYY-MM-DD HH:mm")
    );

    let endMinutesTotal = 0;
    this.props.selectedServices.forEach((element) => {
      let selectedServiceType = this.props.hairstylistServiceTypes.find(
        (serviceType) => serviceType.serviceTypeId === element.serviceTypeId
      );

      endMinutesTotal +=
        selectedServiceType.serviceType.serviceTypeDuration *
        this.props.salonTimeSlot;
    });

    this.props.setEndTime(
      moment(this.props.visitDate)
        .hour(startHours)
        .minute(startMinutes)
        .add(endMinutesTotal, "minutes")
        .format("YYYY-MM-DD HH:mm")
    );
  };

  render() {
    if (!this.state.enableRender) {
      return null;
    }
    return (
      <>
        <div className="form-group">
          <div className="date-time-picker modal-picker">
            <div className="date-time-content flex-wrap">
              <div className="legend-bar">
                <span className="reserved">
                  {this.props.t(
                    "visit.freeVisitSlotsPopupComponent.reserved",
                    "Reserved"
                  )}
                </span>
                <span className="free">
                  {this.props.t(
                    "visit.freeVisitSlotsPopupComponent.free",
                    "Free"
                  )}
                </span>
                <span className="choosed">
                  {this.props.t(
                    "visit.freeVisitSlotsPopupComponent.choosed",
                    "Choosed"
                  )}
                </span>
                <span className="changed">
                  {this.props.t(
                    "visit.freeVisitSlotsPopupComponent.changed",
                    "Changed"
                  )}
                </span>
              </div>
              {/* {this.props.freeVisitTimeSlots.map((r, index) => { */}
              {this.state.checkboxInputs.map((r, index) => {
                if (r.timeSlotFree && !r.visitTimeSlot) {
                  return (
                    <div
                      className={this.slotType(r)}
                      key={r.timeIntervalGuid}
                      onClick={(event) => {
                        let inputs = [
                          ...Helper.cloneDeep(this.state.checkboxInputs),
                        ];
                        let validRange = true;

                        for (
                          let i = 0;
                          i < this.state.lengthInSalonTimeSlots;
                          i++
                        ) {
                          validRange =
                            validRange &&
                            inputs[index + i].timeSlotFree === true;
                        }

                        if (validRange) {
                          if (this.state.newSlots.length > 0) {
                            this.state.newSlots.forEach((newSlot) => {
                              inputs[newSlot].timeSlotFree = true;
                              inputs[newSlot].visitTimeSlot = false;
                            });
                          }

                          if (this.state.activeSlots.length > 0) {
                            this.state.activeSlots.forEach((activeSlot) => {
                              inputs[activeSlot].timeSlotFree = true;
                              inputs[activeSlot].visitTimeSlot = false;
                            });
                          }

                          let internalNewSlots = [];
                          for (
                            let i = 0;
                            i < this.state.lengthInSalonTimeSlots;
                            i++
                          ) {
                            inputs[index + i].timeSlotFree = true;
                            inputs[index + i].visitTimeSlot = true;
                            internalNewSlots.push(index + i);
                          }

                          this.setParentTime(r.startTime, r.endTime);
                          this.setState({
                            newSlots: [...Helper.cloneDeep(internalNewSlots)],
                            checkboxInputs: [...Helper.cloneDeep(inputs)],
                          });
                        }
                      }}
                      required
                    >
                      {moment(r.startTime).format("HH:mm")}
                    </div>
                  );
                }
                if (r.timeSlotFree && r.visitTimeSlot) {
                  return (
                    <div
                      className={this.slotType(r)}
                      key={r.timeIntervalGuid}
                      onClick={(event) => {
                        let inputs = [
                          ...Helper.cloneDeep(this.state.checkboxInputs),
                        ];

                        if (this.state.newSlots.length > 0) {
                          this.state.newSlots.forEach((newSlot) => {
                            inputs[newSlot].timeSlotFree = true;
                            inputs[newSlot].visitTimeSlot = false;
                          });
                        }

                        if (this.state.activeSlots.length > 0) {
                          this.state.activeSlots.forEach((activeSlot) => {
                            inputs[activeSlot].timeSlotFree = false;
                            inputs[activeSlot].visitTimeSlot = true;
                          });

                          this.setParentTime(
                            inputs[this.state.activeSlots[0]].startTime,
                            inputs[this.state.activeSlots[0]].endTime
                          );
                        }

                        this.setState({
                          checkboxInputs: [...Helper.cloneDeep(inputs)],
                        });
                      }}
                      required
                    >
                      {moment(r.startTime).format("HH:mm")}
                    </div>
                  );
                }
                return (
                  <div
                    className={this.slotType(r)}
                    key={r.timeIntervalGuid}
                    required
                  >
                    {moment(r.startTime).format("HH:mm")}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToPropsFreeSlots = (state, ownProps) => {
  return {
    salons: state.salons,
    freeVisitTimeSlots: state.freeVisitTimeSlots,
    hairstylistServiceTypes: ownProps.hairstylistServiceTypes,
    salonId: ownProps.salonId,
    hairstylistId: ownProps.hairstylistId,
    selectedServices: ownProps.selectedServices,
    visitDate: ownProps.visitDate,
    setStartTime: ownProps.setStartTime,
    setEndTime: ownProps.setEndTime,
    salonTimeSlot: ownProps.salonTimeSlot,
    editType: ownProps.editType,
    caller: ownProps.caller,
    visitId: ownProps.visitId,
    startTime: ownProps.startTime,
    endTime: ownProps.endTime,
  };
};

const mapDispatchToPropsFreeSlots = (dispatch) => ({
  getAllFreeVisitTimeSlots(
    salonId,
    hairstylistId,
    visitDate,
    selectedServiceIds
  ) {
    let freeTimeSlotRequest = {
      SalonId: salonId,
      HairstylistId: hairstylistId,
      VisitDate: visitDate,
      SelectedServices: selectedServiceIds,
    };
    dispatch(mutations.getAllFreeVisitTimeSlotsV2(freeTimeSlotRequest));
  },
});

const ConnectedFreeVisitSlotsPopupComponentV3_NoTranslation = connect(
  mapStateToPropsFreeSlots,
  mapDispatchToPropsFreeSlots
)(FreeVisitSlotsPopupComponentV3);

export const ConnectedFreeVisitSlotsPopupComponentV3 = withTranslation()(
  ConnectedFreeVisitSlotsPopupComponentV3_NoTranslation
);
