import React, { useState, Component, useEffect } from "react";
import { connect } from "react-redux";
import { ConnectedSalonsDropDown } from "../Dropdowns/SalonsDropDown";
import { ConnectedCategoryItemsDropDown } from "../Dropdowns/CategoryItemsDropDown";
import * as mutations from "../../store/mutations";
import { FormErrors } from "../FormErrors";
import { useTranslation } from "react-i18next";
import * as helper from "../HelperRepository";
import RequiredFieldsComponent from "../RequiredFieldsComponent";

const CreateHairstylistModalComponent = ({
  isCreateHairstylist,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  phone,
  setPhone,
  email,
  setEmail,
  registrationsNumber,
  setRegistrationsNumber,
  salonId,
  setSalonId,
  setServiceTypes,
  setSalonTimeSlot,
  genderId,
  setGenderId,
  clearCheckboxSystem,
  clearState,
  rolesMap,
  createHairstylist,
  createHairstylistForSalon,
  formErrors,
  setFormErrors,
  formValid,
  setFormValid,
  firstNameValid,
  setFirstNameValid,
  lastNameValid,
  setLastNameValid,
  phoneValid,
  setPhoneValid,
  emailValid,
  setEmailValid,
}) => {
  const { t, i18n } = useTranslation();

  const handleUserInput = (e) => {
    switch (e.target.id) {
      case "hairstylistFirstName":
        setFirstName(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "hairstylistLastName":
        setLastName(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "phone":
        setPhone(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      default:
        break;
    }
  };
  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let firstNameFieldValid = firstNameValid;
    let lastNameFieldValid = lastNameValid;
    let phoneFieldValid = phoneValid;
    let emailFieldValid = emailValid;
    switch (fieldName) {
      case "hairstylistFirstName":
        let firstNameFieldValidPattern = /^([A-Za-z\u0110\u017D\u01C8\u01CB\u0106\u010C\u01C5\u0160\u0111\u017E\u01C9\u01CC\u0107\u010D\u01C6\u0161 ]+)$/;
        firstNameFieldValid = firstNameFieldValidPattern.test(value);
        setFirstNameValid(firstNameFieldValid);
        fieldValidationErrors.firstName = firstNameFieldValid
          ? ""
          : t(
              "common.firstNameIsInvalid",
              "  is invalid. Valid format is one or more character only strings separated by blank"
            );
        break;
      case "hairstylistLastName":
        let lastNameFieldValidPattern = /^([A-Za-z\u0110\u017D\u01C8\u01CB\u0106\u010C\u01C5\u0160\u0111\u017E\u01C9\u01CC\u0107\u010D\u01C6\u0161 ]+)$/;
        lastNameFieldValid = lastNameFieldValidPattern.test(value);
        setLastNameValid(lastNameFieldValid);
        fieldValidationErrors.lastName = lastNameFieldValid
          ? ""
          : t(
              "common.lastNameIsInvalid",
              "  is invalid. Valid format is one or more character only strings separated by blank"
            );
        break;
      case "email":
        let emailFieldValidPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        emailFieldValid = emailFieldValidPattern.test(value);
        setEmailValid(emailFieldValid);
        fieldValidationErrors.email = emailFieldValid
          ? ""
          : t("common.isInvalid", " is invalid");
        break;
      case "phone":
        let phoneFieldValidPattern = /^((\+?)[0-9]{9,15})$/i;
        phoneFieldValid = phoneFieldValidPattern.test(value);
        setPhoneValid(phoneFieldValid);
        fieldValidationErrors.phone = phoneFieldValid
          ? ""
          : t(
              "common.phoneIsInvalid",
              "  is invalid. Valid format +06xxxxxxxx, from 9 to 15 numbers, sign + optional"
            );
        break;
      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    setFormValid(
      firstNameFieldValid &&
        lastNameFieldValid &&
        emailFieldValid &&
        phoneFieldValid &&
        salonId !== ""
    );
  };
  const errorClassLabel = (error) => {
    if (error) {
      return error.length === 0 ? "" : "text-danger";
    }
    return "";
  };
  const errorClassInput = (error) => {
    if (error) {
      return error.length === 0 ? "" : "is-invalid";
    }
    return "";
  };

  useEffect(() => {
    setFormValid(
      firstNameValid !== null &&
        lastNameValid !== null &&
        emailValid !== null &&
        phoneValid !== null &&
        salonId.length > 0
    );
  }, [salonId]);
  return (
    <>
      {/* Create Hairstylist Modal */}
      {isCreateHairstylist && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="createHairstylistModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="createHairstylistModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="createHairstylistModalLabel">
                  <i className="fal fa-address-card fa-lg c-purple" />{" "}
                  {helper.i18nMultiFallbackKey(
                    "hairstylist.createHairstylistModalComponent.createHairstylistModal.title",
                    "Create Provider"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <input type="hidden" value="something" />
                  <div className="form-group">
                    <label
                      htmlFor="hairstylistFirstName"
                      className={`${errorClassLabel(
                        formErrors.firstName
                      )} required`}
                    >
                      {t(
                        "hairstylist.createHairstylistModalComponent.createHairstylistModal.firstnameLabel",
                        "First Name"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.firstName
                      )}`}
                      id="hairstylistFirstName"
                      placeholder={helper.i18nMultiFallbackKey(
                        "hairstylist.createHairstylistModalComponent.createHairstylistModal.firstnamePlaceholder",
                        "Please enter provider first name"
                      )}
                      value={firstName}
                      onChange={handleUserInput}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                      required
                    />
                    <div className="invalid-feedback">
                      {/* <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"firstName"}
                      /> */}
                      {formErrors["firstName"]?.length > 0 && (
                        <p className="text-danger">
                          {t(
                            "hairstylist.createHairstylistModalComponent.createHairstylistModal.firstnameInvalid",
                            "First name"
                          )}
                          {formErrors["firstName"]}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="hairstylistLastName"
                      className={`${errorClassLabel(
                        formErrors.lastName
                      )} required`}
                    >
                      {t(
                        "hairstylist.createHairstylistModalComponent.createHairstylistModal.lastnameLabel",
                        "Last Name"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.lastName
                      )}`}
                      id="hairstylistLastName"
                      placeholder={helper.i18nMultiFallbackKey(
                        "hairstylist.createHairstylistModalComponent.createHairstylistModal.lastnamePlaceholder",
                        "Please enter provider last name"
                      )}
                      value={lastName}
                      onChange={handleUserInput}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                      required
                    />
                    <div className="invalid-feedback">
                      {formErrors["lastName"]?.length > 0 && (
                        <p className="text-danger">
                          {t(
                            "hairstylist.createHairstylistModalComponent.createHairstylistModal.lastnameInvalid",
                            "Last name"
                          )}
                          {formErrors["lastName"]}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="phone"
                      className={`${errorClassLabel(
                        formErrors.phone
                      )} required`}
                    >
                      {t(
                        "hairstylist.createHairstylistModalComponent.createHairstylistModal.phoneLabel",
                        "Phone Number"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.phone
                      )}`}
                      id="phone"
                      placeholder={t(
                        "hairstylist.createHairstylistModalComponent.createHairstylistModal.phonePlaceholder",
                        "Phone Number"
                      )}
                      value={phone}
                      onChange={handleUserInput}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                      required
                    />
                    <div className="invalid-feedback">
                      {formErrors["phone"]?.length > 0 && (
                        <p className="text-danger">
                          {t(
                            "hairstylist.createHairstylistModalComponent.createHairstylistModal.phoneInvalid",
                            "Phone"
                          )}
                          {formErrors["phone"]}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="email"
                      className={`${errorClassLabel(formErrors.email)}`}
                    >
                      {t(
                        "hairstylist.createHairstylistModalComponent.createHairstylistModal.emailLabel",
                        "Email"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.email
                      )}`}
                      id="email"
                      placeholder={t(
                        "hairstylist.createHairstylistModalComponent.createHairstylistModal.emailPlaceholder",
                        "Email"
                      )}
                      value={email}
                      onChange={handleUserInput}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                    />
                    <div className="invalid-feedback">
                      {formErrors["email"]?.length > 0 && (
                        <p className="text-danger">
                          {t(
                            "hairstylist.createHairstylistModalComponent.createHairstylistModal.emailInvalid",
                            "Email"
                          )}
                          {formErrors["email"]}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="registrationsNumber">
                      {t(
                        "hairstylist.createHairstylistModalComponent.createHairstylistModal.registrationNumberLabel",
                        "Registrations Number"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="registrationsNumber"
                      placeholder={t(
                        "hairstylist.createHairstylistModalComponent.createHairstylistModal.registrationNumberPlaceholder",
                        "Registrations Number"
                      )}
                      value={registrationsNumber}
                      onChange={(event) => {
                        setRegistrationsNumber(event.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="salonId" className="required">
                      {helper.i18nMultiFallbackKey(
                        "hairstylist.createHairstylistModalComponent.createHairstylistModal.shopLabel",
                        "Shop"
                      )}
                    </label>
                    <ConnectedSalonsDropDown
                      salonId={salonId}
                      setSalonId={setSalonId}
                      setServiceTypes={setServiceTypes}
                      setSalonTimeSlot={setSalonTimeSlot}
                      id="salonId"
                    />
                    <div className="invalid-feedback">
                      {helper.i18nMultiFallbackKey(
                        "hairstylist.createHairstylistModalComponent.createHairstylistModal.shopInvalid",
                        "Please select your shop."
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="genderId">
                      {t(
                        "hairstylist.createHairstylistModalComponent.createHairstylistModal.genderLabel",
                        "Gender"
                      )}
                    </label>
                    <ConnectedCategoryItemsDropDown
                      categoryType="Gender"
                      categoryItemId={genderId}
                      setCategoryItemId={setGenderId}
                      id="genderId"
                    />
                  </div>
                  <RequiredFieldsComponent />
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => {
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  disabled={!formValid}
                  onClick={() => {
                    if (rolesMap.get("Admin")) {
                      createHairstylist(
                        firstName,
                        lastName,
                        phone,
                        email,
                        registrationsNumber,
                        salonId,
                        genderId,
                        () => {
                          clearState();
                          clearCheckboxSystem();
                        }
                      );
                    } else {
                      if (rolesMap.get("Salon admin")) {
                        createHairstylistForSalon(
                          firstName,
                          lastName,
                          phone,
                          email,
                          registrationsNumber,
                          salonId,
                          genderId,
                          () => {
                            clearState();
                            clearCheckboxSystem();
                          }
                        );
                      }
                    }
                  }}
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isCreateHairstylist: ownProps.isCreateHairstylist,
    firstName: ownProps.firstName,
    setFirstName: ownProps.setFirstName,
    lastName: ownProps.lastName,
    setLastName: ownProps.setLastName,
    phone: ownProps.phone,
    setPhone: ownProps.setPhone,
    email: ownProps.email,
    setEmail: ownProps.setEmail,
    registrationsNumber: ownProps.registrationsNumber,
    setRegistrationsNumber: ownProps.setRegistrationsNumber,
    salonId: ownProps.salonId,
    setSalonId: ownProps.setSalonId,
    setServiceTypes: ownProps.setServiceTypes,
    setSalonTimeSlot: ownProps.setSalonTimeSlot,
    genderId: ownProps.genderId,
    setGenderId: ownProps.setGenderId,
    clearCheckboxSystem: ownProps.clearCheckboxSystem,
    clearState: ownProps.clearState,
    rolesMap: ownProps.rolesMap,
    formErrors: ownProps.formErrors,
    setFormErrors: ownProps.setFormErrors,
    formValid: ownProps.formValid,
    setFormValid: ownProps.setFormValid,
    firstNameValid: ownProps.firstNameValid,
    setFirstNameValid: ownProps.setFirstNameValid,
    lastNameValid: ownProps.lastNameValid,
    setLastNameValid: ownProps.setLastNameValid,
    phoneValid: ownProps.phoneValid,
    setPhoneValid: ownProps.setPhoneValid,
    emailValid: ownProps.emailValid,
    setEmailValid: ownProps.setEmailValid,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createHairstylist(
    firstName,
    lastName,
    phone,
    email,
    registrationsNumber,
    salonId,
    genderId,
    cb
  ) {
    let hairstylist = {
      FirstName: firstName,
      LastName: lastName,
      Phone: phone,
      Email: email,
      RegistrationsNumber: registrationsNumber,
      SalonId: salonId,
      GenderId: genderId,
    };
    cb();
    dispatch(mutations.setLoaded(false));
    dispatch(mutations.createHairstylist(hairstylist));
  },
  createHairstylistForSalon(
    firstName,
    lastName,
    phone,
    email,
    registrationsNumber,
    salonId,
    genderId,
    cb
  ) {
    let hairstylist = {
      FirstName: firstName,
      LastName: lastName,
      Phone: phone,
      Email: email,
      RegistrationsNumber: registrationsNumber,
      SalonId: salonId,
      GenderId: genderId,
    };
    dispatch(mutations.setLoaded(false));
    dispatch(mutations.createHairstylistForSalon(hairstylist, cb));
  },
});

export const ConnectedCreateHairstylistModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateHairstylistModalComponent);
