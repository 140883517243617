import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";

import * as mutations from "../../store/mutations";
import * as helper from "../HelperRepository";
import { useTranslation, withTranslation } from "react-i18next";
import RequiredFieldsComponent from "../RequiredFieldsComponent";

const SalonDeleteRange = ({
  selectedSalon,
  activeUser,
  getActiveUser,
  deleteRange,
  getSalonForId,
  clearCheckboxSystem,
}) => {
  const { t, i18n } = useTranslation();

  const [deleteRangeStartDate, setDeleteRangeStartDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [deleteRangeEndDate, setDeleteRangeEndDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [rolesMap, setRolesMap] = useState(() => {
    return helper.createMapObject();
  });

  const clearCreateWorkingTimeElements = () => {
    let toDate = moment().startOf("day").toDate();
    setDeleteRangeStartDate(moment().format("YYYY-MM-DD"));
    setDeleteRangeEndDate(moment().format("YYYY-MM-DD"));
  };

  // useEffect(() => {
  //   if (!activeUser || !activeUser.personId) {
  //     getActiveUser();
  //   }
  //   return () => {
  //     document.getElementsByTagName("html")[0].classList.remove("grid-view");
  //   };
  // }, []);

  // useEffect(() => {
  //   if (!rolesMap.get("Admin")) {
  //     if (rolesMap.get("Salon admin")) {
  //       getSalonForId(activeUser.userRoles[0].externalId);
  //     }
  //   }
  // }, [rolesMap]);

  // useEffect(() => {
  //   setRolesMap(helper.mapForRole(activeUser));
  // }, [activeUser]);

  return (
    <div
      className="modal fade"
      id="deleteRangeModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="deleteRangeModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-md create-working-time"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="deleteRangeModalLabel">
              <i className="fal fa-clock fa-lg c-purple"></i>{" "}
              <span className="txt">
                {t(
                  "salon.salonDeleteRange.deleteRangeModal.title",
                  "Delete range of working time"
                )}
              </span>
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="fal fa-times" />
            </button>
          </div>

          <div className="modal-body">
            <form className="form needs-validation" noValidate>
              <input type="hidden" value="something" />
              <div className="form-group mb-3">
                <label htmlFor="createWorkingTimeAutoSalonName">
                  {helper.i18nMultiFallbackKey(
                    "salon.salonDeleteRange.deleteRangeModal.shopNameLabel",
                    "Shop name"
                  )}
                </label>
                <input
                  type="text"
                  className="form-control pl-0-maxw-575"
                  id="createWorkingTimeAutoSalonName"
                  value={`${selectedSalon.name}`}
                  readOnly
                />
              </div>
              <div className="row">
                <div className="col-12">
                  <label className="form-group-title pl-0-maxw-575">
                    {t(
                      "salon.salonDeleteRange.deleteRangeModal.selectRangeLabel",
                      "Select range start and end"
                    )}
                  </label>
                </div>
              </div>

              <div className="row cols-block-maxw-575 non-working-days">
                <div className="col-9">
                  <div className="row col-center-padd-5-maxw-575">
                    <div className="form-group calendar-controls nested-react-wrapper-w-100 cc-default col-6 ico-r p-r28-b12">
                      <label
                        htmlFor="createWorkingTimeAutoStartDate"
                        className="required"
                      >
                        {t(
                          "salon.salonDeleteRange.deleteRangeModal.startDateLabel",
                          "Start date"
                        )}
                      </label>
                      <DatePicker
                        id="createWorkingTimeAutoStartDate"
                        className="form-control"
                        selected={moment(deleteRangeStartDate, "YYYY-MM-DD")
                          .startOf("day")
                          .toDate()}
                        dateFormat="yyyy-MM-dd"
                        onKeyDown={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onChange={(date) => {
                          setDeleteRangeStartDate(
                            moment(date).format("YYYY-MM-DD")
                          );
                          // getSalonWorkingTimeIsDateCreated(
                          //   selectedSalon.salonId,
                          //   moment(date).format("YYYY-MM-DD")
                          // );
                        }}
                        placeholderText={t(
                          "salon.salonDeleteRange.deleteRangeModal.startDatePlaceholder",
                          "Select date"
                        )}
                      />
                      <i className="fal fa-calendar-day"></i>
                    </div>
                    <div className="form-group calendar-controls nested-react-wrapper-w-100 cc-default col-6 ico-r p-r28-b12">
                      <label
                        htmlFor="createWorkingTimeAutoEndDate"
                        className="required"
                      >
                        {t(
                          "salon.salonDeleteRange.deleteRangeModal.endDateLabel",
                          "End date"
                        )}
                      </label>
                      <DatePicker
                        id="createWorkingTimeAutoEndDate"
                        className="form-control"
                        selected={moment(deleteRangeEndDate, "YYYY-MM-DD")
                          .startOf("day")
                          .toDate()}
                        dateFormat="yyyy-MM-dd"
                        onKeyDown={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onChange={(date) => {
                          setDeleteRangeEndDate(
                            moment(date).format("YYYY-MM-DD")
                          );
                        }}
                        placeholderText={t(
                          "salon.salonDeleteRange.deleteRangeModal.endDatePlaceholder",
                          "Select date"
                        )}
                      />
                      <i className="fal fa-calendar-day"></i>
                    </div>
                  </div>
                </div>
              </div>
              <RequiredFieldsComponent />
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-success ico-l"
              data-dismiss="modal"
              onClick={() => {
                deleteRange(
                  selectedSalon.salonId,
                  deleteRangeStartDate,
                  deleteRangeEndDate,
                  () => {
                    clearCreateWorkingTimeElements();
                    clearCheckboxSystem();
                  }
                );
              }}
              disabled={
                moment(deleteRangeEndDate) < moment(deleteRangeStartDate)
              }
            >
              <i className="fal fa-check" /> {t("common.finish", "Finish")}
            </button>
            <button
              type="button"
              className="btn btn-outline-danger ico-l"
              data-dismiss="modal"
              onClick={() => {
                clearCreateWorkingTimeElements();
                clearCheckboxSystem();
              }}
            >
              <i className="fal fa-times" /> {t("common.cancel", "Cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    activeUser: state.activeUser,
    selectedSalon: ownProps.selectedSalon,
    clearCheckboxSystem: ownProps.clearCheckboxSystem,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteRange(salonId, deleteRangeStartDate, deleteRangeEndDate, cb) {
      dispatch(
        mutations.deleteRange(
          salonId,
          deleteRangeStartDate,
          deleteRangeEndDate,
          cb
        )
      );
    },
  };
};

export const ConnectedSalonDeleteRange = connect(
  mapStateToProps,
  mapDispatchToProps
)(SalonDeleteRange);
