import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

const CalendarWeeklyComponent = ({
  salonId,
  hairstylistId,
  getVisits,
  visits,
  currentWeekStart,
  dashboardDataWeekly,
  setCurrentDate,
  setCurrentWeekStart,
  setCurrentMonthStart,
}) => {
  const { t, i18n } = useTranslation();
  const [showWeekPicker, setShowWeekPicker] = useState(false);

  useEffect(() => {}, [hairstylistId, salonId]);

  const getDayOfTheWeek = (index) => {
    switch (index) {
      case 0:
        return t("home.calendarWeeklyComponent.monday", "Monday");
      case 1:
        return t("home.calendarWeeklyComponent.tuesday", "Tuesday");
      case 2:
        return t("home.calendarWeeklyComponent.wednesday", "Wednesday");
      case 3:
        return t("home.calendarWeeklyComponent.thursday", "Thursday");
      case 4:
        return t("home.calendarWeeklyComponent.friday", "Friday");
      case 5:
        return t("home.calendarWeeklyComponent.saturday", "Saturday");
      case 6:
        return t("home.calendarWeeklyComponent.sunday", "Sunday");
      default:
        break;
    }
  };

  return (
    <div className="col-lg-4 calendar-week-holder d-flex flex-column">
      <h5>
        {t(
          "home.calendarWeeklyComponent.calendarWeekly.title",
          "Calendar Weekly"
        )}
      </h5>

      <div className="card mb-3 bg-light-gray flex-grow-1">
        <div className="card-body">
          <label className="column-title-muted">
            {t("home.calendarWeeklyComponent.calendarWeekly.weekLabel", "Week")}
          </label>
          <div className="calendar-header text-center">
            <div className="calendar-controls d-inline-flex align-items-stretch justify-content-center">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={(e) => {
                  let d = moment(currentWeekStart, "DD.MM.YYYY.")
                    .add(-7, "days")
                    .format("DD.MM.YYYY.");
                  setCurrentDate(d);
                  setCurrentWeekStart(
                    moment(d, "DD.MM.YYYY.")
                      .startOf("isoWeek")
                      .format("DD.MM.YYYY.")
                  );
                  setCurrentMonthStart(
                    moment(d, "DD.MM.YYYY.")
                      .startOf("month")
                      .format("DD.MM.YYYY.")
                  );
                }}
              >
                <i className="fal fa-chevron-left"></i>
              </button>
              {/* <span
                onClick={e => {
                  setShowWeekPicker(!showWeekPicker);
                }}
                className="text-dark calendar-type-value"
              >
                {currentWeekStart} -{" "}
                {moment(currentWeekStart, "DD.MM.YYYY.")
                  .add(7, "days")
                  .format("DD.MM.YYYY.")}
              </span>
              {showWeekPicker && (
                <input
                  type="date"
                  id="currentWeek"
                  name="currentWeek"
                  min="2018-01-01"
                  value={currentWeek}
                  onChange={e => {
                    let d = moment(e.target.value, "YYYY-MM-DD").format(
                      "DD.MM.YYYY."
                    );
                    setCurrentWeek(e.target.value);
                    setCurrentDate(d);
                    setCurrentWeekStart(
                      moment(d, "DD.MM.YYYY.")
                        .startOf("isoWeek")
                        .format("DD.MM.YYYY.")
                    );
                    setCurrentMonthStart(
                      moment(d, "DD.MM.YYYY.")
                        .startOf("month")
                        .format("DD.MM.YYYY.")
                    );
                  }}
                ></input>
              )} */}
              <span className="text-dark calendar-type-value two-picker-case">
                <DatePicker
                  className="text-dark calendar-type-value"
                  id="currentDateCreateWorkingTimeStart"
                  selected={moment(currentWeekStart, "DD.MM.YYYY.")
                    .hours(0)
                    .minutes(0)
                    .seconds(0)
                    .milliseconds(0)
                    .toDate()}
                  dateFormat="dd.MM.yyyy."
                  onKeyDown={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onChange={(date) => {
                    let d = moment(date, "YYYY-MM-DD")
                      .startOf("isoWeek")
                      .format("DD.MM.YYYY.");
                    setCurrentDate(d);
                    setCurrentWeekStart(d);
                    setCurrentMonthStart(
                      moment(d, "DD.MM.YYYY.")
                        .startOf("month")
                        .format("DD.MM.YYYY.")
                    );
                  }}
                  // includeDates={activeDates()}
                  // highlightDates={activeDates()}
                  placeholderText="Select date"
                />
                <span className="separator-minus">-</span>
                <DatePicker
                  className="text-dark calendar-type-value"
                  id="currentDateCreateWorkingTimeEnd"
                  selected={moment(currentWeekStart, "DD.MM.YYYY.")
                    .add(7, "days")
                    .hours(0)
                    .minutes(0)
                    .seconds(0)
                    .milliseconds(0)
                    .toDate()}
                  dateFormat="dd.MM.yyyy."
                  onKeyDown={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onChange={(date) => {
                    let d = moment(date, "YYYY-MM-DD")
                      .startOf("isoWeek")
                      .format("DD.MM.YYYY.");
                    setCurrentDate(d);
                    setCurrentWeekStart(d);
                    setCurrentMonthStart(
                      moment(d, "DD.MM.YYYY.")
                        .startOf("month")
                        .format("DD.MM.YYYY.")
                    );
                  }}
                  // includeDates={activeDates()}
                  // highlightDates={activeDates()}
                  placeholderText="Select date"
                />
              </span>

              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={(e) => {
                  let d = moment(currentWeekStart, "DD.MM.YYYY.")
                    .add(7, "days")
                    .format("DD.MM.YYYY.");
                  setCurrentDate(d);
                  setCurrentWeekStart(
                    moment(d, "DD.MM.YYYY.")
                      .startOf("isoWeek")
                      .format("DD.MM.YYYY.")
                  );
                  setCurrentMonthStart(
                    moment(d, "DD.MM.YYYY.")
                      .startOf("month")
                      .format("DD.MM.YYYY.")
                  );
                }}
              >
                <i className="fal fa-chevron-right"></i>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h6>
                {" "}
                {t(
                  "home.calendarWeeklyComponent.calendarWeekly.weekRowLabel",
                  "Week:"
                )}{" "}
                {currentWeekStart}
              </h6>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-2">
              <div className="list-group calendar-appointment">
                {dashboardDataWeekly.daySummaries.map((daySummary, index) => {
                  return (
                    <a
                      key={index}
                      href="#"
                      className="list-group-item list-group-item-action"
                      onClick={() => {
                        setCurrentDate(
                          moment(daySummary.summaryDate).format("DD.MM.YYYY.")
                        );
                      }}
                    >
                      <h5 className="appointment-time">
                        {getDayOfTheWeek(index)}
                      </h5>
                      <div className="appointment-description">
                        <span className="badge badge-primary mr-1">
                          {daySummary.reservedSlotsCount}
                        </span>{" "}
                        <small className="text-muted">
                          {daySummary.freeSlotsCount}{" "}
                          {t(
                            "home.calendarWeeklyComponent.calendarWeekly.freeSlotsCountLabel",
                            "is free"
                          )}
                        </small>
                      </div>
                    </a>
                  );
                })}
                {/* <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Ponedeljak</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga naplacena</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Utorak</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga naplacena</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Sreda</h5>
                  <div className="appointment-description">
                    <span className="badge badge-warning mr-1">14</span>{" "}
                    <small className="text-muted">Usluga naplacena</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Četvrtak</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga naplacena</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Petak</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga naplacena</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Subota</h5>
                  <div className="appointment-description">
                    <span className="badge badge-danger mr-1">14</span>{" "}
                    <small className="text-muted">Usluga naplacena</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Nedelja</h5>
                  <div className="appointment-description">
                    <span className="badge badge-info mr-1">14</span>{" "}
                    <small className="text-muted">Usluga naplacena</small>
                  </div>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="card bg-light-gray text-size-md">
        <div className="card-body">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eu
          odio metus. Sed suscipit blandit est, eu feugiat turpis aliquam in.
          Nam ligula nulla, elementum non sem eu, rhoncus pellentesque ligula.
          Etiam eu vestibulum justo. Maecenas efficitur consequat luctus. Mauris
          volutpat lacus tincidunt, vehicula felis quis, luctus neque.
          Suspendisse luctus dapibus nunc vitae interdum. Mauris hendrerit, sem
          a commodo sodales, turpis turpis venenatis nibh, quis blandit leo quam
          in mauris..
        </div>
      </div> */}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    salonId: ownProps.salonId,
    hairstylistId: ownProps.hairstylistId,
    currentWeekStart: ownProps.currentWeekStart,
    dashboardDataWeekly: state.dashboardDataWeekly,
    setCurrentDate: ownProps.setCurrentDate,
    setCurrentWeekStart: ownProps.setCurrentWeekStart,
    setCurrentMonthStart: ownProps.setCurrentMonthStart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export const ConnectedCalendarWeekly = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarWeeklyComponent);
