import React, { useState, Component, useEffect, useRef } from "react";
import { connect } from "react-redux";
import * as mutations from "../../../store/mutations";
import * as mutationsCheckBox from "../../../store/mutationsCheckBox";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { ServiceTypeDurationColumn } from "../../ServiceTypeDurationColumn";
import { useTranslation, withTranslation } from "react-i18next";
import * as helper from "../../HelperRepository";

const SalonServiceTypeListComponent = ({
  selectedSalon,
  serviceTypes,
  serviceTypeTotalCount,
  setParentState,
  setCurrentDate,
  setToGetFreeSlots,
  getServiceTypesPaged,
  setEnableEdit,
  setEnableCreate,
  showInactiveServiceType,
  setShowInactiveServiceType,
}) => {
  const { t, i18n } = useTranslation();

  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 10 });

  useEffect(() => {
    setShowInactiveServiceType(true);
  }, [selectedSalon.salonId]);

  useEffect(() => {
    if (selectedSalon.salonId) {
      getServiceTypesPaged(selectedSalon.salonId, page.skip / 10 + 1, sort);
    }
  }, [getServiceTypesPaged, page, selectedSalon, sort]);

  useEffect(() => {
    if (selectedSalon.salonId) {
      getServiceTypesPaged(selectedSalon.salonId, page.skip / 10 + 1, sort);
    }
  }, [showInactiveServiceType]);

  const ActionButtonsColumnWithTranslation = withTranslation()(
    ActionButtonsColumn
  );

  const grid = (
    <Grid
      data={serviceTypes}
      pageable={{
        buttonCount: 3,
        info: true,
        type: "numeric",
        previousNext: true,
      }}
      skip={page.skip}
      take={page.take}
      onPageChange={(event) => {
        setPage({ skip: event.page.skip, take: event.page.take });
        getServiceTypesPaged(
          selectedSalon.salonId,
          event.page.skip / 10 + 1,
          sort
        );
      }}
      total={serviceTypeTotalCount}
      sortable
      sort={sort}
      onSortChange={(e) => {
        setSort(e.sort);
        getServiceTypesPaged(selectedSalon.salonId, page.skip / 10 + 1, e.sort);
      }}
      style={{ height: "410px" }}
      className="table-header-purple"
    >
      <GridColumn
        field="serviceTypeName"
        title={t(
          "salon.salonServiceTypeListComponent.grid.serviceType.title",
          "Service"
        )}
        className="start-time-cell"
        headerClassName="start-time-cell"
      />
      <GridColumn
        field="serviceTypeDuration"
        title={t(
          "salon.salonServiceTypeListComponent.grid.serviceTypeDuration.title",
          "Duration"
        )}
        className="end-time-cell"
        headerClassName="end-time-cell"
        cell={(props) => (
          <ServiceTypeDurationColumn
            serviceType={props.dataItem}
            selectedSalon={selectedSalon}
            mobileTitle={t(
              "salon.salonServiceTypeListComponent.grid.serviceTypeDuration.mobileTitle",
              "Duration:"
            )}
          />
        )}
      />
      <GridColumn
        field="serviceTypePrice"
        title={t(
          "salon.salonServiceTypeListComponent.grid.serviceTypePrice.title",
          "Price [rsd]"
        )}
        className="end-time-cell"
        headerClassName="end-time-cell"
      />
      <GridColumn
        field="Actions"
        title={t("common.actions", "Actions")}
        cell={(props) => (
          <ActionButtonsColumnWithTranslation
            setParentState={setParentState}
            setToGetFreeSlots={setToGetFreeSlots}
            setCurrentDate={setCurrentDate}
            setEnableEdit={setEnableEdit}
            {...props}
          />
        )}
        headerClassName="actions-cell two-bttns"
      />
    </Grid>
  );

  return (
    <>
      <div className="table-rounded-border table-headlabel-responsive-md hairstylist-table">
        <header>
          <div className="button-container">
            <button
              type="button"
              className="btn btn-purple-outline btn-sm ico-l"
              data-toggle="modal"
              data-target={`#createSalonServiceTypeModal`}
              onClick={() => {}}
            >
              <i className="fal fa-plus"></i>{" "}
              {t("salon.salonServiceTypeListComponent.newLabel", "New")}
              <span className="mobile-hide-550">
                {" "}
                {helper.i18nMultiFallbackKey(
                  "salon.salonServiceTypeListComponent.shopServiceTypeLabel",
                  "Shop Service type"
                )}
              </span>
            </button>
            <label htmlFor="showInactiveServiceType">
              <input
                type="checkbox"
                id="showInactiveServiceType"
                name="showInactiveServiceType"
                checked={showInactiveServiceType}
                onChange={() =>
                  setShowInactiveServiceType(!showInactiveServiceType)
                }
              />{" "}
              Show inactive
            </label>
          </div>
        </header>
        {grid}
      </div>
    </>
  );
};
class ActionButtonsColumn extends Component {
  render() {
    return (
      <td className="actions-cell two-bttns">
        <div className="button-container">
          <button
            type="button"
            className="btn btn-outline-success btn-sm ico-l"
            data-toggle="modal"
            data-target="#editSalonServiceTypeModal"
            onClick={() => {
              this.props.setParentState(this.props.dataItem);
              this.props.setEnableEdit(true);
            }}
          >
            <i className="fal fa-edit"></i>
            {this.props.t("common.edit", "Edit")}
          </button>

          {this.props.dataItem.status.itemName === "Active" && (
            <button
              type="button"
              className="btn btn-outline-danger btn-sm ico-l"
              data-toggle="modal"
              data-target="#deleteSalonServiceTypeModal"
              onClick={() => {
                this.props.setParentState(this.props.dataItem);
                this.props.setEnableEdit(true);
              }}
              // disabled={this.props.dataItem.hasDependents}
            >
              <i className="fal fa-ban"></i>
              {this.props.t("common.deactivate", "Deactivate")}
            </button>
          )}

          {this.props.dataItem.status.itemName === "Inactive" && (
            <button
              type="button"
              className="btn btn-outline-danger btn-sm ico-l"
              data-toggle="modal"
              data-target="#activateSalonServiceTypeModal"
              onClick={() => {
                this.props.setParentState(this.props.dataItem);
                this.props.setEnableEdit(true);
              }}
              // disabled={this.props.dataItem.hasDependents}
            >
              <i className="fal fa-ban"></i>
              {this.props.t("common.activate", "Activate")}
            </button>
          )}
        </div>
      </td>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    selectedSalon: ownProps.selectedSalon,
    setParentState: ownProps.setParentState,
    clearParentState: ownProps.clearParentState,
    serviceTypes: state.serviceTypes,
    serviceTypeTotalCount: state.serviceTypeTotalCount,
    setEnableEdit: ownProps.setEnableEdit,
    setEnableCreate: ownProps.setEnableCreate,
    showInactiveServiceType: state.checkedState,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getServiceTypesPaged(salonId, page, sort) {
    dispatch(mutations.getServiceTypesPagedWithInactive(salonId, page, sort));
  },
  setShowInactiveServiceType(newState) {
    dispatch(mutationsCheckBox.setCheckBox(newState));
  },
});

export const ConnectedSalonServiceTypeList = connect(
  mapStateToProps,
  mapDispatchToProps
)(SalonServiceTypeListComponent);
