import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";

import * as Helper from "../HelperRepository";
import { useTranslation } from "react-i18next";

const ImagesDropDownComponent = ({
    images,
    id,
  setId,
  getAllImages,
}) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getAllImages();
  }, []);

  if (Helper.isArray(images)) {
    return (
      <div className="custom-select-box">
        <select
          className="form-control"
          value={id}
          onChange={(event) => {
            setId(event.target.value);
          }}
          required
        >
          <option key={""} value={""}>
            {t(
              "dropdowns.imagesDropDownComponent.label",
              "Select images"
            )}
          </option>
          {images.map((r) => {
            return (
              <option key={r.id} value={r.id}>
                {r.url}
              </option>
            );
          })}
        </select>
      </div>
    );
  } else {
    return (
      <div className="custom-select-box">
        <select
          className="form-control"
          value={id}
          onChange={(event) => {
            setId(event.target.value);
          }}
          required
        >
          <option key={""} value={""}>
            {t(
              "dropdowns.imagesDropDownComponent.loadingImages",
              "Loading images..."
            )}
          </option>
          <option
            key={images.id}
            value={images.id}
          >
            {images.url}
          </option>
        </select>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    images: state.images,
    id: ownProps.id,
    setId: ownProps.setId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllImages() {
    dispatch(mutations.getAllImages());
  },
});

export const ConnectedImagesDropDown = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImagesDropDownComponent);
