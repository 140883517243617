import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import * as moment from "moment";
import { withTranslation } from "react-i18next";

class FreeSlotsDropdownComponent extends Component {
  componentDidMount() {
    this.setState({ initialLoad: true });
  }
  componentDidUpdate(prevProps) {
    if (this.props.visitDate !== prevProps.visitDate) {
      this.setState({ initialLoad: true });
    }
    if (this.props.hairstylistId !== prevProps.hairstylistId) {
      this.setState({ initialLoad: true });
    }
    if (this.props.salonId !== prevProps.salonId) {
      this.setState({ initialLoad: true });
    }
    if (
      this.props.salonId !== "" &&
      this.props.hairstylistId !== "" &&
      this.props.selectedServices.length > 0 &&
      this.props.visitDate !== "" &&
      this.props.visitDate !== "Invalid date"
    ) {
      if (this.state.initialLoad) {
        this.setState({ initialLoad: false });
        this.reload();
      }
    }
  }
  reload = () => {
    this.props.getAllFreeVisitTimeSlots(
      this.props.salonId,
      this.props.hairstylistId,
      this.props.visitDate,
      this.props.selectedServices
    );
  };

  available = (status) => {
    if (status) {
      return "yes";
    } else {
      return "no";
    }
  };
  render() {
    if (
      this.props.salonId !== "" &&
      this.props.hairstylistId !== "" &&
      this.props.selectedServices.length > 0 &&
      this.props.visitDate !== "" &&
      this.props.visitDate !== "Invalid date"
    ) {
      return (
        <>
          <div className="custom-select-box">
            <select
              className="form-control"
              onChange={(event) => {
                const startHours = moment(event.target.value, "HH:mm").hour();
                const startMinutes = moment(
                  event.target.value,
                  "HH:mm"
                ).minute();

                this.props.setStartTime(
                  moment(this.props.visitDate)
                    .hour(startHours)
                    .minute(startMinutes)
                    .format("YYYY-MM-DD HH:mm")
                );

                this.props.setEndTime(
                  moment(this.props.visitDate)
                    .hour(startHours)
                    .minute(startMinutes)
                    .add(this.props.salonTimeSlot, "minutes")
                    .format("YYYY-MM-DD HH:mm")
                );
              }}
              required
            >
              <option key={""} value={""}>
                {this.props.t(
                  "dropdowns.freeSlotsDropdownComponent.label",
                  "Select time slot"
                )}
              </option>
              {this.props.freeVisitTimeSlots.map((r) => {
                if (r.timeSlotFree) {
                  return (
                    <option
                      key={r.timeIntervalGuid}
                      value={moment(r.startTime).format("HH:mm")}
                    >
                      {moment(r.startTime).format("HH:mm")}
                    </option>
                  );
                } else {
                  return null;
                }
              })}
            </select>
          </div>
        </>
      );
    } else {
      return (
        <div className="custom-select-box">
          <select className="form-control" required>
            <option key={""} value={""}>
              {this.props.t(
                "dropdowns.freeSlotsDropdownComponent.label",
                "Select time slot"
              )}
            </option>
          </select>
        </div>
      );
    }
  }
}

const mapStateToPropsFreeSlots = (state, ownProps) => {
  return {
    salons: state.salons,
    salonId: ownProps.salonId,
    hairstylistId: ownProps.hairstylistId,
    selectedServices: ownProps.selectedServices,
    visitDate: ownProps.visitDate,
    freeVisitTimeSlots: state.freeVisitTimeSlots,
    setStartTime: ownProps.setStartTime,
    setEndTime: ownProps.setEndTime,
    salonTimeSlot: ownProps.salonTimeSlot,
  };
};

const mapDispatchToPropsFreeSlots = (dispatch) => ({
  getAllFreeVisitTimeSlots(
    salonId,
    hairstylistId,
    visitDate,
    selectedServices
  ) {
    let freeTimeSlotRequest = {
      SalonId: salonId,
      HairstylistId: hairstylistId,
      VisitDate: visitDate,
      SelectedServices: selectedServices,
    };
    dispatch(mutations.getAllFreeVisitTimeSlots(freeTimeSlotRequest));
  },
});

export const ConnectedFreeSlotsDropdownComponent = withTranslation()(
  connect(
    mapStateToPropsFreeSlots,
    mapDispatchToPropsFreeSlots
  )(FreeSlotsDropdownComponent)
);
