import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../../store/mutations";
import { withTranslation } from "react-i18next";

export class SalonServiceTypeDelete extends Component {
  render() {
    return (
      <div
        className="modal fade"
        data-backdrop="static"
        data-keyboard="false"
        id="deleteSalonServiceTypeModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteSalonServiceTypeModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteSalonServiceTypeModalLabel">
                <i className="fal fa-user fa-lg c-purple" />{" "}
                {helper.i18nMultiFallbackKey(
                  "salon.salonServiceTypeDelete.deleteSalonServiceTypeModal.title",
                  "Deactivate Shop Service Type"
                )}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fal fa-times" />
              </button>
            </div>

            <div className="modal-body">
              <h2 className="info-title-upp-case-xl icon-custom-top-center">
                <span className="icon-custom is-xl icon-triangle-red-white" />
                {this.props.t(
                  "salon.salonServiceTypeDelete.deleteSalonServiceTypeModal.attention",
                  "Attention"
                )}
              </h2>
              <div className="info-message text-center">
                <p>
                  {helper.i18nMultiFallbackKey(
                    "salon.salonServiceTypeDelete.deleteSalonServiceTypeModal.question",
                    "Are you sure you want to deactivate shop service type"
                  )}
                </p>
                <p className="prime-text">
                  {this.props.selectedServiceType.serviceTypeName}{" "}
                  {helper.i18nMultiFallbackKey(
                    "salon.salonServiceTypeDelete.deleteSalonServiceTypeModal.inShop",
                    "in shop"
                  )}{" "}
                  {this.props.selectedSalon.name} ?
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-success ico-l"
                data-dismiss="modal"
                onClick={() =>
                  this.props.deleteServiceType(
                    this.props.selectedServiceType,
                    () => this.props.clearParentState()
                  )
                }
              >
                <i className="fal fa-check" />{" "}
                {this.props.t("common.yes", "Yes")}
              </button>
              <button
                type="button"
                className="btn btn-outline-danger ico-l"
                data-dismiss="modal"
              >
                <i className="fal fa-times" /> {this.props.t("common.no", "No")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPropsFreeSlots = (state, ownProps) => {
  return {
    selectedSalon: ownProps.selectedSalon,
    clearParentState: ownProps.clearParentState,
    selectedServiceType: ownProps.selectedServiceType,
  };
};

const mapDispatchToPropsFreeSlots = (dispatch) => ({
  deleteServiceType(serviceTypeForDelete, cb) {
    cb();
    dispatch(mutations.deleteServiceType(serviceTypeForDelete));
  },
});

export const ConnectedSalonServiceTypeDelete = withTranslation()(
  connect(
    mapStateToPropsFreeSlots,
    mapDispatchToPropsFreeSlots
  )(SalonServiceTypeDelete)
);
