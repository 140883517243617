import React from "react";
// import "@progress/kendo-theme-default/dist/all.css";
import { Route, Router } from "react-router-dom";
import { Provider } from "react-redux";
import { history } from "./store/history";
import { store } from "./store";
import { ConnectedLogin } from "./components/Login";
import { ConnectedMainContainer } from "./components/MainContainer";
import { Redirect } from "react-router";
import { isAuthenticated } from "./store/authenticate";
import { CookiesProvider } from "react-cookie";
import "react-datepicker/dist/react-datepicker.css";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./style/css/asterisk.css";
import "./style/css/tooltip.ZA.OBRADU.VS.module.css";

const RouteGuard = (Component) => ({ match }) =>
  (!store.getState().session.authenticated && !isAuthenticated()) ||
  !sessionStorage.getItem("token") ? (
    <Redirect to="/login" />
  ) : (
    <Component match={match} />
  );

function App() {
  return (
    <CookiesProvider>
      <Router history={history}>
        <Provider store={store}>
          <>
            <Route exact path="/" render={RouteGuard(ConnectedMainContainer)} />

            <Route
              exact
              path="/login"
              render={(props) => <ConnectedLogin {...props} />}
            />
            {/* <Route exact path="/home" render={RouteGuard(ConnectedHome)} />
            <Route exact path="/user" render={RouteGuard(ConnectedUser)} />
            <Route
              exact
              path="/customer"
              render={RouteGuard(ConnectedCustomer)}
            />
            <Route
              exact
              path="/hairstylist"
              render={RouteGuard(ConnectedHairstylist)}
            />
            <Route exact path="/role" render={RouteGuard(ConnectedRole)} />
            <Route exact path="/salon" render={RouteGuard(ConnectedSalon)} />
            <Route exact path="/visit" render={RouteGuard(ConnectedVisit)} /> */}
          </>
        </Provider>
      </Router>
    </CookiesProvider>
  );
}

export default App;
