import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ConnectedHairstylistList } from "./HairstylistList";
import { ConnectedSalonsDropDown } from "../Dropdowns/SalonsDropDown";
import { ConnectedCategoryItemsDropDown } from "../Dropdowns/CategoryItemsDropDown";
import { ConnectedFreeHairstylistSlotsPopupComponent } from "./FreeHairstylistSlotPopupComponent";
import { ConnectedHairstylistServiceTypeList } from "./HairstylistServiceType/HairstylistServiceTypeList";
import { ConnectedHairstylistSalonList } from "./HairstylistSalonList";
import { ConnectedServiceTypesDropDown } from "../Dropdowns/ServiceTypesDropDown";
import { ConnectedHairstylistWorkingTimeConsolidatedList } from "./HairstylistWorkingTimeConsolidatedList";
import { ConnectedHairstylistsDropDown } from "../Dropdowns/HairstylistsDropDown";
import { ConnectedCreateHairstylistModal } from "./CreateHairstylistModal";
import { ConnectedEditHairstylistModal } from "./EditHairstylistModal";

import * as helper from "../HelperRepository";
import * as mutations from "../../store/mutations";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import RequiredFieldsComponent from "../RequiredFieldsComponent";

const HairstylistComponent = ({
  activeUser,
  getActiveUser,
  isDateCreated,
  deleteHairstylist,
  deleteHairstylistForSalon,
  createHairstylistWorkingTime,
  saveHairstylistWorkingTime,
  deleteHairstylistWorkingTimeConsolidated,
  salons,
  getHairstylistWorkingTimeIsDateCreated,
  getSalonWorkingTimes,
  createHairstylistServiceType,
  deleteHairstylistServiceType,
  createSalonHairstylist,
  deleteSalonHairstylist,
  availableServiceTypes,
  clearSalonHairstylists,
  clearHairstylistWorkingTimes,
  clearHairstylistServiceTypes,
  freeHairstylistTimeSlots,
  getAllHairstylistWorkingTimesDates,
}) => {
  const { t, i18n } = useTranslation();

  document.getElementsByTagName("html")[0].classList.remove("page-signin");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [registrationsNumber, setRegistrationsNumber] = useState("");
  const [personId, setPersonId] = useState("");
  const [hairstylistId, setHairstylistId] = useState("");
  const [salonId, setSalonId] = useState("");
  const [salonHairstylistId, setSalonHairstylistId] = useState("");
  const [statusId, setStatusId] = useState("");
  const [genderId, setGenderId] = useState("");
  const [hasDependents, setHasDependents] = useState(false);
  const [serviceTypes, setServiceTypes] = useState({});
  const [toGetFreeSlots, setToGetFreeSlots] = useState(false);
  const [selectedSalon, setSelectedSalon] = useState({});
  const [selectedSalonHairstylist, setSelectedSalonHairstylist] = useState({});

  const [isEditHairstylist, setIsEditHairstylist] = useState(false);
  const [isCreateHairstylist, setIsCreateHairstylist] = useState(false);
  const [isDeleteHairstylist, setIsDeleteHairstylist] = useState(false);

  const [isEditWorkTime, setIsEditWorkTime] = useState(false);
  const [isCreateWorkTime, setIsCreateWorkTime] = useState(false);
  const [isDeleteWorkTime, setIsDeleteWorkTime] = useState(false);

  const [isActivateServiceType, setIsActivateServiceType] = useState(false);
  const [isDeactivateServiceType, setIsDeactivateServiceType] = useState(false);

  const [isAssignToSalon, setIsAssignToSalon] = useState(false);
  const [isUnassignFromSalon, setIsUnassignFromSalon] = useState(false);

  const [
    selectedHairstylistServiceType,
    setSelectedHairstlistServiceType,
  ] = useState({});

  const [workingTimeId, setWorkingTimeId] = useState("");

  const [selectedHairstylist, setSelectedHairstylist] = useState({});
  const [salonTimeSlot, setSalonTimeSlot] = useState("");
  const [selectedServiceTypeId, setSelectedServiceTypeId] = useState("");
  const [workingTimeConsolidated, setWorkingTimeConsolidated] = useState("");

  const [activeDates, setActiveDates] = useState([]);

  const [startTime, setStartTime] = useState(() => {
    let s = moment().startOf("day").toDate();
    return s;
  });
  const [endTime, setEndTime] = useState(() => {
    let e = moment().startOf("day").toDate();
    return e;
  });
  const m = moment(new Date()).format("YYYY-MM-DD");
  const [currentDate, setCurrentDate] = useState(m);

  const [checkboxInputs, setCheckboxInputs] = useState(() => {
    let s = moment().startOf("day").toDate();

    let e = moment().endOf("day").toDate();

    let n = (e - s) / 1800000;
    let a = [];
    for (let index = 0; index < n; index++) {
      let d1 = new Date(s.getTime() + index * 30 * 60 * 1000);
      let d2 = new Date(s.getTime() + (index + 1) * 30 * 60 * 1000);
      a.push({
        value: true,
        startTime: moment(d1).format("HH:mm"),
        endTime: moment(d2).format("HH:mm"),
      });
    }
    return [...helper.cloneDeep(a)];
  });

  const [rolesMap, setRolesMap] = useState(() => {
    return helper.createMapObject();
  });

  const [formErrors, setFormErrors] = useState({});
  const [firstNameValid, setFirstNameValid] = useState(false);
  const [lastNameValid, setLastNameValid] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(false);
  const [formValid, setFormValid] = useState(false);

  const [editFirstNameValid, setEditFirstNameValid] = useState(true);
  const [editLastNameValid, setEditLastNameValid] = useState(true);
  const [editEmailValid, setEditEmailValid] = useState(true);
  const [editPhoneValid, setEditPhoneValid] = useState(true);
  const [editFormValid, setEditFormValid] = useState(true);

  useEffect(() => {
    setRolesMap(helper.mapForRole(activeUser));
    if (
      activeUser.userRoles &&
      activeUser.userRoles[0].role.name === "Salon admin"
    ) {
      setSalonId(activeUser.userRoles[0].externalId);
    }
  }, [activeUser]);

  useEffect(() => {
    clearSalonHairstylists();
    clearHairstylistWorkingTimes();
    clearHairstylistServiceTypes();
    if (!activeUser || !activeUser.personId) {
      getActiveUser();
    }
    return () => {
      document.getElementsByTagName("html")[0].classList.remove("grid-view");
    };
  }, []);

  useEffect(() => {
    if (!helper.isEmpty(selectedSalon)) {
      getSalonWorkingTimes(selectedSalonHairstylist.salonId);
      clearHairstylistWorkingTimes();
      clearHairstylistServiceTypes();
    }
  }, [selectedSalon]);

  const clearState = () => {
    setFirstName("");
    setLastName("");
    setPhone("");
    setEmail("");
    setPersonId("");
    setSalonId("");
    setSalonHairstylistId("");
    setHairstylistId("");
    setSelectedSalonHairstylist({});
    setSelectedSalon({});
    setRegistrationsNumber("");
    setStatusId("");
    setGenderId("");
    setHasDependents(false);
    setStartTime(new Date());
    setEndTime(new Date());
    setCurrentDate(m);
    setSelectedHairstlistServiceType({});
    setFormErrors({});
    setFormValid(false);
    setFirstNameValid(false);
    setLastNameValid(false);
    setPhoneValid(false);
    setEmailValid(true);
    setEditFormValid(true);
    setEditFirstNameValid(true);
    setEditLastNameValid(true);
    setEditPhoneValid(true);
    setEditEmailValid(true);
    if (
      activeUser.userRoles &&
      activeUser.userRoles[0].role.name === "Salon admin"
    ) {
      setSalonId(activeUser.userRoles[0].externalId);
    }
  };

  const clearSelectedHairstlistServiceType = () => {
    setSelectedHairstlistServiceType({});
    setSelectedServiceTypeId("");
  };

  const clearCheckboxSystem = () => {
    setToGetFreeSlots(false);

    setIsEditHairstylist(false);
    setIsCreateHairstylist(false);
    setIsDeleteHairstylist(false);

    setIsEditWorkTime(false);
    setIsCreateWorkTime(false);
    setIsDeleteWorkTime(false);

    setIsActivateServiceType(false);
    setIsDeactivateServiceType(false);

    setIsAssignToSalon(false);
    setIsUnassignFromSalon(false);

    let s = moment().startOf("day").toDate();

    let e = moment().endOf("day").toDate();

    setStartTime(s);
    setEndTime(s);

    //inicijalizacija checkboxova
    let n = (e - s) / 1800000;
    let a = [];
    for (let index = 0; index < n; index++) {
      let d1 = new Date(s.getTime() + index * 30 * 60 * 1000);
      let d2 = new Date(s.getTime() + (index + 1) * 30 * 60 * 1000);
      a.push({
        value: true,
        startTime: moment(d1).format("HH:mm"),
        endTime: moment(d2).format("HH:mm"),
      });
    }

    setCheckboxInputs([...helper.cloneDeep(a)]);
  };

  const setHairstylistState = (hairstylistValue) => {
    setFirstName(hairstylistValue.firstName);
    setLastName(hairstylistValue.lastName);
    setPhone(hairstylistValue.phone);
    setEmail(hairstylistValue.email);
    setPersonId(hairstylistValue.personId);
    setRegistrationsNumber(hairstylistValue.registrationsNumber);
    setStatusId(hairstylistValue.statusId);
    setGenderId(hairstylistValue.genderId ? hairstylistValue.genderId : "");
    setHasDependents(hairstylistValue.hasDependents);
  };

  // const setWorkingTimeState = workingTime => {
  //   let s = new Date(workingTime.startTime);
  //   let e = new Date(workingTime.endTime);
  //   let c = moment(s).format("YYYY-MM-DD");
  //   setCurrentDate(c);
  //   setStartTime(s);
  //   setEndTime(e);
  //   setWorkingTimeId(workingTime.workingTimeId);
  // };

  const setHairstylistWorkingTimeStateConsolidated = (workingTimeValue) => {
    let c = moment(workingTimeValue.workingTimeDate).format("YYYY-MM-DD");
    setCurrentDate(c);
    setWorkingTimeConsolidated(workingTimeValue);
  };

  const setHairstylistServiceTypeState = (serviceTypeValue) => {
    setSelectedHairstlistServiceType(serviceTypeValue || {});
  };

  const setFullSelectedSalon = (selectedSalonValue) => {
    let salonToUse;
    if (!helper.isEmpty(salons)) {
      salonToUse = helper.cloneDeep(
        salons.find((salon) => salon.salonId === selectedSalonValue.salonId)
      );
    } else {
      salonToUse = helper.cloneDeep(selectedSalonValue);
    }
    setSelectedSalon(salonToUse || {});
  };

  // const filterServiceTypes = allServiceTypes => {
  //   if (allServiceTypes) {
  //     let arrayToUse = allServiceTypes.filter(serviceType => {
  //       let toReturn = true;
  //       for (let hairstylistServiceType of hairstylistServiceTypes) {
  //         if (
  //           serviceType.serviceTypeId === hairstylistServiceType.serviceTypeId
  //         ) {
  //           toReturn = false;
  //         }
  //       }
  //       return toReturn;
  //     });

  //     return arrayToUse;
  //   } else {
  //     return [];
  //   }
  // };

  const getShouldDisableFinish = () => {
    let hasFreeSlots = freeHairstylistTimeSlots.some(
      (element) => element.timeSlotFree
    );
    return isDateCreated || !hasFreeSlots;
  };

  return (
    <>
      <main role="main">
        <div className="container-fluid">
          <ConnectedHairstylistList
            setParentState={setHairstylistState}
            setSelectedHairstylist={setSelectedHairstylist}
            selectedHairstylist={selectedHairstylist}
            clearParentState={clearState}
            setIsEdit={setIsEditHairstylist}
            setIsCreate={setIsCreateHairstylist}
            setIsDelete={setIsDeleteHairstylist}
          />
        </div>
        {selectedHairstylist.firstName && (
          <>
            <div className="container-fluid mt-4">
              <ConnectedHairstylistSalonList
                setParentState={setHairstylistState}
                setSelectedSalon={setFullSelectedSalon}
                setSelectedSalonHairstylist={setSelectedSalonHairstylist}
                selectedSalon={selectedSalon}
                selectedHairstylist={selectedHairstylist}
                clearParentState={clearState}
                setIsAssignToSalon={setIsAssignToSalon}
                setIsUnassignFromSalon={setIsUnassignFromSalon}
              />
            </div>
            {selectedSalonHairstylist.salonHairstylistId && (
              <>
                <div className="container-fluid my-4">
                  <div className="row">
                    <div className="col-md-6">
                      <ConnectedHairstylistWorkingTimeConsolidatedList
                        setParentState={
                          setHairstylistWorkingTimeStateConsolidated
                        }
                        selectedSalonHairstylist={selectedSalonHairstylist}
                        clearParentState={setHairstylistState}
                        setCurrentDate={setCurrentDate}
                        setToGetFreeSlots={setToGetFreeSlots}
                        getHairstylistWorkingTimeIsDateCreated={
                          getHairstylistWorkingTimeIsDateCreated
                        }
                        getAllHairstylistWorkingTimesDates={
                          getAllHairstylistWorkingTimesDates
                        }
                        setActiveDates={setActiveDates}
                        setIsEdit={setIsEditWorkTime}
                        setIsCreate={setIsCreateWorkTime}
                        setIsDelete={setIsDeleteWorkTime}
                      />
                    </div>
                    <div className="col-md-6 mt-4 mt-md-0">
                      <ConnectedHairstylistServiceTypeList
                        setParentState={setHairstylistServiceTypeState}
                        selectedSalonHairstylist={selectedSalonHairstylist}
                        setIsActivate={setIsActivateServiceType}
                        setIsDeactivate={setIsDeactivateServiceType}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </main>

      <footer className="footer">
        <div className="container copyright">
          <span className="text-muted">
            Hairstylist &copy; Copyright {moment().format("YYYY")},{" "}
            {t("common.version", "Version:")} {process.env.VERSION}
          </span>
        </div>
      </footer>

      <ConnectedCreateHairstylistModal
        isCreateHairstylist={isCreateHairstylist}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        phone={phone}
        setPhone={setPhone}
        email={email}
        setEmail={setEmail}
        registrationsNumber={registrationsNumber}
        setRegistrationsNumber={setRegistrationsNumber}
        salonId={salonId}
        setSalonId={setSalonId}
        setServiceTypes={setServiceTypes}
        setSalonTimeSlot={setSalonTimeSlot}
        genderId={genderId}
        setGenderId={setGenderId}
        clearCheckboxSystem={clearCheckboxSystem}
        clearState={clearState}
        rolesMap={rolesMap}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        firstNameValid={firstNameValid}
        setFirstNameValid={setFirstNameValid}
        lastNameValid={lastNameValid}
        setLastNameValid={setLastNameValid}
        phoneValid={phoneValid}
        setPhoneValid={setPhoneValid}
        emailValid={emailValid}
        setEmailValid={setEmailValid}
        formValid={formValid}
        setFormValid={setFormValid}
      />

      <ConnectedEditHairstylistModal
        isEditHairstylist={isEditHairstylist}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        phone={phone}
        setPhone={setPhone}
        email={email}
        setEmail={setEmail}
        registrationsNumber={registrationsNumber}
        setRegistrationsNumber={setRegistrationsNumber}
        salonId={salonId}
        personId={personId}
        statusId={statusId}
        genderId={genderId}
        setGenderId={setGenderId}
        clearCheckboxSystem={clearCheckboxSystem}
        clearState={clearState}
        rolesMap={rolesMap}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        firstNameValid={editFirstNameValid}
        setFirstNameValid={setEditFirstNameValid}
        lastNameValid={editLastNameValid}
        setLastNameValid={setEditLastNameValid}
        phoneValid={editPhoneValid}
        setPhoneValid={setEditPhoneValid}
        emailValid={editEmailValid}
        setEmailValid={setEditEmailValid}
        formValid={editFormValid}
        setFormValid={setEditFormValid}
      />

      {/* Delete Hairstylist Modal */}
      {isDeleteHairstylist && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="deleteHairstylistModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="deleteHairstylistModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="deleteHairstylistModalLabel">
                  <i className="fal fa-address-card fa-lg c-purple" />{" "}
                  {helper.i18nMultiFallbackKey(
                    "hairstylist.hairstylistComponent.deleteHairstylistModal.title",
                    "Deactivate Provider"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <h2 className="info-title-upp-case-xl icon-custom-top-center">
                  <span className="icon-custom is-xl icon-triangle-red-white" />
                  {t(
                    "hairstylist.hairstylistComponent.deleteHairstylistModal.attention",
                    "Attention"
                  )}
                </h2>
                <div className="info-message text-center">
                  <p>
                    {helper.i18nMultiFallbackKey(
                      "hairstylist.hairstylistComponent.deleteHairstylistModal.question",
                      "Are you sure you want to deactivate provider"
                    )}
                  </p>
                  <p className="prime-text">
                    {firstName} {lastName}?
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  onClick={() => {
                    if (rolesMap.get("Admin")) {
                      deleteHairstylist(personId, () => {
                        clearState();
                        clearCheckboxSystem();
                      });
                    } else {
                      if (rolesMap.get("Salon admin")) {
                        deleteHairstylistForSalon(personId, salonId, () => {
                          clearState();
                          clearCheckboxSystem();
                        });
                      }
                    }
                  }}
                >
                  <i className="fal fa-check" /> {t("common.yes", "Yes")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => {
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-times" /> {t("common.no", "No")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Create Hairstylist Working time Modal */}
      {selectedHairstylist.personId && isCreateWorkTime && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="createHairstylistWorkingTimeModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="createHairstylistWorkingTimeModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="createHairstylistWorkingTimeModalLabel"
                >
                  <i className="fal fa-clock fa-lg c-purple" />{" "}
                  {helper.i18nMultiFallbackKey(
                    "hairstylist.hairstylistComponent.createHairstylistWorkingTimeModal.title",
                    "Create Provider Working time"
                  )}{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <input type="hidden" value="something" />
                  <div className="form-group">
                    <label htmlFor="hairstylistFirstNameCreateWorkingTime">
                      {helper.i18nMultiFallbackKey(
                        "hairstylist.hairstylistComponent.createHairstylistWorkingTimeModal.hairstylistNameLabel",
                        "Provider name"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="hairstylistFirstNameCreateWorkingTime"
                      value={`${selectedHairstylist.firstName} ${selectedHairstylist.lastName}`}
                      onChange={(event) => setFirstName(event.target.value)}
                      disabled
                    />
                  </div>

                  <div className="form-group calendar-controls cc-default">
                    <label
                      className="d-block required"
                      htmlFor="currentDateCreateHairstylistWorkingTime"
                    >
                      {t(
                        "hairstylist.hairstylistComponent.createHairstylistWorkingTimeModal.dateLabel",
                        "Date"
                      )}
                    </label>
                    <DatePicker
                      className="form-control"
                      id="currentDateCreateHairstylistWorkingTime"
                      selected={
                        currentDate
                          ? moment(currentDate, "YYYY-MM-DD")
                              .startOf("day")
                              .toDate()
                          : setCurrentDate(
                              moment(activeDates[0]).format("YYYY-MM-DD")
                            )
                      }
                      dateFormat="yyyy-MM-dd"
                      onKeyDown={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onChange={(date) => {
                        setCurrentDate(moment(date).format("YYYY-MM-DD"));
                        getHairstylistWorkingTimeIsDateCreated(
                          selectedSalonHairstylist.salonHairstylistId,
                          moment(date).format("YYYY-MM-DDTHH:mm:ss")
                        );
                      }}
                      includeDates={activeDates}
                      highlightDates={activeDates}
                      placeholderText={t(
                        "hairstylist.hairstylistComponent.createHairstylistWorkingTimeModal.datePlaceholder",
                        "Select date"
                      )}
                    />
                  </div>
                  {toGetFreeSlots && (
                    <div className="form-group">
                      <label
                        htmlFor="slotTimeComponentCreateWorkingTime"
                        className="required"
                      >
                        {t(
                          "hairstylist.hairstylistComponent.createHairstylistWorkingTimeModal.timeSlotLabel",
                          "Time slots"
                        )}
                      </label>
                      <ConnectedFreeHairstylistSlotsPopupComponent
                        selectedHairstylist={selectedHairstylist}
                        selectedSalonHairstylist={selectedSalonHairstylist}
                        visitDate={currentDate}
                        setCheckboxInputs={setCheckboxInputs}
                        startTime={startTime}
                        endTime={endTime}
                        editType={false}
                        id="slotTimeComponentCreateWorkingTime"
                      />
                    </div>
                  )}
                </form>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => {
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  disabled={getShouldDisableFinish()}
                  onClick={() => {
                    createHairstylistWorkingTime(
                      selectedSalonHairstylist.salonHairstylistId,
                      currentDate,
                      startTime,
                      endTime,
                      checkboxInputs,
                      () => {
                        clearCheckboxSystem();
                      }
                    );
                  }}
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Edit Hairstylist Working time Modal */}
      {selectedHairstylist.personId && isEditWorkTime && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="editHairstylistWorkingTimeModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="editHairstylistWorkingTimeModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="editHairstylistWorkingTimeModalLabel"
                >
                  <i className="fal fa-clock fa-lg c-purple" />{" "}
                  {helper.i18nMultiFallbackKey(
                    "hairstylist.hairstylistComponent.editHairstylistWorkingTimeModal.title",
                    "Edit Provider Working time"
                  )}{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <input type="hidden" value="something" />
                  <div className="form-group">
                    <label htmlFor="hairstylistFirstNameEditWorkingTime">
                      {helper.i18nMultiFallbackKey(
                        "hairstylist.hairstylistComponent.editHairstylistWorkingTimeModal.hairstylistNameLabel",
                        "Provider name"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="hairstylistNameEditWorkingTime"
                      value={`${selectedHairstylist.firstName} ${selectedHairstylist.lastName}`}
                      onChange={(event) => setFirstName(event.target.value)}
                      disabled
                    />
                  </div>

                  <div className="form-group calendar-controls cc-default">
                    <label
                      className="d-block required"
                      htmlFor="currentDateEditWorkingTime"
                    >
                      {t(
                        "hairstylist.hairstylistComponent.editHairstylistWorkingTimeModal.dateLabel",
                        "Date"
                      )}
                    </label>
                    <DatePicker
                      className="form-control"
                      id="currentDateEditWorkingTime"
                      selected={
                        currentDate
                          ? moment(currentDate, "YYYY-MM-DD")
                              .startOf("day")
                              .toDate()
                          : setCurrentDate(
                              moment(activeDates[0]).format("YYYY-MM-DD")
                            )
                      }
                      dateFormat="yyyy-MM-dd"
                      onKeyDown={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onChange={(date) => {
                        setCurrentDate(moment(date).format("YYYY-MM-DD"));
                      }}
                      includeDates={activeDates}
                      highlightDates={activeDates}
                      placeholderText={t(
                        "hairstylist.hairstylistComponent.editHairstylistWorkingTimeModal.datePlaceholder",
                        "Select date"
                      )}
                      readOnly
                    />
                  </div>
                  {toGetFreeSlots && (
                    <div className="form-group">
                      <label
                        htmlFor="slotTimeComponentEditWorkingTime"
                        className="required"
                      >
                        {t(
                          "hairstylist.hairstylistComponent.editHairstylistWorkingTimeModal.timeSlotLabel",
                          "Time slots"
                        )}
                      </label>
                      <ConnectedFreeHairstylistSlotsPopupComponent
                        selectedHairstylist={selectedHairstylist}
                        selectedSalonHairstylist={selectedSalonHairstylist}
                        visitDate={currentDate}
                        setCheckboxInputs={setCheckboxInputs}
                        startTime={startTime}
                        endTime={endTime}
                        editType={true}
                        id="slotTimeComponentEditWorkingTime"
                      />
                    </div>
                  )}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => {
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  onClick={() => {
                    saveHairstylistWorkingTime(
                      workingTimeId,
                      selectedSalonHairstylist.salonHairstylistId,
                      currentDate,
                      startTime,
                      endTime,
                      checkboxInputs,
                      () => {
                        clearCheckboxSystem();
                      }
                    );
                  }}
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delete Hairstylist  Working time Modal */}
      {selectedHairstylist.personId && isDeleteWorkTime && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="deleteHairstylistWorkingTimeModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="deleteHairstylistWorkingTimeModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="deleteHairstylistWorkingTimeModalLabel"
                >
                  <i className="fal fa-clock fa-lg c-purple" />{" "}
                  {helper.i18nMultiFallbackKey(
                    "hairstylist.hairstylistComponent.deleteHairstylistWorkingTimeModal.title",
                    "Deactivate Provider Working Time"
                  )}{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <h2 className="info-title-upp-case-xl icon-custom-top-center">
                  <span className="icon-custom is-xl icon-triangle-red-white" />
                  {t(
                    "hairstylist.hairstylistComponent.deleteHairstylistWorkingTimeModal.attention",
                    "Attention"
                  )}
                </h2>
                <div className="info-message text-center">
                  <p>
                    {" "}
                    {t(
                      "hairstylist.hairstylistComponent.deleteHairstylistWorkingTimeModal.question",
                      "Are you sure you want to deactivate working time"
                    )}
                  </p>
                  <p className="prime-text">
                    {helper.i18nMultiFallbackKey(
                      "hairstylist.hairstylistComponent.deleteHairstylistWorkingTimeModal.forHairstylist",
                      "for provider"
                    )}
                    {selectedHairstylist.firstName}{" "}
                    {selectedHairstylist.lastName} in salon {selectedSalon.name}{" "}
                    {t(
                      "hairstylist.hairstylistComponent.deleteHairstylistWorkingTimeModal.onDate",
                      "on date "
                    )}
                    {`${moment(currentDate).format("YYYY-DD-MM")}`}?
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  onClick={() =>
                    deleteHairstylistWorkingTimeConsolidated(
                      workingTimeConsolidated,
                      () => {
                        clearCheckboxSystem();
                      }
                    )
                  }
                >
                  <i className="fal fa-check" /> {t("common.yes", "Yes")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => {
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-times" /> {t("common.no", "No")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Create Hairstylist  Service Type Modal*/}
      {/*
          <ConnectedHairstylistServiceTypeCreate
            selectedSalonHairstylist={selectedSalonHairstylist}
            selectedSalon={selectedSalon}
            clearParentState={clearSelectedHairstlistServiceType}
          /> */}
      <>
        {!helper.isEmpty(selectedSalon) && isActivateServiceType && (
          <div
            className="modal fade"
            data-backdrop="static"
            data-keyboard="false"
            id="activateHairstylistServiceTypeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="activateHairstylistServiceTypeModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-md"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id="activateHairstylistServiceTypeModalLabel"
                  >
                    <i className="fal fa-user fa-lg c-purple" />{" "}
                    {helper.i18nMultiFallbackKey(
                      "hairstylist.hairstylistComponent.activateHairstylistServiceTypeModal.title",
                      "Activate Provider Service Type"
                    )}{" "}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      clearSelectedHairstlistServiceType();
                      clearCheckboxSystem();
                    }}
                  >
                    <i className="fal fa-times" />
                  </button>
                </div>

                <div className="modal-body">
                  <form className="form needs-validation" noValidate>
                    <input type="hidden" value="something" />
                    <div className="form-group">
                      <label htmlFor="serviceTypeId" className="required">
                        {helper.i18nMultiFallbackKey(
                          "hairstylist.hairstylistComponent.serviceTypesFromShopLabel",
                          "Service types from shop"
                        )}{" "}
                        {selectedSalon.name}
                      </label>
                      <ConnectedServiceTypesDropDown
                        serviceTypeId={selectedServiceTypeId}
                        setServiceTypeId={setSelectedServiceTypeId}
                        serviceTypes={availableServiceTypes}
                        salonId={selectedSalon.salonId}
                        id="serviceTypeId"
                      />
                      <div className="invalid-feedback">
                        {helper.i18nMultiFallbackKey(
                          "hairstylist.hairstylistComponent.serviceTypesFromShopInvalid",
                          "Please select your service type."
                        )}
                      </div>
                    </div>
                  </form>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-danger ico-l"
                    data-dismiss="modal"
                    onClick={() => {
                      clearSelectedHairstlistServiceType();
                      clearCheckboxSystem();
                    }}
                  >
                    <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success ico-l"
                    data-dismiss="modal"
                    onClick={() => {
                      let hairstylistServiceTypeForCreate = {
                        salonId: selectedSalon.salonId,
                        serviceTypeId: selectedServiceTypeId,
                        hairstylistId: selectedHairstylist.personId,
                      };

                      createHairstylistServiceType(
                        hairstylistServiceTypeForCreate,
                        () => {
                          clearSelectedHairstlistServiceType();
                          clearCheckboxSystem();
                        }
                      );
                    }}
                  >
                    <i className="fal fa-check" />{" "}
                    {t("common.finish", "Finish")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Edit  Hairstylist  Service Type Modal*/}
        {/* <ConnectedHairstylistServiceTypeEdit
          selectedServiceType={selectedHairstylistServiceType}
          selectedSalon={selectedSalon}
          selectedSalonHairstylist={selectedSalonHairstylist}
          clearParentState={clearSelectedHairstlistServiceType}
        /> */}

        {/* Delete Hairstylist  Service Type Modal*/}
        {/* <ConnectedHairstylistServiceTypeDelete
          selectedHairstylistServiceType={selectedHairstylistServiceType}
          selectedSalon={selectedSalon}
          selectedHairstylist={selectedHairstylist}
          selectedSalonHairstylist={selectedSalonHairstylist}
          clearParentState={clearSelectedHairstlistServiceType}
        /> */}

        {!helper.isEmpty(selectedSalon) && isDeactivateServiceType && (
          <div
            className="modal fade"
            data-backdrop="static"
            data-keyboard="false"
            id="deleteHairstylistServiceTypeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="deleteHairstylistServiceTypeModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-md"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id="deleteHairstylistServiceTypeModalLabel"
                  >
                    <i className="fal fa-user fa-lg c-purple" />{" "}
                    {helper.i18nMultiFallbackKey(
                      "hairstylist.hairstylistComponent.deleteHairstylistServiceTypeModal.title",
                      "Deactivate Provider Service Type"
                    )}{" "}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      clearSelectedHairstlistServiceType();
                      clearCheckboxSystem();
                    }}
                  >
                    <i className="fal fa-times" />
                  </button>
                </div>

                <div className="modal-body">
                  <h2 className="info-title-upp-case-xl icon-custom-top-center">
                    <span className="icon-custom is-xl icon-triangle-red-white" />
                    {t(
                      "hairstylist.hairstylistComponent.deleteHairstylistServiceTypeModal.attention",
                      "Attention"
                    )}
                  </h2>
                  {!selectedHairstylistServiceType.hasDependents && (
                    <div className="info-message text-center">
                      <p>
                        {helper.i18nMultiFallbackKey(
                          "hairstylist.hairstylistComponent.deleteHairstylistServiceTypeModal.question",
                          "Are you sure you want to deactivate provider service type"
                        )}
                      </p>
                      <p className="prime-text">
                        {selectedHairstylistServiceType.serviceType
                          ? selectedHairstylistServiceType.serviceType
                              .serviceTypeName
                          : ""}{" "}
                        {helper.i18nMultiFallbackKey(
                          "hairstylist.hairstylistComponent.deleteHairstylistServiceTypeModal.forProvider",
                          "for provider"
                        )}{" "}
                        {selectedHairstylist.firstName}{" "}
                        {selectedHairstylist.lastName}{" "}
                        {helper.i18nMultiFallbackKey(
                          "hairstylist.hairstylistComponent.deleteHairstylistServiceTypeModal.fromShop",
                          "from shop"
                        )}{" "}
                        {selectedSalon.name}?
                      </p>
                    </div>
                  )}
                  {selectedHairstylistServiceType.hasDependents && (
                    <div className="info-message text-center">
                      <p>
                        {" "}
                        {t(
                          "hairstylist.hairstylistComponent.deleteHairstylistServiceTypeModal.editVisitLabel",
                          "Please edit visits related to"
                        )}
                      </p>
                      <p className="prime-text">
                        {selectedHairstylistServiceType.serviceType
                          ? selectedHairstylistServiceType.serviceType
                              .serviceTypeName
                          : ""}{" "}
                        {helper.i18nMultiFallbackKey(
                          "hairstylist.hairstylistComponent.deleteHairstylistServiceTypeModal.editVisitAndProviderLabel",
                          "and provider"
                        )}
                        {selectedHairstylist.firstName}{" "}
                        {selectedHairstylist.lastName}{" "}
                        {helper.i18nMultiFallbackKey(
                          "hairstylist.hairstylistComponent.deleteHairstylistServiceTypeModal.editVisitAndShopLabel",
                          "from shop"
                        )}{" "}
                        {selectedSalon.name}
                      </p>
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  {!selectedHairstylistServiceType.hasDependents && (
                    <>
                      <button
                        type="button"
                        className="btn btn-outline-success ico-l"
                        data-dismiss="modal"
                        onClick={() => {
                          deleteHairstylistServiceType(
                            selectedHairstylistServiceType,
                            () => {
                              clearSelectedHairstlistServiceType();
                              clearCheckboxSystem();
                            }
                          );
                        }}
                      >
                        <i className="fal fa-check" /> {t("common.yes", "Yes")}
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-danger ico-l"
                        data-dismiss="modal"
                        onClick={() => {
                          clearSelectedHairstlistServiceType();
                          clearCheckboxSystem();
                        }}
                      >
                        <i className="fal fa-times" /> {t("common.no", "No")}
                      </button>
                    </>
                  )}
                  {selectedHairstylistServiceType.hasDependents && (
                    <button
                      type="button"
                      className="btn btn-outline-success ico-l"
                      data-dismiss="modal"
                      onClick={() => {
                        clearSelectedHairstlistServiceType();
                        clearCheckboxSystem();
                      }}
                    >
                      <i className="fal fa-check" /> {t("common.ok", "OK")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Assign Hairstylist To Salon Modal*/}

        {selectedHairstylist && isAssignToSalon && (
          <div
            className="modal fade"
            data-backdrop="static"
            data-keyboard="false"
            id="assignHairstylistToSalonModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="assignHairstylistToSalonModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-md"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id="assignHairstylistToSalonModalLabel"
                  >
                    <i className="fal fa-user fa-lg c-purple" />{" "}
                    {helper.i18nMultiFallbackKey(
                      "hairstylist.hairstylistComponent.assignHairstylistToSalonModal.title",
                      "Assign Provider To Shop"
                    )}{" "}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="fal fa-times" />
                  </button>
                </div>

                <div className="modal-body">
                  <form className="form needs-validation" noValidate>
                    <input type="hidden" value="something" />
                    <div className="form-group">
                      <label htmlFor="salonId" className="required">
                        {helper.i18nMultiFallbackKey(
                          "hairstylist.hairstylistComponent.assignHairstylistToSalonModal.shopLabel",
                          "Shop"
                        )}
                      </label>
                      <ConnectedSalonsDropDown
                        salonId={salonId}
                        setSalonId={setSalonId}
                        setServiceTypes={setServiceTypes}
                        setSalonTimeSlot={setSalonTimeSlot}
                        id="salonId"
                      />
                      <div className="invalid-feedback">
                        {helper.i18nMultiFallbackKey(
                          "hairstylist.hairstylistComponent.assignHairstylistToSalonModal.shopInvalid",
                          "Please select your shop."
                        )}
                      </div>
                    </div>
                    <RequiredFieldsComponent />
                  </form>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-danger ico-l"
                    data-dismiss="modal"
                  >
                    <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success ico-l"
                    data-dismiss="modal"
                    onClick={() => {
                      let salonHairstylist = {
                        salonId: salonId,
                        hairstylistId: selectedHairstylist.personId,
                      };
                      createSalonHairstylist(salonHairstylist, () => {});
                    }}
                  >
                    <i className="fal fa-check" />{" "}
                    {t("common.finish", "Finish")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Unassign Hairstylist From Salon Modal*/}
        {!helper.isEmpty(selectedSalon) && isUnassignFromSalon && (
          <div
            className="modal fade"
            data-backdrop="static"
            data-keyboard="false"
            id="unassignHairstylistFromSalonModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="unassignHairstylistFromSalonModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-md"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id="unassignHairstylistFromSalonModalLabel"
                  >
                    <i className="fal fa-user fa-lg c-purple" />{" "}
                    {helper.i18nMultiFallbackKey(
                      "hairstylist.hairstylistComponent.unassignHairstylistFromSalonModal.title",
                      "Unassign Provider from Shop"
                    )}{" "}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => clearState()}
                  >
                    <i className="fal fa-times" />
                  </button>
                </div>

                <div className="modal-body">
                  <h2 className="info-title-upp-case-xl icon-custom-top-center">
                    <span className="icon-custom is-xl icon-triangle-red-white" />
                    {t(
                      "hairstylist.hairstylistComponent.unassignHairstylistFromSalonModal.attention",
                      "Attention"
                    )}
                  </h2>
                  {!selectedSalonHairstylist.hasDependents && (
                    <div className="info-message text-center">
                      <p>
                        {t(
                          "hairstylist.hairstylistComponent.unassignHairstylistFromSalonModal.question",
                          "Are you sure you want to unassign"
                        )}
                      </p>
                      <p className="prime-text">
                        {" "}
                        {helper.i18nMultiFallbackKey(
                          "hairstylist.hairstylistComponent.unassignHairstylistFromSalonModal.provider",
                          "provider"
                        )}
                        {selectedHairstylist.firstName}{" "}
                        {selectedHairstylist.lastName}{" "}
                        {helper.i18nMultiFallbackKey(
                          "hairstylist.hairstylistComponent.unassignHairstylistFromSalonModal.fromShop",
                          "from shop"
                        )}{" "}
                        {selectedSalon.name}?
                      </p>
                    </div>
                  )}
                  {selectedSalonHairstylist.hasDependents && (
                    <>
                      <div className="info-message text-center">
                        <p>
                          {helper.i18nMultiFallbackKey(
                            "hairstylist.hairstylistComponent.unassignHairstylistFromSalonModal.hairstylistHasDependenciesLabel",
                            "Provider"
                          )}
                          {selectedHairstylist.firstName}{" "}
                          {selectedHairstylist.lastName}
                        </p>
                        <p className="prime-text">
                          {helper.i18nMultiFallbackKey(
                            "hairstylist.hairstylistComponent.unassignHairstylistFromSalonModal.hairstylistHasDependenciesWarning",
                            "has scheduled visits in the future. If you want to set them to another provider, please set"
                          )}
                        </p>
                      </div>
                      <ConnectedHairstylistsDropDown
                        hairstylistId={hairstylistId}
                        setHairstylistId={setHairstylistId}
                        salonId={selectedSalon.salonId}
                        id="hairstylistId"
                      />
                    </>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-success ico-l"
                    data-dismiss="modal"
                    onClick={() =>
                      deleteSalonHairstylist(
                        selectedSalonHairstylist,
                        hairstylistId,
                        () => {}
                      )
                    }
                    // disabled={!hairstylistId}
                  >
                    <i className="fal fa-check" /> {t("common.yes", "Yes")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger ico-l"
                    data-dismiss="modal"
                    onClick={() => clearState()}
                  >
                    <i className="fal fa-times" /> {t("common.no", "No")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    salons: state.salons,
    activeUser: state.activeUser,
    isDateCreated: state.hairstylistWorkingTimesIsDateCreated,
    hairstylistServiceTypes: state.hairstylistServiceTypes,
    freeHairstylistTimeSlots: state.freeHairstylistTimeSlots,
    availableServiceTypes: state.availableServiceTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActiveUser() {
      if (sessionStorage.getItem("user_id")) {
        dispatch(
          mutations.getActiveUser(JSON.parse(sessionStorage.getItem("user_id")))
        );
      }
    },
    deleteHairstylist(personId, cb) {
      let hairstylist = {
        PersonId: personId,
      };
      cb();
      dispatch(mutations.setLoaded(false));
      dispatch(mutations.deleteHairstylist(hairstylist));
    },
    deleteHairstylistForSalon(personId, salonId, cb) {
      let hairstylist = {
        PersonId: personId,
      };
      dispatch(mutations.setLoaded(false));
      dispatch(mutations.deleteHairstylistForSalon(hairstylist, salonId, cb));
    },
    createHairstylistWorkingTime(
      salonHairstylistId,
      currentDate,
      startTime,
      endTime,
      checkboxInputs,
      cb
    ) {
      let startTimeString = `${("0" + startTime.getHours()).slice(-2)}:${(
        "0" + startTime.getMinutes()
      ).slice(-2)}`;
      let endTimeString = `${("0" + endTime.getHours()).slice(-2)}:${(
        "0" + endTime.getMinutes()
      ).slice(-2)}`;
      let startDate = moment(currentDate + " " + startTimeString).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      let endDate = moment(currentDate + " " + endTimeString).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      cb();
      dispatch(
        mutations.createHairstylistWorkingTime(
          salonHairstylistId,
          startDate,
          endDate,
          checkboxInputs
        )
      );
    },
    saveHairstylistWorkingTime(
      workingTimeId,
      salonHairstylistId,
      currentDate,
      startTime,
      endTime,
      checkboxInputs,
      cb
    ) {
      let startTimeString = `${("0" + startTime.getHours()).slice(-2)}:${(
        "0" + startTime.getMinutes()
      ).slice(-2)}`;
      let endTimeString = `${("0" + endTime.getHours()).slice(-2)}:${(
        "0" + endTime.getMinutes()
      ).slice(-2)}`;
      let startDate = moment(currentDate + " " + startTimeString).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      let endDate = moment(currentDate + " " + endTimeString).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      cb();
      dispatch(
        mutations.saveHairstylistWorkingTime(
          workingTimeId,
          salonHairstylistId,
          startDate,
          endDate,
          checkboxInputs
        )
      );
    },
    deleteHairstylistWorkingTime(workingTimeId, salonHairstylistId, cb) {
      cb();
      dispatch(
        mutations.deleteHairstylistWorkingTime(
          workingTimeId,
          salonHairstylistId
        )
      );
    },
    deleteHairstylistWorkingTimeConsolidated(workingTimeConsolidated, cb) {
      cb();
      dispatch(
        mutations.deleteHairstylistWorkingTimeConsolidated(
          workingTimeConsolidated
        )
      );
    },
    getHairstylistWorkingTimeIsDateCreated(salonHairstylistId, startDate) {
      dispatch(
        mutations.getHairstylistWorkingTimeIsDateCreated(
          salonHairstylistId,
          startDate
        )
      );
    },
    getAllHairstylistWorkingTimesDates(salonHairstylistId, page, sort) {
      dispatch(
        mutations.getAllHairstylistWorkingTimesDates(
          salonHairstylistId,
          page,
          sort
        )
      );
    },
    getSalonWorkingTimes(salonId) {
      // dispatch(mutations.getSalonWorkingTimePaged(salonId, null, []));
      dispatch(
        mutations.getSalonWorkingTimePagedConsolidated(salonId, null, [])
      );
    },
    createHairstylistServiceType(hairstylistServiceType, cb) {
      dispatch(
        mutations.createHairstylistServiceType(hairstylistServiceType, cb)
      );
    },
    deleteHairstylistServiceType(hairstylistServiceTypeForDelete, cb) {
      cb();
      dispatch(
        mutations.deleteHairstylistServiceType(hairstylistServiceTypeForDelete)
      );
    },
    createSalonHairstylist(salonHairstylist, cb) {
      cb();
      dispatch(mutations.createSalonHairstylist(salonHairstylist));
    },
    deleteSalonHairstylist(salonHairstylist, hairstylistId, cb) {
      cb();
      // dispatch(mutations.deleteSalonHairstylist(salonHairstylist));
      dispatch(
        mutations.deleteSalonHairstylistReplaceHairstylist(
          salonHairstylist,
          hairstylistId
        )
      );
    },
    clearSalonHairstylists() {
      dispatch(
        mutations.setSalonHairstylists({
          resultList: [],
          pageNumber: 1,
          totalCount: 0,
        })
      );
    },
    clearHairstylistWorkingTimes() {
      dispatch(
        mutations.setHairstylistWorkingTimesConsolidated({
          resultList: [],
          pageNumber: 1,
          totalCount: 0,
        })
      );
      dispatch(
        mutations.setAllHairstylistWorkingTimesDates({
          resultList: [],
          pageNumber: 1,
          totalCount: 0,
        })
      );
    },
    clearHairstylistServiceTypes() {
      dispatch(
        mutations.setHairstylistServiceTypes({
          resultList: [],
          pageNumber: 1,
          totalCount: 0,
        })
      );
    },
  };
};

const ConnectedHairstylist = connect(
  mapStateToProps,
  mapDispatchToProps
)(HairstylistComponent);

export default ConnectedHairstylist;
