import React, { useState, Component, useEffect } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { TimeColumn } from "../TimeColumn";
import { DateColumn } from "../DateColumn";
import { RangeColumn } from "../RangeColumn";
import * as helper from "../HelperRepository";

import * as moment from "moment";
import { useTranslation, withTranslation } from "react-i18next";

const SalonWorkingTimeConsolidatedListComponent = ({
  selectedSalon,
  getSalonWorkingTimePagedConsolidated,
  //workingTimes,
  salonWorkingTimesConsolidated,
  salonWorkingTimeDateList,
  //workingTimeTotalCount,
  salonWorkingTimeTotalCountConsolidated,
  clearParentState,
  setParentState,
  setToGetFreeSlots,
  getSalonWorkingTimeIsDateCreated,
  getSalonWorkingTimeDateList,
  setStartTime,
  setEndTime,
  setIsCreateWorkingtimeNew,
  setIsDeleteRange,
}) => {
  const { t, i18n } = useTranslation();

  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 10 });

  useEffect(() => {
    if (selectedSalon.salonId) {
      getSalonWorkingTimePagedConsolidated(
        selectedSalon.salonId,
        page.skip / 10 + 1,
        sort
      );
      // getSalonWorkingTimeDateList(selectedSalon.salonId);
    }
  }, [getSalonWorkingTimePagedConsolidated, page, selectedSalon, sort]);

  const ActionButtonsColumnWithTranslation = withTranslation()(
    ActionButtonsColumn
  );
  const grid = (
    <Grid
      data={salonWorkingTimesConsolidated}
      pageable={{
        buttonCount: 3,
        info: true,
        type: "numeric",
        previousNext: true,
      }}
      skip={page.skip}
      take={page.take}
      onPageChange={(event) => {
        setPage({ skip: event.page.skip, take: event.page.take });
        getSalonWorkingTimePagedConsolidated(
          selectedSalon.salonId,
          event.page.skip / 10 + 1,
          sort
        );
        // getSalonWorkingTimeDateList(selectedSalon.salonId);
      }}
      total={salonWorkingTimeTotalCountConsolidated}
      sortable
      sort={sort}
      onSortChange={(e) => {
        setSort(e.sort);
        getSalonWorkingTimePagedConsolidated(
          selectedSalon.salonId,
          page.skip / 10 + 1,
          e.sort
        );
        // getSalonWorkingTimeDateList(selectedSalon.salonId);
      }}
      style={{ height: "410px" }}
      className="table-header-purple"
    >
      <GridColumn
        field="workTimeDate"
        title={t(
          "salon.SalonWorkingTimeConsolidatedListComponent.grid.date.title",
          "Date"
        )}
        className="start-time-cell"
        headerClassName="start-time-cell"
        cell={(props) => (
          <DateColumn
            time={props.dataItem.workingTimeDate}
            mobileTitle={t(
              "salon.SalonWorkingTimeConsolidatedListComponent.grid.date.mobileTitle",
              "Date:"
            )}
          />
        )}
      />
      <GridColumn
        field="workTimeRange"
        title={t(
          "salon.SalonWorkingTimeConsolidatedListComponent.grid.range.title",
          "Range"
        )}
        className="end-time-cell"
        headerClassName="end-time-cell"
        cell={(props) => (
          <RangeColumn
            range={props.dataItem.workingTimeRangeList}
            mobileTitle={t(
              "salon.SalonWorkingTimeConsolidatedListComponent.grid.range.mobileTitle",
              "Range:"
            )}
          />
        )}
      />
      <GridColumn
        field="Actions"
        title={t("common.actions", "Actions")}
        cell={(props) => (
          <ActionButtonsColumnWithTranslation
            setParentState={setParentState}
            setToGetFreeSlots={setToGetFreeSlots}
            {...props}
          />
        )}
        headerClassName="actions-cell two-bttns"
      />
    </Grid>
  );

  return (
    <>
      <div className="table-rounded-border table-headlabel-responsive-md hairstylist-table">
        <header>
          <div className="button-container">
            <button
              type="button"
              className="btn btn-purple-outline btn-sm ico-l"
              data-toggle="modal"
              data-target={`#createSalonWorkingTimeModal`}
              onClick={() => {
                // let dateList = [];

                // dateList = helper.orderBy(
                //   salonWorkingTimeDateList.map(x => moment(x)),
                //   [
                //     function(x) {
                //       return x;
                //     }
                //   ],
                //   ["desc"]
                // );

                // console.log(
                //   "------------------------- createSalonWorkingTime salonWorkingTimesConsolidated",
                //   moment(dateList[0]).format("YYYY-MM-DD"),
                //   moment(dateList[0])
                //     .add(1, "day")
                //     .format("YYYY-MM-DD")
                // );

                let newDate;
                let nowMoment = moment();
                let serverMoment = moment(salonWorkingTimeDateList).add(
                  1,
                  "day"
                );

                if (nowMoment > serverMoment) {
                  newDate = nowMoment.format("YYYY-MM-DD");
                } else {
                  newDate = serverMoment.format("YYYY-MM-DD");
                }

                let newStartTime = moment(newDate, "YYYY-MM-DD")
                  .hours(9)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0)
                  .toDate();
                let newEndTime = moment(newDate, "YYYY-MM-DD")
                  .hours(21)
                  .minutes(0)
                  .seconds(0)
                  .milliseconds(0)
                  .toDate();

                setStartTime(newStartTime);
                setEndTime(newEndTime);

                setToGetFreeSlots(true);
                // getSalonWorkingTimeIsDateCreated(
                //   selectedSalon.salonId,
                //   newDate
                // );
                getSalonWorkingTimeDateList(selectedSalon.salonId);
              }}
            >
              <i className="fal fa-plus"></i>{" "}
              {t(
                "salon.SalonWorkingTimeConsolidatedListComponent.newLabel",
                "New"
              )}
              <span className="mobile-hide-550">
                {" "}
                {t(
                  "salon.SalonWorkingTimeConsolidatedListComponent.workingTimeLabel",
                  "Working time"
                )}
              </span>
            </button>
            {/* </div>
          <div className="button-container"> */}
            <button
              type="button"
              className="btn btn-purple-outline btn-sm ico-l"
              // data-toggle="modal"
              // data-target={`#createSalonWorkingTimeAutoModal`}
              onClick={() => {
                // clearParentState();
                setIsCreateWorkingtimeNew(true);
                helper.showModal(`#createSalonWorkingTimeAutoModal`);
              }}
            >
              <i className="fal fa-plus"></i>{" "}
              {t(
                "salon.SalonWorkingTimeConsolidatedListComponent.createWorkingTimeNewLabel",
                "Create Working Time NEW"
              )}{" "}
            </button>
            <button
              type="button"
              className="btn btn-purple-outline btn-sm ico-l"
              // data-toggle="modal"
              // data-target={`#createSalonWorkingTimeAutoModal`}
              onClick={() => {
                // clearParentState();
                setIsDeleteRange(true);
                helper.showModal(`#deleteRangeModal`);
              }}
            >
              <i className="fal fa-plus"></i>{" "}
              {t(
                "salon.SalonWorkingTimeConsolidatedListComponent.deleteRangeLabel",
                "Delete range"
              )}
            </button>
          </div>
        </header>
        {grid}
      </div>
    </>
  );
};
class ActionButtonsColumn extends Component {
  render() {
    return (
      <td className="actions-cell two-bttns">
        <div className="button-container">
          <button
            type="button"
            className="btn btn-outline-success btn-sm ico-l"
            data-toggle="modal"
            data-target="#editSalonWorkingTimeModal"
            onClick={() => {
              this.props.setToGetFreeSlots(true);
              this.props.setParentState(this.props.dataItem);
            }}
            disabled={this.props.dataItem.hasDependents}
          >
            <i className="fal fa-edit"></i>{" "}
            {this.props.t("common.edit", "Edit")}
          </button>
          <button
            type="button"
            className="btn btn-outline-danger btn-sm ico-l"
            data-toggle="modal"
            data-target="#deleteSalonWorkingTimeModal"
            onClick={() => {
              this.props.setParentState(this.props.dataItem);
            }}
            disabled={this.props.dataItem.hasDependents}
          >
            <i className="fal fa-ban"></i>{" "}
            {this.props.t("common.deactivate", "Deactivate")}
          </button>
        </div>
      </td>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    workingTimes: state.salonWorkingTimes,
    salonWorkingTimesConsolidated: state.salonWorkingTimesConsolidated,
    workingTimeTotalCount: state.salonWorkingTimeTotalCount,
    salonWorkingTimeDateList: state.salonWorkingTimeDateList,
    salonWorkingTimeTotalCountConsolidated:
      state.salonWorkingTimeTotalCountConsolidated,
    selectedSalon: ownProps.selectedSalon,
    setParentState: ownProps.setParentState,
    clearParentState: ownProps.clearParentState,
    setToGetFreeSlots: ownProps.setToGetFreeSlots,
    getSalonWorkingTimeIsDateCreated: ownProps.getSalonWorkingTimeIsDateCreated,
    setStartTime: ownProps.setStartTime,
    setEndTime: ownProps.setEndTime,
    setIsCreateWorkingtimeNew: ownProps.setIsCreateWorkingtimeNew,
    setIsDeleteRange: ownProps.setIsDeleteRange,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSalonWorkingTimePagedConsolidated(salonId, page, sort) {
    dispatch(
      mutations.getSalonWorkingTimePagedConsolidated(salonId, page, sort)
    );
  },
  getSalonWorkingTimeDateList(salonId) {
    dispatch(mutations.getSalonWorkingTimeDateList(salonId));
  },
});

export const ConnectedSalonWorkingTimeConsolidatedList = connect(
  mapStateToProps,
  mapDispatchToProps
)(SalonWorkingTimeConsolidatedListComponent);
