import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ConnectedRoleList } from "./RoleList";
import * as mutations from "../../store/mutations";
import { ConnectedCategoryItemsDropDown } from "../Dropdowns/CategoryItemsDropDown";
import { FormErrors } from "../FormErrors";
import * as moment from "moment";
import { useTranslation } from "react-i18next";
import RequiredFieldsComponent from "../RequiredFieldsComponent";

const RoleComponent = ({
  activeUser,
  getActiveUser,
  createRole,
  saveRole,
  deleteRole,
}) => {
  const { t, i18n } = useTranslation();

  document.getElementsByTagName("html")[0].classList.remove("page-signin");

  const [rolename, setRolename] = useState("");
  const [description, setDescription] = useState("");
  const [rankId, setRankId] = useState("");
  const [roleId, setRoleId] = useState("");
  const [hasDependents, setHasDependents] = useState("");

  const [formErrors, setFormErrors] = useState("");
  const [nameValid, setNameValid] = useState("");
  const [rankIdValid, setRankValid] = useState("");
  const [formValid, setFormValid] = useState("");

  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add("grid-view");

    if (!activeUser || !activeUser.personId) {
      getActiveUser();
    }
    return () => {
      document.getElementsByTagName("html")[0].classList.remove("grid-view");
    };
  }, []);

  const clearState = () => {
    setRolename("");
    setDescription("");
    setRankId("");
    setRoleId("");
    setRankValid(false);
    setNameValid(false);
    setFormValid(false);
    setFormErrors({
      roleName: "",
      roleNameEdit: "",
      rankId: "",
      rankIdEdit: "",
    });
  };

  const setRoleState = (role) => {
    setRolename(role.name);
    setDescription(role.description);
    setRankId(role.rankId);
    setRoleId(role.roleId);
    setHasDependents(role.hasDependents);
    setRankValid(true);
    setNameValid(true);
    setFormValid(true);
    setFormErrors({
      roleName: "",
      roleNameEdit: "",
      rankId: "",
      rankIdEdit: "",
    });
  };

  const handleUserInput = (e) => {
    switch (e.target.id) {
      case "roleName":
      case "roleNameEdit":
        setRolename(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "rankId":
      case "rankIdEdit":
        setRankId(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      default:
        break;
    }
  };

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let fieldNameValid = nameValid;
    let fieldRankIdValid = rankIdValid;

    switch (fieldName) {
      case "roleName":
        fieldNameValid = value.match(/^(?!\s*$).+/i) && value.length > 5;
        fieldValidationErrors.roleName = fieldNameValid
          ? ""
          : t(
              "common.roleNameIsInvalid",
              " is invalid. It should begin with letter and have at least 6 characters"
            );
        break;
      case "roleNameEdit":
        fieldNameValid = value.match(/^(?!\s*$).+/i) && value.length > 5;
        fieldValidationErrors.roleNameEdit = fieldNameValid
          ? ""
          : t(
              "common.roleNameIsInvalid",
              " is invalid. It should be at least 6 characters long, and without whitespace  characters (blank, new line, tab)"
            );
        break;
      case "rankId":
        fieldRankIdValid = value.match(/^(?!\s*$).+/i);
        fieldValidationErrors.rankId = fieldRankIdValid
          ? ""
          : "is invalid. Select Rank !!";
        break;
      case "rankIdEdit":
        fieldRankIdValid = value.match(/^(?!\s*$).+/i);
        fieldValidationErrors.rankIdEdit = fieldRankIdValid
          ? ""
          : "is invalid. Select Rank !!";
        break;

      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    setNameValid(fieldNameValid);
    setRankValid(fieldRankIdValid);
    setFormValid(fieldNameValid && rankId !== "");
  };

  const errorClassInput = (error) => {
    if (error) {
      return error.length === 0 ? "" : "is-invalid";
    }
    return "";
  };

  const errorClassLabel = (error) => {
    if (error) {
      return error.length === 0 ? "" : "text-danger";
    }
    return "";
  };

  useEffect(() => {
    setFormValid(nameValid !== null && rankId !== "");
  }, [rankId]);

  return (
    <>
      <main role="main">
        <div className="container-fluid">
          <ConnectedRoleList
            setParentState={setRoleState}
            clearParentState={clearState}
          />
        </div>
      </main>

      <footer className="footer">
        <div className="container copyright">
          <span className="text-muted">
            Hairstylist &copy; Copyright {moment().format("YYYY")},{" "}
            {t("common.version", "Version:")}
            {process.env.VERSION}
          </span>
        </div>
      </footer>

      {/* Create Role Modal */}
      <div
        className="modal fade"
        data-backdrop="static"
        data-keyboard="false"
        id="createRoleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="createRoleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="createRoleModalLabel">
                <i className="fal fa-user-cog fa-lg c-purple" />{" "}
                {t("role.roleComponent.createRoleModal.title", "Create Role")}{" "}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fal fa-times" />
              </button>
            </div>

            <div className="modal-body">
              <form className="form needs-validation" noValidate>
                <input type="hidden" value="something" />
                <div className="form-group">
                  <label
                    htmlFor="roleName"
                    className={`${errorClassLabel(
                      formErrors.roleName
                    )} required`}
                  >
                    {t("role.roleComponent.createRoleModal.nameLabel", "Name")}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${errorClassInput(
                      formErrors.roleName
                    )}`}
                    id="roleName"
                    autoComplete="new-username"
                    placeholder={t(
                      "role.roleComponent.createRoleModal.namePlaceholder",
                      "Please enter role name"
                    )}
                    value={rolename}
                    onChange={handleUserInput}
                    required
                  />
                  <div className="panel panel-default">
                    <FormErrors
                      formErrors={formErrors}
                      fieldToShow={"roleName"}
                      valueToShow={"Role name"}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="description">
                    {t(
                      "role.roleComponent.createRoleModal.descriptionLabel",
                      "Description"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    placeholder={t(
                      "role.roleComponent.createRoleModal.descriptionPlaceholder",
                      "Description"
                    )}
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="rankId"
                    className={`${errorClassLabel(formErrors.rankId)} required`}
                  >
                    {t("role.roleComponent.createRoleModal.rankLabel", "Rank")}
                  </label>
                  <ConnectedCategoryItemsDropDown
                    categoryType="Role rank"
                    categoryItemId={rankId}
                    setCategoryItemId={setRankId}
                    id="rankId"
                  />
                  <div className="panel panel-default">
                    <FormErrors
                      formErrors={formErrors}
                      fieldToShow={"rankId"}
                      valueToShow={"Rank ID"}
                    />
                  </div>
                </div>
                <RequiredFieldsComponent />
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger ico-l"
                data-dismiss="modal"
              >
                <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
              </button>
              <button
                type="button"
                className="btn btn-outline-success ico-l"
                data-dismiss="modal"
                disabled={!formValid}
                onClick={() =>
                  createRole(rolename, description, rankId, () => clearState())
                }
              >
                <i className="fal fa-check" /> {t("common.finish", "Finish")}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Role Modal */}
      <div
        className="modal fade"
        data-backdrop="static"
        data-keyboard="false"
        id="editRoleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editRoleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editRoleModalLabel">
                <i className="fal fa-user-cog fa-lg c-purple" />{" "}
                {t("role.roleComponent.editRoleModal.title", "Edit Role")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fal fa-times" />
              </button>
            </div>

            <div className="modal-body">
              <form className="form needs-validation" noValidate>
                <div className="form-group">
                  <label
                    htmlFor="roleNameEdit"
                    className={`${errorClassLabel(
                      formErrors.roleName
                    )} required`}
                  >
                    {t("role.roleComponent.editRoleModal.nameLabel", "Name")}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${errorClassInput(
                      formErrors.roleName
                    )}`}
                    id="roleNameEdit"
                    placeholder={t(
                      "role.roleComponent.editRoleModal.namePlaceholder",
                      "Role name"
                    )}
                    value={rolename}
                    onChange={handleUserInput}
                    required
                  />
                  <div className="panel panel-default">
                    <FormErrors
                      formErrors={formErrors}
                      fieldToShow={"roleName"}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="descriptionEdit">
                    {t(
                      "role.roleComponent.editRoleModal.descriptionLabel",
                      "Description"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="descriptionEdit"
                    placeholder={t(
                      "role.roleComponent.editRoleModal.descriptionPlaceholder",
                      "Description"
                    )}
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="rankIdEdit"
                    className={`${errorClassLabel(formErrors.rankId)} required`}
                  >
                    {t("role.roleComponent.editRoleModal.rankLabel", "Rank")}
                  </label>
                  <ConnectedCategoryItemsDropDown
                    categoryType="Role rank"
                    categoryItemId={rankId}
                    setCategoryItemId={setRankId}
                    id="rankIdEdit"
                  />
                  <div className="invalid-feedback">
                    {t(
                      "role.roleComponent.editRoleModal.rankInvalid",
                      "Please select your role."
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary ico-l"
                data-dismiss="modal"
              >
                <i className="fal fa-sync" /> {t("common.cancel", "Cancel")}
              </button>
              <button
                type="button"
                className="btn btn-outline-success ico-l"
                data-dismiss="modal"
                disabled={!formValid}
                onClick={() =>
                  saveRole(roleId, rolename, description, rankId, () =>
                    clearState()
                  )
                }
              >
                <i className="fal fa-check" /> {t("common.finish", "Finish")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Delete Role Modal */}
      <div
        className="modal fade"
        data-backdrop="static"
        data-keyboard="false"
        id="deleteRoleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteRoleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteRoleModalLabel">
                <i className="fal fa-user-cog fa-lg c-purple" />{" "}
                {t(
                  "role.roleComponent.deleteRoleModal.title",
                  "Deactivate Role"
                )}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fal fa-times" />
              </button>
            </div>

            <div className="modal-body">
              <h2 className="info-title-upp-case-xl icon-custom-top-center">
                <span className="icon-custom is-xl icon-triangle-red-white" />
                {t("role.roleComponent.deleteRoleModa.attention", "Attention")}
              </h2>
              {!hasDependents && (
                <div className="info-message text-center">
                  <p>
                    {" "}
                    {t(
                      "role.roleComponent.deleteRoleModa.question",
                      "Are you sure you want to deactivate role"
                    )}
                  </p>
                  <p className="prime-text">{rolename}?</p>
                </div>
              )}
              {hasDependents && (
                <div className="info-message text-center">
                  <p>
                    {t(
                      "role.roleComponent.deleteRoleModa.dependencies",
                      "Some users have this role, it is not possible to deactivate."
                    )}
                  </p>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-success ico-l"
                data-dismiss="modal"
                onClick={() => deleteRole(roleId, () => clearState())}
                disabled={hasDependents}
              >
                <i className="fal fa-check" /> {t("common.yes", "Yes")}
              </button>
              <button
                type="button"
                className="btn btn-outline-danger ico-l"
                data-dismiss="modal"
              >
                <i className="fal fa-times" /> {t("common.no", "No")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    activeUser: state.activeUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActiveUser() {
      if (sessionStorage.getItem("user_id")) {
        dispatch(
          mutations.getActiveUser(JSON.parse(sessionStorage.getItem("user_id")))
        );
      }
    },
    createRole(rolename, description, rankId, cb) {
      let role = {
        Name: rolename,
        Description: description,
        RankId: rankId,
      };
      cb();
      dispatch(mutations.createRole(role));
    },
    saveRole(roleId, rolename, description, rankId, cb) {
      let role = {
        RoleId: roleId,
        Name: rolename,
        Description: description,
        RankId: rankId,
      };
      cb();
      dispatch(mutations.saveRole(role));
    },
    deleteRole(roleId, cb) {
      let role = {
        RoleId: roleId,
      };
      cb();
      dispatch(mutations.deleteRole(role));
    },
  };
};

const ConnectedRole = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleComponent);

export default ConnectedRole;
