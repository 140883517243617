import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import * as moment from "moment";
import { ConnectedHairstylistsDropDown } from "../Dropdowns/HairstylistsDropDown";
import { ConnectedCategoryItemsDropDown } from "../Dropdowns/CategoryItemsDropDown";
import { ConnectedCalendarDailyDayColumn } from "./CalendarDailyDayColumn";
import DatePicker from "react-datepicker";

import { ConnectedSalonCustomersDropDown } from "../Dropdowns/SalonCustomersDropDown";
// import { ConnectedHairstylistServiceTypesCheckboxLilstComponent } from "../CheckboxList/HairstylistServiceTypesCheckboxList";
import { ConnectedSalonsDropDown } from "../Dropdowns/SalonsDropDown";

import { ConnectedHairstylistServiceTypesCheckboxLilstComponentV2 } from "../CheckboxList/HairstylistServiceTypesCheckboxListV2";
import { ConnectedFreeVisitSlotsPopupComponentV2 } from "../Visit/FreeVisitSlotPopupComponentV2";
import { AutoComplete } from "primereact/autocomplete";
import "react-datepicker/dist/react-datepicker.css";

import * as helper from "../HelperRepository";
import { useTranslation } from "react-i18next";

const CalendarDailyComponent = ({
  salonId,
  salons,
  hairstylistId,
  currentDate,
  createVisitFromServiceTypeList,
  saveVisit,
  getDashboardData,
  getDashboardDataDaily,
  dashboardDataDaily,
  setCurrentDate,
  setCurrentWeekStart,
  setCurrentMonthStart,
  customers,
  getCustomers,
  hairstylistWorkingTimes,
  saveVisitFromServiceTypeList,
  createCustomer,
  getCustomersPaged,
  // getCustomersPagedForSalon,
  activeUser,
  hairstylistServiceTypes,
}) => {
  const { t, i18n } = useTranslation();

  const [customerId, setCustomerId] = useState("");

  const [statusId, setStatusId] = useState("");
  const [serviceTypeId, setServiceTypeId] = useState("");
  const [visitDate, setVisitDate] = useState(moment().format("YYYY-MM-DD"));
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [description, setDescription] = useState("");
  const [visitId, setVisitId] = useState("");
  const [salonTimeSlot, setSalonTimeSlot] = useState("");
  const [serviceTypes, setServiceTypes] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [registrationsNumber, setRegistrationsNumber] = useState("");
  const [genderId, setGenderId] = useState("");

  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isCustomerCreate, setIsCustomerCreate] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const [salonCustomerId, setSalonCustomerId] = useState("");

  const [selectedSalon, setSelectedSalon] = useState("");
  const [selectedServiceTypeList, setSelectedServiceTypeList] = useState([]);
  const [autoCompleteSearchValue, setAutoCompleteSearchValue] = useState("");

  const [editSalonId, setEditSalonId] = useState("");
  const [editHairstylistId, setEditHairstylistId] = useState("");

  const [createSalonId, setCreateSalonId] = useState("");
  const [createHairstylistId, setCreateHairstylistId] = useState("");

  const [rolesMap, setRolesMap] = useState(() => {
    return helper.createMapObject();
  });
  useEffect(() => {
    setRolesMap(helper.mapForRole(activeUser));
  }, [activeUser]);

  const clearState = () => {
    setDescription("");
    setCustomerId("");
    setStartTime("");
    setEndTime("");
    setStatusId("");
    setVisitId("");
    setServiceTypeId("");
    setSelectedServiceTypeList([]);
    setAutoCompleteSearchValue("");

    setFirstName("");
    setLastName("");
    setPhone("");
    setEmail("");
    setRegistrationsNumber("");
    setGenderId("");
  };

  useEffect(() => {
    let temp = salons.find((element) => element.salonId === salonId);
    setSelectedSalon(temp);
  }, [salonId, salons]);

  const setVisitState = (visit) => {
    setDescription(visit.description);
    setCustomerId(visit.salonCustomer.customer.personId);
    setStartTime(visit.startTime ? visit.startTime : "");
    setEndTime(visit.endTime ? visit.endTime : "");
    setCreateHairstylistId(visit.hairstylist.personId);
    setCreateSalonId(visit.salonCustomer.salon.salonId);
    setEditHairstylistId(visit.hairstylist.personId);
    setEditSalonId(visit.salonCustomer.salon.salonId);
    setStatusId(visit.status.categoryItemId);
    setVisitId(visit.visitId);
    setVisitDate(moment(visit.startTime).format("YYYY-MM-DD"));
    setServiceTypeId(visit.serviceType.serviceTypeId);
    setSelectedServiceTypeList([
      ...helper.cloneDeep(findServiceTypeList(visit)),
    ]);
    setAutoCompleteSearchValue(`${visit.customerFullName}`);
  };

  const setCreateVisitState = (visitD, start, end) => {
    setCreateSalonId(salonId);
    setCreateHairstylistId(hairstylistId);
    setStartTime(start);
    setEndTime(end);
    setVisitDate(moment(visitD, "DD.MM.YYYY.").format("YYYY-MM-DD"));
  };

  const findServiceTypeList = (visit) => {
    return visit.visitServiceTypes.map((v) => {
      return v.serviceType;
    });
  };

  const clearCheckboxSystem = () => {
    setIsEdit(false);
    setIsCreate(false);
    setIsDelete(false);
    setIsCustomerCreate(false);

    setFirstName("");
    setLastName("");
    setPhone("");
    setEmail("");
    setRegistrationsNumber("");
    setGenderId("");
  };

  const emptySetSalonId = () => { };

  const activeDates = () => {
    let dates = [];
    let futureDate = moment(new Date())
      .add(selectedSalon.customerSchedulePeriod, "days")
      .startOf("day")
      .toDate();
    let nowDate = moment(new Date()).startOf("day").toDate();

    for (let workingTime of hairstylistWorkingTimes) {
      let activeDate = moment(workingTime.startTime, "YYYY-MM-DD")
        .startOf("day")
        .toDate();
      if (activeDate <= futureDate && activeDate >= nowDate) {
        dates.push(activeDate);
      }
    }
    return [
      ...dates.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.date) - new Date(a.date);
      }),
    ];
  };

  const helperFunction = () => {
    clearState();
    setIsCustomerCreate(true);
    helper.showModal("#createCustomerInVisitModalCalendarDaily");
  };

  const checkIfSameEditHairstylist = (newEditHairstylistId) => {
    if (editHairstylistId !== newEditHairstylistId) {
      setDescription("");
      setStatusId("");
      setStartTime("");
      setEndTime("");
      setSelectedServiceTypeList([]);
      setEditHairstylistId(newEditHairstylistId);
    }
  };

  const checkIfSameCreateHairstylist = (newCreateHairstylistId) => {
    if (createHairstylistId !== newCreateHairstylistId) {
      setDescription("");
      setStatusId("");
      // setStartTime("");
      // setEndTime("");
      setSelectedServiceTypeList([]);
      setCreateHairstylistId(newCreateHairstylistId);
    }
  };

  const checkIfSameServiceTypeList = (newServiceTypeList) => {
    if (!helper.isArrayEqual(selectedServiceTypeList, newServiceTypeList)) {
      setDescription("");
      setStartTime("");
      setEndTime("");
      setSelectedServiceTypeList(newServiceTypeList);
    }
  };

  const checkIfSameCreateServiceTypeList = (newServiceTypeList) => {
    if (!helper.isArrayEqual(selectedServiceTypeList, newServiceTypeList)) {
      setDescription("");

      let internalLengthInSalonTimeSlots = 0;
      newServiceTypeList.forEach((element) => {
        let selectedServiceType = hairstylistServiceTypes.find(
          (serviceType) => serviceType.serviceTypeId === element.serviceTypeId
        );
        if (selectedServiceType !== undefined) {
          internalLengthInSalonTimeSlots +=
            selectedServiceType.serviceType.serviceTypeDuration;
        }
      });
      if (internalLengthInSalonTimeSlots !== 1) {
        setStartTime("");
        setEndTime("");
      }

      setSelectedServiceTypeList(newServiceTypeList);
    }
  };

  if (!salonId) {
    return null;
  }

  return (
    <>
      <div className="col-12 calendar-day-holder">
        <h5>{t("home.calendarDailyComponent.title", "Calendar Daily")}</h5>

        <div className="card mb-3">
          <div className="card-body top-controls">
            <label className="column-title-muted">
              {t("home.calendarDailyComponent.dayLabel", "Day")}
            </label>
            <div className="calendar-header text-center">
              <div className="calendar-controls">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={(e) => {
                    let d = moment(currentDate, "DD.MM.YYYY.")
                      .add(-1, "days")
                      .format("DD.MM.YYYY.");
                    setCurrentDate(d);
                    setCurrentWeekStart(
                      moment(d, "DD.MM.YYYY.")
                        .startOf("isoWeek")
                        .format("DD.MM.YYYY.")
                    );
                    setCurrentMonthStart(
                      moment(d, "DD.MM.YYYY.")
                        .startOf("month")
                        .format("DD.MM.YYYY.")
                    );
                  }}
                >
                  <i className="fal fa-chevron-left"></i>{" "}
                  {t("home.calendarDailyComponent.previousLabel", "Prev")}
                </button>
                <span className="text-dark calendar-type-value">
                  {/* <DatePicker
                    id="currentDateCreateWorkingTime"
                    selected={moment(currentDate, "DD.MM.YYYY.")
                      .startOf("day")
                      .toDate()}
                    dateFormat="dd.MM.yyyy."
                    onKeyDown={e => {
                      e.preventDefault();
                      return false;
                    }}
                    onChange={date => {
                      let d = moment(date, "YYYY-MM-DD").format("DD.MM.YYYY.");
                      setCurrentDate(d);
                      setCurrentWeekStart(
                        moment(d, "DD.MM.YYYY.")
                          .startOf("isoWeek")
                          .format("DD.MM.YYYY.")
                      );
                      setCurrentMonthStart(
                        moment(d, "DD.MM.YYYY.")
                          .startOf("month")
                          .format("DD.MM.YYYY.")
                      );
                    }}
                    placeholderText="Select date"
                  /> */}
                </span>
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={(e) => {
                    let d = moment(currentDate, "DD.MM.YYYY.")
                      .add(1, "days")
                      .format("DD.MM.YYYY.");
                    setCurrentDate(d);
                    setCurrentWeekStart(
                      moment(d, "DD.MM.YYYY.")
                        .startOf("isoWeek")
                        .format("DD.MM.YYYY.")
                    );
                    setCurrentMonthStart(
                      moment(d, "DD.MM.YYYY.")
                        .startOf("month")
                        .format("DD.MM.YYYY.")
                    );
                  }}
                >
                  Next <i className="fal fa-chevron-right"></i>
                </button>
              </div>
            </div>

            {/* Add clasName "show-scroll" to div below to show calendar-appointments with scroll >  className="row show-scroll" */}
            <div className="row columns-container">
              <ConnectedCalendarDailyDayColumn
                currentDate={moment(currentDate, "DD.MM.YYYY.")
                  .add(-3, "days")
                  .format("DD.MM.YYYY.")}
                setVisitState={setVisitState}
                setIsCreate={setIsCreate}
                setIsEdit={setIsEdit}
                setCreateVisitState={setCreateVisitState}
                dashboardDataDaily={
                  dashboardDataDaily[0] ? dashboardDataDaily[0] : []
                }
                selectedSalon={selectedSalon}
              />

              <ConnectedCalendarDailyDayColumn
                currentDate={moment(currentDate, "DD.MM.YYYY.")
                  .add(-2, "days")
                  .format("DD.MM.YYYY.")}
                setVisitState={setVisitState}
                setIsCreate={setIsCreate}
                setIsEdit={setIsEdit}
                setCreateVisitState={setCreateVisitState}
                dashboardDataDaily={
                  dashboardDataDaily[1] ? dashboardDataDaily[1] : []
                }
                selectedSalon={selectedSalon}
              />

              <ConnectedCalendarDailyDayColumn
                currentDate={moment(currentDate, "DD.MM.YYYY.")
                  .add(-1, "days")
                  .format("DD.MM.YYYY.")}
                setVisitState={setVisitState}
                setIsCreate={setIsCreate}
                setIsEdit={setIsEdit}
                setCreateVisitState={setCreateVisitState}
                dashboardDataDaily={
                  dashboardDataDaily[2] ? dashboardDataDaily[2] : []
                }
                selectedSalon={selectedSalon}
              />

              <ConnectedCalendarDailyDayColumn
                currentDate={moment(currentDate, "DD.MM.YYYY.")
                  .add(0, "days")
                  .format("DD.MM.YYYY.")}
                setVisitState={setVisitState}
                setIsCreate={setIsCreate}
                setIsEdit={setIsEdit}
                setCreateVisitState={setCreateVisitState}
                dashboardDataDaily={
                  dashboardDataDaily[3] ? dashboardDataDaily[3] : []
                }
                selectedSalon={selectedSalon}
              />

              <ConnectedCalendarDailyDayColumn
                currentDate={moment(currentDate, "DD.MM.YYYY.")
                  .add(1, "days")
                  .format("DD.MM.YYYY.")}
                setVisitState={setVisitState}
                setIsCreate={setIsCreate}
                setIsEdit={setIsEdit}
                setCreateVisitState={setCreateVisitState}
                dashboardDataDaily={
                  dashboardDataDaily[4] ? dashboardDataDaily[4] : []
                }
                selectedSalon={selectedSalon}
              />

              <ConnectedCalendarDailyDayColumn
                currentDate={moment(currentDate, "DD.MM.YYYY.")
                  .add(2, "days")
                  .format("DD.MM.YYYY.")}
                setVisitState={setVisitState}
                setIsCreate={setIsCreate}
                setIsEdit={setIsEdit}
                setCreateVisitState={setCreateVisitState}
                dashboardDataDaily={
                  dashboardDataDaily[5] ? dashboardDataDaily[5] : []
                }
                selectedSalon={selectedSalon}
              />
              <ConnectedCalendarDailyDayColumn
                currentDate={moment(currentDate, "DD.MM.YYYY.")
                  .add(3, "days")
                  .format("DD.MM.YYYY.")}
                setVisitState={setVisitState}
                setIsCreate={setIsCreate}
                setIsEdit={setIsEdit}
                setCreateVisitState={setCreateVisitState}
                dashboardDataDaily={
                  dashboardDataDaily[6] ? dashboardDataDaily[6] : []
                }
                selectedSalon={selectedSalon}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Create Visit Modal */}
      {isCreate && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="createVisitCalendarDailyModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="createVisitCalendarDailyModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="createVisitCalendarDailyModalLabel"
                >
                  <i className="fal fa-cut fa-lg c-purple" />{" "}
                  {t(
                    "home.calendarDailyComponent.createVisitCalendarDailyModal.title",
                    "Create Visit"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    clearState();
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <input type="hidden" value="something" />
                  <div className="form-group has-light-background bg-gray-lighter rounded-lg pt-1 mt-n1">
                    <div className="row">
                      <div className="col-md-8">
                        <label
                          htmlFor="customerId"
                          className="d-block text-center text-sm-left"
                        >
                          {helper.i18nMultiFallbackKey("home.calendarDailyComponent.createVisitCalendarDailyModal.customerLabel", "Start typing to select Client")}
                        </label>
                        <AutoComplete
                          field="name"
                          className="form-control-nested"
                          value={autoCompleteSearchValue}
                          onChange={(e) => {
                            setAutoCompleteSearchValue(e.value);
                            setCustomerId("");
                            if (e.value.value) {
                              setCustomerId(e.value.value);
                            }
                          }}
                          suggestions={customers.map((c) => {
                            return {
                              name: `${c.firstName} ${c.lastName}`,
                              value: c.personId,
                            };
                          })}
                          completeMethod={(e) => {
                            getCustomers(e.query);
                          }}
                        />
                      </div>
                      <div className="col-md-4 text-center text-md-right mt-3 mt-md-0">
                        <label className="d-none d-md-block">&nbsp;</label>
                        <button
                          type="button"
                          className="btn btn-outline-primary ico-l w-sm-100"
                          onClick={helperFunction}
                        >
                          <i className="fal fa-plus"></i>{" "}
                          {helper.i18nMultiFallbackKey("home.calendarDailyComponent.createVisitCalendarDailyModal.newCustomerLabel", "New Client")}{" "}
                        </button>
                      </div>
                      <div className="invalid-feedback">
                        {helper.i18nMultiFallbackKey("home.calendarDailyComponent.createVisitCalendarDailyModal.newCustomerInvalid", "Please start typing to select your client.")}
                      </div>
                    </div>
                  </div>

                  {customerId && (
                    <>
                      <div className="form-group">
                        <label htmlFor="salonId">
                          {helper.i18nMultiFallbackKey(
                            "home.calendarDailyComponent.createVisitCalendarDailyModal.shopLabel",
                            "Shop"
                          )}
                        </label>
                        <ConnectedSalonCustomersDropDown
                          salonId={createSalonId}
                          setSelectedSalon={setSelectedSalon}
                          customerId={customerId}
                          setSalonId={emptySetSalonId}
                          setSalonCustomerId={setSalonCustomerId}
                          setServiceTypes={setServiceTypes}
                          setSalonTimeSlot={setSalonTimeSlot}
                          id="salonIdCalendarDailyCreate"
                        />
                        <div className="invalid-feedback">
                          {helper.i18nMultiFallbackKey(
                            "home.calendarDailyComponent.createVisitCalendarDailyModal.shopInvalid",
                            "Please select your shop."
                          )}
                        </div>
                      </div>
                      {createSalonId && (
                        <>
                          <div className="form-group">
                            <label htmlFor="hairstylistId">
                              {helper.i18nMultiFallbackKey("home.calendarDailyComponent.createVisitCalendarDailyModal.hairstylistLabel", "Provider")}
                            </label>
                            <ConnectedHairstylistsDropDown
                              hairstylistId={createHairstylistId}
                              setHairstylistId={checkIfSameCreateHairstylist}
                              salonId={createSalonId}
                              id="hairstylistIdClandarDailyCreate"
                            />
                            <div className="invalid-feedback">
                              {helper.i18nMultiFallbackKey("home.calendarDailyComponent.createVisitCalendarDailyModal.hairstylistInvalid", "Please select your provider.")}
                            </div>
                          </div>
                          {createHairstylistId && (
                            <>
                              <div className="form-group">
                                <label htmlFor="serviceTypeId">
                                  {t(
                                    "home.calendarDailyComponent.createVisitCalendarDailyModal.serviceTypeLabel",
                                    "Service type"
                                  )}
                                </label>
                                <ConnectedHairstylistServiceTypesCheckboxLilstComponentV2
                                  setSelectedServiceTypeList={
                                    checkIfSameCreateServiceTypeList
                                  }
                                  selectedServiceTypeList={
                                    selectedServiceTypeList
                                  }
                                  hairstylistId={createHairstylistId}
                                  salonId={createSalonId}
                                  visitId={visitId}
                                  isEdit={false}
                                  id="hairstylistServiceTypeIdCalendarDailyCreate"
                                />
                                <div className="invalid-feedback">
                                  {t(
                                    "home.calendarDailyComponent.createVisitCalendarDailyModal.serviceTypeInvalid",
                                    "Please select your service type."
                                  )}
                                </div>
                              </div>
                              {selectedServiceTypeList &&
                                selectedServiceTypeList.length > 0 && (
                                  <>
                                    <div className="row">
                                      <div className="form-group calendar-controls cc-default col-md-6">
                                        <label htmlFor="visitDateEdit">
                                          {t(
                                            "home.calendarDailyComponent.createVisitCalendarDailyModal.visitDateLabel",
                                            "Visit date"
                                          )}
                                        </label>

                                        <DatePicker
                                          className="form-control"
                                          id="visitDateEdit"
                                          selected={
                                            visitDate
                                              ? moment(visitDate, "YYYY-MM-DD")
                                                .startOf("day")
                                                .toDate()
                                              : setVisitDate(
                                                moment(
                                                  activeDates()[0]
                                                ).format("YYYY-MM-DD")
                                              )
                                          }
                                          dateFormat="yyyy-MM-dd"
                                          onKeyDown={(e) => {
                                            e.preventDefault();
                                            return false;
                                          }}
                                          onChange={(date) => {
                                            setVisitDate(
                                              moment(date).format("YYYY-MM-DD")
                                            );
                                            if (startTime && endTime) {
                                              setStartTime(
                                                moment(date)
                                                  .hour(
                                                    moment(startTime).format(
                                                      "HH"
                                                    )
                                                  )
                                                  .minute(
                                                    moment(startTime).format(
                                                      "mm"
                                                    )
                                                  )
                                                  .format("YYYY-MM-DD HH:mm")
                                              );

                                              setEndTime(
                                                moment(date)
                                                  .hour(
                                                    moment(endTime).format("HH")
                                                  )
                                                  .minute(
                                                    moment(endTime).format("mm")
                                                  )
                                                  .format("YYYY-MM-DD HH:mm")
                                              );
                                            }
                                          }}
                                          includeDates={activeDates()}
                                          highlightDates={activeDates()}
                                          maxDate={moment(new Date())
                                            .add(
                                              selectedSalon.customerSchedulePeriod,
                                              "days"
                                            )
                                            .toDate()}
                                          placeholderText={t(
                                            "home.calendarDailyComponent.createVisitCalendarDailyModal.visitDatePlaceholder",
                                            "Select visit date"
                                          )}
                                        />
                                      </div>
                                    </div>
                                    {visitDate && visitDate !== undefined && (
                                      <>
                                        <div className="form-group">
                                          <label htmlFor="slotTimeComponentCalendarDailyEdit">
                                            {t(
                                              "home.calendarDailyComponent.createVisitCalendarDailyModal.timeSlotsFromLabel",
                                              "Time slots ( expected visit duration from"
                                            )}{" "}
                                            {startTime
                                              ? moment(startTime).format(
                                                "HH:mm"
                                              )
                                              : ""}{" "}
                                            {t(
                                              "home.calendarDailyComponent.createVisitCalendarDailyModal.timeSlotsToLabel",
                                              "to"
                                            )}{" "}
                                            {endTime
                                              ? moment(endTime).format("HH:mm")
                                              : ""}
                                            )
                                          </label>
                                          <ConnectedFreeVisitSlotsPopupComponentV2
                                            salonId={createSalonId}
                                            hairstylistId={createHairstylistId}
                                            selectedServices={
                                              selectedServiceTypeList
                                            }
                                            visitDate={moment(visitDate).format(
                                              "YYYY-MM-DDTHH:mm:ss"
                                            )}
                                            setStartTime={setStartTime}
                                            setEndTime={setEndTime}
                                            startTime={startTime}
                                            endTime={endTime}
                                            salonTimeSlot={salonTimeSlot}
                                            id="slotTimeComponentCalendarDailyCreate"
                                            caller={
                                              "Create Visit Modal Calendar Daily"
                                            }
                                            editType={false}
                                            visitId={visitId}
                                            key={Math.random * 10000}
                                          />
                                        </div>
                                        {/* {startTime && endTime && (
                                          <div className="form-group">
                                            <label id="visitDurationCreate">
                                              Expected visit duration from{" "}
                                              {startTime
                                                ? moment(startTime).format(
                                                    "HH:mm"
                                                  )
                                                : ""}{" "}
                                              to{" "}
                                              {endTime
                                                ? moment(endTime).format(
                                                    "HH:mm"
                                                  )
                                                : ""}
                                            </label>
                                          </div>
                                        )} */}
                                        <div className="form-group">
                                          <label htmlFor="description">
                                            {t(
                                              "home.calendarDailyComponent.createVisitCalendarDailyModal.descriptionLabel",
                                              "Description"
                                            )}
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="description"
                                            autoComplete="new-username"
                                            placeholder={t(
                                              "home.calendarDailyComponent.createVisitCalendarDailyModal.descriptionPlaceholder",
                                              "Please enter visit description"
                                            )}
                                            value={description}
                                            onChange={(event) =>
                                              setDescription(event.target.value)
                                            }
                                          />
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => {
                    clearState();
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  disabled={startTime === endTime}
                  onClick={() => {
                    createVisitFromServiceTypeList(
                      description,
                      createHairstylistId,
                      salonCustomerId,
                      createSalonId,
                      startTime,
                      endTime,
                      selectedServiceTypeList,
                      () => {
                        clearState();
                        clearCheckboxSystem();
                        getDashboardData(salonId, hairstylistId, currentDate);
                        getDashboardDataDaily(
                          salonId,
                          hairstylistId,
                          currentDate
                        );
                      }
                    );
                  }}
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Edit Visit Modal */}
      {isEdit && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="editVisitCalendarDailyModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="editVisitCalendarDailyModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="editVisitCalendarDailyModalLabel"
                >
                  <i className="fal fa-user fa-lg c-purple" />{" "}
                  {t(
                    "home.calendarDailyComponent.editVisitCalendarDailyModal.title",
                    "Edit Visit"
                  )}{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    clearState();
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <input type="hidden" value="something" />
                  <div className="form-group">
                    <label htmlFor="customerIdEdit">
                      {helper.i18nMultiFallbackKey("home.calendarDailyComponent.editVisitCalendarDailyModal.customerLabel", "Client")}
                    </label>
                    <AutoComplete
                      field="name"
                      className="form-control-nested"
                      value={autoCompleteSearchValue}
                      onChange={(e) => {
                        setAutoCompleteSearchValue(e.value);
                        setCustomerId("");
                        if (e.value.value) {
                          setCustomerId(e.value.value);
                        }
                      }}
                      suggestions={customers.map((c) => {
                        return {
                          name: `${c.firstName} ${c.lastName}`,
                          value: c.personId,
                        };
                      })}
                      completeMethod={(e) => {
                        getCustomers(e.query);
                      }}
                    />

                    <div className="invalid-feedback">
                      {helper.i18nMultiFallbackKey("home.calendarDailyComponent.editVisitCalendarDailyModal.customerInvalid", "Please select your client.")}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="salonIdEdit">
                      {helper.i18nMultiFallbackKey(
                        "home.calendarDailyComponent.editVisitCalendarDailyModal.shopLabel",
                        "Shop"
                      )}
                    </label>
                    <ConnectedSalonCustomersDropDown
                      salonId={editSalonId}
                      customerId={customerId}
                      setSalonId={emptySetSalonId}
                      setSalonCustomerId={setSalonCustomerId}
                      setServiceTypes={setServiceTypes}
                      setSalonTimeSlot={setSalonTimeSlot}
                      id="salonIdCalendarDailyEdit"
                    />
                    <div className="invalid-feedback">
                      {helper.i18nMultiFallbackKey(
                        "home.calendarDailyComponent.editVisitCalendarDailyModal.shopInvalid",
                        "Please select your shop."
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="hairstylistIdCalendarDailyEdit">
                      {helper.i18nMultiFallbackKey("home.calendarDailyComponent.editVisitCalendarDailyModal.hairstylistLabel", "Provider")}
                    </label>
                    <ConnectedHairstylistsDropDown
                      hairstylistId={editHairstylistId}
                      setHairstylistId={checkIfSameEditHairstylist}
                      salonId={editSalonId}
                      id="hairstylistIdCalendarDailyEdit"
                    />
                    <div className="invalid-feedback">
                      {helper.i18nMultiFallbackKey("home.calendarDailyComponent.editVisitCalendarDailyModal.hairstylistInvalid", "Please select your provider.")}
                    </div>
                  </div>
                  {editHairstylistId && (
                    <>
                      <div className="form-group">
                        <label htmlFor="serviceTypeIdCalendarDailyEdit">
                          {t(
                            "home.calendarDailyComponent.editVisitCalendarDailyModal.serviceTypeLabel",
                            "Service type"
                          )}
                        </label>
                        <ConnectedHairstylistServiceTypesCheckboxLilstComponentV2
                          setSelectedServiceTypeList={
                            checkIfSameServiceTypeList
                          }
                          selectedServiceTypeList={selectedServiceTypeList}
                          hairstylistId={editHairstylistId}
                          salonId={editSalonId}
                          visitId={visitId}
                          isEdit={true}
                          id="serviceTypeIdCalendarDailyEdit"
                        />
                        <div className="invalid-feedback">
                          {t(
                            "home.calendarDailyComponent.editVisitCalendarDailyModal.serviceTypeInvalid",
                            "Please select your service type."
                          )}
                        </div>
                      </div>
                      {selectedServiceTypeList &&
                        selectedServiceTypeList.length > 0 && (
                          <>
                            <div className="form-group">
                              <label htmlFor="statusIdCalendarDailyEdit">
                                {t(
                                  "home.calendarDailyComponent.editVisitCalendarDailyModal.statusLabel",
                                  "Status"
                                )}
                              </label>
                              <ConnectedCategoryItemsDropDown
                                categoryType="Visit status"
                                categoryItemId={statusId}
                                setCategoryItemId={setStatusId}
                                id="statusIdCalendarDailyEdit"
                              />
                              <div className="invalid-feedback">
                                {t(
                                  "home.calendarDailyComponent.editVisitCalendarDailyModal.statusInvalid",
                                  "Please select your status."
                                )}
                              </div>
                            </div>
                            {statusId && (
                              <>
                                <div className="row">
                                  <div className="form-group calendar-controls cc-default col-md-6">
                                    <label htmlFor="visitDateCalendarDailyEdit">
                                      {t(
                                        "home.calendarDailyComponent.editVisitCalendarDailyModal.visitDateLabel",
                                        "Visit date"
                                      )}
                                    </label>

                                    <DatePicker
                                      className="form-control"
                                      id="visitDateCalendarDailyEdit"
                                      selected={
                                        visitDate
                                          ? moment(visitDate, "YYYY-MM-DD")
                                            .startOf("day")
                                            .toDate()
                                          : setVisitDate(
                                            moment(activeDates()[0]).format(
                                              "YYYY-MM-DD"
                                            )
                                          )
                                      }
                                      dateFormat="yyyy-MM-dd"
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                      onChange={(date) => {
                                        setVisitDate(
                                          moment(date).format("YYYY-MM-DD")
                                        );
                                      }}
                                      includeDates={activeDates()}
                                      highlightDates={activeDates()}
                                      placeholderText={t(
                                        "home.calendarDailyComponent.editVisitCalendarDailyModal.visitDatePlaceholder",
                                        "This only includes today and tomorrow"
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label htmlFor="slotTimeComponentCalendarDailyEdit">
                                    {t(
                                      "home.calendarDailyComponent.editVisitCalendarDailyModal.timeSlotsFromLabel",
                                      "Time slots ( expected visit duration from"
                                    )}{" "}
                                    {startTime
                                      ? moment(startTime).format("HH:mm")
                                      : ""}{" "}
                                    {t(
                                      "home.calendarDailyComponent.editVisitCalendarDailyModal.timeSlotsToLabel",
                                      "to"
                                    )}{" "}
                                    {endTime
                                      ? moment(endTime).format("HH:mm")
                                      : ""}
                                    )
                                  </label>
                                  <ConnectedFreeVisitSlotsPopupComponentV2
                                    salonId={editSalonId}
                                    hairstylistId={editHairstylistId}
                                    selectedServices={selectedServiceTypeList}
                                    visitDate={visitDate}
                                    setStartTime={setStartTime}
                                    setEndTime={setEndTime}
                                    salonTimeSlot={salonTimeSlot}
                                    id="slotTimeComponentCalendarDailyEdit"
                                    caller={"Edit Visit Modal Calendar Daily"}
                                    editType={true}
                                    visitId={visitId}
                                    key={Math.random * 10000}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="descriptionCalendarDaily">
                                    {t(
                                      "home.calendarDailyComponent.editVisitCalendarDailyModal.descriptionLabel",
                                      "Description"
                                    )}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="descriptionCalendarDaily"
                                    autoComplete="new-username"
                                    placeholder={t(
                                      "home.calendarDailyComponent.editVisitCalendarDailyModal.descriptionPlaceholder",
                                      "Please enter visit description"
                                    )}
                                    value={description}
                                    onChange={(event) =>
                                      setDescription(event.target.value)
                                    }
                                    required
                                  />
                                </div>
                              </>
                            )}
                          </>
                        )}
                    </>
                  )}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => {
                    clearState();
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  disabled={startTime === endTime}
                  onClick={() =>
                    saveVisitFromServiceTypeList(
                      visitId,
                      description,
                      editHairstylistId,
                      salonCustomerId,
                      editSalonId,
                      statusId,
                      serviceTypeId,
                      startTime,
                      endTime,
                      selectedServiceTypeList,
                      () => {
                        clearState();
                        clearCheckboxSystem();
                        getDashboardData(salonId, hairstylistId, currentDate);
                        getDashboardDataDaily(
                          salonId,
                          hairstylistId,
                          currentDate
                        );
                      }
                    )
                  }
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Create Customer Modal */}
      {isCustomerCreate && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="createCustomerInVisitModalCalendarDaily"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="createCustomerInVisitModalCalendarDailylLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="createCustomerInVisitModalCalendarDailyLabel"
                >
                  <i className="fal fa-user-tie fa-lg c-purple" />
                  {helper.i18nMultiFallbackKey("home.calendarDailyComponent.createCustomerInVisitModalCalendarDaily.title", "Create Client")}{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setIsCustomerCreate(false)}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <input type="hidden" value="something" />
                  <div className="form-group">
                    <label htmlFor="customerFirstNameCalendarDaily">
                      {t(
                        "home.calendarDailyComponent.createCustomerInVisitModalCalendarDaily.firstNameLabel",
                        "First Name"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="customerFirstNameCalendarDaily"
                      placeholder={helper.i18nMultiFallbackKey("home.calendarDailyComponent.createCustomerInVisitModalCalendarDaily.firstNamePlaceholder", "Please enter client first name")}
                      value={firstName}
                      onChange={(event) => setFirstName(event.target.value)}
                      required
                    />
                    <div className="invalid-feedback">
                      {t(
                        "home.calendarDailyComponent.createCustomerInVisitModalCalendarDaily.firstNameInvalid",
                        "First name is required."
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="customerLastNameCalendarDaily">
                      {t(
                        "home.calendarDailyComponent.createCustomerInVisitModalCalendarDaily.lastNameLabel",
                        "Last Name"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="customerLastNameCalendarDaily"
                      placeholder={helper.i18nMultiFallbackKey("home.calendarDailyComponent.createCustomerInVisitModalCalendarDaily.lastNamePlaceholder", "Please enter client last name")}
                      value={lastName}
                      onChange={(event) => setLastName(event.target.value)}
                      required
                    />
                    <div className="invalid-feedback">
                      {t(
                        "home.calendarDailyComponent.createCustomerInVisitModalCalendarDaily.lastNameInvalid",
                        "Last name is required."
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="phoneCalendarDaily">
                      {t(
                        "home.calendarDailyComponent.createCustomerInVisitModalCalendarDaily.phoneLabel",
                        "Phone Number"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneCalendarDaily"
                      placeholder={t(
                        "home.calendarDailyComponent.createCustomerInVisitModalCalendarDaily.phonePlaceholder",
                        "Phone Number"
                      )}
                      value={phone}
                      onChange={(event) => setPhone(event.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="emailCalendarDaily">
                      {t(
                        "home.calendarDailyComponent.createCustomerInVisitModalCalendarDaily.emailLabel",
                        "Email"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="emailCalendarDaily"
                      placeholder={t(
                        "home.calendarDailyComponent.createCustomerInVisitModalCalendarDaily.emailPlaceholder",
                        "Email"
                      )}
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="registrationsNumberCalendarDaily">
                      {t(
                        "home.calendarDailyComponent.createCustomerInVisitModalCalendarDaily.registrationNumberLabel",
                        "Registrations Number"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="registrationsNumberCalendarDaily"
                      placeholder={t(
                        "home.calendarDailyComponent.createCustomerInVisitModalCalendarDaily.registrationNumberPlaceholder",
                        "Registrations Number"
                      )}
                      value={registrationsNumber}
                      onChange={(event) =>
                        setRegistrationsNumber(event.target.value)
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="salonIdCalendarDaily">
                      {helper.i18nMultiFallbackKey(
                        "home.calendarDailyComponent.createCustomerInVisitModalCalendarDaily.shopLabel",
                        "Shop"
                      )}
                    </label>
                    <ConnectedSalonsDropDown
                      salonId={salonId}
                      setSalonId={emptySetSalonId}
                      setServiceTypes={setServiceTypes}
                      setSalonTimeSlot={setSalonTimeSlot}
                      id="salonIdCalendarDaily"
                    />
                    <div className="invalid-feedback">
                      {helper.i18nMultiFallbackKey(
                        "home.calendarDailyComponent.createCustomerInVisitModalCalendarDaily.shopPlaceholder",
                        "Please select your shop."
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="genderIdCalendarDaily">
                      {t(
                        "home.calendarDailyComponent.createCustomerInVisitModalCalendarDaily.genderLabel",
                        "Gender"
                      )}
                    </label>
                    <ConnectedCategoryItemsDropDown
                      categoryType="Gender"
                      categoryItemId={genderId}
                      setCategoryItemId={setGenderId}
                      id="genderIdCalendarDaily"
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => setIsCustomerCreate(false)}
                >
                  <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  onClick={() =>
                    createCustomer(
                      firstName,
                      lastName,
                      phone,
                      email,
                      registrationsNumber,
                      genderId,
                      salonId,
                      (customerId, isAllCustomers) => {
                        if (rolesMap.get("Admin")) {
                          getCustomersPaged(
                            "",
                            isAllCustomers ? null : 1,
                            [],
                            () => {
                              setCustomerId(customerId);
                              setAutoCompleteSearchValue(
                                `${firstName} ${lastName}`
                              );
                              setIsCustomerCreate(false);
                            }
                          );
                        } else {
                          if (rolesMap.get("Salon admin")) {
                            // getCustomersPagedForSalon(
                            getCustomersPaged(
                              "",
                              // activeUser.userRoles[0].externalId,
                              isAllCustomers ? null : 1,
                              [],
                              () => {
                                setCustomerId(customerId);
                                setAutoCompleteSearchValue(
                                  `${firstName} ${lastName}`
                                );
                                setIsCustomerCreate(false);
                              }
                            );
                          }
                        }
                      }
                    )
                  }
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    salons: state.salons,
    activeUser: state.activeUser,
    customers: state.customers,
    hairstylistWorkingTimes: state.hairstylistWorkingTimes,
    dashboardDataDaily: state.dashboardDataDaily,
    hairstylistServiceTypes: state.hairstylistServiceTypes,
    salonId: ownProps.salonId,
    hairstylistId: ownProps.hairstylistId,
    currentDate: ownProps.currentDate,
    getDashboardData: ownProps.getDashboardData,
    getDashboardDataDaily: ownProps.getDashboardDataDaily,
    setCurrentDate: ownProps.setCurrentDate,
    setCurrentWeekStart: ownProps.setCurrentWeekStart,
    setCurrentMonthStart: ownProps.setCurrentMonthStart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createCustomer(
      firstName,
      lastName,
      mobile,
      email,
      registrationsNumber,
      genderId,
      salonId,
      cb
    ) {
      let customer = {
        FirstName: firstName,
        LastName: lastName,
        Mobile: mobile,
        Email: email,
        RegistrationsNumber: registrationsNumber,
        GenderId: genderId,
        SalonId: salonId,
      };
      dispatch(mutations.createCustomer(customer, true, cb));
    },
    createVisit(
      description,
      hairstylistId,
      salonCustomerId,
      salonId,
      serviceTypeId,
      startTime,
      endTime,
      cb
    ) {
      let visit = {
        Description: description,
        HairstylistId: hairstylistId,
        SalonCustomerId: salonCustomerId,
        SalonId: salonId,
        ServiceTypeId: serviceTypeId,
        StartTime: startTime,
        EndTime: endTime,
      };
      cb();
      dispatch(mutations.createVisit(visit));
    },
    createVisitFromServiceTypeList(
      description,
      hairstylistId,
      salonCustomerId,
      salonId,
      startTime,
      endTime,
      selectedServiceTypeList,
      cb
    ) {
      let visit = {
        Description: description,
        HairstylistId: hairstylistId,
        SalonCustomerId: salonCustomerId,
        SalonId: salonId,
        StartTime: startTime,
        EndTime: endTime,
        ServiceTypeList: selectedServiceTypeList,
      };
      dispatch(mutations.createVisitFromServiceTypeList(visit, cb));
    },
    saveVisit(
      visitId,
      description,
      hairstylistId,
      salonCustomerId,
      salonId,
      statusId,
      serviceTypeId,
      startTime,
      endTime,
      cb
    ) {
      let visit = {
        VisitId: visitId,
        Description: description,
        HairstylistId: hairstylistId,
        SalonCustomerId: salonCustomerId,
        SalonId: salonId,
        StatusId: statusId,
        ServiceTypeId: serviceTypeId,
        StartTime: startTime,
        EndTime: endTime,
      };
      dispatch(mutations.saveVisit(visit, cb));
    },
    saveVisitFromServiceTypeList(
      visitId,
      description,
      hairstylistId,
      salonCustomerId,
      salonId,
      statusId,
      serviceTypeId,
      startTime,
      endTime,
      selectedServiceTypeList,
      cb
    ) {
      let visit = {
        VisitId: visitId,
        Description: description,
        HairstylistId: hairstylistId,
        SalonCustomerId: salonCustomerId,
        SalonId: salonId,
        StatusId: statusId,
        ServiceTypeId: serviceTypeId,
        StartTime: startTime,
        EndTime: endTime,
        ServiceTypeList: selectedServiceTypeList,
      };
      dispatch(mutations.saveVisitFromServiceTypeList(visit, cb));
    },
    deleteVisit(visitId, cb) {
      let visit = {
        VisitId: visitId,
      };
      dispatch(mutations.deleteVisit(visit));
    },
    getCustomers(searchValue) {
      dispatch(mutations.getCustomersForAutocomplete(searchValue));
    },
    clearCustomers() {
      dispatch(mutations.setAllCustomers([]));
    },
    getAllHairstylists() {
      dispatch(mutations.getAllHairstylistsForDropdown());
    },
    getAllSalons() {
      dispatch(mutations.getAllSalons());
    },
    getAllCustomers() {
      dispatch(mutations.getAllCustomers());
    },
    getCustomersPaged(searchValue, page, sort, cb) {
      const customSetSort = (sort) => {
        let result = [];
        sort.forEach((element) => {
          switch (element.field) {
            case "firstName":
            case "lastName":
            case "email":
            case "phone":
              result.push({
                field:
                  "Person." +
                  element.field[0].toUpperCase() +
                  element.field.slice(1),
                dir: element.dir,
              });
              break;
            default:
              result.push(element);
              break;
          }
        });

        return result;
      };

      let customSort = customSetSort(sort);
      dispatch(mutations.getCustomersPaged(searchValue, page, customSort, cb));
    },
    // getCustomersPagedForSalon(searchValue, salonId, page, sort, cb) {
    //   const customSetSort = (sort) => {
    //     let result = [];
    //     sort.forEach((element) => {
    //       switch (element.field) {
    //         case "firstName":
    //         case "lastName":
    //         case "email":
    //         case "phone":
    //           result.push({
    //             field:
    //               "Person." +
    //               element.field[0].toUpperCase() +
    //               element.field.slice(1),
    //             dir: element.dir,
    //           });
    //           break;
    //         default:
    //           result.push(element);
    //           break;
    //       }
    //     });

    //     return result;
    //   };

    //   let customSort = customSetSort(sort);
    //   dispatch(
    //     mutations.getCustomersPagedForSalon(
    //       searchValue,
    //       salonId,
    //       page,
    //       customSort,
    //       cb
    //     )
    //   );
    // },
  };
};

export const ConnectedCalendarDaily = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarDailyComponent);
