import React, { useState, Component, useEffect } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { TimeColumn } from "../TimeColumn";
import { DateColumn } from "../DateColumn";
import { RangeColumn } from "../RangeColumn";

import * as moment from "moment";
import * as helper from "../HelperRepository";
import { useTranslation, withTranslation } from "react-i18next";

const HairstylistWorkingTimeConsolidatedListComponent = ({
  selectedSalonHairstylist,
  getHairstylistWorkingTimePagedConsolidated,
  getAllHairstylistWorkingTimeConsolidated,
  //workingTimes,
  hairstylistWorkingTimesConsolidated,
  getAllHairstylistWorkingTimesDates,
  //workingTimeTotalCount,
  hairstylistWorkingTimeTotalCountConsolidated,
  clearParentState,
  setParentState,
  setCurrentDate,
  setToGetFreeSlots,
  getHairstylistWorkingTimeIsDateCreated,
  pageState,
  salonWorkingTimesConsolidated,
  hairstylistAllWorkingTimesDates,
  setActiveDates,
  setIsEdit,
  setIsCreate,
  setIsDelete,
}) => {
  const { t, i18n } = useTranslation();

  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 10 });

  useEffect(() => {
    setPage({ skip: (pageState - 1) * 10, take: 10 });
  }, [pageState]);

  useEffect(() => {
    if (selectedSalonHairstylist.salonHairstylistId) {
      getHairstylistWorkingTimePagedConsolidated(
        selectedSalonHairstylist.salonHairstylistId,
        page.skip / 10 + 1,
        sort
      );
      getAllHairstylistWorkingTimesDates(
        selectedSalonHairstylist.salonHairstylistId,
        null,
        []
      );
    }
  }, [
    getHairstylistWorkingTimePagedConsolidated,
    getAllHairstylistWorkingTimesDates,
    page,
    selectedSalonHairstylist,
    sort,
  ]);

  const ActionButtonsColumnWithTranslation = withTranslation()(
    ActionButtonsColumn
  );

  const grid = (
    <Grid
      data={hairstylistWorkingTimesConsolidated}
      pageable={{
        buttonCount: 3,
        info: true,
        type: "numeric",
        previousNext: true,
      }}
      skip={page.skip}
      take={page.take}
      onPageChange={(event) => {
        setPage({ skip: event.page.skip, take: event.page.take });
        getHairstylistWorkingTimePagedConsolidated(
          selectedSalonHairstylist.salonHairstylistId,
          event.page.skip / 10 + 1,
          sort
        );
      }}
      total={hairstylistWorkingTimeTotalCountConsolidated}
      sortable
      sort={sort}
      onSortChange={(e) => {
        setSort(e.sort);
        getHairstylistWorkingTimePagedConsolidated(
          selectedSalonHairstylist.salonHairstylistId,
          page.skip / 10 + 1,
          sort
        );
      }}
      style={{ height: "410px" }}
      className="table-header-purple"
    >
      <GridColumn
        field="workTimeDate"
        title={t(
          "hairstylist.hairstylistWorkingTimeConsolidatedListComponent.grid.date.title",
          "Date"
        )}
        className="start-time-cell"
        headerClassName="start-time-cell"
        cell={(props) => (
          <DateColumn
            time={props.dataItem.workingTimeDate}
            mobileTitle={t(
              "hairstylist.hairstylistWorkingTimeConsolidatedListComponent.grid.date.mobileTitle",
              "Date:"
            )}
          />
        )}
      />
      <GridColumn
        field="workTimeRange"
        title={t(
          "hairstylist.hairstylistWorkingTimeConsolidatedListComponent.grid.range.title",
          "Range"
        )}
        className="end-time-cell"
        headerClassName="end-time-cell"
        cell={(props) => (
          <RangeColumn
            range={props.dataItem.workingTimeRangeList}
            mobileTitle={t(
              "hairstylist.hairstylistWorkingTimeConsolidatedListComponent.grid.range.mobileTitle",
              "Range:"
            )}
          />
        )}
      />
      <GridColumn
        field="Actions"
        title={t("common.actions", "Actions")}
        cell={(props) => (
          <ActionButtonsColumnWithTranslation
            setParentState={setParentState}
            setToGetFreeSlots={setToGetFreeSlots}
            setCurrentDate={setCurrentDate}
            setActiveDates={setActiveDates}
            activeDates={activeDates}
            setIsEdit={setIsEdit}
            setIsDelete={setIsDelete}
            {...props}
          />
        )}
        headerClassName="actions-cell two-bttns"
      />
    </Grid>
  );

  const activeDates = () => {
    let dates = [];
    for (let workingTime of salonWorkingTimesConsolidated) {
      if (
        !hairstylistAllWorkingTimesDates.includes(
          workingTime.workingTimeDate,
          "YYYY-MM-DD"
        ) &&
        moment(workingTime.workingTimeDate, "YYYY-MM-DD").startOf("day") >=
          moment().startOf("day")
      ) {
        dates.push(
          moment(workingTime.workingTimeDate, "YYYY-MM-DD")
            .startOf("day")
            .toDate()
        );
      }
    }

    let dateList = helper.orderBy(
      dates.map((x) => x),
      [
        function (x) {
          return x;
        },
      ],
      ["asc"]
    );
    return [...helper.cloneDeep(dateList)];
  };

  return (
    <>
      <div className="table-rounded-border table-headlabel-responsive-md hairstylist-table">
        <header>
          <div className="button-container">
            <button
              type="button"
              className="btn btn-purple-outline btn-sm ico-l"
              // data-toggle="modal"
              // data-target={`#createHairstylistWorkingTimeModal`}
              onClick={() => {
                let nowDate = moment(activeDates()[0]).format("YYYY-MM-DD");
                setToGetFreeSlots(true);
                setCurrentDate(nowDate);
                getHairstylistWorkingTimeIsDateCreated(
                  selectedSalonHairstylist.salonHairstylistId,
                  nowDate
                );
                setActiveDates(activeDates());

                setIsCreate(true);
                helper.showModal("#createHairstylistWorkingTimeModal");
              }}
            >
              <i className="fal fa-plus"></i>
              {t(
                "hairstylist.hairstylistWorkingTimeConsolidatedListComponent.new",
                "New"
              )}
              <span className="mobile-hide-550">
                {t(
                  "hairstylist.hairstylistWorkingTimeConsolidatedListComponent.workingTime",
                  "Working time"
                )}
              </span>
            </button>
          </div>
        </header>
        {grid}
      </div>
    </>
  );
};
class ActionButtonsColumn extends Component {
  render() {
    return (
      <td className="actions-cell two-bttns">
        <div className="button-container">
          <button
            type="button"
            className="btn btn-outline-success btn-sm ico-l"
            // data-toggle="modal"
            // data-target="#editHairstylistWorkingTimeModal"
            onClick={() => {
              this.props.setToGetFreeSlots(true);
              this.props.setParentState(this.props.dataItem);
              this.props.setActiveDates(this.props.activeDates());
              this.props.setIsEdit(true);
              helper.showModal("#editHairstylistWorkingTimeModal");
            }}
            disabled={this.props.dataItem.hasDependents}
          >
            <i className="fal fa-edit"></i>
            {this.props.t("common.edit", "Edit")}
          </button>
          <button
            type="button"
            className="btn btn-outline-danger btn-sm ico-l"
            // data-toggle="modal"
            // data-target="#deleteHairstylistWorkingTimeModal"
            onClick={() => {
              this.props.setParentState(this.props.dataItem);
              this.props.setIsDelete(true);
              helper.showModal("#deleteHairstylistWorkingTimeModal");
            }}
            disabled={this.props.dataItem.hasDependents}
          >
            <i className="fal fa-ban"></i>
            {this.props.t("common.deactivate", "Deactivate")}
          </button>
        </div>
      </td>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    workingTimes: state.hairstylistWorkingTimes,
    hairstylistWorkingTimesConsolidated:
      state.hairstylistWorkingTimesConsolidated,
    workingTimeTotalCount: state.hairstylistWorkingTimeTotalCount,
    salonWorkingTimesConsolidated: state.salonWorkingTimesConsolidated,
    hairstylistAllWorkingTimesDates: state.hairstylistAllWorkingTimesDates,
    hairstylistWorkingTimeTotalCountConsolidated:
      state.hairstylistWorkingTimeTotalCountConsolidated,
    pageState: state.hairstylistWorkingTimePageConsolidated,
    selectedSalonHairstylist: ownProps.selectedSalonHairstylist,
    setParentState: ownProps.setParentState,
    clearParentState: ownProps.clearParentState,
    setCurrentDate: ownProps.setCurrentDate,
    setToGetFreeSlots: ownProps.setToGetFreeSlots,
    getHairstylistWorkingTimeIsDateCreated:
      ownProps.getHairstylistWorkingTimeIsDateCreated,
    setActiveDates: ownProps.setActiveDates,
    setIsEdit: ownProps.setIsEdit,
    setIsCreate: ownProps.setIsCreate,
    setIsDelete: ownProps.setIsDelete,
    getAllHairstylistWorkingTimesDates:
      ownProps.getAllHairstylistWorkingTimesDates,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getHairstylistWorkingTimePagedConsolidated(salonHairstylistId, page, sort) {
    dispatch(
      mutations.getHairstylistWorkingTimePagedConsolidated(
        salonHairstylistId,
        page,
        sort
      )
    );
  },
});

export const ConnectedHairstylistWorkingTimeConsolidatedList = connect(
  mapStateToProps,
  mapDispatchToProps
)(HairstylistWorkingTimeConsolidatedListComponent);
