import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ConnectedGeneralizationList } from "./GeneralizationList";
import * as mutations from "../../store/mutations";
import { FormErrors } from "../FormErrors";
import * as moment from "moment";
import * as helper from "../HelperRepository";
import { useTranslation } from "react-i18next";
import { ConnectedCategoryItemsDropDown } from "../Dropdowns/CategoryItemsDropDown";
import RequiredFieldsComponent from "../RequiredFieldsComponent";

const GeneralizationComponent = ({
  activeUser,
  getActiveUser,
  createGeneralization,
  saveGeneralization,
  deleteGeneralization,
}) => {
  const { t } = useTranslation();

  document.getElementsByTagName("html")[0].classList.remove("page-signin");

  const [createGeneralizationId, setCreateGeneralizationId] = useState("");

  const [editGeneralizationId, setEditGeneralizationId] = useState("");
  const [deleteGeneralizationId, setDeleteGeneralizationId] = useState("");
  const [
    createGeneralizationDescription,
    setCreateGeneralizationDescription,
  ] = useState("");

  const [
    editGeneralizationDescription,
    setEditGeneralizationDescription,
  ] = useState("");

  const [
    deleteGeneralizationDescription,
    setDeleteGeneralizationDescription,
  ] = useState("");

  const [createShopTypeName, setCreateShopTypeName] = useState("");
  const [editShopTypeName, setEditShopTypeName] = useState("");
  const [deleteShopTypeName, setDeleteShopTypeName] = useState("");

  const [hasDependents, setHasDependents] = useState("");
  const [selectedGeneralization, setSelectedGeneralization] = useState({});

  const [formErrors, setFormErrors] = useState("");

  // const [
  //   generalizationDescriptionValid,
  //   setGeneralizationDescriptionValid
  // ] = useState(false);

  const [shopTypeNameValid, setShopTypeNameValid] = useState(false);

  const [formValid, setFormValid] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add("grid-view");
    if (!activeUser || !activeUser.personId) {
      getActiveUser();
    }
    return () => {
      document.getElementsByTagName("html")[0].classList.remove("grid-view");
    };
  }, []);

  // useEffect(() => {
  //   setFormValid(shopTypeNameValid != null);
  // }, [shopTypeNameValid]);

  const clearState = () => {
    setCreateGeneralizationId("");
    setCreateGeneralizationDescription("");
    setEditGeneralizationId("");
    setEditGeneralizationDescription("");
    setDeleteGeneralizationId("");
    setDeleteGeneralizationDescription("");

    setCreateShopTypeName("");
    setEditShopTypeName("");
    setDeleteShopTypeName("");

    setFormValid(false);
    // setGeneralizationDescriptionValid(false);
    setShopTypeNameValid(false);
    setFormErrors({
      createGeneralizationDescription: "",
      editGeneralizationDescription: "",
      createShopTypeName: "",
      editShopTypeName: "",
    });
  };

  const setGeneralizationState = (generalization) => {
    setEditShopTypeName(generalization.shopTypeName);
    setEditGeneralizationDescription(generalization.description);
    setEditGeneralizationId(generalization.generalizationId);

    setDeleteShopTypeName(generalization.shopTypeName);
    setDeleteGeneralizationDescription(generalization.description);
    setDeleteGeneralizationId(generalization.generalizationId);

    setHasDependents(generalization.hasDependents);
    setFormValid(true);
    // setGeneralizationDescriptionValid(true);
    setShopTypeNameValid(true);
    setFormErrors({
      createShopTypeName: "",
      editShopTypeName: "",
      createGeneralizationDescription: "",
      editGeneralizationDescription: "",
    });
  };

  const clearComponentShow = () => {
    setIsCreate(false);
    setIsDelete(false);
    setIsEdit(false);
  };

  const handleUserInput = (e) => {
    switch (e.target.id) {
      // case "createGeneralizationDescription":
      //   setCreateGeneralizationDescription(e.target.value);
      //   validateField(e.target.id, e.target.value);
      //   break;

      // case "editGeneralizationDescription":
      //   setEditGeneralizationDescription(e.target.value);
      //   validateField(e.target.id, e.target.value);
      //   break;

      case "createShopTypeName":
        setCreateShopTypeName(e.target.value);
        validateField(e.target.id, e.target.value);

        break;
      case "editShopTypeName":
        setEditShopTypeName(e.target.value);
        validateField(e.target.id, e.target.value);

        break;
      default:
        break;
    }
  };

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    // let fieldDescriptionValid = generalizationDescriptionValid;
    let fieldShopTypeNameValid = shopTypeNameValid;

    switch (fieldName) {
      // case "createGeneralizationDescription":
      //   fieldDescriptionValid = value.match(/^(?!\s*$).+/i) && value.length > 5;
      //   fieldValidationErrors.createGeneralizationDescription = fieldDescriptionValid
      //     ? ""
      //     : t("common.isInvalid", " is invalid");
      //   break;
      // case "editGeneralizationDescription":
      //   fieldDescriptionValid = value.match(/^(?!\s*$).+/i) && value.length > 5;
      //   fieldValidationErrors.editGeneralizationDescription = fieldDescriptionValid
      //     ? ""
      //     : t("common.isInvalid", " is invalid");
      //   break;

      case "createShopTypeName":
      case "editShopTypeName":
        let fieldShopTypeNameValid = /^(?!\s*$).+/i;
        fieldShopTypeNameValid =
          fieldShopTypeNameValid.test(value) && value.length > 5;
        fieldValidationErrors.createShopTypeName = fieldShopTypeNameValid
          ? ""
          : t(
              "common.shopTypeNameIsInvalid",
              " is invalid. It should begin with letter and have at least 6 characters"
            );
        break;
      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    // setGeneralizationDescriptionValid(fieldDescriptionValid);
    setShopTypeNameValid(fieldShopTypeNameValid);
    setFormValid(fieldShopTypeNameValid);
  };

  const errorClassInput = (error) => {
    if (error) {
      return error.length === 0 ? "" : "is-invalid";
    }
    return "";
  };

  const errorClassLabel = (error) => {
    if (error) {
      return error.length === 0 ? "" : "text-danger";
    }
    return "";
  };

  return (
    <>
      <main generalization="main">
        <div className="container-fluid">
          <ConnectedGeneralizationList
            setParentState={setGeneralizationState}
            clearParentState={clearState}
            setSelectedGeneralization={setSelectedGeneralization}
            selectedGeneralization={selectedGeneralization}
            setIsCreate={setIsCreate}
            setIsEdit={setIsEdit}
            setIsDelete={setIsDelete}
            editModal="#editGeneralizationModal"
            deleteModal="#deleteGeneralizationModal"
          />
        </div>
      </main>

      <footer className="footer">
        <div className="container copyright">
          <span className="text-muted">
            Hairstylist &copy; Copyright {moment().format("YYYY")},{" "}
            {t("common.version", "Version:")}
            {process.env.VERSION}
          </span>
        </div>
      </footer>

      {/* Create Generalization Modal */}
      {isCreate && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="createGeneralizationModal"
          tabIndex="-1"
          generalization="dialog"
          aria-labelledby="createGeneralizationModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            generalization="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="createGeneralizationModalLabel">
                  <i className="fal fa-user-cog fa-lg c-purple" />{" "}
                  {t(
                    "generalization.generalizationComponent.createGeneralizationModal.title",
                    "Create Generalization"
                  )}{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <input type="hidden" value="something" />
                  <div className="form-group">
                    <label
                      htmlFor="createShopTypeName"
                      className={`${errorClassLabel(
                        formErrors.createShopTypeName
                      )} required`}
                    >
                      {t(
                        "generalization.generalizationComponent.createGeneralizationModal.createShopTypeNameLabel",
                        "Shop type name"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.createShopTypeName
                      )}`}
                      id="createShopTypeName"
                      autoComplete="new-username"
                      placeholder={t(
                        "generalization.generalizationComponent.createGeneralizationModal.createShopTypeNamePlaceholder",
                        "Please enter shop type name"
                      )}
                      value={createShopTypeName}
                      onChange={handleUserInput}
                      required
                    />
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"createShopTypeName"}
                        valueToShow={"ShopTypeName"}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="createGeneralizationDescription"
                      className={`${errorClassLabel(
                        formErrors.createGeneralizationDescription
                      )}`}
                    >
                      {t(
                        "generalization.generalizationComponent.createGeneralizationModal.createDescriptionLabel",
                        "Description"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.createGeneralizationDescription
                      )}`}
                      id="createGeneralizationDescription"
                      autoComplete="new-username"
                      placeholder={t(
                        "generalization.generalizationComponent.createGeneralizationModal.createDescriptionPlaceholder",
                        "Please enter generalization description"
                      )}
                      value={createGeneralizationDescription}
                      onChange={(event) => {
                        setCreateGeneralizationDescription(event.target.value);
                      }}
                      required
                    />
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"createGeneralizationDescription"}
                        valueToShow={"Generalization description"}
                      />
                    </div>
                  </div>
                  <RequiredFieldsComponent />
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => clearComponentShow()}
                >
                  <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  disabled={!formValid}
                  onClick={() =>
                    createGeneralization(
                      createGeneralizationDescription,
                      createShopTypeName,
                      () => {
                        clearState();
                        clearComponentShow();
                      }
                    )
                  }
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Edit Generalization Modal */}
      {isEdit && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="editGeneralizationModal"
          tabIndex="-1"
          generalization="dialog"
          aria-labelledby="editGeneralizationModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            generalization="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="editGeneralizationModalLabel">
                  <i className="fal fa-user-cog fa-lg c-purple" />{" "}
                  {t(
                    "generalization.generalizationComponent.editGeneralizationModal.title",
                    "Edit Generalization"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <div className="form-group">
                    <label
                      htmlFor="editShopTypeName"
                      className={`${errorClassLabel(
                        formErrors.editShopTypeName
                      )} required`}
                    >
                      {t(
                        "generalization.generalizationComponent.editGeneralizationModal.editShopTypeNameLabel",
                        "Shop type name"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.editShopTypeName
                      )}`}
                      id="editShopTypeName"
                      autoComplete="new-username"
                      placeholder={t(
                        "generalization.generalizationComponent.editGeneralizationModal.editShopTypeNamePlaceholder",
                        "Please enter shop type name"
                      )}
                      value={editShopTypeName}
                      onChange={handleUserInput}
                      required
                    />
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"editShopTypeName"}
                        valueToShow={"ShopTypeName"}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="editGeneralizationDescription"
                      className={`${errorClassLabel(
                        formErrors.editGeneralizationDescription
                      )}`}
                    >
                      {t(
                        "generalization.generalizationComponent.editGeneralizationModal.editGeneralizationDescriptionLabel",
                        "Description"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.editGeneralizationDescription
                      )}`}
                      id="editGeneralizationDescription"
                      placeholder={t(
                        "generalization.generalizationComponent.editGeneralizationModal.editGeneralizationDescriptionPlaceholder",
                        "Generalization description"
                      )}
                      value={editGeneralizationDescription}
                      onChange={(event) => {
                        setEditGeneralizationDescription(event.target.value);
                      }}
                      required
                    />
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"editGeneralizationDescription"}
                        valueToShow={"Generalization description"}
                      />
                    </div>
                  </div>
                  <RequiredFieldsComponent />
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-primary ico-l"
                  data-dismiss="modal"
                  onClick={() => clearComponentShow()}
                >
                  <i className="fal fa-sync" /> {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  disabled={!formValid}
                  onClick={() =>
                    saveGeneralization(
                      editGeneralizationId,
                      editGeneralizationDescription,
                      editShopTypeName,
                      () => {
                        clearState();
                        clearComponentShow();
                      }
                    )
                  }
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delete Generalization Modal */}
      {isDelete && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="deleteGeneralizationModal"
          tabIndex="-1"
          generalization="dialog"
          aria-labelledby="deleteGeneralizationModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            generalization="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="deleteGeneralizationModalLabel">
                  <i className="fal fa-user-cog fa-lg c-purple" />{" "}
                  {t(
                    "generalization.generalizationComponent.deleteGeneralizationModal.title",
                    "Deactivate Generalization"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <h2 className="info-title-upp-case-xl icon-custom-top-center">
                  <span className="icon-custom is-xl icon-triangle-red-white" />
                  {t(
                    "generalization.generalizationComponent.deleteGeneralizationModal.attention",
                    "Attention"
                  )}
                </h2>
                {!hasDependents && (
                  <div className="info-message text-center">
                    <p>
                      {" "}
                      {t(
                        "generalization.generalizationComponent.deleteGeneralizationModal.question",
                        "Are you sure you want to deactivate generalization"
                      )}
                    </p>
                    <p className="prime-text">
                      {deleteGeneralizationDescription}?
                    </p>
                  </div>
                )}
                {hasDependents && (
                  <div className="info-message text-center">
                    <p>
                      {t(
                        "generalization.generalizationComponent.deleteGeneralizationModal.dependencies",
                        "Some generalization items have this generalization, it is not possible to deactivate."
                      )}
                    </p>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  onClick={() =>
                    deleteGeneralization(deleteGeneralizationId, () => {
                      clearState();
                      clearComponentShow();
                    })
                  }
                  disabled={hasDependents}
                >
                  <i className="fal fa-check" /> {t("common.yes", "Yes")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => clearComponentShow()}
                >
                  <i className="fal fa-times" /> {t("common.no", "No")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    activeUser: state.activeUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActiveUser() {
      if (sessionStorage.getItem("user_id")) {
        dispatch(
          mutations.getActiveUser(JSON.parse(sessionStorage.getItem("user_id")))
        );
      }
    },
    createGeneralization(generalizationDescription, shopTypeName, cb) {
      let generalization = {
        Description: generalizationDescription,
        ShopTypeName: shopTypeName,
      };
      cb();
      dispatch(mutations.createGeneralization(generalization));
    },
    saveGeneralization(
      generalizationId,
      generalizationDescription,
      shopTypeName,
      cb
    ) {
      let generalization = {
        GeneralizationId: generalizationId,
        Description: generalizationDescription,
        ShopTypeName: shopTypeName,
      };
      cb();
      dispatch(mutations.saveGeneralization(generalization));
    },
    deleteGeneralization(generalizationId, cb) {
      let generalization = {
        GeneralizationId: generalizationId,
      };
      cb();
      dispatch(mutations.deleteGeneralization(generalization));
    },
  };
};

export const ConnectedGeneralization = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralizationComponent);

export default ConnectedGeneralization;
