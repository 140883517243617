import React, { Component } from "react";
import * as moment from "moment";
export class TimeColumn extends Component {
  render() {
    return (
      <td className="contact-cell">
        <span className="mobile-view-title">{this.props.mobileTitle}</span>
        <span className="date-label">
          {moment(this.props.time).format("YYYY-MM-DD")}
        </span>{" "}
        <span className="time-label">
          {moment(this.props.time).format("HH:mm")}
        </span>
      </td>
    );
  }
}
