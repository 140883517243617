import { combineReducers } from "redux";
import * as mutations from "./mutations";
import checkedState from "./reducerCheckBox";
import { importDefaultSpecifier } from "@babel/types";

let defaultState = {
  session: {},
  activeUser: {},
  roles: [],
  users: [],
  customers: [],
  categoryItems: [],
  categories: [],
  generalizations: [],
  images: [],
  generalizationTranslations: [],
  salons: [],
  hairstylists: [],
  visits: [],
  hairstylistWorkingTimes: [],
  hairstylistWorkingTimesConsolidated: [],
  hairstylistAllWorkingTimesDates: [],
  hairstylistWorkingTimesIsDateCreated: false,
  salonWorkingTimes: [],
  salonWorkingTimesConsolidated: [],
  salonWorkingTimeDateList: [],
  freeVisitTimeSlots: [],
  freeSalonTimeSlots: [],
  freeHairstylistTimeSlots: [],
  dashboardDataDaily: [],
  dashboardDataWeekly: { daySummaries: [] },
  dashboardDataMonthly: [],
  serviceTypes: [],
  hairstylistServiceTypes: [],
  visitServiceTypes: [],
  salonHairstylists: [],
  salonCustomers: [],
  salonWorkingTimesIsDateCreated: false,
  loaded: true,
  dashboardLoaded: true,
  path: "home",
  availableServiceTypes: []
};

export const reducer = combineReducers({
  checkedState,
  session: (userSession = defaultState.session, action) => {
    let { type, authenticated, session } = action;
    switch (type) {
      case mutations.SET_STATE:
        return { ...session, id: action.state.session.id };
      case mutations.REQUEST_AUTHENTICATE_USER:
      case mutations.GET_USER_DATA:
        return { ...session, authenticated: mutations.AUTHENTICATING };
      case mutations.PROCESSING_AUTHENTICATE_USER:
        return { ...session, authenticated };
      default:
        return userSession;
    }
  },
  activeUser: (user = defaultState.activeUser, action) => {
    switch (action.type) {
      case mutations.SET_STATE:
        return action.state.activeUser;
      case mutations.SET_ACTIVE_USER:
        return action.activeUser;
      case mutations.SAVE_USER:
        if (user.PersonId === action.user.PersonId) {
          return action.user;
        }
        return user;
      default:
        return user;
    }
  },
  users(users = defaultState.users, action) {
    switch (action.type) {
      case mutations.SET_USERS:
        return action.users;
      default:
        return users;
    }
  },
  page(page = 1, action) {
    switch (action.type) {
      case mutations.SET_USERS:
        return action.page;
      case mutations.SET_ROLES:
        return action.page;
      case mutations.SET_CUSTOMERS:
        return action.page;
      case mutations.SET_CATEGORY_ITEMS:
        return action.page;
      case mutations.SET_CATEGORIES:
        return action.page;
      case mutations.SET_GENERALIZATIONS:
        return action.page;
      case mutations.SET_IMAGES:
        return action.page;
      case mutations.SET_GENERALIZATION_TRANSLATIONS:
        return action.page;
      case mutations.SET_SALONS:
        return action.page;
      case mutations.SET_VISITS:
        return action.page;
      default:
        return page;
    }
  },
  totalCount(totalCount = 1, action) {
    switch (action.type) {
      case mutations.SET_USERS:
        return action.totalCount;
      case mutations.SET_ROLES:
        return action.totalCount;
      case mutations.SET_CUSTOMERS:
        return action.totalCount;
      case mutations.SET_CATEGORY_ITEMS:
        return action.totalCount;
      case mutations.SET_CATEGORIES:
        return action.totalCount;
      case mutations.SET_GENERALIZATIONS:
        return action.totalCount;
      case mutations.SET_IMAGES:
          return action.totalCount;
      case mutations.SET_GENERALIZATION_TRANSLATIONS:
        return action.totalCount;
      case mutations.SET_SALONS:
        return action.totalCount;
      case mutations.SET_VISITS:
        return action.totalCount;
      default:
        return totalCount;
    }
  },
  hairstylistWorkingTimePage(hairstylistWorkingTimePage = 1, action) {
    switch (action.type) {
      case mutations.SET_HAIRSTYLIST_WORKING_TIMES:
        return action.hairstylistWorkingTimePage;
      default:
        return hairstylistWorkingTimePage;
    }
  },
  hairstylistWorkingTimeTotalCount(
    hairstylistWorkingTimeTotalCount = 1,
    action
  ) {
    switch (action.type) {
      case mutations.SET_HAIRSTYLIST_WORKING_TIMES:
        return action.hairstylistWorkingTimeTotalCount;
      default:
        return hairstylistWorkingTimeTotalCount;
    }
  },
  hairstylistWorkingTimes(
    hairstylistWorkingTimes = defaultState.hairstylistWorkingTimes,
    action
  ) {
    switch (action.type) {
      case mutations.SET_HAIRSTYLIST_WORKING_TIMES:
        return action.hairstylistWorkingTimes;
      case mutations.SET_ALL_HAIRSTYLIST_WORKING_TIMES:
        return action.hairstylistWorkingTimes;
      default:
        return hairstylistWorkingTimes;
    }
  },

  hairstylistWorkingTimePageConsolidated(
    hairstylistWorkingTimePageConsolidated = 1,
    action
  ) {
    switch (action.type) {
      case mutations.SET_HAIRSTYLIST_WORKING_TIMES_CONSOLIDATED:
        return action.hairstylistWorkingTimePageConsolidated;
      default:
        return hairstylistWorkingTimePageConsolidated;
    }
  },
  hairstylistWorkingTimeTotalCountConsolidated(
    hairstylistWorkingTimeTotalCountConsolidated = 1,
    action
  ) {
    switch (action.type) {
      case mutations.SET_HAIRSTYLIST_WORKING_TIMES_CONSOLIDATED:
        return action.hairstylistWorkingTimeTotalCountConsolidated;
      default:
        return hairstylistWorkingTimeTotalCountConsolidated;
    }
  },
  hairstylistWorkingTimesConsolidated(
    hairstylistWorkingTimesConsolidated = defaultState.hairstylistWorkingTimesConsolidated,
    action
  ) {
    switch (action.type) {
      case mutations.SET_HAIRSTYLIST_WORKING_TIMES_CONSOLIDATED:
        return action.hairstylistWorkingTimesConsolidated;
      default:
        return hairstylistWorkingTimesConsolidated;
    }
  },
  hairstylistAllWorkingTimesDates(
    hairstylistAllWorkingTimesDates = defaultState.hairstylistAllWorkingTimesDates,
    action
  ) {
    switch (action.type) {
      case mutations.SET_ALL_HAIRSTYLIST_WORKING_TIMES_DATES:
        return action.hairstylistAllWorkingTimesDates;
      default:
        return hairstylistAllWorkingTimesDates;
    }
  },
  hairstylistWorkingTimesIsDateCreated(
    hairstylistWorkingTimesIsDateCreated = defaultState.hairstylistWorkingTimesIsDateCreated,
    action
  ) {
    switch (action.type) {
      case mutations.SET_HAIRSTYLIST_WORKING_TIMES_IS_DATE_CREATED:
        return action.hairstylistWorkingTimesIsDateCreated;
      default:
        return hairstylistWorkingTimesIsDateCreated;
    }
  },
  salonWorkingTimePage(salonWorkingTimePage = 1, action) {
    switch (action.type) {
      case mutations.SET_SALON_WORKING_TIMES:
        return action.salonWorkingTimePage;
      default:
        return salonWorkingTimePage;
    }
  },
  salonWorkingTimeTotalCount(salonWorkingTimeTotalCount = 1, action) {
    switch (action.type) {
      case mutations.SET_SALON_WORKING_TIMES:
        return action.salonWorkingTimeTotalCount;
      default:
        return salonWorkingTimeTotalCount;
    }
  },
  salonWorkingTimes(
    salonWorkingTimes = defaultState.salonWorkingTimes,
    action
  ) {
    switch (action.type) {
      case mutations.SET_SALON_WORKING_TIMES:
        return action.salonWorkingTimes;
      case mutations.SET_ALL_SALON_WORKING_TIMES:
        return action.salonWorkingTimes;
      default:
        return salonWorkingTimes;
    }
  },

  salonWorkingTimePageConsolidated(
    salonWorkingTimePageConsolidated = 1,
    action
  ) {
    switch (action.type) {
      case mutations.SET_SALON_WORKING_TIMES_CONSOLIDATED:
        return action.salonWorkingTimePageConsolidated;
      default:
        return salonWorkingTimePageConsolidated;
    }
  },
  salonWorkingTimeTotalCountConsolidated(
    salonWorkingTimeTotalCountConsolidated = 0,
    action
  ) {
    switch (action.type) {
      case mutations.SET_SALON_WORKING_TIMES_CONSOLIDATED:
        return action.salonWorkingTimeTotalCountConsolidated;
      default:
        return salonWorkingTimeTotalCountConsolidated;
    }
  },
  salonWorkingTimesConsolidated(
    salonWorkingTimesConsolidated = defaultState.salonWorkingTimesConsolidated,
    action
  ) {
    switch (action.type) {
      case mutations.SET_SALON_WORKING_TIMES_CONSOLIDATED:
        return action.salonWorkingTimesConsolidated;
      default:
        return salonWorkingTimesConsolidated;
    }
  },
  salonWorkingTimeDateList(
    salonWorkingTimeDateList = defaultState.salonWorkingTimeDateList,
    action
  ) {
    switch (action.type) {
      case mutations.SET_SALON_WORKING_TIME_DATE_LIST:
        return action.salonWorkingTimeDateList;
      default:
        return salonWorkingTimeDateList;
    }
  },
  salonWorkingTimesIsDateCreated(
    salonWorkingTimesIsDateCreated = defaultState.salonWorkingTimesIsDateCreated,
    action
  ) {
    switch (action.type) {
      case mutations.SET_SALON_WORKING_TIMES_IS_DATE_CREATED:
        return action.salonWorkingTimesIsDateCreated;
      default:
        return salonWorkingTimesIsDateCreated;
    }
  },
  serviceTypePage(serviceTypePage = 1, action) {
    switch (action.type) {
      case mutations.SET_SERVICE_TYPES:
        return action.serviceTypePage;
      default:
        return serviceTypePage;
    }
  },
  serviceTypeTotalCount(serviceTypeTotalCount = 1, action) {
    switch (action.type) {
      case mutations.SET_SERVICE_TYPES:
        return action.serviceTypeTotalCount;
      default:
        return serviceTypeTotalCount;
    }
  },
  serviceTypes(serviceTypes = defaultState.serviceTypes, action) {
    switch (action.type) {
      case mutations.SET_SERVICE_TYPES:
        return action.serviceTypes;
      case mutations.SET_ALL_SERVICE_TYPES_FOR_SALON:
        return action.serviceTypes;
      default:
        return serviceTypes;
    }
  },
  hairstylistServiceTypePage(hairstylistServiceTypePage = 1, action) {
    switch (action.type) {
      case mutations.SET_HAIRSTYLIST_SERVICE_TYPES:
        return action.hairstylistServiceTypePage;
      default:
        return hairstylistServiceTypePage;
    }
  },
  hairstylistServiceTypeTotalCount(
    hairstylistServiceTypeTotalCount = 1,
    action
  ) {
    switch (action.type) {
      case mutations.SET_HAIRSTYLIST_SERVICE_TYPES:
        return action.hairstylistServiceTypeTotalCount;
      default:
        return hairstylistServiceTypeTotalCount;
    }
  },
  hairstylistServiceTypes(
    hairstylistServiceTypes = defaultState.hairstylistServiceTypes,
    action
  ) {
    switch (action.type) {
      case mutations.SET_HAIRSTYLIST_SERVICE_TYPES:
        return action.hairstylistServiceTypes;
      case mutations.SET_ALL_HAIRSTYLIST_SERVICE_TYPES_FOR_HAIRSTYLIST:
        return action.hairstylistServiceTypes;
      default:
        return hairstylistServiceTypes;
    }
  },
  visitServiceTypePage(visitServiceTypePage = 1, action) {
    switch (action.type) {
      case mutations.SET_VISIT_SERVICE_TYPES:
        return action.visitServiceTypePage;
      default:
        return visitServiceTypePage;
    }
  },
  visitServiceTypeTotalCount(visitServiceTypeTotalCount = 1, action) {
    switch (action.type) {
      case mutations.SET_VISIT_SERVICE_TYPES:
        return action.visitServiceTypeTotalCount;
      default:
        return visitServiceTypeTotalCount;
    }
  },
  visitServiceTypes(
    visitServiceTypes = defaultState.visitServiceTypes,
    action
  ) {
    switch (action.type) {
      case mutations.SET_VISIT_SERVICE_TYPES:
        return action.visitServiceTypes;
      case mutations.SET_ALL_VISIT_SERVICE_TYPES_FOR_VISIT:
        return action.visitServiceTypes;
      default:
        return visitServiceTypes;
    }
  },
  salonHairstylistPage(salonHairstylistPage = 1, action) {
    switch (action.type) {
      case mutations.SET_SALON_HAIRSTYLISTS:
        return action.salonHairstylistPage;
      default:
        return salonHairstylistPage;
    }
  },
  salonHairstylistTotalCount(salonHairstylistTotalCount = 1, action) {
    switch (action.type) {
      case mutations.SET_SALON_HAIRSTYLISTS:
        return action.salonHairstylistTotalCount;
      default:
        return salonHairstylistTotalCount;
    }
  },
  salonHairstylists(
    salonHairstylists = defaultState.salonHairstylists,
    action
  ) {
    switch (action.type) {
      case mutations.SET_SALON_HAIRSTYLISTS:
        return action.salonHairstylists;
      case mutations.SET_ALL_SALON_HAIRSTYLISTS_FOR_HAIRSTYLIST:
        return action.salonHairstylists;
      default:
        return salonHairstylists;
    }
  },
  salonCustomerPage(salonCustomerPage = 1, action) {
    switch (action.type) {
      case mutations.SET_SALON_CUSTOMERS:
        return action.salonCustomerPage;
      default:
        return salonCustomerPage;
    }
  },
  salonCustomerTotalCount(salonCustomerTotalCount = 1, action) {
    switch (action.type) {
      case mutations.SET_SALON_CUSTOMERS:
        return action.salonCustomerTotalCount;
      default:
        return salonCustomerTotalCount;
    }
  },
  salonCustomers(salonCustomers = defaultState.salonCustomers, action) {
    switch (action.type) {
      case mutations.SET_SALON_CUSTOMERS:
        return action.salonCustomers;
      default:
        return salonCustomers;
    }
  },
  roles(roles = defaultState.roles, action) {
    switch (action.type) {
      case mutations.SET_ROLES:
        return action.roles;
      case mutations.SET_ALL_ROLES:
        return action.roles;
      default:
        return roles;
    }
  },
  customers(customers = defaultState.customers, action) {
    switch (action.type) {
      case mutations.SET_CUSTOMERS:
        return action.customers;
      case mutations.SET_ALL_CUSTOMERS:
        return action.customers;
      default:
        return customers;
    }
  },
  categoryItems(categoryItems = defaultState.categoryItems, action) {
    switch (action.type) {
      case mutations.SET_CATEGORY_ITEMS:
        return action.categoryItems;
      case mutations.SET_ALL_CATEGORY_ITEMS:
        return action.categoryItems;
      default:
        return categoryItems;
    }
  },
  categories(categories = defaultState.categories, action) {
    switch (action.type) {
      case mutations.SET_CATEGORIES:
        return action.categories;
      case mutations.SET_ALL_CATEGORIES:
        return action.categories;
      default:
        return categories;
    }
  },
  generalizations(generalizations = defaultState.generalizations, action) {
    switch (action.type) {
      case mutations.SET_GENERALIZATIONS:
        return action.generalizations;
      case mutations.SET_ALL_GENERALIZATIONS:
        return action.generalizations;
      default:
        return generalizations;
    }
  },
  images(images = defaultState.images, action) {
    switch (action.type) {
      case mutations.SET_IMAGES:
        return action.images;
      case mutations.SET_ALL_IMAGES:
        return action.images;
      default:
        return images;
    }
  },
  generalizationTranslations(
    generalizationTranslations = defaultState.generalizationTranslations,
    action
  ) {
    switch (action.type) {
      case mutations.SET_GENERALIZATION_TRANSLATIONS:
        return action.generalizationTranslations;
      case mutations.SET_ALL_GENERALIZATION_TRANSLATIONS:
        return action.generalizationTranslations;
      default:
        return generalizationTranslations;
    }
  },
  salons(salons = defaultState.salons, action) {
    switch (action.type) {
      case mutations.SET_SALONS:
        return action.salons;
      case mutations.SET_ALL_SALONS:
        return action.salons;
      default:
        return salons;
    }
  },
  hairstylists(hairstylists = defaultState.hairstylists, action) {
    switch (action.type) {
      case mutations.SET_HAIRSTYLISTS:
        return action.hairstylists;
      case mutations.SET_ALL_HAIRSTYLISTS:
        return action.hairstylists;
      default:
        return hairstylists;
    }
  },
  hairstylistPage(hairstylistPage = 1, action) {
    switch (action.type) {
      case mutations.SET_HAIRSTYLISTS:
        return action.hairstylistPage;
      default:
        return hairstylistPage;
    }
  },
  hairstylistTotalCount(hairstylistTotalCount = 1, action) {
    switch (action.type) {
      case mutations.SET_HAIRSTYLISTS:
        return action.hairstylistTotalCount;
      default:
        return hairstylistTotalCount;
    }
  },
  visits(visits = defaultState.visits, action) {
    switch (action.type) {
      case mutations.SET_VISITS:
        return action.visits;
      case mutations.SET_ALL_VISITS:
        return action.visits;
      default:
        return visits;
    }
  },
  freeVisitTimeSlots(
    freeVisitTimeSlots = defaultState.freeVisitTimeSlots,
    action
  ) {
    switch (action.type) {
      case mutations.SET_ALL_FREE_VISIT_TIME_SLOTS:
        return action.freeVisitTimeSlots;
      default:
        return freeVisitTimeSlots;
    }
  },
  freeSalonTimeSlots(
    freeSalonTimeSlots = defaultState.freeSalonTimeSlots,
    action
  ) {
    switch (action.type) {
      case mutations.SET_ALL_FREE_SALON_TIME_SLOTS:
        return action.freeSalonTimeSlots;
      default:
        return freeSalonTimeSlots;
    }
  },
  freeHairstylistTimeSlots(
    freeHairstylistTimeSlots = defaultState.freeHairstylistTimeSlots,
    action
  ) {
    switch (action.type) {
      case mutations.SET_ALL_FREE_HAIRSTYLIST_TIME_SLOTS:
        return action.freeHairstylistTimeSlots;
      default:
        return freeHairstylistTimeSlots;
    }
  },
  dashboardDataDaily(
    dashboardDataDaily = defaultState.dashboardDataDaily,
    action
  ) {
    switch (action.type) {
      case mutations.SET_DASHBOARD_DATA_DAILY:
        return action.data.dailyFreeTimeSlots;
      default:
        return dashboardDataDaily;
    }
  },
  dashboardDataWeekly(
    dashboardDataWeekly = defaultState.dashboardDataWeekly,
    action
  ) {
    switch (action.type) {
      case mutations.SET_DASHBOARD_DATA:
        return action.data.currentWeekSummary;
      default:
        return dashboardDataWeekly;
    }
  },
  dashboardDataMonthly(
    dashboardDataMonthly = defaultState.dashboardDataMonthly,
    action
  ) {
    switch (action.type) {
      case mutations.SET_DASHBOARD_DATA:
        return action.data.monthSummary;
      default:
        return dashboardDataMonthly;
    }
  },
  loaded(loaded = defaultState.loaded, action) {
    switch (action.type) {
      case mutations.SET_LOADED:
        return action.loaded;
      default:
        return loaded;
    }
  },
  dashboardLoaded(dashboardLoaded = defaultState.dashboardLoaded, action) {
    switch (action.type) {
      case mutations.SET_DASHBOARD_LOADED:
        return action.dashboardLoaded;
      default:
        return dashboardLoaded;
    }
  },
  availableServiceTypes(
    availableServiceTypes = defaultState.availableServiceTypes,
    action
  ) {
    switch (action.type) {
      case mutations.SET_AVAILABLE_SERVICE_TYPES:
        return action.availableServiceTypes;
      default:
        return availableServiceTypes;
    }
  },

  path(path = defaultState.path, action) {
    switch (action.type) {
      case mutations.SET_PATH:
        return action.path;
      default:
        let localStoragePath = JSON.parse(sessionStorage.getItem("path"));
        if (localStoragePath && localStoragePath !== path) {
          return localStoragePath;
        } else {
          return path;
        }
    }
  }
});
