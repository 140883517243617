import React, { Component } from "react";
import * as helper from "../HelperRepository";
import { withTranslation } from "react-i18next";

class ActionButtonsColumnCategoryItems_WithoutTranslation extends Component {
  render() {
    return (
      <td className="actions-cell two-bttns">
        <div className="button-container">
          <button
            type="button"
            className="btn btn-outline-success btn-sm ico-l"
            onClick={() => {
              this.props.setParentState(this.props.dataItem);
              this.props.setIsEdit(true);
              helper.showModal("#editCategoryItemModal");
            }}
          >
            <i className="fal fa-edit"></i>
            {this.props.t("common.edit", "Edit")}
          </button>
          {!this.props.dataItem.isDeleted && (
            <button
              type="button"
              className="btn btn-outline-danger btn-sm ico-l"
              onClick={() => {
                this.props.setParentState(this.props.dataItem);
                this.props.setIsDelete(true);
                helper.showModal("#deleteCategoryItemModal");
              }}
            >
              <i className="fal fa-ban"></i>
              {this.props.t("common.deactivate", "Deactivate")}
            </button>
          )}

          {this.props.dataItem.isDeleted && (
            <button
              type="button"
              className="btn btn-outline-danger btn-sm ico-l"
              onClick={() => {
                this.props.setParentState(this.props.dataItem);
                this.props.setIsActivate(true);
                helper.showModal("#activateCategoryItemModal");
              }}
            >
              <i className="fal fa-ban"></i>
              {this.props.t("common.activate", "Activate")}
            </button>
          )}
        </div>
      </td>
    );
  }
}

export const ActionButtonsColumnCategoryItems = withTranslation()(
  ActionButtonsColumnCategoryItems_WithoutTranslation
);
