export const SEND_EMAIL = `SEND_EMAIL`;
export const FORGOT_PASSWORD = `FORGOT_PASSWORD`;
export const REQUEST_AUTHENTICATE_USER = `REQUEST_AUTHENTICATE_USER`;
export const REQUEST_RENEW_TOKEN = `REQUEST_RENEW_TOKEN`;
export const PROCESSING_AUTHENTICATE_USER = `PROCESSING_AUTHENTICATE_USER`;
export const AUTHENTICATING = `AUTHENTICATING`;
export const AUTHENTICATED = `AUTHENTICATED`;
export const NOT_AUTHENTICATED = `NOT_AUTHENTICATED`;
export const SET_STATE = `SET_STATE`;
export const USERNAME_RESERVED = `USERNAME_RESERVED`;
export const REQUEST_USER_ACCOUNT_CREATION = `REQUEST_USER_ACCOUNT_CREATION`;

/* #region User constants*/
export const GET_ACTIVE_USER = "GET_ACTIVE_USER";
export const SET_ACTIVE_USER = "SET_ACTIVE_USER";
export const SAVE_USER = "SAVE_USER";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const UPDATE_USERS_OWN_PASSWORD = "UPDATE_USERS_OWN_PASSWORD";
export const UPDATE_USER_LANGUAGE = "UPDATE_USER_LANGUAGE";
export const CREATE_USER = "CREATE_USER";
export const DELETE_USER = "DELETE_USER";
export const SET_USERS = "SET_USERS";
export const GET_USERS = "GET_USERS";
export const GET_USER_DATA = "GET_USER_DATA";
export const GET_USERS_PAGED = "GET_USERS_PAGED";
export const GET_USERS_FOR_EXPORT = "GET_USERS_FOR_EXPORT";
/* #endregion*/

/* #region Role constants*/
export const SAVE_ROLE = "SAVE_ROLE";
export const CREATE_ROLE = "CREATE_ROLE";
export const SET_ROLES = "SET_ROLES";
export const SET_ALL_ROLES = "SET_ALL_ROLES";
export const GET_ROLES_PAGED = "GET_ROLES_PAGED";
export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const DELETE_ROLE = "DELETE_ROLE";
export const FILTER_ROLES = "FILTER_ROLES";
export const GET_ROLES_FOR_EXPORT = "GET_ROLES_FOR_EXPORT";

/* #endregion*/

/* #region Customer constants*/

export const SAVE_CUSTOMER = "SAVE_CUSTOMER";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const SET_CUSTOMERS = "SET_CUSTOMERS";

export const SET_ALL_CUSTOMERS = "SET_ALL_CUSTOMERS";
export const GET_CUSTOMERS_PAGED = "GET_CUSTOMERS_PAGED";
// export const GET_CUSTOMERS_PAGED_FOR_SALON = "GET_CUSTOMERS_PAGED_FOR_SALON";
export const GET_CUSTOMERS_FOR_AUTOCOMPLETE = "GET_CUSTOMERS_FOR_AUTOCOMPLETE";
export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";

export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const FILTER_CUSTOMERS = "FILTER_CUSTOMERS";
export const GET_CUSTOMERS_FOR_EXPORT = "GET_CUSTOMERS_FOR_EXPORT";

/* #endregion*/

/* #region CategoryItem constants*/
export const SAVE_CATEGORY_ITEM = "SAVE_CATEGORY_ITEM";
export const CREATE_CATEGORY_ITEM = "CREATE_CATEGORY_ITEM";
export const SET_CATEGORY_ITEMS = "SET_CATEGORY_ITEMS";
export const SET_ALL_CATEGORY_ITEMS = "SET_ALL_CATEGORY_ITEMS";
export const GET_CATEGORY_ITEMS_PAGED = "GET_CATEGORY_ITEMS_PAGED";
export const GET_CATEGORY_ITEMS_PAGED_FOR_CATEGORY =
  "GET_CATEGORY_ITEMS_PAGED_FOR_CATEGORY";
export const GET_ALL_CATEGORY_ITEMS_FOR_CATEGORY =
  "GET_ALL_CATEGORY_ITEMS_FOR_CATEGORY";
export const GET_ALL_CATEGORY_ITEMS = "GET_ALL_CATEGORY_ITEMS";
export const DELETE_CATEGORY_ITEM = "DELETE_CATEGORY_ITEM";
export const ACTIVATE_CATEGORY_ITEM = "ACTIVATE_CATEGORY_ITEM";
export const FILTER_CATEGORY_ITEMS = "FILTER_CATEGORY_ITEMS";
export const GET_CATEGORY_ITEMS_FOR_EXPORT = "GET_CATEGORY_ITEMS_FOR_EXPORT";

/* #endregion*/

/* #region Category constants*/
export const SAVE_CATEGORY = "SAVE_CATEGORY";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_ALL_CATEGORIES = "SET_ALL_CATEGORIES";
export const GET_CATEGORIES_PAGED = "GET_CATEGORIES_PAGED";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const FILTER_CATEGORIES = "FILTER_CATEGORIES";
export const GET_CATEGORIES_FOR_EXPORT = "GET_CATEGORIES_FOR_EXPORT";

/* #endregion*/

/* #region Salon constants*/
export const SAVE_SALON = "SAVE_SALON";
export const SAVE_SALON_FOR_ID = "SAVE_SALON_FOR_ID";
export const CREATE_SALON = "CREATE_SALON";
export const SET_SALONS = "SET_SALONS";
export const SET_ALL_SALONS = "SET_ALL_SALONS";
export const GET_SALONS_PAGED = "GET_SALONS_PAGED";
export const GET_ALL_SALONS = "GET_ALL_SALONS";
export const GET_SALON_FOR_ID = "GET_SALON_FOR_ID";
export const DELETE_SALON = "DELETE_SALON";
export const FILTER_SALONS = "FILTER_SALONS";
export const GET_SALONS_FOR_EXPORT = "GET_SALONS_FOR_EXPORT";

/* #endregion*/

/* #region Hairstylist constants*/

export const SAVE_HAIRSTYLIST = "SAVE_HAIRSTYLIST";
export const SAVE_HAIRSTYLIST_FOR_SALON = "SAVE_HAIRSTYLIST_FOR_SALON";
export const CREATE_HAIRSTYLIST = "CREATE_HAIRSTYLIST";
export const CREATE_HAIRSTYLIST_FOR_SALON = "CREATE_HAIRSTYLIST_FOR_SALON";
export const SET_HAIRSTYLISTS = "SET_HAIRSTYLISTS";

export const SET_ALL_HAIRSTYLISTS = "SET_ALL_HAIRSTYLISTS";
export const GET_ALL_HAIRSTYLISTS_DROPDOWN = "GET_ALL_HAIRSTYLISTS_DROPDOWN";
export const GET_HAIRSTYLISTS_PAGED = "GET_HAIRSTYLISTS_PAGED";
export const GET_HAIRSTYLISTS_PAGED_FOR_SALON =
  "GET_HAIRSTYLISTS_PAGED_FOR_SALON";
export const GET_ALL_HAIRSTYLISTS = "GET_ALL_HAIRSTYLISTS";

export const DELETE_HAIRSTYLIST = "DELETE_HAIRSTYLIST";
export const DELETE_HAIRSTYLIST_FOR_SALON = "DELETE_HAIRSTYLIST_FOR_SALON";
export const FILTER_HAIRSTYLISTS = "FILTER_HAIRSTYLISTS";
export const GET_HAIRSTYLISTS_FOR_EXPORT = "GET_HAIRSTYLISTS_FOR_EXPORT";

/* #endregion*/

/* #region Visit constants*/
export const SAVE_VISIT = "SAVE_VISIT";

export const SAVE_VISIT_FROM_SERVICE_TYPE_LIST =
  "SAVE_VISIT_FROM_SERVICE_TYPE_LIST";
export const CREATE_VISIT = "CREATE_VISIT";
export const CREATE_VISIT_FROM_SERVICE_TYPE_LIST =
  "CREATE_VISIT_FROM_SERVICE_TYPE_LIST";
export const SET_VISITS = "SET_VISITS";
export const SET_ALL_VISITS = "SET_ALL_VISITS";
export const GET_VISITS_PAGED = "GET_VISITS_PAGED";
export const GET_VISITS_PAGED_FOR_SALON = "GET_VISITS_PAGED_FOR_SALON";
export const GET_ALL_VISITS = "GET_ALL_VISITS";
export const DELETE_VISIT = "DELETE_VISIT";
export const FILTER_VISITS = "FILTER_VISITS";
export const GET_VISITS_FOR_EXPORT = "GET_VISITS_FOR_EXPORT";

/* #endregion*/

/* #region ServiceType constants*/

export const SAVE_SERVICE_TYPE = "SAVE_SERVICE_TYPE";
export const CREATE_SERVICE_TYPE = "CREATE_SERVICE_TYPE";
export const SET_SERVICE_TYPES = "SET_SERVICE_TYPES";

export const SET_ALL_SERVICE_TYPES = "SET_ALL_SERVICE_TYPES";
export const SET_ALL_SERVICE_TYPES_FOR_SALON =
  "SET_ALL_SERVICE_TYPES_FOR_SALON";
export const GET_ALL_SERVICE_TYPES_DROPDOWN = "GET_ALL_SERVICE_TYPES_DROPDOWN";
export const GET_SERVICE_TYPES_PAGED = "GET_SERVICE_TYPES_PAGED";
export const GET_SERVICE_TYPES_PAGED_WITH_INACTIVE =
  "GET_SERVICE_TYPES_PAGED_WITH_INACTIVE";
export const GET_ALL_SERVICE_TYPES = "GET_ALL_SERVICE_TYPES";
export const GET_ALL_SERVICE_TYPES_FOR_SALON =
  "GET_ALL_SERVICE_TYPES_FOR_SALON";

export const DELETE_SERVICE_TYPE = "DELETE_SERVICE_TYPE";
export const ACTIVATE_SERVICE_TYPE = "ACTIVATE_SERVICE_TYPE";
export const FILTER_SERVICE_TYPES = "FILTER_SERVICE_TYPES";
export const GET_SERVICE_TYPES_FOR_EXPORT = "GET_SERVICE_TYPES_FOR_EXPORT";

/* #endregion*/

/* #region SalonHairstylist constants*/

export const SAVE_SALON_HAIRSTYLIST = "SAVE_SALON_HAIRSTYLIST";
export const CREATE_SALON_HAIRSTYLIST = "CREATE_SALON_HAIRSTYLIST";
export const SET_SALON_HAIRSTYLISTS = "SET_SALON_HAIRSTYLISTS";

export const SET_ALL_SALON_HAIRSTYLISTS = "SET_ALL_SALON_HAIRSTYLISTS";
export const SET_ALL_SALON_HAIRSTYLISTS_FOR_HAIRSTYLIST =
  "SET_ALL_SALON_HAIRSTYLISTS_FOR_HAIRSTYLIST";
export const GET_ALL_SALON_HAIRSTYLISTS_DROPDOWN =
  "GET_ALL_SALON_HAIRSTYLISTS_DROPDOWN";
export const GET_SALON_HAIRSTYLISTS_PAGED = "GET_SALON_HAIRSTYLISTS_PAGED";
export const GET_ALL_SALON_HAIRSTYLISTS = "GET_ALL_SALON_HAIRSTYLISTS";
export const GET_ALL_SALON_HAIRSTYLISTS_FOR_HAIRSTYLIST =
  "GET_ALL_SALON_HAIRSTYLISTS_FOR_HAIRSTYLIST";

export const DELETE_SALON_HAIRSTYLIST = "DELETE_SALON_HAIRSTYLIST";
export const DELETE_SALON_HAIRSTYLIST_REPLACE_HAIRSTYLIST =
  "DELETE_SALON_HAIRSTYLIST_REPLACE_HAIRSTYLIST";
export const FILTER_SALON_HAIRSTYLISTS = "FILTER_SALON_HAIRSTYLISTS";
export const GET_SALON_HAIRSTYLISTS_FOR_EXPORT =
  "GET_SALON_HAIRSTYLISTS_FOR_EXPORT";

/* #endregion*/

/* #region SalonCustomer constants*/

export const SAVE_SALON_CUSTOMER = "SAVE_SALON_CUSTOMER";
export const CREATE_SALON_CUSTOMER = "CREATE_SALON_CUSTOMER";
export const SET_SALON_CUSTOMERS = "SET_SALON_CUSTOMERS";

export const SET_ALL_SALON_CUSTOMERS = "SET_ALL_SALON_CUSTOMERS";
export const GET_ALL_SALON_CUSTOMERS_FOR_SALON =
  "GET_ALL_SALON_CUSTOMERS_FOR_SALON";
export const GET_SALON_CUSTOMERS_PAGED = "GET_SALON_CUSTOMERS_PAGED";
export const GET_ALL_SALON_CUSTOMERS = "GET_ALL_SALON_CUSTOMERS";

export const DELETE_SALON_CUSTOMER = "DELETE_SALON_CUSTOMER";
export const FILTER_SALON_CUSTOMERS = "FILTER_SALON_CUSTOMERS";
export const GET_SALON_CUSTOMERS_FOR_EXPORT = "GET_SALON_CUSTOMERS_FOR_EXPORT";

/* #endregion*/

/* #region HairstylistServiceType constants*/

export const SAVE_HAIRSTYLIST_SERVICE_TYPE = "SAVE_HAIRSTYLIST_SERVICE_TYPE";
export const CREATE_HAIRSTYLIST_SERVICE_TYPE =
  "CREATE_HAIRSTYLIST_SERVICE_TYPE";
export const SET_HAIRSTYLIST_SERVICE_TYPES = "SET_HAIRSTYLIST_SERVICE_TYPES";

export const SET_ALL_HAIRSTYLIST_SERVICE_TYPES =
  "SET_ALL_HAIRSTYLIST_SERVICE_TYPES";
export const SET_ALL_HAIRSTYLIST_SERVICE_TYPES_FOR_HAIRSTYLIST =
  "SET_ALL_HAIRSTYLIST_SERVICE_TYPES_FOR_HAIRSTYLIST";
export const GET_ALL_HAIRSTYLIST_SERVICE_TYPES_DROPDOWN =
  "GET_ALL_HAIRSTYLIST_SERVICE_TYPES_DROPDOWN";
export const GET_HAIRSTYLIST_SERVICE_TYPES_PAGED =
  "GET_HAIRSTYLIST_SERVICE_TYPES_PAGED";
export const GET_ALL_HAIRSTYLIST_SERVICE_TYPES =
  "GET_ALL_HAIRSTYLIST_SERVICE_TYPES";
export const GET_ALL_HAIRSTYLIST_SERVICE_TYPES_FOR_HAIRSTYLIST =
  "GET_ALL_HAIRSTYLIST_SERVICE_TYPES_FOR_HAIRSTYLIST";

export const DELETE_HAIRSTYLIST_SERVICE_TYPE =
  "DELETE_HAIRSTYLIST_SERVICE_TYPE";
export const FILTER_HAIRSTYLIST_SERVICE_TYPES =
  "FILTER_HAIRSTYLIST_SERVICE_TYPES";
export const GET_HAIRSTYLIST_SERVICE_TYPES_FOR_EXPORT =
  "GET_HAIRSTYLIST_SERVICE_TYPES_FOR_EXPORT";

export const GET_AVAILABLE_SERVICE_TYPES = "GET_AVAILABLE_SERVICE_TYPES";
export const SET_AVAILABLE_SERVICE_TYPES = "SET_AVAILABLE_SERVICE_TYPES";
/* #endregion*/

/* #region VisitServiceType constants*/

export const SAVE_VISIT_SERVICE_TYPE = "SAVE_VISIT_SERVICE_TYPE";
export const CREATE_VISIT_SERVICE_TYPE = "CREATE_VISIT_SERVICE_TYPE";
export const SET_VISIT_SERVICE_TYPES = "SET_VISIT_SERVICE_TYPES";

export const SET_ALL_VISIT_SERVICE_TYPES = "SET_ALL_VISIT_SERVICE_TYPES";
export const SET_ALL_VISIT_SERVICE_TYPES_FOR_VISIT =
  "SET_ALL_VISIT_SERVICE_TYPES_FOR_VISIT";
export const GET_ALL_VISIT_SERVICE_TYPES_DROPDOWN =
  "GET_ALL_VISIT_SERVICE_TYPES_DROPDOWN";
export const GET_VISIT_SERVICE_TYPES_PAGED = "GET_VISIT_SERVICE_TYPES_PAGED";
export const GET_ALL_VISIT_SERVICE_TYPES = "GET_ALL_VISIT_SERVICE_TYPES";
export const GET_ALL_VISIT_SERVICE_TYPES_FOR_VISIT =
  "GET_ALL_VISIT_SERVICE_TYPES_FOR_VISIT";

export const DELETE_VISIT_SERVICE_TYPE = "DELETE_VISIT_SERVICE_TYPE";
export const FILTER_VISIT_SERVICE_TYPES = "FILTER_VISIT_SERVICE_TYPES";
export const GET_VISIT_SERVICE_TYPES_FOR_EXPORT =
  "GET_VISIT_SERVICE_TYPES_FOR_EXPORT";

/* #endregion*/

/* #region Hairstylist Working Time constants*/

export const SAVE_HAIRSTYLIST_WORKING_TIME = "SAVE_HAIRSTYLIST_WORKING_TIME";
export const CREATE_HAIRSTYLIST_WORKING_TIME =
  "CREATE_HAIRSTYLIST_WORKING_TIME";
export const SET_HAIRSTYLIST_WORKING_TIMES = "SET_HAIRSTYLIST_WORKING_TIMES";

export const GET_HAIRSTYLIST_WORKING_TIMES_PAGED =
  "GET_HAIRSTYLIST_WORKING_TIMES_PAGED";

export const GET_HAIRSTYLIST_WORKING_TIMES_PAGED_CONSOLIDATED =
  "GET_HAIRSTYLIST_WORKING_TIMES_PAGED_CONSOLIDATED";
export const SET_HAIRSTYLIST_WORKING_TIMES_CONSOLIDATED =
  "SET_HAIRSTYLIST_WORKING_TIMES_CONSOLIDATED";

export const GET_ALL_HAIRSTYLIST_WORKING_TIMES_DATES =
  "GET_ALL_HAIRSTYLIST_WORKING_TIMES_DATES";

export const SET_ALL_HAIRSTYLIST_WORKING_TIMES_DATES =
  "SET_ALL_HAIRSTYLIST_WORKING_TIMES_DATES";

export const GET_ALL_HAIRSTYLIST_WORKING_TIMES =
  "GET_ALL_HAIRSTYLIST_WORKING_TIMES";

export const SET_ALL_HAIRSTYLIST_WORKING_TIMES =
  "SET_ALL_HAIRSTYLIST_WORKING_TIMES";

export const DELETE_HAIRSTYLIST_WORKING_TIME =
  "DELETE_HAIRSTYLIST_WORKING_TIME";
export const DELETE_HAIRSTYLIST_WORKING_TIME_CONSOLIDATED =
  "DELETE_HAIRSTYLIST_WORKING_TIME_CONSOLIDATED";
export const FILTER_HAIRSTYLIST_WORKING_TIMES =
  "FILTER_HAIRSTYLIST_WORKING_TIMES";
export const GET_HAIRSTYLIST_WORKING_TIMES_FOR_EXPORT =
  "GET_HAIRSTYLIST_WORKING_TIMES_FOR_EXPORT";

export const GET_HAIRSTYLIST_WORKING_TIMES_IS_DATE_CREATED =
  "GET_HAIRSTYLIST_WORKING_TIMES_IS_DATE_CREATED";

export const SET_HAIRSTYLIST_WORKING_TIMES_IS_DATE_CREATED =
  "SET_HAIRSTYLIST_WORKING_TIMES_IS_DATE_CREATED";

/* #endregion*/

/* #region Salon Working Time constants*/

export const SAVE_SALON_WORKING_TIME = "SAVE_SALON_WORKING_TIME";

export const CREATE_SALON_WORKING_TIME = "CREATE_SALON_WORKING_TIME";
export const CREATE_SALON_WORKING_TIME_AUTO = "CREATE_SALON_WORKING_TIME_AUTO";
export const DELETE_RANGE = "DELETE_RANGE";

export const SET_SALON_WORKING_TIMES = "SET_SALON_WORKING_TIMES";
export const SET_SALON_WORKING_TIMES_CONSOLIDATED =
  "SET_SALON_WORKING_TIMES_CONSOLIDATED";
export const SET_ALL_SALON_WORKING_TIMES = "SET_ALL_SALON_WORKING_TIMES";
export const GET_SALON_WORKING_TIMES_PAGED = "GET_SALON_WORKING_TIMES_PAGED";
export const GET_SALON_WORKING_TIMES_PAGED_CONSOLIDATED =
  "GET_SALON_WORKING_TIMES_PAGED_CONSOLIDATED";

export const GET_ALL_SALON_WORKING_TIMES = "GET_ALL_SALON_WORKING_TIMES";

export const DELETE_SALON_WORKING_TIME = "DELETE_SALON_WORKING_TIME";
export const DELETE_SALON_WORKING_TIME_CONSOLIDATED =
  "DELETE_SALON_WORKING_TIME_CONSOLIDATED";
export const FILTER_SALON_WORKING_TIMES = "FILTER_SALON_WORKING_TIMES";
export const GET_SALON_WORKING_TIMES_FOR_EXPORT =
  "GET_SALON_WORKING_TIMES_FOR_EXPORT";

export const GET_SALON_WORKING_TIMES_IS_DATE_CREATED =
  "GET_SALON_WORKING_TIMES_IS_DATE_CREATED";

export const SET_SALON_WORKING_TIMES_IS_DATE_CREATED =
  "SET_SALON_WORKING_TIMES_IS_DATE_CREATED";

export const GET_SALON_WORKING_TIME_DATE_LIST =
  "GET_SALON_WORKING_TIME_DATE_LIST";

export const SET_SALON_WORKING_TIME_DATE_LIST =
  "SET_SALON_WORKING_TIME_DATE_LIST";

/* #endregion*/

/* #region Free Visit Time Slots constants*/
export const SET_ALL_FREE_VISIT_TIME_SLOTS = "SET_ALL_FREE_VISIT_TIME_SLOTS";
export const GET_ALL_FREE_VISIT_TIME_SLOTS = "GET_ALL_FREE_VISIT_TIME_SLOTS";
export const GET_ALL_FREE_VISIT_TIME_SLOTS_V2 =
  "GET_ALL_FREE_VISIT_TIME_SLOTS_V2";

/* #endregion*/

/* #region Free Salon Time Slots constants*/
export const SET_ALL_FREE_SALON_TIME_SLOTS = "SET_ALL_FREE_SALON_TIME_SLOTS";
export const GET_ALL_FREE_SALON_TIME_SLOTS = "GET_ALL_FREE_SALON_TIME_SLOTS";

/* #endregion*/

/* #region Free Hairstylist Time Slots constants*/
export const SET_ALL_FREE_HAIRSTYLIST_TIME_SLOTS =
  "SET_ALL_FREE_HAIRSTYLIST_TIME_SLOTS";
export const GET_ALL_FREE_HAIRSTYLIST_TIME_SLOTS =
  "GET_ALL_FREE_HAIRSTYLIST_TIME_SLOTS";

/* #endregion*/

/* #region Dashboard Time Slots constants*/
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA";
export const GET_DASHBOARD_DATA_DAILY = "GET_DASHBOARD_DATA_DAILY";
export const SET_DASHBOARD_DATA_DAILY = "SET_DASHBOARD_DATA_DAILY";

/* #endregion*/

/* #region Generalization constants*/
export const SAVE_GENERALIZATION = "SAVE_GENERALIZATION";
export const CREATE_GENERALIZATION = "CREATE_GENERALIZATION";
export const SET_GENERALIZATIONS = "SET_GENERALIZATIONS";
export const SET_ALL_GENERALIZATIONS = "SET_ALL_GENERALIZATIONS";
export const GET_GENERALIZATIONS_PAGED = "GET_GENERALIZATIONS_PAGED";
export const GET_ALL_GENERALIZATIONS = "GET_ALL_GENERALIZATIONS";
export const DELETE_GENERALIZATION = "DELETE_GENERALIZATION";
export const FILTER_GENERALIZATIONS = "FILTER_GENERALIZATIONS";
export const GET_GENERALIZATIONS_FOR_EXPORT = "GET_GENERALIZATIONS_FOR_EXPORT";

/* #endregion*/

/* #region Images constants*/
export const SAVE_IMAGES = "SAVE_IMAGES";
export const CREATE_IMAGES = "CREATE_IMAGES";
export const SET_IMAGES = "SET_IMAGES";
export const SET_ALL_IMAGES = "SET_ALL_IMAGES";
export const GET_IMAGES_PAGED = "GET_IMAGES_PAGED";
export const GET_ALL_IMAGES = "GET_ALL_IMAGES";
export const DELETE_IMAGES = "DELETE_IMAGES";
export const FILTER_IMAGES = "FILTER_IMAGES";
export const GET_IMAGES_FOR_EXPORT = "GET_IMAGES_FOR_EXPORT";

/* #endregion*/

/* #region Generalization Translation constants*/
export const SAVE_GENERALIZATION_TRANSLATION =
  "SAVE_GENERALIZATION_TRANSLATION";
export const CREATE_GENERALIZATION_TRANSLATION =
  "CREATE_GENERALIZATION_TRANSLATION";
export const SET_GENERALIZATION_TRANSLATIONS =
  "SET_GENERALIZATION_TRANSLATIONS";
export const SET_ALL_GENERALIZATION_TRANSLATIONS =
  "SET_ALL_GENERALIZATION_TRANSLATIONS";
export const GET_GENERALIZATION_TRANSLATIONS_PAGED =
  "GET_GENERALIZATION_TRANSLATIONS_PAGED";
export const GET_ALL_GENERALIZATION_TRANSLATIONS =
  "GET_ALL_GENERALIZATION_TRANSLATIONS";
export const DELETE_GENERALIZATION_TRANSLATION =
  "DELETE_GENERALIZATION_TRANSLATION";
export const FILTER_GENERALIZATION_TRANSLATIONS =
  "FILTER_GENERALIZATIONS_TRANSLATION";
export const GET_GENERALIZATION_TRANSLATIONS_FOR_EXPORT =
  "GET_GENERALIZATION_TRANSLATIONS_FOR_EXPORT";
export const GET_GENERALIZATION_TRANSLATIONS_PAGED_FOR_GENERALIZATION =
  "GET_GENERALIZATION_TRANSLATIONS_PAGED_FOR_GENERALIZATION";
export const GET_ALL_GENERALIZATION_TRANSLATIONS_FOR_GENERALIZATION =
  "GET_ALL_GENERALIZATION_TRANSLATIONS_FOR_GENERALIZATION";

/* #endregion*/

/* #region Images Translation constants*/
export const SAVE_IMAGES_TRANSLATION =
  "SAVE_IMAGES_TRANSLATION";
export const CREATE_IMAGES_TRANSLATION =
  "CREATE_IMAGES_TRANSLATION";
export const SET_IMAGES_TRANSLATIONS =
  "SET_IMAGES_TRANSLATIONS";
export const SET_ALL_IMAGES_TRANSLATIONS =
  "SET_ALL_IMAGES_TRANSLATIONS";
export const GET_IMAGES_TRANSLATIONS_PAGED =
  "GET_IMAGES_TRANSLATIONS_PAGED";
export const GET_ALL_IMAGES_TRANSLATIONS =
  "GET_ALL_IMAGES_TRANSLATIONS";
export const DELETE_IMAGES_TRANSLATION =
  "DELETE_IMAGES_TRANSLATION";
export const FILTER_IMAGES_TRANSLATIONS =
  "FILTER_IMAGES_TRANSLATION";
export const GET_IMAGES_TRANSLATIONS_FOR_EXPORT =
  "GET_IMAGES_TRANSLATIONS_FOR_EXPORT";
export const GET_IMAGES_TRANSLATIONS_PAGED_FOR_IMAGES =
  "GET_IMAGES_TRANSLATIONS_PAGED_FOR_IMAGES";
export const GET_ALL_IMAGES_TRANSLATIONS_FOR_IMAGES =
  "GET_ALL_IMAGES_TRANSLATIONS_FOR_IMAGES";

/* #endregion*/

export const SET_LOADED = "SET_LOADED";
export const SET_DASHBOARD_LOADED = "SET_DASHBOARD_LOADED";

export const SET_PATH = "SET_PATH";

/* #region User mutations*/
export const sendEmail = (emailInformation) => ({
  type: SEND_EMAIL,
  emailInformation,
});

export const forgotPassword = (email) => ({
  type: FORGOT_PASSWORD,
  email,
});

export const requestAuthenticateUser = (email, password, cb) => ({
  type: REQUEST_AUTHENTICATE_USER,
  email,
  password,
  cb,
});

export const requestRenewToken = (email, password, cb) => ({
  type: REQUEST_RENEW_TOKEN,
});

export const processAuthenticateUser = (
  status = AUTHENTICATING,
  session = null
) => ({
  type: PROCESSING_AUTHENTICATE_USER,
  session,
  authenticated: status,
});

export const setState = (state = {}) => ({
  type: SET_STATE,
  state,
});

export const requestCreateUserAccount = (username, password) => ({
  type: REQUEST_USER_ACCOUNT_CREATION,
  username,
  password,
});

export const getUsersPaged = (searchValue, page, sort) => ({
  type: GET_USERS_PAGED,
  searchValue,
  page,
  sort,
});

export const getUsersForExport = (searchValue, totalCount, sort, cb) => ({
  type: GET_USERS_FOR_EXPORT,
  searchValue,
  totalCount,
  sort,
  cb,
});

export const getActiveUser = (userId) => ({
  type: GET_ACTIVE_USER,
  userId,
});

export const setActiveUser = (activeUser) => ({
  type: SET_ACTIVE_USER,
  activeUser,
});

export const saveUser = (user) => ({
  type: SAVE_USER,
  user,
});

export const updateUserPassword = (user, cb) => ({
  type: UPDATE_USER_PASSWORD,
  user,
  cb,
});

export const updateUsersOwnPassword = (user, cb) => ({
  type: UPDATE_USERS_OWN_PASSWORD,
  user,
  cb,
});

export const updateUserLanguage = (user, cb) => ({
  type: UPDATE_USER_LANGUAGE,
  user,
  cb,
});

export const createUser = (user) => ({
  type: CREATE_USER,
  user,
});

export const deleteUser = (user) => ({
  type: DELETE_USER,
  user,
});

export const setUsers = (state) => ({
  type: SET_USERS,
  users: state.resultList,
  page: state.pageNumber,
  totalCount: state.totalCount,
});
/* #endregion*/

/* #region Role mutations */

export const saveRole = (role) => ({
  type: SAVE_ROLE,
  role,
});

export const createRole = (role) => ({
  type: CREATE_ROLE,
  role,
});

export const setRoles = (state) => ({
  type: SET_ROLES,
  roles: state.resultList,
  page: state.pageNumber,
  totalCount: state.totalCount,
});

export const getRolesPaged = (searchValue, page, sort) => ({
  type: GET_ROLES_PAGED,
  searchValue,
  page,
  sort,
});

export const getRolesForExport = (searchValue, totalCount, sort, cb) => ({
  type: GET_ROLES_FOR_EXPORT,
  searchValue,
  totalCount,
  sort,
  cb,
});

export const getAllRoles = () => ({
  type: GET_ALL_ROLES,
});

export const setAllRoles = (roles) => ({
  type: SET_ALL_ROLES,
  roles,
});

export const deleteRole = (role) => ({
  type: DELETE_ROLE,
  role,
});

export const filterRoles = (searchValue, page) => ({
  type: FILTER_ROLES,
  searchValue,
  page,
});

/* #endregion */

/* #region Customer mutations */

export const saveCustomer = (customer) => ({
  type: SAVE_CUSTOMER,
  customer,
});

export const createCustomer = (customer, isAllCustomers, cb) => ({
  type: CREATE_CUSTOMER,
  customer,
  isAllCustomers,
  cb,
});

export const setCustomers = (state) => ({
  type: SET_CUSTOMERS,
  customers: state.resultList,
  page: state.pageNumber,
  totalCount: state.totalCount,
});

export const getCustomersForAutocomplete = (searchValue, page, sort) => ({
  type: GET_CUSTOMERS_FOR_AUTOCOMPLETE,
  searchValue,
});

export const getCustomersPaged = (searchValue, page, sort, cb) => ({
  type: GET_CUSTOMERS_PAGED,
  searchValue,
  page,
  sort,
  cb,
});

// export const getCustomersPagedForSalon = (
//   searchValue,
//   salonId,
//   page,
//   sort,
//   cb
// ) => ({
//   type: GET_CUSTOMERS_PAGED_FOR_SALON,
//   searchValue,
//   salonId,
//   page,
//   sort,
//   cb,
// });

export const getCustomersForExport = (searchValue, totalCount, sort, cb) => ({
  type: GET_CUSTOMERS_FOR_EXPORT,
  searchValue,
  totalCount,
  sort,
  cb,
});

export const getAllCustomers = () => ({
  type: GET_ALL_CUSTOMERS,
});

export const setAllCustomers = (customers) => ({
  type: SET_ALL_CUSTOMERS,
  customers,
});

export const deleteCustomer = (customer) => ({
  type: DELETE_CUSTOMER,
  customer,
});

export const filterCustomers = (searchValue, page) => ({
  type: FILTER_CUSTOMERS,
  searchValue,
  page,
});

/* #endregion */

/* #region CategoryItem mutations */

export const saveCategoryItem = (categoryItem) => ({
  type: SAVE_CATEGORY_ITEM,
  categoryItem,
});

export const createCategoryItem = (categoryItem) => ({
  type: CREATE_CATEGORY_ITEM,
  categoryItem,
});

export const setCategoryItems = (state) => ({
  type: SET_CATEGORY_ITEMS,
  categoryItems: state.resultList,
  page: state.pageNumber,
  totalCount: state.totalCount,
});

export const getCategoryItemsPaged = (searchValue, page, sort) => ({
  type: GET_CATEGORY_ITEMS_PAGED,
  searchValue,
  page,
  sort,
});

export const getCategoryItemsPagedForCategory = (filterObject, page, sort) => ({
  type: GET_CATEGORY_ITEMS_PAGED_FOR_CATEGORY,
  filterObject,
  page,
  sort,
});

export const getAllCategoryItemsForCategory = (filterObject) => ({
  type: GET_ALL_CATEGORY_ITEMS_FOR_CATEGORY,
  filterObject,
  totalCount: null,
  page: null,
  sort: [],
});

export const getCategoryItemsForExport = (
  searchValue,
  totalCount,
  sort,
  cb
) => ({
  type: GET_CATEGORY_ITEMS_FOR_EXPORT,
  searchValue,
  totalCount,
  sort,
  cb,
});

export const getAllCategoryItems = () => ({
  type: GET_ALL_CATEGORY_ITEMS,
});

export const setAllCategoryItems = (categoryItems) => ({
  type: SET_ALL_CATEGORY_ITEMS,
  categoryItems,
});

export const deleteCategoryItem = (categoryItem) => ({
  type: DELETE_CATEGORY_ITEM,
  categoryItem,
});

export const activateCategoryItem = (categoryItem) => ({
  type: ACTIVATE_CATEGORY_ITEM,
  categoryItem,
});

export const filterCategoryItems = (searchValue, page) => ({
  type: FILTER_CATEGORY_ITEMS,
  searchValue,
  page,
});

/* #endregion */

/* #region Category mutations */

export const saveCategory = (category) => ({
  type: SAVE_CATEGORY,
  category,
});

export const createCategory = (category) => ({
  type: CREATE_CATEGORY,
  category,
});

export const setCategories = (state) => ({
  type: SET_CATEGORIES,
  categories: state.resultList,
  page: state.pageNumber,
  totalCount: state.totalCount,
});

export const getCategoriesPaged = (searchValue, page, sort) => ({
  type: GET_CATEGORIES_PAGED,
  searchValue,
  page,
  sort,
});

export const getCategoriesForExport = (searchValue, totalCount, sort, cb) => ({
  type: GET_CATEGORIES_FOR_EXPORT,
  searchValue,
  totalCount,
  sort,
  cb,
});

export const getAllCategories = () => ({
  type: GET_ALL_CATEGORIES,
});

export const setAllCategories = (categories) => ({
  type: SET_ALL_CATEGORIES,
  categories,
});

export const deleteCategory = (category) => ({
  type: DELETE_CATEGORY,
  category,
});

export const filterCategories = (searchValue, page) => ({
  type: FILTER_CATEGORIES,
  searchValue,
  page,
});

/* #endregion */

/* #region Salon mutations */

export const saveSalon = (salon) => ({
  type: SAVE_SALON,
  salon,
});

export const saveSalonForId = (salon, cb) => ({
  type: SAVE_SALON_FOR_ID,
  salon,
  cb,
});

export const createSalon = (salon) => ({
  type: CREATE_SALON,
  salon,
});

export const setSalons = (state) => ({
  type: SET_SALONS,
  salons: state.resultList,
  page: state.pageNumber,
  totalCount: state.totalCount,
});

export const getSalonsPaged = (searchValue, page, sort) => ({
  type: GET_SALONS_PAGED,
  searchValue,
  page,
  sort,
});

export const getSalonsForExport = (searchValue, totalCount, sort, cb) => ({
  type: GET_SALONS_FOR_EXPORT,
  searchValue,
  totalCount,
  sort,
  cb,
});

export const getAllSalons = () => ({
  type: GET_ALL_SALONS,
});

export const getSalonForId = (salonId) => ({
  type: GET_SALON_FOR_ID,
  salonId,
});

export const setAllSalons = (salons) => ({
  type: SET_ALL_SALONS,
  salons,
});

export const deleteSalon = (salon) => ({
  type: DELETE_SALON,
  salon,
});

export const filterSalons = (searchValue, page) => ({
  type: FILTER_SALONS,
  searchValue,
  page,
});

/* #endregion */

/* #region Hairstylist mutations */

export const saveHairstylist = (hairstylist) => ({
  type: SAVE_HAIRSTYLIST,
  hairstylist,
});

export const saveHairstylistForSalon = (hairstylist, salonId, cb) => ({
  type: SAVE_HAIRSTYLIST_FOR_SALON,
  hairstylist,
  salonId,
  cb,
});

export const createHairstylist = (hairstylist) => ({
  type: CREATE_HAIRSTYLIST,
  hairstylist,
});

export const createHairstylistForSalon = (hairstylist, cb) => ({
  type: CREATE_HAIRSTYLIST_FOR_SALON,
  hairstylist,
  cb,
});

export const setHairstylists = (state) => ({
  type: SET_HAIRSTYLISTS,
  hairstylists: state.resultList,
  hairstylistPage: state.pageNumber,
  hairstylistTotalCount: state.totalCount,
});

export const getHairstylistsPaged = (searchValue, page, sort) => ({
  type: GET_HAIRSTYLISTS_PAGED,
  searchValue,
  page,
  sort,
});

export const getHairstylistsPagedForSalon = (
  searchValue,
  salonId,
  page,
  sort
) => ({
  type: GET_HAIRSTYLISTS_PAGED_FOR_SALON,
  searchValue,
  salonId,
  page,
  sort,
});

export const getHairstylistsForExport = (
  searchValue,
  totalCount,
  sort,
  cb
) => ({
  type: GET_HAIRSTYLISTS_FOR_EXPORT,
  searchValue,
  totalCount,
  sort,
  cb,
});

export const getAllHairstylists = () => ({
  type: GET_ALL_HAIRSTYLISTS,
});

export const getAllHairstylistsForDropdown = () => ({
  type: GET_ALL_HAIRSTYLISTS_DROPDOWN,
});

export const setAllHairstylists = (hairstylists) => ({
  type: SET_ALL_HAIRSTYLISTS,
  hairstylists,
});

export const deleteHairstylist = (hairstylist) => ({
  type: DELETE_HAIRSTYLIST,
  hairstylist,
});

export const deleteHairstylistForSalon = (hairstylist, salonId, cb) => ({
  type: DELETE_HAIRSTYLIST_FOR_SALON,
  hairstylist,
  salonId,
  cb,
});

export const filterHairstylists = (searchValue, page) => ({
  type: FILTER_HAIRSTYLISTS,
  searchValue,
  page,
});

/* #endregion */

/* #region Visit mutations */

export const saveVisit = (visit, cb) => ({
  type: SAVE_VISIT,
  visit,
  cb,
});

export const saveVisitFromServiceTypeList = (visit, cb) => ({
  type: SAVE_VISIT_FROM_SERVICE_TYPE_LIST,
  visit,
  cb,
});

export const createVisit = (visit) => ({
  type: CREATE_VISIT,
  visit,
});

export const createVisitFromServiceTypeList = (visit, cb) => ({
  type: CREATE_VISIT_FROM_SERVICE_TYPE_LIST,
  visit,
  cb,
});
export const setVisits = (state) => ({
  type: SET_VISITS,
  visits: state.resultList,
  page: state.pageNumber,
  totalCount: state.totalCount,
});

export const getVisitsPaged = (searchValue, page, sort) => ({
  type: GET_VISITS_PAGED,
  searchValue,
  page,
  sort,
});

export const getVisitsPagedForSalon = (searchValue, salonId, page, sort) => ({
  type: GET_VISITS_PAGED_FOR_SALON,
  searchValue,
  salonId,
  page,
  sort,
});

export const getVisitsForExport = (searchValue, totalCount, sort, cb) => ({
  type: GET_VISITS_FOR_EXPORT,
  searchValue,
  totalCount,
  sort,
  cb,
});

export const getAllVisits = () => ({
  type: GET_ALL_VISITS,
});

export const setAllVisits = (visits) => ({
  type: SET_ALL_VISITS,
  visits,
});

export const deleteVisit = (visit) => ({
  type: DELETE_VISIT,
  visit,
});

export const filterVisits = (searchValue, page) => ({
  type: FILTER_VISITS,
  searchValue,
  page,
});

/* #endregion */

/* #region ServiceType mutations */

export const saveServiceType = (serviceType) => ({
  type: SAVE_SERVICE_TYPE,
  serviceType,
});

export const createServiceType = (serviceType) => ({
  type: CREATE_SERVICE_TYPE,
  serviceType,
});

export const setServiceTypes = (state) => ({
  type: SET_SERVICE_TYPES,
  serviceTypes: state.resultList,
  serviceTypePage: state.pageNumber,
  serviceTypeTotalCount: state.totalCount,
});

export const getServiceTypesPaged = (searchValue, page, sort) => ({
  type: GET_SERVICE_TYPES_PAGED,
  searchValue,
  page,
  sort,
});

export const getServiceTypesPagedWithInactive = (searchValue, page, sort) => ({
  type: GET_SERVICE_TYPES_PAGED_WITH_INACTIVE,
  searchValue,
  page,
  sort,
});

export const getServiceTypesForExport = (
  searchValue,
  totalCount,
  sort,
  cb
) => ({
  type: GET_SERVICE_TYPES_FOR_EXPORT,
  searchValue,
  totalCount,
  sort,
  cb,
});

export const getAllServiceTypes = () => ({
  type: GET_ALL_SERVICE_TYPES,
});

export const getAllServiceTypesForDropdown = () => ({
  type: GET_ALL_SERVICE_TYPES_DROPDOWN,
});

export const setAllServiceTypes = (serviceTypes) => ({
  type: SET_ALL_SERVICE_TYPES,
  serviceTypes,
});

export const getAllServiceTypesForSalon = (salonId) => ({
  type: GET_ALL_SERVICE_TYPES_FOR_SALON,
  salonId,
});

export const setAllServiceTypesForSalon = (serviceTypes) => ({
  type: SET_ALL_SERVICE_TYPES_FOR_SALON,
  serviceTypes,
});

export const deleteServiceType = (serviceType) => ({
  type: DELETE_SERVICE_TYPE,
  serviceType,
});

export const activateServiceType = (serviceType) => ({
  type: ACTIVATE_SERVICE_TYPE,
  serviceType,
});

export const filterServiceTypes = (searchValue, page) => ({
  type: FILTER_SERVICE_TYPES,
  searchValue,
  page,
});

/* #endregion */

/* #region SalonCustomer mutations */

export const saveSalonCustomer = (salonCustomer) => ({
  type: SAVE_SALON_CUSTOMER,
  salonCustomer,
});

export const createSalonCustomer = (salonCustomer) => ({
  type: CREATE_SALON_CUSTOMER,
  salonCustomer,
});

export const setSalonCustomers = (state) => ({
  type: SET_SALON_CUSTOMERS,
  salonCustomers: state.resultList,
  salonCustomerPage: state.pageNumber,
  salonCustomerTotalCount: state.totalCount,
});

export const getSalonCustomersPaged = (searchValue, page, sort) => ({
  type: GET_SALON_CUSTOMERS_PAGED,
  searchValue,
  page,
  sort,
});

export const getSalonCustomersForExport = (
  searchValue,
  totalCount,
  sort,
  cb
) => ({
  type: GET_SALON_CUSTOMERS_FOR_EXPORT,
  searchValue,
  totalCount,
  sort,
  cb,
});

export const getAllSalonCustomers = () => ({
  type: GET_ALL_SALON_CUSTOMERS,
});

export const getAllSalonCustomersForSalon = () => ({
  type: GET_ALL_SALON_CUSTOMERS_FOR_SALON,
});

export const setAllSalonCustomers = (salonCustomers) => ({
  type: SET_ALL_SALON_CUSTOMERS,
  salonCustomers,
});

export const deleteSalonCustomer = (salonCustomer) => ({
  type: DELETE_SALON_CUSTOMER,
  salonCustomer,
});

export const filterSalonCustomers = (searchValue, page) => ({
  type: FILTER_SALON_CUSTOMERS,
  searchValue,
  page,
});

/* #endregion */

/* #region SalonHairstylist mutations */

export const saveSalonHairstylist = (salonHairstylist) => ({
  type: SAVE_SALON_HAIRSTYLIST,
  salonHairstylist,
});

export const createSalonHairstylist = (salonHairstylist) => ({
  type: CREATE_SALON_HAIRSTYLIST,
  salonHairstylist,
});

export const setSalonHairstylists = (state) => ({
  type: SET_SALON_HAIRSTYLISTS,
  salonHairstylists: state.resultList,
  salonHairstylistPage: state.pageNumber,
  salonHairstylistTotalCount: state.totalCount,
});

export const getSalonHairstylistsPaged = (searchValue, page, sort) => ({
  type: GET_SALON_HAIRSTYLISTS_PAGED,
  searchValue,
  page,
  sort,
});

export const getSalonHairstylistsForExport = (
  searchValue,
  totalCount,
  sort,
  cb
) => ({
  type: GET_SALON_HAIRSTYLISTS_FOR_EXPORT,
  searchValue,
  totalCount,
  sort,
  cb,
});

export const getAllSalonHairstylists = () => ({
  type: GET_ALL_SALON_HAIRSTYLISTS,
});

export const getAllSalonHairstylistsForDropdown = () => ({
  type: GET_ALL_SALON_HAIRSTYLISTS_DROPDOWN,
});

export const setAllSalonHairstylists = (salonHairstylists) => ({
  type: SET_ALL_SALON_HAIRSTYLISTS,
  salonHairstylists,
});

export const getAllSalonHairstylistsForHairstylist = (
  hairstylistId,
  salonId
) => ({
  type: GET_ALL_SALON_HAIRSTYLISTS_FOR_HAIRSTYLIST,
  hairstylistId,
  salonId,
});

export const setAllSalonHairstylistsForHairstylist = (salonHairstylists) => ({
  type: SET_ALL_SALON_HAIRSTYLISTS_FOR_HAIRSTYLIST,
  salonHairstylists,
});

export const deleteSalonHairstylist = (salonHairstylist) => ({
  type: DELETE_SALON_HAIRSTYLIST,
  salonHairstylist,
});

export const deleteSalonHairstylistReplaceHairstylist = (
  salonHairstylist,
  hairstylistId
) => ({
  type: DELETE_SALON_HAIRSTYLIST_REPLACE_HAIRSTYLIST,
  salonHairstylist,
  hairstylistId,
});

export const filterSalonHairstylists = (searchValue, page) => ({
  type: FILTER_SALON_HAIRSTYLISTS,
  searchValue,
  page,
});

/* #endregion */

/* #region HairstylistServiceType mutations */

export const saveHairstylistServiceType = (hairstylistServiceType) => ({
  type: SAVE_HAIRSTYLIST_SERVICE_TYPE,
  hairstylistServiceType,
});

export const createHairstylistServiceType = (hairstylistServiceType, cb) => ({
  type: CREATE_HAIRSTYLIST_SERVICE_TYPE,
  hairstylistServiceType,
  cb,
});

export const setHairstylistServiceTypes = (state) => ({
  type: SET_HAIRSTYLIST_SERVICE_TYPES,
  hairstylistServiceTypes: state.resultList,
  hairstylistServiceTypePage: state.pageNumber,
  hairstylistServiceTypeTotalCount: state.totalCount,
});

export const getHairstylistServiceTypesPaged = (searchValue, page, sort) => ({
  type: GET_HAIRSTYLIST_SERVICE_TYPES_PAGED,
  searchValue,
  page,
  sort,
});

export const getHairstylistServiceTypesForExport = (
  searchValue,
  totalCount,
  sort,
  cb
) => ({
  type: GET_HAIRSTYLIST_SERVICE_TYPES_FOR_EXPORT,
  searchValue,
  totalCount,
  sort,
  cb,
});

export const getAllHairstylistServiceTypes = () => ({
  type: GET_ALL_HAIRSTYLIST_SERVICE_TYPES,
});

export const getAllHairstylistServiceTypesForDropdown = () => ({
  type: GET_ALL_HAIRSTYLIST_SERVICE_TYPES_DROPDOWN,
});

export const setAllHairstylistServiceTypes = (hairstylistServiceTypes) => ({
  type: SET_ALL_HAIRSTYLIST_SERVICE_TYPES,
  hairstylistServiceTypes,
});

export const getAllHairstylistServiceTypesForHairstylist = (
  hairstylistId,
  salonId
) => ({
  type: GET_ALL_HAIRSTYLIST_SERVICE_TYPES_FOR_HAIRSTYLIST,
  hairstylistId,
  salonId,
});

export const setAllHairstylistServiceTypesForHairstylist = (
  hairstylistServiceTypes
) => ({
  type: SET_ALL_HAIRSTYLIST_SERVICE_TYPES_FOR_HAIRSTYLIST,
  hairstylistServiceTypes,
});

export const deleteHairstylistServiceType = (hairstylistServiceType) => ({
  type: DELETE_HAIRSTYLIST_SERVICE_TYPE,
  hairstylistServiceType,
});

export const filterHairstylistServiceTypes = (searchValue, page) => ({
  type: FILTER_HAIRSTYLIST_SERVICE_TYPES,
  searchValue,
  page,
});

export const getAvailableServiceTypes = (salonId, hairstylistId) => ({
  type: GET_AVAILABLE_SERVICE_TYPES,
  salonId,
  hairstylistId,
});

export const setAvailableServiceTypes = (availableServiceTypes) => ({
  type: SET_AVAILABLE_SERVICE_TYPES,
  availableServiceTypes,
});

/* #endregion */

/* #region VisitServiceType mutations */

export const saveVisitServiceType = (visitServiceType) => ({
  type: SAVE_VISIT_SERVICE_TYPE,
  visitServiceType,
});

export const createVisitServiceType = (visitServiceType) => ({
  type: CREATE_VISIT_SERVICE_TYPE,
  visitServiceType,
});

export const setVisitServiceTypes = (state) => ({
  type: SET_VISIT_SERVICE_TYPES,
  visitServiceTypes: state.resultList,
  visitServiceTypePage: state.pageNumber,
  visitServiceTypeTotalCount: state.totalCount,
});

export const getVisitServiceTypesPaged = (searchValue, page, sort) => ({
  type: GET_VISIT_SERVICE_TYPES_PAGED,
  searchValue,
  page,
  sort,
});

export const getVisitServiceTypesForExport = (
  searchValue,
  totalCount,
  sort,
  cb
) => ({
  type: GET_VISIT_SERVICE_TYPES_FOR_EXPORT,
  searchValue,
  totalCount,
  sort,
  cb,
});

export const getAllVisitServiceTypes = () => ({
  type: GET_ALL_VISIT_SERVICE_TYPES,
});

export const getAllVisitServiceTypesForDropdown = () => ({
  type: GET_ALL_VISIT_SERVICE_TYPES_DROPDOWN,
});

export const setAllVisitServiceTypes = (visitServiceTypes) => ({
  type: SET_ALL_VISIT_SERVICE_TYPES,
  visitServiceTypes,
});

export const getAllVisitServiceTypesForVisit = (visitId, salonId) => ({
  type: GET_ALL_VISIT_SERVICE_TYPES_FOR_VISIT,
  visitId,
  salonId,
});

export const setAllVisitServiceTypesForVisit = (visitServiceTypes) => ({
  type: SET_ALL_VISIT_SERVICE_TYPES_FOR_VISIT,
  visitServiceTypes,
});

export const deleteVisitServiceType = (visitServiceType) => ({
  type: DELETE_VISIT_SERVICE_TYPE,
  visitServiceType,
});

export const filterVisitServiceTypes = (searchValue, page) => ({
  type: FILTER_VISIT_SERVICE_TYPES,
  searchValue,
  page,
});

/* #endregion */

/* #region Hairstylist Working Time */

export const saveHairstylistWorkingTime = (
  workingTimeId,
  salonHairstylistId,
  startDate,
  endDate,
  checkboxInputs
) => ({
  type: SAVE_HAIRSTYLIST_WORKING_TIME,
  workingTimeId,
  salonHairstylistId,
  startDate,
  endDate,
  checkboxInputs,
});

export const createHairstylistWorkingTime = (
  salonHairstylistId,
  startDate,
  endDate,
  checkboxInputs
) => ({
  type: CREATE_HAIRSTYLIST_WORKING_TIME,
  salonHairstylistId,
  startDate,
  endDate,
  checkboxInputs,
});

export const getHairstylistWorkingTimePaged = (
  salonHairstylistId,
  page,
  sort
) => ({
  type: GET_HAIRSTYLIST_WORKING_TIMES_PAGED,
  salonHairstylistId,
  page,
  sort,
});

export const setHairstylistWorkingTimes = (state) => ({
  type: SET_HAIRSTYLIST_WORKING_TIMES,
  hairstylistWorkingTimes: state.resultList,
  hairstylistWorkingTimePage: state.pageNumber,
  hairstylistWorkingTimeTotalCount: state.totalCount,
});

export const getHairstylistWorkingTimePagedConsolidated = (
  salonHairstylistId,
  page,
  sort
) => ({
  type: GET_HAIRSTYLIST_WORKING_TIMES_PAGED_CONSOLIDATED,
  salonHairstylistId,
  page,
  sort,
});

export const setHairstylistWorkingTimesConsolidated = (state) => ({
  type: SET_HAIRSTYLIST_WORKING_TIMES_CONSOLIDATED,
  hairstylistWorkingTimesConsolidated: state.resultList,
  hairstylistWorkingTimePageConsolidated: state.pageNumber,
  hairstylistWorkingTimeTotalCountConsolidated: state.totalCount,
});

export const getAllHairstylistWorkingTimesDates = (
  salonHairstylistId,
  page,
  sort
) => ({
  type: GET_ALL_HAIRSTYLIST_WORKING_TIMES_DATES,
  salonHairstylistId,
  page,
  sort,
});

export const setAllHairstylistWorkingTimesDates = (state) => ({
  type: SET_ALL_HAIRSTYLIST_WORKING_TIMES_DATES,
  hairstylistAllWorkingTimesDates: state.resultList,
});

export const getHairstylistWorkingTimeIsDateCreated = (
  salonHairstylistId,
  startDate
) => ({
  type: GET_HAIRSTYLIST_WORKING_TIMES_IS_DATE_CREATED,
  salonHairstylistId,
  startDate,
});

export const setHairstylistWorkingTimeIsDateCreated = (
  hairstylistWorkingTimesIsDateCreated
) => ({
  type: SET_HAIRSTYLIST_WORKING_TIMES_IS_DATE_CREATED,
  hairstylistWorkingTimesIsDateCreated,
});

export const getAllHairstylistWorkingTimes = (salonHairstylistId) => ({
  type: GET_ALL_HAIRSTYLIST_WORKING_TIMES,
  salonHairstylistId,
});

export const setAllHairstylistWorkingTimes = (hairstylistWorkingTimes) => ({
  type: SET_ALL_HAIRSTYLIST_WORKING_TIMES,
  hairstylistWorkingTimes,
});

export const deleteHairstylistWorkingTime = (
  workingTimeId,
  salonHairstylistId
) => ({
  type: DELETE_HAIRSTYLIST_WORKING_TIME,
  workingTimeId,
  salonHairstylistId,
});

export const deleteHairstylistWorkingTimeConsolidated = (
  workingTimeConsolidated
) => ({
  type: DELETE_HAIRSTYLIST_WORKING_TIME_CONSOLIDATED,
  workingTimeConsolidated,
});

/* #region */

/* #region Salon Working Time */

export const saveSalonWorkingTime = (
  workingTimeId,
  salonId,
  startDate,
  endDate,
  checkboxInputs
) => ({
  type: SAVE_SALON_WORKING_TIME,
  workingTimeId,
  salonId,
  startDate,
  endDate,
  checkboxInputs,
});

export const createSalonWorkingTime = (
  salonId,
  startDate,
  endDate,
  checkboxInputs,
  cb
) => ({
  type: CREATE_SALON_WORKING_TIME,
  salonId,
  startDate,
  endDate,
  checkboxInputs,
  cb,
});

export const createSalonWorkingTimeAuto = (salonId, workingTimeList, cb) => ({
  type: CREATE_SALON_WORKING_TIME_AUTO,
  salonId,
  workingTimeList,
  cb,
});

export const deleteRange = (
  salonId,
  deleteRangeStartDate,
  deleteRangeEndDate,
  cb
) => ({
  type: DELETE_RANGE,
  salonId,
  deleteRangeStartDate,
  deleteRangeEndDate,
  cb,
});

export const setSalonWorkingTimes = (state) => ({
  type: SET_SALON_WORKING_TIMES,
  salonWorkingTimes: state.resultList,
  salonWorkingTimePage: state.pageNumber,
  salonWorkingTimeTotalCount: state.totalCount,
});

export const setSalonWorkingTimesConsolidated = (state) => ({
  type: SET_SALON_WORKING_TIMES_CONSOLIDATED,
  salonWorkingTimesConsolidated: state.resultList,
  salonWorkingTimePageConsolidated: state.pageNumber,
  salonWorkingTimeTotalCountConsolidated: state.totalCount,
});

export const getSalonWorkingTimePaged = (salonId, page, sort) => ({
  type: GET_SALON_WORKING_TIMES_PAGED,
  salonId,
  page,
  sort,
});

export const getSalonWorkingTimePagedConsolidated = (salonId, page, sort) => ({
  type: GET_SALON_WORKING_TIMES_PAGED_CONSOLIDATED,
  salonId,
  page,
  sort,
});

export const getSalonWorkingTimeIsDateCreated = (salonId, startDate) => ({
  type: GET_SALON_WORKING_TIMES_IS_DATE_CREATED,
  salonId,
  startDate,
});

export const setSalonWorkingTimeIsDateCreated = (
  salonWorkingTimesIsDateCreated
) => ({
  type: SET_SALON_WORKING_TIMES_IS_DATE_CREATED,
  salonWorkingTimesIsDateCreated,
});

export const getAllSalonWorkingTimes = (salonId) => ({
  type: GET_ALL_SALON_WORKING_TIMES,
  salonId,
});

export const setAllSalonWorkingTimes = (salonWorkingTimes) => ({
  type: SET_ALL_SALON_WORKING_TIMES,
  salonWorkingTimes,
});

export const getSalonWorkingTimeDateList = (salonId) => ({
  type: GET_SALON_WORKING_TIME_DATE_LIST,
  salonId,
});

export const setSalonWorkingTimeDateList = (salonWorkingTimeDateList) => ({
  type: SET_SALON_WORKING_TIME_DATE_LIST,
  salonWorkingTimeDateList,
});

export const deleteSalonWorkingTime = (workingTimeId, salonId) => ({
  type: DELETE_SALON_WORKING_TIME,
  workingTimeId,
  salonId,
});

export const deleteSalonWorkingTimeConsolidated = (
  workingTimeConsolidated
) => ({
  type: DELETE_SALON_WORKING_TIME_CONSOLIDATED,
  workingTimeConsolidated,
});

/* #region */

/* #region Generalization mutations */

export const saveGeneralization = (generalization) => ({
  type: SAVE_GENERALIZATION,
  generalization,
});

export const createGeneralization = (generalization) => ({
  type: CREATE_GENERALIZATION,
  generalization,
});

export const setGeneralizations = (state) => ({
  type: SET_GENERALIZATIONS,
  generalizations: state.resultList,
  page: state.pageNumber,
  totalCount: state.totalCount,
});

export const getGeneralizationsPaged = (searchValue, page, sort) => ({
  type: GET_GENERALIZATIONS_PAGED,
  searchValue,
  page,
  sort,
});

export const getGeneralizationsForExport = (
  searchValue,
  totalCount,
  sort,
  cb
) => ({
  type: GET_GENERALIZATIONS_FOR_EXPORT,
  searchValue,
  totalCount,
  sort,
  cb,
});

export const getAllGeneralizations = () => ({
  type: GET_ALL_GENERALIZATIONS,
});

export const setAllGeneralizations = (generalizations) => ({
  type: SET_ALL_GENERALIZATIONS,
  generalizations,
});

export const deleteGeneralization = (generalization) => ({
  type: DELETE_GENERALIZATION,
  generalization,
});

export const filterGeneralizations = (searchValue, page) => ({
  type: FILTER_GENERALIZATIONS,
  searchValue,
  page,
});

/* #endregion */

/* #region Images mutations */

export const saveImages = (images) => ({
  type: SAVE_IMAGES,
  images,
});

export const createImages = (images) => ({
  type: CREATE_IMAGES,
  images,
});

export const setImages = (state) => ({
  type: SET_IMAGES,
  images: state.resultList,
  page: state.pageNumber,
  totalCount: state.totalCount,
});

export const getImagesPaged = (searchValue, page, sort) => ({
  type: GET_IMAGES_PAGED,
  searchValue,
  page,
  sort,
});

export const getImagesForExport = (
  searchValue,
  totalCount,
  sort,
  cb
) => ({
  type: GET_IMAGES_FOR_EXPORT,
  searchValue,
  totalCount,
  sort,
  cb,
});

export const getAllImages = () => ({
  type: GET_ALL_IMAGES,
});

export const setAllImages = (images) => ({
  type: SET_ALL_IMAGES,
  images,
});

export const deleteImages = (images) => ({
  type: DELETE_IMAGES,
  images,
});

export const filterImages = (searchValue, page) => ({
  type: FILTER_IMAGES,
  searchValue,
  page,
});

/* #endregion */

/* #region Generalization Translation mutations */

export const saveGeneralizationTranslation = (generalizationTranslation) => ({
  type: SAVE_GENERALIZATION_TRANSLATION,
  generalizationTranslation,
});

export const createGeneralizationTranslation = (generalizationTranslation) => ({
  type: CREATE_GENERALIZATION_TRANSLATION,
  generalizationTranslation,
});

export const setGeneralizationTranslations = (state) => ({
  type: SET_GENERALIZATION_TRANSLATIONS,
  generalizationTranslations: state.resultList,
  page: state.pageNumber,
  totalCount: state.totalCount,
});

export const getGeneralizationTranslationsPaged = (
  searchValue,
  page,
  sort
) => ({
  type: GET_GENERALIZATION_TRANSLATIONS_PAGED,
  searchValue,
  page,
  sort,
});

export const getGeneralizationTranslationsPagedForGeneralization = (
  filterObject,
  page,
  sort
) => ({
  type: GET_GENERALIZATION_TRANSLATIONS_PAGED_FOR_GENERALIZATION,
  filterObject,
  page,
  sort,
});

export const getAllGeneralizationTranslationsForGeneralization = (
  filterObject
) => ({
  type: GET_ALL_GENERALIZATION_TRANSLATIONS_FOR_GENERALIZATION,
  filterObject,
  totalCount: null,
  page: null,
  sort: [],
});

export const getGeneralizationTranslationsForExport = (
  searchValue,
  totalCount,
  sort,
  cb
) => ({
  type: GET_GENERALIZATION_TRANSLATIONS_FOR_EXPORT,
  searchValue,
  totalCount,
  sort,
  cb,
});

export const getAllGeneralizationTranslations = () => ({
  type: GET_ALL_GENERALIZATION_TRANSLATIONS,
});

export const setAllGeneralizationTranslations = (
  generalizationTranslations
) => ({
  type: SET_ALL_GENERALIZATION_TRANSLATIONS,
  generalizationTranslations,
});

export const deleteGeneralizationTranslation = (generalizationTranslation) => ({
  type: DELETE_GENERALIZATION_TRANSLATION,
  generalizationTranslation,
});

export const filterGeneralizationTranslations = (searchValue, page) => ({
  type: FILTER_GENERALIZATION_TRANSLATIONS,
  searchValue,
  page,
});

/* #endregion */

/* #region Images Translation mutations */

export const saveGImagesTranslation = (imagesTranslation) => ({
  type: SAVE_IMAGES_TRANSLATION,
  imagesTranslation,
});

export const createImagesTranslation = (imagesTranslation) => ({
  type: CREATE_IMAGES_TRANSLATION,
  imagesTranslation,
});

export const setImagesTranslations = (state) => ({
  type: SET_IMAGES_TRANSLATIONS,
  imagesTranslations: state.resultList,
  page: state.pageNumber,
  totalCount: state.totalCount,
});

export const getImagesTranslationsPaged = (
  searchValue,
  page,
  sort
) => ({
  type: GET_IMAGES_TRANSLATIONS_PAGED,
  searchValue,
  page,
  sort,
});

export const getImagesTranslationsPagedForImages = (
  filterObject,
  page,
  sort
) => ({
  type: GET_IMAGES_TRANSLATIONS_PAGED_FOR_IMAGES,
  filterObject,
  page,
  sort,
});

export const getAllImagesTranslationsForImages = (
  filterObject
) => ({
  type: GET_ALL_IMAGES_TRANSLATIONS_FOR_IMAGES,
  filterObject,
  totalCount: null,
  page: null,
  sort: [],
});

export const getImagesTranslationsForExport = (
  searchValue,
  totalCount,
  sort,
  cb
) => ({
  type: GET_IMAGES_TRANSLATIONS_FOR_EXPORT,
  searchValue,
  totalCount,
  sort,
  cb,
});

export const getAllImagesTranslations = () => ({
  type: GET_ALL_IMAGES_TRANSLATIONS,
});

export const setAllImagesTranslations = (
  imagesTranslations
) => ({
  type: SET_ALL_IMAGES_TRANSLATIONS,
  imagesTranslations,
});

export const deleteImagesTranslation = (imagesTranslation) => ({
  type: DELETE_IMAGES_TRANSLATION,
  imagesTranslation,
});

export const filterImagesTranslations = (searchValue, page) => ({
  type: FILTER_IMAGES_TRANSLATIONS,
  searchValue,
  page,
});

/* #endregion */

/* #region Free Visit Time Slot mutations */

export const getAllFreeVisitTimeSlots = (freeVisitTimeSlotRequest) => ({
  type: GET_ALL_FREE_VISIT_TIME_SLOTS,
  freeVisitTimeSlotRequest,
});

export const getAllFreeVisitTimeSlotsV2 = (freeVisitTimeSlotRequest) => ({
  type: GET_ALL_FREE_VISIT_TIME_SLOTS_V2,
  freeVisitTimeSlotRequest,
});

export const setAllFreeVisitTimeSlots = (freeVisitTimeSlots) => ({
  type: SET_ALL_FREE_VISIT_TIME_SLOTS,
  freeVisitTimeSlots,
});

/* #endregion */

/* #region Free Salon Time Slot mutations */

export const getAllFreeSalonTimeSlots = (freeSalonTimeSlotRequest) => ({
  type: GET_ALL_FREE_SALON_TIME_SLOTS,
  freeSalonTimeSlotRequest,
});

export const setAllFreeSalonTimeSlots = (freeSalonTimeSlots) => ({
  type: SET_ALL_FREE_SALON_TIME_SLOTS,
  freeSalonTimeSlots,
});

/* #endregion */

/* #region Free Hairstylist Time Slot mutations */

export const getAllFreeHairstylistTimeSlots = (
  freeHairstylistTimeSlotRequest
) => ({
  type: GET_ALL_FREE_HAIRSTYLIST_TIME_SLOTS,
  freeHairstylistTimeSlotRequest,
});

export const setAllFreeHairstylistTimeSlots = (freeHairstylistTimeSlots) => ({
  type: SET_ALL_FREE_HAIRSTYLIST_TIME_SLOTS,
  freeHairstylistTimeSlots,
});

/* #endregion */

/* #region Dasboard Time Slot mutations */

export const getDashboardData = (requestData) => ({
  type: GET_DASHBOARD_DATA,
  requestData,
});

export const setDashboardData = (data) => ({
  type: SET_DASHBOARD_DATA,
  data,
});
export const getDashboardDataDaily = (requestData) => ({
  type: GET_DASHBOARD_DATA_DAILY,
  requestData,
});

export const setDashboardDataDaily = (data) => ({
  type: SET_DASHBOARD_DATA_DAILY,
  data,
});
/* #endregion */

export const setLoaded = (loaded) => ({
  type: SET_LOADED,
  loaded,
});
export const setDashboardLoaded = (dashboardLoaded) => ({
  type: SET_DASHBOARD_LOADED,
  dashboardLoaded,
});

/* #region Path mutations */

export const setPath = (path) => ({
  type: SET_PATH,
  path,
});
/* #endregion */
