import i18n from "i18next";
//import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// import HttpApi from "i18next-http-backend";
// import * as backendOptions from "./i18next-backend.config";

import translationEng from "./locales/en-US/translation.json";
import translationSrb from "./locales/sr-Latn/translation.json";

i18n
  // .use(HttpApi)
  //.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // backend: {
    //   loadPath: "/src/app/locales/{{lng}}/{{ns}}.json",
    // },

    // we init with resources
    resources: {
      "en-US": {
        translation: translationEng,
      },
      "sr-Latn": {
        translation: translationSrb,
      },
    },
    lng: "en-US",
    fallbackLng: "en-US",
    debug: true,

    // have a common namespace used around the full app
    ns: "translation",
    defaultNS: "translation",

    // keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ",",
    },

    react: {
      wait: true,
      bindI18n: "languageChanged loaded",
      bindI18nStore: "added removed",
      nsMode: "default",
      useSuspense: true,
    },
  });

export default i18n;
