import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../../store/mutations";
import { withTranslation } from "react-i18next";

class SalonServiceTypeEdit extends Component {
  state = {
    name: this.props.selectedServiceType.serviceTypeName || "",
    price: this.props.selectedServiceType.serviceTypePrice || "",
    duration: this.props.selectedServiceType.serviceTypeDuration || "",
    description: this.props.selectedServiceType.description || "",
  };
  handlePriceChange(event) {
    this.setState({ price: event.target.value });
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handleDurationChange(event) {
    this.setState({ duration: event.target.value });
  }

  handleDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }
  render() {
    if (!this.props.enableEdit) {
      return null;
    }

    // The gray background
    const backdropStyle = {
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "rgba(0,0,0,0.3)",
      padding: 50,
    };

    // The modal "window"
    const modalStyle = {
      display: `${this.props.enableEdit ? "block" : "none"}`,
    };

    return (
      // <div className="backdrop" style={backdropStyle} tabIndex="-1">
      // <div style={backdropStyle} tabIndex="-1">
      <div
        className={`modal fade ${this.props.enableEdit ? "show" : ""}`}
        // className="modal fade" data-backdrop="static" data-keyboard="false"
        id="editSalonServiceTypeModal"
        style={modalStyle}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editSalonServiceTypeModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editSalonServiceTypeModalLabel">
                <i className="fal fa-user fa-lg c-purple" />{" "}
                {helper.i18nMultiFallbackKey(
                  "salon.salonServiceTypeEdit.editSalonServiceTypeModal.title",
                  "Edit Shop Service Type"
                )}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.props.setEnableEdit(false);
                }}
              >
                <i className="fal fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <form className="form needs-validation" noValidate>
                <input type="hidden" value="something" />
                <div className="form-group">
                  <label htmlFor="editSalonServiceTypeModalName">
                    {this.props.t(
                      "salon.salonServiceTypeEdit.editSalonServiceTypeModal.nameLabel",
                      "Name"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="editSalonServiceTypeModalName"
                    autoComplete="new-username"
                    placeholder={this.props.t(
                      "salon.salonServiceTypeEdit.editSalonServiceTypeModal.namePlaceholder",
                      "Please enter service type name"
                    )}
                    value={this.state.name}
                    onChange={(event) => this.handleNameChange(event)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="editSalonServiceTypeModalPrice">
                    {this.props.t(
                      "salon.salonServiceTypeEdit.editSalonServiceTypeModal.priceLabel",
                      "Price"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="editSalonServiceTypeModalPrice"
                    autoComplete="new-username"
                    placeholder={this.props.t(
                      "salon.salonServiceTypeEdit.editSalonServiceTypeModal.pricePlaceholder",
                      "Please enter service type price"
                    )}
                    value={this.state.price}
                    onChange={(event) => this.handlePriceChange(event)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="editSalonServiceTypeModalDuration">
                    {this.props.t(
                      "salon.salonServiceTypeEdit.editSalonServiceTypeModal.durationLabel",
                      "Duration"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="editSalonServiceTypeModalDuration"
                    autoComplete="new-username"
                    placeholder={this.props.t(
                      "salon.salonServiceTypeEdit.editSalonServiceTypeModal.durationPlaceholder",
                      "Please enter service type duration"
                    )}
                    value={this.state.duration}
                    onChange={(event) => this.handleDurationChange(event)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="editSalonServiceTypeModalDescription">
                    {this.props.t(
                      "salon.salonServiceTypeEdit.editSalonServiceTypeModal.descriptionLabel",
                      "Description"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="editSalonServiceTypeModalDescription"
                    autoComplete="new-username"
                    placeholder={this.props.t(
                      "salon.salonServiceTypeEdit.editSalonServiceTypeModal.descriptionPlaceholder",
                      "Please enter service type description"
                    )}
                    value={this.state.description}
                    onChange={(event) => this.handleDescriptionChange(event)}
                    required
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger ico-l"
                data-dismiss="modal"
                onClick={() => {
                  this.props.setEnableEdit(false);
                }}
              >
                <i className="fal fa-ban" />{" "}
                {this.props.t("common.cancel", "Cancel")}
              </button>
              <button
                type="button"
                className="btn btn-outline-success ico-l"
                data-dismiss="modal"
                onClick={() => {
                  let serviceTypeForUpdate = {
                    ServiceTypeId: this.props.selectedServiceType.serviceTypeId,
                    ServiceTypeName: this.state.name,
                    ServiceTypePrice: this.state.price,
                    ServiceTypeDuration: this.state.duration,
                    Description: this.state.description,
                  };

                  this.props.saveServiceType(serviceTypeForUpdate, () => {
                    this.props.clearParentState();
                    this.props.setEnableEdit(false);
                  });
                }}
              >
                <i className="fal fa-check" />{" "}
                {this.props.t("common.finish", "Finish")}
              </button>
            </div>
          </div>
        </div>
      </div>
      //</div>
    );
  }
}

const mapStateToPropsFreeSlots = (state, ownProps) => {
  return {
    selectedServiceType: ownProps.selectedServiceType,
    selectedSalon: ownProps.selectedSalon,
    clearParentState: ownProps.clearParentState,
    setEnableEdit: ownProps.setEnableEdit,
    enableEdit: ownProps.enableEdit,
  };
};

const mapDispatchToPropsFreeSlots = (dispatch) => ({
  saveServiceType(serviceTypeForUpdate, cb) {
    cb();
    dispatch(mutations.saveServiceType(serviceTypeForUpdate));
  },
});

export const ConnectedSalonServiceTypeEdit = withTranslation()(
  connect(
    mapStateToPropsFreeSlots,
    mapDispatchToPropsFreeSlots
  )(SalonServiceTypeEdit)
);
