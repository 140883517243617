import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import * as moment from "moment";
import * as Helper from "../HelperRepository";
import { withTranslation } from "react-i18next";

class FreeHairstylistSlotsPopupComponent extends Component {
  state = {
    checkboxInputs: [],
    initialLoad: true,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.initialLoad || props.freeHairstylistTimeSlots.length <= 0) {
      return null;
    }
    let a = [];
    if (state.checkboxInputs.length > 0) {
      if (
        moment(state.checkboxInputs[0].startTime).format("YYYY-MM-DD") !==
        moment(props.freeHairstylistTimeSlots[0].startTime).format("YYYY-MM-DD")
      ) {
        a = [...Helper.cloneDeep(props.freeHairstylistTimeSlots)];

        if (!props.isDateCreated && !props.editType) {
          a.forEach((item, index) => {
            if (item.timeSlotFree) {
              item.hairstylistTimeSlot = true;
            }
          });
        }
      } else {
        a = [...Helper.cloneDeep(state.checkboxInputs)];
      }
    }
    return {
      checkboxInputs: [...Helper.cloneDeep(a)],
    };
  }

  componentDidMount() {
    if (this.state.initialLoad) {
      this.setState({ initialLoad: false }, () => {
        this.reload();
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      checkboxInputs: [],
      initialLoad: true,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visitDate !== this.props.visitDate) {
      this.reload();
    }
    if (
      !Helper.isArrayEqual(
        prevProps.freeHairstylistTimeSlots,
        this.props.freeHairstylistTimeSlots
      )
    ) {
      let a = [...Helper.cloneDeep(this.props.freeHairstylistTimeSlots)];

      if (!this.props.isDateCreated && !this.props.editType) {
        a.forEach((item, index) => {
          if (item.timeSlotFree) {
            item.hairstylistTimeSlot = true;
          }
        });
      }
      this.initializeCheckboxItemArray(a, "componentDidUpdate");
      // this.setState({
      //   checkboxInputs: [...Helper.cloneDeep(a)]
      // });
    }
  }

  reload = () => {
    this.props.getAllFreeHairstylistTimeSlots(
      this.props.selectedSalonHairstylist.hairstylistId,
      this.props.selectedSalonHairstylist.salonId,
      this.props.visitDate
    );
  };

  slotType = (slotData, slotIndex) => {
    if (slotData.timeSlotFree && !slotData.hairstylistTimeSlot) {
      return "time-item free";
    }

    if (!slotData.timeSlotFree && !slotData.hairstylistTimeSlot) {
      return "time-item selected";
    }

    if (slotData.timeSlotFree && slotData.hairstylistTimeSlot) {
      return "time-item changed";
    }

    if (!slotData.timeSlotFree && slotData.hairstylistTimeSlot) {
      if (this.props.editType) {
        return "time-item changed";
      } else {
        return "time-item choosed";
      }
    }
  };

  initializeCheckboxItemArray = (inputs, caller) => {
    this.setState(
      {
        checkboxInputs: [...Helper.cloneDeep(inputs)],
      },
      () => {
        let a = [];
        this.state.checkboxInputs.forEach((item, index) => {
          if (this.props.editType) {
            a.push({
              value:
                inputs[index].hairstylistTimeSlot &&
                (inputs[index].timeSlotFree || !inputs[index].timeSlotFree),
              startTime: moment(item.startTime).format("HH:mm"),
              endTime: moment(item.endTime).format("HH:mm"),
            });
          } else {
            a.push({
              value:
                inputs[index].hairstylistTimeSlot && inputs[index].timeSlotFree,
              startTime: moment(item.startTime).format("HH:mm"),
              endTime: moment(item.endTime).format("HH:mm"),
            });
          }
        });
        this.props.setCheckboxInputs(a);
      }
    );
  };

  render() {
    return (
      <>
        <div className="form-group">
          <div className="date-time-picker modal-picker">
            <div className="date-time-content flex-wrap justify-content-around">
              <div className="legend-bar">
                <span className="reserved">
                  {this.props.t(
                    "hairstylist.FreeHairstylistSlotsPopupComponent.reserved",
                    "Reserved"
                  )}
                </span>
                <span className="free">
                  {this.props.t(
                    "hairstylist.FreeHairstylistSlotsPopupComponent.free",
                    "Free"
                  )}
                </span>
                <span className="choosed">
                  {this.props.t(
                    "hairstylist.FreeHairstylistSlotsPopupComponent.choosed",
                    "Choosed"
                  )}
                </span>
                <span className="changed">
                  {this.props.t(
                    "hairstylist.FreeHairstylistSlotsPopupComponent.changed",
                    "Changed"
                  )}
                </span>
              </div>
              {this.state.checkboxInputs.map((r, index) => {
                if (r.timeSlotFree && !r.hairstylistTimeSlot) {
                  return (
                    <div
                      className={this.slotType(r, index)}
                      key={r.timeIntervalGuid}
                      onClick={(event) => {
                        if (!this.props.isDateCreated) {
                          let inputs = [
                            ...Helper.cloneDeep(this.state.checkboxInputs),
                          ];
                          inputs[index].hairstylistTimeSlot = true;

                          this.initializeCheckboxItemArray(
                            inputs,
                            "render  if (!this.props.isDateCreated)"
                          );
                        }
                      }}
                      required
                    >
                      {moment(r.startTime).format("HH:mm")}
                    </div>
                  );
                }
                if (r.timeSlotFree && r.hairstylistTimeSlot) {
                  return (
                    <div
                      className={this.slotType(r, index)}
                      key={r.timeIntervalGuid}
                      onClick={(event) => {
                        let inputs = [
                          ...Helper.cloneDeep(this.state.checkboxInputs),
                        ];

                        inputs[index].hairstylistTimeSlot = false;

                        this.initializeCheckboxItemArray(
                          inputs,
                          "render if (r.timeSlotFree && r.hairstylistTimeSlot)"
                        );
                      }}
                      required
                    >
                      {moment(r.startTime).format("HH:mm")}
                    </div>
                  );
                }
                if (!r.timeSlotFree && r.hairstylistTimeSlot) {
                  return (
                    <div
                      className={this.slotType(r, index)}
                      key={r.timeIntervalGuid}
                      onClick={(event) => {
                        if (this.props.editType) {
                          let inputs = [
                            ...Helper.cloneDeep(this.state.checkboxInputs),
                          ];

                          inputs[index].hairstylistTimeSlot = false;
                          inputs[index].timeSlotFree = true;

                          this.initializeCheckboxItemArray(
                            inputs,
                            "render if (!r.timeSlotFree && r.hairstylistTimeSlot)"
                          );
                        }
                      }}
                      required
                    >
                      {moment(r.startTime).format("HH:mm")}
                    </div>
                  );
                }
                if (!r.timeSlotFree && !r.hairstylistTimeSlot) {
                  return (
                    <div
                      className={this.slotType(r, index)}
                      key={r.timeIntervalGuid}
                      required
                    >
                      {moment(r.startTime).format("HH:mm")}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToPropsFreeSlots = (state, ownProps) => {
  return {
    freeHairstylistTimeSlots: state.freeHairstylistTimeSlots,
    isDateCreated: state.hairstylistWorkingTimesIsDateCreated,
    visitDate: ownProps.visitDate,
    startTime: ownProps.startTime,
    endTime: ownProps.endTime,
    setCheckboxInputs: ownProps.setCheckboxInputs,
    selectedHairstylist: ownProps.selectedHairstylist,
    selectedSalonHairstylist: ownProps.selectedSalonHairstylist,
    editType: ownProps.editType,
  };
};

const mapDispatchToPropsFreeSlots = (dispatch) => ({
  getAllFreeHairstylistTimeSlots(hairstylistId, salonId, visitDate) {
    let freeHairstylistTimeSlotRequest = {
      HairstylistId: hairstylistId,
      SalonId: salonId,
      VisitDate: visitDate,
    };
    dispatch(
      mutations.getAllFreeHairstylistTimeSlots(freeHairstylistTimeSlotRequest)
    );
  },
});

export const ConnectedFreeHairstylistSlotsPopupComponent = withTranslation()(
  connect(
    mapStateToPropsFreeSlots,
    mapDispatchToPropsFreeSlots
  )(FreeHairstylistSlotsPopupComponent)
);
