import React, { useState, Component, useEffect } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import * as moment from "moment";
import * as Helper from "../HelperRepository";
import { useTranslation, withTranslation } from "react-i18next";

const SalonSlotComponent = ({
  editType,
  setParentCheckboxInputs,
  getAllFreeSalonTimeSlots,
  selectedSalon,
  visitDate,
  freeSalonTimeSlots,
  startTime,
  endTime,
}) => {
  const { t, i18n } = useTranslation();

  let [checkboxInputs, setCheckboxInputs] = useState([]);

  useEffect(() => {
    if (visitDate) {
      getAllFreeSalonTimeSlots(selectedSalon.salonId, visitDate);
    }
  }, [visitDate]);

  useEffect(() => {
    let a = [];
    if (freeSalonTimeSlots && freeSalonTimeSlots.length) {
      if (
        checkboxInputs.length === 0 ||
        moment(checkboxInputs[0].startTime).format("YYYY-MM-DD") !==
          moment(freeSalonTimeSlots[0].startTime).format("YYYY-MM-DD")
      ) {
        a = [...Helper.cloneDeep(freeSalonTimeSlots)];
      } else {
        a = [...Helper.cloneDeep(checkboxInputs)];
      }

      if (!editType) {
        a.map((item, index) => {
          if (item.timeSlotFree) {
            if (
              startTime >
                moment(item.startTime, "YYYY-MM-DDTHH:mm:ss").toDate() ||
              moment(item.endTime, "YYYY-MM-DDTHH:mm:ss").toDate() > endTime
            ) {
              item.salonTimeSlot = false;
              item.timeSlotFree = true;
            } else {
              item.salonTimeSlot = true;
              item.timeSlotFree = true;
            }
          }
        });
      }

      setCheckboxInputs([...Helper.cloneDeep(a)]);
    }
  }, [freeSalonTimeSlots]);

  const slotType = (slotData) => {
    if (slotData.timeSlotFree && !slotData.salonTimeSlot) {
      return "time-item free";
    }

    if (!slotData.timeSlotFree && !slotData.salonTimeSlot) {
      return "time-item selected";
    }

    if (slotData.timeSlotFree && slotData.salonTimeSlot) {
      return "time-item changed";
    }

    if (!slotData.timeSlotFree && slotData.salonTimeSlot) {
      if (editType) {
        return "time-item changed";
      } else {
        return "time-item choosed";
      }
    }
  };
  const initializeCheckboxItemArray = (inputs) => {
    setCheckboxInputs([...Helper.cloneDeep(inputs)]);
    let a = [];
    inputs.forEach((item, index) => {
      if (editType) {
        a.push({
          value: inputs[index].salonTimeSlot,
          startTime: moment(item.startTime).format("HH:mm"),
          endTime: moment(item.endTime).format("HH:mm"),
        });
      } else {
        a.push({
          value: inputs[index].salonTimeSlot && inputs[index].timeSlotFree,
          startTime: moment(item.startTime).format("HH:mm"),
          endTime: moment(item.endTime).format("HH:mm"),
        });
      }
    });
    setParentCheckboxInputs(a);
  };

  return (
    <>
      <div className="form-group">
        <div className="date-time-picker modal-picker">
          <div className="date-time-content flex-wrap justify-content-around">
            <div className="legend-bar">
              <span className="reserved">
                {t("salon.salonSlotComponent.reserved", "Reserved")}
              </span>
              <span className="free">
                {t("salon.salonSlotComponent.free", "Free")}
              </span>
              <span className="choosed">
                {t("salon.salonSlotComponent.choosed", "Choosed")}
              </span>
              <span className="changed">
                {t("salon.salonSlotComponent.changed", "Changed")}
              </span>
            </div>
            {checkboxInputs.map((r, index) => {
              return (
                <div
                  className={slotType(r)}
                  key={r.timeIntervalGuid}
                  onClick={(event) => {
                    let inputs = [...Helper.cloneDeep(checkboxInputs)];
                    if (r.timeSlotFree && !r.salonTimeSlot) {
                      inputs[index].salonTimeSlot = true;
                      initializeCheckboxItemArray(inputs);
                    }
                    if (r.timeSlotFree && r.salonTimeSlot) {
                      inputs[index].salonTimeSlot = false;
                      initializeCheckboxItemArray(inputs);
                    }
                    if (!r.timeSlotFree && r.salonTimeSlot) {
                      inputs[index].salonTimeSlot = false;
                      inputs[index].timeSlotFree = true;
                      initializeCheckboxItemArray(inputs);
                    }
                  }}
                  required
                >
                  {moment(r.startTime).format("HH:mm")}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    editType: ownProps.editType,
    setParentCheckboxInputs: ownProps.setParentCheckboxInputs,
    selectedSalon: ownProps.selectedSalon,
    visitDate: ownProps.visitDate,
    freeSalonTimeSlots: state.freeSalonTimeSlots,
    startTime: ownProps.startTime,
    endTime: ownProps.endTime,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getAllFreeSalonTimeSlots(salonId, visitDate) {
    let freeSalonTimeSlotRequest = {
      SalonId: salonId,
      VisitDate: moment(visitDate, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mm:ss"),
    };
    dispatch(mutations.getAllFreeSalonTimeSlots(freeSalonTimeSlotRequest));
  },
});

export const ConnectedSalonSlot = connect(
  mapStateToProps,
  mapDispatchToProps
)(SalonSlotComponent);
