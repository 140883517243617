import React from "react";
import { connect } from "react-redux";

export const LoadingSpinner = ({ loaded }) => {
  if (loaded) {
    return null;
  } else {
    return (
      <div className="lds-ring circle" id="load_ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }
};

const mapStateToProps = state => ({
  loaded: state.loaded && state.dashboardLoaded
});

const mapDispatchToProps = {};

export const LoadingSpinnerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadingSpinner);
