import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";

import * as mutations from "../../store/mutations";
import { ConnectedSalonList } from "./SalonList";
import { ConnectedSalonServiceTypeList } from "./SalonServiceType/SalonServiceTypeList";
//import { ConnectedFreeSalonSlotsPopupComponent } from "./FreeSalonSlotPopupComponent";
import { ConnectedSalonSlot } from "./SalonSlotComponent";
import { ConnectedSalonWorkingTimeConsolidatedList } from "./SalonWorkingTimeConsolidatedList";
import { ConnectedSalonCreateWorkingTimeAuto } from "./SalonCreateWorkingTimeAuto";
import { ConnectedSalonDeleteRange } from "./SalonDeleteRange";
import { ConnectedCreateSalonModal } from "./CreateSalonModal";
import { ConnectedCreateServiceTypeModal } from "./CreateServiceTypeModal";
import { ConnectedEditServiceTypeModal } from "./EditServiceTypeModal";
import { ConnectedEditSalonModal } from "./EditSalonModal";
import { ConnectedGeneralizationsDropDown } from "../Dropdowns/GeneralizationsDropDown";
import { ConnectedCategoryItemsDropDown } from "../Dropdowns/CategoryItemsDropDown";

import * as helper from "../HelperRepository";
import { useTranslation } from "react-i18next";
import RequiredFieldsComponent from "../RequiredFieldsComponent";

const SalonComponent = ({
  activeUser,
  isDateCreated,
  getActiveUser,
  createSalon,
  saveSalon,
  deleteSalon,
  createSalonWorkingTime,
  // createSalonWorkingTimeConsolidated,
  createSalonWorkingTimeAuto,
  saveSalonWorkingTime,
  // saveSalonWorkingTimeConsolidated,
  // deleteSalonWorkingTime,
  deleteSalonWorkingTimeConsolidated,
  getSalonWorkingTimeIsDateCreated,
  createServiceType,
  saveServiceType,
  deleteServiceType,
  activateServiceType,
  salons,
  getSalonForId,
  saveSalonForId,
  salonWorkingTimeDateList,
  setAllFreeSalonTimeSlots,
}) => {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [webAddress, setWebAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [email, setEmail] = useState("");
  const [salonId, setSalonId] = useState("");
  const [salonTimeSlot, setSalonTimeSlot] = useState("30");
  const [customerSchedulePeriod, setCustomerSchedulePeriod] = useState("14");
  const [description, setDescription] = useState("");
  const [workingTimeDescription, setWorkingTimeDescription] = useState("");

  const [selectedSalon, setSelectedSalon] = useState({});
  const [toGetFreeSlots, setToGetFreeSlots] = useState(false);
  const [selectedSalonServiceType, setSelectedSalonServiceType] = useState({});
  const [enableEdit, setEnableEdit] = useState(false);
  const [enableCreate, setEnableCreate] = useState(false);

  const [serviceTypeId, setServiceTypeId] = useState("");
  const [serviceTypePrice, setServiceTypePrice] = useState("");
  const [serviceTypeDuration, setServiceTypeDuration] = useState("");
  const [serviceTypeName, setServiceTypeName] = useState("");
  const [serviceTypeDescription, setServiceTypeDescription] = useState("");

  const [workingTimeId, setWorkingTimeId] = useState("");
  const [workingTimeConsolidated, setWorkingTimeConsolidated] = useState("");

  const [embeddedMapAddress, setEmbeddedMapAddress] = useState("");
  const [embeddedMapAddressHasError, setEmbeddedMapAddressHasError] = useState(
    false
  );
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const [generalizationId, setGeneralizationId] = useState("");

  const [startTime, setStartTime] = useState(() => {
    let s = moment().startOf("day").hours(9).toDate();
    return s;
  });
  const [endTime, setEndTime] = useState(() => {
    let e = moment().startOf("day").hours(21).toDate();
    return e;
  });
  const m = moment().format("YYYY-MM-DD");
  const [currentDate, setCurrentDate] = useState("");
  const [createWorkingTimeDate, setCreateWorkingTimeDate] = useState("");
  const [checkboxInputs, setCheckboxInputs] = useState(() => {
    let s = moment().startOf("day").hours(9).toDate();

    let e = moment().startOf("day").hours(21).toDate();

    let n = (e - s) / 1800000;
    let a = [];
    for (let index = 0; index < n; index++) {
      let d1 = new Date(s.getTime() + index * 30 * 60 * 1000);
      let d2 = new Date(s.getTime() + (index + 1) * 30 * 60 * 1000);
      a.push({
        value: true,
        startTime: moment(d1).format("HH:mm"),
        endTime: moment(d2).format("HH:mm"),
      });
    }
    return [...helper.cloneDeep(a)];
  });

  const [rolesMap, setRolesMap] = useState(() => {
    return helper.createMapObject();
  });

  const [isCreateWorkingTimeNew, setIsCreateWorkingtimeNew] = useState(false);
  const [isDeleteRange, setIsDeleteRange] = useState(false);
  const [isCreateWorkingTime, setIsCreateWorkingtime] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [nameValid, setNameValid] = useState(false);
  const [addressValid, setAddressValid] = useState(false);
  const [phoneValid, setPhoneValid] = useState(true);
  const [timeSlotValid, setTimeSlotValid] = useState(true);
  const [schedulePeriodValid, setSchedulePeriodValid] = useState(true);
  const [formValid, setFormValid] = useState(false);

  const [editNameValid, setEditNameValid] = useState(true);
  const [editAddressValid, setEditAddressValid] = useState(true);
  const [editPhoneValid, setEditPhoneValid] = useState(true);
  const [editTimeSlotValid, setEditTimeSlotValid] = useState(true);
  const [editSchedulePeriodValid, setEditSchedulePeriodValid] = useState(true);
  const [editFormValid, setEditFormValid] = useState(true);

  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const [serviceTypesFormErrors, setServiceTypesFormErrors] = useState({});
  const [serviceTypeNameValid, setServiceTypeNameValid] = useState(false);
  const [serviceTypePriceValid, setServiceTypePriceValid] = useState(false);
  const [serviceTypeDurationValid, setServiceTypeDurationValid] = useState(
    false
  );
  const [serviceTypesformValid, setServiceTypesFormValid] = useState(false);

  const [editServiceTypeNameValid, setEditServiceTypeNameValid] = useState(
    true
  );
  const [editServiceTypePriceValid, setEditServiceTypePriceValid] = useState(
    true
  );
  const [
    editServiceTypeDurationValid,
    setEditServiceTypeDurationValid,
  ] = useState(true);
  const [editServiceTypesformValid, setEditServiceTypesFormValid] = useState(
    true
  );

  /*************** Create working time *************/
  // const [workOnSat, setWorkOnSat] = useState(false);
  // const [workOnSun, setWorkOnSun] = useState(false);

  // const [createWorkingTimeStartTime, setCreateWorkingTimeStartTime] = useState(
  //   () => {
  //     let s = moment()
  //       .startOf("day")
  //       .hours(9)
  //       .toDate();
  //     return s;
  //   }
  // );
  // const [createWorkingTimeEndTime, setCreateWorkingTimeEndTime] = useState(
  //   () => {
  //     let e = moment()
  //       .startOf("day")
  //       .hours(21)
  //       .toDate();
  //     return e;
  //   }
  // );

  // const [
  //   createWorkingTimeStartTimeSat,
  //   setCreateWorkingTimeStartTimeSat
  // ] = useState(() => {
  //   let s = moment()
  //     .startOf("day")
  //     .hours(9)
  //     .toDate();
  //   return s;
  // });
  // const [
  //   createWorkingTimeEndTimeSat,
  //   setCreateWorkingTimeEndTimeSat
  // ] = useState(() => {
  //   let e = moment()
  //     .startOf("day")
  //     .hours(21)
  //     .toDate();
  //   return e;
  // });

  // const [
  //   createWorkingTimeStartTimeSun,
  //   setCreateWorkingTimeStartTimeSun
  // ] = useState(() => {
  //   let s = moment()
  //     .startOf("day")
  //     .hours(9)
  //     .toDate();
  //   return s;
  // });
  // const [
  //   createWorkingTimeEndTimeSun,
  //   setCreateWorkingTimeEndTimeSun
  // ] = useState(() => {
  //   let e = moment()
  //     .startOf("day")
  //     .hours(21)
  //     .toDate();
  //   return e;
  // });

  // const [
  //   createWorkingTimeSelectedYear,
  //   setCreateWorkingTimeSelectedYear
  // ] = useState(() => {
  //   let e = moment()
  //     .startOf("day")
  //     .toDate();
  //   return moment(e).format("YYYY");
  // });

  /*************** End Create working time *************/

  useEffect(() => {
    if (!activeUser || !activeUser.personId) {
      getActiveUser();
    }
    return () => {
      document.getElementsByTagName("html")[0].classList.remove("grid-view");
    };
  }, []);

  useEffect(() => {
    if (rolesMap.get("Admin")) {
      if (selectedSalon) {
        let salon = salons.find(
          (salon) => salon.salonId === selectedSalon.salonId
        );
        if (salon) {
          setSelectedSalon(salon);
        } else {
          setSelectedSalon({});
        }
      }
    } else {
      if (rolesMap.get("Salon admin")) {
        if (!helper.isEmpty(salons)) {
          setSelectedSalon(salons[0]);
        } else {
          setSelectedSalon({});
        }
      }
    }
  }, [salons]);

  useEffect(() => {
    if (!rolesMap.get("Admin")) {
      if (rolesMap.get("Salon admin")) {
        if (selectedSalon) {
          setSalonState(selectedSalon);
        }
      }
    }
  }, [selectedSalon]);

  useEffect(() => {
    if (!rolesMap.get("Admin")) {
      if (rolesMap.get("Salon admin")) {
        getSalonForId(activeUser.userRoles[0].externalId);
      }
    }
  }, [rolesMap]);

  useEffect(() => {
    setRolesMap(helper.mapForRole(activeUser));
  }, [activeUser]);

  useEffect(() => {
    if (salonWorkingTimeDateList.length > 0) {
      let newDate;
      let nowMoment = moment();
      let serverMoment = moment(salonWorkingTimeDateList).add(1, "day");

      if (nowMoment > serverMoment) {
        newDate = nowMoment.format("YYYY-MM-DD");
      } else {
        newDate = serverMoment.format("YYYY-MM-DD");
      }

      setCreateWorkingTimeDate(newDate);

      if (selectedSalon.salonId) {
        getSalonWorkingTimeIsDateCreated(selectedSalon.salonId, newDate);
      }
    }
  }, [salonWorkingTimeDateList]);

  useEffect(() => {
    if (createWorkingTimeDate) {
      let newStartTime = moment(createWorkingTimeDate, "YYYY-MM-DD")
        .hours(moment(startTime).format("HH"))
        .minutes(moment(startTime).format("mm"))
        .seconds(0)
        .milliseconds(0)
        .toDate();
      let newEndTime = moment(createWorkingTimeDate, "YYYY-MM-DD")
        .hours(moment(endTime).format("HH"))
        .minutes(moment(endTime).format("mm"))
        .seconds(0)
        .milliseconds(0)
        .toDate();

      setStartTime(newStartTime);
      setEndTime(newEndTime);
    }
  }, [createWorkingTimeDate]);

  const clearState = () => {
    setName("");
    setAddress("");
    setWebAddress("");
    setPhone("");
    setMobilePhone("");
    setEmail("");
    setSalonId("");
    setSalonTimeSlot("30");
    setCustomerSchedulePeriod("14");
    setWorkingTimeDescription("");
    setDescription("");
    setEmbeddedMapAddress("");
    setLongitude("");
    setLatitude("");
    setGeneralizationId("");
    setSelectedSalonServiceType({});
    setFormErrors({});
    setFormValid(false);
    setNameValid(false);
    setAddressValid(false);
    setPhoneValid(false);
    setTimeSlotValid(true);
    setSchedulePeriodValid(true);

    setEditFormValid(true);
    setEditNameValid(true);
    setEditAddressValid(true);
    setEditPhoneValid(true);
    setEditTimeSlotValid(true);
    setEditSchedulePeriodValid(true);

    setIsEdit(false);
    setIsCreate(false);
    setIsDelete(false);
  };

  const clearSelectedSalonServiceType = () => {
    setSelectedSalonServiceType({});
    setServiceTypeId("");
    setServiceTypePrice("");
    setServiceTypeDuration("");
    setServiceTypeName("");
    setServiceTypeDescription("");

    setServiceTypeNameValid(false);
    setServiceTypePriceValid(false);
    setServiceTypeDurationValid(false);
    setServiceTypesFormErrors({});
    setServiceTypesFormValid(false);

    setEditServiceTypeNameValid(true);
    setEditServiceTypePriceValid(true);
    setEditServiceTypeDurationValid(true);
    setEditServiceTypesFormValid(true);
  };

  const clearCheckboxSystem = (clearCreateWorkingTime = true) => {
    setIsEdit(false);
    setIsCreate(false);
    setIsDelete(false);
    setIsCreateWorkingtimeNew(false);
    setIsDeleteRange(false);

    if (
      clearCreateWorkingTime === true &&
      salonWorkingTimeDateList.length > 0
    ) {
      let newDate;
      let nowMoment = moment();
      let serverMoment = moment(salonWorkingTimeDateList).add(1, "day");

      if (nowMoment > serverMoment) {
        newDate = nowMoment.format("YYYY-MM-DD");
      } else {
        newDate = serverMoment.format("YYYY-MM-DD");
      }

      setCreateWorkingTimeDate(newDate);

      if (selectedSalon.salonId) {
        getSalonWorkingTimeIsDateCreated(selectedSalon.salonId, newDate);
      }
    }
    setCurrentDate("");
    setAllFreeSalonTimeSlots([]);
    setToGetFreeSlots(false);

    let s = moment().startOf("day").hours(9).toDate();

    let e = moment().startOf("day").hours(21).toDate();

    setStartTime(s);
    setEndTime(s);

    //inicijalizacija checkboxova
    let n = (e - s) / 1800000;
    let a = [];
    for (let index = 0; index < n; index++) {
      let d1 = new Date(s.getTime() + index * 30 * 60 * 1000);
      let d2 = new Date(s.getTime() + (index + 1) * 30 * 60 * 1000);
      a.push({
        value: true,
        startTime: moment(d1).format("HH:mm"),
        endTime: moment(d2).format("HH:mm"),
      });
    }
    setCheckboxInputs([...helper.cloneDeep(a)]);
  };

  const setSalonState = (salon) => {
    setName(salon.name);
    setAddress(salon.address);
    setWebAddress(salon.webAddress);
    setPhone(salon.phone);
    setMobilePhone(salon.mobilePhone);
    setEmail(salon.email);
    setSalonId(salon.salonId);
    setSalonTimeSlot(
      salon.salonTimeSlot || process.env.DEFAULT_TIME_SLOT_DURATION
    );
    setCustomerSchedulePeriod(
      salon.customerSchedulePeriod ||
        process.env.DEFAULT_CUSTOMER_SCHEDULE_PERIOD
    );
    setDescription(salon.description);
    setWorkingTimeDescription(
      salon.workingTimeDescription !== null ? salon.workingTimeDescription : ""
    );
    setEmbeddedMapAddress(salon.embeddedMapAddress);
    setLatitude(salon.latitude);
    setLongitude(salon.longitude);
    setGeneralizationId(salon.generalizationId);
  };

  // const setSalonWorkingTimeState = workingTime => {
  //   let s = new Date(workingTime.startTime);
  //   let e = new Date(workingTime.endTime);
  //   let c = moment(s).format("YYYY-MM-DD");
  //   setCurrentDate(c);
  //   setStartTime(s);
  //   setEndTime(e);
  //   setWorkingTimeId(workingTime.workingTimeId);
  // };

  const setSalonWorkingTimeStateConsolidated = (workingTime) => {
    let c = moment(workingTime.workingTimeDate).format("YYYY-MM-DD");
    let newStartTime = moment(c, "YYYY-MM-DD")
      .hours(moment(startTime).format("HH"))
      .minutes(moment(startTime).format("mm"))
      .seconds(0)
      .milliseconds(0)
      .toDate();
    let newEndTime = moment(c, "YYYY-MM-DD")
      .hours(moment(endTime).format("HH"))
      .minutes(moment(endTime).format("mm"))
      .seconds(0)
      .milliseconds(0)
      .toDate();

    setStartTime(newStartTime);
    setEndTime(newEndTime);
    setCurrentDate(c);
    setWorkingTimeConsolidated(workingTime);
  };

  const setSalonServiceTypeState = (serviceType) => {
    setSelectedSalonServiceType(serviceType || {});

    setServiceTypeId(serviceType.serviceTypeId || "");
    setServiceTypePrice(serviceType.serviceTypePrice || "");
    setServiceTypeDuration(serviceType.serviceTypeDuration || "");
    setServiceTypeName(serviceType.serviceTypeName || "");
    setServiceTypeDescription(serviceType.description || "");
  };

  // const activeDates = () => {
  //   let dates = [];
  //   for (let workingTime of selectedSalon.workingTimes) {
  //     dates.push(
  //       moment(workingTime.startTime, "YYYY-MM-DD").startOf("day").toDate()
  //     );
  //   }
  //   return [...helper.cloneDeep(dates)];
  // };

  // const getTitle = () => {
  //   if (rolesMap.get("Admin")) {
  //     return "List of Salons";
  //   } else {
  //     if (rolesMap.get("Salon admin")) {
  //       return `Salon ${salons.name || ""}`;
  //     }
  //   }
  // };

  return (
    <>
      <main role="main">
        {rolesMap.get("Admin") && (
          <div className="container-fluid">
            <ConnectedSalonList
              setParentState={setSalonState}
              setSelectedSalon={setSelectedSalon}
              selectedSalon={selectedSalon}
              clearParentState={clearState}
              setIsEdit={setIsEdit}
              setIsCreate={setIsCreate}
              setIsDelete={setIsDelete}
            />
          </div>
        )}

        {!rolesMap.get("Admin") && rolesMap.get("Salon admin") && (
          <>
            <form className="form needs-validation pt-0 mb-5" noValidate>
              <h5 className="text-center mb-4 mt-n1" id="editSalonModalLabel">
                <span>
                  {name}{" "}
                  {helper.i18nMultiFallbackKey(
                    "salon.salonComponent.salonAdmin.shopDetailsLabel",
                    "shop details"
                  )}
                </span>

                <button
                  type="button"
                  className="btn btn-sm btn-outline-success ico-l ml-3"
                  data-dismiss="modal"
                  onClick={() =>
                    saveSalonForId(
                      salonId,
                      name,
                      address,
                      webAddress,
                      phone,
                      mobilePhone,
                      email,
                      salonTimeSlot,
                      customerSchedulePeriod,
                      embeddedMapAddressHasError ? "" : embeddedMapAddress,
                      latitude,
                      longitude,
                      generalizationId,
                      workingTimeDescription,
                      description,
                      () => {
                        //clearState();
                      }
                    )
                  }
                >
                  <i className="fal fa-check" />{" "}
                  {t(
                    "salon.salonComponent.salonAdmin.updateSalonDetailsLabel",
                    "Update details"
                  )}
                </button>
              </h5>
              <input type="hidden" value="something" />

              <div className="row">
                <div className="col-lg-3">
                  <div className="form-group">
                    <label htmlFor="nameEdit">
                      {t(
                        "salon.salonComponent.salonAdmin.salonNameLabel",
                        "Name"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nameEdit"
                      autoComplete="new-username"
                      placeholder={helper.i18nMultiFallbackKey(
                        "salon.salonComponent.salonAdmin.shopNamePlaceholder",
                        "Please enter shop name"
                      )}
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="addressEdit">
                      {t(
                        "salon.salonComponent.salonAdmin.salonAddressLabel",
                        "Address"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="addressEdit"
                      autoComplete="new-username"
                      placeholder={helper.i18nMultiFallbackKey(
                        "salon.salonComponent.salonAdmin.shopAddressPlaceholder",
                        "Please enter shop address"
                      )}
                      value={address}
                      onChange={(event) => setAddress(event.target.value)}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="webAddressEdit">
                      {t(
                        "salon.salonComponent.salonAdmin.salonWebAddressLabel",
                        "WebAddress"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="webAddressEdit"
                      placeholder={t(
                        "salon.salonComponent.salonAdmin.salonWebAddressPlaceholder",
                        "Please enter webAddress"
                      )}
                      value={webAddress}
                      onChange={(event) => setWebAddress(event.target.value)}
                    />
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group">
                    <div className="row gutters-small">
                      <div className="col-md-6">
                        <label htmlFor="phoneEdit">
                          {t(
                            "salon.salonComponent.salonAdmin.salonPhoneLabel",
                            "Phone"
                          )}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="phoneEdit"
                          placeholder={t(
                            "salon.salonComponent.salonAdmin.salonPhonePlaceholder",
                            "Please enter phone"
                          )}
                          value={phone}
                          onChange={(event) => setPhone(event.target.value)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label
                          className="txt-truncate-block"
                          htmlFor="mobilePhoneEdit"
                        >
                          {t(
                            "salon.salonComponent.salonAdmin.salonMobilePhoneLabel",
                            "Mobile phone"
                          )}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="mobilePhoneEdit"
                          placeholder={t(
                            "salon.salonComponent.salonAdmin.salonMobilePhonePlaceholder",
                            "Please enter mobilePhone"
                          )}
                          value={mobilePhone}
                          onChange={(event) =>
                            setMobilePhone(event.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="emailEdit">
                      {t(
                        "salon.salonComponent.salonAdmin.salonEmailLabel",
                        "Email"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="emailEdit"
                      placeholder={t(
                        "salon.salonComponent.salonAdmin.salonEmailPlaceholder",
                        "Please enter email"
                      )}
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <div className="row gutters-small">
                      <div className="col-md-6">
                        <label className="text-nowrap" htmlFor="timeSlotEdit">
                          {t(
                            "salon.salonComponent.salonAdmin.salonTimeSlotLabel",
                            "Time slot"
                          )}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="timeSlotEdit"
                          placeholder={t(
                            "salon.salonComponent.salonAdmin.salonTimeSlotPlaceholder",
                            "Please enter time slot"
                          )}
                          value={salonTimeSlot}
                          onChange={(event) => {
                            setSalonTimeSlot(event.target.value);
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <label
                          className="txt-truncate-block"
                          htmlFor="schedulePeriodEdit"
                        >
                          {t(
                            "salon.salonComponent.salonAdmin.salonSchedulePeriodLabel",
                            "Schedule period"
                          )}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="schedulePeriodEdit"
                          placeholder={t(
                            "salon.salonComponent.salonAdmin.salonSchedulePeriodPlaceholder",
                            "Please enter schedule period (default 14 days)"
                          )}
                          value={customerSchedulePeriod}
                          onChange={(event) => {
                            setCustomerSchedulePeriod(event.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group">
                    <label htmlFor="embeddedMapAddress">
                      {t(
                        "salon.salonComponent.salonAdmin.salonEmbeddedMapAddressLabel",
                        "Embedded map address"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="embeddedMapAddress"
                      placeholder={t(
                        "salon.salonComponent.salonAdmin.salonEmbeddedMapAddressPlaceholder",
                        "Please enter embedded map address"
                      )}
                      value={embeddedMapAddress}
                      onChange={(event) => {
                        const paragraph = event.target.value;
                        const regex = /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.%]+/g;
                        const found = paragraph.match(regex);
                        setEmbeddedMapAddressHasError(found ? false : true);
                        setEmbeddedMapAddress(found ? found[0] : paragraph);
                      }}
                    />
                  </div>
                  <div className="form-group working-time-group">
                    <label
                      className="text-nowrap"
                      htmlFor="workingTimeDescriptionEdit"
                    >
                      {t(
                        "salon.salonComponent.salonAdmin.salonWorkingTimeDescriptionLabel",
                        "Working time description"
                      )}
                    </label>
                    <textarea
                      type="text"
                      className="form-control working-time-ta"
                      id="workingTimeDescriptionEdit"
                      autoComplete="new-username"
                      placeholder={t(
                        "salon.salonComponent.salonAdmin.salonWorkingTimeDescriptionPlaceholder",
                        "Pon-Pet:9-21h Sub:9-16h Ned:Ne radi"
                      )}
                      value={workingTimeDescription}
                      onChange={(event) =>
                        setWorkingTimeDescription(event.target.value)
                      }
                    />
                    <div className="working-time-group-footer">
                      <span className="working-time txt-color text-nowrap">
                        <span className="week-day">
                          <i>
                            {t(
                              "salon.salonComponent.salonAdmin.salonWorkingTimePonPetLabel",
                              "Pon-Pet:"
                            )}
                          </i>
                          {t(
                            "salon.salonComponent.salonAdmin.salonWorkingTimePonPetWorkTimeLabel",
                            "09-21h"
                          )}
                        </span>
                        <span className="week-day saturday">
                          <i>
                            {t(
                              "salon.salonComponent.salonAdmin.salonWorkingTimeSubLabel",
                              "Sub:"
                            )}
                          </i>
                          {t(
                            "salon.salonComponent.salonAdmin.salonWorkingTimeSubWorkTimeLabel",
                            "10-15h"
                          )}
                        </span>
                        <span className="week-day sunday">
                          <i>
                            {t(
                              "salon.salonComponent.salonAdmin.salonWorkingTimeNedLabel",
                              "Ned:"
                            )}
                          </i>
                          {t(
                            "salon.salonComponent.salonAdmin.salonWorkingTimeNedWorkTimeLabel",
                            "10-15h"
                          )}
                        </span>
                      </span>
                      <div className="">
                        <button
                          className="btn btn-gray-outline-transp btn-sm btn-inline"
                          onClick={(e) => {
                            e.preventDefault();
                            setWorkingTimeDescription(`<span class="week-day">
                      <i>Pon-Pet:</i>
                      09-21h
                    </span>
                    <span class="week-day saturday">
                      <i>Sub:</i>
                      10-15h
                    </span>
                    <span class="week-day sunday">
                      <i>Ned:</i>
                      10-15h
                    </span>`);
                          }}
                        >
                          <i className="fal fa-copy"></i>
                          {t(
                            "salon.salonComponent.salonAdmin.salonCopyHtmlLabel",
                            "Copy HTML"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="row gutters-small">
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="latitude">
                          {t(
                            "salon.salonComponent.salonAdmin.salonLatitudeLabel",
                            "Latitude"
                          )}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="latitude"
                          placeholder={t(
                            "salon.salonComponent.salonAdmin.salonLatitudePlaceholder",
                            "Please enter latitude"
                          )}
                          value={latitude}
                          onChange={(event) => {
                            setLatitude(event.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="longitude">
                          {t(
                            "salon.salonComponent.salonAdmin.salonLongitudeLabel",
                            "Longitude"
                          )}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="longitude"
                          placeholder={t(
                            "salon.salonComponent.salonAdmin.salonLongitudePlaceholder",
                            "Please enter longitude"
                          )}
                          value={longitude}
                          onChange={(event) => {
                            setLongitude(event.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="form-group">
                    <label htmlFor="editGeneralizationIdSalonAdmin">
                      {t(
                        "salon.salonComponent.salonAdmin.generalizationIdLabel",
                        "Generalization"
                      )}
                    </label>
                    <ConnectedGeneralizationsDropDown
                      generalizationId={generalizationId}
                      setSalonId={setGeneralizationId}
                      id="editGeneralizationIdSalonAdmin"
                    />
                    <div className="invalid-feedback">
                      {t(
                        "salon.salonComponent.salonAdmin.generalizationIdInvalid",
                        "Please select generalization."
                      )}
                    </div>
                  </div> */}

                  <div className="form-group">
                    <label htmlFor="descriptionEdit">
                      {t(
                        "salon.salonComponent.salonAdmin.salonDescriptionLabel",
                        "Description"
                      )}
                    </label>
                    <textarea
                      rows="3"
                      type="text"
                      className="form-control"
                      id="descriptionEdit"
                      autoComplete="new-username"
                      placeholder={helper.i18nMultiFallbackKey(
                        "salon.salonComponent.salonAdmin.shopDescriptionPlaceholder",
                        "Please enter shop description"
                      )}
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </form>
          </>
        )}

        {selectedSalon.salonId && (
          <>
            <div className="container-fluid my-4">
              <div className="row">
                <div className="col-md-6">
                  <ConnectedSalonWorkingTimeConsolidatedList
                    setParentState={setSalonWorkingTimeStateConsolidated}
                    selectedSalon={selectedSalon}
                    clearParentState={setSalonState}
                    setStartTime={setStartTime}
                    setEndTime={setEndTime}
                    setToGetFreeSlots={setToGetFreeSlots}
                    getSalonWorkingTimeIsDateCreated={
                      getSalonWorkingTimeIsDateCreated
                    }
                    setIsCreateWorkingtimeNew={setIsCreateWorkingtimeNew}
                    setIsDeleteRange={setIsDeleteRange}
                  />
                </div>
                <div className="col-md-6 mt-4 mt-md-0">
                  <ConnectedSalonServiceTypeList
                    setParentState={setSalonServiceTypeState}
                    selectedSalon={selectedSalon}
                    clearParentState={setSalonState}
                    serviceTypes={selectedSalon.serviceTypes}
                    setEnableEdit={setEnableEdit}
                    setEnableCreate={setEnableCreate}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </main>
      <footer className="footer">
        <div className="container copyright">
          <span className="text-muted">
            Hairstylist &copy; Copyright {moment().format("YYYY")},{" "}
            {t("common.version", "Version:")} {process.env.VERSION}
          </span>
        </div>
      </footer>
      {isCreate && (
        <ConnectedCreateSalonModal
          name={name}
          setName={setName}
          address={address}
          setAddress={setAddress}
          webAddress={webAddress}
          setWebAddress={setWebAddress}
          phone={phone}
          setPhone={setPhone}
          mobilePhone={mobilePhone}
          setMobilePhone={setMobilePhone}
          email={email}
          setEmail={setEmail}
          salonTimeSlot={salonTimeSlot}
          setSalonTimeSlot={setSalonTimeSlot}
          customerSchedulePeriod={customerSchedulePeriod}
          setCustomerSchedulePeriod={setCustomerSchedulePeriod}
          embeddedMapAddress={embeddedMapAddress}
          setEmbeddedMapAddress={setEmbeddedMapAddress}
          embeddedMapAddressHasError={embeddedMapAddressHasError}
          setEmbeddedMapAddressHasError={setEmbeddedMapAddressHasError}
          latitude={latitude}
          setLatitude={setLatitude}
          longitude={longitude}
          setLongitude={setLongitude}
          generalizationId={generalizationId}
          setGeneralizationId={setGeneralizationId}
          workingTimeDescription={workingTimeDescription}
          setWorkingTimeDescription={setWorkingTimeDescription}
          description={description}
          setDescription={setDescription}
          createSalon={createSalon}
          clearState={clearState}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          nameValid={nameValid}
          setNameValid={setNameValid}
          addressValid={addressValid}
          setAddressValid={setAddressValid}
          phoneValid={phoneValid}
          setPhoneValid={setPhoneValid}
          timeSlotValid={timeSlotValid}
          setTimeSlotValid={setTimeSlotValid}
          schedulePeriodValid={schedulePeriodValid}
          setSchedulePeriodValid={setSchedulePeriodValid}
          formValid={formValid}
          setFormValid={setFormValid}
        />
      )}

      {isEdit && (
        <ConnectedEditSalonModal
          salonId={salonId}
          name={name}
          setName={setName}
          address={address}
          setAddress={setAddress}
          webAddress={webAddress}
          setWebAddress={setWebAddress}
          phone={phone}
          setPhone={setPhone}
          mobilePhone={mobilePhone}
          setMobilePhone={setMobilePhone}
          email={email}
          setEmail={setEmail}
          salonTimeSlot={salonTimeSlot}
          setSalonTimeSlot={setSalonTimeSlot}
          customerSchedulePeriod={customerSchedulePeriod}
          setCustomerSchedulePeriod={setCustomerSchedulePeriod}
          embeddedMapAddress={embeddedMapAddress}
          setEmbeddedMapAddress={setEmbeddedMapAddress}
          embeddedMapAddressHasError={embeddedMapAddressHasError}
          setEmbeddedMapAddressHasError={setEmbeddedMapAddressHasError}
          latitude={latitude}
          setLatitude={setLatitude}
          longitude={longitude}
          setLongitude={setLongitude}
          generalizationId={generalizationId}
          setGeneralizationId={setGeneralizationId}
          workingTimeDescription={workingTimeDescription}
          setWorkingTimeDescription={setWorkingTimeDescription}
          description={description}
          setDescription={setDescription}
          saveSalon={saveSalon}
          clearState={clearState}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          editNameValid={editNameValid}
          setEditNameValid={setEditNameValid}
          editAddressValid={editAddressValid}
          setEditAddressValid={setEditAddressValid}
          editPhoneValid={editPhoneValid}
          setEditPhoneValid={setEditPhoneValid}
          editTimeSlotValid={editTimeSlotValid}
          setEditTimeSlotValid={setEditTimeSlotValid}
          editSchedulePeriodValid={editSchedulePeriodValid}
          setEditSchedulePeriodValid={setEditSchedulePeriodValid}
          editFormValid={editFormValid}
          setEditFormValid={setEditFormValid}
        />
      )}

      {/* Delete Salon Modal */}
      {isDelete && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="deleteSalonModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="deleteSalonModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="deleteSalonModalLabel">
                  <i className="fal fa-warehouse fa-lg c-purple" />
                  {helper.i18nMultiFallbackKey(
                    "salon.salonComponent.deleteSalonModal.title",
                    "Deactivate Shop"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <h2 className="info-title-upp-case-xl icon-custom-top-center">
                  <span className="icon-custom is-xl icon-triangle-red-white" />
                  {t(
                    "salon.salonComponent.deleteSalonModal.attention",
                    "Attention"
                  )}
                </h2>
                <div className="info-message text-center">
                  <p>
                    {helper.i18nMultiFallbackKey(
                      "salon.salonComponent.deleteSalonModal.question",
                      "Are you sure you want to deactivate shop"
                    )}
                  </p>
                  <p className="prime-text">{name}?</p>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  onClick={() =>
                    deleteSalon(salonId, () => {
                      clearState();
                      setSelectedSalon({});
                    })
                  }
                >
                  <i className="fal fa-check" /> {t("common.yes", "Yes")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                >
                  <i className="fal fa-times" /> {t("common.no", "No")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Create Salon Working time Modal */}
      {selectedSalon.salonId && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="createSalonWorkingTimeModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="createSalonWorkingTimeModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="createSalonWorkingTimeModalLabel"
                >
                  <i className="fal fa-clock fa-lg c-purple" />{" "}
                  {helper.i18nMultiFallbackKey(
                    "salon.salonComponent.createSalonWorkingTimeModal.title",
                    "Create Shop Working time"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <input type="hidden" value="something" />
                  <div className="form-group">
                    <label htmlFor="salonName">
                      {helper.i18nMultiFallbackKey(
                        "salon.salonComponent.createSalonWorkingTimeModal.shopNameLabel",
                        "Shop name"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="salonName"
                      value={`${selectedSalon.name}`}
                      onChange={(event) => setName(event.target.value)}
                      disabled
                    />
                  </div>
                  <div className="row">
                    <div className="form-group calendar-controls cc-default col-md-6">
                      <label htmlFor="currentDate" className="required">
                        {t(
                          "salon.salonComponent.createSalonWorkingTimeModal.dateLabel",
                          "Date"
                        )}
                      </label>
                      {createWorkingTimeDate && (
                        <DatePicker
                          id="currentDate"
                          className="form-control"
                          selected={moment(createWorkingTimeDate, "YYYY-MM-DD")
                            .startOf("day")
                            .toDate()}
                          dateFormat="yyyy-MM-dd"
                          timeFormat="HH:mm"
                          onKeyDown={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onChange={(date) => {
                            setCreateWorkingTimeDate(
                              moment(date).format("YYYY-MM-DD")
                            );
                            getSalonWorkingTimeIsDateCreated(
                              selectedSalon.salonId,
                              moment(date).format("YYYY-MM-DD")
                            );

                            let newStartTime = moment(date, "YYYY-MM-DD")
                              .hours(moment(startTime).format("HH"))
                              .minutes(moment(startTime).format("mm"))
                              .seconds(0)
                              .milliseconds(0)
                              .toDate();
                            let newEndTime = moment(date, "YYYY-MM-DD")
                              .hours(moment(endTime).format("HH"))
                              .minutes(moment(endTime).format("mm"))
                              .seconds(0)
                              .milliseconds(0)
                              .toDate();

                            setStartTime(newStartTime);
                            setEndTime(newEndTime);
                          }}
                          placeholderText={t(
                            "salon.salonComponent.createSalonWorkingTimeModal.datePlaceholder",
                            "Select date"
                          )}
                        />
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group calendar-controls cc-default nested-react-wrapper-w-100 cc-time col-md-6">
                      <label htmlFor="startTime" className="required">
                        {t(
                          "salon.salonComponent.createSalonWorkingTimeModal.dateStartTimeLabel",
                          "Start time"
                        )}
                      </label>
                      {createWorkingTimeDate && (
                        <DatePicker
                          className="form-control"
                          id="startTime"
                          selected={startTime}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={selectedSalon.salonTimeSlot}
                          timeCaption="Time"
                          timeFormat="HH:mm"
                          dateFormat="HH:mm"
                          onKeyDown={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onChange={(event) => {
                            let inputs = [...helper.cloneDeep(checkboxInputs)];
                            let newStartTime = moment(
                              createWorkingTimeDate,
                              "YYYY-MM-DD"
                            )
                              .hours(moment(event).format("HH"))
                              .minutes(moment(event).format("mm"))
                              .seconds(0)
                              .milliseconds(0)
                              .toDate();
                            let newEndTime = moment(
                              createWorkingTimeDate,
                              "YYYY-MM-DD"
                            )
                              .hours(moment(endTime).format("HH"))
                              .minutes(moment(endTime).format("mm"))
                              .seconds(0)
                              .milliseconds(0)
                              .toDate();

                            if (newStartTime > newEndTime) {
                              newEndTime = newStartTime;
                            }

                            for (let inp of inputs) {
                              let currentStartTime = new Date(
                                `${createWorkingTimeDate} ` + inp.startTime
                              );
                              let currentEndTime = new Date(
                                `${createWorkingTimeDate} ` + inp.endTime
                              );

                              if (currentStartTime > currentEndTime) {
                                currentEndTime = moment(currentEndTime)
                                  .add(1, "d")
                                  .toDate();
                              }

                              if (
                                newStartTime > currentStartTime ||
                                currentEndTime > newEndTime
                              ) {
                                inp.value = false;
                              } else {
                                inp.value = true;
                              }
                            }

                            setCheckboxInputs(inputs);

                            // let firstEndTime = new Date(
                            //   `${createWorkingTimeDate} ` + inputs[0].endTime
                            // );
                            // let firstStartTime = new Date(
                            //   `${createWorkingTimeDate} ` + inputs[0].startTime
                            // );

                            // if (firstStartTime <= newStartTime) {
                            //   setStartTime(newStartTime);
                            // }

                            setStartTime(newStartTime);
                            setEndTime(newEndTime);
                          }}
                        />
                      )}
                    </div>
                    <div className="form-group calendar-controls cc-default nested-react-wrapper-w-100 cc-time col-md-6">
                      <label htmlFor="endTime" className="required">
                        {t(
                          "salon.salonComponent.createSalonWorkingTimeModal.dateEndTimeLabel",
                          "End time"
                        )}
                      </label>
                      {createWorkingTimeDate && (
                        <DatePicker
                          className="form-control"
                          id="endTime"
                          selected={endTime}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={selectedSalon.salonTimeSlot}
                          timeCaption="Time"
                          timeFormat="HH:mm"
                          dateFormat="HH:mm"
                          onKeyDown={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onChange={(event) => {
                            let inputs = [...helper.cloneDeep(checkboxInputs)];
                            let newEndTime = moment(
                              createWorkingTimeDate,
                              "YYYY-MM-DD"
                            )
                              .hours(moment(event).format("HH"))
                              .minutes(moment(event).format("mm"))
                              .seconds(0)
                              .milliseconds(0)
                              .toDate();

                            let newStartTime = moment(
                              createWorkingTimeDate,
                              "YYYY-MM-DD"
                            )
                              .hours(moment(startTime).format("HH"))
                              .minutes(moment(startTime).format("mm"))
                              .seconds(0)
                              .milliseconds(0)
                              .toDate();

                            if (newStartTime > newEndTime) {
                              newStartTime = newEndTime;
                            }

                            for (let inp of inputs) {
                              let currentEndTime = new Date(
                                `${createWorkingTimeDate} ` + inp.endTime
                              );
                              let currentStartTime = new Date(
                                `${createWorkingTimeDate} ` + inp.startTime
                              );

                              if (currentStartTime > currentEndTime) {
                                currentEndTime = moment(currentEndTime)
                                  .add(1, "d")
                                  .toDate();
                              }

                              if (
                                newStartTime > currentStartTime ||
                                currentEndTime > newEndTime
                              ) {
                                inp.value = false;
                              } else {
                                inp.value = true;
                              }
                            }
                            setCheckboxInputs(inputs);

                            // let lastEndTime = new Date(
                            //   `${createWorkingTimeDate} ` +
                            //     inputs[inputs.length - 1].endTime
                            // );
                            // let lastStartTime = new Date(
                            //   `${createWorkingTimeDate} ` +
                            //     inputs[inputs.length - 1].startTime
                            // );

                            // if (lastStartTime > lastEndTime) {
                            //   lastEndTime = moment(lastEndTime)
                            //     .add(1, "d")
                            //     .toDate();
                            // }

                            // if (lastEndTime >= newEndTime) {
                            //   setEndTime(newEndTime);
                            // }
                            setEndTime(newEndTime);
                            setStartTime(newStartTime);
                          }}
                        />
                      )}
                    </div>
                  </div>

                  {toGetFreeSlots && (
                    <div className="form-group">
                      <label htmlFor="slotTimeComponent" className="required">
                        {t(
                          "salon.salonComponent.createSalonWorkingTimeModal.timeSlotsLabel",
                          "Time slots"
                        )}
                      </label>
                      {createWorkingTimeDate && (
                        <ConnectedSalonSlot
                          selectedSalon={selectedSalon}
                          visitDate={createWorkingTimeDate}
                          setParentCheckboxInputs={setCheckboxInputs}
                          startTime={startTime}
                          endTime={endTime}
                          editType={false}
                          id="slotTimeComponent"
                        />
                      )}
                    </div>
                  )}
                  <RequiredFieldsComponent />
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => {
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  disabled={
                    isDateCreated ||
                    moment(startTime).format("HH:mm") ===
                      moment(endTime).format("HH:mm")
                  }
                  onClick={() => {
                    let newDate = moment(createWorkingTimeDate).add(1, "day");
                    setCreateWorkingTimeDate(newDate);

                    createSalonWorkingTime(
                      selectedSalon.salonId,
                      createWorkingTimeDate,
                      startTime,
                      endTime,
                      checkboxInputs,
                      () => {
                        clearCheckboxSystem(false);
                      }
                    );
                  }}
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Create Working Time Modal - NEW!!! */}
      {isCreateWorkingTimeNew && (
        <ConnectedSalonCreateWorkingTimeAuto
          selectedSalon={selectedSalon}
          clearCheckboxSystem={clearCheckboxSystem}
        />
      )}

      {isDeleteRange && (
        <ConnectedSalonDeleteRange
          selectedSalon={selectedSalon}
          clearCheckboxSystem={clearCheckboxSystem}
        />
      )}

      {/* Edit Salon Working time Modal */}
      {selectedSalon.salonId && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="editSalonWorkingTimeModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="editSalonWorkingTimeModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="editSalonWorkingTimeModalLabel">
                  <i className="fal fa-clock fa-lg c-purple" />{" "}
                  {helper.i18nMultiFallbackKey(
                    "salon.salonComponent.editSalonWorkingTimeModal.title",
                    "Edit Shop Working time"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <input type="hidden" value="something" />
                  <div className="form-group">
                    <label htmlFor="salonNameEdit">
                      {helper.i18nMultiFallbackKey(
                        "salon.salonComponent.editSalonWorkingTimeModal.shopNameLabel",
                        "Shop name"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="salonNameEdit"
                      value={`${selectedSalon.name}`}
                      onChange={(event) => setName(event.target.value)}
                      disabled
                    />
                  </div>

                  <div className="form-group calendar-controls cc-default">
                    <label
                      htmlFor="currentDateEditWorkingTime"
                      className="required"
                    >
                      {t(
                        "salon.salonComponent.editSalonWorkingTimeModal.salonDateLabel",
                        "Date"
                      )}
                    </label>
                    {currentDate && (
                      <DatePicker
                        id="currentDateEditWorkingTime"
                        className="form-control"
                        selected={moment(currentDate, "YYYY-MM-DD")
                          .startOf("day")
                          .toDate()}
                        dateFormat="yyyy-MM-dd"
                        onKeyDown={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onChange={(date) => {
                          setCurrentDate(moment(date).format("YYYY-MM-DD"));
                        }}
                        placeholderText="Select date"
                        disabled
                      />
                    )}
                  </div>
                  {toGetFreeSlots && (
                    <div className="form-group">
                      <label
                        htmlFor="slotTimeComponentEditWorkingTime"
                        className="required"
                      >
                        {t(
                          "salon.salonComponent.editSalonWorkingTimeModal.salonTimeSlotsLabel",
                          "Time slots"
                        )}
                      </label>
                      {currentDate && (
                        <ConnectedSalonSlot
                          selectedSalon={selectedSalon}
                          visitDate={currentDate}
                          setParentCheckboxInputs={setCheckboxInputs}
                          startTime={startTime}
                          endTime={endTime}
                          editType={true}
                          id="slotTimeComponentEditWorkingTime"
                        />
                      )}
                    </div>
                  )}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => {
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  onClick={() => {
                    saveSalonWorkingTime(
                      workingTimeId,
                      selectedSalon.salonId,
                      currentDate,
                      startTime,
                      endTime,
                      checkboxInputs,
                      () => {
                        clearCheckboxSystem();
                      }
                    );
                  }}
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delete Salon  Working time Modal */}
      {selectedSalon.salonId && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="deleteSalonWorkingTimeModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="deleteSalonWorkingTimeModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="deleteSalonWorkingTimeModalLabel"
                >
                  <i className="fal fa-warehouse fa-lg c-purple" />{" "}
                  {helper.i18nMultiFallbackKey(
                    "salon.salonComponent.deleteSalonWorkingTimeModal.title",
                    "Deactivate Shop Working Time"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <h2 className="info-title-upp-case-xl icon-custom-top-center">
                  <span className="icon-custom is-xl icon-triangle-red-white" />
                  {t(
                    "salon.salonComponent.deleteSalonWorkingTimeModal.attention",
                    "Attention"
                  )}
                </h2>
                <div className="info-message text-center">
                  <p>
                    {helper.i18nMultiFallbackKey(
                      "salon.salonComponent.deleteSalonWorkingTimeModal.question",
                      "Are you sure you want to deactivate shop working time"
                    )}
                  </p>
                  <p className="prime-text">
                    {`${moment(startTime).format("HH:mm")}`} -{" "}
                    {`${moment(endTime).format("HH:mm")}`}{" "}
                    {t(
                      "salon.salonComponent.deleteSalonWorkingTimeModal.forDate",
                      "for date"
                    )}
                    {`${moment(currentDate).format("YYYY-DD-MM")}`}?
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  onClick={() => {
                    deleteSalonWorkingTimeConsolidated(
                      workingTimeConsolidated,
                      () => {
                        clearCheckboxSystem();
                      }
                    );
                  }}
                >
                  <i className="fal fa-check" /> {t("common.yes", "Yes")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                >
                  <i className="fal fa-times" /> {t("common.no", "No")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <ConnectedCreateServiceTypeModal
        serviceTypeName={serviceTypeName}
        setServiceTypeName={setServiceTypeName}
        serviceTypePrice={serviceTypePrice}
        setServiceTypePrice={setServiceTypePrice}
        selectedSalon={selectedSalon}
        serviceTypeDuration={serviceTypeDuration}
        setServiceTypeDuration={setServiceTypeDuration}
        serviceTypeDescription={serviceTypeDescription}
        setServiceTypeDescription={setServiceTypeDescription}
        createServiceType={createServiceType}
        clearSelectedSalonServiceType={clearSelectedSalonServiceType}
        setEnableEdit={setEnableEdit}
        formErrors={serviceTypesFormErrors}
        setFormErrors={setServiceTypesFormErrors}
        formValid={serviceTypesformValid}
        setFormValid={setServiceTypesFormValid}
        serviceTypeNameValid={serviceTypeNameValid}
        setServiceTypeNameValid={setServiceTypeNameValid}
        serviceTypePriceValid={serviceTypePriceValid}
        setServiceTypePriceValid={setServiceTypePriceValid}
        serviceTypeDurationValid={serviceTypeDurationValid}
        setServiceTypeDurationValid={setServiceTypeDurationValid}
      />
      <ConnectedEditServiceTypeModal
        serviceTypeName={serviceTypeName}
        setServiceTypeName={setServiceTypeName}
        serviceTypePrice={serviceTypePrice}
        setServiceTypePrice={setServiceTypePrice}
        selectedSalon={selectedSalon}
        serviceTypeDuration={serviceTypeDuration}
        setServiceTypeDuration={setServiceTypeDuration}
        serviceTypeDescription={serviceTypeDescription}
        setServiceTypeDescription={setServiceTypeDescription}
        saveServiceType={saveServiceType}
        clearSelectedSalonServiceType={clearSelectedSalonServiceType}
        setEnableEdit={setEnableEdit}
        formErrors={serviceTypesFormErrors}
        setFormErrors={setServiceTypesFormErrors}
        formValid={editServiceTypesformValid}
        setFormValid={setEditServiceTypesFormValid}
        serviceTypeNameValid={editServiceTypeNameValid}
        setServiceTypeNameValid={setEditServiceTypeNameValid}
        serviceTypePriceValid={editServiceTypePriceValid}
        setServiceTypePriceValid={setEditServiceTypePriceValid}
        serviceTypeDurationValid={editServiceTypeDurationValid}
        setServiceTypeDurationValid={setEditServiceTypeDurationValid}
        serviceTypeId={serviceTypeId}
      />

      {/* Salon ServiceType Delete*/}
      {/* <ConnectedSalonServiceTypeDelete
        selectedServiceType={selectedSalonServiceType}
        selectedSalon={selectedSalon}
        clearParentState={clearSelectedSalonServiceType}
      /> */}
      <div
        className="modal fade"
        data-backdrop="static"
        data-keyboard="false"
        id="deleteSalonServiceTypeModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteSalonServiceTypeModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteSalonServiceTypeModalLabel">
                <i className="fal fa-cut fa-lg c-purple" />{" "}
                {helper.i18nMultiFallbackKey(
                  "salon.salonComponent.deleteSalonServiceTypeModal.title",
                  "Deactivate Shop Service Type"
                )}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => clearSelectedSalonServiceType()}
              >
                <i className="fal fa-times" />
              </button>
            </div>

            <div className="modal-body">
              <h2 className="info-title-upp-case-xl icon-custom-top-center">
                <span className="icon-custom is-xl icon-triangle-red-white" />
                {t(
                  "salon.salonComponent.deleteSalonServiceTypeModal.attention",
                  "Attention"
                )}
              </h2>
              {!selectedSalonServiceType.hasDependents && (
                <div className="info-message text-center">
                  <p>
                    {helper.i18nMultiFallbackKey(
                      "salon.salonComponent.deleteSalonServiceTypeModal.question",
                      "Are you sure you want to deactivate shop service type"
                    )}
                  </p>
                  <p className="prime-text">
                    {selectedSalonServiceType.serviceTypeName}{" "}
                    {helper.i18nMultiFallbackKey(
                      "salon.salonComponent.deleteSalonServiceTypeModal.inShopLabel",
                      "in shop"
                    )}{" "}
                    {selectedSalon.name} ?
                  </p>
                </div>
              )}
              {selectedSalonServiceType.hasDependents && (
                <div className="info-message text-center">
                  <p>
                    {selectedSalonServiceType.serviceTypeName}{" "}
                    {t(
                      "salon.salonComponent.deleteSalonServiceTypeModal.deactivateServiceTypeLabel",
                      "Please deactivate service type"
                    )}{" "}
                  </p>
                  <p className="prime-text">
                    {selectedSalonServiceType.serviceType
                      ? selectedSalonServiceType.serviceType.serviceTypeName
                      : ""}{" "}
                    {helper.i18nMultiFallbackKey(
                      "salon.salonComponent.deleteSalonServiceTypeModal.onHaorstylistFromSalonLabel",
                      "on providers from shop"
                    )}{" "}
                    {selectedSalon.name}
                  </p>
                </div>
              )}
            </div>
            <div className="modal-footer">
              {!selectedSalonServiceType.hasDependents && (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-success ico-l"
                    data-dismiss="modal"
                    onClick={() =>
                      deleteServiceType(selectedSalonServiceType, () => {
                        clearSelectedSalonServiceType();
                        setEnableEdit(false);
                      })
                    }
                  >
                    <i className="fal fa-check" /> {t("common.yes", "Yes")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger ico-l"
                    data-dismiss="modal"
                    onClick={() => clearSelectedSalonServiceType()}
                  >
                    <i className="fal fa-times" /> {t("common.no", "No")}
                  </button>
                </>
              )}
              {selectedSalonServiceType.hasDependents && (
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  onClick={() => {
                    clearSelectedSalonServiceType();
                    clearCheckboxSystem();
                  }}
                >
                  <i className="fal fa-check" /> {t("common.ok", "OK")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Salon ServiceType Activate*/}
      <div
        className="modal fade"
        data-backdrop="static"
        data-keyboard="false"
        id="activateSalonServiceTypeModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="activateSalonServiceTypeModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="activateSalonServiceTypeModalLabel"
              >
                <i className="fal fa-cut fa-lg c-purple" />{" "}
                {helper.i18nMultiFallbackKey(
                  "salon.salonComponent.activateSalonServiceTypeModal.title",
                  "Activate Shop Service Type"
                )}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => clearSelectedSalonServiceType()}
              >
                <i className="fal fa-times" />
              </button>
            </div>

            <div className="modal-body">
              <h2 className="info-title-upp-case-xl icon-custom-top-center">
                <span className="icon-custom is-xl icon-triangle-red-white" />
                {t(
                  "salon.salonComponent.activateSalonServiceTypeModal.attention",
                  "Attention"
                )}
              </h2>
              <div className="info-message text-center">
                <p>
                  {helper.i18nMultiFallbackKey(
                    "salon.salonComponent.activateSalonServiceTypeModal.question",
                    "Are you sure you want to activate shop service type"
                  )}
                </p>
                <p className="prime-text">
                  {selectedSalonServiceType.serviceTypeName}{" "}
                  {helper.i18nMultiFallbackKey(
                    "salon.salonComponent.activateSalonServiceTypeModal.inShopLabel",
                    "in shop"
                  )}{" "}
                  {selectedSalon.name} ?
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  onClick={() =>
                    activateServiceType(selectedSalonServiceType, () => {
                      clearSelectedSalonServiceType();
                      setEnableEdit(false);
                    })
                  }
                >
                  <i className="fal fa-check" /> {t("common.yes", "Yes")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => clearSelectedSalonServiceType()}
                >
                  <i className="fal fa-times" /> {t("common.no", "No")}
                </button>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    activeUser: state.activeUser,
    isDateCreated: state.salonWorkingTimesIsDateCreated,
    salons: state.salons,
    salonWorkingTimeDateList: state.salonWorkingTimeDateList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActiveUser() {
      if (sessionStorage.getItem("user_id")) {
        dispatch(
          mutations.getActiveUser(JSON.parse(sessionStorage.getItem("user_id")))
        );
      }
    },
    getSalonForId(salonId) {
      dispatch(mutations.getSalonForId(salonId));
    },
    createSalon(
      name,
      address,
      webAddress,
      phone,
      mobilePhone,
      email,
      salonTimeSlot,
      customerSchedulePeriod,
      embeddedMapAddress,
      latitude,
      longitude,
      generalizationId,
      workingTimeDescription,
      description,
      cb
    ) {
      let salon = {
        Name: name,
        Address: address,
        WebAddress: webAddress,
        Phone: phone,
        MobilePhone: mobilePhone,
        Email: email,
        SalonTimeSlot: salonTimeSlot || process.env.DEFAULT_TIME_SLOT_DURATION,
        CustomerSchedulePeriod:
          customerSchedulePeriod ||
          process.env.DEFAULT_CUSTOMER_SCHEDULE_PERIOD,
        EmbeddedMapAddress: embeddedMapAddress,
        Latitude: latitude,
        Longitude: longitude,
        GeneralizationId: generalizationId,
        WorkingTimeDescription: workingTimeDescription,
        Description: description,
      };
      cb();
      dispatch(mutations.createSalon(salon));
    },
    saveSalon(
      salonId,
      name,
      address,
      webAddress,
      phone,
      mobilePhone,
      email,
      salonTimeSlot,
      customerSchedulePeriod,
      embeddedMapAddress,
      latitude,
      longitude,
      generalizationId,
      workingTimeDescription,
      description,
      cb
    ) {
      let salon = {
        SalonId: salonId,
        Name: name,
        Address: address,
        WebAddress: webAddress,
        Phone: phone,
        MobilePhone: mobilePhone,
        Email: email,
        SalonTimeSlot: salonTimeSlot || null,
        CustomerSchedulePeriod: customerSchedulePeriod || null,
        EmbeddedMapAddress: embeddedMapAddress,
        Latitude: latitude,
        Longitude: longitude,
        GeneralizationId: generalizationId,
        WorkingTimeDescription: workingTimeDescription,
        Description: description,
      };
      cb();
      dispatch(mutations.saveSalon(salon));
    },
    saveSalonForId(
      salonId,
      name,
      address,
      webAddress,
      phone,
      mobilePhone,
      email,
      salonTimeSlot,
      customerSchedulePeriod,
      embeddedMapAddress,
      latitude,
      longitude,
      generalizationId,
      workingTimeDescription,
      description,
      cb
    ) {
      let salon = {
        SalonId: salonId,
        Name: name,
        Address: address,
        WebAddress: webAddress,
        Phone: phone,
        MobilePhone: mobilePhone,
        Email: email,
        SalonTimeSlot: salonTimeSlot || null,
        CustomerSchedulePeriod: customerSchedulePeriod || null,
        EmbeddedMapAddress: embeddedMapAddress,
        Latitude: latitude,
        Longitude: longitude,
        GeneralizationId: generalizationId,
        WorkingTimeDescription: workingTimeDescription,
        Description: description,
      };
      dispatch(mutations.saveSalonForId(salon, cb));
    },
    deleteSalon(salonId, cb) {
      let salon = {
        SalonId: salonId,
      };
      cb();
      dispatch(mutations.deleteSalon(salon));
    },
    createSalonWorkingTime(
      salonId,
      currentDate,
      startTime,
      endTime,
      checkboxInputs,
      cb
    ) {
      const startHours = moment(startTime).format("HH");
      const startMinutes = moment(startTime).format("mm");

      let startDate = moment(currentDate)
        .hour(startHours)
        .minute(startMinutes)
        .format("YYYY-MM-DDTHH:mm:ss");

      const endHours = moment(startTime).format("HH");
      const endMinutes = moment(startTime).format("mm");

      let endDate = moment(currentDate)
        .hour(endHours)
        .minute(endMinutes)
        .format("YYYY-MM-DDTHH:mm:ss");

      // cb();
      dispatch(
        mutations.createSalonWorkingTime(
          salonId,
          startDate,
          endDate,
          checkboxInputs,
          cb
        )
      );
    },
    createSalonWorkingTimeAuto(salonId, workingTimeList, cb) {
      dispatch(
        mutations.createSalonWorkingTimeAuto(salonId, workingTimeList, cb)
      );
    },
    saveSalonWorkingTime(
      workingTimeId,
      salonId,
      currentDate,
      startTime,
      endTime,
      checkboxInputs,
      cb
    ) {
      let startTimeString = moment(startTime).format("HH:mm");
      let endTimeString = moment(endTime).format("HH:mm");
      let startDate = moment(currentDate + " " + startTimeString).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      let endDate = moment(currentDate + " " + endTimeString).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      cb();
      dispatch(
        mutations.saveSalonWorkingTime(
          workingTimeId,
          salonId,
          startDate,
          endDate,
          checkboxInputs
        )
      );
    },
    deleteSalonWorkingTime(workingTimeId, salonId, cb) {
      cb();
      dispatch(mutations.deleteSalonWorkingTime(workingTimeId, salonId));
    },
    deleteSalonWorkingTimeConsolidated(workingTimeConsolidated, cb) {
      cb();
      dispatch(
        mutations.deleteSalonWorkingTimeConsolidated(workingTimeConsolidated)
      );
    },
    getSalonWorkingTimeIsDateCreated(salonId, startDate) {
      dispatch(mutations.getSalonWorkingTimeIsDateCreated(salonId, startDate));
    },

    createServiceType(serviceTypeForCreate, cb) {
      cb();
      dispatch(mutations.createServiceType(serviceTypeForCreate));
    },
    saveServiceType(serviceTypeForUpdate, cb) {
      cb();
      dispatch(mutations.saveServiceType(serviceTypeForUpdate));
    },
    deleteServiceType(serviceTypeForDelete, cb) {
      cb();
      dispatch(mutations.deleteServiceType(serviceTypeForDelete));
    },
    activateServiceType(serviceTypeForActivate, cb) {
      cb();
      dispatch(mutations.activateServiceType(serviceTypeForActivate));
    },
    setAllFreeSalonTimeSlots(slots) {
      dispatch(mutations.setAllFreeSalonTimeSlots(slots));
    },
  };
};

const ConnectedSalon = connect(
  mapStateToProps,
  mapDispatchToProps
)(SalonComponent);

export default ConnectedSalon;
