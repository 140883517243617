import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import * as moment from "moment";

import { ConnectedSalonsDropDown } from "../Dropdowns/SalonsDropDown";
import { ConnectedHairstylistsDropDown } from "../Dropdowns/HairstylistsDropDown";
import { ConnectedServiceTypesDropDown } from "../Dropdowns/ServiceTypesDropDown";
import { ConnectedFreeSlotsComponent } from "./FreeSlotComponent";
import { useTranslation } from "react-i18next";

const TimeSlotTest = ({ match, activeUser, getActiveUser }) => {
  const { t, i18n } = useTranslation();

  document.getElementsByTagName("html")[0].classList.remove("page-signin");

  const [description, setDescription] = useState("");
  const [hairstylistId, setHairstylistId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [salonId, setSalonId] = useState("");
  const [serviceTypes, setServiceTypes] = useState("");
  const [statusId, setStatusId] = useState("");
  const [visitId, setVisitId] = useState("");
  const [serviceTypeId, setServiceTypeId] = useState("");
  const [tableVisible, setTableVisible] = useState(false);
  const [salonTimeSlot, setSalonTimeSlot] = useState("");

  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add("grid-view");

    if (!activeUser || !activeUser.personId) {
      getActiveUser();
    }
    return () => {
      document.getElementsByTagName("html")[0].classList.remove("grid-view");
    };
  }, []);

  return (
    <div>
      <form className="form">
        <div className="form-group">
          <label htmlFor="salonId">
            {helper.i18nMultiFallbackKey("home.timeSlotTest.shopLabel", "Shop")}
          </label>
          <ConnectedSalonsDropDown
            salonId={salonId}
            setSalonId={setSalonId}
            setServiceTypes={setServiceTypes}
            setSalonTimeSlot={setSalonTimeSlot}
            id="salonId"
          />
          <div className="invalid-feedback">
            {helper.i18nMultiFallbackKey(
              "home.timeSlotTest.shopInvalid",
              "Please select your shop."
            )}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="hairstylistId">
            {helper.i18nMultiFallbackKey(
              "home.timeSlotTest.hairstylistLabel",
              "Provider"
            )}
          </label>
          <ConnectedHairstylistsDropDown
            hairstylistId={hairstylistId}
            setHairstylistId={setHairstylistId}
            salonId={salonId}
            id="hairstylistId"
          />
          <div className="invalid-feedback">
            {helper.i18nMultiFallbackKey(
              "home.timeSlotTest.hairstylistInvalid",
              "Please select your provider."
            )}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="serviceTypeId">
            {t("home.timeSlotTest.serviceTypeLabel", "Service type")}
          </label>
          <ConnectedServiceTypesDropDown
            serviceTypeId={serviceTypeId}
            setServiceTypeId={setServiceTypeId}
            serviceTypes={serviceTypes}
            salonId={salonId}
            id="serviceTypeId"
          />
          <div className="invalid-feedback">
            {t(
              "home.timeSlotTest.serviceTypeInvalid",
              "Please select your service type."
            )}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="visitDate">
            {t("home.timeSlotTest.visitDateLabel", "Visit date")}
          </label>
          <input
            type="date"
            className="form-control"
            id="visitDate"
            placeholder={t(
              "home.timeSlotTest.visitDatePlaceholder",
              "Please enter visitDate"
            )}
            value={startTime}
            onChange={event => {
              setStartTime(event.target.value);
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="slotTimeComponent">
            {t("home.timeSlotTest.timeSlotLabel", "Time slots")}
          </label>
          <ConnectedFreeSlotsComponent
            salonId={salonId}
            hairstylistId={hairstylistId}
            selectedServices={[serviceTypeId]}
            visitDate={moment(startTime).format("YYYY-MM-DDTHH:mm:ss")}
            id="slotTimeComponent"
          />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    match: ownProps.match,
    activeUser: state.activeUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getActiveUser() {
      if (sessionStorage.getItem("user_id")) {
        dispatch(
          mutations.getActiveUser(JSON.parse(sessionStorage.getItem("user_id")))
        );
      }
    }
  };
};

export const ConnectedTimeSlotTest = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeSlotTest);
