import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { withTranslation } from "react-i18next";

class RolesDropDownComponent extends Component {
  componentDidMount() {
    this.props.getAllRoles();
  }

  render() {
    return (
      <div className="custom-select-box">
        <select
          className="form-control"
          value={this.props.roleId}
          onChange={(event) => {
            this.props.setRoleId(event.target.value);
            this.props.setRole(
              this.props.roles.find((r) => r.roleId === event.target.value)
            );
          }}
          required
        >
          <option key={""} value={""}>
            {this.props.t(
              "dropdowns.rolesDropDownComponent.label",
              "Select role"
            )}
          </option>
          {this.props.roles.map((r) => {
            return (
              <option key={r.roleId} value={r.roleId}>
                {r.name}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    roles: state.roles,
    roleId: ownProps.roleId,
    setRoleId: ownProps.setRoleId,
    setRole: ownProps.setRole,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllRoles() {
    dispatch(mutations.getAllRoles());
  },
});

export const ConnectedRolesDropDown = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(RolesDropDownComponent)
);
