import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";

import * as Helper from "../HelperRepository";
import { useTranslation } from "react-i18next";

const GeneralizationsDropDownComponent = ({
  generalizations,
  generalizationId,
  setGeneralizationId,
  getAllGeneralizations,
}) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getAllGeneralizations();
  }, []);

  if (Helper.isArray(generalizations)) {
    return (
      <div className="custom-select-box">
        <select
          className="form-control"
          value={generalizationId}
          onChange={(event) => {
            setGeneralizationId(event.target.value);
          }}
          required
        >
          <option key={""} value={""}>
            {t(
              "dropdowns.generalizationsDropDownComponent.label",
              "Select generalization"
            )}
          </option>
          {generalizations.map((r) => {
            return (
              <option key={r.generalizationId} value={r.generalizationId}>
                {r.description}
              </option>
            );
          })}
        </select>
      </div>
    );
  } else {
    return (
      <div className="custom-select-box">
        <select
          className="form-control"
          value={generalizationId}
          onChange={(event) => {
            setGeneralizationId(event.target.value);
          }}
          required
        >
          <option key={""} value={""}>
            {t(
              "dropdowns.generalizationsDropDownComponent.loadingGeneralizations",
              "Loading generalizations..."
            )}
          </option>
          <option
            key={generalizations.generalizationId}
            value={generalizations.generalizationId}
          >
            {generalizations.description}
          </option>
        </select>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    generalizations: state.generalizations,
    generalizationId: ownProps.generalizationId,
    setGeneralizationId: ownProps.setGeneralizationId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllGeneralizations() {
    dispatch(mutations.getAllGeneralizations());
  },
});

export const ConnectedGeneralizationsDropDown = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralizationsDropDownComponent);
