import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as moment from "moment";
import Picker from "react-month-picker";
import "react-month-picker/css/month-picker.css";
import { MonthBox } from "./MonthBox";
import { useTranslation } from "react-i18next";

const CalendarMonthlyComponent = ({
  salonId,
  hairstylistId,
  getVisits,
  visits,
  currentMonthStart,
  setCurrentMonthStart,
  dashboardDataMonthly,
  setCurrentDate,
  setCurrentWeekStart,
}) => {
  // const [currentMonth, setCurrentMonth] = useState({
  //   year: moment(currentMonthStart, "DD.MM.YYYY.").year(),
  //   month: moment(currentMonthStart, "DD.MM.YYYY.").month() + 1
  // });
  const { t, i18n } = useTranslation();

  const [showMonthPicker, setShowMonthPicker] = useState(false);

  useEffect(() => {}, [hairstylistId, salonId]);
  const getDayOfTheWeek = (index) => {
    switch (index) {
      case 1:
        return t("home.calendarMonthlyComponent.monday", "Monday");
      case 2:
        return t("home.calendarMonthlyComponent.tuesday", "Tuesday");
      case 3:
        return t("home.calendarMonthlyComponent.wednesday", "Wednesday");
      case 4:
        return t("home.calendarMonthlyComponent.thursday", "Thursday");
      case 5:
        return t("home.calendarMonthlyComponent.friday", "Friday");
      case 6:
        return t("home.calendarMonthlyComponent.saturday", "Saturday");
      case 7:
        return t("home.calendarMonthlyComponent.sunday", "Sunday");
      default:
        break;
    }
  };
  let pickerLang = {
    months: [
      t("home.calendarMonthlyComponent.januaryShort", "Jan"),
      t("home.calendarMonthlyComponent.februaryShort", "Feb"),
      t("home.calendarMonthlyComponent.marchShort", "Mar"),
      t("home.calendarMonthlyComponent.aprilShort", "Apr"),
      t("home.calendarMonthlyComponent.mayShort", "May"),
      t("home.calendarMonthlyComponent.juneShort", "Jun"),
      t("home.calendarMonthlyComponent.julyShort", "Jul"),
      t("home.calendarMonthlyComponent.augustShort", "Aug"),
      t("home.calendarMonthlyComponent.septemberShort", "Sep"),
      t("home.calendarMonthlyComponent.octoberShort", "Oct"),
      t("home.calendarMonthlyComponent.novemberShort", "Nov"),
      t("home.calendarMonthlyComponent.decemberShort", "Dec"),
    ],
  };

  let makeText = (m) => {
    if (m && m.year && m.month)
      return pickerLang.months[m.month - 1] + ". " + m.year;
    return "?";
  };
  return (
    <div className="col-12 calendar-month-holder">
      <h5>
        {t(
          "home.calendarMonthlyComponent.calendarMonthly.title",
          "Calendar Monthly"
        )}
      </h5>

      <div className="card mb-3">
        <div className="card-body">
          <label className="column-title-muted">
            {t(
              "home.calendarMonthlyComponent.calendarMonthly.monthLabel",
              "Month"
            )}
          </label>
          <div className="calendar-header text-center">
            <div className="calendar-controls d-inline-flex align-items-stretch justify-content-center">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={(e) => {
                  let d = moment(currentMonthStart, "DD.MM.YYYY.")
                    .add(-1, "months")
                    .format("DD.MM.YYYY.");
                  setCurrentMonthStart(d);
                  setCurrentWeekStart(
                    moment(d, "DD.MM.YYYY.")
                      .startOf("isoWeek")
                      .format("DD.MM.YYYY.")
                  );
                  setCurrentDate(d);
                }}
              >
                <i className="fal fa-chevron-left"></i>
              </button>
              {/* <span
                onClick={e => {
                  setShowMonthPicker(!showMonthPicker);
                }}
                className="text-dark calendar-type-value"
              >
                {" "}
                {moment(currentMonthStart, "DD.MM.YYYY.").format("MMMM YYYY.")}
              </span>
              {showMonthPicker && (
                <input
                  type="month"
                  id="currentMonth"
                  name="currentMonth"
                  min="2018-01"
                  value={currentMonth}
                  onChange={e => {
                    let d = moment(e.target.value, "YYYY-MM").format(
                      "DD.MM.YYYY."
                    );
                    setCurrentMonth(e.target.value);
                    setCurrentMonthStart(d);
                    setCurrentDate(d);
                    setCurrentWeekStart(
                      moment(d, "DD.MM.YYYY.")
                        .startOf("isoWeek")
                        .format("DD.MM.YYYY.")
                    );
                  }}
                ></input>
              )} */}
              <span className="text-dark calendar-type-value">
                <Picker
                  show={showMonthPicker}
                  years={[
                    2015,
                    2016,
                    2017,
                    2018,
                    2019,
                    2020,
                    2021,
                    2022,
                    2023,
                    2024,
                    2025,
                  ]}
                  value={{
                    year: moment(currentMonthStart, "DD.MM.YYYY.").year(),
                    month: moment(currentMonthStart, "DD.MM.YYYY.").month() + 1,
                  }}
                  lang={pickerLang.months}
                  onChange={(year, month) => {
                    let d = moment([year, month - 1]).format("DD.MM.YYYY.");
                    setCurrentMonthStart(d);
                    setCurrentDate(d);
                    setCurrentWeekStart(
                      moment(d, "DD.MM.YYYY.")
                        .startOf("isoWeek")
                        .format("DD.MM.YYYY.")
                    );
                  }}
                  onDismiss={() => setShowMonthPicker(false)}
                >
                  <MonthBox
                    className="text-dark calendar-type-value"
                    value={makeText({
                      year: moment(currentMonthStart, "DD.MM.YYYY.").year(),
                      month:
                        moment(currentMonthStart, "DD.MM.YYYY.").month() + 1,
                    })}
                    onClick={() => setShowMonthPicker(true)}
                  />
                </Picker>
              </span>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={(e) => {
                  let d = moment(currentMonthStart, "DD.MM.YYYY.")
                    .add(1, "months")
                    .format("DD.MM.YYYY.");
                  setCurrentMonthStart(d);
                  setCurrentWeekStart(
                    moment(d, "DD.MM.YYYY.")
                      .startOf("isoWeek")
                      .format("DD.MM.YYYY.")
                  );
                  setCurrentDate(d);
                }}
              >
                <i className="fal fa-chevron-right"></i>
              </button>
            </div>
          </div>
          <div className="row pt-2">
            {dashboardDataMonthly.map((weekSummary, i) => {
              return (
                <div key={i} className="col-lg-6 col-xl-4 mb-2">
                  <h6>
                    {t(
                      "home.calendarMonthlyComponent.calendarMonthly.weekLabel",
                      "Week:"
                    )}
                    <span className="d-inline-block">
                      {moment(weekSummary.daySummaries[0].summaryDate).format(
                        "DD.MM.YYYY."
                      )}{" "}
                      -{" "}
                      {moment(
                        weekSummary.daySummaries[
                          weekSummary.daySummaries.length - 1
                        ].summaryDate
                      ).format("DD.MM.YYYY.")}
                    </span>
                  </h6>
                  <div className="list-group calendar-appointment">
                    {new Array(
                      moment(
                        weekSummary.daySummaries[0].summaryDate
                      ).isoWeekday() - 1
                    )
                      .fill(false)
                      .map((x, index) => {
                        return (
                          <a
                            key={index}
                            className="list-group-item list-group-item-action"
                          >
                            <h5 className="appointment-time"></h5>
                            <div className="appointment-description"></div>
                          </a>
                        );
                      })}
                    {weekSummary.daySummaries.map((daySummary, index) => {
                      return (
                        <a
                          key={index}
                          className="list-group-item list-group-item-action"
                          onClick={() => {
                            setCurrentDate(
                              moment(daySummary.summaryDate).format(
                                "DD.MM.YYYY."
                              )
                            );
                          }}
                        >
                          <h5 className="appointment-time">
                            {getDayOfTheWeek(
                              moment(daySummary.summaryDate).isoWeekday()
                            )}
                          </h5>
                          <div className="appointment-description">
                            <span className="badge badge-primary mr-1">
                              {daySummary.reservedSlotsCount}
                            </span>{" "}
                            <small className="text-muted">
                              {daySummary.freeSlotsCount}{" "}
                              {t(
                                "home.calendarMonthlyComponent.calendarMonthly.freeSlotsCountLabel",
                                "is free"
                              )}
                            </small>
                          </div>
                        </a>
                      );
                    })}
                    {/* <a
                      href="#"
                      className="list-group-item list-group-item-action"
                    >
                      <h5 className="appointment-time">Ponedeljak</h5>
                      <div className="appointment-description">
                        <span className="badge badge-primary mr-1">14</span>{" "}
                        <small className="text-muted">Usluga</small>
                      </div>
                    </a>
                    <a
                      href="#"
                      className="list-group-item list-group-item-action"
                    >
                      <h5 className="appointment-time">Utorak</h5>
                      <div className="appointment-description">
                        <span className="badge badge-primary mr-1">14</span>{" "}
                        <small className="text-muted">Usluga</small>
                      </div>
                    </a>
                    <a
                      href="#"
                      className="list-group-item list-group-item-action"
                    >
                      <h5 className="appointment-time">Sreda</h5>
                      <div className="appointment-description">
                        <span className="badge badge-primary mr-1">14</span>{" "}
                        <small className="text-muted">Usluga</small>
                      </div>
                    </a>
                    <a
                      href="#"
                      className="list-group-item list-group-item-action"
                    >
                      <h5 className="appointment-time">Četvrtak</h5>
                      <div className="appointment-description">
                        <span className="badge badge-primary mr-1">14</span>{" "}
                        <small className="text-muted">Usluga</small>
                      </div>
                    </a>
                    <a
                      href="#"
                      className="list-group-item list-group-item-action"
                    >
                      <h5 className="appointment-time">Petak</h5>
                      <div className="appointment-description">
                        <span className="badge badge-primary mr-1">14</span>{" "}
                        <small className="text-muted">Usluga</small>
                      </div>
                    </a>
                    <a
                      href="#"
                      className="list-group-item list-group-item-action"
                    >
                      <h5 className="appointment-time">Subota</h5>
                      <div className="appointment-description">
                        <span className="badge badge-primary mr-1">14</span>{" "}
                        <small className="text-muted">Usluga</small>
                      </div>
                    </a>
                    <a
                      href="#"
                      className="list-group-item list-group-item-action"
                    >
                      <h5 className="appointment-time">Nedelja</h5>
                      <div className="appointment-description">
                        <span className="badge badge-primary mr-1">14</span>{" "}
                        <small className="text-muted">Usluga</small>
                      </div>
                    </a> */}
                  </div>
                </div>
              );
            })}
            {/* <div className="col-lg-6 mb-2">
              <h6>Week: 01 - 07.12.2019.</h6>
              <div className="list-group calendar-appointment">
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Ponedeljak</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Utorak</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Sreda</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Četvrtak</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Petak</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Subota</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Nedelja</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-6 mb-2">
              <h6>Week: 08 - 15.12.2019.</h6>
              <div className="list-group calendar-appointment">
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Ponedeljak</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Utorak</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Sreda</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Četvrtak</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Petak</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Subota</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Nedelja</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-6 mb-2">
              <h6>Week: 16 - 24.12.2019.</h6>
              <div className="list-group calendar-appointment">
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Ponedeljak</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Utorak</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Sreda</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Četvrtak</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Petak</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Subota</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Nedelja</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-6 mb-2">
              <h6>Week: 25 - 31.12.2019.</h6>
              <div className="list-group calendar-appointment">
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Ponedeljak</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Utorak</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Sreda</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Četvrtak</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Petak</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Subota</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
                <a href="#" className="list-group-item list-group-item-action">
                  <h5 className="appointment-time">Nedelja</h5>
                  <div className="appointment-description">
                    <span className="badge badge-primary mr-1">14</span>{" "}
                    <small className="text-muted">Usluga</small>
                  </div>
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* <div className="card bg-dark-gray text-white text-size-md">
        <div className="card-body">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eu
          odio metus. Sed suscipit blandit est, eu feugiat turpis aliquam in.
          Nam ligula nulla, elementum non sem eu, rhoncus pellentesque ligula.
          Etiam eu vestibulum justo. Maecenas efficitur consequat luctus. Mauris
          volutpat lacus tincidunt, vehicula felis quis, luctus neque.
          Suspendisse luctus dapibus nunc vitae interdum. Mauris hendrerit, sem
          a commodo sodales, turpis turpis venenatis nibh, quis blandit leo quam
          in mauris..
        </div>
      </div> */}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    salonId: ownProps.salonId,
    hairstylistId: ownProps.hairstylistId,
    currentMonthStart: ownProps.currentMonthStart,
    dashboardDataMonthly: state.dashboardDataMonthly,
    setCurrentDate: ownProps.setCurrentDate,
    setCurrentWeekStart: ownProps.setCurrentWeekStart,
    setCurrentMonthStart: ownProps.setCurrentMonthStart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export const ConnectedCalendarMonthly = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarMonthlyComponent);
