import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { withTranslation } from "react-i18next";

class BaseSalonsDropDownComponent extends Component {
  componentDidMount() {
    this.props.getAllSalons();
  }

  render() {
    return (
      <div className="custom-select-box">
        <select
          className="form-control"
          value={this.props.salonId}
          onChange={(event) => {
            this.props.setSalonId(event.target.value);
          }}
          required
        >
          <option key={""} value={""}>
            {helper.i18nMultiFallbackKey(
              "dropdowns.baseSalonsDropDownComponent.label",
              "Select shop"
            )}{" "}
          </option>
          {this.props.salons.map((r) => {
            return (
              <option key={r.salonId} value={r.salonId}>
                {r.name}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    salons: state.salons,
    salonId: ownProps.salonId,
    setSalonId: ownProps.setSalonId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllSalons() {
    dispatch(mutations.getAllSalons());
  },
});

export const ConnectedBaseSalonsDropDown = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(BaseSalonsDropDownComponent)
);
