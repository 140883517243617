import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { withTranslation } from "react-i18next";

class HairstylistServiceTypesDropDownComponent extends Component {
  componentDidMount() {
    this.props.getAllHairstylistServiceTypesForHairstylist(
      this.props.hairstylistId,
      this.props.salonId
    );
  }

  render() {
    if (this.props.hairstylistServiceTypes.length > 0) {
      return (
        <div className="custom-select-box">
          <select
            className="form-control"
            value={this.props.serviceTypeId}
            onChange={(event) => {
              this.props.setServiceTypeId(event.target.value);
            }}
            required
          >
            <option key={""} value={""}>
              {this.props.t(
                "dropdowns.hairstylistServiceTypesDropDownComponent.label",
                "Select serviceType"
              )}
            </option>
            {this.props.hairstylistServiceTypes.map((r) => {
              return (
                <option key={r.serviceTypeId} value={r.serviceTypeId}>
                  {r.serviceType.serviceTypeName}
                </option>
              );
            })}
          </select>
        </div>
      );
    } else {
      return (
        <div className="custom-select-box">
          <select
            className="form-control"
            value={this.props.serviceTypeId}
            onChange={(event) =>
              this.props.setServiceTypeId(event.target.value)
            }
            required
          >
            <option key={""} value={""}>
              {this.props.t(
                "dropdowns.hairstylistServiceTypesDropDownComponent.label",
                "Select serviceType"
              )}
            </option>
          </select>
        </div>
      );
    }
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    hairstylistServiceTypes: state.hairstylistServiceTypes,
    hairstylistId: ownProps.hairstylistId,
    setServiceTypeId: ownProps.setServiceTypeId,
    serviceTypeId: ownProps.serviceTypeId,
    salonId: ownProps.salonId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllHairstylistServiceTypesForHairstylist(hairstylistId, salonId) {
    dispatch(
      mutations.getAllHairstylistServiceTypesForHairstylist(
        hairstylistId,
        salonId
      )
    );
  },
});

export const ConnectedHairstylistServiceTypesDropDownComponent = withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HairstylistServiceTypesDropDownComponent)
);
