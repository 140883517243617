import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import * as moment from "moment";
import { withTranslation } from "react-i18next";

class FreeSlotsPopupComponent extends Component {
  state = {
    checkboxInputs: [...this.props.freeVisitTimeSlots],
    activeSlot: -1,
    newSlot: -1,
  };
  componentDidMount() {
    this.setState({ initialLoad: true });
  }

  componentWillReceiveProps(props) {
    this.setState({ checkboxInputs: [...props.freeVisitTimeSlots] });
  }
  componentDidUpdate(prevProps) {
    if (this.props.visitDate !== prevProps.visitDate) {
      this.setState({ initialLoad: true });
    }
    if (this.props.hairstylistId !== prevProps.hairstylistId) {
      this.setState({ initialLoad: true });
    }
    if (this.props.salonId !== prevProps.salonId) {
      this.setState({ initialLoad: true });
    }
    if (
      this.props.salonId !== "" &&
      this.props.hairstylistId !== "" &&
      this.props.selectedServices.length > 0 &&
      this.props.visitDate !== "" &&
      this.props.visitDate !== "Invalid date"
    ) {
      if (this.state.initialLoad) {
        this.setState({ initialLoad: false });
        this.reload();
      }
    }
  }
  reload = () => {
    this.props.getAllFreeVisitTimeSlots(
      this.props.salonId,
      this.props.hairstylistId,
      this.props.visitDate,
      this.props.selectedServices
    );
  };

  slotType = (slotData) => {
    if (slotData.timeSlotFree && !slotData.visitTimeSlot) {
      return "time-item free";
    }

    if (!slotData.timeSlotFree && !slotData.visitTimeSlot) {
      return "time-item selected";
    }

    if (!slotData.timeSlotFree && slotData.visitTimeSlot) {
      return "time-item choosed";
    }

    if (slotData.timeSlotFree && slotData.visitTimeSlot) {
      return "time-item changed";
    }
  };

  available = (status) => {
    if (status) {
      return "yes";
    } else {
      return "no";
    }
  };

  setParentTime = (startTime, endTime) => {
    const startHours = moment(startTime).format("HH");
    const startMinutes = moment(startTime).format("mm");

    this.props.setStartTime(
      moment(this.props.visitDate)
        .hour(startHours)
        .minute(startMinutes)
        .format("YYYY-MM-DD HH:mm")
    );

    this.props.setEndTime(
      moment(this.props.visitDate)
        .hour(startHours)
        .minute(startMinutes)
        .add(this.props.salonTimeSlot, "minutes")
        .format("YYYY-MM-DD HH:mm")
    );
  };

  render() {
    return (
      <>
        <div className="form-group">
          <div className="date-time-picker modal-picker">
            <div className="date-time-content flex-wrap">
              <div className="legend-bar">
                <span className="reserved">
                  {this.props.t(
                    "home.freeSlotsPopupComponent.reserved",
                    "Reserved"
                  )}
                </span>
                <span className="free">
                  {this.props.t("home.freeSlotsPopupComponent.free", "Free")}
                </span>
                <span className="choosed">
                  {this.props.t(
                    "home.freeSlotsPopupComponent.choosed",
                    "Choosed"
                  )}
                </span>
                <span className="changed">
                  {this.props.t(
                    "home.freeSlotsPopupComponent.changed",
                    "Changed"
                  )}
                </span>
              </div>
              {/* {this.props.freeVisitTimeSlots.map((r, index) => { */}
              {this.state.checkboxInputs.map((r, index) => {
                if (r.timeSlotFree && !r.visitTimeSlot) {
                  return (
                    <div
                      className={this.slotType(r)}
                      key={r.timeIntervalGuid}
                      onClick={(event) => {
                        this.setParentTime(r.startTime, r.endTime);

                        let inputs = [...this.state.checkboxInputs];

                        if (this.state.newSlot !== -1) {
                          inputs[this.state.newSlot].visitTimeSlot = false;
                        }

                        inputs[index].visitTimeSlot = !inputs[index]
                          .visitTimeSlot;
                        this.setState({ newSlot: index });
                        this.setState({ checkboxInputs: [...inputs] });
                      }}
                      required
                    >
                      {moment(r.startTime).format("HH:mm")}
                    </div>
                  );
                }
                if (r.timeSlotFree && r.visitTimeSlot) {
                  return (
                    <div
                      className={this.slotType(r)}
                      key={r.timeIntervalGuid}
                      onClick={(event) => {
                        let inputs = [...this.state.checkboxInputs];

                        for (let i = 0; i < inputs.length; i++) {
                          if (
                            !inputs[i].timeSlotFree &&
                            inputs[i].visitTimeSlot
                          ) {
                            this.setParentTime(
                              inputs[i].startTime,
                              inputs[i].endTime
                            );
                          }
                        }

                        inputs[index].visitTimeSlot = !inputs[index]
                          .visitTimeSlot;

                        this.setState({ checkboxInputs: [...inputs] });
                      }}
                      required
                    >
                      {moment(r.startTime).format("HH:mm")}
                    </div>
                  );
                }
                if (!r.timeSlotFree && r.visitTimeSlot) {
                  return (
                    <div
                      className={this.slotType(r)}
                      key={r.timeIntervalGuid}
                      required
                    >
                      {moment(r.startTime).format("HH:mm")}
                    </div>
                  );
                }

                return (
                  <div
                    className={this.slotType(r)}
                    key={r.timeIntervalGuid}
                    required
                  >
                    {moment(r.startTime).format("HH:mm")}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToPropsFreeSlots = (state, ownProps) => {
  return {
    salons: state.salons,
    salonId: ownProps.salonId,
    hairstylistId: ownProps.hairstylistId,
    selectedServices: ownProps.selectedServices,
    visitDate: ownProps.visitDate,
    freeVisitTimeSlots: state.freeVisitTimeSlots,
    setStartTime: ownProps.setStartTime,
    setEndTime: ownProps.setEndTime,
    salonTimeSlot: ownProps.salonTimeSlot,
  };
};

const mapDispatchToPropsFreeSlots = (dispatch) => ({
  getAllFreeVisitTimeSlots(
    salonId,
    hairstylistId,
    visitDate,
    selectedServices
  ) {
    let freeTimeSlotRequest = {
      SalonId: salonId,
      HairstylistId: hairstylistId,
      VisitDate: visitDate,
      SelectedServices: selectedServices,
    };
    dispatch(mutations.getAllFreeVisitTimeSlots(freeTimeSlotRequest));
  },
});

export const ConnectedFreeSlotsPopupComponent = withTranslation()(
  connect(
    mapStateToPropsFreeSlots,
    mapDispatchToPropsFreeSlots
  )(FreeSlotsPopupComponent)
);
