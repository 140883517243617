import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../../store/mutations";
import { ConnectedServiceTypesDropDown } from "../../Dropdowns/ServiceTypesDropDown";
import { withTranslation } from "react-i18next";

class HairstylistServiceTypeCreate extends Component {
  state = {
    name: "",
    price: "",
    duration: "",
    description: "",
    serviceTypeId: "",
  };
  handleServiceTypeIdChange = (newServiceTypeId) => {
    this.setState({ serviceTypeId: newServiceTypeId || "" });
  };

  filterServiceTypes = (allServiceTypes) => {
    let arrayToUse = allServiceTypes.filter((serviceType) => {
      let toReturn = true;
      for (let hairstylistServiceType of this.props.hairstylistServiceTypes) {
        if (
          serviceType.serviceTypeId === hairstylistServiceType.serviceTypeId
        ) {
          toReturn = false;
        }
      }
      return toReturn;
    });

    return arrayToUse;
  };

  render() {
    return (
      <div
        className="modal fade"
        data-backdrop="static"
        data-keyboard="false"
        id="activateHairstylistServiceTypeModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="activateHairstylistServiceTypeModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="activateHairstylistServiceTypeModalLabel"
              >
                <i className="fal fa-cut fa-lg c-purple" />{" "}
                {helper.i18nMultiFallbackKey("hairstylist.hairstylistServiceTypeCreate.activateHairstylistServiceTypeModal.title", "Activate Provider Service Type")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fal fa-times" />
              </button>
            </div>

            <div className="modal-body">
              <form className="form needs-validation" noValidate>
                <input type="hidden" value="something" />
                <div className="form-group">
                  <label htmlFor="serviceTypeId">
                    {helper.i18nMultiFallbackKey(
                      "hairstylist.hairstylistServiceTypeCreate.activateHairstylistServiceTypeModal.serviceTypesFromShoplabel",
                      "Service types from shop"
                    )}
                    {this.props.selectedSalonHairstylist.salon.name}
                  </label>
                  <ConnectedServiceTypesDropDown
                    serviceTypeId={this.state.serviceTypeId}
                    setServiceTypeId={this.handleServiceTypeIdChange}
                    serviceTypes={this.filterServiceTypes(
                      this.props.selectedSalon.serviceTypes
                    )}
                    salonId={this.props.selectedSalon.salonId}
                    id="serviceTypeId"
                  />
                  <div className="invalid-feedback">
                    {helper.i18nMultiFallbackKey(
                      "hairstylist.hairstylistServiceTypeCreate.activateHairstylistServiceTypeModal.serviceTypesFromShopInvalid",
                      "Please select your service type."
                    )}
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger ico-l"
                data-dismiss="modal"
              >
                <i className="fal fa-ban" />{" "}
                {this.props.t("common.cancel", "Cancel")}
              </button>
              <button
                type="button"
                className="btn btn-outline-success ico-l"
                data-dismiss="modal"
                onClick={() => {
                  let hairstylistServiceTypeForCreate = {
                    ServiceTypeId: this.state.serviceTypeId,
                    HairstylistId: this.props.selectedSalonHairstylist
                      .hairstylistId,
                  };

                  this.props.createHairstylistServiceType(
                    hairstylistServiceTypeForCreate,
                    () => this.props.clearParentState()
                  );
                }}
              >
                <i className="fal fa-check" />{" "}
                {this.props.t("common.finish", "Finish")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPropsFreeSlots = (state, ownProps) => {
  return {
    selectedSalon: ownProps.selectedSalon,
    clearParentState: ownProps.clearParentState,
    hairstylistServiceTypes: state.hairstylistServiceTypes,
    selectedSalonHairstylist: ownProps.selectedSalonHairstylist,
  };
};

const mapDispatchToPropsFreeSlots = (dispatch) => ({
  createHairstylistServiceType(hairstylistServiceType, cb) {
    cb();
    dispatch(mutations.createHairstylistServiceType(hairstylistServiceType));
  },
});

export const ConnectedHairstylistServiceTypeCreate = withTranslation()(
  connect(
    mapStateToPropsFreeSlots,
    mapDispatchToPropsFreeSlots
  )(HairstylistServiceTypeCreate)
);
