import { applyMiddleware, createStore, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";

import { reducer } from "./reducer";
import * as sagas from "./sagas";

const sagaMiddleware = createSagaMiddleware();

// Sa Saga Dev Tools
// const composeEnhancers =
//   (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//       trace: true,
//       traceLimit: 25
//     })) ||
//   compose;
// export const store = createStore(
//   reducer,
//   composeEnhancers(applyMiddleware(createLogger(), sagaMiddleware))
// );

// Bez Saga Dev Tools
let middleWareList = [];
if (process.env.CURRENT_ENV === "PROD") {
  middleWareList = [sagaMiddleware];
} else {
  middleWareList = [sagaMiddleware];
}

export const store = createStore(reducer, applyMiddleware(...middleWareList));

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}

for (let saga in sagas) {
  sagaMiddleware.run(sagas[saga]);
}
