import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { withTranslation } from "react-i18next";
import * as helper from "../HelperRepository";

class SalonCustomersDropDownComponent extends Component {
  componentDidMount() {
    this.props.getAllSalons();
    this.props.getAllCustomers();
  }

  render() {
    if (this.props.customers.length <= 0) {
      return null;
    }
    let test = [];
    let customerSelected;

    if (this.props.customerId !== "") {
      customerSelected = this.props.customers.filter(
        (customer) => customer.personId === this.props.customerId
      )[0];

      let salonIds = [];

      for (let salonCustomer of customerSelected.salonCustomers) {
        salonIds.push(salonCustomer.salonId);
        if (this.props.salonId) {
          if (salonCustomer.salonId === this.props.salonId) {
            this.props.setSalonCustomerId(salonCustomer.salonCustomerId);
          }
        }
      }

      test = this.props.salons.filter((salon) =>
        salonIds.includes(salon.salonId)
      );
    }
    if (this.props.salonId !== "") {
      const salon = this.props.salons.find(
        (salon) => salon.salonId === this.props.salonId
      );
      this.props.setServiceTypes(salon.serviceTypes);
      this.props.setSalonTimeSlot(salon.salonTimeSlot);
    } else {
      this.props.setServiceTypes([]);
      this.props.setSalonTimeSlot(30);
    }
    return (
      <div className="custom-select-box">
        <select
          className="form-control"
          value={this.props.salonId}
          onChange={(event) => {
            this.props.setSalonId(event.target.value);

            const salon = this.props.salons.find(
              (salon) => salon.salonId === event.target.value
            );
            this.props.setSelectedSalon(salon);

            let salonCustomer = customerSelected.salonCustomers.filter(
              (salonCustomer) => salonCustomer.salonId === event.target.value
            )[0];
            this.props.setSalonCustomerId(salonCustomer.salonCustomerId);
          }}
          required
        >
          <option key={""} value={""}>
            {helper.i18nMultiFallbackKey(
              "dropdowns.SalonCustomersDropDownComponent.label",
              "Select shop"
            )}
          </option>
          {test.map((r) => {
            return (
              <option key={r.salonId} value={r.salonId}>
                {r.name}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    salons: state.salons,
    customers: state.customers,
    salonId: ownProps.salonId,
    setSalonId: ownProps.setSalonId,
    setSelectedSalon: ownProps.setSelectedSalon,
    setSalonCustomerId: ownProps.setSalonCustomerId,
    customerId: ownProps.customerId,
    setServiceTypes: ownProps.setServiceTypes,
    setSalonTimeSlot: ownProps.setSalonTimeSlot,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllSalons() {
    dispatch(mutations.getAllSalons());
  },
  getAllCustomers() {
    dispatch(mutations.getAllCustomers());
  },
});

export const ConnectedSalonCustomersDropDown = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SalonCustomersDropDownComponent)
);
