/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ConnectedNavigationBar } from "./NavigationBar";
import { useTranslation } from "react-i18next";

// import { ConnectedHome } from "./Home/Home";
// import { ConnectedHairstylist } from "./Hairstylist/Hairstylist";
// import { ConnectedRole } from "./Role/Role";
// import { ConnectedSalon } from "./Salon/Salon";
// import { ConnectedCustomer } from "./Customer/Customer";
// import { ConnectedUser } from "./User/User";
// import { ConnectedVisit } from "./Visit/Visit";

// import ConnectedHome from "./Home/Home";
// import ConnectedHairstylist from "./Hairstylist/Hairstylist";
// import ConnectedRole from "./Role/Role";
// import ConnectedSalon from "./Salon/Salon";
// import ConnectedCustomer from "./Customer/Customer";
// import ConnectedUser from "./User/User";
// import ConnectedVisit from "./Visit/Visit";

import * as helper from "./HelperRepository";

const MainContainerComponent = ({ path, activeUser }) => {
  const { t, i18n } = useTranslation();

  const [rolesMap, setRolesMap] = useState(() => {
    return helper.createMapObject();
  });

  useEffect(() => {
    setRolesMap(helper.mapForRole(activeUser));
  }, [activeUser]);
  let title = "";
  switch (path) {
    case "home":
      title = t("mainContainerComponent.titles.home", "Home");
      break;
    case "customer":
      title = helper.i18nMultiFallbackKey(
        "mainContainerComponent.titles.client",
        "List of clients"
      );
      break;
    case "hairstylist":
      title = helper.i18nMultiFallbackKey(
        "mainContainerComponent.titles.provider",
        "List of providers"
      );
      break;
    case "salon":
      if (rolesMap.get("Admin")) {
        title = helper.i18nMultiFallbackKey(
          "mainContainerComponent.titles.shop",
          "List of shops"
        );
      } else {
        if (rolesMap.get("Salon admin")) {
          // title = `Salon ${salons.name || ""}`;
          title = helper.i18nMultiFallbackKey(
            "mainContainerComponent.titles.shopAdminShop",
            "Shop"
          );
        }
      }
      break;
    case "visit":
      title = t("mainContainerComponent.titles.visit", "List of visits");
      break;
    // case "category":
    //   title = t("mainContainerComponent.titles.category", "List of categories");
    //   break;
    case "generalization":
      title = t(
        "mainContainerComponent.titles.generalization",
        "List of generalizations"
      );
      break;
    case "images":
        title = t(
          "mainContainerComponent.titles.images",
          "List of images"
        );
        break;
    case "user":
      title = t("mainContainerComponent.titles.user", "List of users");
      break;
    case "role":
      title = t("mainContainerComponent.titles.role", "List of roles");
      break;
    default:
      break;
  }

  // return (
  //   <>
  //     <ConnectedNavigationBar title={title} />
  //     {path === "home" && <ConnectedHome />}
  //     {path === "user" && <ConnectedUser />}
  //     {path === "customer" && <ConnectedCustomer />}
  //     {path === "role" && <ConnectedRole />}
  //     {path === "salon" && <ConnectedSalon />}
  //     {path === "hairstylist" && <ConnectedHairstylist />}
  //     {path === "visit" && <ConnectedVisit />}
  //   </>
  // );

  return (
    <>
      <ConnectedNavigationBar title={title} />
      {path === "home" &&
        helper.importSuspenseWrapper({
          componentPath: "./Home",
          componentName: "Home",
        })}
      {path === "customer" &&
        helper.importSuspenseWrapper({
          componentPath: "./Customer",
          componentName: "Customer",
        })}
      {path === "hairstylist" &&
        helper.importSuspenseWrapper({
          componentPath: "./Hairstylist",
          componentName: "Hairstylist",
        })}
      {path === "salon" &&
        helper.importSuspenseWrapper({
          componentPath: "./Salon",
          componentName: "Salon",
        })}
      {path === "visit" &&
        helper.importSuspenseWrapper({
          componentPath: "./Visit",
          componentName: "Visit",
        })}
      {/* {path === "category" &&
        helper.importSuspenseWrapper({
          componentPath: "./Category",
          componentName: "Category",
        })} */}
      {path === "generalization" &&
        helper.importSuspenseWrapper({
          componentPath: "./Generalization",
          componentName: "Generalization",
        })}
      {path === "user" &&
        helper.importSuspenseWrapper({
          componentPath: "./User",
          componentName: "User",
        })}
      {path === "role" &&
        helper.importSuspenseWrapper({
          componentPath: "./Role",
          componentName: "Role",
        })}
        {path === "images" &&
        helper.importSuspenseWrapper({
          componentPath: "./Images",
          componentName: "Images",
        })}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    path: state.path,
    activeUser: state.activeUser,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export const ConnectedMainContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainContainerComponent);
