import React, { useState, Component, useEffect } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ConnectedNewItemButton } from "../NewItemButton";
import { ActionButtonsColumn } from "./ActionButtonsColumn";
import { useTranslation } from "react-i18next";

const RoleListComponent = ({
  roles,
  getRolesPaged,
  totalCount,
  getRolesForExport,
  setParentState,
  clearParentState,
}) => {
  const { t, i18n } = useTranslation();

  const [search, setSearch] = useState("");
  const [exporting, setExporting] = useState(false);
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 10 });
  const debounceGetRolesPaged = React.useCallback(
    debounce(getRolesPaged, 700),
    []
  );
  let _export;
  const kendoExport = (searchValue, totalCount) => {
    var innerExport = _export;
    getRolesForExport(searchValue, totalCount, sort, (roles) => {
      innerExport.save(roles);
    });
  };

  let gridPDFExport;
  const exportPDF = (searchValue, totalCount) => {
    var innerExport = gridPDFExport;
    getRolesForExport(searchValue, totalCount, sort, (roles) => {
      innerExport.save(roles, pdfExportDone);
    });

    setExporting(true);
  };

  const pdfExportDone = () => {
    setExporting(false);
  };

  useEffect(() => {
    debounceGetRolesPaged(search, page.skip / 10 + 1, sort);
  }, []);

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  const grid = (
    <Grid
      data={roles}
      pageable={{
        buttonCount: 3,
        info: true,
        type: "numeric",
        previousNext: true,
      }}
      skip={page.skip}
      take={page.take}
      onPageChange={(event) => {
        setPage({ skip: event.page.skip, take: event.page.take });
        debounceGetRolesPaged(search, event.page.skip / 10 + 1, sort);
      }}
      total={totalCount}
      sortable
      sort={sort}
      onSortChange={(e) => {
        setSort(e.sort);
        debounceGetRolesPaged(search, page.skip / 10 + 1, e.sort);
      }}
      style={{ height: "410px" }}
      className="table-header-purple"
    >
      <GridColumn
        field="name"
        title={t("role.roleListComponent.grid.name.title", "Name")}
        className="name-cell"
        headerClassName="name-cell"
      />
      {/* <GridColumn
        field="description"
        title="Description"
        className="first-name-cell"
        headerClassName="first-name-cell"
      /> */}
      <GridColumn
        field="description"
        title={t(
          "role.roleListComponent.grid.description.title",
          "Description"
        )}
        cell={(props) => <DescriptionColumn {...props} />}
        headerClassName="description-cell"
      />
      {/* <GridColumn
        field="rank.itemName"
        title="Rank"
        className="last-name-cell"
        headerClassName="last-name-cell"
      /> */}
      <GridColumn
        field="rank.itemName"
        title={t("role.roleListComponent.grid.rank.title", "Rank")}
        cell={(props) => <RankColumn {...props} />}
        headerClassName="rank-cell"
      />
      <GridColumn
        field="Actions"
        title={t("common.actions", "Actions")}
        cell={(props) => (
          <ActionButtonsColumn setParentState={setParentState} {...props} />
        )}
        headerClassName="actions-cell two-bttns"
      />
    </Grid>
  );
  return (
    <>
      <div className="table-rounded-border table-headlabel-responsive-md role-table">
        <header>
          <div className="button-container">
            <ConnectedNewItemButton clearParentState={clearParentState} />
            <button
              type="button"
              className="btn btn-gray-outline-transp ripple-gray btn-sm ico-l ico-red"
              onClick={() => exportPDF(search, totalCount)}
              disabled={exporting}
            >
              <i className="fal fa-file-pdf"></i>{" "}
              <span className="mobile-hide-550">
                {t("common.exportTo", "Export to")}{" "}
              </span>
              PDF
            </button>
            <button
              type="button"
              className="btn btn-gray-outline-transp ripple-gray btn-sm ico-l ico-green"
              onClick={() => kendoExport(search, totalCount)}
            >
              <i className="fal fa-file-excel"></i>{" "}
              <span className="mobile-hide-550">
                {t("common.exportTo", "Export to")}{" "}
              </span>
              Excel
            </button>
          </div>
          <div className="search-holder">
            <input
              type="text"
              id="inputEmail"
              className="form-control"
              placeholder={t("common.searchPlaceholder", "Search...")}
              value={search}
              onChange={(event) => {
                setPage({ skip: 0, take: 10 });
                setSearch(event.target.value);
                debounceGetRolesPaged(event.target.value, 1, sort);
              }}
            ></input>
            <button type="button" className="btn ico-btn-search">
              <i className="fal fa-search"></i>
            </button>
          </div>
          <div className="actions-holder"></div>
        </header>
        <ExcelExport
          ref={(exporter) => (_export = exporter)}
          fileName={`Roles${new Date().getTime()}.xlsx`}
        >
          <ExcelExportColumn
            field="name"
            title={t("role.roleListComponent.grid.name.title", "Name")}
            width={350}
          />
          <ExcelExportColumn
            field="description"
            title={t(
              "role.roleListComponent.grid.description.title",
              "Description"
            )}
            width={150}
          />
          <ExcelExportColumn
            field="rank.itemName"
            title={t("role.roleListComponent.grid.rank.title", "Rank")}
            width={150}
          />
        </ExcelExport>
        {grid}
        <GridPDFExport
          ref={(pdfExport) => (gridPDFExport = pdfExport)}
          fileName={`Roles${new Date().getTime()}.pdf`}
          margin="1cm"
        >
          <GridColumn
            field="name"
            title={t("role.roleListComponent.grid.name.title", "Name")}
          />
          <GridColumn
            field="description"
            title={t(
              "role.roleListComponent.grid.description.title",
              "Description"
            )}
          />
          <GridColumn
            field="rank.itemName"
            title={t("role.roleListComponent.grid.rank.title", "Rank")}
          />
          {grid}
        </GridPDFExport>
      </div>
    </>
  );
};

class RankColumn extends Component {
  render() {
    return (
      <td className="rank-cell">
        <span className="mobile-view-title">Rank:</span>
        <span>{this.props.dataItem.rank.itemName}</span>
      </td>
    );
  }
}
class DescriptionColumn extends Component {
  render() {
    return (
      <td className="description-cell">
        <span className="mobile-view-title">Description:</span>
        <span>{this.props.dataItem.description}</span>
      </td>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    roles: state.roles,
    totalCount: state.totalCount,
    setParentState: ownProps.setParentState,
    setParentSearch: ownProps.setParentSearch,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getRolesPaged(searchValue, page, sort) {
    dispatch(mutations.getRolesPaged(searchValue, page, sort));
  },
  getRolesForExport(searchValue, totalCount, sort, cb) {
    dispatch(mutations.getRolesForExport(searchValue, totalCount, sort, cb));
  },
});

export const ConnectedRoleList = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleListComponent);
