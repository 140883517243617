import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../../store/mutations";
import { withTranslation } from "react-i18next";

class HairstylistServiceTypeEdit extends Component {
  state = {
    name: "",
    price: "",
    duration: "",
    description: "",
  };

  static getDerivedStateFromProps(props, state) {
    let nameValue = "";
    let priceValue = "";
    let durationValue = "";
    let descriptionValue = "";

    if (props.selectedServiceType.serviceTypeName !== undefined) {
      nameValue = props.selectedServiceType.serviceTypeName;
    } else {
      nameValue = "";
    }

    if (props.selectedServiceType.serviceTypePrice !== undefined) {
      priceValue = props.selectedServiceType.serviceTypePrice;
    } else {
      priceValue = "";
    }

    if (props.selectedServiceType.serviceTypeDuration !== undefined) {
      durationValue = props.selectedServiceType.serviceTypeDuration;
    } else {
      durationValue = "";
    }

    if (props.selectedServiceType.description !== undefined) {
      descriptionValue = props.selectedServiceType.description;
    } else {
      descriptionValue = "";
    }

    return {
      name: nameValue,
      price: priceValue,
      duration: durationValue,
      description: descriptionValue,
    };
  }

  handlePriceChange(event) {
    this.setState({ price: event.target.value });
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handleDurationChange(event) {
    this.setState({ duration: event.target.value });
  }

  handleDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  render() {
    return (
      <div
        className="modal fade"
        data-backdrop="static"
        data-keyboard="false"
        id="editSalonServiceTypeModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editSalonServiceTypeModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editSalonServiceTypeModalLabel">
                <i className="fal fa-user fa-lg c-purple" />{" "}
                {helper.i18nMultiFallbackKey(
                  "hairstylist.hairstylistServiceTypeEdit.editSalonServiceTypeModal.title",
                  "Edit Shop Service Type"
                )}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fal fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <form className="form needs-validation" noValidate>
                <input type="hidden" value="something" />
                <div className="form-group">
                  <label htmlFor="editSalonServiceTypeModalName">
                    {this.props.t(
                      "hairstylist.hairstylistServiceTypeEdit.editSalonServiceTypeModal.nameLabel",
                      "Name"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="editSalonServiceTypeModalName"
                    autoComplete="new-username"
                    placeholder={this.props.t(
                      "hairstylist.hairstylistServiceTypeEdit.editSalonServiceTypeModal.namePlaceholder",
                      "Please enter service type name"
                    )}
                    value={this.state.name}
                    onChange={(event) => this.handleNameChange(event)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="editSalonServiceTypeModalPrice">
                    {this.props.t(
                      "hairstylist.hairstylistServiceTypeEdit.editSalonServiceTypeModal.priceLabel",
                      "Price"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="editSalonServiceTypeModalPrice"
                    autoComplete="new-username"
                    placeholder={this.props.t(
                      "hairstylist.hairstylistServiceTypeEdit.editSalonServiceTypeModal.pricePlaceholder",
                      "Please enter service type price"
                    )}
                    value={this.state.price}
                    onChange={(event) => this.handlePriceChange(event)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="editSalonServiceTypeModalDuration">
                    {this.props.t(
                      "hairstylist.hairstylistServiceTypeEdit.editSalonServiceTypeModal.priceLabel",
                      "Duration"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="editSalonServiceTypeModalDuration"
                    autoComplete="new-username"
                    placeholder={this.props.t(
                      "hairstylist.hairstylistServiceTypeEdit.editSalonServiceTypeModal.pricePlaceholder",
                      "Please enter service type duration"
                    )}
                    value={this.state.duration}
                    onChange={(event) => this.handleDurationChange(event)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="editSalonServiceTypeModalDescription">
                    {this.props.t(
                      "hairstylist.hairstylistServiceTypeEdit.editSalonServiceTypeModal.descriptionLabel",
                      "Description"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="editSalonServiceTypeModalDescription"
                    autoComplete="new-username"
                    placeholder={this.props.t(
                      "hairstylist.hairstylistServiceTypeEdit.editSalonServiceTypeModal.descriptionPlaceholder",
                      "Please enter service type description"
                    )}
                    value={this.state.description}
                    onChange={(event) => this.handleDescriptionChange(event)}
                    required
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger ico-l"
                data-dismiss="modal"
              >
                <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
              </button>
              <button
                type="button"
                className="btn btn-outline-success ico-l"
                data-dismiss="modal"
                onClick={() => {
                  let serviceTypeForUpdate = {
                    ServiceTypeId: this.props.selectedServiceType.serviceTypeId,
                    ServiceTypeName: this.state.name,
                    ServiceTypePrice: this.state.price,
                    ServiceTypeDuration: this.state.duration,
                    Description: this.state.description,
                  };

                  this.props.saveServiceType(serviceTypeForUpdate, () =>
                    this.props.clearParentState()
                  );
                }}
              >
                <i className="fal fa-check" /> {t("common.finish", "Finish")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPropsFreeSlots = (state, ownProps) => {
  return {
    selectedServiceType: ownProps.selectedServiceType,
    selectedSalon: ownProps.selectedSalon,
    clearParentState: ownProps.clearParentState,
  };
};

const mapDispatchToPropsFreeSlots = (dispatch) => ({
  saveServiceType(serviceTypeForUpdate, cb) {
    cb();
    dispatch(mutations.saveServiceType(serviceTypeForUpdate));
  },
});

export const ConnectedHairstylistServiceTypeEdit = withTranslation()(
  connect(
    mapStateToPropsFreeSlots,
    mapDispatchToPropsFreeSlots
  )(HairstylistServiceTypeEdit)
);
