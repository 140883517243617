import React, { Component } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import * as helper from "./HelperRepository";

export const TestReplace = () => {
  const { t } = useTranslation();
  return (
    <div>
      {helper.i18nMultiFallbackKey("hairstylist.hairstylistComponent.assignHairstylistToSalonModal.title", "Assign Provider To Shop")}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TestReplace);
