import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { withTranslation } from "react-i18next";

class CategoryItemsDropDownComponent extends Component {
  componentDidMount() {
    this.props.getAllCategoryItems();
  }

  render() {
    return (
      <div className="custom-select-box">
        <select
          className="form-control"
          value={this.props.categoryItemId}
          onChange={(event) => {
            if (event.target.value !== "") {
              let categoryItemList = this.props.categoryItems.filter(
                (ci) => ci.categoryItemId === event.target.value
              );
              const [categoryItem, ...rest] = categoryItemList;

              this.props.setCategoryItemId(categoryItem.categoryItemId);
            } else {
              this.props.setCategoryItemId("");
            }
          }}
          required
        >
          <option key={""} value={""}>
            {this.props.t(
              "dropdowns.categoryItemsDropDownComponent.label",
              "Select"
            )}{" "}
            {`${this.props.categoryType}`}
          </option>
          {this.props.categoryItems
            .filter((ci) => ci.category.name === this.props.categoryType)
            .map((r) => {
              return (
                <option key={r.categoryItemId} value={r.categoryItemId}>
                  {r.itemName}
                </option>
              );
            })}
        </select>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    categoryType: ownProps.categoryType,
    categoryItems: state.categoryItems,
    categoryItemId: ownProps.categoryItemId,
    setCategoryItemId: ownProps.setCategoryItemId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllCategoryItems() {
    dispatch(mutations.getAllCategoryItems());
  },
});

export const ConnectedCategoryItemsDropDown = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CategoryItemsDropDownComponent)
);
