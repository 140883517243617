import React, { useState, Component, useEffect } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ConnectedNewItemButton } from "../NewItemButton";
import { ConnectedNewItemButtonV2 } from "../NewItemButtonV2";
import { useTranslation, withTranslation } from "react-i18next";
import { ActionButtonsColumn } from "./ActionButtonsColumn";

const UserListComponent = ({
  users,
  getUsersPaged,
  totalCount,
  getUsersForExport,
  setParentState,
  setParentSearch,
  clearParentState,
  setIsEdit,
  setIsCreate,
  setIsDelete,
}) => {
  const { t, i18n } = useTranslation();

  const [search, setSearch] = useState("");
  const [exporting, setExporting] = useState(false);
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 10 });
  const debounceGetUsersPaged = React.useCallback(
    debounce(getUsersPaged, 700),
    []
  );
  let _export;
  const kendoExport = (searchValue, totalCount) => {
    var innerExport = _export;
    getUsersForExport(searchValue, totalCount, sort, (users) => {
      innerExport.save(users);
    });
  };

  let gridPDFExport;
  const exportPDF = (searchValue, totalCount) => {
    var innerExport = gridPDFExport;
    getUsersForExport(searchValue, totalCount, sort, (users) => {
      innerExport.save(users, pdfExportDone);
    });

    setExporting(true);
  };

  const pdfExportDone = () => {
    setExporting(false);
  };

  useEffect(() => {
    debounceGetUsersPaged(search, page.skip / 10 + 1, sort);
  }, []);

  const ContactColumnWithTranslation = withTranslation()(ContactColumn);

  const RoleColumnWithTranslation = withTranslation()(RoleColumn);
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  const grid = (
    <Grid
      data={users}
      pageable={{
        buttonCount: 3,
        info: true,
        type: "numeric",
        previousNext: true,
      }}
      skip={page.skip}
      take={page.take}
      onPageChange={(event) => {
        setPage({ skip: event.page.skip, take: event.page.take });
        debounceGetUsersPaged(search, event.page.skip / 10 + 1, sort);
      }}
      total={totalCount}
      sortable
      sort={sort}
      onSortChange={(e) => {
        setSort(e.sort);
        debounceGetUsersPaged(search, page.skip / 10 + 1, e.sort);
      }}
      style={{ height: "410px" }}
      className="table-header-purple"
    >
      <GridColumn
        field="username"
        title={t("user.userListComponent.grid.username.title", "Username")}
        className="username-cell"
        headerClassName="username-cell"
      />
      <GridColumn
        field="firstName"
        title={t("user.userListComponent.grid.firstname.title", "First name")}
        className="first-name-cell"
        headerClassName="first-name-cell"
      />
      <GridColumn
        field="lastName"
        title={t("user.userListComponent.grid.lastname.title", "Last name")}
        className="last-name-cell"
        headerClassName="last-name-cell"
      />
      <GridColumn
        field="email"
        title={t("user.userListComponent.grid.email.title", "Email")}
        className="email-cell"
        headerClassName="email-cell"
      />
      <GridColumn
        field="phone"
        title={t("user.userListComponent.grid.phone.title", "Phone")}
        cell={(props) => <ContactColumnWithTranslation {...props} />}
        headerClassName="contact-cell"
      />
      <GridColumn
        field="role"
        title={t("user.userListComponent.grid.role.title", "Role")}
        cell={(props) => <RoleColumnWithTranslation {...props} />}
        headerClassName="role-cell"
      />
      <GridColumn
        field="Actions"
        title={t("common.actions", "Actions")}
        cell={(props) => (
          <ActionButtonsColumn
            setParentState={setParentState}
            setIsEdit={setIsEdit}
            setIsCreate={setIsCreate}
            setIsDelete={setIsDelete}
            {...props}
          />
        )}
        headerClassName="actions-cell two-bttns"
      />
    </Grid>
  );

  return (
    <>
      <div className="table-rounded-border table-headlabel-responsive-md user-table">
        <header>
          <div className="button-container">
            {/* <ConnectedNewItemButton clearParentState={clearParentState} /> */}
            <ConnectedNewItemButtonV2
              clearParentState={clearParentState}
              setIsCreate={setIsCreate}
            />
            <button
              type="button"
              className="btn btn-gray-outline-transp ripple-gray btn-sm ico-l ico-red"
              onClick={() => exportPDF(search, totalCount)}
              disabled={exporting}
            >
              <i className="fal fa-file-pdf"></i>{" "}
              <span className="mobile-hide-550">
                {t("common.exportTo", "Export to")}{" "}
              </span>
              PDF
            </button>
            <button
              type="button"
              className="btn btn-gray-outline-transp ripple-gray btn-sm ico-l ico-green"
              onClick={() => kendoExport(search, totalCount)}
            >
              <i className="fal fa-file-excel"></i>{" "}
              <span className="mobile-hide-550">
                {t("common.exportTo", "Export to")}{" "}
              </span>
              Excel
            </button>
          </div>
          <div className="search-holder">
            <input
              type="text"
              id="inputEmail"
              className="form-control"
              placeholder={t("common.searchPlaceholder", "Search...")}
              value={search}
              onChange={(event) => {
                setPage({ skip: 0, take: 10 });
                setSearch(event.target.value);
                setParentSearch(event.target.value);
                debounceGetUsersPaged(event.target.value, 1, sort);
              }}
            ></input>
            <button type="button" className="btn ico-btn-search">
              <i className="fal fa-search"></i>
            </button>
          </div>
          <div className="actions-holder"></div>
        </header>
        <ExcelExport
          ref={(exporter) => (_export = exporter)}
          fileName={`Users${new Date().getTime()}.xlsx`}
        >
          <ExcelExportColumn
            field="username"
            title={t("user.userListComponent.grid.username.title", "Username")}
            width={350}
          />
          <ExcelExportColumn
            field="firstName"
            title={t(
              "user.userListComponent.grid.firstname.title",
              "First name"
            )}
            width={150}
          />
          <ExcelExportColumn
            field="lastName"
            title={t("user.userListComponent.grid.lastname.title", "Last name")}
            width={150}
          />
          <ExcelExportColumn
            field="email"
            title={t("user.userListComponent.grid.email.title", "Email")}
            width={350}
          />
          <ExcelExportColumn
            field="phone"
            title={t("user.userListComponent.grid.phone.title", "Phone")}
            width={150}
          />
          <ExcelExportColumn
            field="userRoles[0].role.name"
            title={t("user.userListComponent.grid.role.title", "Role")}
            width={150}
          />
        </ExcelExport>
        {grid}
        <GridPDFExport
          ref={(pdfExport) => (gridPDFExport = pdfExport)}
          fileName={`Users${new Date().getTime()}.pdf`}
          margin="1cm"
        >
          <GridColumn
            field="username"
            title={t("user.userListComponent.grid.username.title", "Username")}
          />
          <GridColumn
            field="firstName"
            title={t(
              "user.userListComponent.grid.firstname.title",
              "First name"
            )}
          />
          <GridColumn
            field="lastName"
            title={t("user.userListComponent.grid.lastname.title", "Last name")}
          />
          <GridColumn
            field="email"
            title={t("user.userListComponent.grid.email.title", "Email")}
          />
          <GridColumn
            field="phone"
            title={t("user.userListComponent.grid.phone.title", "Phone")}
          />
          <GridColumn
            field="userRoles[0].role.name"
            title={t("user.userListComponent.grid.role.title", "Role")}
          />
          {grid}
        </GridPDFExport>
      </div>
    </>
  );
};
class ContactColumn extends Component {
  render() {
    return (
      <td className="contact-cell">
        <span className="mobile-view-title">
          {this.props.t(
            "user.userListComponent.grid.phone.mobileView",
            "Phone:"
          )}
        </span>
        <span>{this.props.dataItem.phone}</span>
      </td>
    );
  }
}
class RoleColumn extends Component {
  render() {
    return (
      <td className="role-cell">
        <span className="mobile-view-title">
          {this.props.t("user.userListComponent.grid.role.mobileView", "Role:")}
        </span>
        <span>{this.props.dataItem.userRoles[0].role.name}</span>
      </td>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    users: state.users,
    totalCount: state.totalCount,
    setParentState: ownProps.setParentState,
    setParentSearch: ownProps.setParentSearch,
    setIsEdit: ownProps.setIsEdit,
    setIsCreate: ownProps.setIsCreate,
    setIsDelete: ownProps.setIsDelete,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getUsersPaged(searchValue, page, sort) {
    const customSetSort = (sort) => {
      let result = [];
      sort.forEach((element) => {
        switch (element.field) {
          case "firstName":
          case "lastName":
          case "email":
          case "phone":
            result.push({
              field:
                "Person." +
                element.field[0].toUpperCase() +
                element.field.slice(1),
              dir: element.dir,
            });
            break;
          default:
            result.push(element);
            break;
        }
      });

      return result;
    };

    let customSort = customSetSort(sort);
    dispatch(mutations.getUsersPaged(searchValue, page, customSort));
  },
  getUsersForExport(searchValue, totalCount, sort, cb) {
    dispatch(mutations.getUsersForExport(searchValue, totalCount, sort, cb));
  },
});

export const ConnectedUserList = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserListComponent);
