import React from "react";
import { useTranslation } from "react-i18next";

function RequiredFieldsComponent() {
  const { t } = useTranslation();
  return (
    <div className="form-group">
      <div className="warrning-info">
        <span className="title-label required"></span>{" "}
        <span>{t("common.requiredFields", "- required fields")}</span>
      </div>
    </div>
  );
}

export default RequiredFieldsComponent;
