import React, { Component } from "react";
export class ServiceTypeDurationColumn extends Component {
  render() {
    if (!this.props.serviceType || !this.props.selectedSalonHairstylist) {
      return (
        <td className="contact-cell">
          <span className="mobile-view-title">{this.props.mobileTitle}</span>
          <span className="date-label">
            {this.props.serviceType.serviceTypeDuration *
              this.props.selectedSalon.salonTimeSlot}
          </span>
        </td>
      );
    }
    return (
      <td className="contact-cell">
        <span className="mobile-view-title">{this.props.mobileTitle}</span>
        <span className="date-label">
          {this.props.serviceType.serviceTypeDuration *
            this.props.selectedSalonHairstylist.salon.salonTimeSlot}
        </span>
      </td>
    );
  }
}
