import React, { useState, Component, useEffect } from "react";
import { connect } from "react-redux";
import { ConnectedSalonsDropDown } from "../Dropdowns/SalonsDropDown";
import { ConnectedGeneralizationsDropDown } from "../Dropdowns/GeneralizationsDropDown";
import { ConnectedCategoryItemsDropDown } from "../Dropdowns/CategoryItemsDropDown";
import * as mutations from "../../store/mutations";
import { FormErrors } from "../FormErrors";
import { useTranslation } from "react-i18next";
import * as helper from "../HelperRepository";
import RequiredFieldsComponent from "../RequiredFieldsComponent";

const EditSalonModalComponent = ({
  salonId,
  name,
  setName,
  address,
  setAddress,
  webAddress,
  setWebAddress,
  phone,
  setPhone,
  mobilePhone,
  setMobilePhone,
  email,
  setEmail,
  salonTimeSlot,
  setSalonTimeSlot,
  customerSchedulePeriod,
  setCustomerSchedulePeriod,
  embeddedMapAddress,
  setEmbeddedMapAddress,
  embeddedMapAddressHasError,
  setEmbeddedMapAddressHasError,
  latitude,
  setLatitude,
  longitude,
  setLongitude,
  generalizationId,
  setGeneralizationId,
  workingTimeDescription,
  setWorkingTimeDescription,
  description,
  setDescription,
  saveSalon,
  clearState,
  formErrors,
  setFormErrors,
  editNameValid,
  setEditNameValid,
  editAddressValid,
  setEditAddressValid,
  editPhoneValid,
  setEditPhoneValid,
  editTimeSlotValid,
  setEditTimeSlotValid,
  editSchedulePeriodValid,
  setEditSchedulePeriodValid,
  editFormValid,
  setEditFormValid,
}) => {
  const { t, i18n } = useTranslation();

  const handleUserInput = (e) => {
    switch (e.target.id) {
      case "nameEdit":
        setName(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "addressEdit":
        setAddress(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "phoneEdit":
        setPhone(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "timeSlotEdit":
        setSalonTimeSlot(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "schedulePeriodEdit":
        setCustomerSchedulePeriod(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      default:
        break;
    }
  };
  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let nameFieldValid = editNameValid;
    let addressFieldValid = editAddressValid;
    let phoneFieldValid = editPhoneValid;
    let timeSlotFieldValid = editTimeSlotValid;
    let schedulePeriodFieldValid = editSchedulePeriodValid;

    switch (fieldName) {
      case "nameEdit":
        let nameFieldPattern = /^([A-Za-z 0-9\u0110\u017D\u01C8\u01CB\u0106\u010C\u01C5\u0160\u0111\u017E\u01C9\u01CC\u0107\u010D\u01C6\u0161]+)$/i;
        nameFieldValid = nameFieldPattern.test(value);
        setEditNameValid(nameFieldValid);
        fieldValidationErrors.name = nameFieldValid
          ? ""
          : t("common.isInvalid", " is invalid");
        break;
      case "addressEdit":
        let addressFieldPattern = /^(.+)$/i;
        addressFieldValid = addressFieldPattern.test(value);
        setEditAddressValid(addressFieldValid);
        fieldValidationErrors.address = addressFieldValid
          ? ""
          : t("common.isInvalid", " is invalid");
        break;
      case "phoneEdit":
        let phoneFieldPattern = /^((\+?)[0-9]{9,15})$/i;
        phoneFieldValid = phoneFieldPattern.test(value);
        setEditPhoneValid(phoneFieldValid);
        fieldValidationErrors.phone = phoneFieldValid
          ? ""
          : t(
              "common.phoneIsInvalid",
              "  is invalid. Valid format +06xxxxxxxx, from 9 to 15 numbers, sign + optional"
            );
        break;
      case "timeSlotEdit":
        let timeSlotFieldPattern = /^([0-9]+)$/i;
        timeSlotFieldValid = timeSlotFieldPattern.test(value);
        setEditTimeSlotValid(timeSlotFieldValid);
        fieldValidationErrors.timeSlot = timeSlotFieldValid
          ? ""
          : t("common.isInvalid", " is invalid");
        break;
      case "schedulePeriodEdit":
        let schedulePeriodFieldPattern = /^([0-9]+)$/i;
        schedulePeriodFieldValid = schedulePeriodFieldPattern.test(value);
        setEditSchedulePeriodValid(schedulePeriodFieldValid);
        fieldValidationErrors.schedulePeriod = schedulePeriodFieldValid
          ? ""
          : t("common.isInvalid", " is invalid");
        break;
      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    setEditFormValid(
      nameFieldValid &&
        addressFieldValid &&
        phoneFieldValid &&
        timeSlotFieldValid &&
        schedulePeriodFieldValid
    );
  };
  const errorClassLabel = (error) => {
    if (error) {
      return error.length === 0 ? "" : "text-danger";
    }
    return "";
  };
  const errorClassInput = (error) => {
    if (error) {
      return error.length === 0 ? "" : "is-invalid";
    }
    return "";
  };

  return (
    <>
      {/* Edit Salon Modal */}
      <div
        className="modal fade"
        data-backdrop="static"
        data-keyboard="false"
        id="editSalonModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editSalonModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editSalonModalLabel">
                <i className="fal fa-warehouse fa-lg c-purple" />{" "}
                {helper.i18nMultiFallbackKey(
                  "salon.editSalonModalComponent.editSalonModal.title",
                  "Edit Shop"
                )}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  clearState();
                }}
              >
                <i className="fal fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <form className="form needs-validation" noValidate>
                <input type="hidden" value="something" />
                <div className="form-group">
                  <label
                    htmlFor="nameEdit"
                    className={`${errorClassLabel(formErrors.name)} required`}
                  >
                    {t(
                      "salon.editSalonModalComponent.editSalonModal.salonNameLabel",
                      "Name"
                    )}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${errorClassInput(
                      formErrors.name
                    )}`}
                    id="nameEdit"
                    autoComplete="new-username"
                    placeholder={helper.i18nMultiFallbackKey(
                      "salon.editSalonModalComponent.editSalonModal.shopNamePlaceholder",
                      "Please enter shop name"
                    )}
                    value={name}
                    onChange={handleUserInput}
                    onBlur={(e) => {
                      validateField(e.target.id, e.target.value);
                    }}
                    required
                  />
                  <div className="invalid-feedback">
                    {formErrors["name"]?.length > 0 && (
                      <p className="text-danger">
                        {t(
                          "salon.editSalonModalComponent.editSalonModal.salonNameInvalid",
                          "Name"
                        )}
                        {formErrors["name"]}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="addressEdit"
                    className={`${errorClassLabel(
                      formErrors.address
                    )} required`}
                  >
                    {t(
                      "salon.editSalonModalComponent.editSalonModal.salonAddressLabel",
                      "Address"
                    )}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${errorClassInput(
                      formErrors.address
                    )}`}
                    id="addressEdit"
                    autoComplete="new-username"
                    placeholder={helper.i18nMultiFallbackKey(
                      "salon.editSalonModalComponent.editSalonModal.shopAddressPlaceholder",
                      "Please enter shop address"
                    )}
                    value={address}
                    onChange={handleUserInput}
                    onBlur={(e) => {
                      validateField(e.target.id, e.target.value);
                    }}
                    required
                  />
                  <div className="invalid-feedback">
                    {formErrors["address"]?.length > 0 && (
                      <p className="text-danger">
                        {t(
                          "salon.editSalonModalComponent.editSalonModal.salonAddressInvalid",
                          "Address"
                        )}
                        {formErrors["address"]}
                      </p>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="webAddressEdit">
                    {t(
                      "salon.editSalonModalComponent.editSalonModal.salonWebAddressLabel",
                      "Web address"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="webAddressEdit"
                    placeholder={t(
                      "salon.editSalonModalComponent.editSalonModal.salonWebAddressPlaceholder",
                      "Please enter web address"
                    )}
                    value={webAddress}
                    onChange={(event) => setWebAddress(event.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="phoneEdit"
                    className={`${errorClassLabel(formErrors.phone)} required`}
                  >
                    {t(
                      "salon.editSalonModalComponent.editSalonModal.salonPhoneLabel",
                      "Phone"
                    )}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${errorClassInput(
                      formErrors.phone
                    )}`}
                    id="phoneEdit"
                    placeholder={t(
                      "salon.editSalonModalComponent.editSalonModal.salonPhonePlaceholder",
                      "Please enter phone"
                    )}
                    value={phone}
                    onChange={handleUserInput}
                    onBlur={(e) => {
                      validateField(e.target.id, e.target.value);
                    }}
                    required
                  />
                  <div className="invalid-feedback">
                    {formErrors["phone"]?.length > 0 && (
                      <p className="text-danger">
                        {t(
                          "salon.editSalonModalComponent.editSalonModal.salonPhoneInvalid",
                          "Phone"
                        )}
                        {formErrors["phone"]}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="mobilePhoneEdit">
                    {t(
                      "salon.editSalonModalComponent.editSalonModal.salonMobilePhoneLabel",
                      "Mobile phone"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="mobilePhoneEdit"
                    placeholder={t(
                      "salon.editSalonModalComponent.editSalonModal.salonMobilePhonePlaceholder",
                      "Please enter mobile phone"
                    )}
                    value={mobilePhone}
                    onChange={(event) => setMobilePhone(event.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="emailEdit">
                    {t(
                      "salon.editSalonModalComponent.editSalonModal.salonEmailLabel",
                      "Email"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="emailEdit"
                    placeholder={t(
                      "salon.editSalonModalComponent.editSalonModal.salonEmailPlaceholder",
                      "Please enter email"
                    )}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="timeSlotEdit"
                    className={`${errorClassLabel(formErrors.timeSlot)}`}
                  >
                    {t(
                      "salon.editSalonModalComponent.editSalonModal.salonTimeSlotLabel",
                      "Time slot"
                    )}
                  </label>
                  <input
                    type="number"
                    className={`form-control ${errorClassInput(
                      formErrors.timeSlot
                    )}`}
                    id="timeSlotEdit"
                    placeholder={t(
                      "salon.editSalonModalComponent.editSalonModal.salonTimeSlotPlaceholder",
                      "Please enter time slot"
                    )}
                    value={salonTimeSlot}
                    onChange={handleUserInput}
                    onBlur={(e) => {
                      validateField(e.target.id, e.target.value);
                    }}
                    required
                  />
                  <div className="invalid-feedback">
                    {formErrors["timeSlot"]?.length > 0 && (
                      <p className="text-danger">
                        {t(
                          "salon.editSalonModalComponent.editSalonModal.salonTimeSlotInvalid",
                          "Time slot"
                        )}
                        {formErrors["timeSlot"]}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="schedulePeriodEdit"
                    className={`${errorClassLabel(formErrors.schedulePeriod)}`}
                  >
                    {t(
                      "salon.editSalonModalComponent.editSalonModal.salonSchedulePeriodLabel",
                      "Schedule period"
                    )}
                  </label>
                  <input
                    type="number"
                    className={`form-control ${errorClassInput(
                      formErrors.schedulePeriod
                    )}`}
                    id="schedulePeriodEdit"
                    placeholder={t(
                      "salon.editSalonModalComponent.editSalonModal.salonSchedulePeriodPlaceholder",
                      "Please enter schedule period (default 14 days)"
                    )}
                    value={customerSchedulePeriod}
                    onChange={handleUserInput}
                    onBlur={(e) => {
                      validateField(e.target.id, e.target.value);
                    }}
                    required
                  />
                  <div className="invalid-feedback">
                    {formErrors["schedulePeriod"]?.length > 0 && (
                      <p className="text-danger">
                        {t(
                          "salon.editSalonModalComponent.editSalonModal.salonSchedulePeriodInvalid",
                          "Schedule period"
                        )}
                        {formErrors["schedulePeriod"]}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="embeddedMapAddress">
                    {t(
                      "salon.editSalonModalComponent.editSalonModal.salonEmbededMapAddressLabel",
                      "Embedded map address"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="embeddedMapAddress"
                    placeholder={t(
                      "salon.editSalonModalComponent.editSalonModal.salonEmbededMapAddressPlaceholder",
                      "Please enter embedded map address"
                    )}
                    value={embeddedMapAddress}
                    onChange={(event) => {
                      const paragraph = event.target.value;
                      const regex = /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.%]+/g;
                      const found = paragraph.match(regex);
                      setEmbeddedMapAddressHasError(found ? false : true);
                      setEmbeddedMapAddress(found ? found[0] : paragraph);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="latitude">
                    {t(
                      "salon.editSalonModalComponent.editSalonModal.salonLatitudeLabel",
                      "Latitude"
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="latitude"
                    placeholder={t(
                      "salon.editSalonModalComponent.editSalonModal.salonLatitudePlaceholder",
                      "Please enter google map latitude"
                    )}
                    value={latitude}
                    onChange={(event) => {
                      setLatitude(event.target.value);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="longitude">
                    {t(
                      "salon.editSalonModalComponent.editSalonModal.salonLongitudeLabel",
                      "Longitude"
                    )}
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="longitude"
                    placeholder={t(
                      "salon.editSalonModalComponent.editSalonModal.salonLongitudePlaceholder",
                      "Please enter longitude"
                    )}
                    value={longitude}
                    onChange={(event) => {
                      setLongitude(event.target.value);
                    }}
                  />
                </div>
                <div className="form-group working-time-group">
                  <label htmlFor="workingTimeDescriptionEdit">
                    {t(
                      "salon.editSalonModalComponent.editSalonModal.salonWorkingTimeDescriptionLabel",
                      "Working time description"
                    )}
                  </label>
                  <textarea
                    rows="3"
                    type="text"
                    className="form-control"
                    id="workingTimeDescriptionEdit"
                    autoComplete="new-username"
                    placeholder={t(
                      "salon.editSalonModalComponent.editSalonModal.salonWorkingTimeDescriptionPlaceholder",
                      "Pon-Pet:9-21h Sub:9-16h Ned:Ne radi"
                    )}
                    value={workingTimeDescription}
                    onChange={(event) =>
                      setWorkingTimeDescription(event.target.value)
                    }
                  />
                  <div className="working-time-group-footer">
                    <span className="working-time txt-color text-nowrap">
                      <span className="week-day">
                        <i>
                          {t(
                            "salon.editSalonModalComponent.editSalonModal.salonWorkingTimePonPetLabel",
                            "Pon-Pet:"
                          )}
                        </i>
                        {t(
                          "salon.editSalonModalComponent.editSalonModal.salonWorkingTimePonPetWorkTimeLabel",
                          "09-21h"
                        )}
                      </span>
                      <span className="week-day saturday">
                        <i>
                          {t(
                            "salon.editSalonModalComponent.editSalonModal.salonWorkingTimeSubLabel",
                            "Sub:"
                          )}
                        </i>
                        {t(
                          "salon.editSalonModalComponent.editSalonModal.salonWorkingTimeSubWorkTimeLabel",
                          "10-15h"
                        )}
                      </span>
                      <span className="week-day sunday">
                        <i>
                          {t(
                            "salon.editSalonModalComponent.editSalonModal.salonWorkingTimeNedLabel",
                            "Ned:"
                          )}
                        </i>
                        {t(
                          "salon.editSalonModalComponent.editSalonModal.salonWorkingTimeNedWorkTimeLabel",
                          "10-15h"
                        )}
                      </span>
                    </span>
                    <div className="btn-copy-holder">
                      <button
                        className="btn btn-gray-outline-transp btn-sm btn-inline"
                        onClick={(e) => {
                          e.preventDefault();
                          setWorkingTimeDescription(`<span class="week-day">
                      <i>Pon-Pet:</i>
                      09-21h
                    </span>
                    <span class="week-day saturday">
                      <i>Sub:</i>
                      10-15h
                    </span>
                    <span class="week-day sunday">
                      <i>Ned:</i>
                      10-15h
                    </span>`);
                        }}
                      >
                        <i className="fal fa-copy"></i>{" "}
                        {t(
                          "salon.editSalonModalComponent.editSalonModal.salonCopyHtmlLabel",
                          "Copy HTML"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="editGeneralizationId" className="required">
                    {t(
                      "salon.editSalonModalComponent.editSalonModal.generalizationIdLabel",
                      "Generalization"
                    )}
                  </label>
                  <ConnectedGeneralizationsDropDown
                    generalizationId={generalizationId}
                    setGeneralizationId={setGeneralizationId}
                    id="editGeneralizationId"
                  />
                  <div className="invalid-feedback">
                    {t(
                      "salon.editSalonModalComponent.editSalonModal.generalizationIdInvalid",
                      "Please select generalization."
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="descriptionEdit">
                    {t(
                      "salon.editSalonModalComponent.editSalonModal.salonDescriptionLabel",
                      "Description"
                    )}
                  </label>
                  <textarea
                    rows="3"
                    type="text"
                    className="form-control"
                    id="descriptionEdit"
                    autoComplete="new-username"
                    placeholder={helper.i18nMultiFallbackKey(
                      "salon.editSalonModalComponent.editSalonModal.shopDescriptionPlaceholder",
                      "Please enter shop description"
                    )}
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                  />
                </div>
                <RequiredFieldsComponent />
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger ico-l"
                data-dismiss="modal"
                onClick={() => {
                  clearState();
                }}
              >
                <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
              </button>
              <button
                type="button"
                className="btn btn-outline-success ico-l"
                data-dismiss="modal"
                disabled={!editFormValid}
                onClick={() =>
                  saveSalon(
                    salonId,
                    name,
                    address,
                    webAddress,
                    phone,
                    mobilePhone,
                    email,
                    salonTimeSlot,
                    customerSchedulePeriod,
                    embeddedMapAddressHasError ? "" : embeddedMapAddress,
                    latitude,
                    longitude,
                    generalizationId,
                    workingTimeDescription,
                    description,
                    () => {
                      clearState();
                    }
                  )
                }
              >
                <i className="fal fa-check" /> {t("common.finish", "Finish")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    salonId: ownProps.salonId,
    name: ownProps.name,
    setName: ownProps.setName,
    address: ownProps.address,
    setAddress: ownProps.setAddress,
    webAddress: ownProps.webAddress,
    setWebAddress: ownProps.setWebAddress,
    phone: ownProps.phone,
    setPhone: ownProps.setPhone,
    mobilePhone: ownProps.mobilePhone,
    setMobilePhone: ownProps.setMobilePhone,
    email: ownProps.email,
    setEmail: ownProps.setEmail,
    salonTimeSlot: ownProps.salonTimeSlot,
    setSalonTimeSlot: ownProps.setSalonTimeSlot,
    customerSchedulePeriod: ownProps.customerSchedulePeriod,
    setCustomerSchedulePeriod: ownProps.setCustomerSchedulePeriod,
    embeddedMapAddress: ownProps.embeddedMapAddress,
    setEmbeddedMapAddress: ownProps.setEmbeddedMapAddress,
    embeddedMapAddressHasError: ownProps.embeddedMapAddressHasError,
    setEmbeddedMapAddressHasError: ownProps.setEmbeddedMapAddressHasError,
    latitude: ownProps.latitude,
    setLatitude: ownProps.setLatitude,
    longitude: ownProps.longitude,
    setLongitude: ownProps.setLongitude,
    generalizationId: ownProps.generalizationId,
    setGeneralizationId: ownProps.setGeneralizationId,
    description: ownProps.description,
    setDescription: ownProps.setDescription,
    workingTimeDescription: ownProps.workingTimeDescription,
    setWorkingTimeDescription: ownProps.setWorkingTimeDescription,
    saveSalon: ownProps.saveSalon,
    clearState: ownProps.clearState,
    formErrors: ownProps.formErrors,
    setFormErrors: ownProps.setFormErrors,
    editNameValid: ownProps.editNameValid,
    setEditNameValid: ownProps.setEditNameValid,
    editAddressValid: ownProps.editAddressValid,
    setEditAddressValid: ownProps.setEditAddressValid,
    editPhoneValid: ownProps.editPhoneValid,
    setEditPhoneValid: ownProps.setEditPhoneValid,
    editTimeSlotValid: ownProps.editTimeSlotValid,
    setEditTimeSlotValid: ownProps.setEditTimeSlotValid,
    editSchedulePeriodValid: ownProps.editSchedulePeriodValid,
    setEditSchedulePeriodValid: ownProps.setEditSchedulePeriodValid,
    editFormValid: ownProps.editFormValid,
    setEditFormValid: ownProps.setEditFormValid,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export const ConnectedEditSalonModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSalonModalComponent);
