import React, { Component } from "react";
import * as moment from "moment";
export class RangeColumn extends Component {
  render() {
    return (
      <td className="contact-cell">
        <span className="mobile-view-title">{this.props.mobileTitle}</span>
        {this.props.range.map(x => {
          return (
            <div key={x.workingTimeId}>
              <span className="date-label">{x.workingTimeRange}</span>
              <br />
            </div>
          );
        })}
      </td>
    );
  }
}
