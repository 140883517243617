import React, { Suspense, useEffect } from "react";
import i18n from "../i18n";
import {
  xorWith,
  isEmpty,
  isEqual,
  cloneDeep,
  isArray,
  sortBy,
  orderBy,
  capitalize,
} from "lodash";

export { cloneDeep };
export { isArray };
export { sortBy };
export { orderBy };
export { isEmpty };
export { capitalize };

export const waitForElement = (selector) => {
  return new Promise((resolve, reject) => {
    var element = document.querySelector(selector);

    if (element) {
      resolve(element);
      return;
    }

    var observer = new MutationObserver((mutations) => {
      mutations.forEach(function (mutation) {
        var nodes = Array.from(mutation.addedNodes);
        for (var node of nodes) {
          if (node.matches && node.matches(selector)) {
            observer.disconnect();
            resolve(node);
            return;
          }
        }
      });
    });

    observer.observe(document.documentElement, {
      childList: true,
      subtree: true,
    });
  });
};

export const showModal = (modalHtmlId) => {
  waitForElement(modalHtmlId).then(function (element) {
    window.jQuery(modalHtmlId).modal("show");
    window.jQuery(modalHtmlId).css("overflow", "auto");
  });
};

export const hideModal = (modalHtmlId) => {
  waitForElement(modalHtmlId).then(function (element) {
    window.jQuery(modalHtmlId).css("overflow", "hidden");
    window.jQuery(modalHtmlId).modal("hide");
  });
};

export const isArrayEqual = (x, y) => {
  return isEmpty(xorWith(x, y, isEqual));
};

export const createMapObject = (options) => {
  if (typeof options !== "object") {
    options = {};
  }
  if (typeof options.admin === "undefined") {
    options.admin = false;
  }
  if (typeof options.salonAdmin === "undefined") {
    options.salonAdmin = false;
  }
  if (typeof options.hairstylist === "undefined") {
    options.hairstylist = false;
  }

  let result = new Map();
  result.set("Admin", options.admin);
  result.set("Salon admin", options.salonAdmin);
  result.set("Hairstylist", options.hairstylist);

  return new Map(result.entries());
};

export const mapForRole = (userWithRole) => {
  // because Object.entries(new Date()).length === 0;
  // we have to do some additional check
  // Object.entries(activeUser).length === 0 &&
  //   activeUser.constructor === Object;

  // ECMA 5+
  //   Object.keys(activeUser).length === 0 &&
  //   activeUser.constructor === Object
  let result;
  if (!window.jQuery.isEmptyObject(userWithRole)) {
    switch (userWithRole.userRoles[0].role.name) {
      case "Admin":
        result = createMapObject({
          admin: true,
          salonAdmin: true,
          hairstylist: true,
        });
        break;

      case "Salon admin":
        result = createMapObject({
          admin: false,
          salonAdmin: true,
          hairstylist: true,
        });
        break;

      case "Hairstylist":
        result = createMapObject({
          admin: false,
          salonAdmin: false,
          hairstylist: true,
        });
        break;

      default:
        result = createMapObject();
    }
  } else {
    result = createMapObject();
  }

  return new Map(result.entries());
};

export const importSuspenseWrapper = (options) => {
  if (typeof options !== "object") {
    options = {};
  }

  if (typeof options.componentPath === "undefined") {
    options.componentPath = "./Home";
    options.componentName = "Home";
  }
  if (typeof options.componentName === "undefined") {
    options.componentPath = "./Home";
    options.componentName = "Home";
  }

  const Component =
    process.env.CURRENT_ENV === "PROD"
      ? React.lazy(() =>
          import(
            /* webpackChunkName: "[request]" */
            /* webpackMode: "lazy" */ `${options.componentPath}/${options.componentName}`
          )
        )
      : require(`${options.componentPath}/${options.componentName}`).default;

  if (process.env.CURRENT_ENV === "PROD") {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Component />
      </Suspense>
    );
  } else {
    return <Component />;
  }

  // const Component = require(`${options.componentPath}/${options.componentName}`)
  //   .default;

  // return (
  //   <>
  //     <Component />
  //   </>
  // );
};

export const getExpirationDate = (jwtToken) => {
  if (!jwtToken) {
    return null;
  }

  const jwt = JSON.parse(atob(jwtToken.split(".")[1]));

  // multiply by 1000 to convert seconds into milliseconds
  return (jwt && jwt.exp && jwt.exp * 1000) || null;
};

export const getSalonTypeName = (jwtToken) => {
  if (!jwtToken) {
    return null;
  }

  const jwt = JSON.parse(atob(jwtToken.split(".")[1]));

  // multiply by 1000 to convert seconds into milliseconds
  return (jwt && jwt["https://hairstylist.rs/ShopTypeName"]) || null;
};

export const getServiceProvireName = (jwtToken) => {
  if (!jwtToken) {
    return null;
  }

  const jwt = JSON.parse(atob(jwtToken.split(".")[1]));

  // multiply by 1000 to convert seconds into milliseconds
  return (jwt && jwt["https://hairstylist.rs/ServiceProviderName"]) || null;
};

export const getClientTypeName = (jwtToken) => {
  if (!jwtToken) {
    return null;
  }

  const jwt = JSON.parse(atob(jwtToken.split(".")[1]));

  // multiply by 1000 to convert seconds into milliseconds
  return (jwt && jwt["https://hairstylist.rs/ClientTypeName"]) || null;
};

export const isExpired = (exp) => {
  if (!exp) {
    return false;
  }

  return Date.now() > exp;
};

export const useScript = (url) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export const i18nMultiFallbackKey = (keyValue, defaultValue) => {
  return i18n.t(
    [
      `${keyValue}.${JSON.parse(sessionStorage.getItem("shopType"))}`,
      `${keyValue}.Default`,
    ],
    defaultValue
  );
};
