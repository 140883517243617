import React, { Component } from "react";
import * as helper from "../HelperRepository";
import { withTranslation } from "react-i18next";

class ActionButtonsColumn_WithoutTranslation extends Component {
  render() {
    return (
      <td className="actions-cell two-bttns">
        <div className="button-container">
          <button
            type="button"
            className="btn btn-outline-success btn-sm ico-l"
            data-toggle="modal"
            data-target="#editRoleModal"
            onClick={() => this.props.setParentState(this.props.dataItem)}
          >
            <i className="fal fa-edit"></i>
            {this.props.t("common.edit", "Edit")}
          </button>
          <button
            type="button"
            className="btn btn-outline-danger btn-sm ico-l"
            data-toggle="modal"
            data-target="#deleteRoleModal"
            onClick={() => this.props.setParentState(this.props.dataItem)}
          >
            <i className="fal fa-trash-alt"></i>
            {this.props.t("common.deactivate", "Deactivate")}
          </button>
        </div>
      </td>
    );
  }
}

export const ActionButtonsColumn = withTranslation()(
  ActionButtonsColumn_WithoutTranslation
);
