import React, { useState, Component, useEffect } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import * as helper from "../HelperRepository";
import { useTranslation, withTranslation } from "react-i18next";

const HairstylistSalonListComponent = ({
  getSalonHairstylistsPaged,
  totalCount,
  setSelectedSalon,
  setSelectedSalonHairstylist,
  selectedSalon,
  selectedHairstylist,
  salonHairstylists,
  pageState,
  setIsAssignToSalon,
  setIsUnassignFromSalon,
}) => {
  const { t, i18n } = useTranslation();

  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 10 });

  useEffect(() => {
    getSalonHairstylistsPaged(
      selectedHairstylist.personId,
      page.skip / 10 + 1,
      sort
    );
  }, [getSalonHairstylistsPaged, selectedHairstylist, page, sort]);

  useEffect(() => {
    setPage({ skip: (pageState - 1) * 10, take: 10 });
  }, [pageState]);

  const ActionButtonsColumnWithTranslate = withTranslation()(
    ActionButtonsColumn
  );

  const grid = (
    <Grid
      data={salonHairstylists.map((item) => ({
        ...item,
        selected: item.salon.salonId === selectedSalon.salonId,
      }))}
      pageable={{
        buttonCount: 3,
        info: true,
        type: "numeric",
        previousNext: true,
      }}
      skip={page.skip}
      take={page.take}
      onPageChange={(event) => {
        setPage({ skip: event.page.skip, take: event.page.take });
        getSalonHairstylistsPaged(
          selectedHairstylist.personId,
          event.page.skip / 10 + 1,
          sort
        );
      }}
      total={totalCount}
      sortable
      sort={sort}
      onSortChange={(e) => {
        setSort(e.sort);
        getSalonHairstylistsPaged(
          selectedHairstylist.personId,
          page.skip / 10 + 1,
          e.sort
        );
      }}
      style={{ height: "410px" }}
      className="table-header-purple"
      selectedField="selected"
      onRowClick={(e) => {
        if (selectedSalon.salonId === e.dataItem.salon.salonId) {
          setSelectedSalon({});
          setSelectedSalonHairstylist({});
        } else {
          setSelectedSalon(e.dataItem.salon);
          setSelectedSalonHairstylist(e.dataItem);
        }
      }}
    >
      <GridColumn
        field="salon.name"
        title={helper.i18nMultiFallbackKey(
          "hairstylist.hairstylistSalonListComponent.grid.shopName.title",
          "Shop name"
        )}
        className="name-cell"
        headerClassName="name-cell"
      />
      <GridColumn
        field="Actions"
        title={t("common.actions", "Actions")}
        cell={(props) => (
          <ActionButtonsColumnWithTranslate
            setSelectedSalonHairstylist={setSelectedSalonHairstylist}
            setSelectedSalon={setSelectedSalon}
            setIsUnassignFromSalon={setIsUnassignFromSalon}
            {...props}
          />
        )}
        headerClassName="actions-cell two-bttns"
      />
    </Grid>
  );
  return (
    <>
      <div className="table-rounded-border table-headlabel-responsive-md salon-table">
        <header>
          <div className="button-container">
            <button
              type="button"
              className="btn btn-outline-success btn-sm ico-l"
              // data-toggle="modal"
              // data-target={`#assignHairstylistToSalonModal`}
              onClick={() => {
                setIsAssignToSalon(true);
                helper.showModal(`#assignHairstylistToSalonModal`);
              }}
            >
              <i className="fal fa-plus-square"></i>{" "}
              <span className="mobile-hide-550">
                {t(
                  "hairstylist.hairstylistSalonListComponent.assignTo",
                  "Assign to "
                )}
              </span>
              {helper.i18nMultiFallbackKey(
                "hairstylist.hairstylistSalonListComponent.shop",
                "shop"
              )}
            </button>
          </div>
        </header>
        {grid}
      </div>
    </>
  );
};

class ActionButtonsColumn extends Component {
  render() {
    return (
      <td className="actions-cell two-bttns">
        <div className="button-container">
          <button
            type="button"
            className="btn btn-outline-danger btn-sm ico-l"
            // data-toggle="modal"
            // data-target="#unassignHairstylistFromSalonModal"
            onClick={() => {
              this.props.setSelectedSalon(this.props.dataItem.salon);
              this.props.setSelectedSalonHairstylist(this.props.dataItem);
              this.props.setIsUnassignFromSalon(true);
              helper.showModal("#unassignHairstylistFromSalonModal");
            }}
            // disabled={this.props.dataItem.hasDependents}
          >
            <i className="fal fa-trash-alt"></i>{" "}
            {helper.i18nMultiFallbackKey(
              "hairstylist.hairstylistSalonListComponent.unassignFromShop",
              "Unassign from shop"
            )}
          </button>
        </div>
      </td>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    salonHairstylists: state.salonHairstylists,
    totalCount: state.totalCount,
    setSelectedSalon: ownProps.setSelectedSalon,
    setSelectedSalonHairstylist: ownProps.setSelectedSalonHairstylist,
    setParentSearch: ownProps.setParentSearch,
    selectedSalon: ownProps.selectedSalon,
    selectedHairstylist: ownProps.selectedHairstylist,
    pageState: state.salonHairstylistPage,
    setIsAssignToSalon: ownProps.setIsAssignToSalon,
    setIsUnassignFromSalon: ownProps.setIsUnassignFromSalon,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getSalonHairstylistsPaged(searchValue, page, sort) {
    dispatch(mutations.getSalonHairstylistsPaged(searchValue, page, sort));
  },
  getSalonHairstylistsForExport(searchValue, totalCount, sort, cb) {
    dispatch(
      mutations.getSalonHairstylistsForExport(searchValue, totalCount, sort, cb)
    );
  },
});

export const ConnectedHairstylistSalonList = connect(
  mapStateToProps,
  mapDispatchToProps
)(HairstylistSalonListComponent);
