import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";

import * as mutations from "../../store/mutations";
import * as helper from "../HelperRepository";
import { useTranslation } from "react-i18next";
import RequiredFieldsComponent from "../RequiredFieldsComponent";

const SalonCreateWorkingTimeAuto = ({
  selectedSalon,
  activeUser,
  getActiveUser,
  createSalonWorkingTimeAuto,
  getSalonForId,
  clearCheckboxSystem,
}) => {
  const { t, i18n } = useTranslation();

  const [currentDate, setCurrentDate] = useState(new Date());
  const [exceptionStartDate, setExceptionStartDate] = useState(new Date());
  const [exceptionEndDate, setExceptionEndDate] = useState(new Date());
  const [exceptions, setExceptions] = useState([]);
  const [rolesMap, setRolesMap] = useState(() => {
    return helper.createMapObject();
  });

  /*************** Create working time *************/
  const [workOnSat, setWorkOnSat] = useState(false);
  const [workOnSun, setWorkOnSun] = useState(false);

  const [createWorkingTimeStartTime, setCreateWorkingTimeStartTime] = useState(
    () => {
      let s = moment().startOf("day").hours(9).toDate();
      return s;
    }
  );
  const [createWorkingTimeEndTime, setCreateWorkingTimeEndTime] = useState(
    () => {
      let e = moment().startOf("day").hours(21).toDate();
      return e;
    }
  );

  const [
    createWorkingTimeStartTimeSat,
    setCreateWorkingTimeStartTimeSat,
  ] = useState(() => {
    let s = moment().startOf("day").hours(9).toDate();
    return s;
  });
  const [
    createWorkingTimeEndTimeSat,
    setCreateWorkingTimeEndTimeSat,
  ] = useState(() => {
    let e = moment().startOf("day").hours(21).toDate();
    return e;
  });

  const [
    createWorkingTimeStartTimeSun,
    setCreateWorkingTimeStartTimeSun,
  ] = useState(() => {
    let s = moment().startOf("day").hours(9).toDate();
    return s;
  });
  const [
    createWorkingTimeEndTimeSun,
    setCreateWorkingTimeEndTimeSun,
  ] = useState(() => {
    let e = moment().startOf("day").hours(21).toDate();
    return e;
  });

  const [
    createWorkingTimeSelectedYear,
    setCreateWorkingTimeSelectedYear,
  ] = useState(() => {
    let e = moment().startOf("day").toDate();
    return moment(e).format("YYYY");
  });

  const clearCreateWorkingTimeElements = () => {
    let toDate = moment().startOf("day").toDate();

    setWorkOnSat(false);
    setWorkOnSun(false);

    setCreateWorkingTimeStartTime(toDate);
    setCreateWorkingTimeEndTime(toDate);

    setCreateWorkingTimeStartTimeSat(toDate);
    setCreateWorkingTimeEndTimeSat(toDate);

    setCreateWorkingTimeStartTimeSun(toDate);
    setCreateWorkingTimeEndTimeSun(toDate);

    setCreateWorkingTimeSelectedYear(moment(toDate).format("YYYY"));
  };

  const enumerateDaysBetweenDates = (startDate, endDate) => {
    var dates = [];

    var currDate = moment(startDate).startOf("day");
    var lastDate = moment(endDate).startOf("day");

    dates.push(currDate.clone().format("YYYY-MM-DD"));

    while (currDate.add(1, "days").diff(lastDate) <= 0) {
      dates.push(currDate.clone().format("YYYY-MM-DD"));
    }

    return dates;
  };

  const createWorkingTimeElement = (salonId, date, startTime, endTime) => {
    let o = {
      SalonId: salonId,
      StartTime: moment(date)
        .hour(moment(startTime).hour())
        .minute(moment(startTime).minute())
        .format("YYYY-MM-DD HH:mm"),
      EndTime: moment(date)
        .hour(moment(endTime).hour())
        .minute(moment(endTime).minute())
        .format("YYYY-MM-DD HH:mm"),
    };

    return helper.cloneDeep(o);
  };

  const calculateAndCreateWorkingTime = (cb) => {
    let resultList = [];
    let dateList = [];

    if (createWorkingTimeSelectedYear === moment(new Date()).format("YYYY")) {
      dateList = enumerateDaysBetweenDates(
        new Date(),
        moment(createWorkingTimeSelectedYear, "YYYY").endOf("year")
      );
    } else {
      dateList = enumerateDaysBetweenDates(
        moment(createWorkingTimeSelectedYear, "YYYY").startOf("year"),
        moment(createWorkingTimeSelectedYear, "YYYY").endOf("year")
      );
    }

    exceptions.forEach((exceptionGroup) => {
      let exceptionsDateList = enumerateDaysBetweenDates(
        moment(exceptionGroup.start, "YYYY-MM-DD"),
        moment(exceptionGroup.end, "YYYY-MM-DD")
      );

      exceptionsDateList.forEach((date) => {
        let i = dateList.findIndex((d) => d === date);
        dateList.splice(i, 1);
      });
    });
    dateList.forEach((dateElement) => {
      if (moment(dateElement))
        if (moment(dateElement).isoWeekday() < 6) {
          resultList.push(
            createWorkingTimeElement(
              selectedSalon.salonId,
              dateElement,
              createWorkingTimeStartTime,
              createWorkingTimeEndTime
            )
          );
        }

      if (moment(dateElement).isoWeekday() === 6) {
        if (workOnSat) {
          resultList.push(
            createWorkingTimeElement(
              selectedSalon.salonId,
              dateElement,
              createWorkingTimeStartTimeSat,
              createWorkingTimeEndTimeSat
            )
          );
        }
      }
      if (moment(dateElement).isoWeekday() === 7) {
        if (workOnSun) {
          resultList.push(
            createWorkingTimeElement(
              selectedSalon.salonId,
              dateElement,
              createWorkingTimeStartTimeSun,
              createWorkingTimeEndTimeSun
            )
          );
        }
      }
    });

    createSalonWorkingTimeAuto(selectedSalon.salonId, resultList, cb);
  };

  // const activeDates = () => {
  //   let dates = enumerateDaysBetweenDates(
  //     moment(createWorkingTimeSelectedYear, "YYYY").startOf("year"),
  //     moment(createWorkingTimeSelectedYear, "YYYY").endOf("year")
  //   );
  //   return [...dates];
  // };

  const activeYears = () => {
    let years = [];
    let currentYear = moment(new Date()).year();
    for (let i = 0; i < 15; i++) {
      years.push(currentYear + i);
    }
    return [...helper.cloneDeep(years)];
  };

  /*************** End Create working time *************/

  // useEffect(() => {
  //   if (!activeUser || !activeUser.personId) {
  //     getActiveUser();
  //   }
  //   return () => {
  //     document.getElementsByTagName("html")[0].classList.remove("grid-view");
  //   };
  // }, []);

  // useEffect(() => {
  //   if (!rolesMap.get("Admin")) {
  //     if (rolesMap.get("Salon admin")) {
  //       getSalonForId(activeUser.userRoles[0].externalId);
  //     }
  //   }
  // }, [rolesMap]);

  // useEffect(() => {
  //   setRolesMap(helper.mapForRole(activeUser));
  // }, [activeUser]);

  return (
    <div
      className="modal fade"
      id="createSalonWorkingTimeAutoModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="createSalonWorkingTimeAutoModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-md create-working-time"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id="createSalonWorkingTimeAutoModalLabel"
            >
              <i className="fal fa-clock fa-lg c-purple"></i>{" "}
              <span className="txt">
                {t(
                  "salon.SalonCreateWorkingTimeAuto.createSalonWorkingTimeAutoModal.title",
                  "Create Working Time"
                )}
              </span>
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="fal fa-times" />
            </button>
          </div>

          <div className="modal-body">
            <form className="form needs-validation" noValidate>
              <input type="hidden" value="something" />
              <div className="form-group mb-3">
                <label htmlFor="createWorkingTimeAutoSalonName">
                  {helper.i18nMultiFallbackKey(
                    "salon.SalonCreateWorkingTimeAuto.createSalonWorkingTimeAutoModal.shopLabel",
                    "Shop name"
                  )}
                </label>
                <input
                  type="text"
                  className="form-control pl-0-maxw-575"
                  id="createWorkingTimeAutoSalonName"
                  value={`${selectedSalon.name}`}
                  readOnly
                />
              </div>
              <div className="row mb-3">
                <div className="form-group col-sm-5 col-md-5">
                  <label className="form-group-title pl-0-maxw-575 mb-2 required">
                    {t(
                      "salon.SalonCreateWorkingTimeAuto.createSalonWorkingTimeAutoModal.workingTimeYearLabel",
                      "Working time year"
                    )}
                  </label>
                  <div className="custom-select-box ico-start-blue">
                    <select
                      className="form-control"
                      name="createWorkingTimeAutoSelectedYear"
                      value={createWorkingTimeSelectedYear || ""}
                      onChange={(event) => {
                        setCreateWorkingTimeSelectedYear(event.target.value);
                        if (event.target.value === moment().format("YYYY")) {
                          setExceptionStartDate(new Date());
                          setExceptionEndDate(new Date());
                        } else {
                          setExceptionStartDate(
                            moment(event.target.value, "YYYY").startOf("year")
                          );
                          setExceptionEndDate(
                            moment(event.target.value, "YYYY").startOf("year")
                          );
                        }
                      }}
                    >
                      <option key={""} value={""}>
                        {"Select year"}
                      </option>
                      {activeYears().map((year) => {
                        return (
                          <option key={`${year}`} value={`${year}`}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="invalid-feedback">
                    {helper.i18nMultiFallbackKey(
                      "salon.SalonCreateWorkingTimeAuto.createSalonWorkingTimeAutoModal.shopInvalid",
                      "Please select your shop."
                    )}
                  </div>
                </div>
                <div className="form-group col-sm-7 col-md-6 offset-md-1 mt-2 mt-sm-0">
                  <div className="row">
                    <label className="form-group-title pl-15-maxw-575 mb-2 mb-sm-3">
                      {t(
                        "salon.SalonCreateWorkingTimeAuto.createSalonWorkingTimeAutoModal.weekNonWorkingDaysLabel",
                        "Week non-working days"
                      )}
                    </label>
                    <div className="col-6 col-sm-5">
                      <label className="switch-holder size-lg pl-0  text-nowrap">
                        <span className="switch">
                          <input
                            onChange={(e) => setWorkOnSat(e.target.checked)}
                            name="createWorkingTimeAutoToggleSwithSaturday"
                            type="checkbox"
                            id="createWorkingTimeAutoToggleSwithSaturday"
                            checked={workOnSat}
                            value={workOnSat}
                          />
                          <span className="slider"></span>
                        </span>
                        <span className="switch-label">Saturday</span>
                      </label>
                    </div>
                    <div className="col-6 col-sm-7">
                      <label className="switch-holder size-lg pl-0 text-nowrap">
                        <span className="switch">
                          <input
                            onChange={(e) => setWorkOnSun(e.target.checked)}
                            name="createWorkingTimeAutoToggleSwithSunday"
                            type="checkbox"
                            id="createWorkingTimeAutoToggleSwithSunday"
                            checked={workOnSun}
                            value={workOnSun}
                          />
                          <span className="slider"></span>
                        </span>
                        <span className="switch-label">
                          {t(
                            "salon.SalonCreateWorkingTimeAuto.createSalonWorkingTimeAutoModal.sundayLabel",
                            "Sunday"
                          )}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label className="form-group-title pl-0-maxw-575">
                    {t(
                      "salon.SalonCreateWorkingTimeAuto.createSalonWorkingTimeAutoModal.generalWorkingTimeLabel",
                      "General working time"
                    )}
                  </label>
                </div>
              </div>
              <div className="row col-center-padd-5-maxw-575 general-working-time">
                <div className="form-group calendar-controls cc-default nested-react-wrapper-w-100 cc-time col-6 col-md-4 ico-r p-r23-b8">
                  <label
                    htmlFor="createWorkingTimeAutoStartTime"
                    className="text-nowrap required"
                  >
                    {t(
                      "salon.SalonCreateWorkingTimeAuto.createSalonWorkingTimeAutoModal.startTimeLabel",
                      "Start time"
                    )}
                  </label>
                  <DatePicker
                    className="form-control fc-sm"
                    id="createWorkingTimeAutoStartTime"
                    selected={createWorkingTimeStartTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={selectedSalon.salonTimeSlot}
                    timeCaption="Time"
                    timeFormat="HH:mm"
                    dateFormat="HH:mm"
                    onKeyDown={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onChange={(event) => {
                      let newStartTime = moment(currentDate, "YYYY-MM-DD")
                        .hours(moment(event).format("HH"))
                        .minutes(moment(event).format("mm"))
                        .seconds(0)
                        .milliseconds(0)
                        .toDate();
                      let newEndTime = moment(currentDate, "YYYY-MM-DD")
                        .hours(moment(createWorkingTimeEndTime).format("HH"))
                        .minutes(moment(createWorkingTimeEndTime).format("mm"))
                        .seconds(0)
                        .milliseconds(0)
                        .toDate();

                      if (newStartTime > newEndTime) {
                        newEndTime = newStartTime;
                      }

                      setCreateWorkingTimeStartTime(newStartTime);
                      setCreateWorkingTimeEndTime(newEndTime);
                    }}
                  />
                  <i className="fal fa-clock"></i>
                </div>
                <div className="form-group calendar-controls cc-default nested-react-wrapper-w-100 cc-time col-6 col-md-4 ico-r p-r23-b8">
                  <label
                    htmlFor="createWorkingTimeAutoEndTime"
                    className="text-nowrap required"
                  >
                    {t(
                      "salon.SalonCreateWorkingTimeAuto.createSalonWorkingTimeAutoModal.endTimeLabel",
                      "End time"
                    )}
                  </label>
                  <DatePicker
                    className="form-control fc-sm"
                    id="createWorkingTimeAutoEndTime"
                    selected={createWorkingTimeEndTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={selectedSalon.salonTimeSlot}
                    timeCaption="Time"
                    timeFormat="HH:mm"
                    dateFormat="HH:mm"
                    onKeyDown={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onChange={(event) => {
                      let newEndTime = moment(currentDate, "YYYY-MM-DD")
                        .hours(moment(event).format("HH"))
                        .minutes(moment(event).format("mm"))
                        .seconds(0)
                        .milliseconds(0)
                        .toDate();

                      let newStartTime = moment(currentDate, "YYYY-MM-DD")
                        .hours(moment(createWorkingTimeStartTime).format("HH"))
                        .minutes(
                          moment(createWorkingTimeStartTime).format("mm")
                        )
                        .seconds(0)
                        .milliseconds(0)
                        .toDate();

                      if (newStartTime > newEndTime) {
                        newStartTime = newEndTime;
                      }

                      setCreateWorkingTimeEndTime(newEndTime);
                      setCreateWorkingTimeStartTime(newStartTime);
                    }}
                  />
                  <i className="fal fa-clock"></i>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 saturday-sunday-group">
                  {workOnSat && (
                    <div className="row">
                      <div className="col-12 col-md-2 pr-md-0">
                        <label className="form-group-title">
                          {t(
                            "salon.SalonCreateWorkingTimeAuto.createSalonWorkingTimeAutoModal.saturdayLabel",
                            "Saturday:"
                          )}
                        </label>
                      </div>
                      <div className="form-group calendar-controls cc-default nested-react-wrapper-inline-100 cc-time col-md-10">
                        <div className="start-time-block">
                          <label htmlFor="createWorkingTimeAutoStartTimeSat">
                            {t(
                              "salon.SalonCreateWorkingTimeAuto.createSalonWorkingTimeAutoModal.saturdayStartTimeLabel",
                              "Start time"
                            )}
                          </label>
                          <DatePicker
                            className="form-control fc-xs"
                            id="createWorkingTimeAutoStartTimeSat"
                            selected={createWorkingTimeStartTimeSat}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={selectedSalon.salonTimeSlot}
                            timeCaption="Time"
                            timeFormat="HH:mm"
                            dateFormat="HH:mm"
                            onKeyDown={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onChange={(event) => {
                              let newStartTime = moment(
                                currentDate,
                                "YYYY-MM-DD"
                              )
                                .hours(moment(event).format("HH"))
                                .minutes(moment(event).format("mm"))
                                .seconds(0)
                                .milliseconds(0)
                                .toDate();

                              let newEndTime = moment(currentDate, "YYYY-MM-DD")
                                .hours(
                                  moment(createWorkingTimeEndTimeSat).format(
                                    "HH"
                                  )
                                )
                                .minutes(
                                  moment(createWorkingTimeEndTimeSat).format(
                                    "mm"
                                  )
                                )
                                .seconds(0)
                                .milliseconds(0)
                                .toDate();

                              if (newStartTime > newEndTime) {
                                newEndTime = newStartTime;
                              }

                              setCreateWorkingTimeStartTimeSat(newStartTime);
                              setCreateWorkingTimeEndTimeSat(newEndTime);
                            }}
                          />
                        </div>

                        <div className="end-time-block">
                          <label htmlFor="createWorkingTimeAutoEndTimeSat">
                            {t(
                              "salon.SalonCreateWorkingTimeAuto.createSalonWorkingTimeAutoModal.saturdayEndTimeLabel",
                              "End time"
                            )}
                          </label>
                          <DatePicker
                            className="form-control fc-xs"
                            id="createWorkingTimeAutoEndTimeSat"
                            selected={createWorkingTimeEndTimeSat}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={selectedSalon.salonTimeSlot}
                            timeCaption="Time"
                            timeFormat="HH:mm"
                            dateFormat="HH:mm"
                            onKeyDown={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onChange={(event) => {
                              let newEndTime = moment(currentDate, "YYYY-MM-DD")
                                .hours(moment(event).format("HH"))
                                .minutes(moment(event).format("mm"))
                                .seconds(0)
                                .milliseconds(0)
                                .toDate();

                              let newStartTime = moment(
                                currentDate,
                                "YYYY-MM-DD"
                              )
                                .hours(
                                  moment(createWorkingTimeStartTimeSat).format(
                                    "HH"
                                  )
                                )
                                .minutes(
                                  moment(createWorkingTimeStartTimeSat).format(
                                    "mm"
                                  )
                                )
                                .seconds(0)
                                .milliseconds(0)
                                .toDate();

                              if (newStartTime > newEndTime) {
                                newStartTime = newEndTime;
                              }
                              setCreateWorkingTimeEndTimeSat(newEndTime);
                              setCreateWorkingTimeStartTimeSat(newStartTime);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {workOnSun && (
                    <div className="row">
                      <div className="col-md-2 pr-md-0">
                        <label className="form-group-title">
                          {t(
                            "salon.SalonCreateWorkingTimeAuto.createSalonWorkingTimeAutoModal.sundayLabel",
                            "Sunday:"
                          )}
                        </label>
                      </div>
                      <div
                        className="form-group calendar-controls cc-default nested-react-wrapper-inline-100 cc-time col-md-10"
                        disabled={workOnSun}
                      >
                        <div className="start-time-block">
                          <label htmlFor="createWorkingTimeAutoStartTimeSun">
                            {t(
                              "salon.SalonCreateWorkingTimeAuto.createSalonWorkingTimeAutoModal.sundayStartTimeLabel",
                              "Start time"
                            )}
                          </label>
                          <DatePicker
                            className="form-control fc-xs"
                            id="createWorkingTimeAutoStartTimeSun"
                            selected={createWorkingTimeStartTimeSun}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={selectedSalon.salonTimeSlot}
                            timeCaption="Time"
                            timeFormat="HH:mm"
                            dateFormat="HH:mm"
                            onKeyDown={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onChange={(event) => {
                              let newStartTime = moment(
                                currentDate,
                                "YYYY-MM-DD"
                              )
                                .hours(moment(event).format("HH"))
                                .minutes(moment(event).format("mm"))
                                .seconds(0)
                                .milliseconds(0)
                                .toDate();

                              let newEndTime = moment(currentDate, "YYYY-MM-DD")
                                .hours(
                                  moment(createWorkingTimeEndTimeSun).format(
                                    "HH"
                                  )
                                )
                                .minutes(
                                  moment(createWorkingTimeEndTimeSun).format(
                                    "mm"
                                  )
                                )
                                .seconds(0)
                                .milliseconds(0)
                                .toDate();

                              if (newStartTime > newEndTime) {
                                newEndTime = newStartTime;
                              }

                              setCreateWorkingTimeStartTimeSun(newStartTime);
                              setCreateWorkingTimeEndTimeSun(newEndTime);
                            }}
                          />
                        </div>

                        <div className="end-time-block">
                          <label htmlFor="createWorkingTimeAutoEndTimeSun">
                            {t(
                              "salon.SalonCreateWorkingTimeAuto.createSalonWorkingTimeAutoModal.sundayEndTimeLabel",
                              "End time"
                            )}
                          </label>
                          <DatePicker
                            className="form-control fc-xs"
                            id="createWorkingTimeAutoEndTimeSun"
                            selected={createWorkingTimeEndTimeSun}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={selectedSalon.salonTimeSlot}
                            timeCaption="Time"
                            timeFormat="HH:mm"
                            dateFormat="HH:mm"
                            onKeyDown={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onChange={(event) => {
                              let newEndTime = moment(currentDate, "YYYY-MM-DD")
                                .hours(moment(event).format("HH"))
                                .minutes(moment(event).format("mm"))
                                .seconds(0)
                                .milliseconds(0)
                                .toDate();

                              let newStartTime = moment(
                                currentDate,
                                "YYYY-MM-DD"
                              )
                                .hours(
                                  moment(createWorkingTimeStartTimeSun).format(
                                    "HH"
                                  )
                                )
                                .minutes(
                                  moment(createWorkingTimeStartTimeSun).format(
                                    "mm"
                                  )
                                )
                                .seconds(0)
                                .milliseconds(0)
                                .toDate();

                              if (newStartTime > newEndTime) {
                                newStartTime = newEndTime;
                              }

                              setCreateWorkingTimeEndTimeSun(newEndTime);
                              setCreateWorkingTimeStartTimeSun(newStartTime);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label className="form-group-title pl-0-maxw-575">
                    {t(
                      "salon.SalonCreateWorkingTimeAuto.createSalonWorkingTimeAutoModal.addNonWorkingDayExceptionsLabel",
                      "Add non-working days exceptions"
                    )}
                  </label>
                </div>
              </div>

              <div className="row cols-block-maxw-575 non-working-days">
                <div className="col-9">
                  <div className="row col-center-padd-5-maxw-575">
                    <div className="form-group calendar-controls nested-react-wrapper-w-100 cc-default col-6 ico-r p-r28-b12">
                      <label htmlFor="createWorkingTimeAutoStartDate">
                        {t(
                          "salon.SalonCreateWorkingTimeAuto.createSalonWorkingTimeAutoModal.addNonWorkingDayExceptionsStartDateLabel",
                          "Start date"
                        )}
                      </label>
                      <DatePicker
                        id="createWorkingTimeAutoStartDate"
                        className="form-control"
                        selected={moment(exceptionStartDate, "YYYY-MM-DD")
                          .startOf("day")
                          .toDate()}
                        dateFormat="yyyy-MM-dd"
                        onKeyDown={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onChange={(date) => {
                          setExceptionStartDate(
                            moment(date).format("YYYY-MM-DD")
                          );
                        }}
                        placeholderText="Select date"
                        minDate={moment(createWorkingTimeSelectedYear, "YYYY")
                          .startOf("year")
                          .toDate()}
                        maxDate={moment(createWorkingTimeSelectedYear, "YYYY")
                          .endOf("year")
                          .toDate()}
                      />
                      <i className="fal fa-calendar-day"></i>
                    </div>
                    <div className="form-group calendar-controls nested-react-wrapper-w-100 cc-default col-6 ico-r p-r28-b12">
                      <label htmlFor="createWorkingTimeAutoEndDate">
                        {t(
                          "salon.SalonCreateWorkingTimeAuto.createSalonWorkingTimeAutoModal.addNonWorkingDayExceptionsEndDateLabel",
                          "End date"
                        )}
                      </label>
                      <DatePicker
                        id="createWorkingTimeAutoEndDate"
                        className="form-control"
                        selected={moment(exceptionEndDate, "YYYY-MM-DD")
                          .startOf("day")
                          .toDate()}
                        dateFormat="yyyy-MM-dd"
                        onKeyDown={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onChange={(date) => {
                          setExceptionEndDate(
                            moment(date).format("YYYY-MM-DD")
                          );
                        }}
                        placeholderText="Select date"
                        minDate={moment(createWorkingTimeSelectedYear, "YYYY")
                          .startOf("year")
                          .toDate()}
                        maxDate={moment(createWorkingTimeSelectedYear, "YYYY")
                          .endOf("year")
                          .toDate()}
                      />
                      <i className="fal fa-calendar-day"></i>
                    </div>
                  </div>
                </div>

                <div className="col-3 text-center">
                  <label className="d-none d-sm-block mb-1">&nbsp;</label>
                  <button
                    type="button"
                    className="btn btn-purple-outline ico-l btn-add-date"
                    onClick={() => {
                      let ex = exceptions;
                      ex.push({
                        start: exceptionStartDate,
                        end: exceptionEndDate,
                      });
                      setExceptions([...ex]);
                    }}
                    disabled={
                      moment(exceptionEndDate) < moment(exceptionStartDate)
                    }
                  >
                    <i className="fal fa-plus"></i>{" "}
                    {t(
                      "salon.SalonCreateWorkingTimeAuto.createSalonWorkingTimeAutoModal.addLabel",
                      "Add"
                    )}
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="col-12 px-0-maxw-575">
                  <div className="form-group">
                    <label className="mb-10 mt-2 mt-sm-0 c-gray-darker">
                      {t(
                        "salon.SalonCreateWorkingTimeAuto.createSalonWorkingTimeAutoModal.listOfNonWorkingDaysLabel",
                        "List of non-working days"
                      )}
                    </label>
                    <div className="pill-group px-19">
                      {exceptions.map((date, index) => {
                        return (
                          <span key={index} className="pill">
                            {moment(date.start).format("DD.MM.YYYY.")}-
                            {moment(date.end).format("DD.MM.YYYY.")}{" "}
                            <button
                              className="fal fa-times"
                              onClick={() => {
                                let ex = exceptions;
                                ex.splice(index, 1);
                                setExceptions([...ex]);
                              }}
                            ></button>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <RequiredFieldsComponent />
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-success ico-l"
              data-dismiss="modal"
              onClick={() => {
                calculateAndCreateWorkingTime(() => {
                  clearCreateWorkingTimeElements();
                  clearCheckboxSystem();
                });
              }}
            >
              <i className="fal fa-check" /> {t("common.finish", "Finish")}
            </button>
            <button
              type="button"
              className="btn btn-outline-danger ico-l"
              data-dismiss="modal"
              onClick={() => {
                clearCreateWorkingTimeElements();
                clearCheckboxSystem();
              }}
            >
              <i className="fal fa-times" /> {t("common.cancel", "Cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    activeUser: state.activeUser,
    selectedSalon: ownProps.selectedSalon,
    clearCheckboxSystem: ownProps.clearCheckboxSystem,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActiveUser() {
      if (sessionStorage.getItem("user_id")) {
        dispatch(
          mutations.getActiveUser(JSON.parse(sessionStorage.getItem("user_id")))
        );
      }
    },
    getSalonWorkingTimeIsDateCreated(salonId, startDate) {
      dispatch(mutations.getSalonWorkingTimeIsDateCreated(salonId, startDate));
    },
    createSalonWorkingTimeAuto(salonId, workingTimeList, cb) {
      dispatch(
        mutations.createSalonWorkingTimeAuto(salonId, workingTimeList, cb)
      );
    },
  };
};

export const ConnectedSalonCreateWorkingTimeAuto = connect(
  mapStateToProps,
  mapDispatchToProps
)(SalonCreateWorkingTimeAuto);
