import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as moment from "moment";
import * as mutations from "../../store/mutations";
import { ConnectedCalendarDaily } from "../Home/CalendarDaily";
import { ConnectedCalendarWeekly } from "../Home/CalendarWeekly";
import { ConnectedCalendarMonthly } from "../Home/CalendarMonthly";

const HairstylistCalendarComponent = ({
  hairstylistId,
  salonId,
  getDashboardData
}) => {
  const [currentDate, setCurrentDate] = useState(
    moment().format("DD.MM.YYYY.")
  );
  const [currentWeekStart, setCurrentWeekStart] = useState(
    moment()
      .startOf("isoWeek")
      .format("DD.MM.YYYY.")
  );
  const [currentMonthStart, setCurrentMonthStart] = useState(
    moment()
      .startOf("month")
      .format("DD.MM.YYYY.")
  );
  useEffect(() => {
    if (hairstylistId) {
      getDashboardData(salonId, hairstylistId, currentDate);
    }
  }, [hairstylistId, currentDate, salonId, getDashboardData]);

  return (
    <div className="row">
      <ConnectedCalendarDaily
        salonId={salonId}
        hairstylistId={hairstylistId}
        currentDate={currentDate}
        getDashboardData={getDashboardData}
        setCurrentMonthStart={setCurrentMonthStart}
        setCurrentWeekStart={setCurrentWeekStart}
        setCurrentDate={setCurrentDate}
      />
      <ConnectedCalendarWeekly
        salonId={salonId}
        hairstylistId={hairstylistId}
        currentWeekStart={currentWeekStart}
        setCurrentMonthStart={setCurrentMonthStart}
        setCurrentWeekStart={setCurrentWeekStart}
        setCurrentDate={setCurrentDate}
      />
      <ConnectedCalendarMonthly
        salonId={salonId}
        hairstylistId={hairstylistId}
        currentMonthStart={currentMonthStart}
        setCurrentMonthStart={setCurrentMonthStart}
        setCurrentWeekStart={setCurrentWeekStart}
        setCurrentDate={setCurrentDate}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    hairstylistId: ownProps.hairstylistId,
    salonId: ownProps.salonId,
    dashboardDataDaily: state.dashboardDataDaily
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDashboardData(salonId, hairstylistId, visitStartDate) {
      let requestData = {
        SalonId: salonId,
        HairstylistId: hairstylistId,
        VisitDate: moment(visitStartDate, "DD.MM.YYYY.").format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
        SelectedServices: []
      };
      dispatch(mutations.getDashboardData(requestData));
    }
  };
};

export const ConnectedHairstylistCalendar = connect(
  mapStateToProps,
  mapDispatchToProps
)(HairstylistCalendarComponent);
