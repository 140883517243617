import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { withTranslation } from "react-i18next";

class ServiceTypesDropDownComponent extends Component {
  render() {
    if (this.props.serviceTypes.length > 0) {
      return (
        <div className="custom-select-box">
          <select
            className="form-control"
            value={this.props.serviceTypeId}
            onChange={(event) =>
              this.props.setServiceTypeId(event.target.value)
            }
            required
          >
            <option key={""} value={""}>
              {this.props.t(
                "dropdowns.ServiceTypesDropDownComponent.label",
                "Select serviceType"
              )}
            </option>
            {this.props.serviceTypes.map((r) => {
              return (
                <option key={r.serviceTypeId} value={r.serviceTypeId}>
                  {r.serviceTypeName}
                </option>
              );
            })}
          </select>
        </div>
      );
    } else {
      return (
        <div className="custom-select-box">
          <select
            className="form-control"
            value={this.props.serviceTypeId}
            onChange={(event) =>
              this.props.setServiceTypeId(event.target.value)
            }
            required
          >
            <option key={""} value={""}>
              {this.props.t(
                "dropdowns.ServiceTypesDropDownComponent.label",
                "Select serviceType"
              )}
            </option>
          </select>
        </div>
      );
    }
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    serviceTypes: ownProps.serviceTypes,
    serviceTypeId: ownProps.serviceTypeId,
    setServiceTypeId: ownProps.setServiceTypeId,
    salonId: ownProps.salonId,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export const ConnectedServiceTypesDropDown = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ServiceTypesDropDownComponent)
);
