import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { withTranslation } from "react-i18next";

class HairstylistsDropDownComponent extends Component {
  componentDidMount() {
    this.props.getAllHairstylists();
  }

  render() {
    let test = [];

    if (this.props.salonId !== "") {
      test = this.props.hairstylists.filter(
        (hairstylist) =>
          hairstylist.salonHairstylists[0].salonId === this.props.salonId
      );
    }
    if (test.length > 0) {
      return (
        <div className="custom-select-box">
          <select
            className="form-control"
            value={this.props.hairstylistId}
            onChange={(event) =>
              this.props.setHairstylistId(event.target.value)
            }
            required
          >
            <option key={""} value={""}>
              {this.props.t(
                "dropdowns.hairstylistsDropDownComponent.label",
                "Select hairstylist"
              )}
            </option>
            {/* {this.props.hairstylists.map(r => { */}
            {test.map((r) => {
              return (
                <option key={r.personId} value={r.personId}>
                  {r.firstName} {r.lastName}
                </option>
              );
            })}
          </select>
        </div>
      );
    } else {
      return (
        <div className="custom-select-box">
          <select
            className="form-control"
            value={this.props.hairstylistId}
            onChange={(event) =>
              this.props.setHairstylistId(event.target.value)
            }
            required
          >
            <option key={""} value={""}>
              {this.props.t(
                "dropdowns.hairstylistsDropDownComponent.label",
                "Select hairstylist"
              )}
            </option>
          </select>
        </div>
      );
    }
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    hairstylists: state.hairstylists,
    salons: state.salons,
    hairstylistId: ownProps.hairstylistId,
    setHairstylistId: ownProps.setHairstylistId,
    salonId: ownProps.salonId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllHairstylists() {
    dispatch(mutations.getAllHairstylists());
  },
  getAllSalons() {
    dispatch(mutations.getAllSalons());
  },
});

export const ConnectedHairstylistsDropDownComponent = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(HairstylistsDropDownComponent)
);
