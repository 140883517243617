import React, { Component } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import * as helper from "./HelperRepository";

const NewItemButton = ({ path, clearParentState }) => {
  const { t } = useTranslation();

  let currentType = "";
  let currentTypeName = "";
  let currentNew = "";

  switch (path) {
    case "home":
      currentType = "Home";
      currentTypeName = t("newItemButton.currentTypeName.home", "Home");
      currentNew = t("newItemButton.currentTypeName.new_home", "");
      break;
    case "category":
      currentType = "Category";
      currentTypeName = t("newItemButton.currentTypeName.category", "Category");
      currentNew = t("newItemButton.currentTypeName.new_category", "New");
      break;
    case "user":
      currentType = "User";
      currentTypeName = t("newItemButton.currentTypeName.user", "User");
      currentNew = t("newItemButton.currentTypeName.new_user", "New");
      break;
    case "customer":
      currentType = "Customer";
      currentTypeName = helper.i18nMultiFallbackKey("newItemButton.currentTypeName.client", "Client");
      currentNew = helper.i18nMultiFallbackKey("newItemButton.currentTypeName.new_client", "New");
      break;
    case "role":
      currentType = "Role";
      currentTypeName = t("newItemButton.currentTypeName.role", "Role");
      currentNew = t("newItemButton.currentTypeName.new_role", "New");
      break;
    case "salon":
      currentType = "Salon";
      currentTypeName = helper.i18nMultiFallbackKey("newItemButton.currentTypeName.shop", "Shop");
      currentNew = helper.i18nMultiFallbackKey("newItemButton.currentTypeName.new_shop", "New");
      break;
    case "hairstylist":
      currentType = "Hairstylist";
      currentTypeName = t("newItemButton.currentTypeName.provider", "Provider");
      currentNew = t("newItemButton.currentTypeName.new_provider", "New");
      break;
    case "visit":
      currentType = "Visit";
      currentTypeName = t("newItemButton.currentTypeName.visit", "Visit");
      currentNew = t("newItemButton.currentTypeName.new_visit", "New");
      break;
    default:
      currentType = "";
      currentTypeName = "";
      currentNew = "";
  }

  return (
    <button
      type="button"
      className="btn btn-purple-outline btn-sm ico-l"
      data-toggle="modal"
      data-target={`#create${currentType}Modal`}
      onClick={clearParentState}
    >
      <i className="fal fa-plus"></i>
      {currentNew}
      <span className="mobile-hide-550"> {currentTypeName}</span>
    </button>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    path: state.path,
    clearParentState: ownProps.clearParentState,
  };
};

export const ConnectedNewItemButton = connect(mapStateToProps)(NewItemButton);
