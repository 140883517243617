import React, { Component } from "react";
import * as moment from "moment";
import * as helper from "../HelperRepository";
import { withTranslation } from "react-i18next";

class ActionButtonsColumn_WithoutTranslation extends Component {
  render() {
    return (
      <td className="actions-cell two-md-bttns">
        <div className="button-container">
          <button
            type="button"
            className="btn btn-outline-success btn-sm ico-l"
            onClick={() => {
              let hs = this.props.hairstylists.filter(
                (hairstylist) =>
                  hairstylist.personId ===
                  this.props.dataItem.hairstylist.personId
              )[0];
              let shs = hs.salonHairstylists.filter(
                (salonHairstylist) =>
                  salonHairstylist.salonId ===
                  this.props.dataItem.salonCustomer.salon.salonId
              )[0];
              this.props.getAllHairstylistWorkingTimes(shs.salonHairstylistId);
              this.props.setVisitDate(
                moment(this.props.dataItem.startTime).format("YYYY-MM-DD")
              );
              this.props.setParentState(this.props.dataItem);

              this.props.setIsEdit(true);
              helper.showModal("#editVisitModal");
            }}
            disabled={
              this.props.dataItem.status.itemName === "Finished" ||
              this.props.dataItem.status.itemName === "Canceled"
            }
          >
            <i className="fal fa-edit"></i>{" "}
            {this.props.t("common.edit", "Edit")}
          </button>
          <button
            type="button"
            className="btn btn-outline-danger btn-sm ico-l"
            onClick={() => {
              this.props.setParentState(this.props.dataItem);
              this.props.setIsDelete(true);
              helper.showModal("#deleteVisitModal");
            }}
          >
            <i className="fal fa-trash-alt"></i>
            {this.props.t("common.deactivate", "Deactivate")}
          </button>
        </div>
      </td>
    );
  }
}

export const ActionButtonsColumn = withTranslation()(
  ActionButtonsColumn_WithoutTranslation
);
