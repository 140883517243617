import React, { Component } from "react";
import { connect } from "react-redux";
import * as mutations from "../../store/mutations";
import { withTranslation } from "react-i18next";

class SalonsDropDownComponentV2 extends Component {
  componentDidMount() {
    this.props.getAllSalons();
  }

  render() {
    if (this.props.salonId !== "") {
      const salon = this.props.salons.find(
        (salon) => salon.salonId === this.props.salonId
      );
      this.props.setServiceTypes(salon.serviceTypes);
      this.props.setSalonTimeSlot(salon.salonTimeSlot);
    } else {
      this.props.setServiceTypes([]);
      this.props.setSalonTimeSlot(30);
    }
    return (
      <div className="custom-select-box">
        <select
          className="form-control"
          value={this.props.salonId}
          onChange={(event) => {
            this.props.setSalonId(event.target.value);
          }}
          required
        >
          <option key={""} value={""}>
            {helper.i18nMultiFallbackKey(
              "dropdowns.salonsDropDownComponentV2.label",
              "Select shop"
            )}
          </option>
          {this.props.salons.map((r) => {
            return (
              <option key={r.salonId} value={r.salonId}>
                {r.name}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    salons: state.salons,
    salonId: ownProps.salonId,
    setSalonId: ownProps.setSalonId,
    setServiceTypes: ownProps.setServiceTypes,
    setSalonTimeSlot: ownProps.setSalonTimeSlot,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllSalons() {
    dispatch(mutations.getAllSalons());
  },
});

export const ConnectedSalonsDropDownV2 = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SalonsDropDownComponentV2)
);
