// import React, { useState, useEffect } from "react";


// const ImagesComponent = () => {


//   return (
//     <>
//       <main generalization="main">
//         <div className="container-fluid">
//           <p>Hello from Images</p>
//         </div>
//       </main>
//     </>
//   );
// };

// export default ImagesComponent;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ConnectedImagesList } from "./ImagesList";
import * as mutations from "../../store/mutations";
import { FormErrors } from "../FormErrors";
import * as moment from "moment";
import * as helper from "../HelperRepository";
import { useTranslation } from "react-i18next";
import { ConnectedCategoryItemsDropDown } from "../Dropdowns/CategoryItemsDropDown";
import RequiredFieldsComponent from "../RequiredFieldsComponent";

const ImagesComponent = ({
  activeUser,
  getActiveUser,
  createImages,
  saveImages,
  deleteImages,
}) => {
  const { t } = useTranslation();

  document.getElementsByTagName("html")[0].classList.remove("page-signin");

  const [createId, setCreateId] = useState("");

  const [editId, setEditId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [
    createImagesUrl,
    setCreateImagesUrl,
  ] = useState("");

  const [
    editImagesUrl,
    setEditImagesUrl,
  ] = useState("");

  const [
    deleteImagesUrl,
    setDeleteImagesUrl,
  ] = useState("");

  const [createName, setCreateName] = useState("");
  const [editName, setEditName] = useState("");
  const [deleteName, setDeleteName] = useState("");

  const [hasDependents, setHasDependents] = useState("");
  const [selectedImages, setSelectedImages] = useState({});

  const [formErrors, setFormErrors] = useState("");

  // const [
  //   imagesUrlValid,
  //   setImagesUrlValid
  // ] = useState(false);

  const [NameValid, setNameValid] = useState(false);

  const [formValid, setFormValid] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add("grid-view");
    if (!activeUser || !activeUser.personId) {
      getActiveUser();
    }
    return () => {
      document.getElementsByTagName("html")[0].classList.remove("grid-view");
    };
  }, []);

  // useEffect(() => {
  //   setFormValid(NameValid != null);
  // }, [NameValid]);

  const clearState = () => {
    setCreateId("");
    setCreateImagesUrl("");
    setEditId("");
    setEditImagesUrl("");
    setDeleteId("");
    setDeleteImagesUrl("");

    setCreateName("");
    setEditName("");
    setDeleteName("");

    setFormValid(false);
    // setImagesUrlValid(false);
    setNameValid(false);
    setFormErrors({
      createImagesUrl: "",
      editImagesUrl: "",
      createName: "",
      editName: "",
    });
  };

  const setImagesState = (images) => {
    setEditName(images.name);
    setEditImagesUrl(images.url);
    setEditId(images.id);

    setDeleteName(images.name);
    setDeleteImagesUrl(images.url);
    setDeleteId(images.id);

    setHasDependents(images.hasDependents);
    setFormValid(true);
    // setImagesUrlValid(true);
    setNameValid(true);
    setFormErrors({
      createName: "",
      editName: "",
      createImagesUrl: "",
      editImagesUrl: "",
    });
  };

  const clearComponentShow = () => {
    setIsCreate(false);
    setIsDelete(false);
    setIsEdit(false);
  };

  const handleUserInput = (e) => {
    switch (e.target.id) {
      // case "createImagesUrl":
      //   setCreateImagesUrl(e.target.value);
      //   validateField(e.target.id, e.target.value);
      //   break;

      // case "editImagesUrl":
      //   setEditImagesUrl(e.target.value);
      //   validateField(e.target.id, e.target.value);
      //   break;

      case "createName":
        setCreateName(e.target.value);
        validateField(e.target.id, e.target.value);

        break;
      case "editName":
        setEditName(e.target.value);
        validateField(e.target.id, e.target.value);

        break;
      default:
        break;
    }
  };

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    // let fieldUrlValid = imagesUrlValid;
    let fieldNameValid = NameValid;

    switch (fieldName) {
      // case "createImagesUrl":
      //   fieldUrlValid = value.match(/^(?!\s*$).+/i) && value.length > 5;
      //   fieldValidationErrors.createImagesUrl = fieldUrlValid
      //     ? ""
      //     : t("common.isInvalid", " is invalid");
      //   break;
      // case "editImagesUrl":
      //   fieldUrlValid = value.match(/^(?!\s*$).+/i) && value.length > 5;
      //   fieldValidationErrors.editImagesUrl = fieldUrlValid
      //     ? ""
      //     : t("common.isInvalid", " is invalid");
      //   break;

      case "createName":
      case "editName":
        let fieldNameValid = /^(?!\s*$).+/i;
        fieldNameValid =
          fieldNameValid.test(value) && value.length > 5;
        fieldValidationErrors.createName = fieldNameValid
          ? ""
          : t(
              "common.NameIsInvalid",
              " is invalid. It should begin with letter and have at least 6 characters"
            );
        break;
      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    // setImagesUrlValid(fieldUrlValid);
    setNameValid(fieldNameValid);
    setFormValid(fieldNameValid);
  };

  const errorClassInput = (error) => {
    if (error) {
      return error.length === 0 ? "" : "is-invalid";
    }
    return "";
  };

  const errorClassLabel = (error) => {
    if (error) {
      return error.length === 0 ? "" : "text-danger";
    }
    return "";
  };

  return (
    <>
      <main images="main">
        <div className="container-fluid">
          <ConnectedImagesList
            setParentState={setImagesState}
            clearParentState={clearState}
            setSelectedImages={setSelectedImages}
            selectedImages={selectedImages}
            setIsCreate={setIsCreate}
            setIsEdit={setIsEdit}
            setIsDelete={setIsDelete}
            editModal="#editImagesModal"
            deleteModal="#deleteImagesModal"
          />
        </div>
      </main>

      <footer className="footer">
        <div className="container copyright">
          <span className="text-muted">
            Hairstylist &copy; Copyright {moment().format("YYYY")},{" "}
            {t("common.version", "Version:")}
            {process.env.VERSION}
          </span>
        </div>
      </footer>

      {/* Create Images Modal */}
      {isCreate && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="createImagesModal"
          tabIndex="-1"
          images="dialog"
          aria-labelledby="createImagesModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            images="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="createImagesModalLabel">
                  <i className="fal fa-user-cog fa-lg c-purple" />{" "}
                  {t(
                    "images.imagesComponent.createImagesModal.title",
                    "Create Images"
                  )}{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <input type="hidden" value="something" />
                  <div className="form-group">
                    <label
                      htmlFor="createName"
                      className={`${errorClassLabel(
                        formErrors.createName
                      )} required`}
                    >
                      {t(
                        "images.imagesComponent.createImagesModal.createNameLabel",
                        "Images name"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.createName
                      )}`}
                      id="createName"
                      autoComplete="new-username"
                      placeholder={t(
                        "images.imagesComponent.createImagesModal.createNamePlaceholder",
                        "Please enter image name"
                      )}
                      value={createName}
                      onChange={handleUserInput}
                      required
                    />
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"createName"}
                        valueToShow={"Name"}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="createImagesUrl"
                      className={`${errorClassLabel(
                        formErrors.createImagesUrl
                      )}`}
                    >
                      {t(
                        "images.imagesComponent.createImagesModal.createUrlLabel",
                        "Url"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.createImagesUrl
                      )}`}
                      id="createImagesUrl"
                      autoComplete="new-username"
                      placeholder={t(
                        "images.imagesComponent.createImagesModal.createUrlPlaceholder",
                        "Please enter images url"
                      )}
                      value={createImagesUrl}
                      onChange={(event) => {
                        setCreateImagesUrl(event.target.value);
                      }}
                      required
                    />
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"createImagesUrl"}
                        valueToShow={"Images url"}
                      />
                    </div>
                  </div>
                  <RequiredFieldsComponent />
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => clearComponentShow()}
                >
                  <i className="fal fa-ban" /> {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                //   disabled={!formValid}
                  onClick={() =>
                    createImages(
                      createImagesUrl,
                      createName,
                      () => {
                        clearState();
                        clearComponentShow();
                      }
                    )
                  }
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Edit Images Modal */}
      {isEdit && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="editImagesModal"
          tabIndex="-1"
          images="dialog"
          aria-labelledby="editImagesModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            images="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="editImagesModalLabel">
                  <i className="fal fa-user-cog fa-lg c-purple" />{" "}
                  {t(
                    "images.imagesComponent.editImagesModal.title",
                    "Edit Images"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <form className="form needs-validation" noValidate>
                  <div className="form-group">
                    <label
                      htmlFor="editName"
                      className={`${errorClassLabel(
                        formErrors.editName
                      )} required`}
                    >
                      {t(
                        "images.imagesComponent.editImagesModal.editNameLabel",
                        "Name"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.editName
                      )}`}
                      id="editName"
                      autoComplete="new-username"
                      placeholder={t(
                        "images.imagesComponent.editImagesModal.editNamePlaceholder",
                        "Please enter name"
                      )}
                      value={editName}
                      onChange={handleUserInput}
                      required
                    />
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"editName"}
                        valueToShow={"Name"}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="editImagesUrl"
                      className={`${errorClassLabel(
                        formErrors.editImagesUrl
                      )}`}
                    >
                      {t(
                        "images.imagesComponent.editImagesModal.editImagesUrlLabel",
                        "Url"
                      )}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.editImagesUrl
                      )}`}
                      id="editImagesUrl"
                      placeholder={t(
                        "images.imagesComponent.editImagesModal.editImagesUrlPlaceholder",
                        "Images utl"
                      )}
                      value={editImagesUrl}
                      onChange={(event) => {
                        setEditImagesUrl(event.target.value);
                      }}
                      required
                    />
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"editImagesUrl"}
                        valueToShow={"Images url"}
                      />
                    </div>
                  </div>
                  <RequiredFieldsComponent />
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-primary ico-l"
                  data-dismiss="modal"
                  onClick={() => clearComponentShow()}
                >
                  <i className="fal fa-sync" /> {t("common.cancel", "Cancel")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  disabled={!formValid}
                  onClick={() =>
                    saveImages(
                      editId,
                      editImagesUrl,
                      editName,
                      () => {
                        clearState();
                        clearComponentShow();
                      }
                    )
                  }
                >
                  <i className="fal fa-check" /> {t("common.finish", "Finish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delete Images Modal */}
      {isDelete && (
        <div
          className="modal fade"
          data-backdrop="static"
          data-keyboard="false"
          id="deleteImagesModal"
          tabIndex="-1"
          images="dialog"
          aria-labelledby="deleteImagesModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            images="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="deleteImagesModalLabel">
                  <i className="fal fa-user-cog fa-lg c-purple" />{" "}
                  {t(
                    "images.imagesComponent.deleteImagesModal.title",
                    "Deactivate Images"
                  )}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="modal-body">
                <h2 className="info-title-upp-case-xl icon-custom-top-center">
                  <span className="icon-custom is-xl icon-triangle-red-white" />
                  {t(
                    "images.imagesComponent.deleteImagesModal.attention",
                    "Attention"
                  )}
                </h2>
                {!hasDependents && (
                  <div className="info-message text-center">
                    <p>
                      {" "}
                      {t(
                        "images.imagesComponent.deleteImagesModal.question",
                        "Are you sure you want to deactivate images"
                      )}
                    </p>
                    <p className="prime-text">
                      {deleteImagesUrl}?
                    </p>
                  </div>
                )}
                {hasDependents && (
                  <div className="info-message text-center">
                    <p>
                      {t(
                        "images.imagesComponent.deleteImagesModal.dependencies",
                        "Some images items have this images, it is not possible to deactivate."
                      )}
                    </p>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-success ico-l"
                  data-dismiss="modal"
                  onClick={() =>
                    deleteImages(deleteId, () => {
                      clearState();
                      clearComponentShow();
                    })
                  }
                  disabled={hasDependents}
                >
                  <i className="fal fa-check" /> {t("common.yes", "Yes")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger ico-l"
                  data-dismiss="modal"
                  onClick={() => clearComponentShow()}
                >
                  <i className="fal fa-times" /> {t("common.no", "No")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    activeUser: state.activeUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActiveUser() {
      if (sessionStorage.getItem("user_id")) {
        dispatch(
          mutations.getActiveUser(JSON.parse(sessionStorage.getItem("user_id")))
        );
      }
    },
    createImages(imagesUrl, name, cb) {
      let images = {
        Url: imagesUrl,
        Name: name,
      };
      cb();
      dispatch(mutations.createImages(images));
    },
    saveImages(
        Id,
        imagesUrl,
        name,
        cb
    ) {
      let images = {
        Id: Id,
        Url: imagesUrl,
        Name: name,
      };
      cb();
      dispatch(mutations.saveImages(images));
    },
    deleteImages(Id, cb) {
      let images = {
        Id: Id,
      };
      cb();
      dispatch(mutations.deleteImages(images));
    },
  };
};

export const ConnectedImages = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImagesComponent);

export default ConnectedImages;

