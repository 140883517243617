import React, { useState } from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import logo from "../style/images/Hairstylist-Logo-02-TxtDarkPink-BackOffice.svg";
import britainFlag from "../style/images/great-britain-flag-olpb1bjsje5zuvu8899hdclgfy7i530fd5zzsv5uhc.jpg";
import serbianFlag from "../style/images/serbia-flag-olpaubn1nsl5fe0538dgt45x8op4u78d2j5v6pjcsw.png";
import * as mutations from "../store/mutations";
import { FormErrors } from "./FormErrors";
import { useCookies } from "react-cookie";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as moment from "moment";
import { useTranslation } from "react-i18next";

const LoginComponent = ({ authenticateUser, sendMail, forgotPassword }) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  document.getElementsByTagName("html")[0].classList.add("page-signin");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [firstNameValid, setFirstNameValid] = useState(false);
  const [lastNameValid, setLastNameValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const [cookies, setCookie] = useCookies(["username"]);

  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [forgotPasswordEmailValid, setForgotPasswordEmailValid] = useState(
    false
  );
  const [forgotPasswordFormValid, setForgotPasswordFormValid] = useState(false);

  const clearRegisterForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setDescription("");
    setFormErrors({});
    setFirstNameValid(false);
    setLastNameValid(false);
    setEmailValid(false);
    setPhoneValid(false);
    setFormValid(false);
  };
  const clearForgotPasswordForm = () => {
    setForgotPasswordEmail("");
  };
  const errorClassLabel = (error) => {
    if (error) {
      return error.length === 0 ? "" : "text-danger";
    }
    return "";
  };
  const errorClassInput = (error) => {
    if (error) {
      return error.length === 0 ? "" : "is-invalid";
    }
    return "";
  };
  const handleUserInput = (e) => {
    switch (e.target.id) {
      case "firstName":
        setFirstName(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "lastName":
        setLastName(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "forgotPasswordEmail":
        setForgotPasswordEmail(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      case "phone":
        setPhone(e.target.value);
        validateField(e.target.id, e.target.value);
        break;
      default:
        break;
    }
  };
  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let fieldFirstNameValid = firstNameValid;
    let fieldLastNameValid = lastNameValid;
    let fieldEmailValid = emailValid;
    let fieldPhoneValid = phoneValid;

    let fieldForgotPasswordEmailValid = forgotPasswordEmail;

    switch (fieldName) {
      case "firstName":
        fieldFirstNameValid = value.match(
          /^([A-Za-z\u0110\u017D\u01C8\u01CB\u0106\u010C\u01C5\u0160\u0111\u017E\u01C9\u01CC\u0107\u010D\u01C6\u0161 ]+)$/i
        );
        setFirstNameValid(fieldFirstNameValid);
        fieldValidationErrors.firstName = fieldFirstNameValid
          ? ""
          : t("common.isInvalid", " is invalid");
        break;
      case "lastName":
        fieldLastNameValid = value.match(
          /^([A-Za-z\u0110\u017D\u01C8\u01CB\u0106\u010C\u01C5\u0160\u0111\u017E\u01C9\u01CC\u0107\u010D\u01C6\u0161 ]+)$/i
        );
        setLastNameValid(fieldLastNameValid);
        fieldValidationErrors.lastName = fieldLastNameValid
          ? ""
          : t("common.isInvalid", " is invalid");
        break;
      case "email":
        fieldEmailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        setEmailValid(fieldEmailValid);
        fieldValidationErrors.email = fieldEmailValid
          ? ""
          : t("common.isInvalid", " is invalid");
        break;
      case "forgotPasswordEmail":
        fieldForgotPasswordEmailValid = value.match(
          /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
        );
        setForgotPasswordEmailValid(fieldForgotPasswordEmailValid);
        fieldValidationErrors.forgotPasswordEmail = fieldForgotPasswordEmailValid
          ? ""
          : t("common.isInvalid", " is invalid");
        break;
      case "phone":
        fieldPhoneValid = value.match(/^((\+?)[0-9]{9,15})$/i);
        setPhoneValid(fieldPhoneValid);
        fieldValidationErrors.phone = fieldPhoneValid
          ? ""
          : t("common.isInvalid", " is invalid");
        break;
      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    setFormValid(firstNameValid && lastNameValid && emailValid && phoneValid);
    setForgotPasswordFormValid(forgotPasswordEmailValid);
  };
  return (
    <div className="container-fluid text-center">
      <ToastContainer />
      <form
        onSubmit={(e) => authenticateUser(e, rememberMe, setCookie)}
        className="form-signin needs-validation"
        style={{ maxWidth: 360 }}
        noValidate
      >
        <img className="logo-hairstylist" src={logo} alt="Hairstylist logo" />
        <h1>{t("login.logIn", "Log In")}</h1>
        <label htmlFor="inputEmail" className="sr-only">
          {t("login.emailAddress.label", "Email address")}
        </label>
        <div className="form-group no-label">
          <input
            type="email"
            name="email"
            id="inputEmail"
            className="form-control"
            placeholder={t("login.emailAddress.placeHolder", "Email address")}
            defaultValue={cookies.username}
            required
            autoFocus
          />
          <div className="invalid-feedback">
            {t("login.emailAddress.invalid", "Your username is required")}
          </div>
        </div>
        <div className="form-group no-label password-holder">
          <input
            type="password"
            name="password"
            id="inputPassword"
            className="form-control"
            placeholder={t("login.password.placeHolder", "Password")}
            required
          />
          <div className="invalid-feedback">
            {t("login.password.invalid", "Password is required")}
          </div>
        </div>
        <a
          href=""
          className="btn btn-blank btn-forgot ripple-gray"
          data-toggle="modal"
          data-target="#forgotPasswordModal"
        >
          {t("login.password.forgot", "Forgot password?")}
        </a>

        <div className="form-group">
          <label className="switch-holder">
            <span className="switch">
              <input
                name="remember"
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => {
                  setRememberMe(e.target.checked);
                }}
                value={t("login.rememberMe.label", "Remember Me")}
              />
              <span className="slider"></span>
            </span>
            <span className="switch-label">
              {t("login.rememberMe.switchLabel", "Remember Me")}
            </span>
          </label>
        </div>
        <button
          id="signInButton"
          className="btn btn-lg btn-purple btn-login"
          type="submit"
        >
          {t("login.signIn", "Sign in")}
        </button>
        <button
          className="btn btn-md btn-pink-outline btn-register"
          type="button"
          data-toggle="modal"
          data-target="#registerModal"
        >
          {t("login.register", "Register")}
        </button>
      </form>
      <div></div>
      <div className="container">
        <div className="row">
          <div className="col-xs-6 col-md-6">
            <a href="#">
              <img
                src={britainFlag}
                className="rounded mx-auto d-block"
                title="great britain flag"
                alt="great britain flag"
                onClick={() => changeLanguage("en-US")}
              ></img>
            </a>
          </div>
          <div className="col-xs-6 col-md-6">
            <a href="#">
              <img
                src={serbianFlag}
                className="rounded mx-auto d-block"
                title="serbian flag"
                alt="serbian flag"
                onClick={() => changeLanguage("sr-Latn")}
              ></img>
            </a>
          </div>
        </div>
      </div>
      {/* <button
        className="btn btn-purple-outline btn-help"
        type="button"
        data-toggle="modal"
        data-target="#helpModal"
      >
        {t("login.help", "Help")}
      </button> */}

      {/* btn-outline-primary */}
      <button
        className="btn btn-purple-outline btn-footer help ico-l"
        type="button"
        data-toggle="modal"
        data-target="#helpModal"
      >
        <i className="fas fa-question-circle"></i>
        {t("login.help", "Need Help?")}
      </button>
      {/* btn-outline-danger */}
      <button
        className="btn btn-purple-outline btn-footer video ico-l"
        type="button"
        data-toggle="modal"
        data-target="#promoVideoModal"
      >
        <i className="fab fa-youtube"></i>
        {t("login.promo", "Promo Video")}
      </button>

      {/* Help Modal */}
      <div
        className="modal fade"
        data-backdrop="static"
        data-keyboard="false"
        id="helpModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editUserModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editUserModalLabel">
                <i className="fal fa-question-circle fa-lg c-purple"></i>
                {t("login.helpModal.help", "Help")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fal fa-times"></i>
              </button>
            </div>

            <div className="modal-body">
              <div className="help-content">
                <h4>How to use Hairstylist App</h4>

                <div className="help-description">
                  <h5>Login</h5>
                  <p>
                    The login page allows a user to gain access to an
                    application by entering their username and password
                    (previous you should be registered with the system).
                  </p>
                </div>
                <div className="help-description">
                  <h5>Register</h5>
                  <p>
                    On the Register page, you can quickly and easily register in
                    to the system Registered users normally provide some sort of
                    credentials (such as a username or e-mail address, and a
                    password) to the system in order to prove their identity:
                    this is known as logging in. Registered users may be granted
                    privileges beyond those granted to unregistered users.
                  </p>
                </div>
                <div className="help-description">
                  <h5>Dashboard</h5>
                  <p>
                    See your most important reports at a glance. Dashboards are
                    a collection of widgets that give you an overview of the
                    reports and metrics you care about most. Dashboards let you
                    monitor many metrics at once, so you can quickly check the
                    health of your accounts or see correlations between
                    different reports.
                  </p>
                </div>
                <div className="help-description">
                  <h5>Users</h5>
                  <p>
                    Users pages shows a list of all users. Here you can add a
                    new User, edit previously entered information, or declare
                    User inactive.
                  </p>
                </div>
                <div className="help-description">
                  <h5>Customers</h5>
                  <p>
                    Customers pages shows a list of all customers. Here you can
                    add a new Customer, edit previously entered information, or
                    declare Customer inactive.
                  </p>
                </div>
                <div className="help-description">
                  <h5>Roles</h5>
                  <p>
                    Roles pages shows a list of all roles. Here you can add a
                    new Role, edit previously entered information, or declare
                    Role inactive.
                  </p>
                </div>
                <div className="help-description">
                  <h5>Salons</h5>
                  <p>
                    Salons pages shows a list of all salons. Here you can add a
                    new Salon, edit previously entered information, or declare
                    Salon inactive.
                  </p>
                </div>
                <div className="help-description">
                  <h5>Hairstylists</h5>
                  <p>
                    Hairstylists pages shows a list of all hairstylists. Here
                    you can add a new Hairstylist, edit previously entered
                    information, or declare Hairstylist inactive.
                  </p>
                </div>
                <div className="help-description">
                  <h5>Visits</h5>
                  <p>
                    Visits pages shows a list of all visits. Here you can add a
                    new Visits, edit previously entered information, or declare
                    Visit inactive.
                  </p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger ico-l"
                data-dismiss="modal"
              >
                <i className="fal fa-times"></i>
                {t("login.helpModal.close", "Close")}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Promo Video Modal */}
      <div
        className="modal fade"
        data-backdrop="static"
        data-keyboard="false"
        id="promoVideoModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="promoVideoModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="promoVideoModalLabel">
                <i className="fal fa-question-circle fa-lg c-purple"></i>{" "}
                Hairstylist Promo Video
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  var url = document
                    .getElementById("player")
                    .getAttribute("src");
                  document.getElementById("player").setAttribute("src", null);
                  document.getElementById("player").setAttribute("src", url);
                }}
              >
                <i className="fal fa-times"></i>
              </button>
            </div>

            <div className="modal-body">
              <div className="video-responsive-wrapper">
                <iframe
                  id="player"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/TMUgA2_pz84"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={true}
                ></iframe>
              </div>
            </div>
            <div className="modal-footer pt-0">
              <button
                type="button"
                className="btn btn-outline-danger ico-l"
                data-dismiss="modal"
                onClick={(e) => {
                  var url = document
                    .getElementById("player")
                    .getAttribute("src");
                  document.getElementById("player").setAttribute("src", null);
                  document.getElementById("player").setAttribute("src", url);
                }}
              >
                <i className="fal fa-times"></i> Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Register Modal */}
      <div
        className="modal fade"
        data-backdrop="static"
        data-keyboard="false"
        id="registerModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editUserModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editUserModalLabel">
                <i className="fal fa-question-circle fa-lg c-purple"></i>{" "}
                {t(
                  "login.registerModal.registration",
                  "HairStylist - Registration"
                )}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fal fa-times"></i>
              </button>
            </div>

            <div className="modal-body">
              <div className="help-content">
                <form className="form needs-validation" noValidate>
                  <input type="hidden" value="something" />
                  <div className="form-group">
                    <label
                      htmlFor="firstName"
                      className={`${errorClassLabel(formErrors.firstName)}`}
                    >
                      {t("login.registerModal.firstName.label", "Firstname")}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.firstName
                      )}`}
                      id="firstName"
                      placeholder=""
                      value={firstName}
                      onChange={handleUserInput}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                      required
                    ></input>
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"firstName"}
                        valueToShow={t(
                          "login.registerModal.firstName.formError",
                          "First name"
                        )}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="lastName"
                      className={`${errorClassLabel(formErrors.lastName)} `}
                    >
                      {t("login.registerModal.lastName.label", "Lastname")}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.lastName
                      )} `}
                      id="lastName"
                      placeholder=""
                      value={lastName}
                      onChange={handleUserInput}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                      required
                    ></input>
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"lastName"}
                        valueToShow={t(
                          "login.registerModal.lastName.formError",
                          "Last name"
                        )}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="email"
                      className={`${errorClassLabel(formErrors.email)} `}
                    >
                      {t("login.registerModal.email.label", "Email")}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.email
                      )} `}
                      id="email"
                      placeholder=""
                      value={email}
                      onChange={handleUserInput}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                      required
                    ></input>
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"email"}
                        valueToShow={t(
                          "login.registerModal.email.formError",
                          "E-Mail"
                        )}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="phone"
                      className={`${errorClassLabel(formErrors.phone)} `}
                    >
                      {t("login.registerModal.phone.label", "Phone")}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.phone
                      )} `}
                      id="phone"
                      placeholder=""
                      value={phone}
                      onChange={handleUserInput}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                      required
                    ></input>
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"phone"}
                        valueToShow={t(
                          "login.registerModal.phone.formError",
                          "Phone number"
                        )}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone">
                      {t(
                        "login.registerModal.description.label",
                        "Description"
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="description"
                      placeholder=""
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                    ></input>
                    <div className="invalid-feedback">
                      {t(
                        "login.registerModal.description.formError",
                        "Please enter valid description."
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger ico-l"
                data-dismiss="modal"
              >
                <i className="fal fa-ban"></i>
                {t("common.cancel", "Cancel")}
              </button>
              <button
                type="button"
                className="btn btn-outline-success ico-l"
                disabled={!formValid}
                data-dismiss="modal"
                onClick={() => {
                  clearRegisterForm();
                  sendMail(firstName, lastName, email, phone, description);
                }}
              >
                <i className="fal fa-check"></i> {t("common.finish", "Finish")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Forgot Password Modal */}
      <div
        className="modal fade"
        data-backdrop="static"
        data-keyboard="false"
        id="forgotPasswordModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="forgotPasswordLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="forgotPasswordLabel">
                <i className="fal fa-question-circle fa-lg c-purple"></i>{" "}
                {t(
                  "login.forgotPasswordModal.forgotPassword",
                  "HairStylist - Forgot Password"
                )}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fal fa-times"></i>
              </button>
            </div>

            <div className="modal-body">
              <div className="help-content">
                <form className="form needs-validation" noValidate>
                  <input type="hidden" value="something" />
                  <div className="form-group">
                    <label
                      htmlFor="forgotPasswordEmail"
                      className={`${errorClassLabel(
                        formErrors.forgotPasswordEmail
                      )} `}
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorClassInput(
                        formErrors.forgotPasswordEmail
                      )} `}
                      id="forgotPasswordEmail"
                      placeholder=""
                      value={forgotPasswordEmail}
                      onChange={handleUserInput}
                      onBlur={(e) => {
                        validateField(e.target.id, e.target.value);
                      }}
                      required
                    ></input>
                    <div className="panel panel-default">
                      <FormErrors
                        formErrors={formErrors}
                        fieldToShow={"email"}
                        valueToShow={"E-Mail"}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger ico-l"
                data-dismiss="modal"
              >
                <i className="fal fa-ban"></i> {t("common.cancel", "Cancel")}
              </button>
              <button
                type="button"
                className="btn btn-outline-success ico-l"
                disabled={!forgotPasswordFormValid}
                data-dismiss="modal"
                onClick={() => {
                  clearForgotPasswordForm();
                  forgotPassword(forgotPasswordEmail);
                }}
              >
                <i className="fal fa-check"></i> {t("common.finish", "Finish")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer footer-light">
        <div className="container-fluid copyright">
          <span className="text-muted clearfix">
            <span className="float-left">
              Hairstylist &copy;{" "}
              <span className="mobile-hide-550">Copyright</span>{" "}
              {moment().format("YYYY")}
            </span>
            <span className="float-right">
              {t("common.version", "Version:")} {process.env.VERSION}
            </span>
          </span>
        </div>
      </footer>
    </div>
  );
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  authenticateUser(e, rememberMe, setCookie) {
    e.preventDefault();

    let email = e.target[`email`].value;
    let password = e.target[`password`].value;
    dispatch(
      mutations.requestAuthenticateUser(email, password, () => {
        if (rememberMe) {
          setCookie("username", email, { path: "/" });
        }
      })
    );
  },
  sendMail(firstName, lastName, email, phone, description) {
    let emailInformation = {
      firstName,
      lastName,
      email,
      phone,
      description,
    };
    dispatch(mutations.sendEmail(emailInformation));
  },
  forgotPassword(email) {
    dispatch(mutations.forgotPassword(email));
  },
});

export const ConnectedLogin = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginComponent);
