import React, { Component } from "react";
import { connect } from "react-redux";
import * as moment from "moment";
import { withTranslation } from "react-i18next";

class SlotTimeComponent extends Component {
  render() {
    let slotTime = 30;
    let openHours = "";

    if (this.props.salonId !== "") {
      const salon = this.props.salons.find(
        (salon) => salon.salonId === this.props.salonId
      );
      slotTime = salon.salonTimeSlot;
      openHours = `${moment(salon.startTime).format("HH:mm")} - ${moment(
        salon.endTime
      ).format("HH:mm")}`;
    }
    return (
      <>
        <span>
          {helper.i18nMultiFallbackKey(
            "home.slotTimeComponent.shopTimeSlotLabel",
            "Shop time slot [min]:"
          )}{" "}
          {slotTime}
        </span>
        <br />
        <span>
          {" "}
          {helper.i18nMultiFallbackKey(
            "home.slotTimeComponent.shopOpenHoursLabel",
            "Shop open hours:"
          )}{" "}
          {openHours}
        </span>
        <br />
      </>
    );
  }
}

const mapStateToPropsTimeSlot = (state, ownProps) => {
  return {
    salons: state.salons,
    salonId: ownProps.salonId,
  };
};

const mapDispatchToPropsTimeSlot = (dispatch) => ({});

export const ConnectedSlotTimeComponent = withTranslation()(
  connect(
    mapStateToPropsTimeSlot,
    mapDispatchToPropsTimeSlot
  )(SlotTimeComponent)
);
