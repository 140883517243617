import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as moment from "moment";
import * as helper from "../HelperRepository";
import "moment/locale/sr.js";
import { useTranslation } from "react-i18next";

const CalendarDailyDayColumn = ({
  currentDate,
  dashboardDataDaily,
  setVisitState,
  setIsEdit,
  setIsCreate,
  setCreateVisitState,
  selectedSalon,
}) => {
  const { t, i18n } = useTranslation();

  const [dateToUse, setDateToUse] = useState();

  useEffect(() => {
    setDateToUse(currentDate);
  }, [dashboardDataDaily]);

  const getVisitServiceTypeName = (visit, dashboardDataDailyItem) => {
    let result = new Map();
    let listIndex = 0;
    visit.visitServiceTypes.forEach((element) => {
      try {
        if (element.serviceType.serviceTypeDuration > 1) {
          for (let i = 1; i <= element.serviceType.serviceTypeDuration; i++) {
            result.set(
              moment(visit.startTime)
                .add(listIndex * selectedSalon.salonTimeSlot, "minute")
                .format("HH:mm"),
              `${element.serviceType.serviceTypeName} , deo ${i}`
            );

            listIndex++;
          }
        } else {
          result.set(
            moment(visit.startTime)
              .add(listIndex * selectedSalon.salonTimeSlot, "minute")
              .format("HH:mm"),
            element.serviceType.serviceTypeName
          );
          listIndex++;
        }
      } catch (ex) {
        console.log(ex);
      }
    });

    listIndex = 0;
    return result.get(moment(dashboardDataDailyItem.startTime).format("HH:mm"));
  };

  let momentLocal = moment();
  momentLocal.locale("sr");

  return (
    <div className="col-day">
      <div className="row">
        <div className="col">
          <h6 className="day-title">
            <span>
              {`${helper.capitalize(
                momentLocal
                  .localeData()
                  .weekdays(moment(dateToUse, "DD.MM.YYYY."))
              )}`}
              ,{" "}
            </span>
            {`${dateToUse}`}
          </h6>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="list-group calendar-appointment">
            <div
              className={
                dashboardDataDaily && dashboardDataDaily.length
                  ? "max-h-300-v-scroll"
                  : ""
              }
            >
              {dashboardDataDaily &&
                dashboardDataDaily.map((item, indexDashboardDataDaily) => {
                  return (
                    <div
                      key={item.timeIntervalGuid}
                      className={`list-group-item list-group-item-action${
                        item.visits.length > 0 ? " has-visit" : ""
                      }`}
                    >
                      <h5 className="appointment-time d-block">
                        {moment(item.startTime).format("HH:mm")}
                      </h5>
                      {item.capacity - item.visits.length > 0 && (
                        <i
                          className="fal fa-plus ico-btn-add"
                          title={`Slobodna su jos ${
                            item.capacity - item.visits.length
                          } mesta`}
                          onClick={() => {
                            setCreateVisitState(
                              dateToUse,
                              item.startTime,
                              item.endTime
                            );
                            setIsCreate(true);
                            helper.showModal("#createVisitCalendarDailyModal");
                          }}
                        ></i>
                      )}

                      {item.visits.map((visit, index) => {
                        return (
                          <div
                            key={visit.visitId}
                            className="appointment-description ad-dayli"
                          >
                            <a
                              href="#"
                              onClick={() => {
                                if (
                                  visit.status.itemName !== "Finished" &&
                                  visit.status.itemName !== "Canceled"
                                ) {
                                  setVisitState(visit);
                                  setIsEdit(true);
                                  helper.showModal(
                                    "#editVisitCalendarDailyModal"
                                  );
                                }
                              }}
                            >
                              <span
                                className={`avatar ${visit.hairstylist.avatar}`}
                              >
                                {visit.hairstylist.firstName[0]}
                                {visit.hairstylist.lastName[0]}
                              </span>

                              <span className="worker-name">{`${visit.customerFullName}`}</span>
                              <span className="service-type">{`${getVisitServiceTypeName(
                                visit,
                                item
                              )}`}</span>
                              <span className="worker-name">
                                {t(
                                  "home.calendarDailyDayColumn.status",
                                  "Status:"
                                )}{" "}
                                {`${visit.status.itemName}`}
                              </span>
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    dashboardDataDaily: ownProps.dashboardDataDaily,
    currentDate: ownProps.currentDate,
    setVisitState: ownProps.setVisitState,
    setIsCreate: ownProps.setIsCreate,
    setIsEdit: ownProps.setIsEdit,
    setCreateVisitState: ownProps.setCreateVisitState,
    selectedSalon: ownProps.selectedSalon,
  };
};

const mapDispatchToProps = {};

export const ConnectedCalendarDailyDayColumn = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarDailyDayColumn);
