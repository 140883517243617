import { put, take, select } from "redux-saga/effects";
import axios from "axios";

import { history } from "./history";
import * as mutations from "./mutations";
import { toast } from "react-toastify";
import * as helper from "../components/HelperRepository";
import i18n from "../i18n";

const url = process.env.URL;
//const url = `https://localhost:5001/api`;
//const url = `http://ws.hairstylist.rs/api`;
//const url = `https://hsapidocker.azurewebsites.net/api`;
//const url = `http://hsapi.isolutions.local/api`;

const axiosInterceptors = () => {
  axios.interceptors.request.use(
    (config) => {
      // Do something before request is sent
      console.log(config);
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      console.log(response);
      if (response.config.parse) {
        //perform the manipulation here and change the response object
      }
      return response;
    },
    (error) => {
      return Promise.reject(error.message);
    }
  );
};

const toastSuccess = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};
const toastError = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

/* #region User sagas */
const TOKEN = "token";
const USER_ID = "user_id";
const PATH = "path";
const SHOP_TYPE = "shopType";
const SERVICE_PROVIDER = "serviceProvider";
const CLIENT_TYPE = "clientType";

export function* sendEmailSaga() {
  while (true) {
    const { emailInformation } = yield take(mutations.SEND_EMAIL);
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/login/sendEmail",
        data: emailInformation,
      });
      toastSuccess("Email sent");
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error sending email");
    }
  }
}

export function* forgotPasswordSaga() {
  while (true) {
    const { email } = yield take(mutations.FORGOT_PASSWORD);
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/login/forgotPassword",
        data: { Email: email },
      });
      toastSuccess("Email sent");
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error sending email");
    }
  }
}
export function* userAuthenticationSaga() {
  while (true) {
    const { email, password, cb } = yield take(
      mutations.REQUEST_AUTHENTICATE_USER
    );
    try {
      const { data } = yield axios.post(url + `/v1.0/login`, {
        Username: email,
        Password: password,
      });

      let state = { session: data.session, activeUser: data.activeUser };

      yield put(mutations.setState(state));
      yield put(
        mutations.processAuthenticateUser(mutations.AUTHENTICATED, {
          id: data.personId, // todo... get ID from response
          token: data.token,
        })
      );

      sessionStorage.setItem(
        SHOP_TYPE,
        JSON.stringify(helper.getSalonTypeName(data.token))
      );
      sessionStorage.setItem(
        SERVICE_PROVIDER,
        JSON.stringify(helper.getServiceProvireName(data.token))
      );
      sessionStorage.setItem(
        CLIENT_TYPE,
        JSON.stringify(helper.getClientTypeName(data.token))
      );

      sessionStorage.setItem(TOKEN, JSON.stringify(data.token));
      sessionStorage.setItem(USER_ID, JSON.stringify(data.personId));
      sessionStorage.setItem(PATH, JSON.stringify("home"));

      i18n.changeLanguage(data.activeUser.locale.itemName, (err, t) => {
        if (err) return console.log("something went wrong loading", err);
      });

      cb();
      history.push(`/`);
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error logging in");
      yield put(mutations.processAuthenticateUser(mutations.NOT_AUTHENTICATED));
    }
  }
}

export function* renewTokenSaga() {
  while (true) {
    yield take(mutations.REQUEST_RENEW_TOKEN);
    try {
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/renewToken",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      yield put(
        mutations.processAuthenticateUser(mutations.AUTHENTICATED, {
          id: data.personId, // todo... get ID from response
          token: data.token,
        })
      );

      sessionStorage.setItem(TOKEN, JSON.stringify(data.token));
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error renew token");
    }
  }
}

export function* getUsersPagedSaga() {
  while (true) {
    const { searchValue, page, sort } = yield take(mutations.GET_USERS_PAGED);
    yield put(mutations.setLoaded(false));
    try {
      let filters = [
        { Property: "Username", Value: searchValue, Type: "string" },
        { Property: "Person.FirstName", Value: searchValue, Type: "string" },
        { Property: "Person.LastName", Value: searchValue, Type: "string" },
        { Property: "Person.Email", Value: searchValue, Type: "string" },
        { Property: "Person.Phone", Value: searchValue, Type: "string" },
        {
          Property: "UserRoles.FirstOrDefault().Role.Name",
          Value: searchValue,
          Type: "string",
        },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/users/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setUsers(data));
      yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting users");
      yield put(mutations.setLoaded(true));
    }
  }
}
export function* getUsersForExportSaga() {
  while (true) {
    const { searchValue, totalCount, sort, cb } = yield take(
      mutations.GET_USERS_FOR_EXPORT
    );
    try {
      let filters = [
        { Property: "Username", Value: searchValue, Type: "string" },
        { Property: "Person.FirstName", Value: searchValue, Type: "string" },
        { Property: "Person.LastName", Value: searchValue, Type: "string" },
        // { Property: "Person.Email", Value: searchValue, Type: "string" },
        // { Property: "Person.Phone", Value: searchValue, Type: "string" },
        // { Property: "UserRoles[0].Role.Name",Value: searchValue,Type: "string"},
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/users/paged",
        data: {
          PageNumber: 1,
          PageSize: totalCount,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      cb(data.resultList);
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting users");
    }
  }
}

export function* getActiveUserSaga() {
  while (true) {
    const { userId } = yield take(mutations.GET_ACTIVE_USER);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + `/v1.0/users/${userId}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setActiveUser(data));
      yield put(
        mutations.processAuthenticateUser(mutations.AUTHENTICATED, {
          id: data.personId, // todo... get ID from response
          token: JSON.parse(sessionStorage.getItem("token")),
        })
      );
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      yield put(mutations.processAuthenticateUser(mutations.NOT_AUTHENTICATED));
      toastError("Error getting active user");
    }
  }
}

export function* saveUserSaga() {
  while (true) {
    const { user } = yield take(mutations.SAVE_USER);
    try {
      yield axios({
        method: "put",
        url: url + `/v1.0/users`,
        data: { ...user },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      // yield axios({
      //   method: "post",
      //   url: url + `/v1.0/users/update`,
      //   data: { ...user },
      //   headers: {
      //     Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))
      //   }
      // });

      toastSuccess("User is saved");

      yield put(mutations.getUsersPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("User is not saved");
    }
  }
}

export function* updateUserPasswordSaga() {
  while (true) {
    const { user, cb } = yield take(mutations.UPDATE_USER_PASSWORD);
    try {
      yield axios({
        method: "post",
        url: url + `/v1.0/users/updatePassword`,
        data: { ...user },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      toastSuccess("User password updated");

      yield put(mutations.getUsersPaged("", 1, []));

      cb();
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("User password is not updated");
    }
  }
}

export function* updateUsersOwnPasswordSaga() {
  while (true) {
    const { user, cb } = yield take(mutations.UPDATE_USERS_OWN_PASSWORD);
    try {
      yield axios({
        method: "post",
        url: url + `/v1.0/users/updatePassword`,
        data: { ...user },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      toastSuccess("User's own password updated");
      cb();
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("User's own password is not updated");
    }
  }
}

export function* updateUserLanguageSaga() {
  while (true) {
    const { user, cb } = yield take(mutations.UPDATE_USER_LANGUAGE);
    try {
      yield axios({
        method: "post",
        url: url + `/v1.0/users/updateLanguage`,
        data: { ...user },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      toastSuccess("User's language updated");
      cb();
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("User's language is not updated");
    }
  }
}

export function* createUserSaga() {
  while (true) {
    const { user } = yield take(mutations.CREATE_USER);
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/users",
        data: { ...user },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("User is created");

      yield put(mutations.getUsersPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("User is not created");
    }
  }
}

export function* deleteUserSaga() {
  while (true) {
    const { user } = yield take(mutations.DELETE_USER);
    try {
      yield axios({
        method: "delete",
        url: url + `/v1.0/users/${user.PersonId}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("User is deactivated");
      yield put(mutations.getUsersPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("User is not deactivated");
    }
  }
}

/* #endregion */

/* #region Role sagas */

export function* getRolesPagedSaga() {
  while (true) {
    const { searchValue, page, sort } = yield take(mutations.GET_ROLES_PAGED);
    yield put(mutations.setLoaded(false));

    try {
      let filters = [
        { Property: "Name", Value: searchValue, Type: "string" },
        { Property: "Description", Value: searchValue, Type: "string" },
        { Property: "Rank.ItemName", Value: searchValue, Type: "string" },
      ];

      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/roles/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setRoles(data));
      yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting roles");
      yield put(mutations.setLoaded(true));
    }
  }
}

export function* getRolesForExportSaga() {
  while (true) {
    const { searchValue, totalCount, sort, cb } = yield take(
      mutations.GET_ROLES_FOR_EXPORT
    );
    try {
      let filters = [
        { Property: "Name", Value: searchValue, Type: "string" },
        { Property: "Description", Value: searchValue, Type: "string" },
        { Property: "Rank.ItemName", Value: searchValue, Type: "string" },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/roles/paged",
        data: {
          PageNumber: 1,
          PageSize: totalCount,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      cb(data.resultList);
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting roles");
    }
  }
}

export function* getAllRolesSaga() {
  while (true) {
    yield take(mutations.GET_ALL_ROLES);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/roles",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllRoles(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting roles");
    }
  }
}

export function* saveRoleSaga() {
  while (true) {
    const { role } = yield take(mutations.SAVE_ROLE);
    try {
      yield axios({
        method: "put",
        url: url + `/v1.0/roles`,
        data: { ...role },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Role is saved");

      yield put(mutations.getRolesPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Role is not saved");
    }
  }
}

export function* createRoleSaga() {
  while (true) {
    const { role } = yield take(mutations.CREATE_ROLE);
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/roles",
        data: { ...role },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Role is created");

      yield put(mutations.getRolesPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Role is not created");
    }
  }
}

export function* deleteRoleSaga() {
  while (true) {
    const { role } = yield take(mutations.DELETE_ROLE);
    try {
      yield axios({
        method: "delete",
        url: url + `/v1.0/roles/${role.RoleId}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Role is deactivated");
      yield put(mutations.getRolesPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Role is not deactivated");
    }
  }
}
/* #endregion */

/* #region Customer sagas */
export function* getCustomersForAutocompleteSaga() {
  while (true) {
    const { searchValue } = yield take(
      mutations.GET_CUSTOMERS_FOR_AUTOCOMPLETE
    );

    try {
      let filters = [];
      let endpoint = "";
      if (searchValue.includes(" ")) {
        let res = searchValue.split(" ");
        filters = [
          { Property: "Person.FirstName", Value: res[0], Type: "string" },
          { Property: "Person.LastName", Value: res[1], Type: "string" },
        ];
        endpoint = "forAutocomplete";
      } else {
        filters = [
          { Property: "Person.FirstName", Value: searchValue, Type: "string" },
          { Property: "Person.LastName", Value: searchValue, Type: "string" },
        ];
        endpoint = "paged";
      }

      const { data } = yield axios({
        method: "post",
        url: url + `/v1.0/customers/${endpoint}`,
        data: {
          PageNumber: null,
          PageSize: 10,
          Sort: [],
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      yield put(mutations.setCustomers(data));
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting customers for autocomplete");
    }
  }
}

export function* getCustomersPagedSaga() {
  while (true) {
    const { searchValue, page, sort, cb } = yield take(
      mutations.GET_CUSTOMERS_PAGED
    );
    yield put(mutations.setLoaded(false));

    try {
      let filters = [
        { Property: "Person.FirstName", Value: searchValue, Type: "string" },
        { Property: "Person.LastName", Value: searchValue, Type: "string" },
        { Property: "Person.Email", Value: searchValue, Type: "string" },
        { Property: "Person.Phone", Value: searchValue, Type: "string" },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/customers/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      yield put(mutations.setCustomers(data));
      yield put(mutations.setLoaded(true));
      cb();
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting customers");
      yield put(mutations.setLoaded(true));
    }
  }
}

// export function* getCustomersPageForSalondSaga() {
//   while (true) {
//     const { searchValue, salonId, page, sort, cb } = yield take(
//       mutations.GET_CUSTOMERS_PAGED_FOR_SALON
//     );
//     yield put(mutations.setLoaded(false));

//     try {
//       let filters = [
//         { Property: "Person.FirstName", Value: searchValue, Type: "string" },
//         { Property: "Person.LastName", Value: searchValue, Type: "string" },
//         { Property: "Person.Email", Value: searchValue, Type: "string" },
//         { Property: "Person.Phone", Value: searchValue, Type: "string" },
//         { Property: "SalonId", Value: salonId, Type: "guid" },
//         // { Property: "Role.Name", Value: searchValue, Type: "string" }
//       ];
//       const x = sort.map((x) => {
//         return {
//           Property: x.field[0].toUpperCase() + x.field.slice(1),
//           Direction: x.dir.toUpperCase(),
//         };
//       });

//       const { data } = yield axios({
//         method: "post",
//         url: url + "/v1.0/customers/pagedForSalon",
//         data: {
//           PageNumber: page,
//           PageSize: 10,
//           Sort: x,
//           Filters: filters,
//         },
//         headers: {
//           Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
//         },
//       });

//       yield put(mutations.setCustomers(data));
//       yield put(mutations.setLoaded(true));
//       cb();
//     } catch (e) {
//       /* catch block handles failed login */
//       console.log(e);
//       toastError("Error getting customers");
//       yield put(mutations.setLoaded(true));
//     }
//   }
// }

export function* getCustomersForExportSaga() {
  while (true) {
    const { searchValue, totalCount, sort, cb } = yield take(
      mutations.GET_CUSTOMERS_FOR_EXPORT
    );
    try {
      let filters = [
        { Property: "Person.FirstName", Value: searchValue, Type: "string" },
        { Property: "Person.LastName", Value: searchValue, Type: "string" },
        // { Property: "Person.Email", Value: searchValue, Type: "string" },
        // { Property: "Person.Phone", Value: searchValue, Type: "string" },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/customers/paged",
        data: {
          PageNumber: 1,
          PageSize: totalCount,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      cb(data.resultList);
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting customers");
    }
  }
}

export function* getAllCustomersSaga() {
  while (true) {
    yield take(mutations.GET_ALL_CUSTOMERS);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/customers",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllCustomers(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting customers");
    }
  }
}

export function* saveCustomerSaga() {
  while (true) {
    const { customer } = yield take(mutations.SAVE_CUSTOMER);
    try {
      yield axios({
        method: "put",
        url: url + "/v1.0/customers",
        data: { ...customer },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Customer is saved");

      yield put(mutations.getCustomersPaged("", 1, [], () => {}));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Customer is not saved");
    }
  }
}

export function* createCustomerSaga() {
  while (true) {
    const { customer, isAllCustomers, cb } = yield take(
      mutations.CREATE_CUSTOMER
    );
    try {
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/customers",
        data: { ...customer },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Customer is created");

      // if (isAllCustomers) {
      //   yield put(
      //     mutations.getCustomersPaged("", null, [], () => cb(data.result))
      //   );
      // } else {
      //   yield put(
      //     mutations.getCustomersPaged("", 1, [], () => cb(data.result))
      //   );
      // }
      cb(data.result, isAllCustomers);
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Customer is not created");
    }
  }
}

export function* deleteCustomerSaga() {
  while (true) {
    const { customer } = yield take(mutations.DELETE_CUSTOMER);
    try {
      yield axios({
        method: "delete",
        url: url + `/v1.0/customers/${customer.PersonId}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      toastSuccess("Customer is deactivated");
      yield put(mutations.getCustomersPaged("", 1, [], () => {}));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Customer is not deactivated");
    }
  }
}

/* #endregion */

/* #region CategoryItem sagas */

export function* getCategoryItemsPagedSaga() {
  while (true) {
    const { searchValue, page, sort } = yield take(
      mutations.GET_CATEGORY_ITEMS_PAGED
    );
    // yield put(mutations.setLoaded(false));

    try {
      let filters = [
        { Property: "ItemName", Value: searchValue, Type: "string" },
        { Property: "ItemDescription", Value: searchValue, Type: "string" },
        { Property: "Category.Name", Value: searchValue, Type: "string" },
      ];

      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/categoryItems/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setCategoryItems(data));
      // yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting category items");
    }
  }
}

export function* getCategoryItemsPagedForCategorySaga() {
  while (true) {
    const { filterObject, page, sort } = yield take(
      mutations.GET_CATEGORY_ITEMS_PAGED_FOR_CATEGORY
    );
    // yield put(mutations.setLoaded(false));

    try {
      let filters = [{ ...filterObject }];

      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/categoryItems/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setCategoryItems(data));
      // yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting category items");
    }
  }
}

export function* getAllCategoryItemsForCategorySaga() {
  while (true) {
    const { filterObject } = yield take(
      mutations.GET_ALL_CATEGORY_ITEMS_FOR_CATEGORY
    );
    // yield put(mutations.setLoaded(false));

    try {
      let filters = [{ ...filterObject }];

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/categoryItems/paged",
        data: {
          PageNumber: null,
          PageSize: null,
          Sort: [],
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setCategoryItems(data));
      // yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting category items");
    }
  }
}

export function* getCategoryItemsForExportSaga() {
  while (true) {
    const { searchValue, totalCount, sort, cb } = yield take(
      mutations.GET_CATEGORY_ITEMS_FOR_EXPORT
    );
    try {
      let filters = [
        { Property: "ItemName", Value: searchValue, Type: "string" },
        { Property: "ItemDescription", Value: searchValue, Type: "string" },
        { Property: "Category.Name", Value: searchValue, Type: "string" },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/categoryItems/paged",
        data: {
          PageNumber: 1,
          PageSize: totalCount,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      cb(data.resultList);
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting category items");
    }
  }
}

export function* getAllCategoryItemsSaga() {
  while (true) {
    yield take(mutations.GET_ALL_CATEGORY_ITEMS);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/categoryItems",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      yield put(mutations.setAllCategoryItems(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting category items");
    }
  }
}

export function* saveCategoryItemSaga() {
  while (true) {
    const { categoryItem } = yield take(mutations.SAVE_CATEGORY_ITEM);
    try {
      yield axios({
        method: "put",
        url: url + "/v1.0/categoryItems",
        data: { ...categoryItem },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Category item is saved");

      yield put(
        mutations.getCategoryItemsPagedForCategory(
          {
            Property: "CategoryId",
            Value: categoryItem.CategoryId,
            Type: "guid",
          },
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Category item is not saved");
    }
  }
}

export function* createCategoryItemSaga() {
  while (true) {
    const { categoryItem } = yield take(mutations.CREATE_CATEGORY_ITEM);
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/categoryItems",
        data: { ...categoryItem },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Category item is created");

      yield put(
        mutations.getCategoryItemsPagedForCategory(
          {
            Property: "CategoryId",
            Value: categoryItem.CategoryId,
            Type: "guid",
          },
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Category item is not created");
    }
  }
}

export function* deleteCategoryItemSaga() {
  while (true) {
    const { categoryItem } = yield take(mutations.DELETE_CATEGORY_ITEM);
    try {
      yield axios({
        method: "delete",
        url: url + `/v1.0/categoryItems/${categoryItem.CategoryItemId}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Category item is deleted");
      yield put(
        mutations.getCategoryItemsPagedForCategory(
          {
            Property: "CategoryId",
            Value: categoryItem.CategoryId,
            Type: "guid",
          },
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Category item is not deleted");
    }
  }
}

export function* activateCategoryItemSaga() {
  while (true) {
    const { categoryItem } = yield take(mutations.ACTIVATE_CATEGORY_ITEM);
    try {
      const { data } = yield axios({
        method: "patch",
        url: url + `/v1.0/categoryItems/${categoryItem.CategoryItemId}`,
        data: { ...categoryItem },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      if (data.isSucceeded) {
        toastSuccess("CategoryItem is activated");
      } else {
        toastError("CategoryItem is not activated");
      }

      yield put(
        mutations.getCategoryItemsPagedForCategory(
          {
            Property: "CategoryId",
            Value: categoryItem.CategoryId,
            Type: "guid",
          },
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("CategoryItem is not activated");
    }
  }
}
/* #endregion */

/* #region Category sagas */

export function* getCategoriesPagedSaga() {
  while (true) {
    const { searchValue, page, sort } = yield take(
      mutations.GET_CATEGORIES_PAGED
    );
    // yield put(mutations.setLoaded(false));

    try {
      let filters = [{ Property: "Name", Value: searchValue, Type: "string" }];

      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/categories/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      yield put(mutations.setCategories(data));
      // yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting categories");
    }
  }
}

export function* getCategoriesForExportSaga() {
  while (true) {
    const { searchValue, totalCount, sort, cb } = yield take(
      mutations.GET_CATEGORIES_FOR_EXPORT
    );
    try {
      let filters = [{ Property: "Name", Value: searchValue, Type: "string" }];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/categories/paged",
        data: {
          PageNumber: 1,
          PageSize: totalCount,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      cb(data.resultList);
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting categories");
    }
  }
}

export function* getAllCategoriesSaga() {
  while (true) {
    yield take(mutations.GET_ALL_CATEGORIES);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/categories",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      yield put(mutations.setAllCategories(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting categories");
    }
  }
}

export function* saveCategorySaga() {
  while (true) {
    const { category } = yield take(mutations.SAVE_CATEGORY);
    try {
      yield axios({
        method: "put",
        url: url + "/v1.0/categories",
        data: { ...category },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Category is saved");

      yield put(mutations.getCategoriesPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Category is not saved");
    }
  }
}

export function* createCategorySaga() {
  while (true) {
    const { category } = yield take(mutations.CREATE_CATEGORY);
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/categories",
        data: { ...category },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Category is created");

      yield put(mutations.getCategoriesPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Category is not created");
    }
  }
}

export function* deleteCategorySaga() {
  while (true) {
    const { category } = yield take(mutations.DELETE_CATEGORY);
    try {
      yield axios({
        method: "delete",
        url: url + `/v1.0/categories/${category.CategoryId}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Category is deleted");
      yield put(mutations.getCategoriesPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Category is not deleted");
    }
  }
}
/* #endregion */

/* #region Generalization sagas */

export function* getGeneralizationsPagedSaga() {
  while (true) {
    const { searchValue, page, sort } = yield take(
      mutations.GET_GENERALIZATIONS_PAGED
    );
    // yield put(mutations.setLoaded(false));

    try {
      let filters = [
        { Property: "ShopTypeName", Value: searchValue, Type: "string" },
        { Property: "ServiceProviderName", Value: searchValue, Type: "string" },
        { Property: "ClienttypeName", Value: searchValue, Type: "string" },
      ];

      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/generalizations/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      yield put(mutations.setGeneralizations(data));
      // yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting generalizations");
    }
  }
}

export function* getGeneralizationsForExportSaga() {
  while (true) {
    const { searchValue, totalCount, sort, cb } = yield take(
      mutations.GET_GENERALIZATIONS_FOR_EXPORT
    );
    try {
      let filters = [
        { Property: "ShopTypeName", Value: searchValue, Type: "string" },
        { Property: "ServiceProviderName", Value: searchValue, Type: "string" },
        { Property: "ClienttypeName", Value: searchValue, Type: "string" },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/generalizations/paged",
        data: {
          PageNumber: 1,
          PageSize: totalCount,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      cb(data.resultList);
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting generalizations");
    }
  }
}

export function* getAllGeneralizationsSaga() {
  while (true) {
    yield take(mutations.GET_ALL_GENERALIZATIONS);

    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/generalizations",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      console.log(
        "--------------------------- : function*getAllGeneralizationsSaga -> data",
        data
      );

      yield put(mutations.setAllGeneralizations(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting generalizations");
    }
  }
}

export function* saveGeneralizationSaga() {
  while (true) {
    const { generalization } = yield take(mutations.SAVE_GENERALIZATION);
    try {
      yield axios({
        method: "put",
        url: url + "/v1.0/generalizations",
        data: { ...generalization },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Generalization is saved");

      yield put(mutations.getGeneralizationsPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Generalization is not saved");
    }
  }
}

export function* createGeneralizationSaga() {
  while (true) {
    const { generalization } = yield take(mutations.CREATE_GENERALIZATION);
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/generalizations",
        data: { ...generalization },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Generalization is created");

      yield put(mutations.getGeneralizationsPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Generalization is not created");
    }
  }
}

export function* deleteGeneralizationSaga() {
  while (true) {
    const { generalization } = yield take(mutations.DELETE_GENERALIZATION);
    try {
      yield axios({
        method: "delete",
        url: url + `/v1.0/generalizations/${generalization.GeneralizationId}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Generalization is deleted");
      yield put(mutations.getGeneralizationsPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Generalization is not deleted");
    }
  }
}
/* #endregion */

/* #region Generalization Translation sagas */

export function* getGeneralizationTranslationsPagedSaga() {
  while (true) {
    const { searchValue, page, sort } = yield take(
      mutations.GET_GENERALIZATION_TRANSLATIONS_PAGED
    );
    // yield put(mutations.setLoaded(false));

    try {
      let filters = [
        { Property: "ShopTypeName", Value: searchValue, Type: "string" },
        { Property: "ServiceProviderName", Value: searchValue, Type: "string" },
        { Property: "ClienttypeName", Value: searchValue, Type: "string" },
      ];

      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/generalizationTranslations/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      yield put(mutations.setGeneralizationTranslations(data));
      // yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting generalization Translations");
    }
  }
}

export function* getGeneralizationTranslationsPagedForGeneralizationSaga() {
  while (true) {
    const { filterObject, page, sort } = yield take(
      mutations.GET_GENERALIZATION_TRANSLATIONS_PAGED_FOR_GENERALIZATION
    );
    // yield put(mutations.setLoaded(false));

    try {
      let filters = [{ ...filterObject }];

      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/generalizationTranslations/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      console.log(
        "--------------------------- : function*getGeneralizationTranslationsPagedForGeneralizationSaga -> data",
        data
      );
      yield put(mutations.setGeneralizationTranslations(data));
      // yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting generalization Translations");
    }
  }
}

export function* getAllGeneralizationTranslationsForGeneralizationSaga() {
  while (true) {
    const { filterObject } = yield take(
      mutations.GET_ALL_GENERALIZATION_TRANSLATIONS_FOR_GENERALIZATION
    );
    // yield put(mutations.setLoaded(false));

    try {
      let filters = [{ ...filterObject }];

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/generalizationTranslations/paged",
        data: {
          PageNumber: null,
          PageSize: null,
          Sort: [],
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setGeneralizationTranslations(data));
      // yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting generalization Translations");
    }
  }
}

export function* getGeneralizationTranslationsForExportSaga() {
  while (true) {
    const { searchValue, totalCount, sort, cb } = yield take(
      mutations.GET_GENERALIZATION_TRANSLATIONS_FOR_EXPORT
    );
    try {
      let filters = [
        { Property: "ShopTypeName", Value: searchValue, Type: "string" },
        { Property: "ServiceProviderName", Value: searchValue, Type: "string" },
        { Property: "ClienttypeName", Value: searchValue, Type: "string" },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/generalizationTranslations/paged",
        data: {
          PageNumber: 1,
          PageSize: totalCount,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      cb(data.resultList);
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting generalization Translations");
    }
  }
}

export function* getAllGeneralizationTranslationsSaga() {
  while (true) {
    yield take(mutations.GET_ALL_GENERALIZATION_TRANSLATIONS);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/generalizationTranslations",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      yield put(mutations.setAllGeneralizationTranslations(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting generalization Translations");
    }
  }
}

export function* saveGeneralizationTranslationSaga() {
  while (true) {
    const { generalizationTranslation } = yield take(
      mutations.SAVE_GENERALIZATION_TRANSLATION
    );
    try {
      yield axios({
        method: "put",
        url: url + "/v1.0/generalizationTranslations",
        data: { ...generalizationTranslation },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Generalization Translation is saved");

      yield put(
        mutations.getGeneralizationTranslationsPagedForGeneralization(
          {
            Property: "GeneralizationId",
            Value: generalizationTranslation.GeneralizationId,
            Type: "guid",
          },
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Generalization Translation is not saved");
    }
  }
}

export function* createGeneralizationTranslationSaga() {
  while (true) {
    const { generalizationTranslation } = yield take(
      mutations.CREATE_GENERALIZATION_TRANSLATION
    );
    console.log(
      "--------------------------- : function*createGeneralizationTranslationSaga -> generalizationTranslation",
      generalizationTranslation
    );

    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/generalizationTranslations",
        data: { ...generalizationTranslation },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Generalization Translation is created");

      yield put(
        mutations.getGeneralizationTranslationsPagedForGeneralization(
          {
            Property: "GeneralizationId",
            Value: generalizationTranslation.GeneralizationId,
            Type: "guid",
          },
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Generalization Translation is not created");
    }
  }
}

export function* deleteGeneralizationTranslationSaga() {
  while (true) {
    const { generalizationTranslation } = yield take(
      mutations.DELETE_GENERALIZATION_TRANSLATION
    );
    try {
      yield axios({
        method: "delete",
        url:
          url +
          `/v1.0/generalizationTranslations/${generalizationTranslation.GeneralizationTranslationId}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Generalization Translation is deleted");
      yield put(
        mutations.getGeneralizationTranslationsPagedForGeneralization(
          {
            Property: "GeneralizationId",
            Value: generalizationTranslation.GeneralizationId,
            Type: "guid",
          },
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Generalization Translation is not deleted");
    }
  }
}
/* #endregion */

/* #region Images sagas */

export function* getImagesPagedSaga() {
  while (true) {
    const { searchValue, page, sort } = yield take(
      mutations.GET_IMAGES_PAGED
    );
    // yield put(mutations.setLoaded(false));

    try {
      let filters = [
        { Property: "ShopTypeName", Value: searchValue, Type: "string" },
        { Property: "ServiceProviderName", Value: searchValue, Type: "string" },
        { Property: "ClienttypeName", Value: searchValue, Type: "string" },
      ];

      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/images/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      yield put(mutations.setImages(data));
      // yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting images");
    }
  }
}

export function* getImagesForExportSaga() {
  while (true) {
    const { searchValue, totalCount, sort, cb } = yield take(
      mutations.GET_IMAGES_FOR_EXPORT
    );
    try {
      let filters = [
        { Property: "Name", Value: searchValue, Type: "string" },
        { Property: "ServiceProviderName", Value: searchValue, Type: "string" },
        { Property: "ClienttypeName", Value: searchValue, Type: "string" },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/images/paged",
        data: {
          PageNumber: 1,
          PageSize: totalCount,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      cb(data.resultList);
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting images");
    }
  }
}

export function* getAllImagesSaga() {
  while (true) {
    yield take(mutations.GET_ALL_IMAGES);

    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/images",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      console.log(
        "--------------------------- : function*getAllImagesSaga -> data",
        data
      );

      yield put(mutations.setAllImages(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting images");
    }
  }
}

export function* saveImagesSaga() {
  while (true) {
    const { images } = yield take(mutations.SAVE_IMAGES);
    try {
      yield axios({
        method: "put",
        url: url + "/v1.0/images",
        data: { ...images },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Images is saved");

      yield put(mutations.getImagesPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Images is not saved");
    }
  }
}

export function* createImagesSaga() {
  while (true) {
    const { images } = yield take(mutations.CREATE_IMAGES);
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/images",
        data: { ...images },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Images is created");

      yield put(mutations.getImagesPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Images is not created");
    }
  }
}

export function* deleteImagesSaga() {
  while (true) {
    const { images } = yield take(mutations.DELETE_IMAGES);
    try {
      yield axios({
        method: "delete",
        url: url + `/v1.0/images/${images.Id}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Images is deleted");
      yield put(mutations.getImagesPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Images is not deleted");
    }
  }
}
/* #endregion */

/* #region Images Translation sagas */

export function* getImagesTranslationsPagedSaga() {
  while (true) {
    const { searchValue, page, sort } = yield take(
      mutations.GET_IMAGES_TRANSLATIONS_PAGED
    );
    // yield put(mutations.setLoaded(false));

    try {
      let filters = [
        { Property: "ShopTypeName", Value: searchValue, Type: "string" },
        { Property: "ServiceProviderName", Value: searchValue, Type: "string" },
        { Property: "ClienttypeName", Value: searchValue, Type: "string" },
      ];

      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/imagesTranslations/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      yield put(mutations.setImagesTranslations(data));
      // yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting images Translations");
    }
  }
}

export function* getImagesTranslationsPagedForImagesSaga() {
  while (true) {
    const { filterObject, page, sort } = yield take(
      mutations.GET_IMAGES_TRANSLATIONS_PAGED_FOR_IMAGES
    );
    // yield put(mutations.setLoaded(false));

    try {
      let filters = [{ ...filterObject }];

      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/imagesTranslations/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      console.log(
        "--------------------------- : function*getImagesTranslationsPagedForImagesSaga -> data",
        data
      );
      yield put(mutations.setImagesTranslations(data));
      // yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting images Translations");
    }
  }
}

export function* getAllImagesTranslationsForImagesSaga() {
  while (true) {
    const { filterObject } = yield take(
      mutations.GET_ALL_IMAGES_TRANSLATIONS_FOR_IMAGES
    );
    // yield put(mutations.setLoaded(false));

    try {
      let filters = [{ ...filterObject }];

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/imagesTranslations/paged",
        data: {
          PageNumber: null,
          PageSize: null,
          Sort: [],
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setImagesTranslations(data));
      // yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting images Translations");
    }
  }
}

export function* getImagesTranslationsForExportSaga() {
  while (true) {
    const { searchValue, totalCount, sort, cb } = yield take(
      mutations.GET_IMAGES_TRANSLATIONS_FOR_EXPORT
    );
    try {
      let filters = [
        { Property: "ShopTypeName", Value: searchValue, Type: "string" },
        { Property: "ServiceProviderName", Value: searchValue, Type: "string" },
        { Property: "ClienttypeName", Value: searchValue, Type: "string" },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/imagesTranslations/paged",
        data: {
          PageNumber: 1,
          PageSize: totalCount,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      cb(data.resultList);
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting images Translations");
    }
  }
}

export function* getAllImagesTranslationsSaga() {
  while (true) {
    yield take(mutations.GET_ALL_IMAGES_TRANSLATIONS);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/imagesTranslations",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      yield put(mutations.setAllImagesTranslations(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting images Translations");
    }
  }
}

export function* saveImagesTranslationSaga() {
  while (true) {
    const { imagesTranslation } = yield take(
      mutations.SAVE_IMAGES_TRANSLATION
    );
    try {
      yield axios({
        method: "put",
        url: url + "/v1.0/imagesTranslations",
        data: { ...imagesTranslation },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Images Translation is saved");

      yield put(
        mutations.getImagesTranslationsPagedForImages(
          {
            Property: "Id",
            Value: imagesTranslation.Id,
            Type: "guid",
          },
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Images Translation is not saved");
    }
  }
}

export function* createImagesTranslationSaga() {
  while (true) {
    const { imagesTranslation } = yield take(
      mutations.CREATE_IMAGES_TRANSLATION
    );
    console.log(
      "--------------------------- : function*createImagesTranslationSaga -> imagesTranslation",
      imagesTranslation
    );

    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/imagesTranslations",
        data: { ...imagesTranslation },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Images Translation is created");

      yield put(
        mutations.getImagesTranslationsPagedForImages(
          {
            Property: "Id",
            Value: imagesTranslation.Id,
            Type: "guid",
          },
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Images Translation is not created");
    }
  }
}

export function* deleteImagesTranslationSaga() {
  while (true) {
    const { imagesTranslation } = yield take(
      mutations.DELETE_IMAGES_TRANSLATION
    );
    try {
      yield axios({
        method: "delete",
        url:
          url +
          `/v1.0/imagesTranslations/${imagesTranslation.ImagesTranslationId}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Images Translation is deleted");
      yield put(
        mutations.getImagesTranslationsPagedForImages(
          {
            Property: "Id",
            Value: imagesTranslation.Id,
            Type: "guid",
          },
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Images Translation is not deleted");
    }
  }
}
/* #endregion */

/* #region Salon sagas */

export function* getSalonsPaged() {
  while (true) {
    const { searchValue, page, sort } = yield take(mutations.GET_SALONS_PAGED);
    yield put(mutations.setLoaded(false));

    try {
      let filters = [
        { Property: "Name", Value: searchValue, Type: "string" },
        { Property: "Address", Value: searchValue, Type: "string" },
        { Property: "WebAddress", Value: searchValue, Type: "string" },
        { Property: "Phone", Value: searchValue, Type: "string" },
        { Property: "MobilePhone", Value: searchValue, Type: "string" },
        { Property: "Email", Value: searchValue, Type: "string" },
      ];

      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/salons/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setSalons(data));
      yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting salons");
      yield put(mutations.setLoaded(true));
    }
  }
}

export function* getSalonsForExportSaga() {
  while (true) {
    const { searchValue, totalCount, sort, cb } = yield take(
      mutations.GET_SALONS_FOR_EXPORT
    );
    try {
      let filters = [
        { Property: "Name", Value: searchValue, Type: "string" },
        { Property: "Address", Value: searchValue, Type: "string" },
        { Property: "WebAddress", Value: searchValue, Type: "string" },
        { Property: "Phone", Value: searchValue, Type: "string" },
        { Property: "MobilePhone", Value: searchValue, Type: "string" },
        { Property: "Email", Value: searchValue, Type: "string" },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/salons/paged",
        data: {
          PageNumber: 1,
          PageSize: totalCount,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      cb(data.resultList);
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting salons");
    }
  }
}

export function* getAllSalonsSaga() {
  while (true) {
    yield take(mutations.GET_ALL_SALONS);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/salons",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllSalons(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting salons");
    }
  }
}

export function* getSalonForIdSaga() {
  while (true) {
    const { salonId } = yield take(mutations.GET_SALON_FOR_ID);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + `/v1.0/salons/${salonId}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllSalons(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting salons");
    }
  }
}

export function* saveSalonSaga() {
  while (true) {
    const { salon } = yield take(mutations.SAVE_SALON);
    try {
      yield axios({
        method: "put",
        url: url + "/v1.0/salons",
        data: { ...salon },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Salon is saved");

      yield put(mutations.getSalonsPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Salon is not saved");
    }
  }
}

export function* saveSalonForIdSaga() {
  while (true) {
    const { salon, cb } = yield take(mutations.SAVE_SALON_FOR_ID);
    try {
      yield axios({
        method: "put",
        url: url + "/v1.0/salons",
        data: { ...salon },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Salon is saved");

      cb();
      yield put(mutations.getSalonForId(salon.SalonId));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Salon is not saved");
    }
  }
}

export function* createSalonSaga() {
  while (true) {
    const { salon } = yield take(mutations.CREATE_SALON);
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/salons",
        data: { ...salon },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Salon is created");

      yield put(mutations.getSalonsPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Salon is not created");
    }
  }
}

export function* deleteSalonSaga() {
  while (true) {
    const { salon } = yield take(mutations.DELETE_SALON);
    try {
      yield axios({
        method: "delete",
        url: url + `/v1.0/salons/${salon.SalonId}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Salon is deactivated");
      yield put(mutations.getSalonsPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Salon is not deactivated");
    }
  }
}
/* #endregion */

/* #region Hairstylist sagas */

export function* getHairstylistsPagedSaga() {
  while (true) {
    const { searchValue, page, sort } = yield take(
      mutations.GET_HAIRSTYLISTS_PAGED
    );
    try {
      let filters = [
        { Property: "Person.FirstName", Value: searchValue, Type: "string" },
        { Property: "Person.LastName", Value: searchValue, Type: "string" },
        { Property: "Person.Email", Value: searchValue, Type: "string" },
        { Property: "Person.Phone", Value: searchValue, Type: "string" },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/hairstylists/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      yield put(mutations.setHairstylists(data));
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting hairstylists");
    } finally {
      yield put(mutations.setLoaded(true));
    }
  }
}

export function* getHairstylistsPagedForSalonSaga() {
  while (true) {
    const { searchValue, salonId, page, sort } = yield take(
      mutations.GET_HAIRSTYLISTS_PAGED_FOR_SALON
    );
    try {
      let filters = [
        { Property: "Person.FirstName", Value: searchValue, Type: "string" },
        { Property: "Person.LastName", Value: searchValue, Type: "string" },
        // { Property: "Person.Email", Value: searchValue, Type: "string" },
        // { Property: "Person.Phone", Value: searchValue, Type: "string" },
        { Property: "SalonId", Value: salonId, Type: "guid" },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/hairstylists/pagedForSalon",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      yield put(mutations.setHairstylists(data));
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting hairstylists");
    } finally {
      yield put(mutations.setLoaded(true));
    }
  }
}

export function* getHairstylistsForExportSaga() {
  while (true) {
    const { searchValue, totalCount, sort, cb } = yield take(
      mutations.GET_HAIRSTYLISTS_FOR_EXPORT
    );
    try {
      let filters = [
        { Property: "Person.FirstName", Value: searchValue, Type: "string" },
        { Property: "Person.LastName", Value: searchValue, Type: "string" },
        // { Property: "Person.Email", Value: searchValue, Type: "string" },
        // { Property: "Person.Phone", Value: searchValue, Type: "string" },
        // { Property: "Role.Name", Value: searchValue, Type: "string" }
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/hairstylists/paged",
        data: {
          PageNumber: 1,
          PageSize: totalCount,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      cb(data.resultList);
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting hairstylists");
    }
  }
}

export function* getAllHairstylistsSaga() {
  while (true) {
    yield take(mutations.GET_ALL_HAIRSTYLISTS);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/hairstylists",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllHairstylists(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting hairstylists");
    }
  }
}

export function* getAllHairstylistsForDropdownSaga() {
  while (true) {
    yield take(mutations.GET_ALL_HAIRSTYLISTS_DROPDOWN);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/hairstylists/dropdown",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllHairstylists(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting hairstylists for dropdown");
    }
  }
}

export function* saveHairstylistSaga() {
  while (true) {
    const { hairstylist } = yield take(mutations.SAVE_HAIRSTYLIST);
    try {
      yield axios({
        method: "put",
        url: url + "/v1.0/hairstylists",
        data: { ...hairstylist },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Hairstylist is saved");

      yield put(mutations.getHairstylistsPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Hairstylist is not saved");
    }
  }
}

export function* saveHairstylistForSalonSaga() {
  while (true) {
    const { hairstylist, salonId, cb } = yield take(
      mutations.SAVE_HAIRSTYLIST_FOR_SALON
    );
    try {
      yield axios({
        method: "put",
        url: url + "/v1.0/hairstylists",
        data: { ...hairstylist },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Hairstylist is saved");

      cb();
      yield put(mutations.getHairstylistsPagedForSalon("", salonId, 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Hairstylist is not saved");
    }
  }
}

export function* createHairstylistSaga() {
  while (true) {
    const { hairstylist } = yield take(mutations.CREATE_HAIRSTYLIST);
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/hairstylists",
        data: { ...hairstylist },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Hairstylist is created");

      yield put(mutations.getHairstylistsPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Hairstylist is not created");
    }
  }
}

export function* createHairstylistForSalonSaga() {
  while (true) {
    const { hairstylist, cb } = yield take(
      mutations.CREATE_HAIRSTYLIST_FOR_SALON
    );
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/hairstylists",
        data: { ...hairstylist },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Hairstylist is created");

      cb();
      yield put(
        mutations.getHairstylistsPagedForSalon("", hairstylist.SalonId, 1, [])
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Hairstylist is not created");
    }
  }
}

export function* deleteHairstylistSaga() {
  while (true) {
    const { hairstylist } = yield take(mutations.DELETE_HAIRSTYLIST);
    try {
      yield axios({
        method: "delete",
        url: url + `/v1.0/hairstylists/${hairstylist.PersonId}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Hairstylist is deactivated");
      yield put(mutations.getHairstylistsPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Hairstylist is not deactivated");
    }
  }
}

export function* deleteHairstylistForSalonSaga() {
  while (true) {
    const { hairstylist, salonId, cb } = yield take(
      mutations.DELETE_HAIRSTYLIST_FOR_SALON
    );
    try {
      yield axios({
        method: "delete",
        url: url + `/v1.0/hairstylists/${hairstylist.PersonId}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Hairstylist is deactivated");
      cb();
      yield put(mutations.getHairstylistsPagedForSalon("", salonId, 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Hairstylist is not deactivated");
    }
  }
}

/* #endregion */

/* #region Visit sagas */

export function* getVisitsPagedSaga() {
  while (true) {
    const { searchValue, page, sort } = yield take(mutations.GET_VISITS_PAGED);
    yield put(mutations.setLoaded(false));

    try {
      let filters = [
        // { Property: "Description", Value: searchValue, Type: "string" },
        {
          Property: "SalonCustomer.Customer.Person.FullName",
          Value: searchValue,
          Type: "string",
        },
        // {
        //   Property: "SalonCustomer.Customer.Person.LastName",
        //   Value: searchValue,
        //   Type: "string",
        // },
        {
          Property: "SalonCustomer.Salon.Name",
          Value: searchValue,
          Type: "string",
        },
        {
          Property: "Hairstylist.Person.FullName",
          Value: searchValue,
          Type: "string",
        },
        // {
        //   Property: "Hairstylist.Person.LastName",
        //   Value: searchValue,
        //   Type: "string",
        // },
        // {
        //   Property: "ServiceType.ServiceTypeName",
        //   Value: searchValue,
        //   Type: "string",
        // },
        // {
        //   Property: "Status.ItemName",
        //   Value: searchValue,
        //   Type: "string",
        // },
      ];

      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/visits/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      console.log(data);

      yield put(mutations.setVisits(data));
      yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting visits");
      yield put(mutations.setLoaded(true));
    }
  }
}

export function* getVisitsPagedForSalonSaga() {
  while (true) {
    const { searchValue, salonId, page, sort } = yield take(
      mutations.GET_VISITS_PAGED_FOR_SALON
    );
    yield put(mutations.setLoaded(false));

    try {
      let filters = [
        // { Property: "Description", Value: searchValue, Type: "string" },
        {
          Property: "Status.ItemName",
          Value: searchValue,
          Type: "string",
        },
        {
          Property: "SalonId",
          Value: salonId,
          Type: "guid",
        },
      ];

      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/visits/pagedForSalon",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      yield put(mutations.setVisits(data));
      yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting visits");
      yield put(mutations.setLoaded(true));
    }
  }
}

export function* getVisitsForExportSaga() {
  while (true) {
    const { searchValue, totalCount, sort, cb } = yield take(
      mutations.GET_VISITS_FOR_EXPORT
    );
    try {
      let filters = [
        { Property: "Description", Value: searchValue, Type: "string" },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/visits/paged",
        data: {
          PageNumber: 1,
          PageSize: totalCount,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      cb(data.resultList);
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting visits");
    }
  }
}

export function* getAllVisitsSaga() {
  while (true) {
    yield take(mutations.GET_ALL_VISITS);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/visits",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllVisits(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting visits");
    }
  }
}

export function* saveVisitSaga() {
  while (true) {
    const { visit, cb } = yield take(mutations.SAVE_VISIT);
    try {
      yield axios({
        method: "put",
        url: url + "/v1.0/visits",
        data: { ...visit },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Visit is saved");

      yield put(mutations.getVisitsPaged("", 1, []));
      cb();
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Visit is not saved");
    }
  }
}

export function* saveVisitFromServiceTypeListSaga() {
  while (true) {
    const { visit, cb } = yield take(
      mutations.SAVE_VISIT_FROM_SERVICE_TYPE_LIST
    );

    try {
      yield axios({
        method: "put",
        url: url + "/v1.0/visits/fromServiceTypeList",
        data: { ...visit },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Visit is saved");

      yield put(mutations.getVisitsPaged("", 1, []));
      cb();
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Visit is not saved");
    }
  }
}

export function* createVisitSaga() {
  while (true) {
    const { visit } = yield take(mutations.CREATE_VISIT);
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/visits",
        data: { ...visit },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Visit is created");

      yield put(mutations.getVisitsPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Visit is not created");
    }
  }
}

export function* createVisitFromServiceTypeListSaga() {
  while (true) {
    const { visit, cb } = yield take(
      mutations.CREATE_VISIT_FROM_SERVICE_TYPE_LIST
    );
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/visits/fromServiceTypeList",
        data: { ...visit },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      toastSuccess("Visit is created");

      yield put(mutations.getVisitsPaged("", 1, []));
      cb();
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Visit is not created");
    }
  }
}

export function* deleteVisitSaga() {
  while (true) {
    const { visit } = yield take(mutations.DELETE_VISIT);
    try {
      yield axios({
        method: "delete",
        url: url + `/v1.0/visits/${visit.VisitId}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      toastSuccess("Visit is deleted");

      yield put(mutations.getVisitsPaged("", 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Visit is not deleted");
    }
  }
}
/* #endregion */

/* #region ServiceType sagas */

export function* getServiceTypesPagedSaga() {
  while (true) {
    const { searchValue, page, sort } = yield take(
      mutations.GET_SERVICE_TYPES_PAGED
    );
    yield put(mutations.setLoaded(false));

    try {
      let filters = [{ Property: "SalonId", Value: searchValue, Type: "guid" }];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/serviceTypes/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setServiceTypes(data));
      yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting serviceTypes");
      yield put(mutations.setLoaded(true));
    }
  }
}

// Sledece na osnovu https://redux-saga.js.org/docs/api/
const checkedState = (state) => state.checkedState;

export function* getServiceTypesPagedWithInactiveSaga() {
  while (true) {
    const { searchValue, page, sort } = yield take(
      mutations.GET_SERVICE_TYPES_PAGED_WITH_INACTIVE
    );
    yield put(mutations.setLoaded(false));

    let getCheckedState = yield select(checkedState);

    try {
      let filters = getCheckedState
        ? [{ Property: "SalonId", Value: searchValue, Type: "guid" }]
        : [
            { Property: "SalonId", Value: searchValue, Type: "guid" },
            { Property: "Status.ItemName", Value: "Active", Type: "string" },
          ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/serviceTypes/pagedWithInactive",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setServiceTypes(data));
      yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting serviceTypes");
      yield put(mutations.setLoaded(true));
    }
  }
}

export function* getServiceTypesForExportSaga() {
  while (true) {
    const { searchValue, totalCount, sort, cb } = yield take(
      mutations.GET_SERVICE_TYPES_FOR_EXPORT
    );
    try {
      let filters = [{ Property: "SalonId", Value: searchValue, Type: "guid" }];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/serviceTypes/paged",
        data: {
          PageNumber: 1,
          PageSize: totalCount,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      cb(data.resultList);
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting serviceTypes");
    }
  }
}

export function* getAllServiceTypesSaga() {
  while (true) {
    yield take(mutations.GET_ALL_SERVICE_TYPES);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/serviceTypes",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllServiceTypes(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting serviceTypes");
    }
  }
}

export function* getAllServiceTypesForDropdownSaga() {
  while (true) {
    yield take(mutations.GET_ALL_SERVICE_TYPES_DROPDOWN);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/serviceTypes/dropdown",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllServiceTypes(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting serviceTypes for dropdown");
    }
  }
}

export function* getAllServiceTypesForSalonSaga() {
  while (true) {
    const { salonId } = yield take(mutations.GET_ALL_SERVICE_TYPES_FOR_SALON);
    let o = {
      SalonId: salonId,
    };
    try {
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/serviceTypes/allServiceTypes",
        data: { ...o },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      //toastSuccess("If date is created checked");
      yield put(mutations.setAllServiceTypesForSalon(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Unsecsessefully fetched all service types");
    }
  }
}

export function* saveServiceTypeSaga() {
  while (true) {
    const { serviceType } = yield take(mutations.SAVE_SERVICE_TYPE);
    try {
      yield axios({
        method: "put",
        url: url + "/v1.0/serviceTypes",
        data: { ...serviceType },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("ServiceType is saved");

      // yield put(mutations.getServiceTypesPaged("", 1, []));
      yield put(
        mutations.getServiceTypesPagedWithInactive(serviceType.salonId, 1, [])
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("ServiceType is not saved");
    }
  }
}

export function* createServiceTypeSaga() {
  while (true) {
    const { serviceType } = yield take(mutations.CREATE_SERVICE_TYPE);
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/serviceTypes",
        data: { ...serviceType },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("ServiceType is created");
      // yield put(mutations.getServiceTypesPaged("", 1, []));
      yield put(
        mutations.getServiceTypesPagedWithInactive(serviceType.salonId, 1, [])
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("ServiceType is not created");
    }
  }
}

export function* deleteServiceTypeSaga() {
  while (true) {
    const { serviceType } = yield take(mutations.DELETE_SERVICE_TYPE);
    try {
      const { data } = yield axios({
        method: "delete",
        url: url + `/v1.0/serviceTypes/${serviceType.serviceTypeId}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      if (data.isSucceeded) {
        toastSuccess("ServiceType is deactivated");
      } else {
        toastError("ServiceType is not deactivated");
      }
      //yield put(mutations.getServiceTypesPaged("", 1, []));
      yield put(
        mutations.getServiceTypesPagedWithInactive(serviceType.salonId, 1, [])
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("ServiceType is not deactivated");
    }
  }
}

export function* activateServiceTypeSaga() {
  while (true) {
    const { serviceType } = yield take(mutations.ACTIVATE_SERVICE_TYPE);
    try {
      const { data } = yield axios({
        method: "patch",
        url: url + `/v1.0/serviceTypes/${serviceType.serviceTypeId}`,
        data: { ...serviceType },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      if (data.isSucceeded) {
        toastSuccess("ServiceType is activated");
      } else {
        toastError("ServiceType is not activated");
      }
      //yield put(mutations.getServiceTypesPaged("", 1, []));
      yield put(
        mutations.getServiceTypesPagedWithInactive(serviceType.salonId, 1, [])
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("ServiceType is not activated");
    }
  }
}

/* #endregion */

/* #region SalonCustomer sagas */

export function* getSalonCustomersPagedSaga() {
  while (true) {
    const { searchValue, page, sort } = yield take(
      mutations.GET_SALON_CUSTOMERS_PAGED
    );
    yield put(mutations.setLoaded(false));

    try {
      let filters = [{ Property: "SalonId", Value: searchValue, Type: "guid" }];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/salonCustomers/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setSalonCustomers(data));
      yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting salonCustomers");
      yield put(mutations.setLoaded(true));
    }
  }
}

export function* getSalonCustomersForExportSaga() {
  while (true) {
    const { searchValue, totalCount, sort, cb } = yield take(
      mutations.GET_SALON_CUSTOMERS_FOR_EXPORT
    );
    try {
      let filters = [{ Property: "SalonId", Value: searchValue, Type: "guid" }];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/salonCustomers/paged",
        data: {
          PageNumber: 1,
          PageSize: totalCount,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      cb(data.resultList);
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting salonCustomers");
    }
  }
}

export function* getAllSalonCustomersSaga() {
  while (true) {
    yield take(mutations.GET_ALL_SALON_CUSTOMERS);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/salonCustomers",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllSalonCustomers(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting salonCustomers");
    }
  }
}

export function* getAllSalonCustomersForSalonSaga() {
  while (true) {
    const { salonCustomer } = yield take(
      mutations.GET_ALL_SALON_CUSTOMERS_FOR_SALON
    );
    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/salonCustomers/basicForSalon",
        data: { ...salonCustomer },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllSalonCustomers(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting salonCustomers for dropdown");
    }
  }
}

export function* saveSalonCustomerSaga() {
  while (true) {
    const { salonCustomer } = yield take(mutations.SAVE_SALON_CUSTOMER);
    try {
      yield axios({
        method: "put",
        url: url + "/v1.0/salonCustomers",
        data: { ...salonCustomer },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("ServiceType is saved");

      // yield put(mutations.getSalonCustomersPaged("", 1, []));
      yield put(mutations.getSalonCustomersPaged(salonCustomer.salonId, 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("SalonCustomer is not saved");
    }
  }
}

export function* createSalonCustomerSaga() {
  while (true) {
    const { salonCustomer } = yield take(mutations.CREATE_SALON_CUSTOMER);
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/salonCustomers",
        data: { ...salonCustomer },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("SalonCustomer is created");

      // yield put(mutations.getSalonCustomersPaged("", 1, []));
      yield put(mutations.getSalonCustomersPaged(salonCustomer.salonId, 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("SalonCustomer is not created");
    }
  }
}

export function* deleteSalonCustomerSaga() {
  while (true) {
    const { salonCustomer } = yield take(mutations.DELETE_SALON_CUSTOMER);
    try {
      yield axios({
        method: "delete",
        url: url + `/v1.0/salonCustomers/${salonCustomer.salonCustomerId}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("SalonCustomer is deactivated");
      // yield put(mutations.getSalonCustomersPaged("", 1, []));
      yield put(mutations.getSalonCustomersPaged(salonCustomer.salonId, 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("SalonCustomer is not deactivated");
    }
  }
}

/* #endregion */

/* #region SalonHairstylist sagas */

export function* getSalonHairstylistsPagedSaga() {
  while (true) {
    const { searchValue, page, sort } = yield take(
      mutations.GET_SALON_HAIRSTYLISTS_PAGED
    );
    yield put(mutations.setLoaded(false));

    try {
      let filters = [
        { Property: "HairstylistId", Value: searchValue, Type: "guid" },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/salonHairstylists/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setSalonHairstylists(data));
      yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting salonHairstylists");
      yield put(mutations.setLoaded(true));
    }
  }
}

export function* getSalonHairstylistsForExportSaga() {
  while (true) {
    const { searchValue, totalCount, sort, cb } = yield take(
      mutations.GET_SALON_HAIRSTYLISTS_FOR_EXPORT
    );
    try {
      let filters = [
        { Property: "HairstylistId", Value: searchValue, Type: "guid" },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/salonHairstylists/paged",
        data: {
          PageNumber: 1,
          PageSize: totalCount,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      cb(data.resultList);
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting salonHairstylists");
    }
  }
}

export function* getAllSalonHairstylistsSaga() {
  while (true) {
    yield take(mutations.GET_ALL_SALON_HAIRSTYLISTS);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/salonHairstylists",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllSalonHairstylists(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting salonHairstylists");
    }
  }
}

export function* getAllSalonHairstylistsForDropdownSaga() {
  while (true) {
    yield take(mutations.GET_ALL_SALON_HAIRSTYLISTS_DROPDOWN);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/salonHairstylists/dropdown",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllSalonHairstylists(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting salonHairstylists for dropdown");
    }
  }
}

export function* getAllSalonHairstylistsForHairstylistSaga() {
  while (true) {
    const { hairstylistId, salonId } = yield take(
      mutations.GET_ALL_SALON_HAIRSTYLISTS_FOR_HAIRSTYLIST
    );
    let o = {
      HairstylistId: hairstylistId,
      SalonId: salonId,
    };
    try {
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/salonHairstylists/allSalonHairstylists",
        data: { ...o },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      //toastSuccess("If date is created checked");
      yield put(mutations.setAllHairstylistServiceTypesForHairstylist(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Unsecsessefully fetched all hairstylist service types");
    }
  }
}

export function* saveSalonHairstylistSaga() {
  while (true) {
    const { salonHairstylist } = yield take(mutations.SAVE_SALON_HAIRSTYLIST);
    try {
      yield axios({
        method: "put",
        url: url + "/v1.0/salonHairstylists",
        data: { ...salonHairstylist },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("ServiceType is saved");

      // yield put(mutations.getSalonHairstylistsPaged("", 1, []));
      yield put(
        mutations.getSalonHairstylistsPaged(
          salonHairstylist.hairstylistId,
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("SalonHairstylist is not saved");
    }
  }
}

export function* createSalonHairstylistSaga() {
  while (true) {
    const { salonHairstylist } = yield take(mutations.CREATE_SALON_HAIRSTYLIST);
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/salonHairstylists",
        data: { ...salonHairstylist },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("SalonHairstylist is created");

      // yield put(mutations.getSalonHairstylistsPaged("", 1, []));
      yield put(
        mutations.getSalonHairstylistsPaged(
          salonHairstylist.hairstylistId,
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("SalonHairstylist is not created");
    }
  }
}

export function* deleteSalonHairstylistSaga() {
  while (true) {
    const { salonHairstylist } = yield take(mutations.DELETE_SALON_HAIRSTYLIST);
    try {
      yield axios({
        method: "delete",
        url:
          url +
          `/v1.0/salonHairstylists/${salonHairstylist.salonHairstylistId}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("SalonHairstylist is deactivated");
      // yield put(mutations.getSalonHairstylistsPaged("", 1, []));
      yield put(
        mutations.getSalonHairstylistsPaged(
          salonHairstylist.hairstylistId,
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("SalonHairstylist is not deactivated");
    }
  }
}

export function* deleteSalonHairstylistReplaceSaga() {
  while (true) {
    const { salonHairstylist, hairstylistId } = yield take(
      mutations.DELETE_SALON_HAIRSTYLIST_REPLACE_HAIRSTYLIST
    );
    let salonHairstylistId = salonHairstylist.salonHairstylistId;
    try {
      yield axios({
        method: "post",
        url: url + `/v1.0/salonHairstylists/replace`,
        data: { salonHairstylistId, hairstylistId },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("SalonHairstylist is deactivated");
      // yield put(mutations.getSalonHairstylistsPaged("", 1, []));
      yield put(
        mutations.getSalonHairstylistsPaged(
          salonHairstylist.hairstylistId,
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("SalonHairstylist is not deactivated");
    }
  }
}

/* #endregion */

/* #region HairstylistServiceType sagas */

export function* getHairstylistServiceTypesPagedSaga() {
  while (true) {
    const { searchValue, page, sort } = yield take(
      mutations.GET_HAIRSTYLIST_SERVICE_TYPES_PAGED
    );

    yield put(mutations.setLoaded(false));
    try {
      let filters = [
        {
          Property: "HairstylistId",
          Value: searchValue.hairstylistId,
          Type: "guid",
        },
        {
          Property: "ServiceType.SalonId",
          Value: searchValue.salonId,
          Type: "guid",
        },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/hairstylistServiceTypes/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      yield put(mutations.setHairstylistServiceTypes(data));
      yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting hairstylistServiceTypes");
      yield put(mutations.setLoaded(true));
    }
  }
}

export function* getHairstylistServiceTypesForExportSaga() {
  while (true) {
    const { searchValue, totalCount, sort, cb } = yield take(
      mutations.GET_HAIRSTYLIST_SERVICE_TYPES_FOR_EXPORT
    );
    try {
      let filters = [
        { Property: "HairstylistId", Value: searchValue, Type: "guid" },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/hairstylistServiceTypes/paged",
        data: {
          PageNumber: 1,
          PageSize: totalCount,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      cb(data.resultList);
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting hairstylistServiceTypes");
    }
  }
}

export function* getAllHairstylistServiceTypesSaga() {
  while (true) {
    yield take(mutations.GET_ALL_HAIRSTYLIST_SERVICE_TYPES);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/hairstylistServiceTypes",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllHairstylistServiceTypes(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting hairstylistServiceTypes");
    }
  }
}

export function* getAllHairstylistServiceTypesForDropdownSaga() {
  while (true) {
    yield take(mutations.GET_ALL_HAIRSTYLIST_SERVICE_TYPES_DROPDOWN);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/hairstylistServiceTypes/dropdown",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllHairstylistServiceTypes(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting hairstylistServiceTypes for dropdown");
    }
  }
}

export function* getAllHairstylistServiceTypesForHairstylistSaga() {
  while (true) {
    const { hairstylistId, salonId } = yield take(
      mutations.GET_ALL_HAIRSTYLIST_SERVICE_TYPES_FOR_HAIRSTYLIST
    );
    let o = {
      HairstylistId: hairstylistId,
      SalonId: salonId,
    };
    try {
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/hairstylistServiceTypes/allHairstylistServiceTypes",
        data: { ...o },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      //toastSuccess("If date is created checked");
      yield put(mutations.setAllHairstylistServiceTypesForHairstylist(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Unsecsessefully fetched all hairstylist service types");
    }
  }
}

export function* getAvailableServiceTypesSaga() {
  while (true) {
    const { salonId, hairstylistId } = yield take(
      mutations.GET_AVAILABLE_SERVICE_TYPES
    );
    let o = {
      HairstylistId: hairstylistId,
      SalonId: salonId,
    };
    try {
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/hairstylistServiceTypes/availableServiceTypes",
        data: { ...o },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      //toastSuccess("If date is created checked");
      yield put(mutations.setAvailableServiceTypes(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Unsecsessefully fetched all hairstylist service types");
    }
  }
}

export function* saveHairstylistServiceTypeSaga() {
  while (true) {
    const { hairstylistServiceType } = yield take(
      mutations.SAVE_HAIRSTYLIST_SERVICE_TYPE
    );
    try {
      yield axios({
        method: "put",
        url: url + "/v1.0/hairstylistServiceTypes",
        data: { ...hairstylistServiceType },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("ServiceType is saved");

      //yield put(mutations.getHairstylistServiceTypesPaged("", 1, []));
      yield put(
        mutations.getHairstylistServiceTypesPaged(
          {
            hairstylistId: hairstylistServiceType.hairstylistId,
            salonId: hairstylistServiceType.serviceType.salonId,
          },
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("HairstylistServiceType is not saved");
    }
  }
}

export function* createHairstylistServiceTypeSaga() {
  while (true) {
    const { hairstylistServiceType, cb } = yield take(
      mutations.CREATE_HAIRSTYLIST_SERVICE_TYPE
    );
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/hairstylistServiceTypes",
        data: { ...hairstylistServiceType },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("HairstylistServiceType is created");
      cb();
      //yield put(mutations.getHairstylistServiceTypesPaged("", 1, []));
      yield put(
        mutations.getHairstylistServiceTypesPaged(
          {
            hairstylistId: hairstylistServiceType.hairstylistId,
            salonId: hairstylistServiceType.salonId,
          },
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("HairstylistServiceType is not created");
    }
  }
}

export function* deleteHairstylistServiceTypeSaga() {
  while (true) {
    const { hairstylistServiceType } = yield take(
      mutations.DELETE_HAIRSTYLIST_SERVICE_TYPE
    );
    try {
      const { data } = yield axios({
        method: "delete",
        url:
          url +
          `/v1.0/hairstylistServiceTypes/${hairstylistServiceType.hairstylistServiceTypeId}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      if (data.isSucceeded) {
        toastSuccess("HairstylistServiceType is deactivated");
      } else {
        toastError("HairstylistServiceType is not deactivated");
      }
      //yield put(mutations.getHairstylistServiceTypesPaged("", 1, []));
      yield put(
        mutations.getHairstylistServiceTypesPaged(
          {
            hairstylistId: hairstylistServiceType.hairstylistId,
            salonId: hairstylistServiceType.serviceType.salonId,
          },
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("HairstylistServiceType is not deactivated");
    }
  }
}

/* #endregion */

/* #region VisitServiceType sagas */

export function* getVisitServiceTypesPagedSaga() {
  while (true) {
    const { searchValue, page, sort } = yield take(
      mutations.GET_VISIT_SERVICE_TYPES_PAGED
    );

    yield put(mutations.setLoaded(false));
    try {
      let filters = [
        {
          Property: "VisitId",
          Value: searchValue.visitId,
          Type: "guid",
        },
        {
          Property: "ServiceType.SalonId",
          Value: searchValue.salonId,
          Type: "guid",
        },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/visitServiceTypes/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      yield put(mutations.setVisitServiceTypes(data));
      yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting visitServiceTypes");
      yield put(mutations.setLoaded(true));
    }
  }
}

export function* getVisitServiceTypesForExportSaga() {
  while (true) {
    const { searchValue, totalCount, sort, cb } = yield take(
      mutations.GET_VISIT_SERVICE_TYPES_FOR_EXPORT
    );
    try {
      let filters = [{ Property: "VisitId", Value: searchValue, Type: "guid" }];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/visitServiceTypes/paged",
        data: {
          PageNumber: 1,
          PageSize: totalCount,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      cb(data.resultList);
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting visitServiceTypes");
    }
  }
}

export function* getAllVisitServiceTypesSaga() {
  while (true) {
    yield take(mutations.GET_ALL_VISIT_SERVICE_TYPES);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/visitServiceTypes",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllVisitServiceTypes(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting visitServiceTypes");
    }
  }
}

export function* getAllVisitServiceTypesForDropdownSaga() {
  while (true) {
    yield take(mutations.GET_ALL_VISIT_SERVICE_TYPES_DROPDOWN);
    try {
      const { data } = yield axios({
        method: "get",
        url: url + "/v1.0/visitServiceTypes/dropdown",
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllVisitServiceTypes(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting visitServiceTypes for dropdown");
    }
  }
}

export function* getAllVisitServiceTypesForVisitSaga() {
  while (true) {
    const { visitId, salonId } = yield take(
      mutations.GET_ALL_VISIT_SERVICE_TYPES_FOR_VISIT
    );
    let o = {
      VisitId: visitId,
      SalonId: salonId,
    };
    try {
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/visitServiceTypes/allVisitServiceTypes",
        data: { ...o },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      //toastSuccess("If date is created checked");
      yield put(mutations.setAllVisitServiceTypesForVisit(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Unsecsessefully fetched all visit service types");
    }
  }
}

export function* saveVisitServiceTypeSaga() {
  while (true) {
    const { visitServiceType } = yield take(mutations.SAVE_VISIT_SERVICE_TYPE);
    try {
      yield axios({
        method: "put",
        url: url + "/v1.0/visitServiceTypes",
        data: { ...visitServiceType },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("ServiceType is saved");

      //yield put(mutations.getVisitServiceTypesPaged("", 1, []));
      yield put(
        mutations.getVisitServiceTypesPaged(
          {
            visitId: visitServiceType.visitId,
            salonId: visitServiceType.serviceType.salonId,
          },
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("VisitServiceType is not saved");
    }
  }
}

export function* createVisitServiceTypeSaga() {
  while (true) {
    const { visitServiceType } = yield take(
      mutations.CREATE_VISIT_SERVICE_TYPE
    );
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/visitServiceTypes",
        data: { ...visitServiceType },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("VisitServiceType is created");

      //yield put(mutations.getVisitServiceTypesPaged("", 1, []));
      yield put(
        mutations.getVisitServiceTypesPaged(
          {
            visitId: visitServiceType.visitId,
            salonId: visitServiceType.salonId,
          },
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("VisitServiceType is not created");
    }
  }
}

export function* deleteVisitServiceTypeSaga() {
  while (true) {
    const { visitServiceType } = yield take(
      mutations.DELETE_VISIT_SERVICE_TYPE
    );
    try {
      yield axios({
        method: "delete",
        url:
          url +
          `/v1.0/visitServiceTypes/${visitServiceType.visitServiceTypeId}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("VisitServiceType is deactivated");
      //yield put(mutations.getVisitServiceTypesPaged("", 1, []));
      yield put(
        mutations.getVisitServiceTypesPaged(
          {
            visitId: visitServiceType.visitId,
            salonId: visitServiceType.serviceType.salonId,
          },
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("VisitServiceType is not deactivated");
    }
  }
}

/* #endregion */

/* #region Hairstylist WorkingTime sagas */

export function* getHairstylistWorkingTimesPagedSaga() {
  while (true) {
    const { salonHairstylistId, page, sort } = yield take(
      mutations.GET_HAIRSTYLIST_WORKING_TIMES_PAGED
    );
    // yield put(mutations.setLoaded(false));

    try {
      let filters = [
        {
          Property: "SalonHairstylistId",
          Value: salonHairstylistId,
          Type: "guid",
        },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/workingTimes/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setHairstylistWorkingTimes(data));
      // yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting working times");
    }
  }
}

export function* getHairstylistWorkingTimesPagedConsolidatedSaga() {
  while (true) {
    const { salonHairstylistId, page, sort } = yield take(
      mutations.GET_HAIRSTYLIST_WORKING_TIMES_PAGED_CONSOLIDATED
    );
    // yield put(mutations.setLoaded(false));

    try {
      let filters = [
        {
          Property: "SalonHairstylistId",
          Value: salonHairstylistId,
          Type: "guid",
        },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/workingTimes/pagedHairstylistConsolidated",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setHairstylistWorkingTimesConsolidated(data));
      // yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting working times");
    }
  }
}

export function* getAllHairstylistWorkingTimesDatesSaga() {
  while (true) {
    const { salonHairstylistId, page, sort } = yield take(
      mutations.GET_ALL_HAIRSTYLIST_WORKING_TIMES_DATES
    );
    // yield put(mutations.setLoaded(false));

    try {
      let filters = [
        {
          Property: "SalonHairstylistId",
          Value: salonHairstylistId,
          Type: "guid",
        },
      ];

      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/workingTimes/allHairstylistTimesDates",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      yield put(mutations.setAllHairstylistWorkingTimesDates(data));
      // yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting working times");
    }
  }
}

export function* getHairstylistWorkingTimesIsDateCreatedSaga() {
  while (true) {
    const { salonHairstylistId, startDate } = yield take(
      mutations.GET_HAIRSTYLIST_WORKING_TIMES_IS_DATE_CREATED
    );
    let o = {
      SalonHairstylistId: salonHairstylistId,
      StartTime: startDate,
    };
    try {
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/workingTimes/isDateCreated",
        data: { ...o },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      //toastSuccess("If date is created checked");

      yield put(mutations.setHairstylistWorkingTimeIsDateCreated(data.result));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Unsecsessefully checked if selected date is created");
    }
  }
}

export function* getAllHairstylistWorkingTimesSaga() {
  while (true) {
    const { salonHairstylistId } = yield take(
      mutations.GET_ALL_HAIRSTYLIST_WORKING_TIMES
    );
    let o = {
      SalonHairstylistId: salonHairstylistId,
    };
    try {
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/workingTimes/allWorkingTimes",
        data: { ...o },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      //toastSuccess("If date is created checked");
      yield put(mutations.setAllHairstylistWorkingTimes(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Unsecsessefully fetched all hairstylist working times");
    }
  }
}

export function* saveHairstylistWorkingTimeSaga() {
  while (true) {
    const {
      workingTimeId,
      salonHairstylistId,
      startDate,
      endDate,
      checkboxInputs,
    } = yield take(mutations.SAVE_HAIRSTYLIST_WORKING_TIME);
    let o = {
      //WorkingTimeId: workingTimeId, //No use for consolidated, only creates error
      SalonHairstylistId: salonHairstylistId,
      StartTime: startDate,
      EndTime: endDate,
      CheckboxInputs: checkboxInputs,
    };

    try {
      yield axios({
        method: "put",
        url: url + "/v1.0/workingTimes",
        data: { ...o },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Working time is saved");

      yield put(
        mutations.getAllHairstylistWorkingTimesDates(
          salonHairstylistId,
          null,
          []
        )
      );
      yield put(
        mutations.getHairstylistWorkingTimePagedConsolidated(
          salonHairstylistId,
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Working time is not saved");
    }
  }
}

export function* createHairstylistWorkingTimeSaga() {
  while (true) {
    const {
      salonHairstylistId,
      startDate,
      endDate,
      checkboxInputs,
    } = yield take(mutations.CREATE_HAIRSTYLIST_WORKING_TIME);
    let o = {
      SalonHairstylistId: salonHairstylistId,
      StartTime: startDate,
      EndTime: endDate,
      CheckboxInputs: checkboxInputs,
    };
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/workingTimes",
        data: { ...o },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Working time is created");

      yield put(
        mutations.getAllHairstylistWorkingTimesDates(
          salonHairstylistId,
          null,
          []
        )
      );
      yield put(
        mutations.getHairstylistWorkingTimePagedConsolidated(
          salonHairstylistId,
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Working time is not created");
    }
  }
}

export function* deleteHairstylistWorkingTimeSaga() {
  while (true) {
    const { workingTimeId, salonHairstylistId } = yield take(
      mutations.DELETE_HAIRSTYLIST_WORKING_TIME
    );
    try {
      yield axios({
        method: "delete",
        url: url + `/v1.0/workingTimes/${workingTimeId}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      toastSuccess("Working time is deleted");

      yield put(
        mutations.getAllHairstylistWorkingTimesDates(
          salonHairstylistId,
          null,
          []
        )
      );
      yield put(
        mutations.getHairstylistWorkingTimePagedConsolidated(
          salonHairstylistId,
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Working time is not deleted");
    }
  }
}

export function* deleteHairstylistWorkingTimeConsolidatedSaga() {
  while (true) {
    const { workingTimeConsolidated } = yield take(
      mutations.DELETE_HAIRSTYLIST_WORKING_TIME_CONSOLIDATED
    );
    try {
      yield axios({
        method: "delete",
        url: url + `/v1.0/workingTimes/deleteHairstylistConsolidated`,
        data: { ...workingTimeConsolidated },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      toastSuccess("Working time is deleted");
      yield put(
        mutations.getAllHairstylistWorkingTimesDates(
          workingTimeConsolidated.salonHairstylistId,
          null,
          []
        )
      );
      yield put(
        mutations.getHairstylistWorkingTimePagedConsolidated(
          workingTimeConsolidated.salonHairstylistId,
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Working time is not deleted");
    }
  }
}
/* #endregion */

/* #region SalonWorkingTime sagas */

export function* getSalonWorkingTimesPagedSaga() {
  while (true) {
    const { salonId, page, sort } = yield take(
      mutations.GET_SALON_WORKING_TIMES_PAGED
    );
    // yield put(mutations.setLoaded(false));

    try {
      let filters = [
        {
          Property: "SalonId",
          Value: salonId,
          Type: "guid",
        },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/workingTimes/paged",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setSalonWorkingTimes(data));
      // yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting working times");
    }
  }
}

export function* getSalonWorkingTimesPagedConsolidatedSaga() {
  while (true) {
    const { salonId, page, sort } = yield take(
      mutations.GET_SALON_WORKING_TIMES_PAGED_CONSOLIDATED
    );
    // yield put(mutations.setLoaded(false));

    try {
      let filters = [
        {
          Property: "SalonId",
          Value: salonId,
          Type: "guid",
        },
      ];
      const x = sort.map((x) => {
        return {
          Property: x.field[0].toUpperCase() + x.field.slice(1),
          Direction: x.dir.toUpperCase(),
        };
      });

      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/workingTimes/pagedSalonConsolidated",
        data: {
          PageNumber: page,
          PageSize: 10,
          Sort: x,
          Filters: filters,
        },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      yield put(mutations.setSalonWorkingTimesConsolidated(data));
      // yield put(mutations.setLoaded(true));
    } catch (e) {
      /* catch block handles failed login */
      console.log(e);
      toastError("Error getting working times");
    }
  }
}

export function* getSalonWorkingTimesIsDateCreatedSaga() {
  while (true) {
    const { salonId, startDate } = yield take(
      mutations.GET_SALON_WORKING_TIMES_IS_DATE_CREATED
    );
    let o = {
      SalonId: salonId,
      StartTime: startDate,
    };
    try {
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/workingTimes/isDateCreated",
        data: { ...o },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      //toastSuccess("If date is created checked");

      yield put(mutations.setSalonWorkingTimeIsDateCreated(data.result));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Unsecsessefully checked if selected date is created");
    }
  }
}

export function* getAllSalonWorkingTimesSaga() {
  while (true) {
    const { salonId } = yield take(mutations.GET_ALL_SALON_WORKING_TIMES);
    let o = {
      SalonId: salonId,
    };
    try {
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/workingTimes/allWorkingTimes",
        data: { ...o },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      //toastSuccess("If date is created checked");

      yield put(mutations.setAllSalonWorkingTimes(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Unsecsessefully fetched all salon working times");
    }
  }
}

export function* saveSalonWorkingTimeSaga() {
  while (true) {
    const {
      workingTimeId,
      salonId,
      startDate,
      endDate,
      checkboxInputs,
    } = yield take(mutations.SAVE_SALON_WORKING_TIME);
    let o = {
      //WorkingTimeId: workingTimeId, //No use for consolidated, only creates error
      SalonId: salonId,
      StartTime: startDate,
      EndTime: endDate,
      CheckboxInputs: checkboxInputs,
    };
    try {
      yield axios({
        method: "put",
        url: url + "/v1.0/workingTimes",
        data: { ...o },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Working time is saved");

      // yield put(mutations.getSalonWorkingTimePaged(salonId, 1, []));
      yield put(mutations.getSalonWorkingTimePagedConsolidated(salonId, 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Working time is not saved");
    }
  }
}

export function* createSalonWorkingTimeSaga() {
  while (true) {
    const { salonId, startDate, endDate, checkboxInputs, cb } = yield take(
      mutations.CREATE_SALON_WORKING_TIME
    );
    let o = {
      SalonId: salonId,
      StartTime: startDate,
      EndTime: endDate,
      CheckboxInputs: checkboxInputs,
    };
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/workingTimes",
        data: { ...o },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Working time is created");
      cb();
      // yield put(mutations.getSalonWorkingTimePaged(salonId, 1, []));
      yield put(mutations.getSalonWorkingTimePagedConsolidated(salonId, 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Working time is not created");
    }
  }
}

export function* createSalonWorkingTimeAutoSaga() {
  while (true) {
    const { salonId, workingTimeList, cb } = yield take(
      mutations.CREATE_SALON_WORKING_TIME_AUTO
    );

    yield put(mutations.setLoaded(false));
    // yield put(mutations.setDashboardLoaded(false));
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/workingTimes/autoSalon",
        data: { SalonId: salonId, WorkingTimeList: [...workingTimeList] },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Working time is created");

      // yield put(mutations.getSalonWorkingTimePaged(salonId, 1, []));
      yield put(mutations.getSalonWorkingTimePagedConsolidated(salonId, 1, []));

      cb();
      yield put(mutations.setLoaded(true));
      // yield put(mutations.setDashboardLoaded(true));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Working time is not created");
      yield put(mutations.setLoaded(true));
    }
  }
}

export function* deleteRangeSaga() {
  while (true) {
    const {
      salonId,
      deleteRangeStartDate,
      deleteRangeEndDate,
      cb,
    } = yield take(mutations.DELETE_RANGE);
    console.log(deleteRangeStartDate);
    yield put(mutations.setLoaded(false));
    // yield put(mutations.setDashboardLoaded(false));
    let o = {
      SalonId: salonId,
      DeleteRangeStartDate: deleteRangeStartDate,
      DeleteRangeEndDate: deleteRangeEndDate,
    };
    try {
      yield axios({
        method: "post",
        url: url + "/v1.0/workingTimes/deleteRange",
        data: { ...o },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      toastSuccess("Working time range is deleted");

      // yield put(mutations.getSalonWorkingTimePaged(salonId, 1, []));
      yield put(mutations.getSalonWorkingTimePagedConsolidated(salonId, 1, []));

      cb();
      yield put(mutations.setLoaded(true));
      // yield put(mutations.setDashboardLoaded(true));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Working time range is not deleted");
      yield put(mutations.setLoaded(true));
    }
  }
}

export function* deleteSalonWorkingTimeSaga() {
  while (true) {
    const { workingTimeId, salonId } = yield take(
      mutations.DELETE_SALON_WORKING_TIME
    );
    try {
      yield axios({
        method: "delete",
        url: url + `/v1.0/workingTimes/${workingTimeId}`,
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      toastSuccess("Working time is deleted");

      // yield put(mutations.getSalonWorkingTimePaged(salonId, 1, []));
      yield put(mutations.getSalonWorkingTimePagedConsolidated(salonId, 1, []));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Working time is not deleted");
    }
  }
}

export function* deleteSalonWorkingTimeConsolidatedSaga() {
  while (true) {
    const { workingTimeConsolidated } = yield take(
      mutations.DELETE_SALON_WORKING_TIME_CONSOLIDATED
    );
    try {
      yield axios({
        method: "delete",
        url: url + `/v1.0/workingTimes/deleteSalonConsolidated`,
        data: { ...workingTimeConsolidated },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });

      toastSuccess("Working time is deleted");
      // yield put(
      //   mutations.getSalonWorkingTimePaged(
      //     workingTimeConsolidated.salonId,
      //     1,
      //     []
      //   )
      // );
      yield put(
        mutations.getSalonWorkingTimePagedConsolidated(
          workingTimeConsolidated.salonId,
          1,
          []
        )
      );
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Working time is not deleted");
    }
  }
}

export function* getSalonWorkingTimeDateListSaga() {
  while (true) {
    const { salonId } = yield take(mutations.GET_SALON_WORKING_TIME_DATE_LIST);
    let o = {
      SalonId: salonId,
    };
    try {
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/workingTimes/salonWorkingTimeDateList",
        data: { ...o },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      //toastSuccess("If date is created checked");

      yield put(mutations.setSalonWorkingTimeDateList(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Unsecsessefully fetched salon working time date list");
    }
  }
}

/* #endregion */

/* #region Free Visit Time Slot sagas */

export function* getAllFreeVisitTimeSlotsSaga() {
  while (true) {
    const { freeVisitTimeSlotRequest } = yield take(
      mutations.GET_ALL_FREE_VISIT_TIME_SLOTS
    );
    try {
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/freeTimeSlots/visit",
        data: { ...freeVisitTimeSlotRequest },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllFreeVisitTimeSlots(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting free time slots");
    }
  }
}

export function* getAllFreeVisitTimeSlotsV2Saga() {
  while (true) {
    const { freeVisitTimeSlotRequest } = yield take(
      mutations.GET_ALL_FREE_VISIT_TIME_SLOTS_V2
    );
    try {
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/freeTimeSlots/visitV2",
        data: { ...freeVisitTimeSlotRequest },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllFreeVisitTimeSlots(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting free time slots");
    }
  }
}

/* #endregion */

/* #region Free Salon Time Slot sagas */

export function* getAllFreeSalonTimeSlotsSaga() {
  while (true) {
    const { freeSalonTimeSlotRequest } = yield take(
      mutations.GET_ALL_FREE_SALON_TIME_SLOTS
    );
    try {
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/freeTimeSlots/salon",
        data: { ...freeSalonTimeSlotRequest },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllFreeSalonTimeSlots(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting free time slots");
    }
  }
}

/* #endregion */

/* #region Free Hairstylist Time Slot sagas */

export function* getAllFreeHairstylistTimeSlotsSaga() {
  while (true) {
    const { freeHairstylistTimeSlotRequest } = yield take(
      mutations.GET_ALL_FREE_HAIRSTYLIST_TIME_SLOTS
    );

    try {
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/freeTimeSlots/hairstylist",
        data: { ...freeHairstylistTimeSlotRequest },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setAllFreeHairstylistTimeSlots(data));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting free time slots");
    }
  }
}

/* #endregion */

/* #region Free Time Slot sagas */

export function* getDashboardDataSaga() {
  while (true) {
    const { requestData } = yield take(mutations.GET_DASHBOARD_DATA);
    yield put(mutations.setDashboardLoaded(false));
    try {
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/freeTimeSlots/dashboardData",
        data: { ...requestData },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setDashboardData(data));
      yield put(mutations.setDashboardLoaded(true));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting dashboard data");
      yield put(mutations.setDashboardLoaded(true));
    }
  }
}

export function* getDashboardDataDailySaga() {
  while (true) {
    const { requestData } = yield take(mutations.GET_DASHBOARD_DATA_DAILY);
    yield put(mutations.setDashboardLoaded(false));
    try {
      const { data } = yield axios({
        method: "post",
        url: url + "/v1.0/freeTimeSlots/dashboardDataDaily",
        data: { ...requestData },
        headers: {
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      });
      yield put(mutations.setDashboardDataDaily(data));
      yield put(mutations.setDashboardLoaded(true));
    } catch (e) {
      /* catch block handles failed save */
      console.log(e);
      toastError("Error getting dashboard data");
      yield put(mutations.setDashboardLoaded(true));
    }
  }
}

/* #endregion */
